import { requests } from './agent';
import * as API_ROUTES from '../routing/api';

export const downloadFile = (fileIRI, fileName = undefined) => {
  return requests.getFile(fileIRI, {}, fileName);
};

export const downloadReportFile = (reportFileId, fileName = undefined) => {
  return requests.getFile(API_ROUTES.REPORT_FILE_DOWNLOAD.replace(':id', reportFileId), {}, fileName);
};

export const downloadInvoiceNumbersExport = (data, fileName = undefined) => {
  return requests.getFile(API_ROUTES.INVOICE_NUMBERS_EXPORT, data, fileName);
};

export const downloadConsultantPDFFile = (fileIRI, fileName = undefined) => {
  return requests.getFile(fileIRI, {}, fileName, true, true);
};

export const downloadWeeklyInvoiceCountsExport = (data, fileName = undefined) => {
  return requests.getFile(API_ROUTES.INVOICE_BATCH_WEEKLY_COUNTS_EXPORT, data, fileName);
};

export const downloadInvoicePDFFile = (invoicePDFFileId, fileName = '', validator = '') => {
  const qso = validator !== '' ? { validator } : {};
  return requests.getFile(API_ROUTES.INVOICE_PDF_FILE_DOWNLOAD.replace(':id', invoicePDFFileId), qso, fileName, true, true);
};
