import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ChooseDateAndFilesForm from '../../components/forms/ReportBatch/ChooseDateAndFilesForm';
import ConfirmDateAndFilesForm from '../../components/forms/ReportBatch/ConfirmDateAndFilesForm';
import UploadPart3Form from '../../components/forms/ReportBatch/UploadPart3Form';

class ReportBatchCreate extends React.Component {
  render() {
    const { associatedDate, fileToUpload, reportBatch, reportBatchCreate, reportBatchPublish, publishInProgress, addToast, downloadReportFile, resetForm } =
      this.props;
    return (
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Header>Upload Reports</Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col>
                      <ChooseDateAndFilesForm isComplete={reportBatch || (associatedDate && fileToUpload)} />
                    </Col>
                  </Row>
                  {(reportBatch || (associatedDate && fileToUpload)) && (
                    <Row>
                      <Col>
                        <ConfirmDateAndFilesForm
                          reportBatchCreate={reportBatchCreate}
                          associatedDate={associatedDate}
                          fileToUpload={fileToUpload}
                          isComplete={reportBatch}
                        />
                      </Col>
                    </Row>
                  )}
                  {reportBatch && (!reportBatch.isPublished || reportBatch.isProcessed) && (
                    <Row>
                      <Col>
                        <UploadPart3Form
                          reportBatch={reportBatch}
                          reportBatchPublish={reportBatchPublish}
                          isComplete={reportBatch.isPublished}
                          isPublishing={publishInProgress}
                          addToast={addToast}
                          downloadReportFile={downloadReportFile}
                        />
                      </Col>
                    </Row>
                  )}
                  {reportBatch && reportBatch.isProcessed && reportBatch.isPublished && (
                    <Row>
                      <Col>
                        <div className="crud__section">
                          <div className="crud__section-icon">
                            <span className="font-weight-bolder">4</span>
                          </div>
                          <h2 className="crud__section-header mb-0 d-flex justify-content-between">
                            Reports created / emails queued and sent
                            <i className="fa fa-check-circle text-primary" />
                          </h2>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <button type="button" className="btn btn-outline-danger btn-sm ml-2 " onClick={() => resetForm()}>
                    Reset
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ReportBatchCreate;
