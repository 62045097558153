import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

const wpBaseUrl = 'https://www.mediaccounts.co.uk/wp-json/wp/v2';

const WordpressPage = ({ pageId, showAlert = false, showHr = true }) => {
  const [pageContent, setPageContent] = useState(false);

  useEffect(() => {
    setPageContent(localStorage.getItem('wpPageContent' + pageId));

    fetch(wpBaseUrl + `/pages/` + pageId)
      .then((response) => response.json())
      .then((responseJson) => {
        const { content } = responseJson;
        if (content && content.rendered) {
          localStorage.setItem('wpPageContent' + pageId, content.rendered);
          setPageContent(content.rendered);
        } else {
          localStorage.removeItem('wpPageContent' + pageId);
        }
      })
      .catch((error) => {
        localStorage.removeItem('wpPageContent' + pageId);
        console.error(error);
      });
  }, [pageId]);

  if (!pageContent || String(pageContent).length < 1) {
    return null;
  }

  return (
    <>
      {showAlert && <Alert variant="warning">Important information...</Alert>}
      <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
      {showHr && <hr className="mt-4 mb-4" />}
    </>
  );
};

export default WordpressPage;
