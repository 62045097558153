import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../redux/reducers';
import { tokenMiddleware } from '../middleware';
import thunkMiddleware from 'redux-thunk';
//import * as Sentry from '@sentry/react';
import * as Sentry from '@sentry/react';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  let store;
  const reducer = createRootReducer(history);
  if (process.env.REACT_APP_SENTRY_DSN && ['stage', 'production'].includes(window.APP_ENV)) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
      stateTransformer: (state) => {
        return {
          ...state,
          auth: null,
        };
      },
    });
    store = createStore(
      reducer, // root reducer with router state
      preloadedState,
      compose(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          thunkMiddleware,
          tokenMiddleware,
        ),
        sentryReduxEnhancer ? sentryReduxEnhancer : null,
      ),
    );
  } else {
    store = createStore(
      reducer, // root reducer with router state
      preloadedState,
      compose(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          thunkMiddleware,
          tokenMiddleware,
        ),
      ),
    );
  }

  //  if (process.env.NODE_ENV !== 'production') {
  //    if (module.hot) {
  //      module.hot.accept('../redux/reducers', () => {
  //        store.replaceReducer(reducer);
  //      });
  //    }
  //  }

  return store;
}
