import React from 'react';
import Form from 'react-bootstrap/Form';
import MASpinner from '../../common/MASpinner';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { consultantTicketSingleCreate, consultantTicketSingleUnload } from '../../../lib/redux/actions/consultantTickets';
import { renderDatePicker, renderField, renderSelect } from '../../../lib/helper/form';
import { addToast } from '../../../lib/redux/actions/toast';
import { consultantTicketTypeListFetch } from '../../../lib/redux/actions/consultantTicketTypes';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';
import { consultantTypeListFetch } from '../../../lib/redux/actions/consultantType';
import ConsultantTicketUsersTypeAhead from '../../fields/ConsultantTicketUsersTypeAhead';

const mapStateToProps = (state) => {
  return {
    ...state.consultantTickets,
    ...state.consultantTicketTypes,
  };
};
const mapDispatchToProps = {
  consultantTicketSingleCreate,
  consultantTicketTypeListFetch,
  consultantTicketSingleUnload,
  consultantTypeListFetch,
  addToast,
};

class CreateConsultantTicketForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConsultant: undefined,
      clearTypeaheads: false,
      copyEmailToAuthorisedUsers: false,
      selectedAuthorisedUsers: undefined,
      actionDate: null,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return {
        clearTypeaheads: true,
        selectedAuthorisedUsers: undefined,
        selectedConsultant: undefined,
      };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  componentDidMount() {
    const { consultantTicketTypeListFetch, consultantTypeListFetch } = this.props;
    consultantTicketTypeListFetch();
    consultantTypeListFetch();
  }

  componentWillUnmount() {
    this.props.consultantTicketSingleUnload();
  }

  onSubmit(values) {
    const { consultantTicketSingleCreate, reset, consultant, addToast } = this.props;

    let consultantId;
    if (!consultant && this.state.selectedConsultant) {
      consultantId = this.state.selectedConsultant['@id'];
    } else if (consultant) {
      consultantId = consultant['@id'];
    } else {
      throw new SubmissionError('Problem with consultant');
    }
    let authorisedUsersToCopy = [];
    if (this.state.selectedAuthorisedUsers) {
      authorisedUsersToCopy.push(...this.state.selectedAuthorisedUsers);
    }

    return consultantTicketSingleCreate({
      message: values.message,
      consultantTicketType: values.consultantTicketType,
      consultant: consultantId,
      authorisedUsersToCopy: authorisedUsersToCopy,
      actionDate: this.state.actionDate,
    }).then(() => {
      addToast('The Ticket was added', true);
      this.clearTypeaheads();
      reset();
    });
  }

  addDate(date) {
    this.setState(
      (prevState) => {
        return {
          actionDate: date,
        };
      },
      () => {},
    );
  }

  render() {
    const { handleSubmit, error, pristine, submitting, consultantTicketTypes = [], isCreatingConsultantTicket, consultant } = this.props;
    const { selectedConsultant } = this.state;

    const consultantTicketTypeOptions = [];
    consultantTicketTypes.forEach((consultantTicketType) => {
      consultantTicketTypeOptions.push({
        text: consultantTicketType.displayName,
        value: consultantTicketType['@id'],
      });
    });

    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-edit" />
        </div>

        <h2 className="invoice-batch-single__section-header">Create Ticket</h2>
        <p>
          Need to tell us you&apos;re going to be on holiday? Your bank account details are changing? Your fees need updating? Raise a ticket below and we will
          update our records.
        </p>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className={'row'}>
            {!consultant && (
              <div className={'col-sm-4'}>
                <ConsultantTypeahead
                  shouldClear={this.state.clearTypeaheads}
                  hasCleared={this.typeaheadCleared.bind(this)}
                  multiple={false}
                  label={'Consultant'}
                  onChange={(selected) => {
                    this.setState(() => {
                      return {
                        selectedConsultant: selected[0],
                      };
                    });
                  }}
                />
                {selectedConsultant ? (
                  <ConsultantTicketUsersTypeAhead
                    consultant={selectedConsultant}
                    shouldClear={this.state.clearTypeaheads}
                    hasCleared={this.typeaheadCleared.bind(this)}
                    multiple={true}
                    label={'Email these Consultant/Consultant Authorised/Staff users'}
                    onChange={(selected) => {
                      this.setState(() => {
                        return {
                          selectedAuthorisedUsers: selected,
                        };
                      });
                    }}
                  />
                ) : null}
              </div>
            )}

            <div className={'col-sm-3'}>
              <Field
                name="consultantTicketType"
                options={consultantTicketTypeOptions}
                emptyLabel={'Please select'}
                label="Ticket Type"
                component={renderSelect}
                required={true}
              />
            </div>
            <div className={'col-sm-3'}>
              <Field
                component={renderDatePicker}
                wrapperClassName={'form-control'}
                className={'form-control'}
                name="actionDate"
                label={
                  <>
                    <i className="fas fa-calendar mx-2" />
                    Action On Date
                  </>
                }
                id="action-dates"
                handleSelect={this.addDate.bind(this)}
                handleChange={() => {
                  /* this is not used in the case but required*/
                }}
                selected={this.state.actionDate}
                clearOnClose={true}
                popperPlacement="top-end"
                emptyLabel={'Please select'}
                required={true}
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-sm-12'}>
              <Field className="mb-2" style={{ minHeight: '200px' }} name="message" label="Message" id="message" type="textarea" component={renderField} />
              {isCreatingConsultantTicket ? (
                <MASpinner />
              ) : (
                <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block mb-2">
                  Save Ticket
                </button>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'CreateConsultantTicketForm',
    enableReinitialize: true,
  })(CreateConsultantTicketForm),
);
