import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { problemDataEdit } from '../../../lib/redux/actions/problemData';
import { Field, reduxForm } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import PropTypes from 'prop-types';
import { niceDateFormatter } from '../../../lib/helper/formatting';
import UpliftDataForm from '../UpliftData/UpliftDataForm';
import moment from 'moment';
import * as ROUTES from '../../../lib/routing/frontend';
import PROBLEM_STATUS from '../../../lib/helper/problemStatusCodes';
import PROBLEM_TYPE from '../../../lib/helper/problemTypes';
import { upliftDataAdd, upliftDataDelete, upliftDataEdit } from '../../../lib/redux/actions/upliftData';

const mapDispatchToProps = {
  addToast,
  problemDataEdit,
  upliftDataEdit,
  upliftDataAdd,
  upliftDataDelete,
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      patientFirstName: props.problemData.patientFirstName,
      patientLastName: props.problemData.patientLastName,
      insurer: props.problemData.insurer ? props.problemData.insurer['@id'] : null,
      problemType: props.problemData.problemType['@id'],
      problemStatus: props.problemData.problemStatus['@id'],
      treatment: props.problemData.treatment,
      problemDate: moment(props.problemData.problemDate).format('YYYY-MM-DD'),
      consultationDate: props.problemData.consultationDate['@id'],
      invoiceNumbers: props.problemData?.invoiceNumbers,
      otherInformation: props.problemData.otherInformation,
      upliftData: {
        dateSubmitted: moment(props.problemData?.upliftData?.dateSubmitted).format('YYYY-MM-DD'),
        feeRequested: props.problemData?.upliftData?.feeRequested,
        feeAgreed: props.problemData?.upliftData?.feeAgreed,
        charts: props?.problemData?.upliftData?.charts,
        opNotes: props?.problemData?.upliftData?.opNotes,
        upliftForms: props?.problemData?.upliftData?.upliftForms,
      },
    },
  };
};

class EditProblemDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateAmPmMap: [],
      nextDateKey: 0,
      selectedDates: [],
      currentHealthInsurerId: false,
      problemType: false,
      availableInvoiceNumbers: [],
      selectedInvoiceNumbers: [],
      shouldSave: false,
      showUpliftDataForm: props.problemData?.upliftData || props.problemData?.problemType?.code === PROBLEM_TYPE.UPLIFT,
      hasBeenAddedToHealthCodes: false,
    };
  }

  //  componentDidMount() {
  //    if (this.props?.problemData?.invoiceNumbers) {
  //      this.setState(() => {
  //        return {
  //          selectedInvoiceNumbers: this.props.problemData && this.props.problemData.invoiceNumbers ? this.props.problemData.invoiceNumbers.map(h => {
  //            return { value: h, label: h };
  //          }) : [],
  //        };
  //      });
  //    }
  //    if (this.props?.invoiceBatch?.invoiceNumbers) {
  //      let availableInvoiceNumbers = this.props.invoiceBatch && this.props.invoiceBatch.invoiceNumbers ? this.props.invoiceBatch.invoiceNumbers.map(h => {
  //            return { value: h, label: h };
  //      }) : [];
  //      availableInvoiceNumbers.filter(value => {
  //        return !this.props.initialValues.invoiceNumbers.includes(value.value)
  //      });
  //      this.setState(() => {
  //        return {
  //          availableInvoiceNumbers: availableInvoiceNumbers,
  //        };
  //      });
  //    }
  //  }

  onSubmit = (formValues) => {
    const { problemDataEdit, upliftDataAdd, upliftDataDelete, problemData, addToast, upliftDataEdit, history } = this.props;
    if (this.state.selectedInvoiceNumbers && this.state.selectedInvoiceNumbers.length > 0) {
      formValues.invoiceNumbers = this.state.selectedInvoiceNumbers.map((h) => {
        return h.value;
      });
    }
    let upliftFormData;
    if (formValues.upliftData) {
      upliftFormData = formValues.upliftData;
    }
    return problemDataEdit(formValues, problemData.id).then((response) => {
      if (response.problemType.code !== PROBLEM_TYPE.UPLIFT) {
        if (response.upliftData) {
          upliftDataDelete(response.upliftData.id).then(() => {
            addToast('The problem data was updated', true);
            history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', problemData?.invoiceBatch.id));
          });
        } else {
          addToast('The problem data was updated', true);
          //              history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', problemData?.invoiceBatch.id));
        }
      } else {
        upliftFormData.problemData = {
          id: problemData?.id || response?.id,
        };
        if (upliftFormData.charts) {
          upliftFormData.charts = upliftFormData.charts === 'false' ? Boolean(false) : Boolean(true);
        } else {
          delete upliftFormData.charts;
        }
        if (upliftFormData.opNotes) {
          upliftFormData.opNotes = upliftFormData.opNotes === 'false' ? Boolean(false) : Boolean(true);
        } else {
          delete upliftFormData.opNotes;
        }
        if (upliftFormData.upliftForms) {
          upliftFormData.upliftForms = upliftFormData.upliftForms === 'false' ? Boolean(false) : Boolean(true);
        } else {
          delete upliftFormData.upliftForms;
        }
        if (!problemData.upliftData) {
          return upliftDataAdd(upliftFormData).then(() => {
            addToast('Successfully created uplift data', true);
            history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', problemData?.invoiceBatch?.id || response?.invoiceBatch?.id));
          });
        } else {
          return upliftDataEdit(problemData?.upliftData?.id, upliftFormData).then(() => {
            addToast('Successfully updated uplift data', true);
            //                history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', problemData?.invoiceBatch?.id || response?.invoiceBatch?.id));
          });
        }
      }
    });
  };

  completeProblemData = () => {
    const { problemData, addToast, problemDataEdit, history } = this.props;
    let values = {
      problemStatus: '/problem_statuses/3',
      completeDate: moment().utc().format(),
    };
    return problemDataEdit(values, this.props.problemData.id).then(() => {
      addToast('The Problem has been Resolved', true);
      history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', problemData?.invoiceBatch?.id));
    });
  };

  render() {
    const { handleSubmit, healthInsurers, problemTypes, problemStatuses, problemData } = this.props;
    const healthInsurerOptions = [];
    healthInsurers.forEach((healthInsurer) => {
      healthInsurerOptions.push({
        text: healthInsurer.name,
        value: healthInsurer['@id'],
      });
    });
    const problemTypesOptions = [];
    problemTypes.forEach((problemType) => {
      problemTypesOptions.push({
        text: problemType.name,
        value: problemType['@id'],
      });
    });
    const problemStatusOptions = [];
    problemStatuses.forEach((problemStatus) => {
      if (problemData?.problemStatus?.code === String(PROBLEM_STATUS.COMPLETE)) {
        problemStatusOptions.push({
          text: problemStatus.name,
          value: problemStatus['@id'],
        });
      } else if (problemStatus.name !== 'Complete') {
        problemStatusOptions.push({
          text: problemStatus.name,
          value: problemStatus['@id'],
        });
      }
    });
    const consultationDatesOptions = [];
    problemData.invoiceBatch.invoiceDates.forEach((invoiceDate) => {
      consultationDatesOptions.push({
        text: niceDateFormatter(invoiceDate.date) + ' ' + invoiceDate.timeOfDayText,
        value: invoiceDate['@id'],
      });
    });
    return (
      <div className="p-3">
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            <fieldset className="col-md-12">
              <div className="row">
                <div className="col-sm-6 col-xl-3">
                  <Field name="problemDate" label="Problem Date" id="problemDate" type="date" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field
                    showEmpty={false}
                    name="insurer"
                    options={healthInsurerOptions}
                    emptyLabel={'Please select'}
                    label="Health Insurer"
                    component={renderSelect}
                  />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field
                    name="consultationDate"
                    options={consultationDatesOptions}
                    emptyLabel={'Please select'}
                    label="Treatment Date"
                    component={renderSelect}
                  />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field name="problemStatus" options={problemStatusOptions} label="Status" emptyLabel={'Please select'} component={renderSelect} />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <Field name="patientFirstName" label="Patient First Name" id="patientFirstName" type="text" component={renderField} />
                </div>
                <div className="col-md-6">
                  <Field name="patientLastName" label="Patient Last Name" id="patientLastName" type="text" component={renderField} />
                </div>
              </div>
            </fieldset>
          </div>

          <div className="row">
            <fieldset className="col-sm-12 col-md-12">
              <div className="row">
                <div className="col-sm-6 col-xl-6">
                  <Field
                    name="problemType"
                    options={problemTypesOptions}
                    label="Type"
                    emptyLabel={'Please select'}
                    onChange={(e) => {
                      if (e.target.value === '/problem_types/3') {
                        this.setState({ showUpliftDataForm: true });
                      } else {
                        this.setState({ showUpliftDataForm: false });
                      }
                      this.setState({ problemType: e.target.value });
                    }}
                    component={renderSelect}
                  />
                </div>
                <div className="col-md-6">
                  <Field name="invoiceNumbers" id="invoiceNumbers" label="Invoice Numbers" type="textarea" component={renderField} />
                  {/*<Field*/}
                  {/*    name="invoiceNumbers"*/}
                  {/*    isMulti*/}
                  {/*    closeMenuOnSelect={this.state.availableInvoiceNumbers.length - this.state.selectedInvoiceNumbers.length === 1}*/}
                  {/*    handleSelect={selected => {*/}
                  {/*      this.setState(() => {*/}
                  {/*        return Symbol.iterator in Object(selected) ? { selectedInvoiceNumbers: [...selected] } : { selectedInvoiceNumbers: [] };*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*    selectedOptions={this.state.selectedInvoiceNumbers}*/}
                  {/*    label="Please select the invoice numbers that this problem relates to"*/}
                  {/*    options={this.state.availableInvoiceNumbers}*/}
                  {/*    component={renderReactSelect}*/}
                  {/*/>*/}
                </div>
              </div>
            </fieldset>
          </div>

          <div className="row">
            <fieldset className="col-sm-12 col-md-12">
              <div className="row">
                <div className="col-sm-6 col-xl-6">
                  <Field name="treatment" label="Treatment" id="treatment" rows="3" type="textarea" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-6">
                  <Field name="otherInformation" label="Any Other Information" id="otherInformation" rows="3" type="textarea" component={renderField} />
                </div>
              </div>
            </fieldset>
          </div>
          <hr />
          {this.state.showUpliftDataForm && (
            <>
              Uplift Data
              <UpliftDataForm problemData={problemData} type={problemData.upliftData === undefined ? 'create' : 'edit'} />
            </>
          )}
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="row save-controls">
                <div className="col-sm-12 col-md-12">
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Form.Check
                      className="mt-3 mb-3"
                      name="isEscalateConfirmedChecked"
                      label="Please confirm that you have added a note in Healthcodes"
                      id="is-escalate-confirmed-checked"
                      type={'switch'}
                      component={renderCheckbox}
                      checked={this.state.hasBeenAddedToHealthCodes}
                      onChange={() => this.setState({ hasBeenAddedToHealthCodes: !this.state.hasBeenAddedToHealthCodes })}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={
                      !this.state.hasBeenAddedToHealthCodes ||
                      (this.state?.selectedInvoiceNumbers?.length === this.props.initialValues?.invoiceNumbers.length &&
                        (this.props.pristine || this.props.submitting))
                    }
                    className="btn btn-primary float-right">
                    Save
                  </button>
                </div>
              </div>
              <div className="row">
                {this.props.problemData.problemStatus.code !== String(PROBLEM_STATUS.COMPLETE) && (
                  <div className="col-sm-12 col-md-12 mt-3">
                    <button
                      type="button"
                      onClick={() => {
                        this.completeProblemData();
                      }}
                      disabled={this.props.dirty && this.props.dirty && !this.props.submitSucceeded}
                      className="btn btn-danger float-right mt-2">
                      Mark Resolved
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

EditProblemDataForm = reduxForm({
  form: 'EditProblemDataForm',
  enableReinitialize: true,
})(EditProblemDataForm);

EditProblemDataForm = connect(mapStateToProps, mapDispatchToProps)(EditProblemDataForm);

export default EditProblemDataForm;

EditProblemDataForm.propTypes = {
  problemTypes: PropTypes.array.isRequired,
  healthInsurers: PropTypes.array.isRequired,
};
