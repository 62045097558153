import { Field } from 'redux-form';
import React from 'react';
import { hospitalListFetch, hospitalListUnload } from '../../lib/redux/actions/hospitals';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TypeAheadField from '../common/reduxForm/TypeaheadField';

const mapStateToProps = (state) => ({
  ...state.hospitals,
});
const mapDispatchToProps = {
  hospitalListFetch,
  hospitalListUnload,
};

class HospitalTypeahead extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selected: [] };
  }

  onSearch(query) {
    const { defaultFilters = {} } = this.props;
    const filters = { name: query, ...defaultFilters, pagination: false };
    this.props.hospitalListFetch(1, filters);
  }

  componentDidMount() {
    const { selectedHospitals = [] } = this.props;
    if (this.state.selected.length === 0 && selectedHospitals.length > 0) {
      this.setState(() => ({ selected: selectedHospitals }));
    }
  }

  componentDidUpdate() {
    const { shouldClear, hasCleared } = this.props;
    if (shouldClear) {
      this.hospitalRef.getRenderedComponent().clear();
      hasCleared();
    }
  }

  componentWillUnmount() {
    this.props.hospitalListUnload();
  }

  render() {
    const {
      onChange,
      hospitals = [],
      label = 'Hospitals',
      name = 'hospitals',
      multiple = true,
      isFetchingHospitals,
      selectedHospitals = [],
      error,
      disabled = false,
    } = this.props;

    let optHospitals = [];
    //     If there are no hospital options supplied, use the selected (i.e. already linked to the related object) hospitals
    if (hospitals.length === 0 && selectedHospitals.length > 0) {
      hospitals.push(...selectedHospitals);
      optHospitals = hospitals.filter((hospital, index, self) => self.findIndex((h) => h.id === hospital.id) === index);
    } else if (hospitals.length > 0 && selectedHospitals.length > 0) {
      // ensure selected options don't already exist in the dropdown.
      optHospitals = hospitals.filter((x) => !selectedHospitals.filter((y) => y.id === x.id).length);
    } else if (hospitals.length > 0 && selectedHospitals.length === 0) {
      // if none selected so far then allow all returned as options
      optHospitals = hospitals;
    }
    return (
      <>
        <Field
          name={name}
          isLoading={isFetchingHospitals}
          label={label}
          onSearch={this.onSearch.bind(this)}
          options={optHospitals}
          labelKey="name"
          selected={this.state.selected}
          component={TypeAheadField}
          multiple={multiple}
          onChange={(selected) => {
            this.props.hospitalListUnload();
            this.setState(
              () => ({ selected }),
              () => {
                onChange(selected);
              },
            );
          }}
          ref={(ref) => (this.hospitalRef = ref)}
          forwardRef
          disabled={disabled}
        />

        {error && <small className="form-text text-danger">{error}</small>}
      </>
    );
  }
}

HospitalTypeahead.propTypes = {
  shouldClear: PropTypes.bool.isRequired,
  hasCleared: PropTypes.func.isRequired,
  isFetchingHospitals: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hospitalListFetch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalTypeahead);
