import { INVOICE_DATE_ADD_REQUEST, INVOICE_DATE_ADDED, INVOICE_DATE_UPDATE_REQUEST, INVOICE_DATE_UPDATED } from '../constants';

const reducer = (
  state = {
    invoiceDate: null,
    isFetchingInvoiceDate: false,
    isUpdatingInvoiceDate: false,
    isAddingInvoiceDate: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_DATE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingInvoiceDate: true,
      };
      return state;

    case INVOICE_DATE_UPDATED:
      return {
        ...state,
        invoiceDate: action.data,
        isUpdatingInvoiceDate: false,
      };

    case INVOICE_DATE_ADD_REQUEST:
      return {
        ...state,
        isAddingInvoiceDate: true,
      };

    case INVOICE_DATE_ADDED:
      return {
        ...state,
        isAddingInvoiceDate: false,
      };

    default:
      return state;
  }
};

export default reducer;
