import React from 'react';
import { connect } from 'react-redux';
import ConsultantSingleCreate from './ConsultantSingleCreate';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';
import { consultantSingleCreate, consultantSingleUnload } from '../../lib/redux/actions/consultant';

const mapDispatchToProps = {
  consultantSingleCreate,
  consultantSingleUnload,
  addToast,
};

class ConsultantSingleCreateContainer extends React.Component {
  componentWillUnmount() {
    this.props.consultantSingleUnload();
  }

  onCreate(data) {
    const { consultantSingleCreate, addToast, history } = this.props;
    return consultantSingleCreate(data).then((consultant) => {
      addToast('The Consultant was created successfully', true);
      history.push(ROUTES.CONSULTANTS.SINGLE.replace(':id', consultant.id));
    });
  }

  render() {
    return <ConsultantSingleCreate onCreate={this.onCreate.bind(this)} />;
  }
}

export default connect(null, mapDispatchToProps)(ConsultantSingleCreateContainer);
