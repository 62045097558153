import {
  CHASER_WORK_SCHEDULE_ERROR,
  CHASER_WORK_SCHEDULE_RECEIVED,
  CHASER_WORK_SCHEDULE_REQUEST,
  CHASER_WORK_SCHEDULE_SET_DATE,
  CHASER_WORK_SCHEDULE_SINGLE_RECEIVED,
  CHASER_WORK_SCHEDULE_UNLOAD,
} from '../constants';

const reducer = (
  state = {
    workSchedules: [],
    isFetchingWorkSchedule: false,
    currentDate: undefined,
  },
  action,
) => {
  switch (action.type) {
    case CHASER_WORK_SCHEDULE_REQUEST:
      state = {
        ...state,
        isFetchingWorkSchedule: true,
      };
      return state;

    case CHASER_WORK_SCHEDULE_RECEIVED:
      state = {
        ...state,
        workSchedules: action.data['hydra:member'],
        isFetchingWorkSchedule: false,
      };
      return state;
    case CHASER_WORK_SCHEDULE_SINGLE_RECEIVED:
      let workSchedules = state.workSchedules.filter((x) => x.id === action.data.id);
      if (workSchedules.length === 0) {
        workSchedules = [...state.workSchedules, action.data];
      } else {
        workSchedules = [
          ...state.workSchedules.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ];
      }
      state = {
        ...state,
        workSchedules: workSchedules,
        isFetchingWorkSchedule: false,
      };
      return state;

    case CHASER_WORK_SCHEDULE_ERROR:
      state = {
        ...state,
        isFetchingWorkSchedule: false,
      };
      return state;

    case CHASER_WORK_SCHEDULE_UNLOAD:
      state = {
        workSchedules: [],
        isFetchingWorkSchedule: false,
        currentDate: undefined,
      };
      return state;

    case CHASER_WORK_SCHEDULE_SET_DATE:
      state = {
        ...state,
        currentDate: action.date,
      };
      return state;

    default:
      return state;
  }
};

export default reducer;
