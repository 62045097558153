import React from 'react';
import Table from 'react-bootstrap/Table';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getCurrentWeekRange, isToday } from '../../lib/helper/calendarTableHelper';
import EditableWorkScheduleCell from './EditiableWorkScheduleCell';
import { isOutsourcer } from '../../lib/helper/authorisation';

const moment = extendMoment(Moment);

const doesUserHaveEventOnThisDay = (workScheduleWeeklyEvents, cellDate, user) => {
  return (
    workScheduleWeeklyEvents.filter((event) => {
      if (
        Number(event.userId) === Number(user.id) &&
        moment(event.startDate).format('YYYY-MM-DD') <= cellDate.format('YYYY-MM-DD') &&
        moment(event.endDate).format('YYYY-MM-DD') >= cellDate.format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    }).length > 0
  );
};

class WorkScheduleTable extends React.Component {
  constructor(props) {
    super(props);
    this.today = moment().utc();
    //    this.state = {
    //      totals: []
    //    };
    this.totals = [];
  }

  renderHeaderCell(day) {
    return (
      <th key={`th-${day.unix()}`} className={isToday(day, this.today) ? 'highlight-today' : undefined}>
        <span className="day">{day.format('ddd')}</span>
        <span className="date">{day.format('DD/MM')}</span>
      </th>
    );
  }

  renderHeader() {
    const { currentDate } = this.props;
    const headerCells = [];
    for (let day of getCurrentWeekRange(currentDate).by('day')) {
      headerCells.push(this.renderHeaderCell(day));
    }
    return (
      <thead>
        <tr>
          <th style={{ width: '180px' }}>Account Name</th>
          {headerCells}
        </tr>
      </thead>
    );
  }

  renderRow(user) {
    if (!user) {
      return null;
    }

    if (!user.isEnabled) {
      return null;
    }
    return (
      <tr key={user.id}>
        <th>{user.firstName + ' ' + user.lastName}</th>
        {this.renderRowCells(user)}
      </tr>
    );
  }

  renderRowCells(user) {
    const { workSchedules, currentDate, workScheduleWeeklyEvents = [] } = this.props;

    const userSchedules = workSchedules.find((schedule) => {
      return schedule.user.id === user.id;
    });

    const range = getCurrentWeekRange(currentDate);
    const cells = [];
    for (let day of range.by('day')) {
      cells.push(this.renderCell(user, userSchedules, day, workScheduleWeeklyEvents));
    }
    return cells;
  }

  renderTotals() {
    const { currentDate } = this.props;
    const range = getCurrentWeekRange(currentDate);
    const cells = [];
    //    console.log({totals: this.totals});
    for (let day of range.by('day')) {
      cells.push(
        <td className="status font-weight-bold" key={day.format('DMMY')}>
          <p>{this.totals[day.format('DMMY')]}</p>
        </td>,
      );
    }
    return cells;
  }

  renderCell(user, userSchedules, cellDate, workScheduleWeeklyEvents) {
    let day = cellDate.format('dddd');
    cellDate.set('hour', 12);

    const eventOnThisDay = doesUserHaveEventOnThisDay(workScheduleWeeklyEvents, cellDate, user);
    if (!this.totals[cellDate.format('DMMY')]) {
      this.totals[cellDate.format('DMMY')] = 0;
    }
    this.totals[cellDate.format('DMMY')] += userSchedules && typeof userSchedules[day.toLowerCase()] !== 'undefined' ? userSchedules[day.toLowerCase()] : 0;

    return (
      <EditableWorkScheduleCell
        eventOnThisDay={eventOnThisDay}
        key={`${user.id}_${cellDate.format('YYYY-MM-DD')}`}
        day={day}
        cellDate={cellDate}
        userSchedules={userSchedules}
        user={user}
      />
    );
  }

  render() {
    const { users = [], currentDate } = this.props;
    this.totals = [];
    if (users.length < 1) {
      return null;
    }
    const currentMonday = getCurrentWeekRange(currentDate).start;
    return (
      <Table hover className="mediaccounts-billing-checklist-table mb-0 d-none d-sm-table">
        {this.renderHeader()}
        <tbody>
          {users
            .sort((user1, user2) => {
              return user1.lastName.localeCompare(user2.lastName);
            })
            .map((user) => this.renderRow(user, currentMonday))}
          {!isOutsourcer(true) && (
            <tr className={'mediaccounts-work-schedule-totals'}>
              <th>Day Totals</th>
              {this.renderTotals()}
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default WorkScheduleTable;
