import React from 'react';
import ExportInvoiceNumbers from './ExportInvoiceNumbers';
import { downloadInvoiceNumbersExport } from '../../lib/helper/downloadHelper';

class ExportInvoiceNumbersContainer extends React.Component {
  render() {
    return <ExportInvoiceNumbers doExport={downloadInvoiceNumbersExport} />;
  }
}

export default ExportInvoiceNumbersContainer;
