import {
  USERS_LIST_REQUEST,
  USERS_LIST_RECEIVED,
  USERS_LIST_ERROR,
  USERS_LIST_SET_PAGE,
  USERS_LIST_SET_FILTERS,
  USERS_LIST_CLEAR_FILTERS,
  USERS_LIST_UNLOAD,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    users: [],
    isFetchingUsers: false,
    currentPage: 1,
    pageCount: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case USERS_LIST_UNLOAD:
      return {
        users: [],
        isFetchingUsers: false,
        currentPage: 1,
        pageCount: null,
        filters: {},
      };

    case USERS_LIST_REQUEST:
      return {
        ...state,
        isFetchingUsers: true,
      };

    case USERS_LIST_RECEIVED:
      return {
        ...state,
        users: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingUsers: false,
      };

    case USERS_LIST_ERROR:
      return {
        ...state,
        isFetchingUsers: false,
      };
    case USERS_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case USERS_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case USERS_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    default:
      return state;
  }
};

export default reducer;
