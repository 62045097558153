import {
  PROBLEM_DATA_ADDED,
  PROBLEM_DATA_SINGLE_ERROR,
  PROBLEM_DATA_SINGLE_RECEIVED,
  PROBLEM_DATA_SINGLE_REQUEST,
  PROBLEM_DATA_SINGLE_UNLOAD,
} from '../constants';

const reducer = (
  state = {
    problemData: null,
    isFetchingProblemData: false,
    error: false,
  },
  action,
) => {
  switch (action.type) {
    case PROBLEM_DATA_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingProblemData: true,
        error: false,
      };
    case PROBLEM_DATA_SINGLE_RECEIVED:
      return {
        ...state,
        problemData: action.data,
        error: false,
        isFetchingProblemData: false,
      };
    case PROBLEM_DATA_SINGLE_UNLOAD:
      return {
        ...state,
        problemData: null,
        error: false,
        isFetchingProblemData: false,
      };
    case PROBLEM_DATA_ADDED:
      return {
        ...state,
        problemData: action.data['hydra:member'],
        error: false,
        isFetchingProblemData: false,
      };
    case PROBLEM_DATA_SINGLE_ERROR:
      return {
        ...state,
        problemData: null,
        error: true,
        isFetchingProblemData: false,
      };
    default:
      return state;
  }
};

export default reducer;
