import React, { Component, Fragment } from 'react';
import { siteSettingSingleUpdate } from '../../../lib/redux/actions/siteSettings';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';

const mapDispatchToProps = {
  siteSettingSingleUpdate,
  addToast,
};

class EditableSettingTR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingValue: props.setting.value,
      hasChanged: false,
    };
  }

  onSave() {
    const { setting, siteSettingSingleUpdate, addToast } = this.props;
    const reset = () => {
      this.setState({ hasChanged: false });
    };
    siteSettingSingleUpdate({ value: String(this.state.settingValue) }, setting.id).then(() => {
      reset();
      addToast('"' + setting.name + '" Updated successfully', true);
    });
  }

  render() {
    const { setting } = this.props;
    return (
      <tr>
        <th>
          {setting.name}
          {setting?.helperText ? (
            <p>
              <small>
                {setting.helperText.split('\\n').map((item, key) => {
                  return (
                    <Fragment key={key}>
                      {item}
                      <br />
                    </Fragment>
                  );
                })}
              </small>
            </p>
          ) : null}
        </th>
        <td>
          {setting.type === 'int' ? (
            <input
              className="form-control"
              type={'number'}
              value={this.state.settingValue}
              onChange={(e) => {
                this.setState({ settingValue: e.target.value, hasChanged: true });
              }}
            />
          ) : setting.type === 'bool' ? (
            <input
              className="form-control"
              type={'checkbox'}
              checked={this.state.settingValue}
              onChange={(e) => {
                this.setState({ settingValue: !this.state.settingValue, hasChanged: true });
              }}
            />
          ) : (
            <textarea
              className="form-control"
              value={this.state.settingValue}
              onChange={(e) => {
                this.setState({ settingValue: e.target.value, hasChanged: true });
              }}
            />
          )}
        </td>
        <td width={'100'}>
          {this.state.hasChanged ? (
            <button onClick={this.onSave.bind(this)} className={`btn btn-danger btn-sm`}>
              Save
            </button>
          ) : null}
        </td>
      </tr>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditableSettingTR);
