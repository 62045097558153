import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { userResetPassword } from '../../../lib/redux/actions/user';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import * as qs from 'qs';
import * as ROUTES from '../../../lib/routing/frontend';
import { addToast } from '../../../lib/redux/actions/toast';
import { Link } from 'react-router-dom';

const mapDispatchToProps = {
  userResetPassword,
  addToast,
};

const mapStateToProps = (props, state) => {
  return {
    ...state,
    showPasswordResetLink: props.userSingle.showPasswordResetLink,
  };
};

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : {};
    if (props.user && props.user.email) {
      queryParams.email = props.user.email;
    }
    this.state = {
      showPass: false,
      isNew: Boolean(queryParams['isNew']),
      email: queryParams?.email ?? false,
      showPasswordResetLink: false,
    };

    props.initialize(queryParams);
  }

  toggleShow() {
    this.setState((prevState) => {
      return { showPass: !prevState.showPass };
    });
  }

  onSubmit(values) {
    const { addToast, userResetPassword } = this.props;
    return userResetPassword(values.email, values.resetCode, values.newPassword, values.retypedNewPassword).then(() => {
      addToast('Your password was reset successfully, please log in.', true);
      this.props.history.push(ROUTES.LOGIN);
    });
  }

  render() {
    const { handleSubmit, error, showPasswordResetLink } = this.props;
    const { isNew } = this.state;
    return (
      <Card className="login-card">
        <Card.Header>{isNew ? 'Account Setup' : 'Reset Password'}</Card.Header>
        <Card.Body>
          {isNew && (
            <p>
              Welcome to the MediAccounts Billing Portal
              <br />
              <br />
              Your login email address is <strong>{this.state.email}</strong>.<br />
              <br />
              Please set your password below.
            </p>
          )}
          {!isNew && <p>Please enter a new password to reset your password.</p>}
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field name="email" label="Email Address" groupClasses="d-none" type="email" component={renderField} />
            <Field name="resetCode" label="Code" type="text" disabled component={renderField} />
            <Field name="newPassword" label="New Password" type={this.state.showPass ? 'text' : 'password'} component={renderField} />
            <Field name="retypedNewPassword" label="Confirm Password" type={this.state.showPass ? 'text' : 'password'} component={renderField} />
            {/*<button type="button" className="btn btn-sm btn-outline-secondary" onClick={this.toggleShow.bind(this)}>{this.state.showPass ? 'Hide' : 'Show'} Password</button>*/}
            <button type="submit" className="btn btn-primary btn-big btn-block">
              Save Password
            </button>
            {showPasswordResetLink && (
              <p className="text-center mt-4">
                <Link to={ROUTES.FORGOT_PASSWORD}>Request new link</Link>
              </p>
            )}
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'ResetPasswordForm',
})(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm));
