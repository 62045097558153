import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { invoiceBatchAuditLogFetch } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import { niceDateTimeFormatter } from '../../lib/helper/formatting';

const mapStateToProps = (state) => ({
  invoiceBatchAuditLog: state.invoiceBatchSingle.invoiceBatchAuditLog,
});

const mapDispatchToProps = {
  invoiceBatchAuditLogFetch,
};

class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceBatch, invoiceBatchAuditLogFetch } = this.props;

    invoiceBatchAuditLogFetch(invoiceBatch.id);
  }

  render() {
    const { invoiceBatch, invoiceBatchAuditLog } = this.props;

    return (
      <Row>
        <Col>
          <p className="text-center text-uppercase font-weight-bold">Audit log</p>
          <Card body>
            <ul className="mb-0 list-unstyled audit-log-clamp">
              {invoiceBatchAuditLog &&
                invoiceBatchAuditLog.length > 0 &&
                invoiceBatchAuditLog.reverse().map((log) => (
                  <li key={log.id}>
                    {niceDateTimeFormatter(log.createdAt)} - {log.displayText}
                  </li>
                ))}
              <li>{niceDateTimeFormatter(invoiceBatch.createdAt)} - Invoice Batch Created</li>
            </ul>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
