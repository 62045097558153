import moment from 'moment';
import React from 'react';

export const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const daysOfTheWeekShortened = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const timeOfDayMap = { AM: 1, PM: 10 };

export const niceRolesFormatter = (roles, firstOnly = false, ignoreRoleUser = true) => {
  const niceRoleMap = {
    'Primary User': 'Consultant',
    'Subuser': 'Consultant Sub User',
  };
  const searchRegex = new RegExp(Object.keys(niceRoleMap).join('|'), 'gi');
  const niceRoles = roles
      .filter(r => !ignoreRoleUser || r !== 'ROLE_USER')
      .map(r => r.replace('ROLE_', '')
      .replace('_', ' ')
      .toLowerCase())
      .join(', ')
      .replace(/\b\w/g, l => l.toUpperCase())
    .replace(searchRegex, function (matched) {
      return niceRoleMap[matched];
    });
  if (firstOnly) {
    return niceRoles.split(',')[0];
  }
  return niceRoles;
};

export const niceDateFormatter = (utcDate, format = 'DD/MM/YYYY') => {
  return moment(utcDate).format(format);
};

export const niceClinicDateFormatter = (utcDate, format = 'DD MMM YYYY') => {
  return moment(utcDate).format(format);
};

export const niceDateTimeFormatter = (utcDate, format = 'DD/MM/YYYY HH:mm') => {
  return niceDateFormatter(utcDate, format);
};

export const userNameFormatter = (userObj, includeNumberOfProcessingInvoices = false, includeEmail = false) => {
  return `${userObj.firstName} ${userObj.lastName}${includeNumberOfProcessingInvoices ? ' (Currently Processing: ' + userObj.numberOfInvoicesCurrentlyProcessing + ')' : ''}${includeEmail ? ' (' + userObj.email + ')' : ''}`;
};

export const dateDiffFormatter = (utcDateFirst, utcDateSecond, format = 'days') => {
  let dateFirst = moment();
  if (utcDateFirst) {
    dateFirst = moment(utcDateFirst);
  }

  let dateSecond = moment();
  if (utcDateSecond) {
    dateSecond = moment(utcDateSecond);
  }

  return dateFirst.diff(dateSecond, 'days');
};

export const dayOfWeekFormatter = (dow) => {
  return daysOfTheWeek[dow] || '';
};

export const dayOfWeekShortenedFormatter = (dow) => {
  return daysOfTheWeekShortened[dow] || '';
};

export const timeOfDayText = (timeOfDay) => {
  return timeOfDay === timeOfDayMap.AM ? 'AM' : 'PM';
};

export const generalCurrencyFormatter = (cost, currencySymbol = '£') => {
  return currencySymbol + Number(cost).toFixed(2);

  // should probably be:
  //  return Intl.NumberFormat('en-GB', {
  //    style: 'currency',
  //    currency: 'GBP',
  //  }).format(cost)
};

export const invoiceAmountFormatter = (cost, currencySymbol) => {
  //  invoice amount and outstanding balance stored in small currency so converting to big before formatting
  return generalCurrencyFormatter(cost / 100, currencySymbol);
};

export const capitalizeWords = (strToCapitalize) => {
  return strToCapitalize
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const invoiceBatchIdFromIri = (iri) => {
  return iri ? Number(iri.replace('/invoice_batches/', '')) : false;
};

export const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

export const getSortIcon = (sortDir) => {
  return sortDir ? (
    sortDir === 'DESC' ? (
      <i className={'fa fa-sort-down pl-2'}></i>
    ) : (
      <i className={'fa fa-sort-up pl-2'}></i>
    )
  ) : (
    <i className={'fa fa-sort pl-2'}></i>
  );
};
