import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import {
  ONBOARDING_ITEMS_LIST_RECEIVED,
  ONBOARDING_ITEMS_LIST_RECEIVED_ERROR,
  ONBOARDING_ITEMS_LIST_REQUEST,
  ONBOARDING_ITEMS_LIST_UNLOAD,
  ONBOARDING_ITEMS_PATCH_ERROR,
  ONBOARDING_ITEMS_PATCH_RECEIVED,
} from '../constants';

export const onboardingItemsListRequest = () => ({
  type: ONBOARDING_ITEMS_LIST_REQUEST,
});

export const onboardingItemsListReceived = (data) => ({
  type: ONBOARDING_ITEMS_LIST_RECEIVED,
  data,
});

export const onboardingItemsListReceivedError = (error) => ({
  type: ONBOARDING_ITEMS_LIST_RECEIVED_ERROR,
  error,
});

export const onboardingItemsListUnload = () => ({
  type: ONBOARDING_ITEMS_LIST_UNLOAD,
});

export const onboardingItemsPatchError = (error) => ({
  type: ONBOARDING_ITEMS_PATCH_ERROR,
  error,
});

export const onboardingItemsListPatchReceieved = (data) => ({
  type: ONBOARDING_ITEMS_PATCH_RECEIVED,
  data,
});

export const onboardingItemsListFetch = (id) => {
  return (dispatch) => {
    dispatch(onboardingItemsListRequest());
    return requests
      .get(`${API_ROUTES.ONBOARDING_ITEMS.replace(':id', id)}`)
      .then((response) => {
        dispatch(onboardingItemsListReceived(response));
        return response;
      })
      .catch((error) => {
        dispatch(onboardingItemsListReceivedError(error));
      });
  };
};

export const onboardingItemsListPatch = (formValues) => {
  //  console.log(formValues)
  return (dispatch) => {
    dispatch(onboardingItemsListRequest());
    return requests
      .post(API_ROUTES.ONBOARDING_ITEMS_UPDATE, formValues)
      .then((response) => {
        dispatch(onboardingItemsListPatchReceieved(response));
        return response;
      })
      .catch((error) => {
        dispatch(onboardingItemsPatchError(error));
      });
  };
};
