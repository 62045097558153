import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { consultantListClearFilters, consultantListSetFilters } from '../../../lib/redux/actions/consultant';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';
import PrimaryUserTypeahead from '../../fields/PrimaryUserTypeahead';
import MASpinner from '../../common/MASpinner';
import { consultantTypeListFetch } from '../../../lib/redux/actions/consultantType';

const mapStateToProps = (state) => ({
  ...state.consultantList,
  ...state.consultantTypes,
});
const mapDispatchToProps = {
  consultantListSetFilters,
  consultantListClearFilters,
  consultantTypeListFetch,
};

class ConsultantTableFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConsultants: [],
      selectedPrimaryUsers: [],
      clearTypeaheads: false,
    };
  }

  componentDidMount() {
    this.props.consultantTypeListFetch();
  }

  onSubmit(values) {
    if (this.state.selectedConsultants.length) {
      values['id'] = this.state.selectedConsultants.map((c) => c['@id']);
    }
    if (this.state.selectedPrimaryUsers.length) {
      values['primaryUser'] = this.state.selectedPrimaryUsers.map((u) => u['@id']);
    }
    this.props.consultantListSetFilters(values);
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  resetFilters() {
    const { reset, consultantListClearFilters } = this.props;
    consultantListClearFilters();
    reset();
    this.clearTypeaheads();
  }

  render() {
    const { handleSubmit, error, isFetchingConsultantTypes, consultantTypes = [] } = this.props;
    const consultantStatuses = [
      { value: 1, text: 'Active' },
      { value: 0, text: 'InActive' },
    ];
    if (isFetchingConsultantTypes) {
      return <MASpinner />;
    }
    const typeOptions = [];
    consultantTypes.forEach((consultantType) => {
      typeOptions.push({ value: consultantType['@id'], text: consultantType.displayName });
    });
    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <ConsultantTypeahead
            shouldClear={this.state.clearTypeaheads}
            hasCleared={this.typeaheadCleared.bind(this)}
            multiple={false}
            label={'Account Name'}
            onChange={(selected) => {
              this.setState(() => {
                return { selectedConsultants: selected };
              });
            }}
          />

          <PrimaryUserTypeahead
            shouldClear={this.state.clearTypeaheads}
            hasCleared={this.typeaheadCleared.bind(this)}
            multiple={false}
            selectedUsers={this.state.selectedPrimaryUsers}
            onChange={(selected) => {
              this.setState(() => {
                return { selectedPrimaryUsers: selected };
              });
            }}
          />

          <Field name="consultantType" label="Consultant Type" type="select" options={typeOptions} component={renderSelect} />

          <Field name="isActive" label="Status" options={consultantStatuses} component={renderSelect} />

          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Filter
            </button>
            <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
              Reset
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ConsultantTableFilterForm',
})(connect(mapStateToProps, mapDispatchToProps)(ConsultantTableFilterForm));
