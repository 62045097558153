import React from 'react';
import { FormCheck } from 'react-bootstrap';
import { connect } from 'react-redux';
import { consultantHealthInsurerSingleUpdate } from '../../lib/redux/actions/consultantHealthInsurer';
import PropTypes from 'prop-types';
import { Cell } from 'react-sticky-table';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import { niceDateFormatter } from '../../lib/helper/formatting';

const mapDispatchToProps = {
  consultantHealthInsurerSingleUpdate,
};

class EditableFeeAssuredCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldValue: Boolean(props.consultantHealthInsurer.feeAssured),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.consultantHealthInsurer &&
      this.props.consultantHealthInsurer &&
      prevProps.consultantHealthInsurer.feeAssured !== this.props.consultantHealthInsurer.feeAssured
    ) {
      this.setState(() => ({
        fieldValue: Boolean(this.props.consultantHealthInsurer.feeAssured),
      }));
    }
  }

  handleChange(event) {
    const { reloadCodes } = this.props;
    const feeAssured = event.target.checked;
    if (!isSeniorStaff()) {
      return;
    }

    this.props
      .consultantHealthInsurerSingleUpdate({ feeAssured }, this.props.consultantHealthInsurer.id)
      .then(() => {
        this.props.consultantHealthInsurer.feeAssured = feeAssured;
        this.setState(() => ({ fieldValue: feeAssured }));
      })
      .then(() => {
        reloadCodes();
      });
  }

  render() {
    return (
      <Cell
        className="text-center border-right"
        title={`${this.props.consultantHealthInsurer.healthInsurer.name}: ${this.state.fieldValue ? '' : 'NOT '}Fee Assured since ${
          this.props.consultantHealthInsurer.feeAssuredLastChangeDate
            ? niceDateFormatter(this.props.consultantHealthInsurer.feeAssuredLastChangeDate)
            : niceDateFormatter(this.props.consultantCreatedDate) + ' (Consultant created)'
        }`}>
        <FormCheck
          type="switch"
          onChange={this.handleChange.bind(this)}
          id={`default-feeAssured-${this.props.consultantHealthInsurer.id}`}
          checked={this.state.fieldValue}
          disabled={!isSeniorStaff()}
          title={`${this.props.consultantHealthInsurer.healthInsurer.name}: ${this.state.fieldValue ? '' : 'NOT '}Fee Assured since ${this.props.consultantHealthInsurer.feeAssuredLastChangeDate ? niceDateFormatter(this.props.consultantHealthInsurer.feeAssuredLastChangeDate) : niceDateFormatter(this.props.consultantCreatedDate) + ' (Consultant created)'}`}
        />
      </Cell>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditableFeeAssuredCell);

EditableFeeAssuredCell.propTypes = {
  consultantHealthInsurer: PropTypes.object.isRequired,
};
