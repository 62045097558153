import Form from 'react-bootstrap/Form';
import React from 'react';
import FileUploader from '../../file/FileUploader';
import FileBrowser from '../../file/FileBrowser';
import { fileClearReferences, fileDelete, fileUpload } from '../../../lib/redux/actions/invoiceBatchFiles';
import { invoiceBatchSingleUpdate } from '../../../lib/redux/actions/invoiceBatch';
import { addToast } from '../../../lib/redux/actions/toast';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { isSubmitted } from '../../../lib/helper/invoiceBatch';
import { invoiceBatchStatusListFetch } from '../../../lib/redux/actions/invoiceBatchStatus';
import INVOICE_BATCH_STATUS from '../../../lib/helper/statusCodes';
import MASpinner from '../../common/MASpinner';

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    ...state.invoiceBatchFiles,
    ...state.invoiceBatchStatusList,
  };
}

const mapDispatchToProps = {
  fileDelete,
  fileClearReferences,
  invoiceBatchSingleUpdate,
  fileUpload,
  addToast,
  invoiceBatchStatusListFetch,
};

class CheckedAndPricedFileUploadForm extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceBatchStatusListFetch } = this.props;
    invoiceBatchStatusListFetch();
  }

  onFilesAdded(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      this.props.fileUpload(e.target.files[i]);
    }
  }

  onSubmit(values) {
    const {
      invoiceBatchSingleUpdate,
      invoiceBatch,
      invoiceBatchStatuses,
      fileClearReferences,
      temporaryFiles,
      addToast,
      onFilesUploaded,
      onUploadStart,
      appendFiles = false,
    } = this.props;

    onUploadStart();

    const data = {
      numberOfInvoicesToProcess: Number(values.numberOfInvoices),
    };
    if (temporaryFiles.length > 0) {
      data.temporaryInvoiceFilesToAttach = temporaryFiles.map((tempFile) => tempFile['id']);
    }
    if (isSubmitted(invoiceBatch)) {
      data.status = invoiceBatchStatuses.find((x) => Number(x.code) === Number(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED))['@id'];
    }

    return invoiceBatchSingleUpdate(data, invoiceBatch.id, true, appendFiles).then(() => {
      fileClearReferences();
      addToast('Checked and Priced Files successfully uploaded', true);
      if (onFilesUploaded) {
        onFilesUploaded();
      }
    });
  }

  render() {
    const { temporaryFiles, fileDelete, invoiceBatch, fileRequestInProgress, isFetchingInvoiceBatchStatuses, handleSubmit, pristine, submitting, error } =
      this.props;

    // ensure we have the statuses so we can updated onsubmit
    if (isFetchingInvoiceBatchStatuses) {
      return <MASpinner />;
    }
    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-upload" />
        </div>
        <h2 className="invoice-batch-single__section-header">
          {invoiceBatch.noFilesSupplied ? 'Confirm Number of Invoices' : 'Upload Checked and Priced Files'}
        </h2>
        <div className="invoice-batch-single__uploader">
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <>
              <FileUploader onFilesAdded={this.onFilesAdded.bind(this)} requestInProgress={fileRequestInProgress} />
              <FileBrowser files={temporaryFiles} deleteHandler={fileDelete} isLocked={fileRequestInProgress} />
            </>

            <Field name="numberOfInvoices" label="Number of invoices to process" id="numberOfInvoices" type="number" component={renderField} />

            <button
              type="submit"
              disabled={pristine || submitting || fileRequestInProgress || (temporaryFiles.length < 1 && !invoiceBatch.noFilesSupplied)}
              className="btn btn-primary">
              {invoiceBatch.noFilesSupplied ? 'Confirm' : 'Upload Files'}
            </button>
          </Form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'CheckedAndPricedFileUploadForm',
})(connect(mapStateToProps, mapDispatchToProps)(CheckedAndPricedFileUploadForm));
