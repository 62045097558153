import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { billingChecklistEventListClearFilters, billingChecklistEventListSetFilters } from '../../../lib/redux/actions/billingChecklistEvents';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';

const mapStateToProps = (state) => ({
  ...state.consultantList,
});
const mapDispatchToProps = {
  billingChecklistEventListSetFilters,
  billingChecklistEventListClearFilters,
};

class BillingChecklistEventTableFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConsultants: [],
      selectedPrimaryUsers: [],
      clearTypeaheads: false,
    };
  }

  onSubmit(values) {
    if (this.state.selectedConsultants.length) {
      values['consultant.id'] = this.state.selectedConsultants.map((c) => c['@id']);
    }

    this.props.billingChecklistEventListSetFilters(values);
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  resetFilters() {
    const { reset, billingChecklistEventListClearFilters } = this.props;
    billingChecklistEventListClearFilters();
    reset();
    this.clearTypeaheads();
  }

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <ConsultantTypeahead
            shouldClear={this.state.clearTypeaheads}
            hasCleared={this.typeaheadCleared.bind(this)}
            multiple={false}
            label={'Account Name'}
            onChange={(selected) => {
              this.setState(() => {
                return { selectedConsultants: selected };
              });
            }}
          />

          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Filter
            </button>
            <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
              Reset
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'BillingChecklistEventTableFilterForm',
})(connect(mapStateToProps, mapDispatchToProps)(BillingChecklistEventTableFilterForm));
