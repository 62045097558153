import {
  INVOICE_BATCH_BILLING_CHECKLIST_REQUEST,
  INVOICE_BATCH_BILLING_CHECKLIST_RECEIVED,
  INVOICE_BATCH_BILLING_CHECKLIST_ERROR,
  INVOICE_BATCH_BILLING_CHECKLIST_UNLOAD,
  INVOICE_BATCH_BILLING_CHECKLIST_SET_DATE,
} from '../constants';

import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';
import moment from 'moment';

export const billingChecklistRequest = () => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_REQUEST,
});

export const billingChecklistReceived = (data) => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_RECEIVED,
  data,
});

export const billingChecklistError = (error) => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_ERROR,
  error,
});

export const billingChecklistUnload = () => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_UNLOAD,
});

export const billingChecklistSetDate = (date) => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_SET_DATE,
  date,
});

export const billingChecklistFetch = (date = moment().utc().format('DD-MM-YYYY')) => {
  return (dispatch) => {
    dispatch(billingChecklistRequest());
    //console.log('Fetching billing checklist data ...');
    return requests
      .get(`${API_ROUTES.BILLING_CHECKLIST}`, { date, pagination: false })
      .then((response) => dispatch(billingChecklistReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(billingChecklistError(error));
      });
  };
};
