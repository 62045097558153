import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { PAYMENT_LINK_ERROR, PAYMENT_LINK_RECEIVED, PAYMENT_LINK_REQUEST } from '../constants';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';

export const paymentLinkRequest = () => ({
  type: PAYMENT_LINK_REQUEST,
});

export const paymentLinkReceived = (data) => ({
  type: PAYMENT_LINK_RECEIVED,
  data,
});

export const paymentLinkError = (error) => ({
  type: PAYMENT_LINK_ERROR,
  error,
});

export const generatePaymentLink = (invoiceId, formValues) => {
  return (dispatch) => {
    dispatch(paymentLinkRequest());
    return requests
      .post(API_ROUTES.PAYMENT_LINK.replace(':invoiceId', invoiceId), formValues)
      .then((response) => dispatch(paymentLinkReceived(response)))
      .catch((error) => {
        if (error && error.response && error.response.body) {
          let paymentLinkErrorResponse = error.response.body;
          dispatch(paymentLinkError({ error: paymentLinkErrorResponse }));
        }
        dispatch(paymentLinkError(error));
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
