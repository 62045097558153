import {
  ONBOARDING_ITEMS_LIST_RECEIVED,
  ONBOARDING_ITEMS_LIST_RECEIVED_ERROR,
  ONBOARDING_ITEMS_LIST_REQUEST,
  ONBOARDING_ITEMS_LIST_UNLOAD,
  ONBOARDING_ITEMS_PATCH_RECEIVED,
} from '../constants';

const reducer = (
  state = {
    onboardingItems: [],
    isFetchingOnboardingItems: false,
    error: false,
  },
  action,
) => {
  switch (action.type) {
    case ONBOARDING_ITEMS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ONBOARDING_ITEMS_LIST_RECEIVED:
      return {
        ...state,
        isLoading: false,
        onboardingItems: action.data['hydra:member'],
      };
    case ONBOARDING_ITEMS_PATCH_RECEIVED:
      return {
        ...state,
        isLoading: false,
        onboardingItems: action.data['hydra:member'],
      };
    case ONBOARDING_ITEMS_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isLoading: false,
        onboardingItems: [],
      };
    case ONBOARDING_ITEMS_LIST_UNLOAD:
      return {
        ...state,
        isLoading: false,
        onboardingItems: [],
      };
    default:
      return state;
  }
};

export default reducer;
