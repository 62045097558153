import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import { CONSULTANT_TICKET_TYPE_LIST_REQUEST, CONSULTANT_TICKET_TYPE_LIST_RECEIVED, CONSULTANT_TICKET_TYPE_LIST_ERROR } from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantTicketTypeListRequest = () => ({
  type: CONSULTANT_TICKET_TYPE_LIST_REQUEST,
});

export const consultantTicketTypeListReceived = (data) => ({
  type: CONSULTANT_TICKET_TYPE_LIST_RECEIVED,
  data,
});

export const consultantTicketTypeListError = (error) => ({
  type: CONSULTANT_TICKET_TYPE_LIST_ERROR,
  error,
});

export const consultantTicketTypeListFetch = () => {
  return (dispatch) => {
    dispatch(consultantTicketTypeListRequest());
    const filters = { pagination: false };
    return requests
      .get(API_ROUTES.CONSULTANT_TICKET_TYPE, filters)
      .then((response) => dispatch(consultantTicketTypeListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantTicketTypeListError(error));
      });
  };
};
