import { CONSULTANT_TYPE_LIST_REQUEST, CONSULTANT_TYPE_LIST_RECEIVED, CONSULTANT_TYPE_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    consultantTypes: [],
    isFetchingConsultantTypes: false,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_TYPE_LIST_REQUEST:
      return {
        ...state,
        isFetchingConsultantTypes: true,
      };

    case CONSULTANT_TYPE_LIST_RECEIVED:
      return {
        ...state,
        consultantTypes: action.data['hydra:member'],
        isFetchingConsultantTypes: false,
      };

    case CONSULTANT_TYPE_LIST_ERROR:
      return {
        ...state,
        isFetchingConsultantTypes: false,
      };
    default:
      return state;
  }
};

export default reducer;
