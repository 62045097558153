import React from 'react';
import Form from 'react-bootstrap/Form';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import { renderSelect} from 'lib/helper/form';
import MASpinner from '../../common/MASpinner';
import {connect} from 'react-redux';
import {addToast} from 'lib/redux/actions/toast';
import {requests} from '../../../lib/helper/agent';
import * as API_ROUTES from '../../../lib/routing/api';
import {invoiceSingleFetch} from "../../../lib/redux/actions/invoices";

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = {
    addToast,
    invoiceSingleFetch
};

const outcomeOptions = [{value: 'Contact Made', text: 'Contact Made'}, {value: 'Message Left', text: 'Message Left'}, {value: 'No Answer', text: 'No Answer'}];

class ManualPhoneCallForm extends React.Component {
    onSubmit(values) {
        const {invoice, addToast, reset, invoiceSingleFetch} = this.props;
        if (!invoice?.contactRecords.some((c) => Number(c.id) === Number(values['contactRecord']))) {
            return new SubmissionError({contactRecord: 'Please select a contact'});
        }
        if (!values['outcome']) {
            return new SubmissionError({outcome: 'Please select an outcome'});
        }
        const data = {
            contactId: Number(values['contactRecord']),
            outcome: values.outcome
        };
        requests.post(API_ROUTES.INVOICE_MANUAL_PHONE_CALL.replace(':id', invoice.id), data).then((response) => {
            addToast('Manual payment recorded successfully', true);
            reset();
            invoiceSingleFetch(Number(invoice.id));
        });
    }

    render() {
        const {handleSubmit, isUpdatingInvoice = false, submitting, pristine, invoice, reset} = this.props;

        const contactOptions = [];
        invoice?.contactRecords?.forEach((contactRecord) => {
            contactOptions.push({value: contactRecord.id, text: contactRecord.name});
        });

        return (
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className="row">
                        <div className="col-6">
                            <Field required id={'contactRecord'} name={'contactRecord'} label={'Contact Called'} component={renderSelect} options={contactOptions}/>
                        </div>

                        <div className="col-6">
                            <Field required id={'outcome'} name={'outcome'} label={'Outcome'} component={renderSelect} options={outcomeOptions}/>
                        </div>
                    </div>
                    {isUpdatingInvoice ? <MASpinner/> : null}
                    {!isUpdatingInvoice && !pristine ? (
                            <>
                                <button type="submit" disabled={submitting || pristine} className="btn btn-primary mr-2">
                                    Record Call
                                </button>
                                <button type="reset" disabled={submitting} className="btn btn-secondary" onClick={() => reset()}>
                                    Reset
                                </button>
                            </>
                    ) : null}
                </Form>
        );
    }
}

export default connect(
        mapStateToProps,
        mapDispatchToProps,
)(
        reduxForm({
            form: 'ManualPhoneCallForm',
            enableReinitialize: true,
            destroyOnUnmount: false,
        })(ManualPhoneCallForm),
);
