import React from 'react';
import Table from 'react-bootstrap/Table';
import clsx from 'clsx';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import { niceDateFormatter } from '../../lib/helper/formatting';
import AcceptButton from '../../components/common/AcceptButton';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { isClient } from '../../lib/helper/authorisation';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import { getNumberOfInvoices, hasDiscrepantInvoiceCounts } from '../../lib/helper/invoiceBatch';
import Badge from 'react-bootstrap/Badge';
import { renderCheckbox } from '../../lib/helper/form';
import Form from 'react-bootstrap/Form';

class EmbassyInvoiceBatchListTable extends React.Component {
  render() {
    const { invoiceBatches = [], showBulkSelect = false, bulkSelectAction = () => {}, bulkSelectedInvoiceBatches = [] } = this.props;
    if (!invoiceBatches || invoiceBatches.length === 0) {
      return (
        <Card.Body>
          <Alert variant={'info'} className="mb-0">
            No invoices found
          </Alert>
        </Card.Body>
      );
    }
    return (
      <div>
        <Table hover responsive className="invoice-batch-list-table">
          <thead>
            <tr>
              {showBulkSelect && <th>Select</th>}
              <th style={{ minWidth: '80px' }}>Batch ID</th>
              <th>Uploaded Date</th>
              <th>Account Name</th>
              <th>Invoice Date(s)</th>
              <th>Status</th>
              {!showBulkSelect && !isClient() && <th className="text-center"># Inv</th>}
              {!isClient() && <th className="text-center">Chaser Notes</th>}
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {invoiceBatches &&
              invoiceBatches.map((invoiceBatch) => {
                const isBulkSelected = bulkSelectedInvoiceBatches.some((batchId) => batchId === invoiceBatch.id);
                const rowClasses = clsx({
                  'table-row--problem': !isClient() && invoiceBatch.isProblem,
                  'table-row--urgent': invoiceBatch.isUrgent,
                  'table-row--completed': Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.COMPLETED),
                  'table-row--cancelled': Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.CANCELLED),
                  'table-row--selected': isBulkSelected,
                });
                const noteClasses = clsx({ 'h5 text-center': true, 'has-notes': invoiceBatch.invoiceBatchNotesCount > 0 });
                const invoiceCounterClasses = clsx({
                  'h5 text-center': true,
                  'has-notes': !isNaN(getNumberOfInvoices(invoiceBatch)),
                  'has-discrepancy': hasDiscrepantInvoiceCounts(invoiceBatch),
                });
                const statusStyle = clsx({ 'status--completed': Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.COMPLETED) });
                return (
                  <tr key={invoiceBatch.id} className={rowClasses}>
                    {showBulkSelect && (
                      <td>
                        <Form.Check
                          type={'switch'}
                          name={`invoiceBatches.${invoiceBatch.id}`}
                          id={`invoiceBatches.${invoiceBatch.id}`}
                          onChange={() => bulkSelectAction(invoiceBatch)}
                          component={renderCheckbox}
                          className="mb-4"
                          checked={isBulkSelected}
                        />
                      </td>
                    )}
                    <td>
                      <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', invoiceBatch.id)} className="text-body text-nowrap">
                        <span className="mr-2">{invoiceBatch.id}</span>
                        {!isClient() && invoiceBatch.isProblem && <i className="fas fa-exclamation-circle text-danger" title="Problem invoice" />}{' '}
                        {invoiceBatch.isUrgent && <i className="fas fa-clock text-warning" title="Urgent invoices" />}
                        {invoiceBatch.isEmbassy && (
                          <>
                            <br />
                            <Badge variant={'danger'} className="text-uppercase">
                              Embassy
                            </Badge>
                          </>
                        )}
                      </Link>
                    </td>
                    {showBulkSelect && !isClient() && (
                      <td className={invoiceCounterClasses}>
                        <span className="note-count-wrapper">
                          <span className={`badge badge-pill ${isBulkSelected ? 'badge-primary' : 'badge-secondary'}`} title="Invoices to process">
                            {String(getNumberOfInvoices(invoiceBatch))}
                          </span>
                        </span>
                      </td>
                    )}
                    <td>{niceDateFormatter(invoiceBatch.createdAt)}</td>
                    <td className="font-weight-bold">{invoiceBatch.consultant && `${invoiceBatch.consultant.name} (${invoiceBatch.consultant.code})`}</td>
                    <td>
                      {invoiceBatch.invoiceDates && invoiceBatch.invoiceDates.length > 0 && (
                        <ul className="list-unstyled clinic-sessions mb-0">
                          {invoiceBatch.invoiceDates.map((invoiceDate) => (
                            <li key={invoiceDate.id}>
                              {niceDateFormatter(invoiceDate.date, 'ddd')} <strong>{niceDateFormatter(invoiceDate.date)}</strong>{' '}
                              <span className="clinic-session__day-half">({invoiceDate.timeOfDayText})</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                    <td className={statusStyle}>
                      {invoiceBatch.status.name}
                      {Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.COMPLETED) && (
                        <i className="fa fa-check-circle text-primary ml-1" title="Completed" />
                      )}
                    </td>
                    {!showBulkSelect && !isClient() && (
                      <td className={invoiceCounterClasses}>
                        <span className="note-count-wrapper">
                          <span className={`badge badge-pill ${isBulkSelected ? 'badge-primary' : 'badge-secondary'}`} title="Invoices to process">
                            {String(getNumberOfInvoices(invoiceBatch))}
                          </span>
                        </span>
                      </td>
                    )}
                    {!isClient() && (
                      <td className={noteClasses}>
                        <span className="note-count-wrapper">
                          <span className="badge badge-pill badge-danger" title="Note count">
                            {invoiceBatch.invoiceBatchNotesEmbassyChaserCount}
                          </span>
                        </span>
                      </td>
                    )}
                    <td className="text-sm-left text-md-right">
                      <AcceptButton invoiceBatch={invoiceBatch} />
                      <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', invoiceBatch.id)} className="btn btn-sm btn-primary ml-2">
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default EmbassyInvoiceBatchListTable;
