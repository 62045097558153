import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import AccountDetailsForm from '../../components/forms/Consultant/AccountDetailsForm';

class ConsultantSingleCreate extends React.Component {
  render() {
    const { onCreate } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="crud__meta">
                    <Link to={ROUTES.CONSULTANTS.ALL.replace('/:page', '')}>&larr; Back to Consultant Accounts</Link>
                  </span>
                  <span className="ml-auto crud__statuses"></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="crud__body-title">Create a Consultant Account</h1>
                  </Col>
                </Row>
                <hr />
                <Row>
                  {/* Left Column - START */}
                  <Col sm={12} md={12} lg={8}>
                    {/* CRUD Main Form - Start */}
                    <Row>
                      <Col>
                        <AccountDetailsForm onCreate={onCreate} isCreate={true} />
                      </Col>
                    </Row>
                    {/* CRUD Main Form - END */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantSingleCreate;
