import { SUB_USER_SINGLE_REQUEST, SUB_USER_SINGLE_ERROR, SUB_USER_SINGLE_RECEIVED } from '../constants';

const reducer = (
  state = {
    isFetchingSubUser: false,
  },
  action,
) => {
  switch (action.type) {
    case SUB_USER_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingSubUser: true,
      };
    case SUB_USER_SINGLE_RECEIVED:
      return {
        ...state,
        isFetchingSubUser: false,
      };

    case SUB_USER_SINGLE_ERROR:
      return {
        ...state,
        isFetchingSubUser: false,
      };

    default:
      return state;
  }
};

export default reducer;
