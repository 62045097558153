import React, { Fragment, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { invoiceAmountFormatter, userNameFormatter } from '../../lib/helper/formatting';
import { connect } from 'react-redux';
import BillingDetailsForm from './BillingDetailsForm';
import InvoicePDFFileDownloadButton from '../../components/invoice/InvoicePDFFileDownloadButton';
import { Col, Row } from 'react-bootstrap';

const YourInvoice = ({ invoice, isFetchingInvoice, showWorldPayIframe = false }) => {
  const [showAddBillingAddressForm, setShowAddBillingAddressForm] = useState(false);
  if (isFetchingInvoice) {
    return <>Loading...</>;
  }

  if (!invoice) {
    return null;
  }

  return (
    <Fragment>
      <Card className="patient-invoice-details">
        <Card.Header as={'h2'}>Invoice Details</Card.Header>
        <Card.Body>
          <table className={'table'}>
            <tr>
              <td className={'text-left border-top-0'}>Patient:</td>
              <th className={'text-right border-top-0'}>{userNameFormatter({ firstName: invoice?.patientFirstName, lastName: invoice?.patientLastName })}</th>
            </tr>
            {invoice.consultant && (
              <tr>
                <td className={'text-left'}>Consultant:</td>
                <th className={'text-right'}>{invoice.consultant.name}</th>
              </tr>
            )}
            <tr>
              <td className={'text-left'}>Invoice:</td>
              <th className={'text-right'}>{invoice.invoiceNo}</th>
            </tr>
            <tr>
              <td className={'text-left'}>Balance to pay:</td>
              <th className={'text-right'}>
                {invoice.isPaid && invoice.outstandingBalance <= 0 ? `Paid in full` : `O/S ${invoiceAmountFormatter(invoice.outstandingBalance)}`}
              </th>
            </tr>
          </table>
          {/*<p className="patient-name">Patient:</p>*/}
          {/*{invoice.consultant ? <p className="consultant-name">Consultant: {invoice.consultant.name}</p> : null}*/}
          {/*<div className="invoice-table">*/}
          {/*  <p className="invoice-number">Invoice {invoice.invoiceNo}</p>*/}
          {/*  {invoice.isPaid && invoice.outstandingBalance <= 0 ? (*/}
          {/*    <p className="balance-paid">Paid in full</p>*/}
          {/*  ) : (*/}
          {/*    <p className="outstanding-balance">O/S {invoiceAmountFormatter(invoice.outstandingBalance)}</p>*/}
          {/*  )}*/}
          <Row>
            {!showWorldPayIframe && invoice.outstandingBalance > 0 && !showAddBillingAddressForm ? (
              <>
                <Col sm={12} md={6} className={'mb-2 text-center'}>
                  <button onClick={() => setShowAddBillingAddressForm(true)} className="btn btn-primary">
                    Pay Invoice
                  </button>
                </Col>
                <Col sm={12} md={6} className={'text-center'}>
                  <InvoicePDFFileDownloadButton invoice={invoice} overrideClassStr={'btn btn-primary'} btnText={'Download Invoice'} />
                </Col>
              </>
            ) : (
              <Col sm={12} className={'text-center'}>
                <InvoicePDFFileDownloadButton invoice={invoice} overrideClassStr={'btn btn-primary'} btnText={'Download Invoice'} />
              </Col>
            )}
            {/*</div>*/}
          </Row>
        </Card.Body>
      </Card>
      {showAddBillingAddressForm ? <BillingDetailsForm invoice={invoice} /> : null}
      {!showWorldPayIframe && !invoice.isPaid && invoice.outstandingBalance > 0 && (
        <Card className="my-4">
          <Card.Header as={'h2'}>Other ways to pay</Card.Header>
          <Card.Body className={'text-center'}>
            <p>Payment Instructions: Pay by BACS</p>
            {invoice && invoice.consultant && (
              <>
                {invoice?.consultant?.name ? <p>Payee: MediAccounts Ltd {invoice.consultant.name} Client Deposit Manager</p> : null}
                {invoice?.consultant?.clientSortCode ? <p>Sort Code: {invoice.consultant.clientSortCode}</p> : null}
                {invoice?.consultant?.clientAccountNumber ? <p>Account Number: {invoice?.consultant?.clientAccountNumber}</p> : null}
              </>
            )}
            <p>PLEASE NOTE THIS IS A BUSINESS ACCOUNT</p>
            <p>
              Reference: Please use the Invoice No. <strong>{invoice.invoiceNo}</strong>
            </p>
            <p>
              Please call 020 3637 0038 or go to the website{' '}
              <a target={'_blank'} href="https://www.mediaccounts.co.uk/" rel="noreferrer">
                https://www.mediaccounts.co.uk/
              </a>
            </p>
            <p>Cheques are no longer accepted.</p>
            <p>Payment terms: Please note payment is now due.</p>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.invoice,
    ...state.isFetchingInvoice,
  };
};

export default connect(mapStateToProps)(YourInvoice);
