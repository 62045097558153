import { extendMoment } from 'moment-range';
import Moment from 'moment';

const moment = extendMoment(Moment);

export function isToday(date, today) {
  return date.isSame(today, 'day');
}

export function getCurrentWeekRange(currentDate) {
  const monday = moment(currentDate, 'DD-MM-YYYY').startOf('isoWeek').isoWeekday(1);
  const sunday = moment(currentDate, 'DD-MM-YYYY').endOf('isoWeek').isoWeekday(7);
  return moment.range(monday, sunday);
}

export function slotDOWConverted(dow) {
  return Number(dow) === 7 ? 0 : Number(dow);
}
