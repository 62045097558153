import { INVOICE_BATCH_WEEKLY_COUNTS_UPDATED } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceBatchWeeklyCountsUpdated = (invoiceBatchWeeklyCounts) => ({
  type: INVOICE_BATCH_WEEKLY_COUNTS_UPDATED,
  invoiceBatchWeeklyCounts,
});

export const getInvoiceBatchWeeklyCounts = (filters = {}) => {
  return (dispatch) => {
    return requests
      .get(`${API_ROUTES.INVOICE_BATCH_WEEKLY_COUNTS}`, filters)
      .then((response) => dispatch(invoiceBatchWeeklyCountsUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
      });
  };
};
