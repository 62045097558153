import { INVOICE_ACTION_STATS_RECEIVED, INVOICE_ACTION_STATS_REQUEST } from '../constants';

const reducer = (
  state = {
    invoiceActionStats: [],
    isFetchingInvoiceActionStats: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_ACTION_STATS_RECEIVED:
      return {
        ...state,
        invoiceActionStats: action.invoiceActionStats,
        isFetchingInvoiceActionStats: false,
      };

    case INVOICE_ACTION_STATS_REQUEST:
      return {
        ...state,
        invoiceActionStats: [],
        isFetchingInvoiceActionStats: true,
      };

    default:
      return state;
  }
};

export default reducer;
