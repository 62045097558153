import {
  PROBLEM_DATA_INSURER_LIST_ERROR,
  PROBLEM_DATA_INSURER_LIST_RECEIVED,
  PROBLEM_DATA_INSURER_LIST_REQUEST,
  PROBLEM_DATA_INSURER_LIST_UNLOAD,
} from '../constants';
import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    problemDataInsurers: [],
    isFetchingProblemDataHealthInsurers: false,
    currentPage: 1,
    pageCount: null,
  },
  action,
) => {
  switch (action.type) {
    case PROBLEM_DATA_INSURER_LIST_UNLOAD:
      return {
        problemDataInsurers: [],
        isFetchingProblemDataHealthInsurers: false,
        currentPage: 1,
        pageCount: null,
      };
    case PROBLEM_DATA_INSURER_LIST_REQUEST:
      return {
        ...state,
        isFetchingProblemDataHealthInsurers: true,
      };
    case PROBLEM_DATA_INSURER_LIST_RECEIVED:
      return {
        ...state,
        problemDataInsurers: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingProblemDataHealthInsurers: false,
      };
    case PROBLEM_DATA_INSURER_LIST_ERROR:
      return {
        ...state,
        isFetchingProblemDataHealthInsurers: false,
      };
    default:
      return state;
  }
};

export default reducer;
