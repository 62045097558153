import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import {
  REPORT_BATCH_SET_FILE_AND_DATE,
  REPORT_BATCH_CREATE_REQUEST,
  REPORT_BATCH_CREATE_SUCCESS,
  REPORT_BATCH_CREATE_ERROR,
  REPORT_BATCH_UPDATE_REQUEST,
  REPORT_BATCH_UPDATE_SUCCESS,
  REPORT_BATCH_UPDATE_ERROR,
  REPORT_BATCH_SINGLE_REQUEST,
  REPORT_BATCH_SINGLE_RECEIVED,
  REPORT_BATCH_SINGLE_ERROR,
  REPORT_BATCH_CREATE_UNLOAD,
  REPORT_BATCH_PUBLISH_ERROR,
  REPORT_BATCH_PUBLISH_REQUEST,
  REPORT_BATCH_PUBLISH_SUCCESS,
} from '../constants';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const reportBatchSetFileAndDate = (associatedDate, file) => {
  return {
    type: REPORT_BATCH_SET_FILE_AND_DATE,
    associatedDate,
    file,
  };
};

export const reportBatchAddFileAndDate = (associatedDate, file) => {
  return (dispatch) => {
    dispatch(reportBatchSetFileAndDate(associatedDate, file));
  };
};

export const reportBatchCreateRequest = () => {
  return {
    type: REPORT_BATCH_CREATE_REQUEST,
  };
};

export const reportBatchCreateSuccess = (data) => {
  return {
    type: REPORT_BATCH_CREATE_SUCCESS,
    reportBatch: data,
  };
};

export const reportBatchCreateError = () => {
  return {
    type: REPORT_BATCH_CREATE_ERROR,
  };
};

export const reportBatchCreate = (file) => {
  return (dispatch) => {
    dispatch(reportBatchCreateRequest());
    //console.log('Uploading file for report batch...');
    return requests
      .upload(API_ROUTES.REPORT_BATCHES, file)
      .then((response) => dispatch(reportBatchCreateSuccess(response)))
      .catch(() => dispatch(reportBatchCreateError()));
  };
};

export const reportBatchCreateUnload = () => {
  return {
    type: REPORT_BATCH_CREATE_UNLOAD,
  };
};

export const reportBatchUpdateRequest = () => {
  return {
    type: REPORT_BATCH_UPDATE_REQUEST,
  };
};

export const reportBatchUpdateSuccess = (data) => {
  return {
    type: REPORT_BATCH_UPDATE_SUCCESS,
    reportBatch: data,
  };
};

export const reportBatchUpdateError = () => {
  return {
    type: REPORT_BATCH_UPDATE_ERROR,
  };
};

export const reportBatchUpdate = (data, reportBatchId) => {
  return (dispatch) => {
    dispatch(reportBatchUpdateRequest());
    //console.log('Updating report batch with associated date...');
    return requests
      .patch(`${API_ROUTES.REPORT_BATCHES}/${reportBatchId}`, data)
      .then((response) => dispatch(reportBatchUpdateSuccess(response)))
      .catch(() => dispatch(reportBatchUpdateError()));
  };
};

export const reportBatchPublishRequest = () => {
  return {
    type: REPORT_BATCH_PUBLISH_REQUEST,
  };
};

export const reportBatchPublishSuccess = (data) => {
  return {
    type: REPORT_BATCH_PUBLISH_SUCCESS,
    reportBatch: data,
  };
};

export const reportBatchPublishError = () => {
  return {
    type: REPORT_BATCH_PUBLISH_ERROR,
  };
};

export const reportBatchPublish = (reportBatchId) => {
  return (dispatch) => {
    dispatch(reportBatchPublishRequest());
    //console.log('Updating report batch with associated date...');
    return requests
      .post(`${API_ROUTES.REPORT_BATCH_PUBLISH.replace(':id', reportBatchId)}`, {})
      .then((response) => dispatch(reportBatchPublishSuccess(response)))
      .catch(() => dispatch(reportBatchPublishError()));
  };
};

export const reportBatchSingleRequest = () => ({
  type: REPORT_BATCH_SINGLE_REQUEST,
});

export const reportBatchSingleReceived = (data) => ({
  type: REPORT_BATCH_SINGLE_RECEIVED,
  reportBatch: data,
});

export const reportBatchSingleError = (error) => ({
  type: REPORT_BATCH_SINGLE_ERROR,
  error,
});

export const reportBatchSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(reportBatchSingleRequest());
    //console.log('Fetching single report batch ...');
    return requests
      .get(`${API_ROUTES.REPORT_BATCHES}/${id}`)
      .then((response) => dispatch(reportBatchSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(reportBatchSingleError(error));
      });
  };
};

//
//
//export const fileUploadClearReferences = () => {
//  return {
//    type: REPORT_BATCH_FILE_UPLOAD_CLEAR_REFERENCES
//  };
//};
//
//export const fileClearReferences = () => {
//  return (dispatch) => {
//    console.log('Clear Temporary file references...');
//    dispatch(fileUploadClearReferences());
//  };
//};
//
//
//export const fileDeleteSuccess = (id) => {
//  return {
//    type: REPORT_BATCH_FILE_DELETE_SUCCESS,
//    id,
//  };
//};
//
//export const fileDeleteRequest = () => {
//  return {
//    type: REPORT_BATCH_FILE_DELETE_REQUEST,
//  };
//};
//
//export const fileDelete = (id) => {
//  return (dispatch) => {
//    dispatch(fileDeleteRequest());
//    console.log('Deleting Image...');
//    return requests.delete(`${API_ROUTES.TEMPORARY_INVOICE_FILES}/${id}`).then(() => dispatch(fileDeleteSuccess(id)));
////    .catch(error => dispatch(imageDeleteError()));
//  };
//};
