const setFiltersInSession = (key, value) => {
  if (!window.sessionStorage || !key) {
    return;
  }
  const filterKeyName = `filters[${key}]`;
  window.sessionStorage.setItem(filterKeyName, JSON.stringify(value));
};

const removeFiltersFromSession = (key) => {
  if (!window.sessionStorage || !key) {
    return;
  }
  const filterKeyName = `filters[${key}]`;
  window.sessionStorage.removeItem(filterKeyName);
};

const getFiltersFromSession = (key, type = 'json') => {
  if (!window.sessionStorage || !key) {
    return;
  }
  const filterKeyName = `filters[${key}]`;
  const filterItems = window.sessionStorage.getItem(filterKeyName);
  return type === 'json' ? JSON.parse(filterItems) : filterItems;
};

export {
  getFiltersFromSession,
  setFiltersInSession,
  removeFiltersFromSession,
};
