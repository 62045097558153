import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import MASpinner from '../../common/MASpinner';
import { renderField, renderSelect } from '../../../lib/helper/form';
import { ccsdCodeSingleFetch, ccsdCodeSingleUpdate, ccsdCodeSingleUnload } from '../../../lib/redux/actions/ccsdCodes';
import { ccsdCodeTypeListFetch } from '../../../lib/redux/actions/ccsdCodeType';

const mapStateToProps = (state) => ({
  ...state.ccsdCodeTypes,
});

const mapDispatchToProps = {
  ccsdCodeSingleFetch,
  ccsdCodeSingleUpdate,
  ccsdCodeSingleUnload,
  ccsdCodeTypeListFetch,
  addToast,
};

class CcsdCodeForm extends React.Component {
  constructor(props) {
    super(props);
    const { ccsdCode, initialize } = this.props;
    const initValues = ccsdCode ?? {};
    initValues['ccsdCodeType'] = ccsdCode.ccsdCodeType['@id'];

    initialize(initValues);
  }

  componentDidMount() {
    this.props.ccsdCodeTypeListFetch();
  }

  onSubmit(values) {
    const { ccsdCodeSingleUpdate, ccsdCode = undefined, addToast } = this.props;

    if (ccsdCode) {
      return ccsdCodeSingleUpdate(values, ccsdCode.id).then(() => {
        addToast('The CCSD Code was successfully updated', true);
      });
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      submitting,
      isUpdatingCcsdCode,
      isFetchingCcsdCode,
      hasLoadedCcsdCode,
      isFetchingCcsdCodeTypes,
      title = 'CCSD Code Details',
      isCreate = false,
      ccsdCodeTypes = [],
    } = this.props;
    if ((!hasLoadedCcsdCode && isFetchingCcsdCode) || isFetchingCcsdCodeTypes) {
      return <MASpinner />;
    }
    const typeOptions = [];
    ccsdCodeTypes.forEach((ccsdCodeType) => {
      typeOptions.push({ value: ccsdCodeType['@id'], text: ccsdCodeType.name });
    });

    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-book-medical" />
        </div>
        <h2 className="crud__section-header">{title}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            <div className="col-sm-12 col-md-2">
              <Field name="reference" label="CCSD Code" type="text" component={renderField} />
            </div>

            <div className="col-sm-12 col-md-2">
              <Field name="ccsdCodeType" label="CCSD Code Type" type="select" options={typeOptions} component={renderSelect} showEmpty={false} />
            </div>

            <div className="col-sm-12 col-md-5">
              <Field name="name" label="Description" type="text" component={renderField} />
            </div>
            {isUpdatingCcsdCode ? (
              <MASpinner />
            ) : (
              <div className="col-sm-12 col-md-5 align-self-end">
                <button type="submit" disabled={submitting} className="btn btn-primary mb-3">
                  {isCreate ? 'Create CCSD Code' : 'Update'}
                </button>
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'CcsdCodeForm',
})(connect(mapStateToProps, mapDispatchToProps)(CcsdCodeForm));
