import * as API_ROUTES from '../../routing/api';
import { requests } from '../../helper/agent';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';
import {
  DATED_COST_LIST_ERROR,
  DATED_COST_LIST_REQUEST,
  DATED_COST_LIST_SET_PAGE,
  DATED_COST_LIST_UNLOAD,
  DATED_COST_LIST_UPDATED,
  DATED_COST_SINGLE_CREATE_REQUEST,
  DATED_COST_SINGLE_CREATE_SUCCESS,
} from '../constants';

export const datedCostCreateRequest = () => ({
  type: DATED_COST_SINGLE_CREATE_REQUEST,
});

export const datedCostSingleCreated = (data) => ({
  type: DATED_COST_SINGLE_CREATE_SUCCESS,
  data,
});

export const datedCostSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(datedCostCreateRequest());
    let endpointURL = `${API_ROUTES.DATED_COSTS}`;
    return requests
      .post(endpointURL, data)
      .then((response) => {
        dispatch(datedCostSingleCreated(response));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const datedCostListUpdated = (datedCostList) => ({
  type: DATED_COST_LIST_UPDATED,
  datedCostList,
});

export const datedCostListUnload = () => ({
  type: DATED_COST_LIST_UNLOAD,
});

export const datedCostListSetPage = (page) => ({
  type: DATED_COST_LIST_SET_PAGE,
  page,
});
export const datedCostListRequest = () => ({
  type: DATED_COST_LIST_REQUEST,
});
export const datedCostListError = (error) => ({
  type: DATED_COST_LIST_ERROR,
  error,
});

export const getDatedCostList = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(datedCostListRequest);
    filters.page = page;
    return requests
      .get(`${API_ROUTES.DATED_COSTS}`, filters)
      .then((response) => dispatch(datedCostListUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(datedCostListError(error));
      });
  };
};
