import {
  USER_LOGIN_SUCCESS,
  USER_PROFILE_ERROR,
  USER_LOGOUT,
  USER_LOGIN_2FA_SUCCESS,
  USER_PROFILE_RECEIVED,
  SUBUSER_SET_CONSULTANT_TO_MANAGE,
  SUB_USER_SINGLE_RECEIVED,
} from './redux/constants';
import { requests } from './helper/agent';
import { userLogout } from './redux/actions/user';

export const tokenMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      window.localStorage.setItem('jwtToken', action.token);
      requests.setToken(action.token);
      break;

    case USER_LOGIN_2FA_SUCCESS:
      window.localStorage.setItem('jwtToken', action.token);
      window.localStorage.setItem('userId', action.userId);
      requests.setToken(action.token);
      break;
    case USER_LOGOUT:
      window.localStorage.removeItem('jwtToken');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('roles');
      window.localStorage.removeItem('consultantToManage');
      window.localStorage.removeItem('subUserPermissions');
      requests.setToken(null);
      break;
    case USER_PROFILE_ERROR: {
      const authState = store.getState().auth;
      if (authState.userId === action.userId && authState.userData === null) {
        store.dispatch(userLogout());
      }
      break;
    }
    case USER_PROFILE_RECEIVED:
      window.localStorage.setItem('roles', action.userData.roles);
      break;
    case SUBUSER_SET_CONSULTANT_TO_MANAGE:
      //console.log('SubUser selected ', action.consultantToManage);
      window.localStorage.removeItem('subUserPermissions');
      window.localStorage.setItem('consultantToManage', JSON.stringify(action.consultantToManage));
      break;
    case SUB_USER_SINGLE_RECEIVED:
      //console.log('SubUser Permissions Received');
      if (action.data.isActive) {
        window.localStorage.setItem('subUserPermissions', JSON.stringify(action.data.permissions));
      } else {
        window.localStorage.removeItem('subUserPermissions');
      }
      break;
    default:
  }

  next(action);
};
