import React from 'react';
import Alert from 'react-bootstrap/Alert';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import InvoiceBatchEditCompleteInvoiceNumbers from './InvoiceBatchEditCompleteInvoiceNumbers';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';

class InvoiceBatchRelatedInvoiceNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editInvoiceNumbers: false,
    };
    this.editInvoiceNumbersHandler = this.editInvoiceNumbersHandler.bind(this);
  }

  editInvoiceNumbersHandler() {
    this.setState((prevState) => ({
      editInvoiceNumbers: !prevState,
    }));
  }
  renderInvoiceLinks(invoiceBatch) {
    const fieldArr = [];
    if (!invoiceBatch?.invoices || invoiceBatch.invoices.length < 1) {
      fieldArr.push(<li className="list-inline-item">No Invoices found</li>);
    }

    let i = 0;
    while (i < invoiceBatch.invoices.length) {
      fieldArr.push(
        <li key={i} className="list-inline-item">
          <Link to={ROUTES.INVOICES.SINGLE.replace(':id', invoiceBatch.invoices[i].id)} className="btn btn-sm btn-outline-dark">
            {invoiceBatch.invoices[i].invoiceNo}
          </Link>
        </li>,
      );
      i++;
    }
    return fieldArr;
  }

  renderInvoiceNumberFields(invoiceBatch) {
    const fieldArr = [];
    if (invoiceBatch.invoiceNumbers.length < 1) {
      fieldArr.push(<li className="list-inline-item">No Invoice Numbers found</li>);
    }

    let i = 0;
    while (i <= invoiceBatch.invoiceNumbers.length) {
      fieldArr.push(
        <li key={i} className="list-inline-item">
          {invoiceBatch.invoiceNumbers[i]}
        </li>,
      );
      i++;
    }
    return fieldArr;
  }

  render() {
    const { invoiceBatch } = this.props;
    const { editInvoiceNumbers } = this.state;
    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-check-circle" />
        </div>
        <h2 className="invoice-batch-single__section-header">Related Invoice Numbers</h2>
        {Number(invoiceBatch.status.code) !== Number(INVOICE_BATCH_STATUS.COMPLETED) ? (
          <Alert variant={'info'}>Awaiting completion, please check back later</Alert>
        ) : (
          <>
            {editInvoiceNumbers ? (
              <>
                <InvoiceBatchEditCompleteInvoiceNumbers invoiceBatch={invoiceBatch} editInvoiceNumbersHandler={this.editInvoiceNumbersHandler} />
                <button className={'btn btn-block btn-danger mt-2'} onClick={() => this.setState({ editInvoiceNumbers: false })}>
                  Cancel
                </button>
              </>
            ) : (
              <>
                {invoiceBatch?.invoices?.length ? (
                  <>
                    <h6>Linked Invoices:</h6>
                    <ul className="list-unstyled list-inline mb-0">{this.renderInvoiceLinks(invoiceBatch)}</ul>
                  </>
                ) : null}
                <>
                  <h6>Invoices Numbers:</h6>
                  <ul className="list-unstyled list-inline mb-0">{this.renderInvoiceNumberFields(invoiceBatch)}</ul>
                </>
                <button className="btn btn-block btn-primary mt-2" onClick={() => this.setState({ editInvoiceNumbers: true })}>
                  Edit Invoice Numbers
                </button>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default InvoiceBatchRelatedInvoiceNumbers;
