import {
  CMS_CONTENT_SINGLE_REQUEST,
  CMS_CONTENT_CREATE_REQUEST,
  CMS_CONTENT_SINGLE_UPDATE_REQUEST,
  CMS_CONTENT_SINGLE_UPDATE_SUCCESS,
  CMS_CONTENT_CREATE_SUCCESS,
  CMS_CONTENT_SINGLE_ERROR,
  CMS_CONTENT_SINGLE_RECEIVED,
  CMS_CONTENT_SINGLE_UNLOAD,
} from '../constants';

const reducer = (
  state = {
    cmsContent: null,
    error: null,
    isFetchingContent: false,
    isUpdatingContent: false,
  },
  action,
) => {
  switch (action.type) {
    case CMS_CONTENT_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingContent: true,
      };
    case CMS_CONTENT_SINGLE_RECEIVED:
      return {
        ...state,
        isFetchingContent: false,
        cmsContent: action.data,
      };
    case CMS_CONTENT_CREATE_REQUEST:
    case CMS_CONTENT_SINGLE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdatingContent: true,
      };
    case CMS_CONTENT_SINGLE_UPDATE_SUCCESS:
    case CMS_CONTENT_CREATE_SUCCESS:
      return {
        ...state,
        isUpdatingContent: false,
        cmsContent: action.data,
      };
    case CMS_CONTENT_SINGLE_ERROR:
      return {
        ...state,
        isFetchingContent: false,
        isUpdatingContent: false,
        cmsContent: null,
        error: action.error,
      };
    case CMS_CONTENT_SINGLE_UNLOAD:
      return {
        ...state,
        isFetchingContent: false,
        cmsContent: null,
      };
    default:
      return state;
  }
};

export default reducer;
