import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  CONSULTANT_TICKET_LIST_REQUEST,
  CONSULTANT_TICKET_LIST_RECEIVED,
  CONSULTANT_TICKET_LIST_ERROR,
  CONSULTANT_TICKET_LIST_SET_PAGE,
  CONSULTANT_TICKET_LIST_CLEAR_FILTERS,
  CONSULTANT_TICKET_LIST_SET_FILTERS,
  CONSULTANT_TICKET_LIST_UNLOAD,
  CONSULTANT_TICKET_SINGLE_UPDATED,
  CONSULTANT_TICKET_SINGLE_UPDATE_REQUEST,
  CONSULTANT_TICKET_SINGLE_CREATE_SUCCESS,
  CONSULTANT_TICKET_SINGLE_CREATE_REQUEST,
  CONSULTANT_TICKET_SINGLE_REQUEST,
  CONSULTANT_TICKET_SINGLE_RECEIVED,
  CONSULTANT_TICKET_SINGLE_ERROR,
  CONSULTANT_TICKET_SINGLE_UNLOAD,
  CONSULTANT_TICKET_SINGLE_CREATE_ERROR,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantTicketListRequest = () => ({
  type: CONSULTANT_TICKET_LIST_REQUEST,
});

export const consultantTicketListReceived = (data) => ({
  type: CONSULTANT_TICKET_LIST_RECEIVED,
  data,
});

export const consultantTicketListError = (error) => ({
  type: CONSULTANT_TICKET_LIST_ERROR,
  error,
});

export const consultantTicketListSetPage = (page) => ({
  type: CONSULTANT_TICKET_LIST_SET_PAGE,
  page,
});

export const consultantTicketListSetFilters = (filters) => ({
  type: CONSULTANT_TICKET_LIST_SET_FILTERS,
  filters,
});

export const consultantTicketListClearFilters = () => ({
  type: CONSULTANT_TICKET_LIST_CLEAR_FILTERS,
});

export const consultantTicketListUnload = () => ({
  type: CONSULTANT_TICKET_LIST_UNLOAD,
});

export const consultantTicketListFetch = (page = 1, filters = {}, endPoint = API_ROUTES.CONSULTANT_TICKETS) => {
  return (dispatch) => {
    dispatch(consultantTicketListRequest());
    //console.log('Fetching consultantTickets ...');
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(endPoint, filters)
      .then((response) => dispatch(consultantTicketListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantTicketListError(error));
      });
  };
};

export const consultantTicketSingleRequest = () => ({
  type: CONSULTANT_TICKET_SINGLE_REQUEST,
});

export const consultantTicketSingleReceived = (data) => ({
  type: CONSULTANT_TICKET_SINGLE_RECEIVED,
  data,
});

export const consultantTicketSingleError = (error) => ({
  type: CONSULTANT_TICKET_SINGLE_ERROR,
  error,
});

export const consultantTicketSingleUnload = () => ({
  type: CONSULTANT_TICKET_SINGLE_UNLOAD,
});
export const consultantTicketSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(consultantTicketSingleRequest());
    //console.log('Fetching single invoice batch ...');
    return requests
      .get(`${API_ROUTES.CONSULTANT_TICKETS}/${id}`)
      .then((response) => dispatch(consultantTicketSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantTicketSingleError(error));
      });
  };
};

export const consultantTicketSingleUpdateRequest = () => ({
  type: CONSULTANT_TICKET_SINGLE_UPDATE_REQUEST,
});

export const consultantTicketSingleUpdated = (data) => ({
  type: CONSULTANT_TICKET_SINGLE_UPDATED,
  data,
});

export const consultantTicketSingleUpdate = (data, consultantTicketId) => {
  return (dispatch) => {
    dispatch(consultantTicketSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.CONSULTANT_TICKETS}/${consultantTicketId}`, data)
      .then((response) => dispatch(consultantTicketSingleUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const consultantTicketSingleCreateRequest = () => ({
  type: CONSULTANT_TICKET_SINGLE_CREATE_REQUEST,
});

export const consultantTicketSingleCreateSuccess = (data) => ({
  type: CONSULTANT_TICKET_SINGLE_CREATE_SUCCESS,
  data,
});

export const consultantTicketSingleCreateError = (error) => ({
  type: CONSULTANT_TICKET_SINGLE_CREATE_ERROR,
  error,
});

export const consultantTicketSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(consultantTicketSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.CONSULTANT_TICKETS}`, data)
      .then((response) => {
        dispatch(consultantTicketSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(consultantTicketSingleCreateError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
