import React from 'react';
import { connect } from 'react-redux';
import MASpinner from '../common/MASpinner';
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import * as ROUTES from '../../lib/routing/frontend';
import { workScheduleFetchList, workScheduleSetDate } from '../../lib/redux/actions/workSchedule';
import WeeklyCalendarContainer from '../common/WeeklyCalendarContainer';
import { isOutsourcer } from '../../lib/helper/authorisation';
import qs from 'qs';
import WorkScheduleTable from './WorkScheduleTable';
import { userListFetch } from '../../lib/redux/actions/userList';
import { userSingleFetch } from '../../lib/redux/actions/user';
import { workScheduleWeeklyEventsFetch } from '../../lib/redux/actions/workScheduleWeeklyEvents';

const moment = extendMoment(Moment);

const mapStateToProps = (state) => ({
  ...state.userList,
  ...state.userSingle,
  ...state.workSchedule,
  ...state.workScheduleWeeklyEvents,
});

const mapDispatchToProps = {
  workScheduleSetDate,
  userListFetch,
  userSingleFetch,
  workScheduleFetchList,
  workScheduleWeeklyEventsFetch,
};

class WorkSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredConsultants: [],
    };
  }

  changePage(date) {
    const { history, workScheduleSetDate, useQueryString = false, paginationRoute = ROUTES.WORK_SCHEDULE } = this.props;
    workScheduleSetDate(date);
    if (useQueryString) {
      history.push(paginationRoute + '?date=' + date);
    } else {
      history.push(paginationRoute.replace(':date', date));
    }
  }

  getQueryParamDate() {
    const { useQueryString = false } = this.props;
    if (useQueryString) {
      const qsDate = qs.parse(this.props.location.search.replace('?', ''));
      return qsDate['date'] || moment().utc().format('DD-MM-YYYY');
    }

    return this.props.match.params.date || moment().utc().format('DD-MM-YYYY');
  }

  componentDidMount() {
    const { workScheduleFetchList, workScheduleWeeklyEventsFetch, userListFetch, userSingleFetch } = this.props;
    if (isOutsourcer(true)) {
      userSingleFetch(window.localStorage.getItem('userId'));
    } else {
      userListFetch(1, { pagination: false, roles: '["ROLE_OUTSOURCER"]' });
    }
    workScheduleFetchList(this.getQueryParamDate());
    workScheduleWeeklyEventsFetch(this.getQueryParamDate());
  }

  componentDidUpdate(prevProps) {
    const { currentDate, workScheduleFetchList, workScheduleSetDate, workScheduleWeeklyEventsFetch } = this.props;
    if (prevProps.match.params.date !== this.getQueryParamDate()) {
      workScheduleSetDate(this.getQueryParamDate());
    }

    if (prevProps.currentDate !== currentDate) {
      workScheduleFetchList(currentDate);
      workScheduleWeeklyEventsFetch(currentDate);
    }
  }

  render() {
    const { isFetchingWorkSchedule, workSchedules, users, user, workScheduleWeeklyEvents } = this.props;
    if (isOutsourcer(true)) {
      return (
        <WeeklyCalendarContainer title={'Batch Work Schedule'} changePage={this.changePage.bind(this)} match={this.props.match} isFetching={isFetchingWorkSchedule}>
          {isFetchingWorkSchedule ? (
            <MASpinner wrapperClasses="mt-3 mb-3" />
          ) : (
            <WorkScheduleTable
              currentDate={this.getQueryParamDate()}
              users={[user]}
              workSchedules={workSchedules}
              workScheduleWeeklyEvents={workScheduleWeeklyEvents}
            />
          )}
        </WeeklyCalendarContainer>
      );
    }

    return (
      <WeeklyCalendarContainer title={'Batch Work Schedule'} changePage={this.changePage.bind(this)} match={this.props.match} isFetching={isFetchingWorkSchedule}>
        {isFetchingWorkSchedule ? (
          <MASpinner wrapperClasses="mt-3 mb-3" />
        ) : (
          <WorkScheduleTable
            currentDate={this.getQueryParamDate()}
            users={users}
            workSchedules={workSchedules}
            workScheduleWeeklyEvents={workScheduleWeeklyEvents}
          />
        )}
      </WeeklyCalendarContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkSchedule);
