import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import { CONTACT_RECORD_TYPE_LIST_REQUEST, CONTACT_RECORD_TYPE_LIST_RECEIVED, CONTACT_RECORD_TYPE_LIST_ERROR } from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const contactRecordTypeListRequest = () => ({
  type: CONTACT_RECORD_TYPE_LIST_REQUEST,
});

export const contactRecordTypeListReceived = (data) => ({
  type: CONTACT_RECORD_TYPE_LIST_RECEIVED,
  data,
});

export const contactRecordTypeListError = (error) => ({
  type: CONTACT_RECORD_TYPE_LIST_ERROR,
  error,
});

export const contactRecordTypeListFetch = () => {
  return (dispatch) => {
    dispatch(contactRecordTypeListRequest());
    const filters = { pagination: false };
    return requests
      .get(API_ROUTES.CONTACT_RECORD_TYPE, filters)
      .then((response) => dispatch(contactRecordTypeListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(contactRecordTypeListError(error));
      });
  };
};
