import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  HOSPITAL_LIST_REQUEST,
  HOSPITAL_LIST_RECEIVED,
  HOSPITAL_LIST_ERROR,
  HOSPITAL_LIST_SET_PAGE,
  //  HOSPITAL_LIST_CLEAR_FILTERS, HOSPITAL_LIST_SET_FILTERS,
  HOSPITAL_LIST_UNLOAD,
  HOSPITAL_SINGLE_UPDATED,
  HOSPITAL_SINGLE_UPDATE_REQUEST,
  HOSPITAL_SINGLE_CREATE_SUCCESS,
  HOSPITAL_SINGLE_CREATE_REQUEST,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const hospitalListRequest = () => ({
  type: HOSPITAL_LIST_REQUEST,
});

export const hospitalListReceived = (data) => ({
  type: HOSPITAL_LIST_RECEIVED,
  data,
});

export const hospitalListError = (error) => ({
  type: HOSPITAL_LIST_ERROR,
  error,
});

export const hospitalListSetPage = (page) => ({
  type: HOSPITAL_LIST_SET_PAGE,
  page,
});
//
//export const hospitalListSetFilters = (filters) => ({
//  type: HOSPITAL_LIST_SET_FILTERS,
//  filters,
//});
//
//export const hospitalListClearFilters = () => ({
//  type: HOSPITAL_LIST_CLEAR_FILTERS,
//});

export const hospitalListUnload = () => ({
  type: HOSPITAL_LIST_UNLOAD,
});

export const hospitalListFetch = (page = 1, filters = {}, endPoint = API_ROUTES.HOSPITALS) => {
  return (dispatch) => {
    dispatch(hospitalListRequest());
    //console.log('Fetching hospitals ...');
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(endPoint, filters)
      .then((response) => dispatch(hospitalListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(hospitalListError(error));
      });
  };
};

export const hospitalSingleUpdateRequest = () => ({
  type: HOSPITAL_SINGLE_UPDATE_REQUEST,
});

export const hospitalSingleUpdated = (data) => ({
  type: HOSPITAL_SINGLE_UPDATED,
  data,
});

export const hospitalSingleUpdate = (data, hospitalId) => {
  return (dispatch) => {
    dispatch(hospitalSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.HOSPITALS}/${hospitalId}`, data)
      .then((response) => dispatch(hospitalSingleUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const hospitalSingleCreateRequest = () => ({
  type: HOSPITAL_SINGLE_CREATE_REQUEST,
});

export const hospitalSingleCreateSuccess = (data) => ({
  type: HOSPITAL_SINGLE_CREATE_SUCCESS,
  data,
});

//export const hospitalSingleCreateError = (error) => ({
//  type: HOSPITAL_SINGLE_CREATE_ERROR,
//  error,
//});

export const hospitalSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(hospitalSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.HOSPITALS}`, data)
      .then((response) => {
        dispatch(hospitalSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
          parsedError._error = 'It looks like you may be creating a hospital which already exists - please check the name and try again if not.';
        } else {
          parsedError._error =
            'There was a problem creating the hospital - please check the details and try again. If the problem persists please contact support.';
        }
        throw new SubmissionError(parsedError);
      });
  };
};
