import { PROBLEM_STATUSES_RECEIVED, PROBLEM_STATUSES_REQUEST, PROBLEM_STATUSES_UNLOAD } from '../constants';

const reducer = (
  state = {
    problemStatuses: [],
    isFetchingProblemStatuses: false,
  },
  action,
) => {
  switch (action.type) {
    case PROBLEM_STATUSES_REQUEST:
      return {
        ...state,
        isFetchingProblemStatuses: true,
      };
    case PROBLEM_STATUSES_RECEIVED:
      return {
        ...state,
        isFetchingProblemStatuses: false,
        problemStatuses: action.data['hydra:member'],
      };
    case PROBLEM_STATUSES_UNLOAD:
      return {
        ...state,
        isFetchingProblemStatuses: false,
        problemStatuses: [],
      };
    default:
      return state;
  }
};

export default reducer;
