import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import React from 'react';
import {
  consultantSingleError,
  consultantSubAccountInvite,
  consultantSubAccountInviteRemove,
  consultantSubAccountInviteUpdate,
} from '../../../lib/redux/actions/consultant';
import { addToast } from '../../../lib/redux/actions/toast';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderCheckbox, renderField } from '../../../lib/helper/form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MASpinner from '../../common/MASpinner';
import { isConsultant, isStaff } from '../../../lib/helper/authorisation';

const mapStateToProps = (state, props) => ({
  ...state.consultantSingle,
  initialValues: props.consultant,
});

const mapDispatchToProps = {
  consultantSubAccountInvite,
  consultantSubAccountInviteUpdate,
  consultantSubAccountInviteRemove,
  consultantSingleError,
  addToast,
};

class SubAccountDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    const { consultant, subUser, initialize } = this.props;
    const initVals = {
      code: consultant.code,
      id: null,
    };
    if (subUser && subUser.user) {
      initVals.firstName = subUser.user.firstName;
      initVals.id = subUser.id;
      initVals.lastName = subUser.user.lastName;
      initVals.email = subUser.user.email;
      subUser.permissions.map((permission) => (initVals[`permissions_${permission}`] = true));
    }
    initialize(initVals);
  }

  onSubmit(values) {
    const { consultantSubAccountInvite, consultantSubAccountInviteUpdate, consultantSubAccountInviteRemove, addToast, reset } = this.props;

    const mapPermissions = (values) => {
      values.permissions = [];
      Object.keys(values).forEach((fieldName) => {
        if (fieldName.toString().indexOf('permissions_') > -1 && values[fieldName] === true) {
          values.permissions.push(fieldName.toString().replace('permissions_', ''));
        }
      });
      return values;
    };

    if (values.action === 'update') {
      return consultantSubAccountInviteUpdate(mapPermissions(values)).then(() => {
        addToast('User Successfully Updated', true);
      });
    }

    if (values.action === 'remove') {
      return consultantSubAccountInviteRemove(values).then(() => {
        addToast('User Invite Successfully Removed', true);
      });
    }
    return consultantSubAccountInvite(mapPermissions(values)).then(() => {
      addToast('An email inviting this user to access your account has been sent.', true);
      reset();
    });
  }

  render() {
    const { handleSubmit, error, subUser, isUpdatingSubuser } = this.props;
    const invited = (
      <Badge pill variant={'warning'} className="text-uppercase mb-3">
        Invited
      </Badge>
    );
    const accepted = (
      <Badge pill variant={'primary'} className="text-uppercase mb-3">
        Accepted
      </Badge>
    );
    const permsId = subUser ? subUser.id : 'new';
    if (!subUser && isUpdatingSubuser) {
      return <MASpinner />;
    }
    return (
      <div className="crud__section__assocated-user mb-3">
        {error && <div className="alert alert-danger">{error}</div>}
        {/* Badges - should be Invited and Active see MED-92  */}

        <Form className="sub-account-invite-form" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Form.Control type="text" name="id" readOnly hidden />
          {(isConsultant() || isStaff(true)) && subUser && subUser.user ? (
            <p>
              {`${subUser.user.firstName} ${subUser.user.lastName} - ${subUser.user.email}`} - Status: {subUser ? (subUser.isActive ? accepted : invited) : ''}
            </p>
          ) : (
            <>
              <Row>
                <Col sm="6" md="12">
                  <Field name="firstName" label="First Name" required type="text" component={renderField} disabled={!!(subUser && subUser.isActive)} />
                </Col>
                <Col sm="6" md="12">
                  <Field name="lastName" label="Last Name" required type="text" component={renderField} disabled={!!(subUser && subUser.isActive)} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field name="email" label="Email address" type="email" required component={renderField} disabled={!!(subUser && subUser.isActive)} />
                </Col>
              </Row>
            </>
          )}
          <div className="crud__section__assocated-user__checkboxes">
            <p className="sr-only">Permissions</p>
            <div>
              <Field
                disabled={isStaff(true)}
                wrapperClassNames="checkbox"
                name="permissions_upload_bills"
                label="Upload invoices"
                id={`${permsId}_permissions_upload_bills`}
                component={renderCheckbox}
              />
              <Field
                disabled={isStaff(true)}
                wrapperClassNames="checkbox"
                name="permissions_receive_invoice_emails"
                label="Receive emails when invoices are uploaded"
                id={`${permsId}_permissions_receive_invoice_emails`}
                component={renderCheckbox}
              />
              <Field
                disabled={isStaff(true)}
                wrapperClassNames="checkbox"
                name="permissions_receive_summary_emails"
                label="Receive an email containing the weekly summary of invoices"
                id={`${permsId}_permissions_receive_summary_emails`}
                component={renderCheckbox}
              />
              <Field
                disabled={isStaff(true)}
                wrapperClassNames="checkbox"
                name="permissions_view_and_download_invoices"
                label="View and download invoices"
                id={`${permsId}_permissions_view_and_download_invoices`}
                component={renderCheckbox}
              />
              <Field
                disabled={isStaff(true)}
                wrapperClassNames="checkbox"
                name="permissions_view_and_download_reports"
                label="View and download reports"
                id={`${permsId}_permissions_view_and_download_reports`}
                component={renderCheckbox}
              />
            </div>
          </div>
          <hr />
          {!subUser && !isStaff(true) && <button className="btn btn-outline-primary mr-2">Send Invite</button>}
          {subUser && !isStaff(true) && !subUser.isActive && <button className="btn btn-outline-primary mr-2">Resend Invite</button>}
          {subUser && !isStaff(true) && (
            <button
              className="btn btn-outline-primary mr-2"
              name="update"
              onClick={handleSubmit((values) =>
                this.onSubmit({
                  ...values,
                  action: 'update',
                }),
              )}>
              Update
            </button>
          )}
          {subUser && !isStaff(true) && (
            <button
              className="btn btn-outline-danger"
              name="remove"
              onClick={handleSubmit((values) =>
                this.onSubmit({
                  ...values,
                  action: 'remove',
                }),
              )}>
              Remove
            </button>
          )}
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'SubAccountDetailsForm',
})(connect(mapStateToProps, mapDispatchToProps)(SubAccountDetailsForm));
