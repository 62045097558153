import Card from 'react-bootstrap/Card';
import AssignToUserTypeahead from '../../fields/AssignToUserTypeahead';
import { invoiceBatchAssign } from '../../../lib/redux/actions/invoiceBatch';
import { Field, reduxForm } from 'redux-form';
import { renderCheckbox } from '../../../lib/helper/form';
import MASpinner from '../../common/MASpinner';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { Col, Row } from 'react-bootstrap';
import { userNameFormatter } from '../../../lib/helper/formatting';

const mapDispatchToProps = {
  invoiceBatchAssign,
  addToast,
};

class BulkAssignForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssignToUser: false,
      clearTypeaheads: false,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    const { invoiceBatchAssign, addToast, invoiceBatchIdsToAssign = [], loadInvoiceBatches = () => {}, reset } = this.props;

    values['user'] = this.state.selectedAssignToUser !== false ? this.state.selectedAssignToUser['id'] : null;
    values['invoiceBatches'] = invoiceBatchIdsToAssign.length > 0 ? invoiceBatchIdsToAssign : null;

    if (values['user'] === null || values['invoiceBatches'] === null) {
      return;
    }

    return invoiceBatchAssign(values).then(() => {
      addToast('Invoice Batches Assigned Successfully', true);
      reset();
      this.setState(() => ({ selectedAssignToUser: false }));
      this.clearTypeaheads();
      loadInvoiceBatches();
    });
  }

  render() {
    const { handleSubmit, submitting, pristine, isUpdatingInvoiceBatch, invoiceBatchIdsToAssign = [], numberOfInvoices = 0 } = this.props;
    const needsMoreData = numberOfInvoices === 0 || invoiceBatchIdsToAssign.length < 1 || !this.state?.selectedAssignToUser?.id;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="bulk-assign-form">
        <Card.Body>
          <Row>
            <Col lg={2} xl={1}>
              <label className="mt-lg-2">Assign to:</label>
            </Col>
            <Col lg={4} xl={5}>
              <AssignToUserTypeahead
                inline={true}
                selectedUsers={this.state.selectedAssignToUser ? [this.state.selectedAssignToUser] : []}
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                name="user"
                label=""
                labelKey={(option) => userNameFormatter(option)}
                multiple={false}
                defaultUser={false}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedAssignToUser: selected[0] };
                  });
                }}
              />
            </Col>
            <Col lg={2}>
              <Field
                name="isProcessingOutsourced"
                label="Outsourced?"
                id="is-processing-outsourced"
                component={renderCheckbox}
                className="mt-xl-2 mb-3 mb-xl-0"
              />
            </Col>
            <Col xl={4}>
              {submitting && isUpdatingInvoiceBatch ? (
                <MASpinner />
              ) : (
                <button
                  type="submit"
                  disabled={needsMoreData || pristine || submitting}
                  className={`btn ${needsMoreData ? 'btn-secondary' : 'btn-primary'} btn-block `}>
                  Assign{' '}
                  <span className="badge badge-light badge-primary badge-large-text" title="Invoices to process">
                    {numberOfInvoices}
                    {this.state?.selectedAssignToUser?.nextWorkDayWorkSchedule ? '/' + this.state.selectedAssignToUser.nextWorkDayWorkSchedule : ''}
                  </span>{' '}
                  invoices
                  {this.state?.selectedAssignToUser?.id
                    ? ' to ' + this.state.selectedAssignToUser.firstName + ' ' + this.state.selectedAssignToUser.lastName
                    : ''}
                </button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Form>
    );
  }
}

export default reduxForm({
  initialValues: {
    isProcessingOutsourced: true,
  },
  form: 'BulkAssignForm',
})(connect(null, mapDispatchToProps)(BulkAssignForm));
