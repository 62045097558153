import * as API_ROUTES from '../../routing/api';
import { UPLIFT_DATA_REQUEST, UPLIFT_DATA_RECEIVED, UPLIFT_DATA_RECEIVED_ERROR } from '../constants';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';
import { requests } from '../../helper/agent';

export const upliftDataRequest = () => ({
  type: UPLIFT_DATA_REQUEST,
});

export const upliftDataReceived = (data) => ({
  type: UPLIFT_DATA_RECEIVED,
  data,
});

export const upliftDataReceivedError = (error) => ({
  type: UPLIFT_DATA_RECEIVED_ERROR,
  error,
});

export const upliftDataAdd = (data) => {
  return (dispatch) => {
    dispatch(upliftDataRequest());
    return requests
      .post(`${API_ROUTES.UPLIFT_DATA}`, data)
      .then((response) => {
        dispatch(upliftDataReceived(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(upliftDataReceivedError(error));
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const upliftDataDelete = (id) => {
  return (dispatch) => {
    dispatch(upliftDataRequest());
    return requests
      .delete(`${API_ROUTES.UPLIFT_DATA_SINGLE.replace(':id', id)}`)
      .then((response) => {
        dispatch(upliftDataReceived(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(upliftDataReceivedError(error));
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const upliftDataEdit = (id, data) => {
  return (dispatch) => {
    dispatch(upliftDataRequest());
    return requests
      .patch(`${API_ROUTES.UPLIFT_DATA_SINGLE.replace(':id', id)}`, data)
      .then((response) => {
        dispatch(upliftDataReceivedError(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(upliftDataReceivedError(error));
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
