import { TOAST_REMOVED, TOAST_ADDED } from '../constants';

export const addToastRequest = (message, isPositive, isNegative, embed) => ({
  type: TOAST_ADDED,
  message,
  isPositive,
  isNegative,
  embed,
});

export const removeToastRequest = (id) => ({
  type: TOAST_REMOVED,
  id,
});

export const addToast = (message, isPositive = false, isNegative = false, embed = undefined) => {
  return (dispatch) => {
    dispatch(addToastRequest(message, isPositive, isNegative, embed));
  };
};

export const removeToast = (toastId) => {
  return (dispatch) => {
    dispatch(removeToastRequest(toastId));
  };
};
