import { hydraPageCount } from '../../helper/apiUtils';
import {
  PROBLEM_DATA_LIST_ERROR,
  PROBLEM_DATA_LIST_RECEIVED,
  PROBLEM_DATA_LIST_REQUEST,
  PROBLEM_DATA_LIST_SET_PAGE,
  PROBLEM_DATA_LIST_CLEAR_FILTERS,
  PROBLEM_DATA_LIST_SET_FILTERS,
  PROBLEM_DATA_LIST_UNLOAD,
  PROBLEM_DATA_ADDED,
} from '../constants';

const reducer = (
  state = {
    problemData: [],
    isFetchingProblemData: false,
    currentPage: 1,
    pageCount: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case PROBLEM_DATA_LIST_REQUEST:
      return {
        ...state,
        isFetchingProblemData: true,
      };
    case PROBLEM_DATA_ADDED:
      return {
        ...state,
        problemData: action.data['hydra:member'],
        isFetchingProblemData: false,
      };
    case PROBLEM_DATA_LIST_RECEIVED:
      return {
        ...state,
        isFetchingProblemData: false,
        problemData: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
      };
    case PROBLEM_DATA_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case PROBLEM_DATA_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case PROBLEM_DATA_LIST_UNLOAD:
      return {
        ...state,
        problemData: [],
        isFetchingProblemData: false,
      };
    case PROBLEM_DATA_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };
    case PROBLEM_DATA_LIST_ERROR: {
      return {
        ...state,
        isFetchingProblemData: false,
        problemData: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
