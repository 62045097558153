import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { capitalizeWords, generalCurrencyFormatter } from '../../../lib/helper/formatting';
import TimelineCostsIcon from '../../ccsdCodeCost/TimelineCostsIcon';
import FormControl from 'react-bootstrap/FormControl';
//import { CCSD_CODE_TYPE_CONSULTATION_CODE } from '../../../lib/helper/general';
import MASpinner from '../../common/MASpinner';
import { sortHealthInsurersByName } from '../../../lib/helper/general';

class InvoiceBatchCcsdCodeCostTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentHealthInsurerId: false,
      codeFilter: '',
    };
  }

  setCurrentHealthInsurerId(event) {
    const vals = event.target.value.split('-');
    this.setState(() => ({
      currentHealthInsurerId: vals && vals[0] ? vals[0] : false,
    }));
    this.props.setSelectedConsultantHealthInsurer(vals && vals[1] ? vals[1] : false);
  }

  getCcsdCodeSpan(feeAssured, ccsdCodeCost) {
    const { consultant } = this.props;

    /* If the current code is for a feeAssured relation and the currentCode is not a consultation type code, use the currentCosts from the defaultCcsdCodeCost */
    //    const currentCosts = feeAssured && ccsdCodeCost.ccsdCode.ccsdCodeType.code !== CCSD_CODE_TYPE_CONSULTATION_CODE ?
    //        ccsdCodeCost.ccsdCode.defaultCcsdCodeCosts.filter(dCcsdCodeCost => dCcsdCodeCost.healthInsurer.id === ccsdCodeCost.consultantHealthInsurer.healthInsurer.id)[0].currentCosts : ccsdCodeCost.currentCosts;

    const currentCostForType = ccsdCodeCost.currentCosts.find((currentCost) => {
      return String(currentCost.consultantType.code) === String(consultant.consultantType.code);
    });
    return (
      <span key={ccsdCodeCost.id}>
        {currentCostForType ? generalCurrencyFormatter(currentCostForType.cost) : 'n/a'}
        <TimelineCostsIcon ccsdCodeCost={ccsdCodeCost} consultantType={consultant.consultantType} />
      </span>
    );
  }

  getCellContent(consultantHealthInsurer, ccsdCode) {
    const costs = this.props.ccsdCodeCosts.filter((ccsdCodeCost) => {
      return (
        String(ccsdCodeCost.ccsdCode.reference) === String(ccsdCode.reference) &&
        Number(ccsdCodeCost.consultantHealthInsurer.id) === Number(consultantHealthInsurer.id)
      );
    });
    if (!costs || costs.length < 1) {
      return <MASpinner />;
    }
    return costs.map((ccsdCodeCost) => (
      <div key={ccsdCodeCost.id} data-key={ccsdCodeCost.id}>
        {this.getCcsdCodeSpan(consultantHealthInsurer.feeAssured, ccsdCodeCost)}
      </div>
    ));
  }

  getCcsdCodeCell(consultantHealthInsurer, ccsdCode) {
    return (
      <td className={'text-center'} key={consultantHealthInsurer.healthInsurer.id}>
        {this.getCellContent(consultantHealthInsurer, ccsdCode)}
      </td>
    );
  }

  getCcsdCodeRow(ccsdCode) {
    const { consultant } = this.props;

    const consultantHealthInsurerAnalogues = consultant.consultantHealthInsurers.filter(
      (chi) => chi.healthInsurer.isAnalogue && !chi.healthInsurer.canHaveOverrideLabel,
    );

    const consultantHealthInsurersAndOthers = consultant.consultantHealthInsurers
      .filter((chi) => !chi.healthInsurer.isAnalogue || chi.healthInsurer.canHaveOverrideLabel)
      .filter((chi) => Number(chi.healthInsurer.id) === Number(this.state.currentHealthInsurerId));
    return (
      <tr key={ccsdCode.id}>
        <th title={ccsdCode.name && ccsdCode.name.length > 0 ? ccsdCode.name : ''}>
          {ccsdCode.name}
          <br />
          <span className="font-weight-normal">{ccsdCode.reference}</span> <small>({capitalizeWords(String(ccsdCode.ccsdCodeType.code))} Code)</small>
        </th>

        {consultantHealthInsurerAnalogues.map((consultantHealthInsurer) => this.getCcsdCodeCell(consultantHealthInsurer, ccsdCode))}

        {consultantHealthInsurersAndOthers.map((consultantHealthInsurer) => this.getCcsdCodeCell(consultantHealthInsurer, ccsdCode))}
      </tr>
    );
  }

  setCodeFilter(e) {
    this.setState(() => ({
      codeFilter: e.target.value,
    }));
  }

  render() {
    const { consultant, ccsdCodes } = this.props;
    if (!consultant.consultantHealthInsurers) {
      return null;
    }
    const consultantHealthInsurerAnalogues = consultant.consultantHealthInsurers.filter(
      (chi) => chi.healthInsurer.isAnalogue && !chi.healthInsurer.canHaveOverrideLabel,
    );

    const consultantHealthInsurersAndOthers = consultant.consultantHealthInsurers
      .filter((chi) => !chi.healthInsurer.isAnalogue || chi.healthInsurer.canHaveOverrideLabel)
      .sort(sortHealthInsurersByName);
    return (
      <Row>
        <Col>
          <div className="mb-3">
            <div className="d-block d-sm-none">
              <p>Sorry - the fee table can only be viewed on a wider device</p>
            </div>

            <Table hover bordered className="mediaccounts-batch-invoice-ccsd-table mb-0 d-none d-sm-table bg-light">
              <thead>
                <tr>
                  <th className="stuck-row-1">
                    Code
                    <div className="ml-4 d-none d-sm-inline-flex">
                      <FormControl size={'sm'} placeholder="Filter by code..." aria-label="Type to filter by code" onChange={this.setCodeFilter.bind(this)} />
                    </div>
                  </th>
                  {consultantHealthInsurerAnalogues.map((chi) => (
                    <th className="batch-invoice-ccsd-table-title-cell stuck-row-1" key={chi.healthInsurer.id}>
                      {chi.healthInsurer.name}
                    </th>
                  ))}
                  <th className={'text-right stuck-row-1'}>
                    <div className={'form-group mb-0'}>
                      <select className={'form-control'} onChange={this.setCurrentHealthInsurerId.bind(this)}>
                        <option> - Select Insurer -</option>
                        {consultantHealthInsurersAndOthers.map((chi) => (
                          <option
                            className={chi.healthInsurer.canHaveOverrideLabel ? 'font-italic' : ''}
                            key={`i-` + chi.healthInsurer.id}
                            value={chi.healthInsurer.id + '-' + chi.id}>
                            {chi.healthInsurer.canHaveOverrideLabel && chi.overrideLabel ? chi.overrideLabel : chi.healthInsurer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th className="stuck-row-2">Fee Assured?</th>

                  {consultantHealthInsurerAnalogues.map((chi) => (
                    <th className={'text-center stuck-row-2'} key={`s-` + chi.healthInsurer.id}>
                      <Form.Check type="switch" defaultChecked={chi.feeAssured} id={`self-pay-${chi.healthInsurer.id}`} disabled />
                    </th>
                  ))}
                  {consultantHealthInsurersAndOthers
                    .filter((chi) => Number(chi.healthInsurer.id) === Number(this.state.currentHealthInsurerId))
                    .map((chi) => (
                      <th className={'text-center stuck-row-2'} key={`s2-` + chi.healthInsurer.id}>
                        <Form.Check type="switch" defaultChecked={chi.feeAssured} id={`self-pay-${chi.healthInsurer.id}`} disabled />
                      </th>
                    ))}
                </tr>
                <tr>
                  <th className="stuck-row-3">Provider No</th>
                  {consultantHealthInsurerAnalogues.map((chi) => (
                    <th className={'text-center stuck-row-3'} key={`r` + chi.healthInsurer.id}>
                      {chi.providerReference}
                    </th>
                  ))}

                  {consultantHealthInsurersAndOthers
                    .filter((chi) => Number(chi.healthInsurer.id) === Number(this.state.currentHealthInsurerId))
                    .map((chi) => (
                      <th className={'text-center stuck-row-3'} key={`r` + chi.healthInsurer.id}>
                        {chi.providerReference}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {ccsdCodes
                  .filter((ccsdCode) => this.state.codeFilter.length < 2 || ccsdCode.reference.toString().includes(this.state.codeFilter))
                  .map((ccsdCode) => this.getCcsdCodeRow(ccsdCode))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    );
  }
}

export default InvoiceBatchCcsdCodeCostTable;
