import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Cell } from 'react-sticky-table';
import { ccsdCodeCostSingleFetch } from '../../lib/redux/actions/ccsdCodeCosts';
import { datedCostSingleCreate } from '../../lib/redux/actions/datedCosts';
import moment from 'moment';
import { ccsdCodeSingleFetch } from '../../lib/redux/actions/ccsdCodes';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import { addToast } from '../../lib/redux/actions/toast';
import CcsdCodeCostAndTimelineIcon from './CcsdCodeCostAndTimelineIcon';

const mapDispatchToProps = {
  datedCostSingleCreate,
  ccsdCodeCostSingleFetch,
  ccsdCodeSingleFetch,
  addToast,
};

class EditableCcsdCodeCost extends React.Component {
  constructor(props) {
    super(props);

    this.todayDate = new Date();
    this.state = {
      isEditing: false,
      fieldValue: '',
      fieldValidFrom: this.todayDate.toISOString().substring(0, 10),
    };
  }

  handleCostChange(event) {
    this.setState(() => ({ fieldValue: event.target.value }));
  }

  handleDateChange(event) {
    this.setState(() => ({ fieldValidFrom: event.target.value }));
  }

  startEditing() {
    if (!isSeniorStaff()) {
      return;
    }
    this.setState(() => ({ isEditing: true }));
  }

  stopEditing(e) {
    e.stopPropagation();
    this.setState(() => ({ isEditing: false }));
  }

  saveAndStopEditing(e) {
    if (!isSeniorStaff()) {
      return;
    }

    if (this.state.fieldVale === '' || this.state.fieldValidFrom === '') {
      this.props.addToast('Please enter both a cost and a date.', false, true);
      return;
    }

    const { ccsdCode, consultantType, ccsdCodeCost = false, defaultCcsdCodeCost = false, addToast } = this.props;
    const data = {
      cost: Number(this.state.fieldValue),
      validFrom: moment.utc(this.state.fieldValidFrom).toISOString(),
      consultantType: consultantType['@id'],
    };

    if (defaultCcsdCodeCost !== false) {
      data['defaultCcsdCodeCost'] = defaultCcsdCodeCost['@id'];
    } else {
      data['ccsdCodeCost'] = ccsdCodeCost['@id'];
    }
    this.props.datedCostSingleCreate(data).then(() => {
      addToast('Cost added successfully', true);
      if (!ccsdCodeCost) {
        this.props.ccsdCodeSingleFetch(ccsdCode.id);
      } else {
        this.props.ccsdCodeCostSingleFetch(ccsdCodeCost.id);
      }
    });
    this.stopEditing(e);
  }

  renderContent() {
    const { consultantType, ccsdCodeCost = false, defaultCcsdCodeCost = false } = this.props;

    const ccsdCodeCostToUse = ccsdCodeCost ? ccsdCodeCost : defaultCcsdCodeCost;

    return this.state.isEditing ? (
      <div>
        <input
          autoFocus
          type="number"
          placeholder="0.00"
          step="0.01"
          min="0.00"
          title="New Cost"
          value={this.state.fieldValue}
          onChange={this.handleCostChange.bind(this)}
          className="form-control mb-1"
        />
        <input
          type="date"
          title="Cost Valid From Date"
          value={this.state.fieldValidFrom}
          onChange={this.handleDateChange.bind(this)}
          className="form-control mb-1"
        />
        <Button variant="primary" title="Save Cost Change" size="sm" className="mb-0" onClick={this.saveAndStopEditing.bind(this)}>
          <i className="fas fa-check" />
        </Button>
        <Button variant="danger" title="Cancel Cost Change" size="sm" className="mb-0" onClick={this.stopEditing.bind(this)}>
          <i className="fas fa-times" />
        </Button>
      </div>
    ) : (
      <CcsdCodeCostAndTimelineIcon ccsdCodeCostToUse={ccsdCodeCostToUse} consultantType={consultantType} />
    );
  }

  render() {
    const {
      ccsdCode = { reference: '' },
      healthInsurer,
      consultantHealthInsurer = false,
      isCell = false,
      ccsdCodeCost = false,
      defaultCcsdCodeCost = false,
    } = this.props;

    let labelToUse = healthInsurer.name;
    if (consultantHealthInsurer && consultantHealthInsurer.healthInsurer.canHaveOverrideLabel && consultantHealthInsurer.overrideLabel) {
      labelToUse = consultantHealthInsurer.overrideLabel;
    }
    const ok = ((healthInsurer?.id && defaultCcsdCodeCost?.id) || (consultantHealthInsurer?.id && ccsdCodeCost?.id)) && ccsdCode?.id;

    return isCell ? (
      <Cell className="text-right border-right" title={`Edit ${ccsdCode.reference} for ${labelToUse}`} onClick={this.startEditing.bind(this)}>
        {this.renderContent()}
        {!ok && (
          <p>
            <i
              title="There is a problem with the data for this cell"
              data-info={`hi:${healthInsurer.id}|chi:${consultantHealthInsurer.id}|cc:${ccsdCode.id}|ccc:${ccsdCodeCost.id}|dccc:${defaultCcsdCodeCost.id}`}
              className="text-danger fa fa-exclamation-triangle"
            />
          </p>
        )}
      </Cell>
    ) : (
      <td className="text-right" title={`Edit ${ccsdCode.reference} for ${labelToUse}`} onClick={this.startEditing.bind(this)}>
        {this.renderContent()}
        {!ok && (
          <p>
            <i
              title="There is a problem with the data for this cell"
              data-info={`hi:${healthInsurer.id}|chi:${consultantHealthInsurer.id}|cc:${ccsdCode.id}|ccc:${ccsdCodeCost.id}|dccc:${defaultCcsdCodeCost.id}`}
              className="text-danger fa fa-exclamation-triangle"
            />
          </p>
        )}
      </td>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditableCcsdCodeCost);
