import {
  CONSULTANT_BIBLE_LOG_ERROR,
  CONSULTANT_BIBLE_LOG_REQUEST,
  CONSULTANT_BIBLE_LOG_SET_PAGE,
  CONSULTANT_BIBLE_LOG_UNLOAD,
  CONSULTANT_BIBLE_LOG_UPDATED,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantBibleLogUpdated = (consultantBibleLog) => ({
  type: CONSULTANT_BIBLE_LOG_UPDATED,
  consultantBibleLog,
});

export const consultantBibleLogUnload = () => ({
  type: CONSULTANT_BIBLE_LOG_UNLOAD,
});

export const consultantBibleLogSetPage = (page) => ({
  type: CONSULTANT_BIBLE_LOG_SET_PAGE,
  page,
});
export const consultantBibleLogRequest = () => ({
  type: CONSULTANT_BIBLE_LOG_REQUEST,
});
export const consultantBibleLogError = (error) => ({
  type: CONSULTANT_BIBLE_LOG_ERROR,
  error,
});

export const getConsultantBibleLog = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(consultantBibleLogRequest);
    filters.page = page;
    return requests
      .get(`${API_ROUTES.CONSULTANT_BIBLE_LOG}`, filters)
      .then((response) => dispatch(consultantBibleLogUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantBibleLogError(error));
      });
  };
};
