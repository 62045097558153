import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { niceDateTimeFormatter } from '../../lib/helper/formatting';

class InvoiceAuditLog extends React.Component {
  render() {
    const { invoice } = this.props;

    return (
      <Row>
        <Col>
          <ul className="mb-0 list-unstyled">
            {invoice &&
              invoice.invoiceAuditLogs.length > 0 &&
              invoice.invoiceAuditLogs.reverse().map((log) => (
                <li key={log.id}>
                  {niceDateTimeFormatter(log.createdAt)} - {log.displayText}
                </li>
              ))}
            <li>{niceDateTimeFormatter(invoice.createdAt)} - Invoice Imported</li>
          </ul>
        </Col>
      </Row>
    );
  }
}

export default connect()(InvoiceAuditLog);
