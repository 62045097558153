import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import type { Consultant, ContactRecord } from '../../../apiTypes';
import { jspp } from '../../../lib/helper/general';
import { generalCurrencyFormatter, niceDateFormatter } from '../../../lib/helper/formatting';
import { ManageInvoiceContacts } from './ManageInvoiceContacts';
import { INVOICES } from '../../../lib/routing/frontend';
import { Link } from 'react-router-dom';

type InvoiceData = {
  patientName: string;
  patientDOB: string;
  invoiceNo: string;
  invoiceDate: string;
  serviceDate: string;
  address: string;
  amountDue: string;
  invoiceAmount: string;
  consultant: Consultant;
  existingInvoiceId?: string | number | boolean;
};

type Step3CardProps = {
  debugData?: {} | undefined;
  contacts: ContactRecord[];
  debug?: boolean;
  invoiceData: InvoiceData;
  reset: () => void;
  addNewContact: (newContact: ContactRecord) => boolean;
  removeContact: (contactToRemove: ContactRecord) => boolean;
  updateContact: (updatedContact: ContactRecord, origContact: ContactRecord) => boolean;
  children?: React.ReactNode;
  // eslint-disable-next-line no-empty-pattern
  createInvoiceAndContactRecords: ({}: any) => void;
};

export function Step3Card({
  invoiceData,
  contacts,
  debugData = undefined,
  reset,
  debug = false,
  addNewContact,
  removeContact,
  updateContact,
  createInvoiceAndContactRecords,
}: Step3CardProps) {
  const [contactsUnderEdit, setContactsUnderEdit] = useState(false);
  return (
    <Card className="mb-4">
      <Card.Header as={'h2'}>
        Review Patient/Contact Details{' '}
        <button type={'button'} className="btn btn-secondary float-right" onClick={reset}>
          Start Over
        </button>
      </Card.Header>
      <Card.Body>
        {debug && debugData && (
          <Row>
            <Col>
              <pre>{jspp(debugData)}</pre>
            </Col>
          </Row>
        )}
        <Row>
          <Col className={'col-sm-12 col-xl-3'}>
            <h5 className={'mb-3'}>Invoice Details</h5>
            <ul className={'list-unstyled'}>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Consultant:</span> <strong>{invoiceData.consultant.name}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Patient Name:</span> <strong>{invoiceData.patientName}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Patient DOB:</span> <strong>{niceDateFormatter(invoiceData.patientDOB)}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Invoice Number:</span> <strong>{invoiceData.invoiceNo}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Invoice Date:</span> <strong>{niceDateFormatter(invoiceData.invoiceDate)}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Service Date:</span> <strong>{niceDateFormatter(invoiceData.serviceDate)}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>Invoice Amount:</span> <strong>{generalCurrencyFormatter(invoiceData.invoiceAmount)}</strong>
              </li>
              <li className={'mb-2'}>
                <span className={'w-40 d-inline-block'}>O/S Amount:</span> <strong>{generalCurrencyFormatter(invoiceData.amountDue)}</strong>
              </li>
            </ul>
          </Col>
          <Col className={'col-sm-12 col-xl'}>
            <ManageInvoiceContacts
              setContactsUnderEdit={setContactsUnderEdit}
              contacts={contacts}
              updateContact={updateContact}
              addNewContact={addNewContact}
              removeContact={removeContact}
            />
          </Col>
        </Row>
        {invoiceData?.existingInvoiceId !== false && (
          <Link to={INVOICES.SINGLE.replace(':id', String(invoiceData.existingInvoiceId))} className={'btn btn-info'}>
            View existing Invoice
          </Link>
        )}
        {!invoiceData?.existingInvoiceId && (
          <button
            disabled={!contactsUnderEdit}
            onClick={() => createInvoiceAndContactRecords({ i: invoiceData, c: contacts })}
            className={'btn btn-primary float-right mt-4'}
            type={'button'}>
            {Number(invoiceData.amountDue) > 0 ? 'Create invoice and send notifications' : 'Create invoice'}
          </button>
        )}
      </Card.Body>
    </Card>
  );
}
