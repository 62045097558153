import {
  INVOICE_BATCH_ASSIGN_ERROR,
  INVOICE_BATCH_ASSIGN_RECEIVED,
  INVOICE_BATCH_ASSIGN_REQUEST,
  INVOICE_BATCH_LIST_CLEAR_FILTERS,
  INVOICE_BATCH_LIST_ERROR,
  INVOICE_BATCH_LIST_RECEIVED,
  INVOICE_BATCH_LIST_REQUEST,
  INVOICE_BATCH_LIST_SET_FILTERS,
  INVOICE_BATCH_LIST_SET_PAGE,
  INVOICE_BATCH_LIST_UNLOAD,
  INVOICE_BATCH_NOTE_DELETE_REQUEST,
  INVOICE_BATCH_NOTE_DELETED,
  INVOICE_BATCH_SINGLE_STATUS_CHANGED,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    invoiceBatches: [],
    isFetchingInvoiceBatches: false,
    isDeletingInvoiceBatchNote: false,
    currentPage: 1,
    pageCount: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_LIST_REQUEST:
    case INVOICE_BATCH_ASSIGN_REQUEST:
      return {
        ...state,
        isFetchingInvoiceBatches: true,
      };

    case INVOICE_BATCH_LIST_RECEIVED:
      return {
        ...state,
        invoiceBatches: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingInvoiceBatches: false,
      };
    case INVOICE_BATCH_ASSIGN_RECEIVED:
      return {
        ...state,
        isFetchingInvoiceBatches: false,
      };
    case INVOICE_BATCH_LIST_UNLOAD:
    case INVOICE_BATCH_LIST_ERROR:
    case INVOICE_BATCH_ASSIGN_ERROR:
      return {
        ...state,
        isFetchingInvoiceBatches: false,
        invoiceBatches: [],
      };

    case INVOICE_BATCH_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case INVOICE_BATCH_LIST_SET_FILTERS:
//      setFiltersInSession(action.routeKey || 'invoiceBatchList', action.filters.filter())
      return {
        ...state,
        filters: action.filters,
      };

    case INVOICE_BATCH_LIST_CLEAR_FILTERS:
//      setFiltersInSession(action.routeKey || 'invoiceBatchList', {})
      return {
        ...state,
        filters: {},
      };

    case INVOICE_BATCH_NOTE_DELETE_REQUEST:
      return {
        ...state,
        isDeletingInvoiceBatchNote: true,
      };
    case INVOICE_BATCH_NOTE_DELETED:
      return {
        ...state,
        isDeletingInvoiceBatchNote: false,
      };
    case INVOICE_BATCH_SINGLE_STATUS_CHANGED:
      return {
        ...state,
        invoiceBatches: state.invoiceBatches
          ? [
              ...state.invoiceBatches.map((item) => {
                // Find the item with the matching id
                if (item.id === action.data.id) {
                  // Return a new object
                  const new1 = {
                    ...item,
                  };
                  new1.status = action.data.status;
                  return new1;
                }

                // Leave every other item unchanged
                return item;
              }),
            ]
          : [],
      };

    default:
      return state;
  }
};

export default reducer;
