import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CreateForm from '../../components/forms/InvoiceBatch/CreateForm';

class InvoiceBatchCreate extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Upload invoices</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="crud__section mb-3">
                      <div className="crud__section-icon">
                        <i className="fas fa-upload" />
                      </div>
                      <h2 className="crud__section-header">Submit files</h2>
                      <p>Upload ZIP, PDF, JPG or DOCX files here for processing</p>
                      <CreateForm />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceBatchCreate;
