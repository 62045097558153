import { DATED_COST_LIST_ERROR, DATED_COST_LIST_REQUEST, DATED_COST_LIST_SET_PAGE, DATED_COST_LIST_UNLOAD, DATED_COST_LIST_UPDATED } from '../constants';
import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    datedCostList: [],
    currentPage: 1,
    pageCount: null,
    isFetchingDatedCostList: false,
  },
  action,
) => {
  switch (action.type) {
    case DATED_COST_LIST_REQUEST:
      return {
        ...state,
        isFetchingDatedCostList: true,
      };
    case DATED_COST_LIST_UPDATED:
      return {
        ...state,
        datedCostList: action.datedCostList['hydra:member'],
        pageCount: hydraPageCount(action.datedCostList),
        isFetchingDatedCostList: false,
      };
    case DATED_COST_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case DATED_COST_LIST_UNLOAD:
      return {
        datedCostList: [],
        currentPage: 1,
        pageCount: null,
        isFetchingDatedCostList: false,
      };
    case DATED_COST_LIST_ERROR:
      return {
        ...state,
        isFetchingDatedCostList: false,
        datedCostList: [],
      };
    default:
      return state;
  }
};

export default reducer;
