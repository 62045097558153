import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  BILLING_CHECKLIST_EVENT_LIST_REQUEST,
  BILLING_CHECKLIST_EVENT_LIST_RECEIVED,
  BILLING_CHECKLIST_EVENT_LIST_ERROR,
  BILLING_CHECKLIST_EVENT_LIST_SET_PAGE,
  BILLING_CHECKLIST_EVENT_LIST_UNLOAD,
  BILLING_CHECKLIST_EVENT_SINGLE_CREATE_REQUEST,
  BILLING_CHECKLIST_EVENT_SINGLE_CREATE_SUCCESS,
  BILLING_CHECKLIST_EVENT_SINGLE_REQUEST,
  BILLING_CHECKLIST_EVENT_SINGLE_RECEIVED,
  BILLING_CHECKLIST_EVENT_SINGLE_ERROR,
  BILLING_CHECKLIST_EVENT_LIST_SET_FILTERS,
  BILLING_CHECKLIST_EVENT_LIST_CLEAR_FILTERS,
  BILLING_CHECKLIST_EVENT_SINGLE_UPDATE_REQUEST,
  BILLING_CHECKLIST_EVENT_SINGLE_UPDATED,
  BILLING_CHECKLIST_EVENT_SINGLE_UNLOAD,
  BILLING_CHECKLIST_EVENT_DELETE_REQUEST,
  BILLING_CHECKLIST_EVENT_DELETED,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const billingChecklistEventListRequest = () => ({
  type: BILLING_CHECKLIST_EVENT_LIST_REQUEST,
});

export const billingChecklistEventListReceived = (data) => ({
  type: BILLING_CHECKLIST_EVENT_LIST_RECEIVED,
  data,
});

export const billingChecklistEventListError = (error) => ({
  type: BILLING_CHECKLIST_EVENT_LIST_ERROR,
  error,
});

export const billingChecklistEventListSetPage = (page) => ({
  type: BILLING_CHECKLIST_EVENT_LIST_SET_PAGE,
  page,
});

export const billingChecklistEventListSetFilters = (filters) => ({
  type: BILLING_CHECKLIST_EVENT_LIST_SET_FILTERS,
  filters,
});

export const billingChecklistEventListClearFilters = () => ({
  type: BILLING_CHECKLIST_EVENT_LIST_CLEAR_FILTERS,
});

export const billingChecklistEventListUnload = () => ({
  type: BILLING_CHECKLIST_EVENT_LIST_UNLOAD,
});

export const billingChecklistEventListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(billingChecklistEventListRequest());
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    if (filters.pagination) {
      filters.page = page;
    }
    return requests
      .get(API_ROUTES.BILLING_CHECKLIST_EVENTS, filters)
      .then((response) => dispatch(billingChecklistEventListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(billingChecklistEventListError(error));
      });
  };
};

// CONSULTANT_SINGLE

export const billingChecklistEventSingleRequest = () => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_REQUEST,
});

export const billingChecklistEventSingleReceived = (data) => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_RECEIVED,
  data,
});

export const billingChecklistEventSingleError = (error) => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_ERROR,
  error,
});

export const billingChecklistEventSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(billingChecklistEventSingleRequest());
    return requests
      .get(`${API_ROUTES.BILLING_CHECKLIST_EVENTS}/${id}`)
      .then((response) => dispatch(billingChecklistEventSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new billingChecklistEventSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(billingChecklistEventSingleError(error));
      });
  };
};

export const billingChecklistEventSingleCreateRequest = () => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_CREATE_REQUEST,
});

export const billingChecklistEventSingleCreateSuccess = (data) => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_CREATE_SUCCESS,
  data,
});

//export const billingChecklistEventSingleCreateError = (error) => ({
//  type: BILLING_CHECKLIST_EVENT_SINGLE_CREATE_ERROR,
//  error,
//});

export const billingChecklistEventSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(billingChecklistEventSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.BILLING_CHECKLIST_EVENTS}`, data)
      .then((response) => {
        dispatch(billingChecklistEventSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
          parsedError._error = 'It looks like you may be creating a Billing Checklist Event which already exists - please check and try again if not.';
        } else {
          parsedError._error =
            'There was a problem creating the Billing Checklist Event - please check the details and try again. If the problem persists please contact support.';
        }
        throw new SubmissionError(parsedError);
      });
  };
};

export const billingChecklistEventSingleUpdateRequest = () => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_UPDATE_REQUEST,
});

export const billingChecklistEventSingleUpdated = (data) => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_UPDATED,
  data,
});

export const billingChecklistEventSingleUpdate = (data, billingChecklistEventId) => {
  return (dispatch) => {
    dispatch(billingChecklistEventSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.BILLING_CHECKLIST_EVENTS}/${billingChecklistEventId}`, data)
      .then((response) => dispatch(billingChecklistEventSingleUpdated(response)))
      .catch((error) => {
        dispatch(billingChecklistEventSingleError(''));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const billingChecklistEventSingleUnload = () => ({
  type: BILLING_CHECKLIST_EVENT_SINGLE_UNLOAD,
});

export const billingChecklistEventDeleteRequest = () => ({
  type: BILLING_CHECKLIST_EVENT_DELETE_REQUEST,
});

export const billingChecklistEventDeleted = (id) => ({
  type: BILLING_CHECKLIST_EVENT_DELETED,
  id,
});

export const billingChecklistEventDelete = (billingChecklistEventId) => {
  return (dispatch) => {
    dispatch(billingChecklistEventDeleteRequest());
    return requests
      .delete(`${API_ROUTES.BILLING_CHECKLIST_EVENTS}/${billingChecklistEventId}`)
      .then(() => dispatch(billingChecklistEventDeleted(billingChecklistEventId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
