import React, { useCallback, useEffect } from 'react';
import { generatePaymentLink } from '../../lib/redux/actions/paymentLink';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { invoiceAmountFormatter } from '../../lib/helper/formatting';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';

export const WORLDPAY_SCRIPT = 'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js';

const scriptAlreadyLoaded = (src) => {
  return document.querySelector(`script[src="${src}"]`);
};
const loadWorldPayScript = (src) => {
  return new Promise((resolve, reject) => {
    if (scriptAlreadyLoaded(src)) {
      resolve();
      return;
    }

    let worldpayScript = document.createElement('script');
    worldpayScript.src = src;
    worldpayScript.onload = resolve;
    worldpayScript.onerror = reject;
    document.head.appendChild(worldpayScript);
  });
};

const WorldpayPaymentContainer = ({ invoice, paymentLink, isFetchingPaymentLink }) => {
  const history = useHistory();
  useEffect(() => {
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, []);

  const handleWorldpayResponse = useCallback(
    (responseData) => {
      history.push(ROUTES.PUBLIC_PATIENT_INVOICES.PAYMENT_RESPONSE.replace(':response', responseData?.order?.status ?? 'unknown'), {
        invoice: invoice,
      });
    },
    [history, invoice],
  );

  const addWorldpayIframe = useCallback(
    (invoice, paymentLink, target = 'worldpay-iframe-container') => {
      if (typeof window !== 'undefined') {
        let libraryObject = new WPCL.Library(); // eslint-disable-line
        libraryObject.setup({
          url: paymentLink,
          orderSummary: true,
          type: 'iframe',
          inject: 'immediate',
          target: target,
          accessibility: true,
          debug: false,
          resultCallback: handleWorldpayResponse,
        });
      }
    },
    [handleWorldpayResponse],
  );

  useEffect(() => {
    loadWorldPayScript(WORLDPAY_SCRIPT)
      .then(() => {
        addWorldpayIframe(invoice, paymentLink, 'worldpay-iframe-container');
      })
      .catch(console.warn);
  }, [addWorldpayIframe, invoice, paymentLink]);

  if (isFetchingPaymentLink) {
    return <>Loading...</>;
  }

  if (isFetchingPaymentLink) {
    return <>Loading...</>;
  }

  return (
    <Card className="worldpay-iframe-card">
      <Card.Header as={'h2'}>
        You are paying {invoiceAmountFormatter(invoice.outstandingBalance)} against invoice: {invoice.invoiceNo}
      </Card.Header>
      <Card.Body>
        <div id="worldpay-iframe-container" />
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.invoices,
    ...state.paymentLink,
  };
};

const mapDispatchToProps = {
  generatePaymentLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorldpayPaymentContainer);
