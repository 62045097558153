import {
  CCSD_CODE_LIST_REQUEST,
  CCSD_CODE_LIST_RECEIVED,
  CCSD_CODE_LIST_ERROR,
  CCSD_CODE_LIST_SET_PAGE,
  CCSD_CODE_LIST_UNLOAD,
  CCSD_CODE_SINGLE_REQUEST,
  CCSD_CODE_SINGLE_RECEIVED,
  CCSD_CODE_SINGLE_ERROR,
  CCSD_CODE_LIST_SET_FILTERS,
  CCSD_CODE_LIST_CLEAR_FILTERS,
  CCSD_CODE_SINGLE_UPDATE_REQUEST,
  CCSD_CODE_SINGLE_UPDATED,
  CCSD_CODE_SINGLE_UNLOAD,
  CCSD_CODE_DELETE_REQUEST,
  CCSD_CODE_DELETED,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    ccsdCodes: [],
    isFetchingCcsdCodes: false,
    currentPage: 1,
    isUpdatingCcsdCode: false,
    isFetchingCcsdCode: false,
    hasLoadedCcsdCode: false,
    pageCount: null,
    ccsdCode: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case CCSD_CODE_LIST_UNLOAD:
      return {
        ccsdCodes: [],
        isFetchingCcsdCodes: false,
        currentPage: 1,
        pageCount: null,
        filters: {},
      };

    case CCSD_CODE_LIST_REQUEST:
      return {
        ...state,
        isFetchingCcsdCodes: true,
      };

    case CCSD_CODE_LIST_RECEIVED:
      return {
        ...state,
        ccsdCodes: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingCcsdCodes: false,
      };

    case CCSD_CODE_LIST_ERROR:
      return {
        ...state,
        isFetchingCcsdCodes: false,
      };
    case CCSD_CODE_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case CCSD_CODE_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingCcsdCode: true,
      };

    case CCSD_CODE_SINGLE_RECEIVED:
      return {
        ...state,
        ccsdCodes: [
          ...state.ccsdCodes.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        hasLoadedCcsdCode: true,
        ccsdCode: action.data,
        isFetchingCcsdCode: false,
      };

    case CCSD_CODE_SINGLE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingCcsdCode: false,
      };

    case CCSD_CODE_SINGLE_UNLOAD:
      return {
        ...state,
        ccsdCode: null,
        hasLoadedCcsdCode: false,
        error: null,
        isFetchingCcsdCode: false,
        isUpdatingCcsdCode: false,
      };

    case CCSD_CODE_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case CCSD_CODE_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    case CCSD_CODE_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingCcsdCode: true,
      };
      return state;

    case CCSD_CODE_SINGLE_UPDATED:
      return {
        ...state,
        ccsdCodes: [
          ...state.ccsdCodes.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        ccsdCode: action.data,
        isUpdatingCcsdCode: false,
      };
    case CCSD_CODE_DELETE_REQUEST:
      return {
        ...state,
        isDeletingCcsdCode: true,
      };
    case CCSD_CODE_DELETED:
      return {
        ...state,
        isDeletingCcsdCode: false,
      };

    default:
      return state;
  }
};

export default reducer;
