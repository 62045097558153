import { generalCurrencyFormatter } from '../../lib/helper/formatting';
import TimelineCostsIcon from './TimelineCostsIcon';
import React from 'react';

const CcsdCodeCostAndTimelineIcon = ({ ccsdCodeCostToUse, consultantType }) => {
  const currentCostForType = ccsdCodeCostToUse.currentCosts.find((currentCost) => {
    return String(currentCost.consultantType?.code) === String(consultantType.code);
  });
  return (
    <>
      {currentCostForType && !isNaN(currentCostForType.cost) ? generalCurrencyFormatter(currentCostForType.cost) : '-'}
      <TimelineCostsIcon ccsdCodeCost={ccsdCodeCostToUse} consultantType={consultantType} />
    </>
  );
};

export default CcsdCodeCostAndTimelineIcon;
