import { INVOICE_ACTION_STATS_RECEIVED, INVOICE_ACTION_STATS_REQUEST } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceActionStatsReceived = (invoiceActionStats) => ({
  type: INVOICE_ACTION_STATS_RECEIVED,
  invoiceActionStats,
});

export const invoiceActionStatsRequest = () => ({
  type: INVOICE_ACTION_STATS_REQUEST,
});

export const getInvoiceActionStats = (filters = {}) => {
  return (dispatch) => {
    dispatch(invoiceActionStatsRequest());
    return requests
      .get(`${API_ROUTES.INVOICE_ACTION_STATS}`, filters)
      .then((response) => dispatch(invoiceActionStatsReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
      });
  };
};
