import React from 'react';

class Denied extends React.Component {
  render() {
    return (
      <div className="text-center p-5">
        <i className="fa fa-ban display-1 text-danger mb-2" />
        <h1>Error 403</h1>
        <p>You do not have permission access to this page</p>
      </div>
    );
  }
}

export default Denied;
