import React from 'react';

import { setDefaultLocale } from 'react-datepicker';

import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { userLogout, userProfileFetch, userSetId } from './lib/redux/actions/user';

import { history } from './lib/bootstrap/configureStore';
import { requests } from './lib/helper/agent';

import Routes from './Routes';
import { getStats } from './lib/redux/actions/stats';
import { isClient } from './lib/helper/authorisation';

setDefaultLocale('en');

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  userProfileFetch,
  userSetId,
  userLogout,
  getStats,
};

class App extends React.Component {
  constructor(props) {
    super(props);

    const token = window.localStorage.getItem('jwtToken');
    if (token) {
      requests.setToken(token);
    }
  }

  componentWillUnmount() {
    clearInterval(this.statsTimer);
    this.statsTimer = null;
  }

  componentDidMount() {
    const { userSetId, userLogout } = this.props;
    const userId = window.localStorage.getItem('userId');
    if (userId) {
      userSetId(userId);
    } else {
      userLogout();
    }
  }

  componentDidUpdate(prevProps) {
    const { userId, userProfileFetch, getStats } = this.props;

    if (prevProps.userId !== userId && userId !== null) {
      userProfileFetch(userId).then(() => {
        if (window.tidioChatApi && !isClient()) {
          window.tidioChatApi.hide();
        }
        const stopGettingStats = () => {
          clearInterval(this.statsTimer);
          this.statsTimer = null;
        };
        getStats(stopGettingStats);
        this.statsTimer = setInterval(
          () =>
            getStats(stopGettingStats).catch(() => {
              stopGettingStats();
            }),
          Number(process.env.REACT_APP_STATS_UPDATE_INTERVAL_MS || 60000),
        );
      });
    }
  }

  render() {
    const { userId } = this.props;
    return (
      <ConnectedRouter history={history}>
        <Routes userId={userId} />
      </ConnectedRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
