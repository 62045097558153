import React, { Component } from 'react';
import { connect } from 'react-redux';
import { consultantSingleFetch, consultantSingleUnload } from '../../lib/redux/actions/consultant';
import ConsultantSingleTableView from './ConsultantSingleTableView';
import MASpinner from '../common/MASpinner';

const mapStateToProps = (state) => {
  return {
    ...state.consultantSingle,
  };
};

const mapDispatchToProps = {
  consultantSingleFetch,
  consultantSingleUnload,
};

class ConsultantSingleTableContainer extends Component {
  componentDidMount() {
    const { consultantId } = this.props;
    this.props.consultantSingleFetch(consultantId);
  }

  componentWillUnmount() {
    this.props.consultantSingleUnload();
  }

  render() {
    const { consultant, isFetchingConsultant } = this.props;
    if (!consultant || isFetchingConsultant) {
      return <MASpinner />;
    }
    return (
      <div className="crud__section mb-3">
        <ConsultantSingleTableView consultant={consultant} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantSingleTableContainer);
