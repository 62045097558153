import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PDFTextExtractor from '../../../components/invoice/PDFTextExtractor';
import React from 'react';

type Step1CardProps = {
  setExtractedText: (text: string) => void;
  setFile: (file: any) => void;
  setRawFile: (file: any) => void;
};

export function Step1Card({ setExtractedText, setFile, setRawFile }: Step1CardProps) {
  return (
    <Card className="mb-4">
      <Card.Header as={'h2'}>Upload an Invoice PDF</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <div className="crud__section mb-3">
              <div className="crud__section-icon">
                <i className="fas fa-upload" />
              </div>
              <h2 className="crud__section-header">Submit Invoice File</h2>
              <p>Upload a PDF file here for processing</p>
              <PDFTextExtractor setExtractedText={setExtractedText} setFile={setFile} setRawFile={setRawFile} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
