import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { userLogout } from '../../lib/redux/actions/user';
import Toasts from '../common/Toasts';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  userLogout,
};

class MinimalLayout extends React.Component {
  render() {
    const { children, isAuthenticated, is2faAuthenticated, userData, userLogout, linkLogo = true } = this.props;

    return (
      <Container className="app app-minimal" fluid>
        <Row>
          <Header isAuthenticated={isAuthenticated} is2faAuthenticated={is2faAuthenticated} userData={userData} userLogout={userLogout} linkLogo={linkLogo} />
        </Row>
        <Row>
          <Col>
            <main className="main-content">
              {children}
              <Toasts />
            </main>
          </Col>
        </Row>
      </Container>
    );
  }
}

MinimalLayout.propTypes = {
  children: PropTypes.node,
};

export default connect(mapStateToProps, mapDispatchToProps)(MinimalLayout);
