import React from 'react';
import Table from 'react-bootstrap/Table';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getCurrentWeekRange } from '../../lib/helper/calendarTableHelper';
import EditableWorkScheduleCell from './EditiableChaserWorkScheduleCell';
import { isChaserOutsourcer } from '../../lib/helper/authorisation';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';

const moment = extendMoment(Moment);

class ChaserWorkScheduleTable extends React.Component {
  constructor(props) {
    super(props);
    this.today = moment().utc();
    //    this.state = {
    //      totals: []
    //    };
    this.totals = [];
  }

  renderHeaderCell(day) {
    if (day.format('dddd') === 'Wednesday') {
      return (
        <th key={`th-${day.unix()}`}>
          <span className="date">{'Requested Hours'}</span>
        </th>
      );
    }
  }

  renderHeader() {
    const { currentDate } = this.props;
    const headerCells = [];
    for (let day of getCurrentWeekRange(currentDate).by('day')) {
      headerCells.push(this.renderHeaderCell(day));
    }
    return (
      <thead>
        <tr>
          <th style={{ width: '180px' }}>Account Name</th>
          <th style={{ width: '180px' }}>Invoices Assigned</th>
          {headerCells}
        </tr>
      </thead>
    );
  }

  renderRow(user) {
    if (!user) {
      return null;
    }

    if (!user.isEnabled) {
      return null;
    }
    return (
      <tr key={user.id}>
        <th>{user.firstName + ' ' + user.lastName}</th>
        <th style={{ textAlign: 'center' }}>
          <Link to={ROUTES.INVOICES.ALL.replace('/:page', '') + 'assignedTo=' + user['@id']}>{user?.chInvCnt ?? 0}</Link>
        </th>
        {this.renderRowCells(user)}
      </tr>
    );
  }

  renderRowCells(user) {
    const { workSchedules, currentDate, workScheduleWeeklyEvents = [] } = this.props;
    const userSchedules = workSchedules
      ? workSchedules.find((schedule) => {
          return schedule?.user?.id === user?.id;
        })
      : [];

    const range = getCurrentWeekRange(currentDate);
    const cells = [];
    for (let day of range.by('day')) {
      cells.push(this.renderCell(user, userSchedules, day, workScheduleWeeklyEvents));
    }
    return cells;
  }

  renderTotals() {
    const { currentDate } = this.props;
    const range = getCurrentWeekRange(currentDate);
    const cells = [];
    for (let day of range.by('day')) {
      if (day.format('dddd') === 'Wednesday') {
        cells.push(
          <td className={'status font-weight-bold'} key={day.format('DMMY')}>
            <p>{this.totals[day.format('DMMY')]}</p>
          </td>,
        );
      }
    }
    return cells;
  }

  renderCell(user, userSchedules, cellDate) {
    let day = cellDate.format('dddd');
    cellDate.set('hour', 12);
    if (!this.totals[cellDate.format('DMMY')]) {
      this.totals[cellDate.format('DMMY')] = 0;
    }
    if (day === 'Wednesday') {
      this.totals[cellDate.format('DMMY')] += userSchedules && typeof userSchedules['requestHours'] !== 'undefined' ? userSchedules['requestHours'] : 0;
      return (
        <EditableWorkScheduleCell key={`${user.id}_${cellDate.format('YYYY-MM-DD')}`} day={day} cellDate={cellDate} userSchedules={userSchedules} user={user} />
      );
    }
  }

  render() {
    const { users = [], currentDate } = this.props;
    this.totals = [];
    if (users.length < 1) {
      return null;
    }
    const currentMonday = getCurrentWeekRange(currentDate).start;
    return (
      <Table hover className="mediaccounts-billing-checklist-table mb-0 d-none d-sm-table">
        {this.renderHeader()}
        <tbody>
          {users
            .sort((user1, user2) => {
              return user1.lastName.localeCompare(user2.lastName);
            })
            .map((user) => this.renderRow(user, currentMonday))}
          {!isChaserOutsourcer(true) && (
            <tr className={'mediaccounts-work-schedule-totals'}>
              <th>Week Totals</th>
              {this.renderTotals()}
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default ChaserWorkScheduleTable;
