import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { invoiceUnassign } from '../../lib/redux/actions/invoices';
import { addToast } from '../../lib/redux/actions/toast';
import UpdateForm from '../../components/forms/Invoice/ImportPaidCSVForm';
import moment from 'moment';
function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    currentFormValues: getFormValues('CreateForm')(state),
    isUnassigningInvoices: state.invoices.isUnassigningInvoices,

    ...state.invoiceFiles,
  };
}

const mapDispatchToProps = {
  invoiceUnassign,
  addToast,
};

class InvoiceImportPaidCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      unassigned: [],
      updatedDate: null,
      confirmed: false,
    };
    this.setErrors.bind(this);
    this.setUnassigned.bind(this);
    this.setUpdatedDate.bind(this);
    this.timeoutHandle = false;
  }

  setErrors(errors) {
    this.setState({
      errors: [...errors],
    });
  }

  setUnassigned(unassigned) {
    this.setState({
      unassigned: Object.entries(unassigned),
    });
  }

  confirm() {
    this.setState(() => ({ confirmed: true }));

    this.timeoutHandle = setTimeout(() => {
      this.setState(() => ({ confirmed: false }));
    }, 10000);
  }

  unassignInvoices() {
    return this.props.invoiceUnassign().then((response) => {
      this.props.addToast(`${response.unassigned} invoices unassigned`, true);
      this.setState({ confirmed: false });
    });
  }

  setUpdatedDate(date) {
    this.setState({ updatedDate: date });
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Upload Paid Invoices CSV</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="crud__section mb-3" style={{ height: '400px' }}>
                      <div className="crud__section-icon">
                        <i className="fas fa-upload" />
                      </div>
                      <h2 className="crud__section-header">Submit files</h2>
                      <p>Upload credit control paid invoice CSV files (from Medicodes) here for updating</p>
                      <UpdateForm
                        setErrors={(errors) => this.setErrors(errors)}
                        setUnassigned={(unassigned) => this.setUnassigned(unassigned)}
                        setUpdatedDate={(date) => this.setUpdatedDate(date)}
                      />
                    </div>
                  </Col>
                  {this.state.errors.length > 0 ? (
                    <Col>
                      <Row>
                        <Col>
                          <div className="crud__section mb-3" style={{ overflow: 'auto', height: '400px' }}>
                            <h2 className="crud__section-header" style={{ paddingLeft: 0 }}>
                              Log from import on {moment(this.state.updatedDate).format('DD/MM/YYYY HH:mm:ss')}
                            </h2>
                            <pre>
                              {this.state.errors.map((error) => {
                                return <p className={'mb-1'}>{error}</p>;
                              })}
                            </pre>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                  {this.state.unassigned.length > 0 ? (
                    <Col>
                      <div className="crud__section mb-3" style={{ overflow: 'auto', height: '400px' }}>
                        <h2 className="crud__section-header" style={{ paddingLeft: 0 }}>
                          Invoices Unassigned on {moment(this.state.updatedDate).format('DD/MM/YYYY HH:mm:ss')}
                        </h2>
                        <pre>
                          {this.state.unassigned.map((value) => {
                            return (
                              <p key={value[0]} className={'mb-1'}>
                                Outsourcer {value[0]} - {value[1].length} invoices removed as fully paid
                              </p>
                            );
                          })}
                        </pre>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceImportPaidCSV);
