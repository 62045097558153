import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import { addToast } from '../../lib/redux/actions/toast';
import moment from 'moment';
import { consultantTicketSingleUpdate, consultantTicketSingleFetch } from '../../lib/redux/actions/consultantTickets';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  consultantTicketSingleUpdate,
  consultantTicketSingleFetch,
  addToast,
};

class MarkAsActionedButton extends React.Component {
  markAsActioned() {
    const { consultantTicketSingleUpdate, consultantTicket, addToast, consultantTicketSingleFetch } = this.props;
    return consultantTicketSingleUpdate(
      {
        actionedDate: moment().utc(),
      },
      consultantTicket.id,
    ).then(() => {
      consultantTicketSingleFetch(consultantTicket.id);
      addToast('The ticket was marked as actioned', true);
    });
  }

  render() {
    const { consultantTicket } = this.props;
    if (!isSeniorStaff() || consultantTicket?.actionedDate || consultantTicket?.actionedBy) {
      return null;
    }
    return (
      <button type="button" onClick={this.markAsActioned.bind(this)} className="btn btn-primary">
        <i className="fa fa-check mr-2" />
        Mark as Actioned
      </button>
    );
  }
}

MarkAsActionedButton.propTypes = {
  consultantTicket: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkAsActionedButton);
