import React from 'react';
import UserTypeahead from './UserTypeahead';
import { userNameFormatter } from '../../lib/helper/formatting';

class NewPrimaryUserTypeahead extends React.Component {
  render() {
    const { label = 'Primary User', name = 'primaryUser', ...rest } = this.props;
    return (
      <UserTypeahead
        labelKey={(option) => userNameFormatter(option, false, true)}
        label={label}
        name={name}
        defaultFilters={{ roles: '["ROLE_PRIMARY_USER"]', 'exists[consultantAsPrimaryUser]': false }}
        {...rest}
      />
    );
  }
}

export default NewPrimaryUserTypeahead;
