import React from 'react';
import { connect } from 'react-redux';
import { workScheduleEventSingleFetch, workScheduleEventSingleUnload, workScheduleEventDelete } from '../../lib/redux/actions/workScheduleEvents';
import WorkScheduleEventSingle from './WorkScheduleEventSingle';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';
import { consultantListFetch } from '../../lib/redux/actions/consultant';

const mapStateToProps = (state) => ({
  ...state.consultant,
  ...state.workScheduleEvents,
});

const mapDispatchToProps = {
  consultantListFetch,
  workScheduleEventSingleFetch,
  workScheduleEventSingleUnload,
  workScheduleEventDelete,
  addToast,
};

class WorkScheduleEventSingleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  componentDidMount() {
    this.props.consultantListFetch({}, { pagination: false });
    this.props.workScheduleEventSingleFetch(this.props.match.params.id);
  }

  delete() {
    const { addToast, history } = this.props;
    if (!this.state.deleting) {
      this.setState(() => {
        return { deleting: true };
      });
    } else {
      this.props.workScheduleEventDelete(this.props.workScheduleEvent.id).then(() => {
        addToast('Work Schedule Event deleted successfully', true);
        this.setState(() => {
          return { deleting: false };
        });
        history.push(ROUTES.WORK_SCHEDULE_EVENTS.ALL.replace(':page', ''));
      });
    }
  }

  componentWillUnmount() {
    this.props.workScheduleEventSingleUnload();
  }

  render() {
    const { workScheduleEvent, isFetchingWorkScheduleEvent, hasLoadedWorkScheduleEvent, consultants } = this.props;
    if (!workScheduleEvent || (!hasLoadedWorkScheduleEvent && isFetchingWorkScheduleEvent)) {
      return <MASpinner />;
    }
    return (
      <WorkScheduleEventSingle
        workScheduleEvent={workScheduleEvent}
        consultants={consultants}
        isDeleting={this.state.deleting}
        deleteWorkScheduleEvent={this.delete.bind(this)}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkScheduleEventSingleContainer);
