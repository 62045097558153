import { Field } from 'redux-form';
import React from 'react';
import { consultantsTypeaheadListFetch } from '../../lib/redux/actions/consultantsTypeahead';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TypeAheadField from '../common/reduxForm/TypeaheadField';

const mapStateToProps = (state) => ({
  ...state.consultantsTypeaheadList,
});
const mapDispatchToProps = {
  consultantsTypeaheadListFetch,
};

class ConsultantTypeahead extends React.Component {
  componentDidUpdate() {
    const { shouldClear, hasCleared } = this.props;
    if (shouldClear) {
      this.consultantRef.getRenderedComponent().clear();
      hasCleared();
    }
  }

  _onSearch(query) {
    this.props.consultantsTypeaheadListFetch({ nameAndCode: query });
  }

  render() {
    const {
      onChange,
      consultants = [],
      horizontal = false,
      isFetchingConsultants,
      multiple = true,
      label = 'Account',
      name = 'consultant',
      selectedConsultants = null,
    } = this.props;

    return (
      <Field
        name={name}
        isLoading={isFetchingConsultants}
        label={label}
        horizontal={horizontal}
        onSearch={this._onSearch.bind(this)}
        options={consultants || []}
        labelKey={(option) => `${option.name} (${option.code})`}
        component={TypeAheadField}
        multiple={multiple}
        onChange={onChange}
        ref={(ref) => (this.consultantRef = ref)}
        forwardRef
        selected={selectedConsultants}
      />
    );
  }
}

ConsultantTypeahead.propTypes = {
  shouldClear: PropTypes.bool.isRequired,
  hasCleared: PropTypes.func.isRequired,
  isFetchingConsultants: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  consultantsTypeaheadListFetch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantTypeahead);
