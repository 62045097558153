import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField, renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { invoiceBatchStatusListFetch } from '../../../lib/redux/actions/invoiceBatchStatus';
import { invoiceBatchUrgentCategoryListFetch } from '../../../lib/redux/actions/invoiceBatchUrgentCategory';
import { invoiceBatchListClearFilters, invoiceBatchListSetFilters } from '../../../lib/redux/actions/invoiceBatch';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';
import AssignToUserTypeahead from '../../fields/AssignToUserTypeahead';
import MASpinner from '../../common/MASpinner';
import moment from 'moment';
import { invoiceBatchProblemCategoryListFetch } from '../../../lib/redux/actions/invoiceBatchProblemCategory';

const mapStateToProps = (state) => ({
  ...state.invoiceBatchStatusList,
  ...state.invoiceBatchUrgentCategoryList,
  ...state.invoiceBatchProblemCategoryList,
});
const mapDispatchToProps = {
  invoiceBatchStatusListFetch,
  invoiceBatchListSetFilters,
  invoiceBatchListClearFilters,
  invoiceBatchUrgentCategoryListFetch,
  invoiceBatchProblemCategoryListFetch,
};

class InvoiceBatchTableFilterForm extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceBatchStatusListFetch, invoiceBatchUrgentCategoryListFetch, invoiceBatchProblemCategoryListFetch } = this.props;
    invoiceBatchStatusListFetch();
    invoiceBatchUrgentCategoryListFetch();
    invoiceBatchProblemCategoryListFetch();

    this.state = {
      selectedConsultants: [],
      selectedAssignedToUsers: [],
      clearTypeaheads: false,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    if (this.state.selectedConsultants.length) {
      values['consultant.id'] = this.state.selectedConsultants.map((c) => c['@id']);
    }
    if (this.state.selectedAssignedToUsers.length) {
      values['assignedUser'] = this.state.selectedAssignedToUsers.map((u) => u['@id']);
    }
    if (values.invoiceDates && values.invoiceDates.date) {
      values['invoiceDates.date(after)'] = values.invoiceDates.date;
      values['invoiceDates.date(strictly_before)'] = moment(values.invoiceDates.date).add(1, 'day').format('YYYY-MM-DD');
    }

    if (values['invoiceNumbers'] && values['invoiceNumbers'].length) {
      values['invoiceNumbers'] = `"${values['invoiceNumbers']}"`;
    }
    this.props.invoiceBatchListSetFilters(values);
  }

  resetFilters() {
    const { reset, invoiceBatchListClearFilters } = this.props;
    invoiceBatchListClearFilters();
    this.clearTypeaheads();
    this.setState(() => ({
      selectedConsultants: [],
      selectedAssignedToUsers: [],
    }));
    reset();
  }

  getFiltersToShow() {
    const { filterOverrides = {} } = this.props;
    return {
      ...{
        dateRange: true,
        consultant: true,
        assignedUser: true,
        status: true,
        isUrgent: true,
        isProblem: true,
        isEmbassy: true,
        invoiceNumbers: true,
        urgentCategory: false,
      },
      ...filterOverrides,
    };
  }

  render() {
    const { handleSubmit, error, invoiceBatchStatuses = {}, invoiceBatchUrgentCategories = [], invoiceBatchProblemCategories } = this.props;

    if (!invoiceBatchStatuses || !invoiceBatchUrgentCategories || !invoiceBatchProblemCategories) {
      return <MASpinner />;
    }

    const filtersToShow = this.getFiltersToShow();

    const urgentOptions = [
      { value: 1, text: 'Urgent' },
      { value: 0, text: 'Not Urgent' },
    ];
    const problemOptions = [
      { value: 1, text: 'Problem' },
      { value: 0, text: 'Not Problem' },
    ];
    const embassyOptions = [
      { value: 1, text: 'Is Embassy' },
      { value: 0, text: 'Not Embassy' },
    ];
    const urgentCategoryOptions = [];
    invoiceBatchUrgentCategories.forEach((invoiceBatchUrgentCategory) => {
      urgentCategoryOptions.push({ value: invoiceBatchUrgentCategory['@id'], text: invoiceBatchUrgentCategory.displayName });
    });

    const problemCategoryOptions = [];
    invoiceBatchProblemCategories.forEach((invoiceBatchProblemCategory) => {
      problemCategoryOptions.push({ value: invoiceBatchProblemCategory['@id'], text: invoiceBatchProblemCategory.displayName });
    });

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters col">
          <div className="row">
            <Field name="id" label="Batch ID" type="number" component={renderField} />
            {filtersToShow.dateRange && <Field name="createdAt(after)" label="Uploaded Date From" type="date" component={renderField} />}
            {filtersToShow.dateRange && <Field name="createdAt(before)" label="Uploaded Date To" type="date" component={renderField} />}
            {filtersToShow.consultant && (
              <ConsultantTypeahead
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedConsultants: selected };
                  });
                }}
              />
            )}

            {filtersToShow.assignedUser && (
              <AssignToUserTypeahead
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedAssignedToUsers: selected };
                  });
                }}
                label="Assigned To"
              />
            )}
          </div>
          <div className="row">
            {(filtersToShow.dateRange || filtersToShow.invoiceDates) && (
              <Field name="invoiceDates.date" label="Includes Invoice for Date" type="date" component={renderField} />
            )}
            {filtersToShow.invoiceNumbers && <Field name="invoiceNumbers" label="Related Invoice Number" type="number" component={renderField} />}

            {filtersToShow.status && (
              <Field
                name="status"
                label="Status"
                options={invoiceBatchStatuses.map((status) => {
                  return { value: status['@id'], text: status.name };
                })}
                component={renderSelect}
              />
            )}

            {filtersToShow.isUrgent && <Field name="isUrgent" label="Urgency" type="select" options={urgentOptions} component={renderSelect} />}
            {filtersToShow.urgentCategory && (
              <Field name="invoiceBatchUrgentCategory" label="Urgency Category" type="select" options={urgentCategoryOptions} component={renderSelect} />
            )}

            {filtersToShow.isProblem && <Field name="isProblem" label="Problems?" type="select" options={problemOptions} component={renderSelect} />}
            {filtersToShow.problemCategory && (
              <Field name="invoiceBatchProblemCategory" label="Problem Category" type="select" options={problemCategoryOptions} component={renderSelect} />
            )}

            {filtersToShow.isEmbassy && <Field name="isEmbassy" label="Embassy?" type="select" options={embassyOptions} component={renderSelect} />}

            <div className="filter-actions">
              <button type="submit" className="btn btn-primary btn-sm">
                Filter
              </button>
              <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
                Reset
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'InvoiceTableFilterForm',
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchTableFilterForm));
