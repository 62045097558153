import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_RECEIVED,
  INVOICE_LIST_ERROR,
  INVOICE_LIST_SET_PAGE,
  INVOICE_LIST_UNLOAD,
  INVOICE_SINGLE_REQUEST,
  INVOICE_SINGLE_RECEIVED,
  INVOICE_SINGLE_ERROR,
  INVOICE_LIST_SET_FILTERS,
  INVOICE_LIST_CLEAR_FILTERS,
  INVOICE_SINGLE_UPDATE_REQUEST,
  INVOICE_SINGLE_UPDATED,
  INVOICE_SINGLE_UNLOAD,
  INVOICE_DELETE_REQUEST,
  INVOICE_DELETED,
  INVOICE_SINGLE_ACTION_REQUEST,
  INVOICE_SINGLE_ACTION_PERFORMED,
  INVOICE_ASSIGN_REQUEST,
  INVOICE_ASSIGN_RECEIVED,
  INVOICE_ASSIGN_ERROR,
  INVOICE_UNASSIGN_REQUEST,
  INVOICE_BULK_UNASSIGNED,
  INVOICE_SINGLE_UNASSIGNED_FROM_CURRENT_USER,
  INVOICE_LIST_UNLOAD_PERSIST_FILTERS,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';
import { removeFiltersFromSession, setFiltersInSession } from '../../routing/filters';

const reducer = (
  state = {
    invoices: [],
    isFetchingInvoices: false,
    currentPage: 1,
    isUpdatingInvoice: false,
    isFetchingInvoice: false,
    hasLoadedInvoice: false,
    pageCount: null,
    invoice: null,
    isUnassigningInvoices: false,
    invoicesUnassigned: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_LIST_UNLOAD:
      return {
        invoices: [],
        isFetchingInvoices: false,
        currentPage: 1,
        pageCount: null,
        filters: {},
      };
    case INVOICE_LIST_UNLOAD_PERSIST_FILTERS:
      return {
        ...state,
        invoices: [],
      };

    case INVOICE_LIST_REQUEST:
    case INVOICE_ASSIGN_REQUEST:
      return {
        ...state,
        isFetchingInvoices: true,
        isUpdatingMultipleInvoices: true,
      };

    case INVOICE_LIST_RECEIVED:
      return {
        ...state,
        invoices: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingInvoices: false,
      };

    case INVOICE_ASSIGN_RECEIVED:
      return {
        ...state,
        isFetchingInvoices: false,
        isUpdatingMultipleInvoices: false,
      };

    case INVOICE_LIST_ERROR:
    case INVOICE_ASSIGN_ERROR:
      return {
        ...state,
        isFetchingInvoices: false,
      };
    case INVOICE_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case INVOICE_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingInvoice: true,
      };

    case INVOICE_SINGLE_RECEIVED:
      return {
        ...state,
        //        invoices: [
        //          ...state.invoices.map(x => {
        //            return action.data.id === x.id ? action.data : x;
        //          })],
        hasLoadedInvoice: true,
        invoice: action.data,
        isFetchingInvoice: false,
      };

    case INVOICE_SINGLE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingInvoice: false,
      };

    case INVOICE_SINGLE_UNLOAD:
      return {
        ...state,
        invoice: null,
        hasLoadedInvoice: false,
        error: null,
        isFetchingInvoice: false,
        isUpdatingInvoice: false,
      };

    case INVOICE_LIST_SET_FILTERS:
      setFiltersInSession(action.routeKey || null, action.filters)
      return {
        ...state,
        filters: action.filters,
      };

    case INVOICE_LIST_CLEAR_FILTERS:
      removeFiltersFromSession(action.routeKey || null)
      return {
        ...state,
        filters: {},
      };

    case INVOICE_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingInvoice: true,
      };
      return state;

    case INVOICE_SINGLE_UPDATED:
      return {
        ...state,
        invoices: [
          ...state.invoices.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        invoice: action.data,
        isUpdatingInvoice: false,
      };
    case INVOICE_DELETE_REQUEST:
      return {
        ...state,
        isDeletingInvoice: true,
      };
    case INVOICE_DELETED:
      return {
        ...state,
        isDeletingInvoice: false,
      };

    case INVOICE_SINGLE_ACTION_REQUEST:
      state = {
        ...state,
        isUpdatingInvoice: true,
      };
      return state;

    case INVOICE_SINGLE_ACTION_PERFORMED:
      return {
        ...state,
        invoices: [
          ...state.invoices.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        invoice: action.data,
        isUpdatingInvoice: false,
      };

    case INVOICE_SINGLE_UNASSIGNED_FROM_CURRENT_USER:
      let newInvoicesList = [];
      if (state.invoices.length > 0) {
        newInvoicesList = [
          ...state.invoices.filter(function (obj) {
            return obj.id !== action.id;
          }),
        ];
      }
      state = {
        ...state,
        invoices: [...newInvoicesList],
        invoice: null,
        isUpdatingInvoice: false,
      };
      return state;

    case INVOICE_UNASSIGN_REQUEST:
      state = {
        ...state,
        isUnassigningInvoices: true,
      };
      return state;
    case INVOICE_BULK_UNASSIGNED:
      state = {
        ...state,
        isUnassigningInvoices: false,
        invoicesUnassigned: `${action.data.invoices}/${action.data.unassigned}`,
      };
      return state;

    default:
      return state;
  }
};

export default reducer;
