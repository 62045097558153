import { requests } from '../../helper/agent';
import moment from 'moment';
import { SubmissionError } from 'redux-form';
import {
  USER_LOGIN_SUCCESS,
  USER_PROFILE_ERROR,
  USER_PROFILE_RECEIVED,
  USER_PROFILE_REQUEST,
  USER_SET_ID,
  USER_LOGOUT,
  USER_SINGLE_REQUEST,
  USER_SINGLE_RECEIVED,
  USER_SINGLE_ERROR,
  USER_LOGIN_2FA_SUCCESS,
  USER_SINGLE_UPDATED,
  USER_SINGLE_UPDATE_REQUEST,
  USER_SINGLE_CREATE_SUCCESS,
  USER_SINGLE_CREATE_REQUEST,
  USER_AGREED_TERMS,
  RESET_PASSWORD_LINK_UNLOAD,
  RESET_PASSWORD_LINK_RECEIVED,
  USER_FORGOT_PASSWORD_ERROR,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { parseApiErrors } from '../../helper/apiUtils';
import { isAdmin, isLoggedInUser, isSeniorStaff } from '../../helper/authorisation';

export const userLoginSuccess = (token, qrCode, isClient) => {
  return {
    type: USER_LOGIN_SUCCESS,
    token,
    qrCode,
    isClient,
  };
};

export const userLogin2faSuccess = (token, userId) => {
  return {
    type: USER_LOGIN_2FA_SUCCESS,
    token,
    userId,
  };
};

export const userPasswordLinkRequest = (data) => {
  return {
    type: RESET_PASSWORD_LINK_RECEIVED,
    data,
  };
};

export const userPasswordLinkRequestUnload = () => {
  return {
    type: RESET_PASSWORD_LINK_UNLOAD,
  };
};

export const userLogout = () => {
  if (window && window.tidioChatApi) {
    window.tidioChatApi.show();
  }

  return {
    type: USER_LOGOUT,
  };
};

export const userLoginAttempt = (username, password) => {
  //console.log('Attempting Login...');
  return (dispatch) => {
    return requests
      .post(API_ROUTES.LOGIN_CHECK, { username, password }, false)
      .then((response) => dispatch(userLoginSuccess(response.token, response.qr ?? null, response.isClient ?? false)))
      .catch(() => {
        throw new SubmissionError({
          _error: 'Email address or Password is incorrect, or your account is not active',
        });
      });
  };
};

export const userLogin2faAttempt = (challenge, isEmailCode = false) => {
  //console.log('Attempting 2fa Login...');
  return (dispatch) => {
    return requests
      .post(isEmailCode ? API_ROUTES.LOGIN_CHECK_2FA_EMAIL : API_ROUTES.LOGIN_CHECK_2FA, { challenge })
      .then((response) => dispatch(userLogin2faSuccess(response.token, response.id)))
      .catch(() => {
        throw new SubmissionError({
          _error: 'Please check the code entered is correct',
        });
      });
  };
};

export const userSkipLogin2faAttempt = () => {
  //console.log('Skipping 2fa Login...');
  return (dispatch) => {
    return requests
      .post(API_ROUTES.LOGIN_SKIP_2FA)
      .then((response) => dispatch(userLogin2faSuccess(response.token, response.id)))
      .catch(() => {
        throw new SubmissionError({
          _error: 'Please check the code entered is correct',
        });
      });
  };
};

export const userRequestEmail2faCode = () => {
  //console.log('Requesting Email 2fa Code...');
  return () => {
    return requests.get(API_ROUTES.REQUEST_2FA_EMAIL, {}).catch(() => {
      throw new SubmissionError({
        _error: 'Error: problem requesting email code - please try again',
      });
    });
  };
};
//
//export const userForgotPasswordRequest = () => {
//  return {
//    type: USER_FORGOT_PASSWORD_REQUEST,
//  };
//};
//
//export const userForgotPasswordSuccess = () => {
//  return {
//    type: USER_FORGOT_PASSWORD_SUCCESS,
//  };
//};
//
export const userForgotPasswordError = () => {
  return {
    type: USER_FORGOT_PASSWORD_ERROR,
  };
};

export const userForgotPassword = (email) => {
  //console.log('Attempting Forgot Password...');
  return () => {
    //    dispatch(userForgotPasswordRequest());
    return (
      requests
        .post(API_ROUTES.FORGOT_PASSWORD, { email }, false)
        //        .then(response => dispatch(userForgotPasswordSuccess()))
        .catch((error) => {
          throw new SubmissionError(parseApiErrors(error));
        })
    );
  };
};

export const userRequestResetLink = (email, isNew = false) => {
  //console.log('Attempting Forgot Password...');
  return (dispatch) => {
    //    dispatch(userForgotPasswordRequest());
    return requests
      .post(API_ROUTES.RESET_PASSWORD_LINK, { email, isNew })
      .then((response) => dispatch(userPasswordLinkRequest(response)))
      .catch((error) => {
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const userResetPassword = (email, resetCode, newPassword, retypedNewPassword) => {
  //console.log('Attempting Reset Password...');
  return (dispatch) => {
    //    dispatch(userForgotPasswordRequest());
    return (
      requests
        .post(API_ROUTES.RESET_PASSWORD, { email, resetCode, newPassword, retypedNewPassword }, false)
        //        .then(response => dispatch(userForgotPasswordSuccess()))
        .catch((error) => {
          dispatch(userForgotPasswordError());
          throw new SubmissionError(parseApiErrors(error));
        })
    );
  };
};

export const userChangePassword = (newPassword, retypedNewPassword) => {
  //console.log('Attempting Change Password...');
  return () => {
    //    dispatch(userForgotPasswordRequest());
    return (
      requests
        .post(API_ROUTES.CHANGE_PASSWORD, { newPassword, retypedNewPassword })
        //        .then(response => dispatch(userForgotPasswordSuccess()))
        .catch((error) => {
          throw new SubmissionError(parseApiErrors(error));
        })
    );
  };
};

export const userSetId = (userId) => {
  return {
    type: USER_SET_ID,
    userId,
  };
};

export const userProfileRequest = () => {
  return {
    type: USER_PROFILE_REQUEST,
  };
};

export const userProfileReceived = (userId, userData) => {
  return {
    type: USER_PROFILE_RECEIVED,
    userData,
    userId,
  };
};

export const userProfileError = (userId) => {
  return {
    type: USER_PROFILE_ERROR,
    userId,
  };
};

export const userProfileFetch = (userId) => {
  //console.log('Attempting fetch user profile ...');
  return (dispatch) => {
    dispatch(userProfileRequest());
    return requests
      .get(`${API_ROUTES.USERS}/${userId}`)
      .then((response) => dispatch(userProfileReceived(userId, response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          throw new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(userProfileError(userId));
      });
  };
};

// USER_SINGLE

export const userSingleRequest = () => ({
  type: USER_SINGLE_REQUEST,
});

export const userSingleReceived = (data) => ({
  type: USER_SINGLE_RECEIVED,
  data,
});

export const userSingleError = (error) => ({
  type: USER_SINGLE_ERROR,
  error,
});

export const userSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(userSingleRequest());
    //console.log('Fetching single user data ...');
    return requests
      .get(`${API_ROUTES.USERS}/${id}`, { isEnabled: isSeniorStaff() ? 'anything' : true })
      .then((response) => dispatch(userSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(userSingleError(error));
      });
  };
};

export const userSingleUpdateRequest = () => ({
  type: USER_SINGLE_UPDATE_REQUEST,
});

export const userSingleUpdated = (data) => ({
  type: USER_SINGLE_UPDATED,
  data,
});

export const userSingleUpdate = (data, userId) => {
  return (dispatch) => {
    dispatch(userSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.USERS}/${userId}`, data)
      .then((response) => dispatch(userSingleUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const userAgreedTermsSuccess = (data, userId) => ({
  type: USER_AGREED_TERMS,
  userData: data,
  userId,
});

export const userSingleTermsAgree = (userId) => {
  return (dispatch) => {
    dispatch(userSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.USERS}/${userId}`, { dateTermsRead: moment().utc() })
      .then((response) => dispatch(userAgreedTermsSuccess(response, userId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const userSingleCreateRequest = () => ({
  type: USER_SINGLE_CREATE_REQUEST,
});

export const userSingleCreateSuccess = (data) => ({
  type: USER_SINGLE_CREATE_SUCCESS,
  data,
});

//export const userSingleCreateError = (error) => ({
//  type: USER_SINGLE_CREATE_ERROR,
//  error,
//});

export const userSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(userSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.USERS}`, data)
      .then((response) => {
        dispatch(userSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
          parsedError._error = 'It looks like you may be creating a user which already exists - please check the email address and try again if not.';
        } else {
          parsedError._error =
            'There was a problem creating the user - please check the details and try again. If the problem persists please contact support.';
        }
        throw new SubmissionError(parsedError);
      });
  };
};

export const userSingleDisable2FA = (userId) => {
  if (!isAdmin(true) && !isLoggedInUser(userId)) {
    return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
  }
  return (dispatch) => {
    dispatch(userSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.USERS_DISABLE_2FA.replace(':id', userId)}`, {})
      .then((response) => {
        //          dispatch(userSingleUpdated(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
