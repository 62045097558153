import React from 'react';
import { connect } from 'react-redux';
import { isToday } from '../../lib/helper/calendarTableHelper';
import { Button } from 'react-bootstrap';
import { workScheduleUpdate } from '../../lib/redux/actions/workSchedule';
import { addToast } from '../../lib/redux/actions/toast';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { isAdmin } from '../../lib/helper/authorisation';

const moment = extendMoment(Moment);

const mapDispatchToProps = {
  workScheduleUpdate,
  addToast,
};

class EditableWorkScheduleCell extends React.Component {
  constructor(props) {
    let invoiceValue =
      props.userSchedules && typeof props.userSchedules[props.day.toLowerCase()] !== 'undefined' ? props.userSchedules[props.day.toLowerCase()] : 0;
    super(props);
    this.today = moment().utc();
    this.state = {
      isEditing: false,
      invoicesPerDay: invoiceValue,
    };
  }

  saveAndStopEditing(e) {
    const { cellDate, user, workScheduleUpdate, addToast } = this.props;
    let values = {};
    values['day'] = cellDate;
    values['user_id'] = user.id;
    values['invoices_per_day'] = this.state.invoicesPerDay;
    workScheduleUpdate(values)
      .then(() => {
        addToast('Schedule Updated Successfully', true);
        this.setState(() => ({
          isEditing: false,
        }));
      })
      .catch((e) => {
        addToast('Problem Updating Schedule, please try again.', false, true);
      });
  }

  startEditing() {
    const { user, eventOnThisDay } = this.props;
    if (!eventOnThisDay && (parseInt(window.localStorage.getItem('userId')) === parseInt(user.id) || isAdmin())) {
      this.setState(() => ({ isEditing: true }));
    }
  }

  stopEditing(e, revertVal = true) {
    e.stopPropagation();
    const startVal =
      this.props.userSchedules && typeof this.props.userSchedules[this.props.day.toLowerCase()] !== 'undefined'
        ? this.props.userSchedules[this.props.day.toLowerCase()]
        : 0;
    const invoicesPerDay = revertVal ? startVal : this.state.invoicesPerDay;
    this.setState(() => ({
      isEditing: false,
      invoicesPerDay,
    }));
  }

  handleInvoiceNumberChange(event) {
    this.setState(() => ({ invoicesPerDay: event.target.value }));
  }

  renderContent() {
    const { isEditing, invoicesPerDay } = this.state;
    const { cellDate, eventOnThisDay = false } = this.props;
    if (!eventOnThisDay && isEditing && (cellDate.isSameOrAfter(this.today, 'day') || isAdmin())) {
      return (
        <div>
          <div className={'work_schedule_input_container'}>
            <input
              autoFocus
              type="number"
              placeholder="0"
              title="Invoices Per Day"
              value={invoicesPerDay}
              onChange={this.handleInvoiceNumberChange.bind(this)}
              className="form-control w-25 mr-2"
            />
            <Button variant="primary" type="submit" title="Save Change" size="sm" className="mb-0" onClick={this.saveAndStopEditing.bind(this)}>
              <i className="fas fa-check" />
            </Button>
            <Button variant="danger" title="Cancel Change" size="sm" className="mb-0" onClick={this.stopEditing.bind(this)}>
              <i className="fas fa-times" />
            </Button>
          </div>
        </div>
      );
    } else {
      return <p>{eventOnThisDay ? <i className={`fa fa-h-square`} title={'Holiday'} /> : invoicesPerDay}</p>;
    }
  }

  render() {
    const { isEditing } = this.state;
    const { cellDate, user } = this.props;
    return (
      <td
        key={`${user.id}-td-${cellDate.unix()}`}
        className={`status ${isEditing ? 'work_schedule_td_container' : ''} ${isToday(cellDate, this.today) ? 'highlight-today' : undefined}`}
        onClick={this.startEditing.bind(this)}>
        {this.renderContent()}
      </td>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditableWorkScheduleCell);
