import React, { Component } from 'react';
import { connect } from 'react-redux';
import { problemTypesFetch, problemTypesUnload } from '../../lib/redux/actions/problemTypes';
import Card from 'react-bootstrap/Card';
import MASpinner from '../../components/common/MASpinner';
import { problemStatusesFetch, problemStatusesUnload } from '../../lib/redux/actions/problemStatus';
import ProblemDataEdit from './ProblemDataEdit';
import { problemDataSingleFetch, problemDataSingleUnload } from '../../lib/redux/actions/problemData';
import { problemDataInsurerListFetch, problemDataInsurerListUnload } from '../../lib/redux/actions/problemDataInsurers';

const mapDispatchToProps = {
  problemDataInsurerListFetch,
  problemDataInsurerListUnload,
  problemTypesFetch,
  problemTypesUnload,
  problemDataSingleFetch,
  problemDataSingleUnload,
  problemStatusesFetch,
  problemStatusesUnload,
};

const mapStateToProps = (state) => {
  return {
    ...state.problemDataInsurers,
    ...state.problemTypes,
    ...state.problemStatuses,
    ...state.problemDataSingle,
  };
};

class ProblemDataEditContainer extends Component {
  componentDidMount() {
    this.props.problemDataSingleFetch(this.props.match.params.id);
    this.props.problemDataInsurerListFetch({}, { pagination: false });
    this.props.problemTypesFetch();
    this.props.problemStatusesFetch();
  }

  componentWillUnmount() {
    this.props.problemTypesUnload();
    this.props.problemStatusesUnload();
    this.props.problemDataInsurerListUnload();
    this.props.problemDataSingleUnload();
  }

  render() {
    const { problemData, problemDataInsurers, problemTypes, problemStatuses, isFetchingProblemData, history } = this.props;
    if (!isFetchingProblemData && !problemData) {
      return (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">Not Found</div>
          </Card.Header>
          <Card.Body>
            <p>Could not find data for this invoice</p>
          </Card.Body>
        </Card>
      );
    }
    if (isFetchingProblemData && !problemData) {
      return <MASpinner />;
    }
    return (
      <ProblemDataEdit
        history={history}
        problemData={problemData}
        healthInsurers={problemDataInsurers}
        problemTypes={problemTypes}
        problemStatuses={problemStatuses}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemDataEditContainer);
