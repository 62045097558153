export const LOGIN = '/login';
export const AUTH2FA = '/auth';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';

export const HOME = '/';
export const KITCHEN_SINK = '/kitchen-sink';
export const HELP = '/help';
export const SUPPORT = '/support';
export const TERMS = '/terms-and-conditions';
export const BLANK = '/blank';
export const NOT_FOUND = '/404';
export const DENIED = '/access-denied';
export const PROFILE = '/edit-profile';
export const CCSD_CODES = {
  ALL: '/ccsd-codes/:page?',
  SINGLE: '/ccsd-code/:id',
};

export const EXPORT_INVOICE_NUMBERS = '/export-invoice-numbers';

export const BILLING_CHECKLIST = '/billing-checklist/:date?';

export const BILLING_CHECKLIST_EVENTS = {
  ALL: '/billing-checklist-events/:page?',
  SINGLE: '/billing-checklist-event/:id',
  CREATE: '/billing-checklist-event',
};

export const WORK_SCHEDULE = '/work-schedule/:date?';

export const CHASER_WORK_SCHEDULE = '/chaser-work-schedule/:date?';
export const WORK_SCHEDULE_EVENTS = {
  ALL: '/work-schedule-events/:page?',
  SINGLE: '/work-schedule-event/:id',
  CREATE: '/work-schedule-event',
};

export const SELECT_CONSULTANT = '/choose-an-account';

export const INVOICE_BATCHES = {
  ALL: '/invoice-batches/:page?',
  EMBASSY: '/embassy-invoice-batches/:page?',
  URGENT: '/urgent-invoices/:page?',
  UNASSIGNED: '/unassigned-invoices/:page?',
  TO_CHECK_AND_PRICE: '/to-check-and-price-invoices/:page?',
  PROBLEM: '/problem-batches/:page?',
  SINGLE: '/invoice-batch/:id',
  CREATE: '/upload-invoices',
  TO_ACCEPT: '/invoices-to-accept/:page?',
  TO_PROCESS: '/invoices-to-process/:page?',
  BULK_ASSIGN: '/bulk-assign-invoices/:page?',
};

export const INVOICES = {
  ALL: '/invoices/:page?',
  VOIDS: '/void-invoices/:page?',
  UNPAID: '/unpaid-invoices/:page?',
  SINGLE: '/invoice/:id',
  CREATE: '/client-control/upload-csv',
  PARSE: '/client-control/upload-invoice',
  UPDATE: '/client-control/upload-paid-csv',
  BULK_ASSIGN: '/invoices/bulk-assign/:page?',
  ESCALATED: '/invoices-escalated/:page?',
  MISSING_CONTACT_DETAILS: '/invoices-missing-contact-details/:page?',
};

export const PROBLEM_DATA = {
  ALL: '/problem-datas/:page?',
  COMPLETED: '/problem-data/complete/:page?',
  CREATE: '/problem-data/',
  SINGLE: '/problem-data/:id',
  SINGLE_EDIT: '/problem-data/edit/:id',
};

export const SUBACCOUNT = {
  ACCEPT: '/accept-invitation',
  ACCEPT_SECURE: '/accept-invitation-secure',
};

export const REPORTS = {
  ALL: '/reports/:page?',
  CREATE: '/upload-reports',
};

export const CONSULTANTS = {
  ALL: '/consultants/:page?',
  SINGLE: '/consultant/:id',
  SINGLE_FOR_SUBUSER: '/consultant',
  CREATE: '/new-consultant',
};

export const USERS = {
  ALL: '/users/:page?',
  SINGLE: '/user/:id',
  CREATE: '/new-user',
};

export const HOSPITALS = {
  ALL: '/hospitals/:page?',
};

export const CONSULTANT_TICKETS = {
  ALL: '/consultant-tickets/:page?',
  SINGLE: '/consultant-ticket/:id',
  CREATE: '/new-consultant-ticket',
};

export const CMS = {
  REMITTANCES: '/info/remittances',
  HOSPITAL: '/info/hospital',
  INSURANCE: '/info/insurance',
  EMBASSY: '/info/embassy',
};

export const PUBLIC_PATIENT_INVOICES = {
  VIEW: '/view-an-invoice/:uniqueRef',
  PAY: '/pay-an-invoice/:uniqueRef',
  PAYMENT_RESPONSE: '/payment-response/:response',
};

export const SITE_SETTINGS = '/settings';
