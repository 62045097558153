import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import MAToggleButton from '../common/MAToggleButton';
import { isClient, isOutsourcer, isSeniorStaff, isSubUser } from '../../lib/helper/authorisation';
import { connect } from 'react-redux';
import Search from './Search';

const mapStateToProps = (state) => ({
  ...state.stats,
  ...state.auth,
});

class Header extends React.Component {
  url = window.location.toString();

  renderUser() {
    const { userData, is2faAuthenticated, userLogout, consultantToManage = false, stats } = this.props;
    return (
      <span className="d-inline-block mt-2 mt-sm-0 text-white">
        {isOutsourcer(true) && (
          <span className="d-none d-lg-inline">
            <Link to={ROUTES.INVOICE_BATCHES.TO_ACCEPT.replace('/:page', '')} className="btn btn-dark mr-2 btn-sm">
              To Accept: <span className="badge badge-light"> {stats.invoicesToAccept || 0}</span>
            </Link>
            <Link to={ROUTES.INVOICE_BATCHES.TO_PROCESS.replace('/:page', '')} className="btn btn-dark mr-2 btn-sm">
              To Process: <span className="badge badge-light"> {stats.invoicesToProcess || 0}</span>
            </Link>
            {stats?.urgentInvoiceBatchesToCompleteForCurrentUser > 0 && (
              <Link to={ROUTES.INVOICE_BATCHES.TO_ACCEPT.replace('/:page', '')} className="btn btn-warning mr-2 btn-sm">
                Urgent: <span className="badge badge-dark"> {stats.urgentInvoiceBatchesToCompleteForCurrentUser || 0}</span>
              </Link>
            )}
          </span>
        )}

        {isSeniorStaff() && (
          <span className="d-none d-lg-inline">
            <Link to={ROUTES.INVOICE_BATCHES.UNASSIGNED.replace('/:page', '')} className="btn btn-dark mr-2 btn-sm">
              To Assign: <span className="badge badge-light"> {stats.invoicesToAssign || 0}</span>
            </Link>
            <Link to={ROUTES.INVOICE_BATCHES.TO_CHECK_AND_PRICE.replace('/:page', '')} className="btn btn-dark mr-2 btn-sm">
              To Check and Price: <span className="badge badge-light"> {stats.invoicesToCheckAndPrice || 0}</span>
            </Link>
            <Link to={ROUTES.CONSULTANT_TICKETS.ALL.replace('/:page', '')} className="btn btn-dark mr-2 btn-sm">
              Unactioned Tickets: <span className="badge badge-light"> {stats.unactionedTickets || 0}</span>
            </Link>
            {stats?.urgentInvoiceBatchesToComplete > 0 && (
              <Link to={ROUTES.INVOICE_BATCHES.URGENT.replace('/:page', '')} className="btn btn-warning mr-2 btn-sm">
                Urgent: <span className="badge badge-dark"> {stats.urgentInvoiceBatchesToComplete || 0}</span>
              </Link>
            )}
          </span>
        )}

        {userData && is2faAuthenticated && <span className="d-none d-md-inline-block mr-2">Hi {`${userData.firstName}`}</span>}
        {userData && isSubUser(true) && consultantToManage && (
          <span>
            <span className="d-none d-md-inline-block"> - you are managing</span>{' '}
            <strong className="consultant-account-highlight mr-2">{consultantToManage.name}</strong>
            {userData.consultantsAsSubuser.length > 1 && (
              <Link to={ROUTES.SELECT_CONSULTANT} className="btn btn-sm btn-outline-white mr-2">
                <i className="fas fa-retweet mr-2" />
                Change account
              </Link>
            )}
          </span>
        )}

        <button className="btn btn-sm btn-outline-white" onClick={userLogout}>
          <i className="fas fa-sign-out-alt mr-2" /> Log out
        </button>
      </span>
    );
  }

  render() {
    const { isAuthenticated, sideBarToggleAction, is2faAuthenticated, linkLogo = true } = this.props;
    return (
      <nav
        className={`navbar navbar-dark ${this.url.includes('local.') || this.url.includes('localhost') ? 'bg-warning' : this.url.includes('stage-portal') ? 'bg-info' : 'bg-danger'}`}>
        {sideBarToggleAction && <MAToggleButton toggleAction={sideBarToggleAction} buttonContent={<i className="fas fa-bars text-danger" />} />}
        <div className={'d-flex'}>
          {linkLogo ? (
            <Link to={ROUTES.HOME} className="navbar-brand">
              <img src="/images/MediAccounts_Logo-white-cropped.png" alt={'Mediaccount Logo'} />
            </Link>
          ) : (
            <div className="navbar-brand">
              <img src="/images/MediAccounts_Logo-white-cropped.png" alt={'Mediaccount Logo'} />
            </div>
          )}

          {isAuthenticated && is2faAuthenticated && !isClient() && <Search />}
        </div>

        <span className="navbar-link d-none d-md-block">{isAuthenticated && this.renderUser()}</span>
      </nav>
    );
  }
}

export default connect(mapStateToProps, null)(Header);
