import React from 'react';
import Form from 'react-bootstrap/Form';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {renderCheckbox, renderField} from 'lib/helper/form';
import MASpinner from '../../common/MASpinner';
import {connect} from 'react-redux';
import {addToast} from 'lib/redux/actions/toast';
import {invoiceSingleUpdate} from 'lib/redux/actions/invoices';
import {isAdmin, isStaff} from 'lib/helper/authorisation';
import moment from 'moment';

const mapStateToProps = (state, props) => {
    const {
        disableChasing = false,
        isWrittenOff = false,
        isVoid = false,
        outstandingBalance = 0,
        dateOfBirth = '',
        patientFirstName = '',
        patientLastName = '',
        disableChasingUntilDate = '',
    } = props.invoice;

    return {
        currentFormValues: getFormValues('InvoiceAdminActionsForm')(state),
        initialValues: {
            disableChasing,
            disableChasingUntilDate: moment(disableChasingUntilDate).format('YYYY-MM-DD'),
            isWrittenOff,
            isVoid,
            patientFirstName,
            patientLastName,
            dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
            outstandingBalance: outstandingBalance / 100,
        },
    };
};

const mapDispatchToProps = {
    invoiceSingleUpdate,
    addToast,
};

class InvoiceAdminActionsForm extends React.Component {
    onSubmit(values) {
        const {invoiceSingleUpdate, invoice, addToast, reset} = this.props;
        values.outstandingBalance = values.outstandingBalance * 100;
        if (!values?.disableChasing) {
            values.disableChasingUntilDate = null;
        }

        return invoiceSingleUpdate(values, invoice.id).then(() => {
            addToast('The Invoice was successfully updated', true);
            reset();
        });
    }

    render() {
        const {handleSubmit, isUpdatingInvoice, submitting, pristine, currentFormValues, reset} = this.props;

        if (!isStaff()) {
            return null;
        }

        return (
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className="my-3">
                        <Field name="disableChasing" label="Pause automated chasing?" id="disable-chasing" component={renderCheckbox}/>
                    </div>
                    {currentFormValues?.disableChasing && (
                            <div>
                                <Field
                                        name="disableChasingUntilDate"
                                        horizontal={true}
                                        horizontalLabelWidth={6}
                                        label="Pause Until"
                                        id="disableChasingUntilDate"
                                        min={moment().format('YYYY-MM-DD')}
                                        type="date"
                                        component={renderField}
                                />
                            </div>
                    )}
                    <div className="mb-3">
                        <Field name="isWrittenOff" label="Mark as write-off?" id="is-written-off" component={renderCheckbox}/>
                    </div>
                    {isAdmin() && (
                        <div className="mb-3">
                            <Field name="isVoid" label="Mark as void?" id="is-void" component={renderCheckbox}/>
                        </div>
                    )}
                    <div>
                        <Field
                                name="outstandingBalance"
                                horizontal={true}
                                horizontalLabelWidth={6}
                                label="Update O/S Amount (£)"
                                id="outstanding-balance"
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                min="0.00"
                                component={renderField}
                        />
                    </div>
                    <div>
                        <Field name="dateOfBirth" horizontal={true} horizontalLabelWidth={6} label="Update Date of Birth" id="dateOfBirth" type="date" component={renderField}/>
                    </div>
                    <div>
                        <Field
                                name="patientFirstName"
                                horizontal={true}
                                horizontalLabelWidth={6}
                                label="Patient Name"
                                id="patientFirstName"
                                type="text"
                                component={renderField}
                        />
                    </div>
                    <div>
                        <Field
                                name="patientLastName"
                                horizontal={true}
                                horizontalLabelWidth={6}
                                label="Patient Last Name"
                                id="patientLastName"
                                type="text"
                                component={renderField}
                        />
                    </div>
                    {isUpdatingInvoice ? <MASpinner/> : null}
                    {!isUpdatingInvoice && !pristine ? (
                            <>
                                <button type="submit" disabled={submitting} className="btn btn-primary mr-2">
                                    Update
                                </button>

                                <button type="reset" disabled={submitting} className="btn btn-secondary" onClick={() => reset()}>
                                    Reset
                                </button>
                            </>
                    ) : null}
                </Form>
        );
    }
}

export default connect(
        mapStateToProps,
        mapDispatchToProps,
)(
        reduxForm({
            form: 'InvoiceAdminActionsForm',
            enableReinitialize: true,
            destroyOnUnmount: false,
        })(InvoiceAdminActionsForm),
);
