import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  problemDataListClearFilters,
  problemDataListFetch,
  problemDataListSetFilters,
  problemDataListSetPage,
  problemDataListUnload,
} from '../../lib/redux/actions/problemData';
import Card from 'react-bootstrap/Card';
import MASpinner from '../../components/common/MASpinner';
import Paginator from '../../components/common/Paginator';
import ProblemDataListTable from './ProblemDataListTable';
import ProblemDataTableFilterForm from '../../components/forms/ProblemData/ProblemDataTableFilterForm';

const mapStateToProps = (state) => ({
  ...state.problemDataList,
  ...state.auth,
});

const mapDispatchToProps = {
  problemDataListFetch,
  problemDataListUnload,
  problemDataListClearFilters,
  problemDataListSetPage,
  problemDataListSetFilters,
};

class ProblemDataListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      orderByUpdatedAt: null,
    };
  }

  componentDidMount() {
    this.loadProblemData();
  }

  componentWillUnmount() {
    this.props.problemDataListUnload();
  }

  loadProblemData() {
    this.props.problemDataListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    const { orderByUpdatedAt } = this.state;
    const mergedFilters = { ...defaultFilters, ...filters };
    if (mergedFilters['problemStatus.code'] && mergedFilters['problemStatus.code'] === '~') {
      delete mergedFilters['problemStatus.code'];
    }
    if (orderByUpdatedAt) {
      mergedFilters['order[updatedAt]'] = orderByUpdatedAt;
      delete mergedFilters['order[invoiceBatch.consultant.primaryUser.lastName]'];
      delete mergedFilters['order[problemType.name]'];
      delete mergedFilters['order[createdAt]'];
    }

    return mergedFilters;
  }

  componentDidUpdate(prevProps) {
    const { currentPage, problemDataListFetch, filters, defaultFilters, problemDataListUnload, problemDataListClearFilters, problemDataListSetPage } =
      this.props;

    if (prevProps.defaultFilters !== defaultFilters) {
      problemDataListUnload();
      problemDataListClearFilters();
      this.loadProblemData();
      this.setState(() => {
        return { showFilters: false };
      });
    }
    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      problemDataListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      problemDataListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, problemDataListSetPage, path } = this.props;
    problemDataListSetPage(page);
    history.push(path.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  orderByUpdatedAt() {
    const { orderByUpdatedAt } = this.state;
    switch (orderByUpdatedAt) {
      case 'ASC':
        this.setState({ orderByUpdatedAt: 'DESC' }, () => {
          this.props.problemDataListFetch(this.getQueryParamPage(), this.getCombinedFilters());
        });
        break;
      case 'DESC':
        this.setState({ orderByUpdatedAt: null }, () => {
          this.props.problemDataListFetch(this.getQueryParamPage(), this.getCombinedFilters());
        });
        break;
      case null:
      default:
        this.setState({ orderByUpdatedAt: 'ASC' }, () => {
          this.props.problemDataListFetch(this.getQueryParamPage(), this.getCombinedFilters());
        });
        break;
    }
  }

  render() {
    const { userData, problemData, isFetchingProblemData, currentPage, pageCount, pageTitle } = this.props;
    const { orderByUpdatedAt } = this.state;
    return (
      <Card className={pageTitle.replace(/\s+/g, '-').toLowerCase()}>
        <Card.Header>
          <div className="d-flex justify-content-between">
            {' '}
            {pageTitle}
            {!isFetchingProblemData && problemData && problemData.length > 0 && (
              <div>
                <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                  Filters
                </button>
                <span className="tabular-data-result-count">{pageCount} pages</span>
              </div>
            )}
          </div>
        </Card.Header>

        {this.state.showFilters && (
          <Card.Body className={'mediaccount-filter-wrapper'}>
            <ProblemDataTableFilterForm />
          </Card.Body>
        )}

        {isFetchingProblemData && (
          <Card.Body>
            <MASpinner />
          </Card.Body>
        )}

        {((!isFetchingProblemData && problemData?.length < 1) || problemData?.length > 0) && (
          <ProblemDataListTable
            currentUserIRI={userData['@id']}
            problemData={problemData}
            orderByUpdatedAt={this.orderByUpdatedAt.bind(this)}
            orderByUpdatedAtStatus={orderByUpdatedAt}
          />
        )}
        {!isFetchingProblemData && problemData && problemData.length > 0 && (
          <Paginator
            currentPage={currentPage}
            pageCount={pageCount}
            setPage={this.changePage.bind(this)}
            nextPage={this.nextPage.bind(this)}
            prevPage={this.prevPage.bind(this)}
          />
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemDataListContainer);
