import {
  SITE_SETTING_SINGLE_UPDATE_REQUEST,
  SITE_SETTING_SINGLE_UPDATED,
  SITE_SETTINGS_LIST_ERROR,
  SITE_SETTINGS_LIST_RECEIVED,
  SITE_SETTINGS_LIST_REQUEST,
  SITE_SETTINGS_LIST_UNLOAD,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const siteSettingsListRequest = () => ({
  type: SITE_SETTINGS_LIST_REQUEST,
});

export const siteSettingsListReceived = (data) => ({
  type: SITE_SETTINGS_LIST_RECEIVED,
  data,
});

export const siteSettingsListError = (error) => ({
  type: SITE_SETTINGS_LIST_ERROR,
  error,
});

export const siteSettingsListUnload = () => ({
  type: SITE_SETTINGS_LIST_UNLOAD,
});

export const siteSettingsListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(siteSettingsListRequest());
    //console.log('Fetching reports ...');
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(`${API_ROUTES.SITE_SETTINGS}`, filters)
      .then((response) => dispatch(siteSettingsListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(siteSettingsListError(error));
      });
  };
};

//
//// SITE_SETTINGS_SINGLE
//
//export const rEPORTSingleRequest = () => ({
//  type: SITE_SETTINGS_SINGLE_REQUEST,
//});
//
//export const rEPORTSingleReceived = (data) => ({
//  type: SITE_SETTINGS_SINGLE_RECEIVED,
//  data,
//});
//
//export const rEPORTSingleError = (error) => ({
//  type: SITE_SETTINGS_SINGLE_ERROR,
//  error,
//});
//
//export const rEPORTSingleFetch = (id) => {
//  return (dispatch) => {
//    dispatch(rEPORTSingleRequest());
//    console.log('Fetching single reports ...');
//    return requests.get(`${API_ROUTES.SITE_SETTING_BATCHES}/${id}`)
//        .then(response => dispatch(rEPORTSingleReceived(response)))
//        .catch(error => {
//      if (error.response && error.response.status === 401) {
//        return dispatch(userLogout());
//      } else if (error.response && error.response.status === 403) {
//        return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
//      }
//      dispatch(rEPORTSingleError(error));
//    });
//  };
//};
//
export const siteSettingSingleUpdateRequest = () => ({
  type: SITE_SETTING_SINGLE_UPDATE_REQUEST,
});

export const siteSettingSingleUpdated = (data) => ({
  type: SITE_SETTING_SINGLE_UPDATED,
  data,
});

export const siteSettingSingleUpdate = (data, siteSettingId) => {
  return (dispatch) => {
    dispatch(siteSettingSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.SITE_SETTINGS}/${siteSettingId}`, data)
      .then((response) => dispatch(siteSettingSingleUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
