import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import MASpinner from '../common/MASpinner';
import BillingChecklistTable from './BillingChecklistTable';
import * as ROUTES from '../../lib/routing/frontend';
import { consultantListFetch } from '../../lib/redux/actions/consultant';
import { billingChecklistFetch, billingChecklistSetDate } from '../../lib/redux/actions/billingChecklist';
import { billingChecklistWeeklyEventsFetch, billingChecklistWeeklyEventsSetDate } from '../../lib/redux/actions/billingChecklistWeeklyEvents';
import { connect } from 'react-redux';
import qs from 'qs';
import { getSubusersConsultantToManage, isSubUser } from '../../lib/helper/authorisation';
import Alert from 'react-bootstrap/Alert';
import WeeklyCalendarContainer from '../common/WeeklyCalendarContainer';

const moment = extendMoment(Moment);

const mapStateToProps = (state) => ({
  ...state.consultantList,
  ...state.billingChecklist,
  ...state.billingChecklistWeeklyEvents,
});

const mapDispatchToProps = {
  consultantListFetch,
  billingChecklistFetch,
  billingChecklistSetDate,
  billingChecklistWeeklyEventsFetch,
  billingChecklistWeeklyEventsSetDate,
};

class BillingChecklist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredConsultants: [],
    };
  }

  componentDidUpdate(prevProps) {
    const {
      currentDate,
      billingChecklistFetch,
      billingChecklistSetDate,
      billingChecklistWeeklyEventsFetch,
      billingChecklistWeeklyEventsSetDate,
      filters,
      consultants,
    } = this.props;

    if (prevProps.consultants !== consultants) {
      this.setState(() => {
        return { filteredConsultants: consultants };
      });
      if (isSubUser(true)) {
        this.filterConsultantsByText(getSubusersConsultantToManage().name);
      }
    }

    if (prevProps.match.params.date !== this.getQueryParamDate()) {
      billingChecklistSetDate(this.getQueryParamDate());
      billingChecklistWeeklyEventsSetDate(this.getQueryParamDate());
    }

    if (prevProps.currentDate !== currentDate) {
      billingChecklistFetch(currentDate, filters);
      billingChecklistWeeklyEventsFetch(currentDate, filters);
    }
  }

  componentDidMount() {
    const { consultantListFetch, billingChecklistFetch, billingChecklistWeeklyEventsFetch } = this.props;

    consultantListFetch(1, { pagination: false });
    billingChecklistFetch(this.getQueryParamDate());
    billingChecklistWeeklyEventsFetch(this.getQueryParamDate());
  }

  filterConsultantsByText(filterByText) {
    const { consultants } = this.props;
    this.setState(() => {
      return {
        filteredConsultants: consultants.filter((consultant) => {
          return consultant.name.toLowerCase().includes(filterByText.toLowerCase()) || consultant.code.toLowerCase().includes(filterByText.toLowerCase());
        }),
      };
    });
  }

  //  setConsultantFilter(e) {
  //    this.filterConsultantsByText(e.currentTarget.value);
  //  }

  getQueryParamDate() {
    const { useQueryString = false } = this.props;
    if (useQueryString) {
      const qsDate = qs.parse(this.props.location.search.replace('?', ''));
      return qsDate['date'] || moment().utc().format('DD-MM-YYYY');
    }

    return this.props.match.params.date || moment().utc().format('DD-MM-YYYY');
  }

  changePage(date) {
    const {
      history,
      billingChecklistSetDate,
      billingChecklistWeeklyEventsSetDate,
      useQueryString = false,
      paginationRoute = ROUTES.BILLING_CHECKLIST,
    } = this.props;
    billingChecklistSetDate(date);
    billingChecklistWeeklyEventsSetDate(date);
    if (useQueryString) {
      history.push(paginationRoute + '?date=' + date);
    } else {
      history.push(paginationRoute.replace(':date', date));
    }
  }

  render() {
    const { checklistInvoiceBatches, isFetchingChecklistInvoicesBatches, checklistWeeklyEvents, isFetchingChecklistWeeklyEvents } = this.props;

    if (isFetchingChecklistInvoicesBatches || isFetchingChecklistWeeklyEvents) {
      return <MASpinner />;
    }

    return (
      <WeeklyCalendarContainer
        title={'Billing Checklist'}
        changePage={this.changePage.bind(this)}
        match={this.props.match}
        useQueryString={this.props.useQueryString}>
        <BillingChecklistTable
          currentDate={this.getQueryParamDate()}
          consultants={this.state.filteredConsultants.sort((consultantA, consultantB) => {
            return consultantA.primaryUser.lastName.replace(' ', '').localeCompare(consultantB.primaryUser.lastName.replace(' ', ''));
          })}
          checklistInvoiceBatches={checklistInvoiceBatches}
          checklistWeeklyEvents={checklistWeeklyEvents}
        />

        <Alert variant={'danger'} className="text-center m-2 d-block d-sm-none">
          The Billing Checklist is not suitable for display on mobile devices. Please log in on a desktop or tablet to view.
        </Alert>
      </WeeklyCalendarContainer>

      //        <Table hover className="mediaccounts-billing-checklist-table mb-0">
      //          {this.renderHeader()}
      //          <tbody>
      //          {consultants.map(consultant => this.renderRow(consultant))}
      //          </tbody>
      //        </Table>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingChecklist);
