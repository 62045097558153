import React, { Component } from 'react';
import { userNameFormatter } from '../../lib/helper/formatting';
import Table from 'react-bootstrap/Table';

class ConsultantSingleTableView extends Component {
  render() {
    const { consultant } = this.props;
    if (!consultant) {
      return <></>;
    }
    return (
      <Table hover bordered className="mediaccounts-batch-invoice-ccsd-table mb-0 d-none d-sm-table bg-light">
        <tbody>
          <tr>
            <th colSpan={2}>Account Details</th>
          </tr>
          {consultant.code && (
            <tr>
              <td>Account Code</td>
              <td>{consultant.code}</td>
            </tr>
          )}
          {consultant.name && (
            <tr>
              <td>Account Name</td>
              <td>{consultant.name}</td>
            </tr>
          )}
          {consultant.primaryUser && (
            <tr>
              <td>Primary User</td>
              <td>
                {userNameFormatter(consultant.primaryUser)} ({consultant.primaryUser.email})
              </td>
            </tr>
          )}
          <tr>
            <th colSpan={2}>Consultant Specifics</th>
          </tr>
          {consultant.consultantType && (
            <tr>
              <td>Consultant Type</td>
              <td>{consultant.consultantType.displayName}</td>
            </tr>
          )}
          {consultant.gmcReferenceNumber && (
            <tr>
              <td>GMC Number</td>
              <td>{consultant.gmcReferenceNumber}</td>
            </tr>
          )}
          {consultant.speciality && (
            <tr>
              <td>Speciality</td>
              <td>{consultant.speciality}</td>
            </tr>
          )}
          {consultant.invoicePrefix && (
            <tr>
              <td>Invoice Prefix</td>
              <td>{consultant.invoicePrefix}</td>
            </tr>
          )}
          {consultant.phoneNumber || consultant.clientAccountNumber || consultant.clientSortCode ? (
            <tr>
              <th colSpan={2}>Client Account Details</th>
            </tr>
          ) : null}
          {consultant.phoneNumber && (
            <tr>
              <td>Client Phone Number</td>
              <td>{consultant.phoneNumber}</td>
            </tr>
          )}
          {consultant.clientAccountNumber && (
            <tr>
              <td>Client Account Number</td>
              <td>{consultant.clientAccountNumber}</td>
            </tr>
          )}
          {consultant.clientSortCode && (
            <tr>
              <td>Client Sort Code</td>
              <td>{consultant.clientSortCode}</td>
            </tr>
          )}
          {consultant.personalAccountNumber || consultant.personalSortCode ? (
            <tr>
              <th colSpan={2}>Personal Account Details</th>
            </tr>
          ) : null}
          {consultant.personalAccountNumber && (
            <tr>
              <td>Personal Account Number</td>
              <td>{consultant.personalAccountNumber}</td>
            </tr>
          )}
          {consultant.personalSortCode && (
            <tr>
              <td>Personal Sort Code</td>
              <td>{consultant.personalSortCode}</td>
            </tr>
          )}
          {consultant.secretaryName || consultant.secretaryEmail || consultant.secretaryPhoneNumber ? (
            <tr>
              <th colSpan={2}>Secretary Details</th>
            </tr>
          ) : null}
          {consultant.secretaryName && (
            <tr>
              <td>Name</td>
              <td>{consultant.secretaryName}</td>
            </tr>
          )}
          {consultant.secretaryPhoneNumber && (
            <tr>
              <td>Phone Number</td>
              <td>{consultant.secretaryPhoneNumber}</td>
            </tr>
          )}
          {consultant.secretaryEmail && (
            <tr>
              <td>Email</td>
              <td>{consultant.secretaryEmail}</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default ConsultantSingleTableView;
