import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { invoiceBatchNoteDelete } from '../../lib/redux/actions/invoiceBatchNote';
import { connect } from 'react-redux';
import InvoiceBatchNote from './InvoiceBatchNote';
import { invoiceBatchSingleFetch } from '../../lib/redux/actions/invoiceBatch';

const mapStateToProps = (state) => ({
  ...state.invoiceBatchSingle,
});

const mapDispatchToProps = {
  invoiceBatchNoteDelete,
  invoiceBatchSingleFetch,
};

class InvoiceBatchNotes extends React.Component {
  render() {
    const { invoiceBatchNotes, invoiceBatchNoteDelete, invoiceBatchSingleFetch, invoiceBatch } = this.props;

    const deleteNote = (noteId) => {
      invoiceBatchNoteDelete(noteId).then(() => {
        invoiceBatchSingleFetch(invoiceBatch.id);
      });
    };

    return (
      <Row>
        <Col>
          <div className="invoice-batch-single__section mb-3">
            <div className="invoice-batch-single__section-icon">
              <i className="fas fa-envelope" />
            </div>
            <h2 className="invoice-batch-single__section-header">Notes</h2>
            <div className="invoice-batch-single__notes-wrapper">
              {(!invoiceBatchNotes || invoiceBatchNotes.length === 0) && <p>No notes have been added to this Invoice Batch</p>}
              <TransitionGroup>
                {invoiceBatchNotes &&
                  invoiceBatchNotes.length > 0 &&
                  invoiceBatchNotes.map((note) => (
                    <CSSTransition key={note.id} timeout={500} classNames="fade">
                      <InvoiceBatchNote note={note} deleteNote={deleteNote} />
                    </CSSTransition>
                  ))}
              </TransitionGroup>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchNotes);
