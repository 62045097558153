import {
  CCSD_CODE_COST_LIST_REQUEST,
  CCSD_CODE_COST_LIST_RECEIVED,
  CCSD_CODE_COST_LIST_ERROR,
  CCSD_CODE_COST_LIST_SET_PAGE,
  CCSD_CODE_COST_LIST_UNLOAD,
  CCSD_CODE_COST_SINGLE_UPDATED,
  CCSD_CODE_COST_SINGLE_UPDATE_REQUEST,
  CCSD_CODE_COST_SINGLE_REQUEST,
  CCSD_CODE_COST_SINGLE_RECEIVED,
  CCSD_CODE_COST_SINGLE_CREATE_REQUEST,
  CCSD_CODE_COST_SINGLE_CREATE_SUCCESS,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const API_ROOT = window.API_URL;

const reducer = (
  state = {
    ccsdCodeCosts: [],
    isFetchingCcsdCodeCosts: false,
    currentPage: 1,
    pageCount: null,
    nextPage: false,
  },
  action,
) => {
  switch (action.type) {
    case CCSD_CODE_COST_LIST_UNLOAD:
      return {
        ccsdCodeCosts: [],
        isFetchingCcsdCodeCosts: false,
        isFetchingCcsdCodeCost: false,
        isUpdatingCcsdCodeCost: false,
        currentPage: 1,
        pageCount: null,
        nextPage: false,
      };

    case CCSD_CODE_COST_LIST_REQUEST:
      return {
        ...state,
        isFetchingCcsdCodeCosts: true,
      };

    case CCSD_CODE_COST_LIST_RECEIVED: {
      let nextPage = false;
      if (action.data['hydra:view'] && action.data['hydra:view']['hydra:next']) {
        const nextPageUrl = new URL(action.data['hydra:view']['hydra:next'], API_ROOT);
        nextPage = nextPageUrl.searchParams.get('page');
      }
      let newCcsdCodeCosts = action.data['hydra:member'];
      if (action.append) {
        const mergedCcsdCodeCosts = [...state.ccsdCodeCosts, ...action.data['hydra:member']];
        newCcsdCodeCosts = Array.from(new Set(mergedCcsdCodeCosts.map((a) => a['@id']))).map((id) => {
          return mergedCcsdCodeCosts.find((a) => a['@id'] === id);
        });
      }

      return {
        ...state,
        ccsdCodeCosts: newCcsdCodeCosts,
        pageCount: hydraPageCount(action.data),
        nextPage: nextPage,
        isFetchingCcsdCodeCosts: false,
      };
    }
    case CCSD_CODE_COST_LIST_ERROR:
      return {
        ...state,
        isFetchingCcsdCodeCosts: false,
      };
    case CCSD_CODE_COST_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case CCSD_CODE_COST_SINGLE_CREATE_REQUEST:
    case CCSD_CODE_COST_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingCcsdCodeCost: true,
      };
      return state;

    case CCSD_CODE_COST_SINGLE_CREATE_SUCCESS:
    case CCSD_CODE_COST_SINGLE_RECEIVED:
    case CCSD_CODE_COST_SINGLE_UPDATED:
      return {
        ...state,
        ccsdCodeCosts: [
          ...state.ccsdCodeCosts.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        isFetchingCcsdCodeCost: false,
        isUpdatingCcsdCodeCost: false,
      };

    case CCSD_CODE_COST_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingCcsdCodeCost: true,
      };

    default:
      return state;
  }
};

export default reducer;
