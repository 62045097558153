import { STATS_UPDATED } from '../constants';

const reducer = (
  state = {
    stats: [],
  },
  action,
) => {
  switch (action.type) {
    case STATS_UPDATED:
      return {
        ...state,
        stats: action.stats,
      };

    default:
      return state;
  }
};

export default reducer;
