import {
  INVOICE_BATCH_NOTE_ADD_REQUEST,
  INVOICE_BATCH_NOTE_ADDED,
  INVOICE_BATCH_NOTE_DELETE_REQUEST,
  INVOICE_BATCH_NOTE_DELETED,
  INVOICE_BATCH_NOTES_ERROR,
  INVOICE_BATCH_NOTES_RECEIVED,
  INVOICE_BATCH_NOTES_REQUEST,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceBatchNoteAddRequest = () => ({
  type: INVOICE_BATCH_NOTE_ADD_REQUEST,
});

export const invoiceBatchNoteAdded = (data) => ({
  type: INVOICE_BATCH_NOTE_ADDED,
  data,
});

export const invoiceBatchNoteAdd = (data) => {
  return (dispatch) => {
    dispatch(invoiceBatchNoteAddRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_BATCH_NOTE}`, data)
      .then((response) => dispatch(invoiceBatchNoteAdded(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceBatchNoteDeleteRequest = () => ({
  type: INVOICE_BATCH_NOTE_DELETE_REQUEST,
});

export const invoiceBatchNoteDeleted = (id) => ({
  type: INVOICE_BATCH_NOTE_DELETED,
  id,
});

export const invoiceBatchNoteDelete = (invoiceBatchNoteId) => {
  return (dispatch) => {
    dispatch(invoiceBatchNoteDeleteRequest());
    return requests
      .delete(`${API_ROUTES.INVOICE_BATCH_NOTE}/${invoiceBatchNoteId}`)
      .then(() => dispatch(invoiceBatchNoteDeleted(invoiceBatchNoteId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceBatchNotesRequest = () => ({
  type: INVOICE_BATCH_NOTES_REQUEST,
});

export const invoiceBatchNotesReceived = (data, invoiceBatchId) => ({
  type: INVOICE_BATCH_NOTES_RECEIVED,
  data,
  invoiceBatchId,
});

export const invoiceBatchNotesError = (error) => ({
  type: INVOICE_BATCH_NOTES_ERROR,
  error,
});

export const invoiceBatchNotesFetch = (invoiceBatchId) => {
  return (dispatch) => {
    dispatch(invoiceBatchNotesRequest());
    //console.log('Fetching notes for invoice batch ...');

    return requests
      .get(`${API_ROUTES.INVOICE_BATCH_NOTES.replace(':id', invoiceBatchId)}`, { pagination: false })
      .then((response) => dispatch(invoiceBatchNotesReceived(response, invoiceBatchId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceBatchNotesError(parseApiErrors(error)));
      });
  };
};
