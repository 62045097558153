import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ImportCSVForm from '../../components/forms/Invoice/ImportCSVForm';
import MASpinner from '../../components/common/MASpinner';
import { getFormValues } from 'redux-form';

import { connect } from 'react-redux';
import { invoiceUnassign } from '../../lib/redux/actions/invoices';
import { addToast } from '../../lib/redux/actions/toast';

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    currentFormValues: getFormValues('CreateForm')(state),
    isUnassigningInvoices: state.invoices.isUnassigningInvoices,

    ...state.invoiceFiles,
  };
}

const mapDispatchToProps = {
  invoiceUnassign,
  addToast,
};

class InvoiceImportCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      confirmed: false,
    };
    this.setErrors.bind(this);
    this.timeoutHandle = false;
  }

  setErrors(errors) {
    this.setState({
      errors: [...errors],
    });
  }

  confirm() {
    this.setState(() => ({ confirmed: true }));

    this.timeoutHandle = setTimeout(() => {
      this.setState(() => ({ confirmed: false }));
    }, 10000);
  }

  unassignInvoices() {
    return this.props.invoiceUnassign().then((response) => {
      this.props.addToast(`${response.unassigned} invoices unassigned`, true);
      this.setState({ confirmed: false });
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Upload credit control invoices</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="crud__section mb-3" style={{ height: '400px' }}>
                      <div className="crud__section-icon">
                        <i className="fas fa-upload" />
                      </div>
                      <h2 className="crud__section-header">Submit files</h2>
                      <p>Upload credit control invoice CSV files (from Medicodes) here for processing</p>
                      <ImportCSVForm setErrors={(errors) => this.setErrors(errors)} />
                    </div>
                  </Col>
                  {this.state.errors.length > 0 ? (
                    <Col>
                      <Row>
                        <Col>
                          <div className="crud__section mb-3" style={{ overflow: 'auto', height: '400px' }}>
                            <h2 className="crud__section-header" style={{ paddingLeft: 0 }}>
                              Log
                            </h2>
                            <pre>
                              {this.state.errors.map((error) => {
                                return <p className={'mb-1'}>{error}</p>;
                              })}
                            </pre>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
                <hr />
                <Row>
                  <Col>
                    <button
                      type="button"
                      onClick={
                        this.state.confirmed
                          ? this.unassignInvoices.bind(this)
                          : () => {
                              this.confirm();
                            }
                      }
                      className={`btn ${this.state.confirmed ? 'btn-danger' : 'btn-outline-danger'}`}>
                      {this.props.isUnassigningInvoices ? (
                        <MASpinner />
                      ) : this.state.confirmed ? (
                        'Are you sure you want to unassign all invoices?'
                      ) : (
                        'UNASSIGN ALL INVOICES'
                      )}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceImportCSV);
