import React from 'react';
import { Redirect } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { userLogin2faAttempt, userSkipLogin2faAttempt, userRequestEmail2faCode } from '../../../lib/redux/actions/user';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import * as ROUTES from '../../../lib/routing/frontend';
import { addToast } from '../../../lib/redux/actions/toast';
import qs from 'qs';
import { isDevMode } from '../../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.auth,
});
const mapDispatchToProps = {
  userLogin2faAttempt,
  userSkipLogin2faAttempt,
  userRequestEmail2faCode,
  addToast,
};

class TwoFactorAuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkEmail: false,
    };

    this.challengeInput = React.createRef();
    this.focus = this.focus.bind(this);
  }

  focus() {
    this.challengeInput.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      let redirectTo = ROUTES.HOME;
      if (this.props.location && this.props.location.search) {
        const qsParams = qs.parse(this.props.location.search.replace('?', ''));
        if (qsParams.redirectTo) {
          redirectTo = qsParams.redirectTo;
          delete qsParams.redirectTo;
          redirectTo += '?' + qs.stringify(qsParams);
        }
      }
      this.props.history.push(redirectTo);
    }
  }

  getEmailLink() {
    const { addToast } = this.props;
    this.setState(() => {
      return { checkEmail: true };
    });
    return this.props.userRequestEmail2faCode().then(() => {
      addToast("We've sent you an email. Please check and enter the code above", true);
      const challengeInput = document.getElementById('challengeInput');
      if (challengeInput) {
        challengeInput.focus();
      }
    });
  }

  onSubmit(values) {
    const { userLogin2faAttempt } = this.props;
    return userLogin2faAttempt(values.challenge, this.state.checkEmail);
  }

  onSkip() {
    const { userSkipLogin2faAttempt } = this.props;
    return userSkipLogin2faAttempt();
  }

  render() {
    const { handleSubmit, error, isAuthenticated, is2faAuthenticated, qrCode, isClient } = this.props;
    if (!isAuthenticated) {
      // console.log('Redirect from Login to Home because Authed');
      return <Redirect to={ROUTES.LOGIN} />;
    }
    if (is2faAuthenticated) {
      // console.log('Redirect from Login to Home because Authed');
      return <Redirect to={ROUTES.HOME} />;
    }
    return (
      <Card className="login-card">
        <Card.Header>2-Factor Authentication </Card.Header>
        <Card.Body>
          <div className="mediaaccounts-2fa-explainer">
            <p className="twofactor-skipping">
              With 2-factor authentication, an extra layer of security is added to your account to prevent someone from logging in, even if they have your
              password.{' '}
            </p>
          </div>
          {qrCode && isClient && (
            <div className="twofactor-skipping">
              Don&apos;t want to secure your account?{' '}
              <button
                className="btn btn-link"
                onClick={() => {
                  this.onSkip();
                }}>
                Skip and login
              </button>
            </div>
          )}
          {this.state.checkEmail
            ? ''
            : qrCode && (
                <div className="mediaaccounts-2fa-explainer">
                  <h4 className="mb-4 mt-4">Complete the following steps to activate 2-factor authentication for your account:</h4>
                  <p>
                    <span className="step-label">Step 1</span>
                    <br />
                    Download Twilio Authy or an alternative 2FA app for your phone/tablet
                  </p>
                  <p className="appstore-links mb-2">
                    <a href="https://play.google.com/store/apps/details?id=com.authy.authy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <img alt="Get it on Google Play" src="/images/google--en_badge_web_generic.png" className="mb-2" />
                    </a>

                    <a href="https://apps.apple.com/us/app/twilio-authy/id494168017?itsct=apps_box&amp;itscg=30200">
                      <img src="/images/apple--en_badge_web_generic.png" alt="Download on the App Store" className="mb-2" />
                    </a>
                  </p>
                  <hr />
                  <p className="mb-4">
                    <span className="step-label">Step 2</span>
                    <br />
                    Scan the QR code below to continue setup and enter the code below
                  </p>
                  <div className="text-center qr-code-wrapper mb-4">
                    <img src={qrCode} alt="Scan this QR code to setup 2FA auth in your authenticator app" />
                  </div>
                </div>
              )}

          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field
              id="challengeInput"
              name="challenge"
              autoFocus={!qrCode}
              label={`Enter the ${this.state.checkEmail ? 'Email ' : ''}Authentication Code:`}
              type="text"
              component={renderField}
              onChange={(e) => {
                if (/^[0-9]{6,6}$/.test(e.target.value)) {
                  handleSubmit(this.onSubmit.bind(this, { challenge: e.target.value }))();
                }
              }}
            />
            <button type="submit" className="btn btn-primary btn-block">
              Confirm
            </button>
            {qrCode && isDevMode() && (
              <button
                type="button"
                onClick={() => {
                  this.onSkip();
                }}
                className="btn btn-danger btn-block">
                Skip For Now {isDevMode() && `(Shown for dev mode only)`}
              </button>
            )}
          </Form>
          <br />

          {this.state.checkEmail && (
            <p className="text-center">
              Can&apos;t access email?{' '}
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  this.setState(() => {
                    return { checkEmail: false };
                  });
                }}>
                Use the QR code
              </button>
            </p>
          )}
          {!this.state.checkEmail && (
            <p className="text-center">
              Can&apos;t access the authenticator App?{' '}
              <button type="button" className="btn btn-link" onClick={this.getEmailLink.bind(this)}>
                Get a code by email
              </button>
            </p>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'Setup2faForm',
})(connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthForm));
