import React from 'react';
import InvoiceBatchListTable from './InvoiceBatchListTable';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { invoiceBatchListFetch, invoiceBatchListSetPage, invoiceBatchListUnload, invoiceBatchListClearFilters } from '../../lib/redux/actions/invoiceBatch';
import InvoiceTableFilterForm from '../../components/forms/InvoiceBatch/InvoiceBatchTableFilterForm';
import Paginator from '../../components/common/Paginator';
import MASpinner from '../../components/common/MASpinner';
import BulkAssignForm from '../../components/forms/InvoiceBatch/BulkAssignForm';
import { getNumberOfInvoices } from '../../lib/helper/invoiceBatch';

const mapStateToProps = (state) => ({
  ...state.invoiceBatchList,
  ...state.auth,
});

const mapDispatchToProps = {
  invoiceBatchListFetch,
  invoiceBatchListSetPage,
  invoiceBatchListUnload,
  invoiceBatchListClearFilters,
};

class InvoiceBatchListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      numberOfInvoicesBulkSelected: 0,
      bulkSelectedInvoiceBatches: [],
    };
  }

  componentDidMount() {
    this.loadInvoiceBatches();
  }

  componentWillUnmount() {
    this.props.invoiceBatchListUnload();
  }

  loadInvoiceBatches() {
    this.setState(() => ({
      totalInvoicesBulkSelected: 0,
      bulkSelectedInvoiceBatches: [],
    }));
    this.props.invoiceBatchListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    return { ...filters, ...defaultFilters };
  }

  componentDidUpdate(prevProps) {
    const { currentPage, invoiceBatchListFetch, invoiceBatchListSetPage, filters, defaultFilters, invoiceBatchListUnload, invoiceBatchListClearFilters } =
      this.props;

    /** If defaultFilters change the we are going to a different version of the invoice list page so unload current dataSet **/
    if (prevProps.defaultFilters !== defaultFilters) {
      invoiceBatchListUnload();
      invoiceBatchListClearFilters();
      this.loadInvoiceBatches();
      this.setState(() => {
        return { showFilters: false };
      });
    }
    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      invoiceBatchListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      invoiceBatchListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, invoiceBatchListSetPage, path } = this.props;
    invoiceBatchListSetPage(page);
    history.push(path.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  invoiceBatchBulkSelected(invoiceBatch) {
    const { totalInvoicesBulkSelected, bulkSelectedInvoiceBatches } = this.state;
    let noOfInvoicesForBatch = getNumberOfInvoices(invoiceBatch);
    if (noOfInvoicesForBatch === '-') {
      noOfInvoicesForBatch = 0;
    }
    let invoiceBatchesToSelect = bulkSelectedInvoiceBatches;

    let newTotal;
    // if already selected, remove it
    if (bulkSelectedInvoiceBatches.some((invoice) => invoice === invoiceBatch.id)) {
      invoiceBatchesToSelect = invoiceBatchesToSelect.filter((invoiceBatchId) => invoiceBatchId !== invoiceBatch.id);
      newTotal = totalInvoicesBulkSelected - noOfInvoicesForBatch;
    } else {
      // otherwise add it
      invoiceBatchesToSelect.push(invoiceBatch.id);
      newTotal = totalInvoicesBulkSelected + noOfInvoicesForBatch;
    }
    this.setState({
      totalInvoicesBulkSelected: newTotal,
      bulkSelectedInvoiceBatches: invoiceBatchesToSelect,
    });
  }

  render() {
    const {
      userData,
      invoiceBatches,
      isFetchingInvoiceBatches,
      currentPage,
      pageCount,
      pageTitle,
      filterOverrides = {},
      showBulkSelect = false,
      enableBulkAssign = false,
      showUrgentCategory = false,
      showProblemCategory = false,
    } = this.props;
    const { bulkSelectedInvoiceBatches = [], totalInvoicesBulkSelected = 0 } = this.state;
    return (
      <Card className={pageTitle.replace(/\s+/g, '-').toLowerCase()}>
        <Card.Header>
          <div className="d-flex justify-content-between">
            {' '}
            {pageTitle}
            {!isFetchingInvoiceBatches && invoiceBatches && invoiceBatches.length > 0 && (
              <div>
                <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                  Filters
                </button>
                <span className="tabular-data-result-count">{pageCount} pages</span>
              </div>
            )}
          </div>
        </Card.Header>

        {this.state.showFilters && (
          <Card.Body className={'mediaccount-filter-wrapper'}>
            <InvoiceTableFilterForm filterOverrides={filterOverrides} />
          </Card.Body>
        )}

        {showBulkSelect && enableBulkAssign && (
          <BulkAssignForm
            invoiceBatchIdsToAssign={bulkSelectedInvoiceBatches}
            numberOfInvoices={totalInvoicesBulkSelected}
            loadInvoiceBatches={this.loadInvoiceBatches.bind(this)}
          />
        )}

        {isFetchingInvoiceBatches && (
          <Card.Body>
            <MASpinner />
          </Card.Body>
        )}

        {((!isFetchingInvoiceBatches && invoiceBatches.length < 1) || invoiceBatches.length > 0) && (
          <InvoiceBatchListTable
            isFetchingInvoiceBatches={isFetchingInvoiceBatches}
            currentUserIRI={userData['@id']}
            invoiceBatches={invoiceBatches}
            showBulkSelect={showBulkSelect}
            showUrgentCategory={showUrgentCategory}
            showProblemCategory={showProblemCategory}
            bulkSelectedInvoiceBatches={bulkSelectedInvoiceBatches}
            bulkSelectAction={this.invoiceBatchBulkSelected.bind(this)}
          />
        )}
        {!isFetchingInvoiceBatches && invoiceBatches && invoiceBatches.length > 0 && (
          <Paginator
            currentPage={currentPage}
            pageCount={pageCount}
            setPage={this.changePage.bind(this)}
            nextPage={this.nextPage.bind(this)}
            prevPage={this.prevPage.bind(this)}
          />
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchListContainer);
