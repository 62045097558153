import React from 'react';
import MASpinner from '../../common/MASpinner';
import ReportsListTable from '../../../pages/ReportsList/ReportsListTable';
import Alert from 'react-bootstrap/Alert';

class UploadPart3Form extends React.Component {
  renderTable(hasReports) {
    const { reportBatch, downloadReportFile, addToast } = this.props;

    if (!hasReports) {
      return (
        <div>
          <MASpinner />
          <p className="text-center">Waiting for reports to process</p>
        </div>
      );
    }

    return (
      <ReportsListTable
        tableClasses="table--reports-preview"
        tableSize="sm"
        reports={reportBatch.reports}
        addToast={addToast}
        downloadFile={downloadReportFile}
      />
    );
  }

  publish() {
    const { reportBatch, reportBatchPublish } = this.props;

    return reportBatchPublish(reportBatch.id);
  }

  render() {
    const { isComplete = false, reportBatch, isPublishing = false } = this.props;
    const hasReports = reportBatch && reportBatch.reports && reportBatch.reports.length > 0;
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <span className="font-weight-bolder">3</span>
        </div>
        {isComplete ? (
          <h2 className="crud__section-header mb-0 d-flex justify-content-between">
            Reports created (preview)
            <i className="fa fa-check-circle text-primary" />
          </h2>
        ) : (
          <>
            <h2 className="crud__section-header">Preview of reports created</h2>
            <div className="table-responsive table-max-height--table--reports-preview">{this.renderTable(hasReports)}</div>
            {reportBatch.potentialIssues && reportBatch.potentialIssues.length > 0 && (
              <Alert variant="danger">
                <p>We found some potential issues:</p>
                <ul>
                  {reportBatch.potentialIssues.split('|||').map((issue) => (
                    <li key={issue}>{issue}</li>
                  ))}
                </ul>
                <p className="mb-0">Please check to ensure all reports have been created with all the files you expect before proceeding.</p>
              </Alert>
            )}
            {hasReports && !isPublishing && (
              <button onClick={this.publish.bind(this)} className="btn btn-primary">
                Confirm and send emails
              </button>
            )}
            {isPublishing && <MASpinner />}
          </>
        )}
      </div>
    );
  }
}

export default UploadPart3Form;
