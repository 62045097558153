import Form from 'react-bootstrap/Form';
import React from 'react';
import PropTypes from 'prop-types';
import { invoiceBatchSingleUpdate } from '../../../lib/redux/actions/invoiceBatch';
import { addToast } from '../../../lib/redux/actions/toast';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../lib/helper/form';

const mapStateToProps = (state, props) => {
  return {
    isUpdatingInvoiceBatch: state.invoiceBatchSingle.isUpdatingInvoiceBatch,
    initialValues: { numberOfInvoices: props.invoiceBatch.numberOfInvoicesToProcess },
  };
};

const mapDispatchToProps = {
  invoiceBatchSingleUpdate,
  addToast,
};

class EditNumberOfInvoicesForm extends React.Component {
  onSubmit(values) {
    const { invoiceBatchSingleUpdate, invoiceBatch, addToast, onUpdateSuccess } = this.props;
    const data = {
      numberOfInvoicesToProcess: Number(values.numberOfInvoices),
    };

    return invoiceBatchSingleUpdate(data, invoiceBatch.id).then(() => {
      addToast('Number of Invoices successfully updated', true);
      onUpdateSuccess();
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, error } = this.props;
    return (
      <>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="number-invoices-edit-form">
          <div className="input-group input-group-sm pt-3">
            <Field name="numberOfInvoices" aria-label="Invoices to Process" id="numberOfInvoices" type="number" component={renderField} />
            <div className="input-group-append">
              <button type="submit" disabled={pristine || submitting} className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

EditNumberOfInvoicesForm.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired,
  invoiceBatchSingleUpdate: PropTypes.func.isRequired,
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'EditNumberOfInvoicesForm',
    enableReinitialize: true,
  })(EditNumberOfInvoicesForm),
);
