import React from 'react';
import { connect } from 'react-redux';
import { invoiceBatchAuditLogUnload, invoiceBatchSingleFetch, invoiceBatchSingleUnload } from '../../lib/redux/actions/invoiceBatch';
import { invoiceBatchNotesFetch } from '../../lib/redux/actions/invoiceBatchNote';
import InvoiceBatchSingle from './InvoiceBatchSingle';
import MASpinner from '../../components/common/MASpinner';
import { downloadFile } from '../../lib/helper/downloadHelper';
import Card from 'react-bootstrap/Card';
import { addToast } from '../../lib/redux/actions/toast';
import INVOICE_BATCH_FILE_TYPES from '../../lib/helper/fileTypes';
import { fileClearReferences } from '../../lib/redux/actions/invoiceBatchFiles';

const mapStateToProps = (state) => ({
  ...state.invoiceBatchSingle,
  ...state.invoiceBatchFiles,
});

const mapDispatchToProps = {
  invoiceBatchSingleFetch,
  invoiceBatchSingleUnload,
  fileClearReferences,
  invoiceBatchAuditLogUnload,
  invoiceBatchNotesFetch,
  addToast,
};

class InvoiceBatchSingleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.initialLoad();
    this.state = {
      checkForFiles: false,
    };
  }

  initialLoad() {
    this.props.invoiceBatchSingleUnload();
    this.props.invoiceBatchAuditLogUnload();
    this.props.invoiceBatchSingleFetch(this.props.match.params.id);

    clearInterval(this.checkForFilesTimer);
    this.checkForFilesTimer = null;
  }

  onUploadStart() {
    this.setState({ checkForFiles: true });
  }

  componentWillUnmount() {
    this.props.invoiceBatchSingleUnload();
    this.props.invoiceBatchAuditLogUnload();
    this.props.fileClearReferences();
    clearInterval(this.checkForFilesTimer);
    this.checkForFilesTimer = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { invoiceBatchSingleFetch, invoiceBatch, invoiceBatchNotesFetch } = this.props;

    // if just the invoice number changes in the url - force a reload
    if (this.props?.match?.params?.id !== prevProps?.match?.params?.id && prevProps?.match?.params?.id !== false) {
      //      console.log('cdu: initialLoad');
      this.initialLoad();
    }

    if (prevProps.invoiceBatch !== invoiceBatch && invoiceBatch?.id) {
      //      console.log('cdu: fetch notes');
      invoiceBatchNotesFetch(invoiceBatch?.id);
    }

    // POLL AFTER FILE IS UPLOADED SO THAT THE STATE UPDATES ONCE THE FILES ARE RETURNED AFTER THE ASYNC PROCESS

    let previousCheckedAndPricedFilesCount = 0;
    if (prevProps.invoiceBatch && prevProps.invoiceBatch.invoiceFiles) {
      //      console.log('cdu: there are invoice files');
      previousCheckedAndPricedFilesCount = prevProps.invoiceBatch.invoiceFiles.length;
      //      console.log('cdu: there are invoice files - previousCheckedAndPricedFilesCount:'+ previousCheckedAndPricedFilesCount);
    }

    let latestCheckedAndPricedFile = false;
    if (invoiceBatch && invoiceBatch.invoiceFiles && invoiceBatch.invoiceFiles.length > previousCheckedAndPricedFilesCount) {
      latestCheckedAndPricedFile = invoiceBatch.invoiceFiles.filter(
        (invoiceFile) => invoiceFile.type === INVOICE_BATCH_FILE_TYPES.FILE_TYPE_INVOICE_CURRENT_CHECKED_AND_PRICED,
      )[0];
      //      console.log('cdu: invoiceBatch.invoiceFiles.length > previousCheckedAndPricedFilesCount : ',invoiceBatch.invoiceFiles.length ,previousCheckedAndPricedFilesCount);
    }

    //    console.log({ checkForFilesTimer: this.checkForFilesTimer, latestCheckedAndPricedFile });
    if (this.checkForFilesTimer !== null && latestCheckedAndPricedFile && latestCheckedAndPricedFile !== false) {
      //      console.log('cdu: this.checkForFilesTimer !== null && latestCheckedAndPricedFile && latestCheckedAndPricedFile !== false - clearInterval');
      clearInterval(this.checkForFilesTimer);
      this.checkForFilesTimer = null;
      this.setState({ checkForFiles: false });
    }

    if (prevState.checkForFiles !== this.state.checkForFiles && this.checkForFiles === true && this.checkForFilesTimer === null) {
      //console.log('cdu: prevProps.fileRequestInProgress !== fileRequestInProgress && fileRequestInProgress === true - set the interval request polling');
      this.checkForFilesTimer = setInterval(() => invoiceBatchSingleFetch(invoiceBatch.id), Number(process.env.REACT_APP_CHECK_FILES_INTERVAL_MS || 2000));
    }
  }

  render() {
    const { invoiceBatch, isFetchingInvoiceBatch, addToast, invoiceBatchNotes = [] } = this.props;
    if (!isFetchingInvoiceBatch && !invoiceBatch) {
      return (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">Not Found</div>
          </Card.Header>
          <Card.Body>
            <p>The invoice you are looking for cannot be found.</p>
          </Card.Body>
        </Card>
      );
    }
    if (isFetchingInvoiceBatch && !invoiceBatch) {
      return <MASpinner />;
    }
    return (
      <InvoiceBatchSingle
        addToast={addToast}
        invoiceBatch={invoiceBatch}
        invoiceBatchNotes={invoiceBatchNotes}
        downloadFile={downloadFile}
        onUploadStart={this.onUploadStart.bind(this)}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchSingleContainer);
