import { USER_LOGIN_SUCCESS, USER_LOGOUT, USER_PROFILE_RECEIVED, USER_SET_ID, USER_LOGIN_2FA_SUCCESS, USER_AGREED_TERMS } from '../constants';

const reducer = (
  state = {
    token: null,
    userId: null,
    isAuthenticated: null,
    is2faAuthenticated: null,
    qrCode: null,
    userData: null,
    isClient: null,
  },
  action,
) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      //console.log(USER_LOGIN_SUCCESS);
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        is2faAuthenticated: false,
        qrCode: action.qrCode,
        isClient: action.isClient ?? false,
      };

    case USER_LOGIN_2FA_SUCCESS:
      //console.log(USER_LOGIN_2FA_SUCCESS);
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        is2faAuthenticated: true,
        qrCode: null,
      };

    case USER_LOGOUT:
      //console.log(USER_LOGOUT);
      return {
        ...state,
        userId: null,
        token: null,
        isAuthenticated: false,
        is2faAuthenticated: false,
        userData: null,
        qrCode: null,
        isClient: null,
      };

    case USER_SET_ID:
      //console.log(USER_SET_ID);
      return {
        ...state,
        userId: action.userId,
        isAuthenticated: true,
        is2faAuthenticated: true,
      };

    case USER_PROFILE_RECEIVED:
      //console.log(USER_PROFILE_RECEIVED);
      return {
        ...state,
        userData: state.userId === action.userId && state.userData === null ? action.userData : state.userData,
        isAuthenticated: state.userId === action.userId && state.userData === null,
        is2faAuthenticated: state.userId === action.userId && state.userData === null,
      };

    case USER_AGREED_TERMS:
      //console.log(USER_PROFILE_RECEIVED);
      return {
        ...state,
        userData: state.userId === action.userId && state.userData.dateTermsRead === undefined ? action.userData : state.userData,
      };
    default:
      return state;
  }
};

export default reducer;
