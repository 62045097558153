import { SUB_USER_SINGLE_ERROR, SUB_USER_SINGLE_RECEIVED, SUB_USER_SINGLE_REQUEST, SUBUSER_SET_CONSULTANT_TO_MANAGE } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';
import { userLogout } from './user';

export const subuserSetConsultantToManage = (consultant) => ({
  type: SUBUSER_SET_CONSULTANT_TO_MANAGE,
  consultantToManage: consultant,
});

export const subUserRequest = () => {
  return {
    type: SUB_USER_SINGLE_REQUEST,
  };
};

export const subUserReceived = (data) => {
  return {
    type: SUB_USER_SINGLE_RECEIVED,
    data: data[0],
  };
};

export const subUserError = () => {
  return {
    type: SUB_USER_SINGLE_ERROR,
  };
};

export const subUserFetch = (userId, consultantId) => {
  // console.log('Attempting fetch sub user details ...');
  return (dispatch) => {
    dispatch(subUserRequest());
    return requests
      .get(API_ROUTES.SUB_USER_BY_CONSULTANT_ID.replace(':consultantId', consultantId))
      .then((response) => {
        dispatch(subUserReceived(response));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          throw new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(subUserError());
      });
  };
};
