import qs from 'qs';

export const parseApiErrors = (error) => {
  if (error && error.response && error.response.body && error.response.body.violations) {
    return error.response.body.violations.reduce((parsedErrors, violation) => {
      parsedErrors[violation['propertyPath']] = violation['message'];
      return parsedErrors;
    }, {});
  } else if (error && error.response && error.response.body && error.response.body['hydra:description']) {
    return { _error: error.response.body['hydra:description'] };
  }
};

export const parseFilterObjectToQueryString = (objToParse) => {
  const transformedObject = {};
  Object.keys(objToParse).map((key) => (transformedObject[key.replace('(', '[').replace(')', ']')] = objToParse[key]));
  return qs.stringify(transformedObject, { encode: false, arrayFormat: 'brackets' });
};

export const hydraPageCount = (collection) => {
  if (!collection['hydra:view'] || collection['hydra:view']['hydra:last'] === undefined) {
    return 1;
  }
  return Number(collection['hydra:view']['hydra:last'].match(/page=(\d+)/)[1]);
};
