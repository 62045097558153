import { Field } from 'redux-form';
import React from 'react';
import { consultantTicketUsersTypeaheadListFetch } from '../../lib/redux/actions/consultantTicketUsersTypeahead';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TypeAheadField from '../common/reduxForm/TypeaheadField';

const mapStateToProps = (state) => ({
  ...state.consultantTicketUsersTypeaheadList,
});
const mapDispatchToProps = {
  consultantTicketUsersTypeaheadListFetch,
};

function getSuffix(user) {
  if (user.roles.includes('ROLE_SUBUSER')) {
    return 'Consultant Authorised';
  } else if (!user.roles.includes('ROLE_PRIMARY_USER')) {
    return 'MediAccounts';
  }
  return 'Consultant';
}

class ConsultantTicketUsersTypeAhead extends React.Component {
  componentDidUpdate() {
    const { shouldClear, hasCleared } = this.props;
    if (shouldClear) {
      this.authorisedUsersRef.getRenderedComponent().clear();
      hasCleared();
    }
  }

  _onSearch(query) {
    const { consultant } = this.props;
    this.props.consultantTicketUsersTypeaheadListFetch({
      consultant: consultant,
      query,
    });
  }

  render() {
    const {
      onChange,
      authorisedUsers = [],
      isFetchingConsultantTicketUsers,
      multiple = true,
      label = 'Authorised Users',
      name = 'consultantAuthorisedUsers',
      selectedAuthorisedUsers = null,
    } = this.props;

    return (
      <Field
        name={name}
        isLoading={isFetchingConsultantTicketUsers}
        label={label}
        onSearch={this._onSearch.bind(this)}
        options={authorisedUsers || []}
        labelKey={(option) => `${option.firstName} ${option.lastName} (${option.email}) (${getSuffix(option)})`}
        component={TypeAheadField}
        multiple={multiple}
        onChange={onChange}
        ref={(ref) => (this.authorisedUsersRef = ref)}
        forwardRef
        selected={selectedAuthorisedUsers}
      />
    );
  }
}

ConsultantTicketUsersTypeAhead.propTypes = {
  shouldClear: PropTypes.bool.isRequired,
  hasCleared: PropTypes.func.isRequired,
  isFetchingConsultantTicketUsers: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  consultantTicketUsersTypeaheadListFetch: PropTypes.func.isRequired,
  consultant: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantTicketUsersTypeAhead);
