import {
  CONSULTANTS_LIST_REQUEST,
  CONSULTANTS_LIST_RECEIVED,
  CONSULTANTS_LIST_ERROR,
  CONSULTANTS_LIST_SET_PAGE,
  CONSULTANTS_LIST_SET_FILTERS,
  CONSULTANTS_LIST_CLEAR_FILTERS,
  CONSULTANTS_LIST_UNLOAD,
  CONSULTANT_SINGLE_UPDATED,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    consultants: [],
    isFetchingConsultants: false,
    currentPage: 1,
    pageCount: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANTS_LIST_REQUEST:
      return {
        ...state,
        isFetchingConsultants: true,
      };

    case CONSULTANTS_LIST_RECEIVED:
      return {
        ...state,
        consultants: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingConsultants: false,
      };

    case CONSULTANT_SINGLE_UPDATED: {
      const updatedConsultantsList = [...state.consultants];
      const removeIndex = updatedConsultantsList.map((item) => item.id).indexOf(action.data.id);

      if (removeIndex >= 0) {
        updatedConsultantsList.splice(removeIndex, 1);
        return {
          ...state,
          consultants: [...updatedConsultantsList, action.data].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        };
      }

      return {
        ...state,
      };
    }
    case CONSULTANTS_LIST_ERROR:
      return {
        ...state,
        isFetchingConsultants: false,
      };

    case CONSULTANTS_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case CONSULTANTS_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case CONSULTANTS_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    case CONSULTANTS_LIST_UNLOAD:
      return {
        consultants: [],
        isFetchingConsultants: false,
        currentPage: 1,
        pageCount: null,
        filters: {},
      };
    default:
      return state;
  }
};

export default reducer;
