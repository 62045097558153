import {
  WORK_SCHEDULE_EVENT_LIST_REQUEST,
  WORK_SCHEDULE_EVENT_LIST_RECEIVED,
  WORK_SCHEDULE_EVENT_LIST_ERROR,
  WORK_SCHEDULE_EVENT_LIST_SET_PAGE,
  WORK_SCHEDULE_EVENT_LIST_UNLOAD,
  WORK_SCHEDULE_EVENT_SINGLE_REQUEST,
  WORK_SCHEDULE_EVENT_SINGLE_RECEIVED,
  WORK_SCHEDULE_EVENT_SINGLE_ERROR,
  WORK_SCHEDULE_EVENT_LIST_SET_FILTERS,
  WORK_SCHEDULE_EVENT_LIST_CLEAR_FILTERS,
  WORK_SCHEDULE_EVENT_SINGLE_UPDATE_REQUEST,
  WORK_SCHEDULE_EVENT_SINGLE_UPDATED,
  WORK_SCHEDULE_EVENT_SINGLE_UNLOAD,
  WORK_SCHEDULE_EVENT_DELETE_REQUEST,
  WORK_SCHEDULE_EVENT_DELETED,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    workScheduleEvents: [],
    isFetchingWorkScheduleEvents: false,
    currentPage: 1,
    isUpdatingWorkScheduleEvent: false,
    isFetchingWorkScheduleEvent: false,
    hasLoadedWorkScheduleEvent: false,
    pageCount: null,
    workScheduleEvent: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case WORK_SCHEDULE_EVENT_LIST_UNLOAD:
      return {
        workScheduleEvents: [],
        isFetchingWorkScheduleEvents: false,
        currentPage: 1,
        pageCount: null,
        filters: {},
      };

    case WORK_SCHEDULE_EVENT_LIST_REQUEST:
      return {
        ...state,
        isFetchingWorkScheduleEvents: true,
      };

    case WORK_SCHEDULE_EVENT_LIST_RECEIVED:
      return {
        ...state,
        workScheduleEvents: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingWorkScheduleEvents: false,
      };

    case WORK_SCHEDULE_EVENT_LIST_ERROR:
      return {
        ...state,
        isFetchingWorkScheduleEvents: false,
      };
    case WORK_SCHEDULE_EVENT_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case WORK_SCHEDULE_EVENT_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingWorkScheduleEvent: true,
      };

    case WORK_SCHEDULE_EVENT_SINGLE_RECEIVED:
      return {
        ...state,
        workScheduleEvents: [
          ...state.workScheduleEvents.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        hasLoadedWorkScheduleEvent: true,
        workScheduleEvent: action.data,
        isFetchingWorkScheduleEvent: false,
      };

    case WORK_SCHEDULE_EVENT_SINGLE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingWorkScheduleEvent: false,
      };

    case WORK_SCHEDULE_EVENT_SINGLE_UNLOAD:
      return {
        ...state,
        workScheduleEvent: null,
        hasLoadedWorkScheduleEvent: false,
        error: null,
        isFetchingWorkScheduleEvent: false,
        isUpdatingWorkScheduleEvent: false,
      };

    case WORK_SCHEDULE_EVENT_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case WORK_SCHEDULE_EVENT_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    case WORK_SCHEDULE_EVENT_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingWorkScheduleEvent: true,
      };
      return state;

    case WORK_SCHEDULE_EVENT_SINGLE_UPDATED:
      return {
        ...state,
        workScheduleEvents: [
          ...state.workScheduleEvents.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
        workScheduleEvent: action.data,
        isUpdatingWorkScheduleEvent: false,
      };
    case WORK_SCHEDULE_EVENT_DELETE_REQUEST:
      return {
        ...state,
        isDeletingWorkScheduleEvent: true,
      };
    case WORK_SCHEDULE_EVENT_DELETED:
      return {
        ...state,
        isDeletingWorkScheduleEvent: false,
      };

    default:
      return state;
  }
};

export default reducer;
