import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import moment from 'moment/moment';
import MASpinner from '../../common/MASpinner';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { invoiceSingleUpdate } from '../../../lib/redux/actions/invoices';
import { invoiceEscalationReasonFetch } from '../../../lib/redux/actions/invoiceEscalationReason';
import { invoiceEscalationStatusFetch } from '../../../lib/redux/actions/invoiceEscalationStatus';

const mapStateToProps = (state, props) => {
  const initialValues = {
    isEscalated: props?.invoice?.isEscalated ?? false,
    invoiceEscalationReason: props.invoice?.invoiceEscalationReason?.['@id'],
    invoiceEscalationStatus: props.invoice?.invoiceEscalationStatus?.['@id'],
    revisitDate: props?.invoice?.revisitDate ? moment(props?.invoice?.revisitDate).format('YYYY-MM-DD') : null,
  };
  return {
    initialValues,
    ...state.invoiceEscalationReason,
    ...state.invoiceEscalationStatus,
    currentFormValues: getFormValues('InvoiceEscalationForm')(state),
  };
};

const mapDispatchToProps = {
  invoiceSingleUpdate,
  invoiceEscalationReasonFetch,
  invoiceEscalationStatusFetch,
  addToast,
};

class InvoiceEscalationForm extends React.Component {
  componentDidMount() {
    const { invoiceEscalationReasonFetch, invoiceEscalationStatusFetch } = this.props;

    invoiceEscalationReasonFetch();
    invoiceEscalationStatusFetch();
  }

  onSubmit(values) {
    const { invoiceSingleUpdate, invoice, addToast, reset } = this.props;
    if (!values['isEscalated']) {
      values['invoiceEscalationReason'] = null;
      values['invoiceEscalationStatus'] = null;
      values['revisitDate'] = null;
    }
    if (values['invoiceEscalationStatus'] === '') {
      values['invoiceEscalationStatus'] = null;
    }
    if (values['revisitDate'] === '') {
      values['revisitDate'] = null;
    }

    return invoiceSingleUpdate(values, invoice.id).then(() => {
      addToast('The Invoice was successfully updated', true);
      reset();
    });
  }

  render() {
    const { handleSubmit, currentFormValues, disabled, isUpdatingInvoice, invoiceEscalationReasons, invoiceEscalationStatuses, pristine, submitting } =
      this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <div className="mb-3">
          <Field name="isEscalated" label="Escalated?" id="is-escalated" component={renderCheckbox} />
        </div>
        {currentFormValues?.isEscalated ? (
          <>
            <div className="mb-3">
              <Field
                name="invoiceEscalationReason"
                label="Escalation Reason"
                id="reason"
                component={renderSelect}
                options={invoiceEscalationReasons.map((reason) => ({ text: reason.reason, value: reason['@id'] }))}
                emptyLabel={'Select Reason'}
                required={true}
              />
            </div>
            <div className="mb-3">
              <Field
                name="invoiceEscalationStatus"
                label="Escalation Status"
                id="status"
                component={renderSelect}
                options={invoiceEscalationStatuses.map((status) => ({ text: status.status, value: status['@id'] }))}
                emptyLabel={''}
              />
            </div>
            <div className="mb-3">
              <Field name="revisitDate" label="Revisit Date" type="date" min={moment().format('YYYY-MM-DD')} component={renderField} />
            </div>
          </>
        ) : null}
        {isUpdatingInvoice ? <MASpinner /> : null}
        {!isUpdatingInvoice && !pristine ? (
          <button type="submit" disabled={submitting || disabled} className="btn btn-primary">
            Update
          </button>
        ) : null}
      </Form>
    );
  }
}

InvoiceEscalationForm.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'InvoiceEscalationForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(InvoiceEscalationForm),
);
