import React from 'react';
import { connect } from 'react-redux';
import ReportBatchCreate from './ReportBatchCreate';
import {
  reportBatchCreate,
  reportBatchCreateUnload,
  reportBatchSingleFetch,
  reportBatchUpdate,
  reportBatchPublish,
} from '../../lib/redux/actions/reportBatches';
import { downloadReportFile } from '../../lib/helper/downloadHelper';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  ...state.reportBatchSingle,
});

const mapDispatchToProps = {
  reportBatchUpdate,
  reportBatchSingleFetch,
  reportBatchCreate,
  reportBatchCreateUnload,
  reportBatchPublish,
  addToast,
};

class ReportBatchCreateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.reportBatchTimer = null;
  }

  componentWillUnmount() {
    clearInterval(this.reportBatchTimer);
    this.reportBatchTimer = null;
    this.props.reportBatchCreateUnload();
  }

  resetForm() {
    this.props.history.push(ROUTES.BLANK);
    this.props.history.goBack();
  }

  componentDidUpdate(prevProps) {
    const { associatedDate, reportBatchSingleFetch, reportBatchUpdate, reportBatch } = this.props;

    // once a report batch is created by uploading a file, add the associatedDate
    if (prevProps.reportBatch === null && reportBatch && associatedDate) {
      reportBatchUpdate({ associatedDate }, reportBatch.id);
    }

    // if the reportBatch now exists with an associatedDate but no reports, poll update it until it has reports
    if (reportBatch && reportBatch.associatedDate && reportBatch.reports.length < 1 && this.reportBatchTimer === null) {
      this.reportBatchTimer = setInterval(
        () =>
          reportBatchSingleFetch(reportBatch.id).catch(() => {
            clearInterval(this.reportBatchTimer);
            this.reportBatchTimer = null;
          }),
        Number(process.env.REACT_APP_REPORT_BATCH_CHECK_FOR_REPORTS_INTERVAL_MS || 60000),
      );
    }

    // once reports exist clear the timer if still running
    if (reportBatch && reportBatch.associatedDate && reportBatch.reports.length > 0 && this.reportBatchTimer !== null) {
      clearInterval(this.reportBatchTimer);
      this.reportBatchTimer = null;
    }
  }

  render() {
    const { associatedDate, fileToUpload, reportBatch, reportBatchCreate, reportBatchPublish, publishInProgress, addToast } = this.props;

    return (
      <ReportBatchCreate
        resetForm={this.resetForm.bind(this)}
        addToast={addToast}
        downloadReportFile={downloadReportFile}
        associatedDate={associatedDate}
        fileToUpload={fileToUpload}
        reportBatch={reportBatch}
        reportBatchCreate={reportBatchCreate}
        reportBatchPublish={reportBatchPublish}
        publishInProgress={publishInProgress}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportBatchCreateContainer);
