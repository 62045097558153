import {
  INVOICE_BATCH_LIST_REQUEST,
  INVOICE_BATCH_LIST_RECEIVED,
  INVOICE_BATCH_LIST_ERROR,
  INVOICE_BATCH_LIST_SET_PAGE,
  INVOICE_BATCH_LIST_SET_FILTERS,
  INVOICE_BATCH_LIST_CLEAR_FILTERS,
  INVOICE_BATCH_SINGLE_REQUEST,
  INVOICE_BATCH_SINGLE_RECEIVED,
  INVOICE_BATCH_SINGLE_ERROR,
  INVOICE_BATCH_SINGLE_SPLIT_REQUEST,
  INVOICE_BATCH_SINGLE_SPLIT_RECEIVED,
  INVOICE_BATCH_SINGLE_SPLIT_ERROR,
  INVOICE_BATCH_SINGLE_UPDATE_REQUEST,
  INVOICE_BATCH_SINGLE_UPDATED,
  INVOICE_BATCH_SINGLE_UNLOAD,
  INVOICE_BATCH_LIST_UNLOAD,
  INVOICE_BATCH_ADD_REQUEST,
  INVOICE_BATCH_ADDED,
  INVOICE_BATCH_SINGLE_STATUS_CHANGE_REQUEST,
  INVOICE_BATCH_SINGLE_STATUS_CHANGED,
  INVOICE_BATCH_SINGLE_STATUS_CHANGE_ERROR,
  INVOICE_BATCH_AUDIT_LOG_RECEIVED,
  INVOICE_BATCH_AUDIT_LOG_REQUEST,
  INVOICE_BATCH_ASSIGN_REQUEST,
  INVOICE_BATCH_ASSIGN_RECEIVED,
  INVOICE_BATCH_ASSIGN_ERROR,
  INVOICE_BATCH_AUDIT_LOG_UNLOAD,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';
import { isSeniorStaff } from '../../helper/authorisation';
//import { consultantSingleFetch } from './consultant';

// INVOICE_BATCH_LIST

export const invoiceBatchListRequest = () => ({
  type: INVOICE_BATCH_LIST_REQUEST,
});

export const invoiceBatchListReceived = (data, appendUntilUnloaded) => ({
  type: INVOICE_BATCH_LIST_RECEIVED,
  data,
  appendUntilUnloaded,
});

export const invoiceBatchListError = (error) => ({
  type: INVOICE_BATCH_LIST_ERROR,
  error,
});

export const invoiceBatchListSetPage = (page) => ({
  type: INVOICE_BATCH_LIST_SET_PAGE,
  page,
});

export const invoiceBatchListSetFilters = (filters, routeKey) => ({
  type: INVOICE_BATCH_LIST_SET_FILTERS,
  filters,
  routeKey
});

export const invoiceBatchListClearFilters = () => ({
  type: INVOICE_BATCH_LIST_CLEAR_FILTERS,
});

export const invoiceBatchListUnload = () => ({
  type: INVOICE_BATCH_LIST_UNLOAD,
});

export const invoiceBatchListFetch = (page = 1, filters = {}, appendUntilUnloaded = false) => {
  return (dispatch) => {
    dispatch(invoiceBatchListRequest());
    //console.log('Fetching invoice batches ...');
    filters.page = page;

    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(`${API_ROUTES.INVOICE_BATCH}`, filters)
      .then((response) => dispatch(invoiceBatchListReceived(response, appendUntilUnloaded)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceBatchListError(error));
      });
  };
};

// INVOICE_BATCH_SINGLE

export const invoiceBatchSingleRequest = () => ({
  type: INVOICE_BATCH_SINGLE_REQUEST,
});

export const invoiceBatchSingleReceived = (data) => ({
  type: INVOICE_BATCH_SINGLE_RECEIVED,
  data,
});

export const invoiceBatchSingleError = (error) => ({
  type: INVOICE_BATCH_SINGLE_ERROR,
  error,
});

export const invoiceBatchSingleUnload = () => ({
  type: INVOICE_BATCH_SINGLE_UNLOAD,
});
export const invoiceBatchSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(invoiceBatchSingleRequest());
    //console.log('Fetching single invoice batch ...');
    return requests
      .get(`${API_ROUTES.INVOICE_BATCH}/${id}`)
      .then((response) => {
        //console.log({response,cid: response.consultant.id});
        //        dispatch(consultantSingleFetch(response.consultant.id, false, true));
        return dispatch(invoiceBatchSingleReceived(response));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceBatchSingleError(error));
      });
  };
};

export const invoiceBatchSingleSplitRequest = () => ({
  type: INVOICE_BATCH_SINGLE_SPLIT_REQUEST,
});

export const invoiceBatchSingleSplitReceived = (data) => ({
  type: INVOICE_BATCH_SINGLE_SPLIT_RECEIVED,
  data,
});

export const invoiceBatchSingleSplitError = (error) => ({
  type: INVOICE_BATCH_SINGLE_SPLIT_ERROR,
  error,
});

export const invoiceBatchSingleSplit = (invoiceBatchId, splitQty) => {
  return (dispatch) => {
    dispatch(invoiceBatchSingleSplitRequest());
    return requests
      .patch(`${API_ROUTES.INVOICE_BATCH}/${invoiceBatchId}/split?splitQty=` + splitQty, {})
      .then((response) => {
        dispatch(invoiceBatchSingleSplitReceived(response, invoiceBatchId));
        if (isSeniorStaff()) {
          dispatch(invoiceBatchAuditLogFetch(invoiceBatchId));
        }
        return response;
      })
      .catch((error) => {
        dispatch(invoiceBatchSingleSplitError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(error);
      });
  };
};

export const invoiceBatchAuditLogRequest = () => ({
  type: INVOICE_BATCH_AUDIT_LOG_REQUEST,
});

export const invoiceBatchAuditLogReceived = (data, id) => ({
  type: INVOICE_BATCH_AUDIT_LOG_RECEIVED,
  auditLog: data.invoiceBatchAuditLogs,
  id,
});

export const invoiceBatchAuditLogUnload = () => ({
  type: INVOICE_BATCH_AUDIT_LOG_UNLOAD,
});

export const invoiceBatchAuditLogFetch = (id) => {
  return (dispatch) => {
    dispatch(invoiceBatchAuditLogRequest());
    //console.log('Fetching audit logs for invoice batch ...');
    return requests
      .get(`${API_ROUTES.INVOICE_BATCH_AUDIT_LOG.replace(':id', id)}`)
      .then((response) => dispatch(invoiceBatchAuditLogReceived(response, id)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceBatchSingleError(error));
      });
  };
};

export const invoiceBatchSingleUpdateRequest = () => ({
  type: INVOICE_BATCH_SINGLE_UPDATE_REQUEST,
});

export const invoiceBatchSingleUpdated = (data) => ({
  type: INVOICE_BATCH_SINGLE_UPDATED,
  data,
});

export const invoiceBatchSingleUpdate = (data, invoiceBatchId, checkedAndPriceUpload = false, appendFiles = false) => {
  return (dispatch) => {
    dispatch(invoiceBatchSingleUpdateRequest());
    let endpointURL = `${API_ROUTES.INVOICE_BATCH}/${invoiceBatchId}`;
    if (checkedAndPriceUpload && !appendFiles) {
      endpointURL += API_ROUTES.INVOICE_BATCH_CHECKED_AND_PRICED_SUB_PATH;
    } else if (checkedAndPriceUpload && appendFiles) {
      endpointURL += API_ROUTES.INVOICE_BATCH_APPEND_CHECKED_AND_PRICED_SUB_PATH;
    }
    return requests
      .patch(endpointURL, data)
      .then((response) => {
        dispatch(invoiceBatchSingleUpdated(response));
        if (isSeniorStaff()) {
          dispatch(invoiceBatchAuditLogFetch(invoiceBatchId));
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceBatchSingleStatusChangeRequest = () => ({
  type: INVOICE_BATCH_SINGLE_STATUS_CHANGE_REQUEST,
});

export const invoiceBatchSingleStatusChanged = (data, id) => ({
  type: INVOICE_BATCH_SINGLE_STATUS_CHANGED,
  data,
  id,
});

export const invoiceBatchSingleStatusChangeError = (error) => ({
  type: INVOICE_BATCH_SINGLE_STATUS_CHANGE_ERROR,
  error,
});

const invoiceBatchSingleStatusChange = (invoiceBatchId, endpointPart) => {
  return (dispatch) => {
    dispatch(invoiceBatchSingleStatusChangeRequest());
    return requests
      .patch(`${API_ROUTES.INVOICE_BATCH}/${invoiceBatchId}/${endpointPart}`, {})
      .then((response) => {
        dispatch(invoiceBatchSingleStatusChanged(response, invoiceBatchId));
        if (isSeniorStaff()) {
          dispatch(invoiceBatchAuditLogFetch(invoiceBatchId));
        }
      })
      .catch((error) => {
        dispatch(invoiceBatchSingleStatusChangeError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(error);
      });
  };
};

export const invoiceBatchSingleAccept = (invoiceBatchId) => {
  return invoiceBatchSingleStatusChange(invoiceBatchId, 'accept');
};

export const invoiceBatchSingleComplete = (invoiceBatchId) => {
  return invoiceBatchSingleStatusChange(invoiceBatchId, 'complete');
};

export const invoiceBatchSingleCancel = (invoiceBatchId) => {
  return invoiceBatchSingleStatusChange(invoiceBatchId, 'cancel');
};

export const invoiceBatchSingleUseOriginalFiles = (invoiceBatchId) => {
  return invoiceBatchSingleStatusChange(invoiceBatchId, API_ROUTES.INVOICE_BATCH_USE_ORIGINAL_FILES_SUB_PATH.replace('/', ''));
};

export const invoiceBatchAddRequest = () => ({
  type: INVOICE_BATCH_ADD_REQUEST,
});

export const invoiceBatchAdded = (data) => ({
  type: INVOICE_BATCH_ADDED,
  data,
});

export const invoiceBatchAdd = (data) => {
  return (dispatch) => {
    dispatch(invoiceBatchAddRequest());
    return (
      requests
        .post(`${API_ROUTES.INVOICE_BATCH}`, data)
        //        .then(response => dispatch(invoiceBatchAdded(response)))
        .then((response) => {
          dispatch(invoiceBatchAdded(response));
          return response;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            return dispatch(userLogout());
          } else if (error.response && error.response.status === 403) {
            return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
          }
          throw new SubmissionError(parseApiErrors(error));
        })
    );
  };
};
// Invoice Batch Assign

export const invoiceBatchAssignRequest = () => ({
  type: INVOICE_BATCH_ASSIGN_REQUEST,
});

export const invoiceBatchAssignReceived = (data) => ({
  type: INVOICE_BATCH_ASSIGN_RECEIVED,
  data,
});

export const invoiceBatchAssignError = (error) => ({
  type: INVOICE_BATCH_ASSIGN_ERROR,
  error,
});

export const invoiceBatchAssign = (data) => {
  return (dispatch) => {
    dispatch(invoiceBatchAssignRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_BATCH_ASSIGN}`, data)
      .then((response) => {
        dispatch(invoiceBatchAssignReceived(response));
      })
      .catch((error) => {
        dispatch(invoiceBatchAssignError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(error);
      });
  };
};
