import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import Helmet from 'react-helmet';
import CcsdCodeForm from '../../components/forms/CcsdCode/CcsdCodeForm';
import Alert from 'react-bootstrap/Alert';
import DefaultCcsdCodeCostTable from '../../components/defaultCcsdCodeCost/DefaultCcsdCodeCostTable';
import { CCSD_CODE_TYPE_PROCEDURE_CODE } from '../../lib/helper/general';

class CcsdCodeSingle extends React.Component {
  render() {
    const { ccsdCode, deleteCcsdCode, isDeleting, healthInsurers, consultantTypes } = this.props;

    //    if ( !ccsdCode || !ccsdCode.ccsdCodeType || !ccsdCode.ccsdCodeType.code ) {
    //      return null;
    //    }
    return (
      <div>
        <Helmet>
          <title>{ccsdCode.reference} - A CcsdCode</title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="crud__meta">
                    <Link to={ROUTES.CCSD_CODES.ALL.replace('/:page', '')}>&larr; Back to CCSD Codes </Link>
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="crud__body-title">Edit a CCSD Code ({ccsdCode.reference})</h1>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <CcsdCodeForm ccsdCode={ccsdCode} />

                    {ccsdCode && ccsdCode.ccsdCodeType && ccsdCode.ccsdCodeType.code === CCSD_CODE_TYPE_PROCEDURE_CODE && (
                      <DefaultCcsdCodeCostTable healthInsurers={healthInsurers} consultantTypes={consultantTypes} ccsdCode={ccsdCode} />
                    )}

                    {isDeleting && (
                      <Alert variant={'danger'}>
                        Deleting a CCSD Code will delete that code and all it&apos;s links to, and related costs for, any Consultant that currently uses it.
                      </Alert>
                    )}
                    <button
                      onClick={() => {
                        deleteCcsdCode();
                      }}
                      className={`btn mr-3 ${isDeleting ? 'btn-primary' : 'btn-danger'}`}
                      type="button">
                      {isDeleting ? 'This is destructive and irreversible. Click again to confirm...' : 'Delete CCSD Code and All Related Data'}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CcsdCodeSingle;
