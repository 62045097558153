import {
  HEALTH_INSURER_LIST_REQUEST,
  HEALTH_INSURER_LIST_RECEIVED,
  HEALTH_INSURER_LIST_ERROR,
  HEALTH_INSURER_LIST_SET_PAGE,
  HEALTH_INSURER_LIST_UNLOAD,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    healthInsurers: [],
    isFetchingHealthInsurers: false,
    currentPage: 1,
    pageCount: null,
  },
  action,
) => {
  switch (action.type) {
    case HEALTH_INSURER_LIST_UNLOAD:
      return {
        healthInsurers: [],
        isFetchingHealthInsurers: false,
        currentPage: 1,
        pageCount: null,
      };

    case HEALTH_INSURER_LIST_REQUEST:
      return {
        ...state,
        isFetchingHealthInsurers: true,
      };

    case HEALTH_INSURER_LIST_RECEIVED:
      return {
        ...state,
        healthInsurers: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingHealthInsurers: false,
      };

    case HEALTH_INSURER_LIST_ERROR:
      return {
        ...state,
        isFetchingHealthInsurers: false,
      };
    case HEALTH_INSURER_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    default:
      return state;
  }
};

export default reducer;
