import {
  INVOICE_BATCH_FILE_UPLOAD_REQUEST,
  INVOICE_BATCH_FILE_UPLOAD_SUCCESS,
  INVOICE_BATCH_FILE_UPLOAD_ERROR,
  INVOICE_BATCH_FILE_DELETE_SUCCESS,
  INVOICE_BATCH_FILE_DELETE_REQUEST,
  INVOICE_BATCH_FILE_UPLOAD_CLEAR_REFERENCES,
} from '../constants';

const reducer = (
  state = {
    fileRequestInProgress: false,
    temporaryFiles: [],
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_FILE_DELETE_REQUEST:
    case INVOICE_BATCH_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        fileRequestInProgress: true,
      };
    case INVOICE_BATCH_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        fileRequestInProgress: false,
        temporaryFiles: state.temporaryFiles.concat(action.file),
      };
    case INVOICE_BATCH_FILE_UPLOAD_ERROR:
      return {
        ...state,
        fileRequestInProgress: false,
      };
    case INVOICE_BATCH_FILE_DELETE_SUCCESS:
      return {
        ...state,
        temporaryFiles: state.temporaryFiles.filter((file) => file.id !== action.id),
        fileRequestInProgress: false,
      };

    case INVOICE_BATCH_FILE_UPLOAD_CLEAR_REFERENCES:
      return {
        ...state,
        temporaryFiles: [],
        fileRequestInProgress: false,
      };
    default:
      return state;
  }
};

export default reducer;
