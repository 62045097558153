import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { addToast } from '../../lib/redux/actions/toast';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { isAdmin } from '../../lib/helper/authorisation';
import { chaserWorkScheduleUpdate } from '../../lib/redux/actions/chaserWorkSchedule';

const moment = extendMoment(Moment);

const mapDispatchToProps = {
  chaserWorkScheduleUpdate,
  addToast,
};

class EditableChaserWorkScheduleCell extends React.Component {
  constructor(props) {
    let hourValue =
      props.day === 'Wednesday'
        ? props.userSchedules && typeof props.userSchedules['requestHours'] !== 'undefined'
          ? props.userSchedules['requestHours']
          : 0
        : 0;
    super(props);
    this.today = moment().utc();
    this.state = {
      isEditing: false,
      requestedHours: hourValue,
    };
  }

  saveAndStopEditing(e) {
    const { cellDate, user, chaserWorkScheduleUpdate } = this.props;
    let values = {};
    values['day'] = cellDate;
    values['user_id'] = user.id;
    values[`requested_hours`] = this.state.requestedHours;
    chaserWorkScheduleUpdate(values);
    addToast('Schedule Updated Successfully', true);
    this.stopEditing(e);
  }

  startEditing() {
    const { user } = this.props;
    const { day } = this.props;
    if (day === 'Wednesday') {
      if (parseInt(window.localStorage.getItem('userId')) === parseInt(user.id) || isAdmin()) {
        this.setState(() => ({ isEditing: true }));
      }
    }
  }

  stopEditing(e) {
    e.stopPropagation();
    this.setState(() => ({ isEditing: false }));
  }

  handleInvoiceNumberChange(event) {
    this.setState(() => ({ requestedHours: event.target.value }));
  }

  renderContent() {
    const { isEditing, requestedHours } = this.state;
    const { day } = this.props;
    if (isEditing && day === 'Wednesday') {
      return (
        <div>
          <div className={'work_schedule_input_container'}>
            <input
              autoFocus
              type="number"
              placeholder="0"
              title="Requested Hours"
              value={requestedHours}
              onChange={this.handleInvoiceNumberChange.bind(this)}
              className="form-control w-25 mr-2"
            />
            <Button variant="primary" type="submit" title="Save Change" size="sm" className="mb-0" onClick={this.saveAndStopEditing.bind(this)}>
              <i className="fas fa-check" />
            </Button>
            <Button variant="danger" title="Cancel Change" size="sm" className="mb-0" onClick={this.stopEditing.bind(this)}>
              <i className="fas fa-times" />
            </Button>
          </div>
        </div>
      );
    } else {
      return <p>{requestedHours}</p>;
    }
  }

  render() {
    const { isEditing } = this.state;
    const { cellDate, user } = this.props;
    return (
      <td key={`${user.id}-td-${cellDate.unix()}`} className={`status ${isEditing ? '' : ''} `} onClick={this.startEditing.bind(this)}>
        {this.renderContent()}
      </td>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditableChaserWorkScheduleCell);
