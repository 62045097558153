import ContactRecordForm from '../../../components/forms/Invoice/ContactRecordForm';
import React from 'react';
import { contactRecordIsPatient } from '../../../lib/helper/invoice';
import type { ContactRecord, ContactRecordType } from '../../../apiTypes';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import { contactRecordTypeListFetch } from '../../../lib/redux/actions/contactRecordType';

interface Props {
  existingInvoice?: boolean;
  canRemove: boolean;
  onRemove?: (c: ContactRecord) => void;
  toggleFormUnderEdit: (formId: string) => void;
  contactRecordTypes?: ContactRecordType[];
  contact: ContactRecord;
  onSubmit: (c: ContactRecord) => void;
  startOpen?: boolean;
  isNew?: boolean;
  showPatientOption?: boolean;
  contactRecordTypeListFetch?: () => void;
  onDiscardChanges?: () => void;
}

const mapStateToProps = (state: any) => ({
  ...state.contactRecordTypes,
});

const mapDispatchToProps = {
  contactRecordTypeListFetch,
};

class ContactRecordDrawer extends React.Component<Props, { open: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.startOpen || false,
    };
  }
  componentDidMount() {
    this.props?.contactRecordTypeListFetch && this.props.contactRecordTypeListFetch();
  }
  render() {
    const {
      canRemove = false,
      contact,
      onSubmit,
      isNew = false,
      showPatientOption = true,
      contactRecordTypes = [],
      toggleFormUnderEdit,
      onRemove,
      existingInvoice = false,
    }: Props = this.props;

    const recordType = contactRecordTypes.find((o) => o?.code === contact?.contactRecordType?.code);
    const isPatient = contactRecordIsPatient(contact);
    const canClose = !isNew && !isPatient;
    return (
      <Card className={'mb-2 no-shadow card-tight'}>
        <Card.Header className={` ${canClose ? 'pointer' : ''}`} onClick={() => canClose && this.setState({ open: !this.state.open })} title={'Click to edit'}>
          <span className={'h6'}>{!isNew ? contact.name : 'New Contact'}</span>
          <span className={`ml-4 badge badge-pill ${isPatient ? 'badge-primary' : 'badge-secondary'}`}>{recordType?.name ?? ''}</span>
          <>
            <span className={'float-right mt-2 h6'}>
              Notifications{' '}
              {contact.notifyByEmail || contact.notifyByMobile ? (
                <i className={'fas fa-check-circle text-primary'} />
              ) : (
                <i className={'fas fa-times-circle text-danger'} />
              )}
            </span>
            {contact.email && (
              <span className={'ml-4 h6'}>
                <i className={`fas fa-envelope mr-2 ${contact.notifyByEmail ? 'text-primary' : 'text-danger'}`} />
                {contact.email}
              </span>
            )}

            {contact.mobileNumber && (
              <span className={'ml-4 h6'}>
                <i className={`fas fa-phone mr-2 ${contact.notifyByMobile ? 'text-primary' : 'text-danger'}`} />
                {contact.mobileNumber}
              </span>
            )}
          </>
        </Card.Header>
        {this.state.open && (
          <Card.Body>
            <div className={'col pl-0'}>
              <ContactRecordForm
                existingInvoice={existingInvoice}
                toggleFormUnderEdit={toggleFormUnderEdit}
                showPatientOption={showPatientOption}
                form={'ContactRecordForm' + contact.name + (contact.id ?? '-1')}
                key={contact.name}
                contact={contact}
                onClose={() => canClose && this.setState({ open: false })}
                isNew={isNew}
                onSubmit={(c: ContactRecord) => {
                  onSubmit(c);
                }}
              />
            </div>
            {canRemove && onRemove && (
              <button className={'btn btn-danger float-right btn-sm'} type={'button'} onClick={() => onRemove(contact)}>
                Remove Contact
              </button>
            )}
          </Card.Body>
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactRecordDrawer);
