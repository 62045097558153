import {
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_REQUEST,
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RECEIVED,
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_ERROR,
  INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RESET,
} from '../constants';

const reducer = (
  state = {
    isValidating: false,
    checkedInvoiceNumbers: [],
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_REQUEST:
      return {
        ...state,
        isValidating: true,
      };

    case INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RECEIVED:
      return {
        ...state,
        checkedInvoiceNumbers: action.invoiceNumbers,
        isValidating: false,
      };
    case INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_ERROR:
      return {
        ...state,
        checkedInvoiceNumbers: [],
        isValidating: false,
        error: action.error,
      };
    case INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RESET:
      return {
        isValidating: false,
        checkedInvoiceNumbers: [],
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
