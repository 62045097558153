import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderCheckbox, renderSelect } from '../../../lib/helper/form';
import { invoiceBatchStatusListFetch } from '../../../lib/redux/actions/invoiceBatchStatus';
import AssignToUserTypeahead from '../../fields/AssignToUserTypeahead';
import { invoiceBatchSingleUpdate, invoiceBatchSingleCancel } from '../../../lib/redux/actions/invoiceBatch';
import MASpinner from '../../common/MASpinner';
import { addToast } from '../../../lib/redux/actions/toast';
import { isOutsourcer, isSeniorStaff, isStaff } from '../../../lib/helper/authorisation';
import { Alert } from 'react-bootstrap';
import { isCompleted } from '../../../lib/helper/invoiceBatch';
import { Link } from 'react-router-dom';
import { invoiceBatchUrgentCategoryListFetch } from '../../../lib/redux/actions/invoiceBatchUrgentCategory';
import { invoiceBatchProblemCategoryListFetch } from '../../../lib/redux/actions/invoiceBatchProblemCategory';

const mapStateToProps = (state, props) => {
  const formFields = [
    'isUrgent',
    'isProblem',
    'isEmbassy',
    'isProcessingOutsourced',
    'assignedUser',
    'invoiceBatchUrgentCategory',
    'invoiceBatchProblemCategory',
  ];
  const cloneBatch = { ...props.invoiceBatch };
  Object.keys(cloneBatch)
    .filter((key) => !formFields.includes(key))
    .forEach((key) => delete cloneBatch[key]);

  if (cloneBatch.invoiceBatchUrgentCategory) {
    cloneBatch.invoiceBatchUrgentCategory = cloneBatch.invoiceBatchUrgentCategory['@id'];
  }
  if (cloneBatch.invoiceBatchProblemCategory) {
    cloneBatch.invoiceBatchProblemCategory = cloneBatch.invoiceBatchProblemCategory['@id'];
  }

  return {
    ...state.invoiceBatchStatusList,
    ...state.invoiceBatchUrgentCategoryList,
    ...state.invoiceBatchProblemCategoryList,
    initialValues: cloneBatch,
  };
};
const mapDispatchToProps = {
  invoiceBatchStatusListFetch,
  invoiceBatchUrgentCategoryListFetch,
  invoiceBatchProblemCategoryListFetch,
  invoiceBatchSingleUpdate,
  invoiceBatchSingleCancel,
  addToast,
};

class EditForm extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceBatchStatusListFetch, invoiceBatchUrgentCategoryListFetch, invoiceBatchProblemCategoryListFetch } = this.props;

    this.state = {
      selectedAssignedToUser: false,
      clearTypeaheads: false,
      isUrgentChecked: props?.invoiceBatch?.isUrgent,
      isProblemChecked: props?.invoiceBatch?.isProblem,
    };

    invoiceBatchStatusListFetch();
    invoiceBatchUrgentCategoryListFetch();
    invoiceBatchProblemCategoryListFetch();
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  componentDidMount() {
    this.setState(() => {
      return { selectedAssignedToUser: this.props.invoiceBatch && this.props.invoiceBatch.assignedUser ? this.props.invoiceBatch.assignedUser : false };
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoiceBatch.id !== this.props.invoiceBatch.id) {
      this.setState(() => {
        return { selectedAssignedToUser: false };
      });
    }
  }

  onSubmit(values) {
    const { invoiceBatchSingleUpdate, invoiceBatch, addToast } = this.props;
    if (this.state.selectedAssignedToUser !== undefined && this.state.selectedAssignedToUser !== null) {
      values['assignedUser'] = this.state.selectedAssignedToUser ? this.state.selectedAssignedToUser['@id'] : null;
    } else {
      values['assignedUser'] = null;
    }
    if (!values['isUrgent']) {
      values['invoiceBatchUrgentCategory'] = null;
    }
    if (values['invoiceBatchUrgentCategory'] === '') {
      values['invoiceBatchUrgentCategory'] = null;
    }
    if (!values['isProblem']) {
      values['invoiceBatchProblemCategory'] = null;
    }
    if (values['invoiceBatchProblemCategory'] === '') {
      values['invoiceBatchProblemCategory'] = null;
    }
    return invoiceBatchSingleUpdate(values, invoiceBatch.id).then(() => {
      addToast('The invoice was updated', true);
    });
  }

  renderFields() {
    const { invoiceBatch, hasCheckedAndPricedFiles, invoiceBatchUrgentCategories } = this.props;

    const urgentCategoryOptions = [];
    invoiceBatchUrgentCategories.forEach((invoiceBatchUrgentCategory) => {
      urgentCategoryOptions.push({ value: invoiceBatchUrgentCategory['@id'], text: invoiceBatchUrgentCategory.displayName });
    });

    if (!isSeniorStaff()) {
      return null;
    }
    if (isCompleted(invoiceBatch)) {
      return (
        <>
          <Field name="isUrgent" label="Urgent?" id="is-urgent" component={renderCheckbox} />
          <br />
          {this.state.isUrgentChecked && isStaff() ? (
            <Field
              name="invoiceBatchUrgentCategory"
              label="Urgent Category"
              type="select"
              options={urgentCategoryOptions}
              emptyLabel="Please select"
              component={renderSelect}
            />
          ) : (
            <></>
          )}
          <br />
        </>
      );
    }
    return (
      <>
        {hasCheckedAndPricedFiles || invoiceBatch.useOriginalFiles || (invoiceBatch.noFilesSupplied && !isNaN(invoiceBatch.numberOfInvoicesToProcess)) ? (
          <>
            <AssignToUserTypeahead
              selectedUsers={this.state.selectedAssignedToUser ? [this.state.selectedAssignedToUser] : []}
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              name="assignedUser"
              multiple={false}
              defaultUser={invoiceBatch.assignedUser || false}
              onChange={(selected) => {
                this.setState(() => {
                  return { selectedAssignedToUser: selected[0] };
                });
              }}
            />
            <Field name="isProcessingOutsourced" label="Outsourced?" id="is-processing-outsourced" component={renderCheckbox} />
            <hr />
          </>
        ) : (
          <Alert variant="info">
            You must upload checked and priced files (or confirm the number of invoices if no files are supplied) before assigning to a user to process
          </Alert>
        )}

        <Field
          name="isUrgent"
          label="Urgent?"
          id="is-urgent"
          component={renderCheckbox}
          onChange={() => this.setState({ isUrgentChecked: !this.state.isUrgentChecked })}
        />
        <br />

        {this.state.isUrgentChecked && isStaff() ? (
          <Field
            name="invoiceBatchUrgentCategory"
            label="Urgent Category"
            type="select"
            options={urgentCategoryOptions}
            emptyLabel="Please select"
            component={renderSelect}
          />
        ) : (
          <></>
        )}

        <Field name="isEmbassy" label="Embassy Invoices?" id="is-embassy" component={renderCheckbox} />
      </>
    );
  }

  render() {
    const {
      handleSubmit,
      error,
      invoiceBatchStatuses,
      isFetchingInvoiceBatchStatuses,
      isFetchingInvoiceBatchUrgentCategories,
      pristine,
      submitting,
      isUpdatingInvoiceBatch,
      invoiceBatch,
      invoiceBatchProblemCategories,
    } = this.props;
    if (!invoiceBatchStatuses || isFetchingInvoiceBatchStatuses) {
      return <MASpinner />;
    }
    if (isFetchingInvoiceBatchUrgentCategories) {
      return <MASpinner />;
    }

    const problemCategoryOptions = [];
    invoiceBatchProblemCategories.forEach((invoiceBatchProblemCategory) => {
      problemCategoryOptions.push({ value: invoiceBatchProblemCategory['@id'], text: invoiceBatchProblemCategory.displayName });
    });

    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-save" />
        </div>
        <h2 className="invoice-batch-single__section-header">Edit Invoice Batch</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {this.renderFields()}
          <br />
          {!isCompleted(invoiceBatch) && (
            <Field
              name="isProblem"
              label="Problem?"
              id="is-problem"
              component={renderCheckbox}
              onChange={() => this.setState({ isProblemChecked: !this.state.isProblemChecked })}
            />
          )}

          {this.state.isProblemChecked && (isOutsourcer() || isStaff()) ? (
            <>
              <br />
              <Field
                name="invoiceBatchProblemCategory"
                label="Problem Category"
                type="select"
                options={problemCategoryOptions}
                emptyLabel="Please select"
                component={renderSelect}
              />
            </>
          ) : (
            <></>
          )}
          {(isOutsourcer() || isStaff()) && (
            <Link
              className={`btn btn-block btn-danger mt-2`}
              to={{
                pathname: `/problem-data/${invoiceBatch.id}`,
              }}>
              Add Problem Medicode Invoices
            </Link>
          )}
          {submitting && isUpdatingInvoiceBatch ? (
            <MASpinner />
          ) : (
            <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block mt-2">
              Update
            </button>
          )}
        </Form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'EditForm',
    enableReinitialize: true,
    initialValues: {
      isProcessingOutsourced: true,
    },
  })(EditForm),
);
