import React, { Component } from 'react';
import { problemDataListFetch, problemDataListUnload } from '../../../lib/redux/actions/problemData';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { niceDateFormatter, userNameFormatter } from '../../../lib/helper/formatting';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  ...state.problemDataList,
});

const mapDispatchToProps = {
  problemDataListFetch,
  problemDataListUnload,
};

class ProblemDataTable extends Component {
  componentDidMount() {
    this.props.problemDataListFetch(1, { 'invoiceBatch.id': this.props.invoiceBatch.id });
  }

  componentWillUnmount() {
    this.props.problemDataListUnload();
  }

  render() {
    const { problemData } = this.props;
    return (
      <Table hover bordered className="mediaccounts-batch-invoice-ccsd-table mb-0 d-none d-sm-table bg-light">
        <thead>
          <tr>
            <th className="stuck-row-1">Problem Date</th>
            <th className="stuck-row-1">Patient Name</th>
            <th className="stuck-row-1">Insurer</th>
            <th className="stuck-row-1">Type</th>
            <th className="stuck-row-1">Invoice Numbers</th>
            <th className="stuck-row-1">Status</th>
            <th className="stuck-row-1">Created By</th>
            <th className="stuck-row-1">Actions</th>
          </tr>
        </thead>
        <tbody>
          {problemData?.map((data) => {
            return (
              <tr key={data.id}>
                <td>{niceDateFormatter(data?.problemDate)}</td>
                <td>{userNameFormatter({ firstName: data.patientFirstName ?? '', lastName: data.patientLastName ?? '' })}</td>
                <td>{data?.insurer?.name}</td>
                <td>{data?.problemType?.name}</td>
                <td>{data?.invoiceNumbers}</td>
                <td>{data?.problemStatus?.name}</td>
                <td>{data?.createdBy?.firstName + ' ' + data?.createdBy?.lastName || ''}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/problem-data/edit/${data.id}`,
                    }}>
                    View
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemDataTable);
