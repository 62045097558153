import Card from 'react-bootstrap/Card';
import AssignToUserTypeahead from '../../fields/AssignToUserTypeahead';
import { invoiceAssign } from '../../../lib/redux/actions/invoices';
import { reduxForm } from 'redux-form';
import MASpinner from '../../common/MASpinner';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { userNameFormatter } from '../../../lib/helper/formatting';
import { chaserWorkScheduleForUserFetchList, chaserWorkScheduleUnload } from '../../../lib/redux/actions/chaserWorkSchedule';
import moment from 'moment';
import { getStats } from '../../../lib/redux/actions/stats';

const mapStateToProps = (state) => ({
  ...state.stats,
  ...state.chaserWorkSchedule,
});

const mapDispatchToProps = {
  invoiceAssign,
  chaserWorkScheduleForUserFetchList,
  chaserWorkScheduleUnload,
  getStats,
};

class BulkAssignInvoicesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectedAssignToUser: false,
      clearTypeaheads: false,
      usersCurrentWorkSchedule: false,
      hoursPerWeek: 0,
    };
    props.getStats();
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true, usersCurrentWorkSchedule: false };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false, usersCurrentWorkSchedule: false };
      });
    }
  }

  onSubmit(values) {
    const { invoiceAssign, invoiceIdsToAssign = [], loadInvoices, reset, getStats } = this.props;

    values['user'] = this.state.selectedAssignToUser !== false ? this.state.selectedAssignToUser['id'] : null;
    values['invoices'] = invoiceIdsToAssign?.length > 0 ? invoiceIdsToAssign : null;

    if (values['user'] === null || values['invoices'] === null) {
      return;
    }

    return invoiceAssign(values).then(() => {
      reset();
      getStats();
      this.setState(() => ({ selectedAssignToUser: false }));
      this.clearTypeaheads();
      loadInvoices('Invoices Assigned Successfully');
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props?.workSchedules?.length > 0 && prevProps?.workSchedules && prevProps?.workSchedules[0]?.user?.id !== this.props.workSchedules[0]?.user?.id) {
      this.setState(() => {
        return { hoursPerWeek: this.props.workSchedules[0]?.requestHours ?? 0 };
      });
    }
  }

  onInputChange(event) {
    this.setState({
      hoursPerWeek: event.target.value,
    });
  }

  getInvoiceCountsForSelectedUser(selectedUser) {
    const { chaserWorkScheduleUnload, chaserWorkScheduleForUserFetchList } = this.props;
    chaserWorkScheduleUnload();

    this.setState(() => {
      return { selectedAssignToUser: selectedUser };
    });

    if (selectedUser) {
      chaserWorkScheduleForUserFetchList(moment().utc().format('DD-MM-YYYY'), selectedUser['id']);
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      isUpdatingMultipleInvoices,
      invoiceIdsToAssign = [],
      numberOfInvoices = 0,
      isFetchingWorkSchedule,
      autoBulkSelectAction = () => {},
      stats,
      showNextDayCount = true,
    } = this.props;

    const itemsToAssign = this.state.hoursPerWeek * stats.ccInvPerHr;

    const needsMoreData = numberOfInvoices === 0 || invoiceIdsToAssign.length < 1 || !this.state?.selectedAssignToUser?.id;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="bulk-assign-form">
        <Card.Body>
          <Row>
            <Col xs={6} lg={3} className={'d-flex flex-column justify-content-end'}>
              <AssignToUserTypeahead
                rolesToShow='["ROLE_ADMIN","ROLE_SENIOR_STAFF","ROLE_STAFF","ROLE_CHASER_OUTSOURCER"]'
                inline={true}
                selectedUsers={this.state.selectedAssignToUser ? [this.state.selectedAssignToUser] : []}
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                name="user"
                label="Assign To"
                labelKey={(option) => userNameFormatter(option)}
                multiple={false}
                defaultUser={false}
                onChange={(selected) => {
                  if (selected.length > 0) {
                    this.setState({
                      hoursPerWeek: 0,
                    });
                    autoBulkSelectAction(0);
                  }
                  this.getInvoiceCountsForSelectedUser(selected[0]);
                }}
              />
            </Col>
            <Col xs={6} lg={2} className={'d-flex flex-column justify-content-end'}>
              {isFetchingWorkSchedule ? (
                <MASpinner />
              ) : (
                <div className={'mb-3'}>
                  <label form="hoursPerWeek">Hours This Week</label>
                  <input
                    name="hoursPerWeek"
                    title="Hours This Week"
                    className="form-control"
                    type="text"
                    onChange={this.onInputChange.bind(this)}
                    value={this.state.hoursPerWeek}
                  />
                </div>
              )}
            </Col>
            <Col xs={6} lg={2} className={'d-flex flex-column justify-content-end'}>
              {isFetchingWorkSchedule ? (
                <MASpinner />
              ) : (
                <div className={'mb-3'}>
                  <small className={'float-right text-secondary'} title={'Currently set to assign ' + stats.ccInvPerHr + ' invoices per hour'}>
                    ({stats.ccInvPerHr}/hour)
                  </small>
                  <label form="itemsToAssign">Items To Assign</label>
                  <input name="itemsToAssign" title="Items To Assign" className="form-control disabled" disabled type="text" value={itemsToAssign} />
                </div>
              )}
            </Col>
            <Col xs={6} lg={2} className={'d-flex flex-column justify-content-end'}>
              <div className={'mb-3'}>
                {numberOfInvoices ? null : (
                  <button type="button" onClick={() => autoBulkSelectAction(itemsToAssign)} className="btn btn-outline-secondary">
                    Auto Select
                  </button>
                )}
                {numberOfInvoices ? (
                  <button type="button" onClick={() => autoBulkSelectAction(0)} className="btn btn-outline-danger ml-3">
                    Clear Selection
                  </button>
                ) : null}
              </div>
            </Col>
            <Col xs={6} lg={3} className={'d-flex flex-column justify-content-end'}>
              {submitting && isUpdatingMultipleInvoices ? (
                <MASpinner />
              ) : (
                <div className={'mb-3'}>
                  <button
                    type="submit"
                    disabled={needsMoreData || pristine || submitting}
                    className={`btn ${needsMoreData ? 'btn-secondary' : 'btn-primary'} btn-block `}>
                    Assign{' '}
                    <span className="badge badge-light badge-primary badge-large-text" title="Invoices to process">
                      {numberOfInvoices}
                      {showNextDayCount && this.state?.selectedAssignToUser?.nextWorkDayWorkSchedule
                        ? '/' + this.state.selectedAssignToUser.nextWorkDayWorkSchedule
                        : ''}
                    </span>{' '}
                    invoices
                  </button>
                </div>
              )}
            </Col>
          </Row>
          <p>
            <small>Currently set to assign {stats.ccInvPerHr} invoices per / hour</small>
          </p>
        </Card.Body>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'BulkAssignForm',
})(connect(mapStateToProps, mapDispatchToProps)(BulkAssignInvoicesForm));
