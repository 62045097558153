import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { parseFilterObjectToQueryString } from './apiUtils';
import { getSubusersConsultantToManage, isSubUser } from './authorisation';
import { SubmissionError } from 'redux-form/lib/SubmissionError';

const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = window.API_URL;
const responseBody = (response) => response.body;

let token = null;

const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) {
      request.set('Authorization', `Bearer ${token}`);
    }
  };
};

const manageConsultantPlugin = (isSubUser) => {
  return (request) => {
    if (isSubUser) {
      const consultantBeingManaged = getSubusersConsultantToManage();
      if (consultantBeingManaged && consultantBeingManaged.iri) {
        request.query('consultant=' + consultantBeingManaged.iri);
      }
    }
  };
};

function downloadBlob(blob, fileName, newTab = false) {
  // IE11
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);

    return;
  }

  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  if (fileName && fileName !== '') {
    link.download = fileName;
  }
  if (newTab) {
    link.target = '_blank';
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    URL.revokeObjectURL(blobUrl);
  }, 100);

  // Append link to the body
  //  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  //  link.dispatchEvent(
  //    new MouseEvent('click', {
  //      bubbles: true,
  //      cancelable: true,
  //      view: window,
  //    }),
  //  );

  // Remove link from body
  //  document.body.removeChild(link);
}

export const requests = {
  get: (url, queryStringObject = {}, secured = true) => {
    return superagent
      .get(`${API_ROOT}${url}`)
      .use(manageConsultantPlugin(isSubUser(true)))
      .query(parseFilterObjectToQueryString(queryStringObject))
      .use(tokenPlugin(secured))
      .then(responseBody);
  },
  post: (url, body = null, secured = true) => {
    return superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin(secured)).then(responseBody);
  },
  patch: (url, body = null, secured = true) => {
    return superagent
      .patch(`${API_ROOT}${url}`, body)
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept', 'application/ld+json')
      .use(tokenPlugin(secured))
      .then(responseBody);
  },
  upload: (url, file, secured = true) => {
    return superagent.post(`${API_ROOT}${url}`).attach('file', file).use(tokenPlugin(secured)).then(responseBody);
  },
  delete: (url, secured = true) => {
    return superagent.del(`${API_ROOT}${url}`).use(tokenPlugin(secured)).then(responseBody);
  },
  getFile: (url, queryStringObject = {}, fileName, secured = true, newTab = false) => {
    return superagent
      .get(`${API_ROOT}${url}`)

      .use(manageConsultantPlugin(isSubUser(true)))
      .query(parseFilterObjectToQueryString(queryStringObject))
      .responseType('blob')
      .use(tokenPlugin(secured))
      .end((error, response) => {
        if (error) {
          return new SubmissionError({ _error: 'There was a problem downloading the file.' });
        } else {
          downloadBlob(new Blob([response.body], { type: response.type }), fileName, newTab);
        }
      });
  },
  setToken: (newJwtToken) => (token = newJwtToken),
};
//XDEBUG_SESSION=INSOMNIA
