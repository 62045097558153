import {
  USER_SINGLE_REQUEST,
  USER_SINGLE_RECEIVED,
  USER_SINGLE_ERROR,
  USER_SINGLE_UPDATE_REQUEST,
  USER_SINGLE_UPDATED,
  USER_SINGLE_CREATE_SUCCESS,
  USER_SINGLE_CREATE_REQUEST,
  RESET_PASSWORD_LINK_RECEIVED,
  RESET_PASSWORD_LINK_UNLOAD,
  USER_FORGOT_PASSWORD_ERROR,
} from '../constants';

const reducer = (
  state = {
    user: null,
    resetPasswordLink: null,
    isFetchingUser: false,
    isUpdatingUser: false,
    isCreatingUser: false,
    showPasswordResetLink: false,
  },
  action,
) => {
  switch (action.type) {
    case USER_SINGLE_CREATE_REQUEST:
      return {
        ...state,
        isCreatingUser: true,
      };

    case USER_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
      };

    case USER_SINGLE_RECEIVED:
      return {
        ...state,
        user: action.data,
        isFetchingUser: false,
      };

    case USER_SINGLE_ERROR:
      return {
        ...state,
        isFetchingUser: false,
      };
    case RESET_PASSWORD_LINK_RECEIVED:
      return {
        ...state,
        resetPasswordLink: action.data,
      };
    case RESET_PASSWORD_LINK_UNLOAD:
      return {
        ...state,
        resetPasswordLink: null,
      };

    case USER_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingUser: true,
      };
      return state;
    case USER_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        showPasswordResetLink: true,
      };
    case USER_SINGLE_CREATE_SUCCESS:
    case USER_SINGLE_UPDATED:
      return {
        ...state,
        user: action.data,
        isUpdatingUser: false,
        isCreatingUser: false,
      };
    default:
      return state;
  }
};

export default reducer;
