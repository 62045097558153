export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_2FA_SUCCESS = 'USER_LOGIN_2FA_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

//export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
//export const USER_CONFIRMATION_SUCCESS = 'USER_CONFIRMATION_SUCCESS';
//export const USER_REGISTER_COMPLETE = 'USER_REGISTER_COMPLETE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_RECEIVED = 'USER_PROFILE_RECEIVED';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const USER_AGREED_TERMS = 'USER_AGREED_TERMS';
export const USER_SET_ID = 'USER_SET_ID';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_ERROR = 'USER_FORGOT_PASSWORD_ERROR';

export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_RECEIVED = 'USERS_LIST_RECEIVED';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';
export const USERS_LIST_SET_PAGE = 'USERS_LIST_SET_PAGE';
export const USERS_LIST_SET_FILTERS = 'USERS_LIST_SET_FILTERS';
export const USERS_LIST_CLEAR_FILTERS = 'USERS_LIST_CLEAR_FILTERS';
export const USERS_LIST_UNLOAD = 'USERS_LIST_UNLOAD';

export const USER_SINGLE_REQUEST = 'USER_SINGLE_REQUEST';
export const USER_SINGLE_RECEIVED = 'USER_SINGLE_RECEIVED';
export const USER_SINGLE_ERROR = 'USER_SINGLE_ERROR';
export const USER_SINGLE_UPDATE_REQUEST = 'USER_SINGLE_UPDATE_REQUEST';
export const USER_SINGLE_UPDATED = 'USER_SINGLE_UPDATED';
export const USER_SINGLE_CREATE_SUCCESS = 'USER_SINGLE_CREATE_SUCCESS';
export const USER_SINGLE_CREATE_REQUEST = 'USER_SINGLE_CREATE_REQUEST';
export const RESET_PASSWORD_LINK_RECEIVED = 'RESET_PASSWORD_LINK_RECEIVED';
export const RESET_PASSWORD_LINK_UNLOAD = 'RESET_PASSWORD_LINK_UNLOAD';

export const INVOICE_BATCH_SINGLE_REQUEST = 'INVOICE_BATCH_SINGLE_REQUEST';
export const INVOICE_BATCH_SINGLE_RECEIVED = 'INVOICE_BATCH_SINGLE_RECEIVED';
export const INVOICE_BATCH_SINGLE_ERROR = 'INVOICE_BATCH_SINGLE_ERROR';
export const INVOICE_BATCH_SINGLE_UPDATE_REQUEST = 'INVOICE_BATCH_SINGLE_UPDATE_REQUEST';
export const INVOICE_BATCH_SINGLE_UPDATED = 'INVOICE_BATCH_SINGLE_UPDATED';
export const INVOICE_BATCH_SINGLE_UNLOAD = 'INVOICE_BATCH_SINGLE_UNLOAD';

export const INVOICE_BATCH_ADD_REQUEST = 'INVOICE_BATCH_ADD_REQUEST';
export const INVOICE_BATCH_ADDED = 'INVOICE_BATCH_ADDED';

export const INVOICE_DATE_ADD_REQUEST = 'INVOICE_DATE_ADD_REQUEST';
export const INVOICE_DATE_ADDED = 'INVOICE_DATE_ADDED';
export const INVOICE_DATE_DELETE_REQUEST = 'INVOICE_DATE_DELETE_REQUEST';
export const INVOICE_DATE_DELETED = 'INVOICE_DATE_DELETED';
export const INVOICE_DATE_UPDATE_REQUEST = 'INVOICE_DATE_UPDATE_REQUEST';
export const INVOICE_DATE_UPDATED = 'INVOICE_DATE_UPDATED';

export const INVOICE_FILE_UPLOAD_SUCCESS = 'INVOICE_FILE_UPLOAD_SUCCESS';
export const INVOICE_FILE_UPLOAD_REQUEST = 'INVOICE_FILE_UPLOAD_REQUEST';
export const INVOICE_FILE_UPLOAD_ERROR = 'INVOICE_FILE_UPLOAD_ERROR';
export const INVOICE_FILE_DELETE_SUCCESS = 'INVOICE_FILE_DELETE_SUCCESS';
export const INVOICE_FILE_DELETE_REQUEST = 'INVOICE_FILE_DELETE_REQUEST';
export const INVOICE_FILE_UPLOAD_CLEAR_REFERENCES = 'INVOICE_FILE_UPLOAD_CLEAR_REFERENCES';

export const INVOICE_BATCH_AUDIT_LOG_RECEIVED = 'INVOICE_BATCH_AUDIT_LOG_RECEIVED';
export const INVOICE_BATCH_AUDIT_LOG_REQUEST = 'INVOICE_BATCH_AUDIT_LOG_REQUEST';
export const INVOICE_BATCH_AUDIT_LOG_UNLOAD = 'INVOICE_BATCH_AUDIT_LOG_UNLOAD';

export const INVOICE_BATCH_ASSIGN_REQUEST = 'INVOICE_BATCH_ASSIGN_REQUEST';
export const INVOICE_BATCH_ASSIGN_RECEIVED = 'INVOICE_BATCH_ASSIGN_RECEIVED';
export const INVOICE_BATCH_ASSIGN_ERROR = 'INVOICE_BATCH_ASSIGN_ERROR';

export const INVOICE_BATCH_SINGLE_STATUS_CHANGE_REQUEST = 'INVOICE_BATCH_SINGLE_STATUS_CHANGE_REQUEST';
export const INVOICE_BATCH_SINGLE_STATUS_CHANGED = 'INVOICE_BATCH_SINGLE_STATUS_CHANGED';
export const INVOICE_BATCH_SINGLE_STATUS_CHANGE_ERROR = 'INVOICE_BATCH_SINGLE_STATUS_CHANGE_ERROR';

export const INVOICE_BATCH_SINGLE_SPLIT_REQUEST = 'INVOICE_BATCH_SINGLE_SPLIT_REQUEST';
export const INVOICE_BATCH_SINGLE_SPLIT_RECEIVED = 'INVOICE_BATCH_SINGLE_SPLIT_RECEIVED';
export const INVOICE_BATCH_SINGLE_SPLIT_ERROR = 'INVOICE_BATCH_SINGLE_SPLIT_ERROR';

export const INVOICE_BATCH_FILE_UPLOAD_SUCCESS = 'INVOICE_BATCH_FILE_UPLOAD_SUCCESS';
export const INVOICE_BATCH_FILE_UPLOAD_REQUEST = 'INVOICE_BATCH_FILE_UPLOAD_REQUEST';
export const INVOICE_BATCH_FILE_UPLOAD_ERROR = 'INVOICE_BATCH_FILE_UPLOAD_ERROR';
export const INVOICE_BATCH_FILE_DELETE_SUCCESS = 'INVOICE_BATCH_FILE_DELETE_SUCCESS';
export const INVOICE_BATCH_FILE_DELETE_REQUEST = 'INVOICE_BATCH_FILE_DELETE_REQUEST';
export const INVOICE_BATCH_FILE_UPLOAD_CLEAR_REFERENCES = 'INVOICE_BATCH_FILE_UPLOAD_CLEAR_REFERENCES';

export const INVOICE_BATCH_LIST_REQUEST = 'INVOICE_BATCH_LIST_REQUEST';
export const INVOICE_BATCH_LIST_RECEIVED = 'INVOICE_BATCH_LIST_RECEIVED';
export const INVOICE_BATCH_LIST_ERROR = 'INVOICE_BATCH_LIST_ERROR';
export const INVOICE_BATCH_LIST_SET_PAGE = 'INVOICE_BATCH_LIST_SET_PAGE';
export const INVOICE_BATCH_LIST_SET_FILTERS = 'INVOICE_BATCH_LIST_SET_FILTERS';
export const INVOICE_BATCH_LIST_CLEAR_FILTERS = 'INVOICE_BATCH_LIST_CLEAR_FILTERS';
export const INVOICE_BATCH_LIST_UNLOAD = 'INVOICE_BATCH_LIST_UNLOAD';

export const INVOICE_BATCH_BILLING_CHECKLIST_REQUEST = 'INVOICE_BATCH_BILLING_CHECKLIST_REQUEST';
export const INVOICE_BATCH_BILLING_CHECKLIST_RECEIVED = 'INVOICE_BATCH_BILLING_CHECKLIST_RECEIVED';
export const INVOICE_BATCH_BILLING_CHECKLIST_ERROR = 'INVOICE_BATCH_BILLING_CHECKLIST_ERROR';
export const INVOICE_BATCH_BILLING_CHECKLIST_UNLOAD = 'INVOICE_BATCH_BILLING_CHECKLIST_UNLOAD';
export const INVOICE_BATCH_BILLING_CHECKLIST_SET_DATE = 'INVOICE_BATCH_BILLING_CHECKLIST_SET_DATE';

export const INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_REQUEST = 'INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_REQUEST';
export const INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_RECEIVED = 'INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_RECEIVED';
export const INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_ERROR = 'INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_ERROR';
export const INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_UNLOAD = 'INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_UNLOAD';
export const INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_SET_DATE = 'INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_SET_DATE';

export const WORK_SCHEDULE_WEEKLY_EVENTS_REQUEST = 'WORK_SCHEDULE_WEEKLY_EVENTS_REQUEST';
export const WORK_SCHEDULE_WEEKLY_EVENTS_RECEIVED = 'WORK_SCHEDULE_WEEKLY_EVENTS_RECEIVED';
export const WORK_SCHEDULE_WEEKLY_EVENTS_ERROR = 'WORK_SCHEDULE_WEEKLY_EVENTS_ERROR';
export const WORK_SCHEDULE_WEEKLY_EVENTS_UNLOAD = 'WORK_SCHEDULE_WEEKLY_EVENTS_UNLOAD';
export const WORK_SCHEDULE_WEEKLY_EVENTS_SET_DATE = 'WORK_SCHEDULE_WEEKLY_EVENTS_SET_DATE';

export const INVOICE_BATCH_STATUS_LIST_REQUEST = 'INVOICE_BATCH_STATUS_LIST_REQUEST';
export const INVOICE_BATCH_STATUS_LIST_RECEIVED = 'INVOICE_BATCH_STATUS_LIST_RECEIVED';
export const INVOICE_BATCH_STATUS_LIST_ERROR = 'INVOICE_BATCH_STATUS_LIST_ERROR';

export const INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_REQUEST = 'INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_REQUEST';
export const INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RECEIVED = 'INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RECEIVED';
export const INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_ERROR = 'INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_ERROR';
export const INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RESET = 'INVOICE_BATCH_VALIDATE_INVOICE_NUMBERS_RESET';

export const CONSULTANT_SINGLE_REQUEST = 'CONSULTANT_SINGLE_REQUEST';
export const CONSULTANT_SINGLE_RECEIVED = 'CONSULTANT_SINGLE_RECEIVED';
export const CONSULTANT_SINGLE_ERROR = 'CONSULTANT_SINGLE_ERROR';
export const CONSULTANT_SINGLE_UPDATE_REQUEST = 'CONSULTANT_SINGLE_UPDATE_REQUEST';
export const CONSULTANT_SINGLE_UPDATED = 'CONSULTANT_SINGLE_UPDATED';
export const CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_REQUEST = 'CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_REQUEST';
export const CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_SUCCESS = 'CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_SUCCESS';
export const CONSULTANT_SINGLE_CREATE_REQUEST = 'CONSULTANT_SINGLE_CREATE_REQUEST';
export const CONSULTANT_SINGLE_CREATE_SUCCESS = 'CONSULTANT_SINGLE_CREATE_SUCCESS';
export const CONSULTANT_SINGLE_UNLOAD = 'CONSULTANT_SINGLE_UNLOAD';
export const CONSULTANT_SINGLE_DELETE_REQUEST = 'export const CONSULTANT_SINGLE_DELETE_REQUEST';
export const CONSULTANT_SINGLE_DELETE_SUCCESS = 'export const CONSULTANT_SINGLE_DELETE_SUCCESS';
export const CONSULTANT_REGISTRATION_FORM_SUCCESS = 'CONSULTANT_REGISTRATION_FORM_SUCCESS';
export const CONSULTANT_REGISTRATION_FORM_REQUEST = 'CONSULTANT_REGISTRATION_FORM_REQUEST';

export const CONSULTANT_SUB_ACCOUNT_INVITE_REQUEST = 'CONSULTANT_SINGLE_SUB_ACCOUNT_REQUEST';
export const CONSULTANT_SUB_ACCOUNT_INVITE_INVITED = 'CONSULTANT_SINGLE_SUB_ACCOUNT_INVITE';
export const CONSULTANT_SUB_ACCOUNT_INVITE_UPDATED = 'CONSULTANT_SUB_ACCOUNT_INVITE_UPDATED';
export const CONSULTANT_SUB_ACCOUNT_INVITE_REMOVED = 'CONSULTANT_SUB_ACCOUNT_INVITE_REMOVED';
export const CONSULTANT_SUB_ACCOUNT_INVITE_ERROR = 'CONSULTANT_SINGLE_SUB_ACCOUNT_ERROR';
export const CONSULTANT_SUB_ACCOUNT_CREATE_REQUEST = 'CONSULTANT_SUB_ACCOUNT_CREATE_REQUEST';
export const CONSULTANT_SUB_ACCOUNT_CREATED = 'CONSULTANT_SUB_ACCOUNT_CREATED';

export const CONSULTANTS_LIST_REQUEST = 'CONSULTANTS_LIST_REQUEST';
export const CONSULTANTS_LIST_RECEIVED = 'CONSULTANTS_LIST_RECEIVED';
export const CONSULTANTS_LIST_ERROR = 'CONSULTANTS_LIST_ERROR';
export const CONSULTANTS_LIST_SET_PAGE = 'CONSULTANTS_LIST_SET_PAGE';
export const CONSULTANTS_LIST_SET_FILTERS = 'CONSULTANTS_LIST_SET_FILTERS';
export const CONSULTANTS_LIST_CLEAR_FILTERS = 'CONSULTANTS_LIST_CLEAR_FILTERS';
export const CONSULTANTS_LIST_UNLOAD = 'CONSULTANTS_LIST_UNLOAD';

export const CONSULTANTS_TYPEAHEAD_LIST_REQUEST = 'CONSULTANTS_TYPEAHEAD_LIST_REQUEST';
export const CONSULTANTS_TYPEAHEAD_LIST_RECEIVED = 'CONSULTANTS_TYPEAHEAD_LIST_RECEIVED';
export const CONSULTANTS_TYPEAHEAD_LIST_ERROR = 'CONSULTANTS_TYPEAHEAD_LIST_ERROR';

export const CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_REQUEST = 'CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_REQUEST';
export const CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_RECEIVED = 'CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_RECEIVED';
export const CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_ERROR = 'CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_ERROR';

export const CMS_CONTENT_SINGLE_REQUEST = 'CMS_CONTENT_SINGLE_REQUEST';
export const CMS_CONTENT_SINGLE_RECEIVED = 'CMS_CONTENT_SINGLE_RECEIVED';
export const CMS_CONTENT_SINGLE_UPDATE_REQUEST = 'CMS_CONTENT_SINGLE_UPDATE_REQUEST';
export const CMS_CONTENT_SINGLE_UPDATE_SUCCESS = 'CMS_CONTENT_SINGLE_UPDATE_SUCCESS';
export const CMS_CONTENT_CREATE_REQUEST = 'CMS_CONTENT_CREATE_REQUEST';
export const CMS_CONTENT_CREATE_SUCCESS = 'CMS_CONTENT_CREATE_SUCCESS';
export const CMS_CONTENT_SINGLE_ERROR = 'CMS_CONTENT_SINGLE_ERROR';
export const CMS_CONTENT_SINGLE_UNLOAD = 'CMS_CONTENT_SINGLE_UNLOAD';

export const INVOICE_BATCH_NOTE_ADD_REQUEST = 'INVOICE_BATCH_NOTE_ADD_REQUEST';
export const INVOICE_BATCH_NOTE_ADDED = 'INVOICE_BATCH_NOTE_ADDED';
export const INVOICE_BATCH_NOTE_DELETE_REQUEST = 'INVOICE_BATCH_NOTE_DELETE_REQUEST';
export const INVOICE_BATCH_NOTE_DELETED = 'INVOICE_BATCH_NOTE_DELETED';

export const INVOICE_BATCH_NOTES_REQUEST = 'INVOICE_BATCH_NOTES_REQUEST';
export const INVOICE_BATCH_NOTES_RECEIVED = 'INVOICE_BATCH_NOTES_RECEIVED';
export const INVOICE_BATCH_NOTES_ERROR = 'INVOICE_BATCH_NOTES_ERROR';

export const INVOICE_BATCH_URGENT_CATEGORY_LIST_REQUEST = 'INVOICE_BATCH_URGENT_CATEGORY_LIST_REQUEST';
export const INVOICE_BATCH_URGENT_CATEGORY_LIST_RECEIVED = 'INVOICE_BATCH_URGENT_CATEGORY_LIST_RECEIVED';
export const INVOICE_BATCH_URGENT_CATEGORY_LIST_ERROR = 'INVOICE_BATCH_URGENT_CATEGORY_LIST_ERROR';

export const INVOICE_BATCH_PROBLEM_CATEGORY_LIST_REQUEST = 'INVOICE_BATCH_PROBLEM_CATEGORY_LIST_REQUEST';
export const INVOICE_BATCH_PROBLEM_CATEGORY_LIST_RECEIVED = 'INVOICE_BATCH_PROBLEM_CATEGORY_LIST_RECEIVED';
export const INVOICE_BATCH_PROBLEM_CATEGORY_LIST_ERROR = 'INVOICE_BATCH_PROBLEM_CATEGORY_LIST_ERROR';

export const INVOICE_BATCH_WEEKLY_COUNTS_UPDATED = 'INVOICE_BATCH_WEEKLY_COUNTS_UPDATED';

export const TOAST_ADDED = 'TOAST_ADDED';
export const TOAST_REMOVED = 'TOAST_REMOVED';

export const STATS_UPDATED = 'STATS_UPDATED';

export const REPORT_BATCH_SET_FILE_AND_DATE = 'REPORT_BATCH_SET_FILE_AND_DATE';
//export const REPORT_BATCH_ADD_FILE_AND_DATE = 'REPORT_BATCH_ADD_FILE_AND_DATE';
export const REPORT_BATCH_CREATE_SUCCESS = 'REPORT_BATCH_CREATE_SUCCESS';
export const REPORT_BATCH_CREATE_REQUEST = 'REPORT_BATCH_CREATE_REQUEST';
export const REPORT_BATCH_CREATE_ERROR = 'REPORT_BATCH_CREATE_ERROR';
export const REPORT_BATCH_CREATE_UNLOAD = 'REPORT_BATCH_CREATE_UNLOAD';

export const REPORT_BATCH_UPDATE_REQUEST = 'REPORT_BATCH_UPDATE_REQUEST';
export const REPORT_BATCH_UPDATE_SUCCESS = 'REPORT_BATCH_UPDATE_SUCCESS';
export const REPORT_BATCH_UPDATE_ERROR = 'REPORT_BATCH_UPDATE_ERROR';

export const REPORT_BATCH_SINGLE_REQUEST = 'REPORT_BATCH_SINGLE_REQUEST';
export const REPORT_BATCH_SINGLE_RECEIVED = 'REPORT_BATCH_SINGLE_RECEIVED';
export const REPORT_BATCH_SINGLE_ERROR = 'REPORT_BATCH_SINGLE_ERROR';

export const REPORT_BATCH_PUBLISH_REQUEST = 'REPORT_BATCH_PUBLISH_REQUEST';
export const REPORT_BATCH_PUBLISH_SUCCESS = 'REPORT_BATCH_PUBLISH_SUCCESS';
export const REPORT_BATCH_PUBLISH_ERROR = 'REPORT_BATCH_PUBLISH_ERROR';

export const REPORTS_LIST_REQUEST = 'REPORTS_LIST_REQUEST';
export const REPORTS_LIST_RECEIVED = 'REPORTS_LIST_RECEIVED';
export const REPORTS_LIST_ERROR = 'REPORTS_LIST_ERROR';
export const REPORTS_LIST_SET_PAGE = 'REPORTS_LIST_SET_PAGE';
export const REPORTS_LIST_SET_FILTERS = 'REPORTS_LIST_SET_FILTERS';
export const REPORTS_LIST_CLEAR_FILTERS = 'REPORTS_LIST_CLEAR_FILTERS';

export const REPORT_SINGLE_DELETE_REQUEST = 'REPORT_SINGLE_DELETE_REQUEST';
export const REPORT_SINGLE_DELETE_SUCCESS = 'REPORT_SINGLE_DELETE_SUCCESS';
//
//
//export const REPORT_BATCH_FILE_UPLOAD_SUCCESS = 'REPORT_BATCH_FILE_UPLOAD_SUCCESS';
//export const REPORT_BATCH_FILE_UPLOAD_REQUEST = 'REPORT_BATCH_FILE_UPLOAD_REQUEST';
//export const REPORT_BATCH_FILE_UPLOAD_ERROR = 'REPORT_BATCH_FILE_UPLOAD_ERROR';
//export const REPORT_BATCH_FILE_DELETE_SUCCESS = 'REPORT_BATCH_FILE_DELETE_SUCCESS';
//export const REPORT_BATCH_FILE_DELETE_REQUEST = 'REPORT_BATCH_FILE_DELETE_REQUEST';
//export const REPORT_BATCH_FILE_UPLOAD_CLEAR_REFERENCES = 'REPORT_BATCH_FILE_UPLOAD_CLEAR_REFERENCES';

export const SUB_ACCOUNT_SINGLE_REQUEST = 'SUB_ACCOUNT_SINGLE_REQUEST';
export const SUB_ACCOUNT_SINGLE_RECEIVED = 'SUB_ACCOUNT_SINGLE_RECEIVED';
export const SUB_ACCOUNT_SINGLE_ERROR = 'SUB_ACCOUNT_SINGLE_ERROR';

export const SUBUSER_SET_CONSULTANT_TO_MANAGE = 'USER_SET_CONSULTANT_TO_MANAGE';
export const SUB_USER_SINGLE_REQUEST = 'SUB_USER_SINGLE_REQUEST';
export const SUB_USER_SINGLE_RECEIVED = 'SUB_USER_SINGLE_RECEIVED';
export const SUB_USER_SINGLE_ERROR = 'SUB_USER_SINGLE_ERROR';

export const HOSPITAL_LIST_REQUEST = 'HOSPITAL_LIST_REQUEST';
export const HOSPITAL_LIST_RECEIVED = 'HOSPITAL_LIST_RECEIVED';
export const HOSPITAL_LIST_ERROR = 'HOSPITAL_LIST_ERROR';
export const HOSPITAL_LIST_SET_PAGE = 'HOSPITAL_LIST_SET_PAGE';
//export const HOSPITAL_LIST_SET_FILTERS = 'HOSPITAL_LIST_SET_FILTERS';
//export const HOSPITAL_LIST_CLEAR_FILTERS = 'HOSPITAL_LIST_CLEAR_FILTERS';
export const HOSPITAL_LIST_UNLOAD = 'HOSPITAL_LIST_UNLOAD';

export const HOSPITAL_SINGLE_UPDATED = 'HOSPITAL_SINGLE_UPDATED';
export const HOSPITAL_SINGLE_UPDATE_REQUEST = 'HOSPITAL_SINGLE_UPDATE_REQUEST';
export const HOSPITAL_SINGLE_CREATE_SUCCESS = 'HOSPITAL_SINGLE_CREATE_SUCCESS';
export const HOSPITAL_SINGLE_CREATE_REQUEST = 'HOSPITAL_SINGLE_CREATE_REQUEST';

export const CONSULTANT_BIBLE_LOG_REQUEST = 'CONSULTANT_BIBLE_LOG_REQUEST';
export const CONSULTANT_BIBLE_LOG_UPDATED = 'CONSULTANT_BIBLE_LOG_UPDATED';
export const CONSULTANT_BIBLE_LOG_UNLOAD = 'CONSULTANT_BIBLE_LOG_UNLOAD';
export const CONSULTANT_BIBLE_LOG_ERROR = 'CONSULTANT_BIBLE_LOG_ERROR';
export const CONSULTANT_BIBLE_LOG_SET_PAGE = 'CONSULTANT_BIBLE_LOG_SET_PAGE';

export const HEALTH_INSURER_LIST_REQUEST = 'HEALTH_INSURER_LIST_REQUEST';
export const HEALTH_INSURER_LIST_RECEIVED = 'HEALTH_INSURER_LIST_RECEIVED';
export const HEALTH_INSURER_LIST_ERROR = 'HEALTH_INSURER_LIST_ERROR';
export const HEALTH_INSURER_LIST_SET_PAGE = 'HEALTH_INSURER_LIST_SET_PAGE';
export const HEALTH_INSURER_LIST_UNLOAD = 'HEALTH_INSURER_LIST_UNLOAD';

export const CCSD_CODE_LIST_REQUEST = 'CCSD_CODE_LIST_REQUEST';
export const CCSD_CODE_LIST_RECEIVED = 'CCSD_CODE_LIST_RECEIVED';
export const CCSD_CODE_LIST_ERROR = 'CCSD_CODE_LIST_ERROR';
export const CCSD_CODE_LIST_SET_PAGE = 'CCSD_CODE_LIST_SET_PAGE';
export const CCSD_CODE_LIST_UNLOAD = 'CCSD_CODE_LIST_UNLOAD';
export const CCSD_CODE_LIST_SET_FILTERS = 'CCSD_CODE_LIST_SET_FILTERS';
export const CCSD_CODE_LIST_CLEAR_FILTERS = 'CCSD_CODE_LIST_CLEAR_FILTERS';
export const CCSD_CODE_SINGLE_REQUEST = 'CCSD_CODE_SINGLE_REQUEST';
export const CCSD_CODE_SINGLE_RECEIVED = 'CCSD_CODE_SINGLE_RECEIVED';
export const CCSD_CODE_SINGLE_ERROR = 'CCSD_CODE_SINGLE_ERROR';
export const CCSD_CODE_SINGLE_UPDATE_REQUEST = 'CCSD_CODE_SINGLE_UPDATE_REQUEST';
export const CCSD_CODE_SINGLE_UPDATED = 'CCSD_CODE_SINGLE_UPDATED';
export const CCSD_CODE_SINGLE_UNLOAD = 'CCSD_CODE_SINGLE_UNLOAD';
export const CCSD_CODE_DELETE_REQUEST = 'CCSD_CODE_DELETE_REQUEST';
export const CCSD_CODE_DELETED = 'CCSD_CODE_DELETED';

export const CCSD_CODE_SINGLE_CREATE_REQUEST = 'CCSD_CODE_SINGLE_CREATE_REQUEST';
export const CCSD_CODE_SINGLE_CREATE_SUCCESS = 'CCSD_CODE_SINGLE_CREATE_SUCCESS';

export const CCSD_CODE_TYPEAHEAD_LIST_REQUEST = 'CCSD_CODE_TYPEAHEAD_LIST_REQUEST';
export const CCSD_CODE_TYPEAHEAD_LIST_RECEIVED = 'CCSD_CODE_TYPEAHEAD_LIST_RECEIVED';
export const CCSD_CODE_TYPEAHEAD_LIST_ERROR = 'CCSD_CODE_TYPEAHEAD_LIST_ERROR';
export const CCSD_CODE_TYPEAHEAD_LIST_UNLOAD = 'CCSD_CODE_TYPEAHEAD_LIST_UNLOAD';

export const CCSD_CODE_COST_LIST_REQUEST = 'CCSD_CODE_COST_LIST_REQUEST';
export const CCSD_CODE_COST_LIST_RECEIVED = 'CCSD_CODE_COST_LIST_RECEIVED';
export const CCSD_CODE_COST_LIST_ERROR = 'CCSD_CODE_COST_LIST_ERROR';
export const CCSD_CODE_COST_LIST_SET_PAGE = 'CCSD_CODE_COST_LIST_SET_PAGE';
export const CCSD_CODE_COST_LIST_UNLOAD = 'CCSD_CODE_COST_LIST_UNLOAD';

export const CCSD_CODE_COST_SINGLE_REQUEST = 'CCSD_CODE_COST_SINGLE_REQUEST';
export const CCSD_CODE_COST_SINGLE_RECEIVED = 'CCSD_CODE_COST_SINGLE_RECEIVED';
export const CCSD_CODE_COST_SINGLE_ERROR = 'CCSD_CODE_COST_SINGLE_ERROR';

export const CCSD_CODE_COST_SINGLE_CREATE_REQUEST = 'CCSD_CODE_SINGLE_COST_CREATE_REQUEST';
export const CCSD_CODE_COST_SINGLE_CREATE_SUCCESS = 'CCSD_CODE_SINGLE_COST_CREATE_SUCCESS';
export const CCSD_CODE_COST_SINGLE_UPDATE_REQUEST = 'CCSD_CODE_COST_SINGLE_UPDATE_REQUEST';
export const CCSD_CODE_COST_SINGLE_UPDATED = 'CCSD_CODE_COST_SINGLE_UPDATED';

export const DATED_COST_SINGLE_CREATE_REQUEST = 'DATED_COST_SINGLE_CREATE_REQUEST';
export const DATED_COST_SINGLE_CREATE_SUCCESS = 'DATED_COST_SINGLE_CREATE_SUCCESS';
export const DATED_COST_LIST_ERROR = 'DATED_COST_LIST_ERROR';
export const DATED_COST_LIST_REQUEST = 'DATED_COST_LIST_REQUEST';
export const DATED_COST_LIST_SET_PAGE = 'DATED_COST_LIST_SET_PAGE';
export const DATED_COST_LIST_UNLOAD = 'DATED_COST_LIST_UNLOAD';
export const DATED_COST_LIST_UPDATED = 'DATED_COST_LIST_UPDATED';

export const CCSD_CODE_TYPE_LIST_REQUEST = 'CCSD_CODE_TYPE_LIST_REQUEST';
export const CCSD_CODE_TYPE_LIST_RECEIVED = 'CCSD_CODE_TYPE_LIST_RECEIVED';
export const CCSD_CODE_TYPE_LIST_ERROR = 'CCSD_CODE_TYPE_LIST_ERROR';

export const CONSULTANT_TYPE_LIST_REQUEST = 'CONSULTANT_TYPE_LIST_REQUEST';
export const CONSULTANT_TYPE_LIST_RECEIVED = 'CONSULTANT_TYPE_LIST_RECEIVED';
export const CONSULTANT_TYPE_LIST_ERROR = 'CONSULTANT_TYPE_LIST_ERROR';

export const CONSULTANT_HEALTH_INSURER_SINGLE_UPDATE_REQUEST = 'CONSULTANT_HEALTH_INSURER_SINGLE_UPDATE_REQUEST';
export const CONSULTANT_HEALTH_INSURER_SINGLE_UPDATED = 'CONSULTANT_HEALTH_INSURER_SINGLE_UPDATED';

export const ONBOARDING_ITEMS_LIST_REQUEST = 'ONBOARDING_ITEMS_LIST_REQUEST';
export const ONBOARDING_ITEMS_LIST_RECEIVED = 'ONBOARDING_ITEMS_LIST_RECEIVED';
export const ONBOARDING_ITEMS_LIST_RECEIVED_ERROR = 'ONBOARDING_ITEMS_LIST_RECEIVED_ERROR';
export const ONBOARDING_ITEMS_LIST_UNLOAD = 'ONBOARDING_ITEMS_LIST_UNLOAD';

export const ONBOARDING_ITEMS_PATCH_ERROR = 'ONBOARDING_ITEMS_PATCH_ERROR';
export const ONBOARDING_ITEMS_PATCH_RECEIVED = 'ONBOARDING_ITEMS_PATCH_RECEIVED';

export const WORK_SCHEDULE_REQUEST = 'WORK_SCHEDULE_REQUEST';
export const WORK_SCHEDULE_RECEIVED = 'WORK_SCHEDULE_RECEIVED';
export const WORK_SCHEDULE_SINGLE_RECEIVED = 'WORK_SCHEDULE_SINGLE_RECEIVED';
export const WORK_SCHEDULE_ERROR = 'WORK_SCHEDULE_ERROR';
export const WORK_SCHEDULE_UNLOAD = 'WORK_SCHEDULE_UNLOAD';
export const WORK_SCHEDULE_SET_DATE = 'WORK_SCHEDULE_SET_DATE';

export const CHASER_WORK_SCHEDULE_REQUEST = 'CHASER_WORK_SCHEDULE_REQUEST';
export const CHASER_WORK_SCHEDULE_RECEIVED = 'CHASER_WORK_SCHEDULE_RECEIVED';
export const CHASER_WORK_SCHEDULE_SINGLE_RECEIVED = 'CHASER_WORK_SCHEDULE_SINGLE_RECEIVED';
export const CHASER_WORK_SCHEDULE_ERROR = 'CHASER_WORK_SCHEDULE_ERROR';
export const CHASER_WORK_SCHEDULE_UNLOAD = 'CHASER_WORK_SCHEDULE_UNLOAD';
export const CHASER_WORK_SCHEDULE_SET_DATE = 'CHASER_WORK_SCHEDULE_SET_DATE';

export const PROBLEM_TYPES_REQUEST = 'PROBLEM_TYPES_REQUEST';
export const PROBLEM_TYPES_UNLOAD = 'PROBLEM_TYPES_UNLOAD';
export const PROBLEM_TYPES_RECEIVED = 'PROBLEM_TYPES_RECEIVED';

export const PROBLEM_DATA_SINGLE_REQUEST = 'PROBLEM_DATA_SINGLE_REQUEST';
export const PROBLEM_DATA_SINGLE_RECEIVED = 'PROBLEM_DATA_SINGLE_RECEIVED';
export const PROBLEM_DATA_SINGLE_ERROR = 'PROBLEM_DATA_SINGLE_ERROR';
export const PROBLEM_DATA_SINGLE_UNLOAD = 'PROBLEM_DATA_SINGLE_UNLOAD';
export const PROBLEM_DATA_SINGLE_UPDATED = 'PROBLEM_DATA_SINGLE_UPDATED';

export const PROBLEM_STATUSES_REQUEST = 'PROBLEM_STATUSES_REQUEST';
export const PROBLEM_STATUSES_UNLOAD = 'PROBLEM_STATUSES_UNLOAD';
export const PROBLEM_STATUSES_RECEIVED = 'PROBLEM_STATUSES_RECEIVED';

export const PROBLEM_DATA_REQUEST = 'PROBLEM_DATA_REQUEST';
export const PROBLEM_DATA_RECEIVED = 'PROBLEM_DATA_RECEIVED';
export const PROBLEM_DATA_ADDED = 'PROBLEM_DATA_ADDED';
export const PROBLEM_DATA_UNLOAD = 'PROBLEM_DATA_UNLOAD';

export const PROBLEM_DATA_LIST_ERROR = 'PROBLEM_DATA_LIST_ERROR';
export const PROBLEM_DATA_LIST_REQUEST = 'PROBLEM_DATA_LIST_REQUEST';
export const PROBLEM_DATA_LIST_RECEIVED = 'PROBLEM_DATA_LIST_RECEIVED';

export const PROBLEM_DATA_LIST_SET_PAGE = 'PROBLEM_DATA_LIST_SET_PAGE';
export const PROBLEM_DATA_LIST_UNLOAD = 'PROBLEM_DATA_LIST_UNLOAD';
export const PROBLEM_DATA_LIST_SET_FILTERS = 'PROBLEM_DATA_LIST_SET_FILTERS';
export const PROBLEM_DATA_LIST_CLEAR_FILTERS = 'PROBLEM_DATA_LIST_CLEAR_FILTERS';

export const PROBLEM_DATA_INSURER_LIST_REQUEST = 'PROBLEM_DATA_INSURER_LIST_REQUEST';
export const PROBLEM_DATA_INSURER_LIST_RECEIVED = 'PROBLEM_DATA_INSURER_LIST_RECEIVED';
export const PROBLEM_DATA_INSURER_LIST_ERROR = 'PROBLEM_DATA_INSURER_LIST_ERROR';
export const PROBLEM_DATA_INSURER_LIST_UNLOAD = 'PROBLEM_DATA_INSURER_LIST_UNLOAD';

export const UPLIFT_DATA_REQUEST = 'UPLIFT_DATA_REQUEST';
export const UPLIFT_DATA_RECEIVED = 'UPLIFT_DATA_RECEIVED';
export const UPLIFT_DATA_RECEIVED_ERROR = 'UPLIFT_DATA_RECEIVED_ERROR';

export const BILLING_CHECKLIST_EVENT_LIST_REQUEST = 'BILLING_CHECKLIST_EVENT_LIST_REQUEST';
export const BILLING_CHECKLIST_EVENT_LIST_RECEIVED = 'BILLING_CHECKLIST_EVENT_LIST_RECEIVED';
export const BILLING_CHECKLIST_EVENT_LIST_ERROR = 'BILLING_CHECKLIST_EVENT_LIST_ERROR';
export const BILLING_CHECKLIST_EVENT_LIST_SET_PAGE = 'BILLING_CHECKLIST_EVENT_LIST_SET_PAGE';
export const BILLING_CHECKLIST_EVENT_LIST_UNLOAD = 'BILLING_CHECKLIST_EVENT_LIST_UNLOAD';
export const BILLING_CHECKLIST_EVENT_LIST_SET_FILTERS = 'BILLING_CHECKLIST_EVENT_LIST_SET_FILTERS';
export const BILLING_CHECKLIST_EVENT_LIST_CLEAR_FILTERS = 'BILLING_CHECKLIST_EVENT_LIST_CLEAR_FILTERS';
export const BILLING_CHECKLIST_EVENT_SINGLE_REQUEST = 'BILLING_CHECKLIST_EVENT_SINGLE_REQUEST';
export const BILLING_CHECKLIST_EVENT_SINGLE_RECEIVED = 'BILLING_CHECKLIST_EVENT_SINGLE_RECEIVED';
export const BILLING_CHECKLIST_EVENT_SINGLE_ERROR = 'BILLING_CHECKLIST_EVENT_SINGLE_ERROR';
export const BILLING_CHECKLIST_EVENT_SINGLE_UPDATE_REQUEST = 'BILLING_CHECKLIST_EVENT_SINGLE_UPDATE_REQUEST';
export const BILLING_CHECKLIST_EVENT_SINGLE_UPDATED = 'BILLING_CHECKLIST_EVENT_SINGLE_UPDATED';
export const BILLING_CHECKLIST_EVENT_SINGLE_UNLOAD = 'BILLING_CHECKLIST_EVENT_SINGLE_UNLOAD';
export const BILLING_CHECKLIST_EVENT_DELETE_REQUEST = 'BILLING_CHECKLIST_EVENT_DELETE_REQUEST';
export const BILLING_CHECKLIST_EVENT_DELETED = 'BILLING_CHECKLIST_EVENT_DELETED';
export const BILLING_CHECKLIST_EVENT_SINGLE_CREATE_REQUEST = 'BILLING_CHECKLIST_EVENT_SINGLE_CREATE_REQUEST';
export const BILLING_CHECKLIST_EVENT_SINGLE_CREATE_SUCCESS = 'BILLING_CHECKLIST_EVENT_SINGLE_CREATE_SUCCESS';

export const WORK_SCHEDULE_EVENT_LIST_REQUEST = 'WORK_SCHEDULE_EVENT_LIST_REQUEST';
export const WORK_SCHEDULE_EVENT_LIST_RECEIVED = 'WORK_SCHEDULE_EVENT_LIST_RECEIVED';
export const WORK_SCHEDULE_EVENT_LIST_ERROR = 'WORK_SCHEDULE_EVENT_LIST_ERROR';
export const WORK_SCHEDULE_EVENT_LIST_SET_PAGE = 'WORK_SCHEDULE_EVENT_LIST_SET_PAGE';
export const WORK_SCHEDULE_EVENT_LIST_UNLOAD = 'WORK_SCHEDULE_EVENT_LIST_UNLOAD';
export const WORK_SCHEDULE_EVENT_LIST_SET_FILTERS = 'WORK_SCHEDULE_EVENT_LIST_SET_FILTERS';
export const WORK_SCHEDULE_EVENT_LIST_CLEAR_FILTERS = 'WORK_SCHEDULE_EVENT_LIST_CLEAR_FILTERS';
export const WORK_SCHEDULE_EVENT_SINGLE_REQUEST = 'WORK_SCHEDULE_EVENT_SINGLE_REQUEST';
export const WORK_SCHEDULE_EVENT_SINGLE_RECEIVED = 'WORK_SCHEDULE_EVENT_SINGLE_RECEIVED';
export const WORK_SCHEDULE_EVENT_SINGLE_ERROR = 'WORK_SCHEDULE_EVENT_SINGLE_ERROR';
export const WORK_SCHEDULE_EVENT_SINGLE_UPDATE_REQUEST = 'WORK_SCHEDULE_EVENT_SINGLE_UPDATE_REQUEST';
export const WORK_SCHEDULE_EVENT_SINGLE_UPDATED = 'WORK_SCHEDULE_EVENT_SINGLE_UPDATED';
export const WORK_SCHEDULE_EVENT_SINGLE_UNLOAD = 'WORK_SCHEDULE_EVENT_SINGLE_UNLOAD';
export const WORK_SCHEDULE_EVENT_DELETE_REQUEST = 'WORK_SCHEDULE_EVENT_DELETE_REQUEST';
export const WORK_SCHEDULE_EVENT_DELETED = 'WORK_SCHEDULE_EVENT_DELETED';
export const WORK_SCHEDULE_EVENT_SINGLE_CREATE_REQUEST = 'WORK_SCHEDULE_EVENT_SINGLE_CREATE_REQUEST';
export const WORK_SCHEDULE_EVENT_SINGLE_CREATE_SUCCESS = 'WORK_SCHEDULE_EVENT_SINGLE_CREATE_SUCCESS';

export const CONSULTANT_TICKET_LIST_REQUEST = 'CONSULTANT_TICKET_LIST_REQUEST';
export const CONSULTANT_TICKET_LIST_RECEIVED = 'CONSULTANT_TICKET_LIST_RECEIVED';
export const CONSULTANT_TICKET_LIST_ERROR = 'CONSULTANT_TICKET_LIST_ERROR';
export const CONSULTANT_TICKET_LIST_SET_PAGE = 'CONSULTANT_TICKET_LIST_SET_PAGE';
export const CONSULTANT_TICKET_LIST_SET_FILTERS = 'CONSULTANT_TICKET_LIST_SET_FILTERS';
export const CONSULTANT_TICKET_LIST_CLEAR_FILTERS = 'CONSULTANT_TICKET_LIST_CLEAR_FILTERS';
export const CONSULTANT_TICKET_LIST_UNLOAD = 'CONSULTANT_TICKET_LIST_UNLOAD';
export const CONSULTANT_TICKET_SINGLE_UPDATE_REQUEST = 'CONSULTANT_TICKET_SINGLE_UPDATE_REQUEST';
export const CONSULTANT_TICKET_SINGLE_UPDATED = 'CONSULTANT_TICKET_SINGLE_UPDATED';
export const CONSULTANT_TICKET_SINGLE_CREATE_SUCCESS = 'CONSULTANT_TICKET_SINGLE_CREATE_SUCCESS';
export const CONSULTANT_TICKET_SINGLE_CREATE_REQUEST = 'CONSULTANT_TICKET_SINGLE_CREATE_REQUEST';
export const CONSULTANT_TICKET_SINGLE_CREATE_ERROR = 'CONSULTANT_TICKET_SINGLE_CREATE_ERROR';

export const CONSULTANT_TICKET_SINGLE_REQUEST = 'CONSULTANT_TICKET_SINGLE_REQUEST';
export const CONSULTANT_TICKET_SINGLE_RECEIVED = 'CONSULTANT_TICKET_SINGLE_RECEIVED';
export const CONSULTANT_TICKET_SINGLE_ERROR = 'CONSULTANT_TICKET_SINGLE_ERROR';
export const CONSULTANT_TICKET_SINGLE_UNLOAD = 'CONSULTANT_TICKET_SINGLE_UNLOAD';
export const CONSULTANT_TICKET_TYPE_LIST_REQUEST = 'CONSULTANT_TICKET_TYPE_LIST_REQUEST';
export const CONSULTANT_TICKET_TYPE_LIST_RECEIVED = 'CONSULTANT_TICKET_TYPE_LIST_RECEIVED';
export const CONSULTANT_TICKET_TYPE_LIST_ERROR = 'CONSULTANT_TICKET_TYPE_LIST_ERROR';

export const CONSULTANT_TICKET_NOTE_SINGLE_CREATE_SUCCESS = 'CONSULTANT_TICKET_NOTE_SINGLE_CREATE_SUCCESS';
export const CONSULTANT_TICKET_NOTE_SINGLE_CREATE_REQUEST = 'CONSULTANT_TICKET_NOTE_SINGLE_CREATE_REQUEST';
export const CONSULTANT_TICKET_NOTE_SINGLE_CREATE_ERROR = 'CONSULTANT_TICKET_NOTE_SINGLE_CREATE_ERROR';

export const INVOICE_LIST_REQUEST = 'INVOICE_LIST_REQUEST';
export const INVOICE_LIST_RECEIVED = 'INVOICE_LIST_RECEIVED';
export const INVOICE_LIST_UNLOAD_PERSIST_FILTERS = 'INVOICE_LIST_UNLOAD_PERSIST_FILTERS';
export const INVOICE_LIST_ERROR = 'INVOICE_LIST_ERROR';
export const INVOICE_LIST_SET_PAGE = 'INVOICE_LIST_SET_PAGE';
export const INVOICE_LIST_UNLOAD = 'INVOICE_LIST_UNLOAD';
export const INVOICE_LIST_SET_FILTERS = 'INVOICE_LIST_SET_FILTERS';
export const INVOICE_LIST_CLEAR_FILTERS = 'INVOICE_LIST_CLEAR_FILTERS';
export const INVOICE_SINGLE_REQUEST = 'INVOICE_SINGLE_REQUEST';
export const INVOICE_SINGLE_RECEIVED = 'INVOICE_SINGLE_RECEIVED';
export const INVOICE_SINGLE_ERROR = 'INVOICE_SINGLE_ERROR';
export const INVOICE_SINGLE_UPDATE_REQUEST = 'INVOICE_SINGLE_UPDATE_REQUEST';
export const INVOICE_SINGLE_UPDATED = 'INVOICE_SINGLE_UPDATED';
export const INVOICE_SINGLE_UNLOAD = 'INVOICE_SINGLE_UNLOAD';
export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST';
export const INVOICE_DELETED = 'INVOICE_DELETED';

export const INVOICE_SINGLE_ACTION_REQUEST = 'INVOICE_SINGLE_ACTION_REQUEST';
export const INVOICE_SINGLE_ACTION_PERFORMED = 'INVOICE_SINGLE_ACTION_PERFORMED';

export const INVOICE_SINGLE_UNASSIGNED_FROM_CURRENT_USER = 'INVOICE_SINGLE_UNASSIGNED_FROM_CURRENT_USER';
export const INVOICE_SINGLE_ACTION_ERROR = 'INVOICE_SINGLE_ACTION_ERROR';

export const INVOICE_ADDED = 'INVOICE_ADDED';
export const INVOICE_ADD_REQUEST = 'INVOICE_ADD_REQUEST';
export const INVOICE_ASSIGN_REQUEST = 'INVOICE_ASSIGN_REQUEST';
export const INVOICE_ASSIGN_RECEIVED = 'INVOICE_ASSIGN_REQUEST';
export const INVOICE_ASSIGN_ERROR = 'INVOICE_ASSIGN_REQUEST';

export const INVOICE_UNASSIGN_REQUEST = 'INVOICE_UNASSIGN_REQUEST';
export const INVOICE_BULK_UNASSIGNED = 'INVOICE_BULK_UNASSIGNED';

export const INVOICE_PAYOR_TYPE_LIST_REQUEST = 'INVOICE_PAYOR_TYPE_LIST_REQUEST';
export const INVOICE_PAYOR_TYPE_LIST_RECEIVED = 'INVOICE_PAYOR_TYPE_LIST_RECEIVED';
export const INVOICE_PAYOR_TYPE_LIST_ERROR = 'INVOICE_PAYOR_TYPE_LIST_ERROR';

export const INVOICE_ACTION_STATS_RECEIVED = 'INVOICE_ACTION_STATS_RECEIVED';

export const INVOICE_ACTION_STATS_REQUEST = 'INVOICE_ACTION_STATS_REQUEST';

export const INVOICE_MISSING_DETAILS_TYPE_LIST_REQUEST = 'INVOICE_MISSING_DETAILS_TYPE_LIST_REQUEST';
export const INVOICE_MISSING_DETAILS_TYPE_LIST_RECEIVED = 'INVOICE_MISSING_DETAILS_TYPE_LIST_RECEIVED';
export const INVOICE_MISSING_DETAILS_TYPE_LIST_ERROR = 'INVOICE_MISSING_DETAILS_TYPE_LIST_ERROR';

export const EMAIL_AUDIT_LOG_LIST_REQUEST = 'EMAIL_AUDIT_LOG_LIST_REQUEST';
export const EMAIL_AUDIT_LOG_LIST_DATA_RECEIVED = 'EMAIL_AUDIT_LOG_LIST_DATA_RECEIVED';
export const EMAIL_AUDIT_LOG_LIST_ERROR = 'EMAIL_AUDIT_LOG_LIST_ERROR';
export const UNLOAD_EMAIL_AUDIT_LOG_LIST = 'UNLOAD_EMAIL_AUDIT_LOG_LIST';

export const INVOICE_ESCALATION_REASON_LIST_REQUEST = 'INVOICE_ESCALATION_REASON_LIST_REQUEST';

export const INVOICE_ESCALATION_REASON_LIST_RECEIVED = 'INVOICE_ESCALATION_REASON_LIST_RECEIVED';

export const INVOICE_ESCALATION_REASON_LIST_ERROR = 'INVOICE_ESCALATION_REASON_LIST_ERROR';

export const INVOICE_ESCALATION_STATUS_LIST_REQUEST = 'INVOICE_ESCALATION_STATUS_LIST_REQUEST';

export const INVOICE_ESCALATION_STATUS_LIST_RECEIVED = 'INVOICE_ESCALATION_STATUS_LIST_RECEIVED';

export const INVOICE_ESCALATION_STATUS_LIST_ERROR = 'INVOICE_ESCALATION_STATUS_LIST_ERROR';

export const PAYMENT_LINK_REQUEST = 'PAYMENT_LINK_REQUEST';
export const PAYMENT_LINK_RECEIVED = 'PAYMENT_LINK_RECEIVED';
export const PAYMENT_LINK_ERROR = 'PAYMENT_LINK_ERROR';

export const SITE_SETTINGS_LIST_REQUEST = 'SITE_SETTINGS_LIST_REQUEST';
export const SITE_SETTINGS_LIST_RECEIVED = 'SITE_SETTINGS_LIST_RECEIVED';
export const SITE_SETTINGS_LIST_ERROR = 'SITE_SETTINGS_LIST_ERROR';
export const SITE_SETTINGS_LIST_UNLOAD = 'SITE_SETTINGS_LIST_UNLOAD';

export const SITE_SETTING_SINGLE_UPDATE_REQUEST = 'SITE_SETTING_SINGLE_UPDATE_REQUEST';
export const SITE_SETTING_SINGLE_UPDATED = 'SITE_SETTING_SINGLE_UPDATED';

export const CONTACT_RECORD_LIST_REQUEST = 'CONTACT_RECORD_LIST_REQUEST';
export const CONTACT_RECORD_LIST_RECEIVED = 'CONTACT_RECORD_LIST_RECEIVED';
export const CONTACT_RECORD_LIST_ERROR = 'CONTACT_RECORD_LIST_ERROR';
export const CONTACT_RECORD_LIST_SET_PAGE = 'CONTACT_RECORD_LIST_SET_PAGE';
export const CONTACT_RECORD_LIST_UNLOAD = 'CONTACT_RECORD_LIST_UNLOAD';
export const CONTACT_RECORD_SINGLE_UPDATED = 'CONTACT_RECORD_SINGLE_UPDATED';
export const CONTACT_RECORD_SINGLE_UPDATE_REQUEST = 'CONTACT_RECORD_SINGLE_UPDATE_REQUEST';
export const CONTACT_RECORD_SINGLE_CREATE_SUCCESS = 'CONTACT_RECORD_SINGLE_CREATE_SUCCESS';
export const CONTACT_RECORD_SINGLE_CREATE_REQUEST = 'CONTACT_RECORD_SINGLE_CREATE_REQUEST';

export const CONTACT_RECORD_SINGLE_REMOVE_REQUEST = 'CONTACT_RECORD_SINGLE_REMOVE_REQUEST';
export const CONTACT_RECORD_SINGLE_REMOVED = 'CONTACT_RECORD_SINGLE_REMOVED';
export const CONTACT_RECORD_SINGLE_REMOVE_ERROR = 'CONTACT_RECORD_SINGLE_REMOVE_ERROR';

export const CONTACT_RECORD_TYPE_LIST_REQUEST = 'CONTACT_RECORD_TYPE_LIST_REQUEST';
export const CONTACT_RECORD_TYPE_LIST_RECEIVED = 'CONTACT_RECORD_TYPE_LIST_RECEIVED';
export const CONTACT_RECORD_TYPE_LIST_ERROR = 'CONTACT_RECORD_TYPE_LIST_ERROR';
