import React from 'react';
import BillingChecklist from '../../components/billingChecklist/BillingChecklist';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class BillingChecklistContainer extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <BillingChecklist match={this.props.match} history={this.props.history} />
        </Col>
      </Row>
    );
  }
}

export default BillingChecklistContainer;
