import React from 'react';
import SubAccountForm from '../../components/forms/Consultant/SubAccountAcceptInviteForm';

class SubAccountAccept extends React.Component {
  render() {
    const { subAccount, id, code, history } = this.props;
    return (
      <div>
        <SubAccountForm history={history} subAccount={subAccount} id={id} code={code} />
      </div>
    );
  }
}

export default SubAccountAccept;
