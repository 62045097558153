import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  CCSD_CODE_LIST_REQUEST,
  CCSD_CODE_LIST_RECEIVED,
  CCSD_CODE_LIST_ERROR,
  CCSD_CODE_LIST_SET_PAGE,
  CCSD_CODE_LIST_UNLOAD,
  CCSD_CODE_SINGLE_CREATE_REQUEST,
  CCSD_CODE_SINGLE_CREATE_SUCCESS,
  CCSD_CODE_SINGLE_REQUEST,
  CCSD_CODE_SINGLE_RECEIVED,
  CCSD_CODE_SINGLE_ERROR,
  CCSD_CODE_LIST_SET_FILTERS,
  CCSD_CODE_LIST_CLEAR_FILTERS,
  CCSD_CODE_SINGLE_UPDATE_REQUEST,
  CCSD_CODE_SINGLE_UPDATED,
  CCSD_CODE_SINGLE_UNLOAD,
  CCSD_CODE_DELETE_REQUEST,
  CCSD_CODE_DELETED,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const ccsdCodeListRequest = () => ({
  type: CCSD_CODE_LIST_REQUEST,
});

export const ccsdCodeListReceived = (data) => ({
  type: CCSD_CODE_LIST_RECEIVED,
  data,
});

export const ccsdCodeListError = (error) => ({
  type: CCSD_CODE_LIST_ERROR,
  error,
});

export const ccsdCodeListSetPage = (page) => ({
  type: CCSD_CODE_LIST_SET_PAGE,
  page,
});

export const ccsdCodeListSetFilters = (filters) => ({
  type: CCSD_CODE_LIST_SET_FILTERS,
  filters,
});

export const ccsdCodeListClearFilters = () => ({
  type: CCSD_CODE_LIST_CLEAR_FILTERS,
});

export const ccsdCodeListUnload = () => ({
  type: CCSD_CODE_LIST_UNLOAD,
});

export const ccsdCodeListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(ccsdCodeListRequest());
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    if (filters.pagination) {
      filters.page = page;
    }
    return requests
      .get(API_ROUTES.CCSD_CODES, filters)
      .then((response) => dispatch(ccsdCodeListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(ccsdCodeListError(error));
      });
  };
};

// CONSULTANT_SINGLE

export const ccsdCodeSingleRequest = () => ({
  type: CCSD_CODE_SINGLE_REQUEST,
});

export const ccsdCodeSingleReceived = (data) => ({
  type: CCSD_CODE_SINGLE_RECEIVED,
  data,
});

export const ccsdCodeSingleError = (error) => ({
  type: CCSD_CODE_SINGLE_ERROR,
  error,
});

export const ccsdCodeSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(ccsdCodeSingleRequest());
    //console.log('Fetching single consultant ...');
    return requests
      .get(`${API_ROUTES.CCSD_CODES}/${id}`)
      .then((response) => dispatch(ccsdCodeSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new ccsdCodeSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(ccsdCodeSingleError(error));
      });
  };
};

export const ccsdCodeSingleCreateRequest = () => ({
  type: CCSD_CODE_SINGLE_CREATE_REQUEST,
});

export const ccsdCodeSingleCreateSuccess = (data) => ({
  type: CCSD_CODE_SINGLE_CREATE_SUCCESS,
  data,
});

//export const ccsdCodeSingleCreateError = (error) => ({
//  type: CCSD_CODE_SINGLE_CREATE_ERROR,
//  error,
//});

export const ccsdCodeSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(ccsdCodeSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.CCSD_CODES}`, data)
      .then((response) => {
        dispatch(ccsdCodeSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
          parsedError._error = 'It looks like you may be creating a CCSD code which already exists - please check and try again if not.';
        } else {
          parsedError._error =
            'There was a problem creating the CCSD code - please check the details and try again. If the problem persists please contact support.';
        }
        throw new SubmissionError(parsedError);
      });
  };
};

export const ccsdCodeSingleUpdateRequest = () => ({
  type: CCSD_CODE_SINGLE_UPDATE_REQUEST,
});

export const ccsdCodeSingleUpdated = (data) => ({
  type: CCSD_CODE_SINGLE_UPDATED,
  data,
});

export const ccsdCodeSingleUpdate = (data, ccsdCodeId) => {
  return (dispatch) => {
    dispatch(ccsdCodeSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.CCSD_CODES}/${ccsdCodeId}`, data)
      .then((response) => dispatch(ccsdCodeSingleUpdated(response)))
      .catch((error) => {
        dispatch(ccsdCodeSingleError(''));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const ccsdCodeSingleUnload = () => ({
  type: CCSD_CODE_SINGLE_UNLOAD,
});

export const ccsdCodeDeleteRequest = () => ({
  type: CCSD_CODE_DELETE_REQUEST,
});

export const ccsdCodeDeleted = (id) => ({
  type: CCSD_CODE_DELETED,
  id,
});

export const ccsdCodeDelete = (ccsdCodeId) => {
  return (dispatch) => {
    dispatch(ccsdCodeDeleteRequest());
    return requests
      .delete(`${API_ROUTES.CCSD_CODES}/${ccsdCodeId}`)
      .then(() => dispatch(ccsdCodeDeleted(ccsdCodeId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
