import React from 'react';
import { connect } from 'react-redux';
import { ccsdCodeSingleFetch, ccsdCodeSingleUnload, ccsdCodeDelete } from '../../lib/redux/actions/ccsdCodes';
import CcsdCodeSingle from './CcsdCodeSingle';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';
import { healthInsurerListFetch } from '../../lib/redux/actions/healthInsurer';
import { consultantTypeListFetch } from '../../lib/redux/actions/consultantType';

const mapStateToProps = (state) => ({
  ...state.healthInsurer,
  ...state.ccsdCodes,
  ...state.consultantTypes,
});

const mapDispatchToProps = {
  healthInsurerListFetch,
  consultantTypeListFetch,
  ccsdCodeSingleFetch,
  ccsdCodeSingleUnload,
  ccsdCodeDelete,
  addToast,
};

class CcsdCodeSingleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  componentDidMount() {
    this.props.consultantTypeListFetch();
    this.props.healthInsurerListFetch({}, { pagination: false });
    this.props.ccsdCodeSingleFetch(this.props.match.params.id);
  }

  delete() {
    const { addToast, history } = this.props;
    if (!this.state.deleting) {
      this.setState(() => {
        return { deleting: true };
      });
    } else {
      this.props.ccsdCodeDelete(this.props.ccsdCode.id).then(() => {
        addToast('CCSD Code and all data deleted successfully', true);
        this.setState(() => {
          return { deleting: false };
        });
        history.push(ROUTES.CCSD_CODES.ALL.replace(':page', ''));
      });
    }
  }

  componentWillUnmount() {
    this.props.ccsdCodeSingleUnload();
  }

  render() {
    const { ccsdCode, isFetchingCcsdCode, hasLoadedCcsdCode, healthInsurers, isFetchingConsultantTypes, consultantTypes = [] } = this.props;
    if (!ccsdCode || (!hasLoadedCcsdCode && isFetchingCcsdCode)) {
      return <MASpinner />;
    }
    if (!consultantTypes.length && isFetchingConsultantTypes) {
      return <MASpinner />;
    }
    return (
      <CcsdCodeSingle
        ccsdCode={ccsdCode}
        healthInsurers={healthInsurers}
        consultantTypes={consultantTypes}
        isDeleting={this.state.deleting}
        deleteCcsdCode={this.delete.bind(this)}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CcsdCodeSingleContainer);
