import React from 'react';
import { connect } from 'react-redux';
import { consultantSingleFetch, consultantSingleUnload } from '../../lib/redux/actions/consultant';
import ConsultantSingleForSubUser from './ConsultantSingleForSubUser';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';
import { getSubusersConsultantToManage, isSubUser } from '../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.consultantSingle,
});

const mapDispatchToProps = {
  consultantSingleFetch,
  consultantSingleUnload,
  addToast,
};

class ConsultantSingleForSubUserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deactivating: false,
      deleting: false,
    };
  }

  componentDidMount() {
    const consultantToManage = isSubUser(true) ? getSubusersConsultantToManage() : false;
    //    console.log({consultantToManage});
    this.props.consultantSingleFetch(consultantToManage.id);
  }

  componentWillUnmount() {
    this.props.consultantSingleUnload();
  }

  render() {
    const { consultant, isFetchingConsultant } = this.props;
    //    console.log({props:this.props});
    if (!consultant || isFetchingConsultant) {
      return <MASpinner />;
    }
    return <ConsultantSingleForSubUser consultant={consultant} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantSingleForSubUserContainer);
