import React from 'react';
import { connect } from 'react-redux';
import {
  consultantSingleFetch,
  consultantSingleUnload,
  consultantSingleDeactivate,
  consultantSingleActivate,
  consultantSingleDelete,
} from '../../lib/redux/actions/consultant';
import ConsultantSingle from './ConsultantSingle';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';

const mapStateToProps = (state) => ({
  ...state.consultantSingle,
});

const mapDispatchToProps = {
  consultantSingleFetch,
  consultantSingleUnload,
  consultantSingleDeactivate,
  consultantSingleActivate,
  consultantSingleDelete,
  addToast,
};

class ConsultantSingleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deactivating: false,
      deleting: false,
    };
  }

  componentDidMount() {
    this.props.consultantSingleFetch(this.props.match.params.id);
  }

  deactivate() {
    const { addToast } = this.props;
    if (!this.state.deactivating) {
      this.setState(() => {
        return { deactivating: true };
      });
    } else {
      this.props.consultantSingleDeactivate(this.props.consultant.id).then(() => {
        addToast('Consultant deactivated successfully', true);
        this.setState(() => {
          return { deactivating: false };
        });
      });
    }
  }

  activate() {
    const { addToast } = this.props;

    this.props.consultantSingleActivate(this.props.consultant.id).then(() => {
      addToast('Consultant reactivated successfully', true);
    });
  }

  delete() {
    const { addToast, history } = this.props;
    if (!this.state.deleting) {
      this.setState(() => {
        return { deleting: true };
      });
    } else {
      this.props.consultantSingleDelete(this.props.consultant.id).then(() => {
        addToast('Consultant and all data deleted successfully', true);
        this.setState(() => {
          return { deleting: false };
        });
        history.push(ROUTES.CONSULTANTS.ALL.replace(':page', ''));
      });
    }
  }

  componentWillUnmount() {
    this.props.consultantSingleUnload();
  }

  render() {
    const { consultant, isFetchingConsultant, subAccountInviteError } = this.props;
    if (!consultant || isFetchingConsultant) {
      return <MASpinner />;
    }
    return (
      <ConsultantSingle
        consultant={consultant}
        subAccountInviteError={subAccountInviteError}
        isDeleting={this.state.deleting}
        isDeactivating={this.state.deactivating}
        deactivateConsultant={this.deactivate.bind(this)}
        deleteConsultant={this.delete.bind(this)}
        activateConsultant={this.activate.bind(this)}
        history={this.props.history}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantSingleContainer);
