import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

class FileBrowser extends React.Component {
  constructor(props) {
    super(props);
    this._nodeRef = React.createRef();
  }

  render() {
    const { files, deleteHandler = false, isLocked = false } = this.props;
    return (
      <div className="mb-3 file-browser-list">
        <TransitionGroup component={null}>
          {files.map((file) => {
            const onClick = (e) => {
              e.preventDefault();
              if (deleteHandler !== false) {
                deleteHandler(file.id);
              }
            };
            return (
              <CSSTransition nodeRef={this._nodeRef} timeout={1000} classNames="fade" key={file.id}>
                <div className="file-browser-list-item">
                  <p className="mb-0">
                    {file.url}
                    <span
                      className="action-icon ml-2"
                      onClick={
                        isLocked
                          ? () => {
                              return false;
                            }
                          : onClick
                      }>
                      <span className="sr-only">Delete</span>
                      <i className="fas fa-trash text-danger" title="Remove file" />
                    </span>
                  </p>
                </div>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    );
  }
}

export default FileBrowser;
