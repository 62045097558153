import {
  REPORT_BATCH_CREATE_REQUEST,
  REPORT_BATCH_CREATE_SUCCESS,
  REPORT_BATCH_SET_FILE_AND_DATE,
  REPORT_BATCH_SINGLE_RECEIVED,
  REPORT_BATCH_UPDATE_REQUEST,
  REPORT_BATCH_UPDATE_SUCCESS,
  REPORT_BATCH_CREATE_UNLOAD,
  REPORT_BATCH_PUBLISH_REQUEST,
  REPORT_BATCH_PUBLISH_SUCCESS,
} from '../constants';

const reducer = (
  state = {
    fileRequestInProgress: false,
    publishInProgress: false,
    reportBatch: null,
    associatedDate: null,
    fileToUpload: null,
  },
  action,
) => {
  switch (action.type) {
    case REPORT_BATCH_SET_FILE_AND_DATE:
      return {
        ...state,
        associatedDate: action.associatedDate,
        fileToUpload: action.file,
      };

    case REPORT_BATCH_PUBLISH_REQUEST:
      return {
        ...state,
        publishInProgress: true,
      };
    case REPORT_BATCH_UPDATE_REQUEST:
    case REPORT_BATCH_CREATE_REQUEST:
      return {
        ...state,
        requestInProgress: true,
      };
    case REPORT_BATCH_SINGLE_RECEIVED:
    case REPORT_BATCH_UPDATE_SUCCESS:
    case REPORT_BATCH_CREATE_SUCCESS:
      return {
        ...state,
        fileRequestInProgress: false,
        reportBatch: action.reportBatch,
        fileToUpload: null,
      };

    case REPORT_BATCH_PUBLISH_SUCCESS:
      return {
        ...state,
        publishInProgress: false,
        reportBatch: action.reportBatch,
      };
    case REPORT_BATCH_CREATE_UNLOAD:
      return {
        ...state,
        fileRequestInProgress: false,
        reportBatch: null,
        fileToUpload: null,
        associatedDate: null,
      };

    //    case REPORT_BATCH_ADD_ERROR:
    //      return {
    //        ...state,
    //        requestInProgress: false,
    //      };
    //    case REPORT_BATCH_FILE_DELETE_SUCCESS:
    //    case REPORT_BATCH_FILE_UPLOAD_CLEAR_REFERENCES:
    //      return {
    //        ...state,
    //        reportBatch: null,
    //        requestInProgress: false
    //      };
    default:
      return state;
  }
};

export default reducer;
