import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

class HelpPage extends React.Component {
  render() {
    return (
      <div>
        {/*  HELP PAGE */}
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Help and Information</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <div>
                      <h1 className="h2">Frequently Asked Questions</h1>
                    </div>

                    <Accordion className="mt-4 mediaccounts-faq-accordion">
                      <Accordion.Toggle as="h3" eventKey="0" className="h5 mediaccounts-faq-accordion__question">
                        How do I upload my invoices?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <div className="mediaccounts-faq-accordion__answer">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                          <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>and then lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                          <p>Example video</p>

                          <div className="embed-container">
                            {/* When copying in Embed code from YouTube, camelCase frameBorder and allowFullScreen*/}
                            <iframe
                              title="Temp iframe"
                              src="https://www.youtube-nocookie.com/embed/EBdPzv75i3A"
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                          </div>
                        </div>
                      </Accordion.Collapse>

                      <Accordion.Toggle as="h3" eventKey="1" className="h5 mediaccounts-faq-accordion__question">
                        How do I access my monthly reports?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <div className="mediaccounts-faq-accordion__answer">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                          <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>and then lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                        </div>
                      </Accordion.Collapse>

                      <Accordion.Toggle as="h3" eventKey="2" className="h5 mediaccounts-faq-accordion__question">
                        How do I do another thing?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <div className="mediaccounts-faq-accordion__answer">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                          <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>and then lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                        </div>
                      </Accordion.Collapse>

                      <Accordion.Toggle as="h3" eventKey="3" className="h5 mediaccounts-faq-accordion__question">
                        And another common question?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <div className="mediaccounts-faq-accordion__answer">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                          <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li>and then lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                            in culpa qui officia deserunt mollit anim id est laborum.
                          </p>
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HelpPage;
