import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import MASpinner from '../../common/MASpinner';
import { renderField } from '../../../lib/helper/form';
import { workScheduleEventSingleFetch, workScheduleEventSingleUpdate, workScheduleEventSingleUnload } from '../../../lib/redux/actions/workScheduleEvents';
import UserTypeahead from '../../fields/UserTypeahead';
import { getCurrentUserURI, isOutsourcer } from '../../../lib/helper/authorisation';

const mapStateToProps = (state, props) => ({
  initialValues: props?.workScheduleEvent ?? { name: 'Holiday' },
});

const mapDispatchToProps = {
  workScheduleEventSingleFetch,
  workScheduleEventSingleUpdate,
  workScheduleEventSingleUnload,
  addToast,
};

class WorkScheduleEventForm extends React.Component {
  constructor(props) {
    super(props);
    const { workScheduleEvent } = this.props;

    this.state = {
      selectedUser: workScheduleEvent && workScheduleEvent.user ? workScheduleEvent.user : undefined,
      clearTypeaheads: false,
    };
  }

  onSubmit(values) {
    const { workScheduleEventSingleUpdate, workScheduleEvent = undefined, addToast, isCreate = false, onCreate = undefined } = this.props;

    delete values.user;
    if (this.state.selectedUser) {
      values.user = this.state.selectedUser['@id'];
    }
    if (isOutsourcer(true)) {
      values.user = getCurrentUserURI();
    }

    if (isCreate && onCreate) {
      return onCreate(values);
    } else if (workScheduleEvent) {
      return workScheduleEventSingleUpdate(values, workScheduleEvent.id).then(() => {
        addToast('The Holiday was successfully updated', true);
      });
    }
  }

  clearTypeaheads() {
    this.setState(() => {
      return {
        selectedUser: undefined,
        clearTypeaheads: true,
      };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      submitting,
      isUpdatingWorkScheduleEvent,
      isFetchingWorkScheduleEvent,
      hasLoadedWorkScheduleEvent,
      workScheduleEvent,
      title = 'Outsourcer Holiday Details',
      isCreate = false,
    } = this.props;
    if (!hasLoadedWorkScheduleEvent && isFetchingWorkScheduleEvent) {
      return <MASpinner />;
    }

    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-book-medical" />
        </div>
        <h2 className="crud__section-header">{title}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            {!isOutsourcer(true) && (
              <div className="col-sm-12 col-md-2">
                <UserTypeahead
                  name="user"
                  label={'Outsourcer'}
                  defaultFilters={{ roles: '["ROLE_OUTSOURCER"]' }}
                  shouldClear={this.state.clearTypeaheads}
                  hasCleared={this.typeaheadCleared.bind(this)}
                  multiple={false}
                  selectedUsers={this.state.selectedUser ? [this.state.selectedUser] : []}
                  onChange={(selected) => {
                    this.setState(() => {
                      return { selectedUser: selected[0] };
                    });
                  }}
                />
              </div>
            )}
            <div className="col-sm-12 col-md-2">
              <Field name="startDate" label="Start Date" type="date" value={workScheduleEvent ? workScheduleEvent.startDate : ''} component={renderField} />
            </div>
            <div className="col-sm-12 col-md-2">
              <Field name="endDate" label="End Date" type="date" value={workScheduleEvent ? workScheduleEvent.endDate : ''} component={renderField} />
            </div>

            {isUpdatingWorkScheduleEvent ? (
              <MASpinner />
            ) : (
              <div className="col-sm-12 col-md-5 align-self-end">
                <button type="submit" disabled={submitting} className="btn btn-primary mb-3">
                  {isCreate ? 'Create Holiday' : 'Update'}
                </button>
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'WorkScheduleEventForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(WorkScheduleEventForm),
);
