import { Field } from 'redux-form';
import React from 'react';
import { userListFetch, userListUnload } from '../../lib/redux/actions/userList';
import { connect } from 'react-redux';
import { userNameFormatter } from '../../lib/helper/formatting';
import PropTypes from 'prop-types';
import TypeAheadField from '../common/reduxForm/TypeaheadField';

const mapStateToProps = (state) => ({
  ...state.userList,
});
const mapDispatchToProps = {
  userListFetch,
  userListUnload,
};

class UserTypeahead extends React.Component {
  onSearch(query) {
    const { defaultFilters = {} } = this.props;
    const filters = { name: query, ...defaultFilters };
    this.props.userListFetch(1, filters);
  }

  componentDidUpdate() {
    const { shouldClear, hasCleared } = this.props;
    if (shouldClear) {
      this.userRef.getRenderedComponent().clear();
      hasCleared();
    }
  }

  componentWillUnmount() {
    this.props.userListUnload();
  }

  render() {
    const {
      onChange,
      users = [],
      label = 'User',
      name = 'user',
      labelKey = (option) => userNameFormatter(option),
      multiple = false,
      isFetchingUsers,
      defaultUser = false,
      selectedUsers = undefined,
      error,
    } = this.props;

    if (users.length === 0 && defaultUser !== false) {
      users.push(defaultUser);
    }

    return (
      <>
        <Field
          name={name}
          isLoading={isFetchingUsers}
          label={label}
          onSearch={this.onSearch.bind(this)}
          options={users}
          labelKey={labelKey}
          component={TypeAheadField}
          multiple={multiple}
          onChange={onChange}
          ref={(ref) => (this.userRef = ref)}
          selected={selectedUsers}
          forwardRef
        />

        {error && <small className="form-text text-danger">{error}</small>}
      </>
    );
  }
}

UserTypeahead.propTypes = {
  shouldClear: PropTypes.bool.isRequired,
  hasCleared: PropTypes.func.isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  userListFetch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTypeahead);
