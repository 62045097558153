import React from 'react';
import { connect } from 'react-redux';
import WorkScheduleEventSingleCreate from './WorkScheduleEventSingleCreate';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';
import { workScheduleEventSingleCreate, workScheduleEventSingleUnload } from '../../lib/redux/actions/workScheduleEvents';

const mapDispatchToProps = {
  workScheduleEventSingleCreate,
  workScheduleEventSingleUnload,
  addToast,
};

class WorkScheduleEventSingleCreateContainer extends React.Component {
  componentWillUnmount() {
    this.props.workScheduleEventSingleUnload();
  }

  onCreate(data) {
    const { workScheduleEventSingleCreate, addToast, history } = this.props;
    return workScheduleEventSingleCreate(data).then((workScheduleEvent) => {
      addToast('The Work Schedule Event was created successfully', true);
      history.push(ROUTES.WORK_SCHEDULE_EVENTS.SINGLE.replace(':id', workScheduleEvent.id));
    });
  }

  render() {
    return <WorkScheduleEventSingleCreate onCreate={this.onCreate.bind(this)} />;
  }
}

export default connect(null, mapDispatchToProps)(WorkScheduleEventSingleCreateContainer);
