import { INVOICE_ESCALATION_STATUS_LIST_REQUEST, INVOICE_ESCALATION_STATUS_LIST_RECEIVED, INVOICE_ESCALATION_STATUS_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    invoiceEscalationStatuses: [],
    isFetchingInvoiceEscalationStatuses: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_ESCALATION_STATUS_LIST_REQUEST:
      state = {
        ...state,
        isFetchingInvoiceEscalationStatuses: true,
      };
      return state;

    case INVOICE_ESCALATION_STATUS_LIST_RECEIVED:
      state = {
        ...state,
        invoiceEscalationStatuses: action.data['hydra:member'],
        isFetchingInvoiceEscalationStatuses: false,
      };
      return state;

    case INVOICE_ESCALATION_STATUS_LIST_ERROR:
      state = {
        ...state,
        isFetchingInvoiceEscalationStatuses: false,
      };
      return state;

    default:
      return state;
  }
};

export default reducer;
