import { SUB_ACCOUNT_SINGLE_REQUEST, SUB_ACCOUNT_SINGLE_RECEIVED, SUB_ACCOUNT_SINGLE_ERROR } from '../constants';

const reducer = (
  state = {
    subAccount: null,
    error: null,
    isFetchingSubAccount: false,
    isUpdatingSubAccount: false,
  },
  action,
) => {
  switch (action.type) {
    case SUB_ACCOUNT_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingSubAccount: true,
      };

    case SUB_ACCOUNT_SINGLE_RECEIVED:
      return {
        ...state,
        subAccount: action.data,
        isFetchingSubAccount: false,
      };

    case SUB_ACCOUNT_SINGLE_ERROR:
      return {
        ...state,
        error: action.data,
        isFetchingSubAccount: false,
        isUpdatingSubAccount: false,
      };
    default:
      return state;
  }
};

export default reducer;
