import {
  CONSULTANT_SINGLE_REQUEST,
  CONSULTANT_SINGLE_RECEIVED,
  CONSULTANT_SINGLE_ERROR,
  CONSULTANT_SINGLE_UPDATED,
  CONSULTANT_SINGLE_UPDATE_REQUEST,
  CONSULTANT_SUB_ACCOUNT_INVITE_INVITED,
  CONSULTANT_SUB_ACCOUNT_INVITE_REQUEST,
  CONSULTANT_SUB_ACCOUNT_INVITE_ERROR,
  CONSULTANT_SUB_ACCOUNT_INVITE_UPDATED,
  CONSULTANT_SUB_ACCOUNT_INVITE_REMOVED,
  CONSULTANT_SINGLE_CREATE_SUCCESS,
  CONSULTANT_SINGLE_CREATE_REQUEST,
  CONSULTANT_SINGLE_UNLOAD,
  CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_REQUEST,
  CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_SUCCESS,
  CONSULTANT_REGISTRATION_FORM_SUCCESS,
} from '../constants';

const reducer = (
  state = {
    consultant: null,
    error: null,
    subAccountInviteError: null,
    isCreatingConsultant: false,
    isFetchingConsultant: false,
    isUpdatingConsultant: false,
    isUpdatingSubuser: false,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_SINGLE_CREATE_REQUEST:
      return {
        ...state,
        isCreatingConsultant: true,
      };

    case CONSULTANT_SINGLE_CREATE_SUCCESS:
      return {
        ...state,
        consultant: action.data,
        isCreatingConsultant: false,
      };

    case CONSULTANT_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingConsultant: !action.isRefresh,
        isRefreshingConsultant: action.isRefresh,
      };

    case CONSULTANT_SINGLE_RECEIVED:
      return {
        ...state,
        consultant: action.data,
        isFetchingConsultant: false,
        isRefreshingConsultant: false,
      };

    case CONSULTANT_SINGLE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingConsultant: false,
        isUpdatingConsultant: false,
      };

    case CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_REQUEST:
    case CONSULTANT_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingConsultant: true,
      };
      return state;

    case CONSULTANT_SINGLE_IS_ACTIVE_CHANGE_SUCCESS:
    case CONSULTANT_SINGLE_UPDATED:
      // ensure any loaded costs are kept if the same data is not returned.
      if (
        (!action.data.consultantHealthInsurers || action.data.consultantHealthInsurers.length < 1) &&
        state.consultant.consultantHealthInsurers &&
        state.consultant.consultantHealthInsurers.length > 0
      ) {
        action.data.consultantHealthInsurers = state.consultant.consultantHealthInsurers;
      }
      return {
        ...state,
        consultant: action.data,
        isUpdatingConsultant: false,
      };
    case CONSULTANT_SUB_ACCOUNT_INVITE_ERROR:
      return {
        ...state,
        subAccountInviteError: action.error,
        isUpdatingSubuser: false,
      };

    case CONSULTANT_SUB_ACCOUNT_INVITE_REQUEST:
      state = {
        ...state,
        isUpdatingSubuser: true,
      };
      return state;

    case CONSULTANT_REGISTRATION_FORM_SUCCESS: {
      let consultantToUpdate = state.consultant;
      if (consultantToUpdate) {
        consultantToUpdate.registrationForm = action.data.registrationForm;
      }
      return {
        ...state,
        consultant: consultantToUpdate,
        isFetchingConsultant: false,
        isRefreshingConsultant: false,
      };
    }
    case CONSULTANT_SUB_ACCOUNT_INVITE_INVITED:
    case CONSULTANT_SUB_ACCOUNT_INVITE_UPDATED:
    case CONSULTANT_SUB_ACCOUNT_INVITE_REMOVED:
      return {
        ...state,
        consultant: action.data,
        isUpdatingSubuser: false,
      };
    case CONSULTANT_SINGLE_UNLOAD:
      return {
        ...state,
        consultant: null,
        error: null,
        subAccountInviteError: null,
        isCreatingConsultant: false,
        isFetchingConsultant: false,
        isUpdatingConsultant: false,
        isUpdatingSubuser: false,
      };
    default:
      return state;
  }
};

export default reducer;
