import React from 'react';
import UserTypeahead from './UserTypeahead';
import { userNameFormatter } from '../../lib/helper/formatting';

class AssignToUserTypeahead extends React.Component {
  render() {
    const {
      label = 'Assign To User',
      name = 'assignedTo',
      labelKey = (option) => userNameFormatter(option, true),
      rolesToShow = '["ROLE_ADMIN","ROLE_SENIOR_STAFF","ROLE_STAFF","ROLE_OUTSOURCER"]',
      ...rest
    } = this.props;
    // N.B the below filter.roles value is correct - it needs to be a string of an array...
    return <UserTypeahead label={label} labelKey={labelKey} name={name} defaultFilters={{ roles: rolesToShow }} {...rest} />;
  }
}

export default AssignToUserTypeahead;
