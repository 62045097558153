import { EMAIL_AUDIT_LOG_LIST_DATA_RECEIVED, EMAIL_AUDIT_LOG_LIST_ERROR, EMAIL_AUDIT_LOG_LIST_REQUEST, UNLOAD_EMAIL_AUDIT_LOG_LIST } from '../constants';

const reducer = (
  state = {
    emailAuditLogList: [],
    isFetchingEmailAuditLog: false,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case EMAIL_AUDIT_LOG_LIST_REQUEST:
      return {
        ...state,
        isFetchingEmailAuditLog: true,
      };
    case EMAIL_AUDIT_LOG_LIST_DATA_RECEIVED:
      return {
        ...state,
        isFetchingEmailAuditLog: false,
        emailAuditLogList: action.data['hydra:member'],
      };
    case EMAIL_AUDIT_LOG_LIST_ERROR:
      return {
        ...state,
        isFetchingEmailAuditLog: false,
      };
    case UNLOAD_EMAIL_AUDIT_LOG_LIST:
      return {
        ...state,
        isFetchingEmailAuditLog: false,
        emailAuditLogList: [],
      };
    default:
      return { ...state };
  }
};

export default reducer;
