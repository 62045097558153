import React from 'react';
import { connect } from 'react-redux';
import InvoiceImportPaidCSV from './InvoiceImportPaidCSV';
import { fileDelete } from '../../lib/redux/actions/invoiceBatchFiles';

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  ...state.invoiceBatchFiles,
});

const mapDispatchToProps = {
  fileDelete,
};

class InvoiceImportPaidCSVContainer extends React.Component {
  render() {
    const { files, fileDelete, fileRequestInProgress } = this.props;

    return <InvoiceImportPaidCSV files={files} fileDelete={fileDelete} fileRequestInProgress={fileRequestInProgress} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceImportPaidCSVContainer);
