import {
  CHASER_WORK_SCHEDULE_ERROR,
  CHASER_WORK_SCHEDULE_RECEIVED,
  CHASER_WORK_SCHEDULE_REQUEST,
  CHASER_WORK_SCHEDULE_SET_DATE,
  CHASER_WORK_SCHEDULE_SINGLE_RECEIVED,
  CHASER_WORK_SCHEDULE_UNLOAD,
} from '../constants';
import moment from 'moment';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const chaserWorkScheduleRequest = () => ({
  type: CHASER_WORK_SCHEDULE_REQUEST,
});

export const chaserWorkScheduleReceived = (data) => ({
  type: CHASER_WORK_SCHEDULE_RECEIVED,
  data,
});

export const chaserWorkScheduleSingleReceived = (data) => ({
  type: CHASER_WORK_SCHEDULE_SINGLE_RECEIVED,
  data,
});

export const chaserWorkScheduleError = (error) => ({
  type: CHASER_WORK_SCHEDULE_ERROR,
  error,
});

export const chaserWorkScheduleUnload = () => ({
  type: CHASER_WORK_SCHEDULE_UNLOAD,
});

export const chaserWorkScheduleSetDate = (date) => ({
  type: CHASER_WORK_SCHEDULE_SET_DATE,
  date,
});

export const chaserWorkScheduleFetchList = (date = moment().utc().format('DD-MM-YYYY')) => {
  return (dispatch) => {
    dispatch(chaserWorkScheduleRequest());
    return requests
      .get(`${API_ROUTES.CHASER_WORK_SCHEDULE_LIST}`, { date, pagination: false })
      .then((response) => dispatch(chaserWorkScheduleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(chaserWorkScheduleError(error));
      });
  };
};

export const chaserWorkScheduleForUserFetchList = (date = moment().utc().format('DD-MM-YYYY'), user) => {
  return (dispatch) => {
    dispatch(chaserWorkScheduleRequest());
    return requests
      .get(`${API_ROUTES.CHASER_WORK_SCHEDULE_LIST_FOR_USER}`, { date, user, pagination: false })
      .then((response) => dispatch(chaserWorkScheduleSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(chaserWorkScheduleError(error));
      });
  };
};

export const chaserWorkScheduleUpdate = (data) => {
  return (dispatch) => {
    return requests
      .post(`${API_ROUTES.CHASER_WORK_SCHEDULE_UPDATE}`, data)
      .then((response) => dispatch(chaserWorkScheduleSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(chaserWorkScheduleError(error));
      });
  };
};
