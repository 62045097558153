import {
  REPORTS_LIST_REQUEST,
  REPORTS_LIST_RECEIVED,
  REPORTS_LIST_ERROR,
  REPORTS_LIST_SET_PAGE,
  REPORTS_LIST_SET_FILTERS,
  REPORTS_LIST_CLEAR_FILTERS,
  REPORT_SINGLE_DELETE_REQUEST,
  REPORT_SINGLE_DELETE_SUCCESS,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    reports: [],
    isFetchingReports: false,
    currentPage: 1,
    pageCount: null,
    filters: {},
  },
  action,
) => {
  switch (action.type) {
    case REPORTS_LIST_REQUEST:
      return {
        ...state,
        isFetchingReports: true,
      };

    case REPORTS_LIST_RECEIVED:
      return {
        ...state,
        reports: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingReports: false,
      };

    case REPORTS_LIST_ERROR:
      return {
        ...state,
        isFetchingReports: false,
      };

    case REPORTS_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case REPORTS_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case REPORTS_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    case REPORT_SINGLE_DELETE_REQUEST:
      return {
        ...state,
      };

    case REPORT_SINGLE_DELETE_SUCCESS:
      return {
        ...state,
        reports: state.reports.filter((reports) => reports.id !== action.id),
      };
    default:
      return state;
  }
};

export default reducer;
