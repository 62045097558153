import React from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { niceDateFormatter } from '../../../lib/helper/formatting';
import moment from 'moment';
import { addToast } from '../../../lib/redux/actions/toast';

const mapDispatchToProps = { addToast };

class ExportInvoiceNumbersForm extends React.Component {
  onSubmit(values) {
    const { addToast, doExport } = this.props;

    if (!values.from_date) {
      throw new SubmissionError({
        from_date: 'Required',
      });
    } else if (!values.to_date) {
      throw new SubmissionError({
        to_date: 'Required',
      });
    } else if (moment.utc(values.to_date).isBefore(moment.utc(values.from_date))) {
      throw new SubmissionError({
        to_date: 'To date must be after From date',
      });
    }

    return doExport(values, `INVOICE_NUMBERS_${niceDateFormatter(moment.utc(values.from_date))}-${niceDateFormatter(moment.utc(values.to_date))}.csv`).catch(
      () => {
        addToast('There was a problem downloading the file. Please seek support if the issue continues.', false);
      },
    );
  }

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <Field name="from_date" label="From Date" type="date" component={renderField} />
          <Field name="to_date" label="To Date" type="date" component={renderField} />

          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Export
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ExportInvoiceNumbersForm',
})(connect(null, mapDispatchToProps)(ExportInvoiceNumbersForm));
