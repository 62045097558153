import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  CONTACT_RECORD_LIST_REQUEST,
  CONTACT_RECORD_LIST_RECEIVED,
  CONTACT_RECORD_LIST_ERROR,
  CONTACT_RECORD_LIST_SET_PAGE,
  CONTACT_RECORD_LIST_UNLOAD,
  CONTACT_RECORD_SINGLE_UPDATED,
  CONTACT_RECORD_SINGLE_UPDATE_REQUEST,
  CONTACT_RECORD_SINGLE_CREATE_SUCCESS,
  CONTACT_RECORD_SINGLE_CREATE_REQUEST,
  CONTACT_RECORD_SINGLE_REMOVE_REQUEST,
  CONTACT_RECORD_SINGLE_REMOVED,
  CONTACT_RECORD_SINGLE_REMOVE_ERROR,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const contactRecordListRequest = () => ({
  type: CONTACT_RECORD_LIST_REQUEST,
});

export const contactRecordListReceived = (data) => ({
  type: CONTACT_RECORD_LIST_RECEIVED,
  data,
});

export const contactRecordListError = (error) => ({
  type: CONTACT_RECORD_LIST_ERROR,
  error,
});

export const contactRecordListSetPage = (page) => ({
  type: CONTACT_RECORD_LIST_SET_PAGE,
  page,
});

export const contactRecordListUnload = () => ({
  type: CONTACT_RECORD_LIST_UNLOAD,
});

export const contactRecordListFetch = (page = 1, filters = {}, endPoint = API_ROUTES.CONTACT_RECORDS) => {
  return (dispatch) => {
    dispatch(contactRecordListRequest());
    //console.log('Fetching contactRecords ...');
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(endPoint, filters)
      .then((response) => dispatch(contactRecordListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(contactRecordListError(error));
      });
  };
};

export const contactRecordSingleUpdateRequest = () => ({
  type: CONTACT_RECORD_SINGLE_UPDATE_REQUEST,
});

export const contactRecordSingleUpdated = (data) => ({
  type: CONTACT_RECORD_SINGLE_UPDATED,
  data,
});

export const contactRecordSingleUpdate = (data, contactRecordId) => {
  return (dispatch) => {
    dispatch(contactRecordSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.CONTACT_RECORDS}/${contactRecordId}`, data)
      .then((response) => dispatch(contactRecordSingleUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const contactRecordSingleCreateRequest = () => ({
  type: CONTACT_RECORD_SINGLE_CREATE_REQUEST,
});

export const contactRecordSingleCreateSuccess = (data) => ({
  type: CONTACT_RECORD_SINGLE_CREATE_SUCCESS,
  data,
});

//export const contactRecordSingleCreateError = (error) => ({
//  type: CONTACT_RECORD_SINGLE_CREATE_ERROR,
//  error,
//});

export const contactRecordSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(contactRecordSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.CONTACT_RECORDS}`, data)
      .then((response) => {
        dispatch(contactRecordSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        //        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
        //          parsedError._error = 'It looks like you may be creating a contactRecord which already exists - please check the name and try again if not.';
        //        } else {
        //          parsedError._error =
        //            'There was a problem creating the contactRecord - please check the details and try again. If the problem persists please contact support.';
        //        }
        throw new SubmissionError(parsedError);
      });
  };
};

export const contactRecordRemoveRequest = () => ({
  type: CONTACT_RECORD_SINGLE_REMOVE_REQUEST,
});

export const contactRecordRemoved = (data) => ({
  type: CONTACT_RECORD_SINGLE_REMOVED,
  data,
});

export const contactRecordError = (error) => ({
  type: CONTACT_RECORD_SINGLE_REMOVE_ERROR,
  error,
});

export const contactRecordRemove = (contactRecord) => {
  return (dispatch) => {
    dispatch(contactRecordRemoveRequest());
    return requests
      .delete(`${API_ROUTES.CONTACT_RECORDS}/${contactRecord.id}`)
      .then((response) => {
        dispatch(contactRecordRemoved(response));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          dispatch(contactRecordError(parseApiErrors({ _error: 'You are not allowed to do this.' })));
        }
        dispatch(contactRecordError(parseApiErrors(error)));
      });
  };
};
