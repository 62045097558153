import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { consultantHealthInsurerSingleUpdate } from '../../lib/redux/actions/consultantHealthInsurer';
import { Cell } from 'react-sticky-table';
import { isSeniorStaff } from '../../lib/helper/authorisation';

const mapDispatchToProps = {
  consultantHealthInsurerSingleUpdate,
};

class EditableConsultantHealthInsurerLabelCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      fieldValue: this.props.consultantHealthInsurer.overrideLabel,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.consultantHealthInsurer !== this.props.consultantHealthInsurer) {
      this.setState(() => ({
        fieldValue: this.props.consultantHealthInsurer.overrideLabel,
      }));
    }
  }

  handleChange(event) {
    this.setState(() => ({ fieldValue: event.target.value }));
  }

  startEditing() {
    if (!this.props.consultantHealthInsurer.healthInsurer.canHaveOverrideLabel || !isSeniorStaff()) {
      return false;
    }
    this.setState(() => ({ isEditing: true }));
  }

  stopEditing(e) {
    e.stopPropagation();
    this.setState(() => ({ isEditing: false }));
  }

  saveAndStopEditing(e) {
    if (!isSeniorStaff()) {
      return;
    }
    this.props.consultantHealthInsurerSingleUpdate({ overrideLabel: this.state.fieldValue }, this.props.consultantHealthInsurer.id);
    this.props.consultantHealthInsurer.overrideLabel = this.state.fieldValue;
    this.stopEditing(e);
  }

  render() {
    const { consultantHealthInsurer } = this.props;
    const labelToUse =
      consultantHealthInsurer.healthInsurer.canHaveOverrideLabel && consultantHealthInsurer.overrideLabel
        ? consultantHealthInsurer.overrideLabel
        : consultantHealthInsurer.healthInsurer.name;
    return (
      <Cell
        title={this.props.consultantHealthInsurer.healthInsurer.canHaveOverrideLabel ? `Edit label for ${labelToUse}` : ''}
        onClick={this.startEditing.bind(this)}
        className={`sticky-table-cell batch-invoice-ccsd-code-insurer-column border-right ${this.props.consultantHealthInsurer.healthInsurer.canHaveOverrideLabel && 'isOther'}`}>
        {this.state.isEditing && this.props.consultantHealthInsurer.healthInsurer.canHaveOverrideLabel ? (
          <div className="input-group input-group--ccsdCode-inline-edit">
            <input
              autoFocus
              type="text"
              title="'Other' consultant specific label"
              value={this.state.fieldValue}
              onChange={this.handleChange.bind(this)}
              className="form-control"
            />
            <div className="input-group-append">
              <Button variant="primary" size="sm" title="Save Label Change" className="mb-0" onClick={this.saveAndStopEditing.bind(this)}>
                <i className="fas fa-check" />
              </Button>
              <Button variant="danger" size="sm" title="Cancel Label Change" className="mb-0" onClick={this.stopEditing.bind(this)}>
                <i className="fas fa-times" />
              </Button>
            </div>
          </div>
        ) : (
          labelToUse
        )}
      </Cell>
    );
  }
}

export default connect(null, mapDispatchToProps)(EditableConsultantHealthInsurerLabelCell);
