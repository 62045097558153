import React from 'react';
import { niceDateFormatter } from '../../lib/helper/formatting';
import Table from 'react-bootstrap/Table';
import { isAdmin } from '../../lib/helper/authorisation';
import ConfirmButton from '../../components/common/ConfirmButton';

class ReportsListTable extends React.Component {
  doDeleteReport(reportId) {
    const { addToast, deleteReport } = this.props;
    deleteReport(reportId)
      .then(() => {
        addToast('The report was successfully deleted.', true);
      })
      .catch(() => {
        addToast('There was a problem deleting the report. Please seek support if the issue continues.', false);
      });
  }

  doDownload(fileIRI, fileName) {
    const { addToast, downloadFile } = this.props;
    downloadFile(fileIRI, fileName).catch(() => {
      addToast('There was a problem downloading the file. Please seek support if the issue continues.', false);
    });
  }

  render() {
    const { reports = [], tableClasses = 'mb-0', tableSize = '' } = this.props;
    return (
      <Table striped hover responsive size={tableSize} className={tableClasses}>
        <thead>
          <tr>
            <th>Report ID</th>
            <th>Consultant</th>
            <th>Associated Month</th>
            <th>Date Uploaded</th>
            <th>Files</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => {
            const hasReportFiles =
              report && report.reportFiles && report.reportFiles.length > 0 && report.reportFiles[0].filenames && report.reportFiles[0].filenames.length > 0;
            return (
              <tr key={report.id}>
                <td>{report.id}</td>
                <td>
                  <strong>{report.consultant.name}</strong> ({report.consultant.code})
                </td>
                <td>{niceDateFormatter(report.associatedDate, 'MMMM YYYY')}</td>
                <td>{niceDateFormatter(report.createdAt, 'DD/MM/YYYY hh:mm')}</td>
                <td>
                  {hasReportFiles && (
                    <ul className="list-unstyled mb-0">
                      {report.reportFiles[0].filenames.map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))}
                    </ul>
                  )}
                </td>
                <td className="text-right">
                  {hasReportFiles && (
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={this.doDownload.bind(
                        this,
                        report.reportFiles[0].id,
                        `REPORT${report.id}_${report.consultant.code}_${niceDateFormatter(report.associatedDate, 'MM-YYYY')}.zip`,
                      )}>
                      Download
                    </button>
                  )}
                  {isAdmin() && (
                    <ConfirmButton
                      confirmClasses="btn btn-outline-danger btn-sm ml-2"
                      confirmedClasses="btn btn-danger btn-sm ml-2"
                      confirmText={'Delete'}
                      confirmedText={'Are you sure?'}
                      confirmedAction={this.doDeleteReport.bind(this, report.id)}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default ReportsListTable;
