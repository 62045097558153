import {
  WORK_SCHEDULE_WEEKLY_EVENTS_REQUEST,
  WORK_SCHEDULE_WEEKLY_EVENTS_RECEIVED,
  WORK_SCHEDULE_WEEKLY_EVENTS_ERROR,
  WORK_SCHEDULE_WEEKLY_EVENTS_UNLOAD,
  WORK_SCHEDULE_WEEKLY_EVENTS_SET_DATE,
} from '../constants';

const reducer = (
  state = {
    workScheduleWeeklyEvents: [],
    isFetchingChecklistWeeklyEvents: false,
    currentDateWeeklyEvents: undefined,
  },
  action,
) => {
  switch (action.type) {
    case WORK_SCHEDULE_WEEKLY_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingChecklistWeeklyEvents: true,
      };

    case WORK_SCHEDULE_WEEKLY_EVENTS_RECEIVED:
      return {
        ...state,
        workScheduleWeeklyEvents: action.data,
        isFetchingChecklistWeeklyEvents: false,
      };

    case WORK_SCHEDULE_WEEKLY_EVENTS_ERROR:
      return {
        ...state,
        isFetchingChecklistWeeklyEvents: false,
      };

    case WORK_SCHEDULE_WEEKLY_EVENTS_UNLOAD:
      return {
        workScheduleWeeklyEvents: [],
        isFetchingChecklistWeeklyEvents: false,
        currentDateWeeklyEvents: undefined,
      };

    case WORK_SCHEDULE_WEEKLY_EVENTS_SET_DATE:
      return {
        ...state,
        currentDateWeeklyEvents: action.date,
      };

    default:
      return state;
  }
};

export default reducer;
