import { TOAST_ADDED, TOAST_REMOVED } from '../constants';

const reducer = (
  state = {
    toasts: [],
  },
  action,
) => {
  switch (action.type) {
    case TOAST_ADDED:
      return {
        ...state,
        toasts: [...state.toasts, { id: Date.now(), text: action.message, positive: action.isPositive, negative: action.isNegative, embed: action.embed }],
      };
    case TOAST_REMOVED:
      return {
        ...state,
        toasts: state.toasts.filter(function (obj) {
          return obj.id !== action.id;
        }),
      };

    default:
      return state;
  }
};

export default reducer;
