import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileUploader from '../file/FileUploader';
import FileBrowser from '../file/FileBrowser';
import { reduxForm } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { consultantRegistrationFormUpload } from '../../lib/redux/actions/consultant';
import { downloadConsultantPDFFile } from '../../lib/helper/downloadHelper';
import { addToast } from '../../lib/redux/actions/toast';
import { isSeniorStaff } from '../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.ccsdCodeCosts,
});

const mapDispatchToProps = {
  consultantRegistrationFormUpload,
  addToast,
};

class ConsultantRegistrationFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
    };
  }

  onFileAdded(e) {
    if (!e.target || !e.target.files || !e.target.files.length > 0) {
      return;
    }
    const file = e.target.files[0];
    file.id = file.name;
    file.url = file.name;
    this.setState(() => {
      return { file };
    });
  }

  onSubmit() {
    const { consultant, consultantRegistrationFormUpload, addToast } = this.props;

    return consultantRegistrationFormUpload(consultant.id, this.state.file).then(() => {
      this.setState(() => {
        return { file: undefined };
      });
      addToast('File successfully uploaded', true, false);
    });
  }

  render() {
    const { handleSubmit, submitting, consultant } = this.props;
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-book-open" />
        </div>
        <h2 className="crud__section-header">Registration Form</h2>

        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            {isSeniorStaff() && (
              <div className="col">
                <p>Upload the Consultant&apos;s Registration Form below.</p>
                <FileUploader accept={'.pdf'} multiple={false} onFilesAdded={this.onFileAdded.bind(this)} />
                <FileBrowser files={this.state.file ? [this.state.file] : []} />
                <button type="submit" className="btn btn-primary" disabled={!this.state.file || submitting}>
                  Upload
                </button>
                {consultant.registrationForm && (
                  <p className="mt-2">
                    <em>N.B. Uploading a new file will replace the current one.</em>
                  </p>
                )}
              </div>
            )}
            <div className="col">
              {consultant.registrationForm ? (
                <>
                  <p>Download the Consultant&apos;s Registration Form below.</p>
                  <div className={'mt-5 font-weight-bold text-center '}>
                    <i className="fa fa-file-pdf h1 d-block" />
                    <button className="btn btn-info" onClick={() => downloadConsultantPDFFile(consultant.registrationForm['@id'])}>
                      Download Registration Form
                    </button>
                  </div>
                </>
              ) : (
                <p>No Registration Form has been uploaded.</p>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ChooseDateAndFilesForm',
})(connect(mapStateToProps, mapDispatchToProps)(ConsultantRegistrationFormContainer));

ConsultantRegistrationFormContainer.propTypes = {
  consultant: PropTypes.object.isRequired,
};
