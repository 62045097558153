import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { consultantTicketListSetFilters, consultantTicketListClearFilters, consultantTicketListSetPage } from '../../../lib/redux/actions/consultantTickets';
import Form from 'react-bootstrap/Form';
import { isStaff } from '../../../lib/helper/authorisation';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';

const isActionedOptions = [
  { value: 1, text: 'Actioned' },
  { value: 0, text: 'UnActioned' },
];
const mapStateToProps = (state) => {
  return {
    ...state.consultantList,
    initialValues: {
      isActioned: 0,
    },
  };
};
const mapDispatchToProps = {
  consultantTicketListSetPage,
  consultantTicketListSetFilters,
  consultantTicketListClearFilters,
};

class ConsultantTicketTableFilterForm extends React.Component {
  constructor(props) {
    super(props);

    const { initialize, initialValues } = this.props;
    initialize(initialValues);
    this.state = {
      selectedConsultant: undefined,
      clearTypeaheads: false,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    const { consultant = false } = this.props;
    if (Number(values.isActioned) === 1) {
      values['exists[actionedDate]'] = true;
    } else {
      //  } else if (Number(values.isActioned) === 0) {
      values['exists[actionedDate]'] = false;
    }

    if (this.state.selectedConsultant !== undefined) {
      values['consultant'] = this.state.selectedConsultant['@id'];
    }
    if (consultant !== false) {
      values['consultant'] = consultant['@id'];
    }
    this.props.consultantTicketListSetPage(1);
    this.props.consultantTicketListSetFilters(values);
  }

  resetFilters() {
    const { reset, consultantTicketListClearFilters } = this.props;
    consultantTicketListClearFilters();
    this.clearTypeaheads();
    reset();
  }

  render() {
    const { handleSubmit, error, consultant = false } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <Field name="isActioned" label="Actioned" options={isActionedOptions} showEmpty={false} component={renderSelect} />
          {isStaff() && consultant === false && (
            <ConsultantTypeahead
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              multiple={false}
              label={'Consultant'}
              onChange={(selected) => {
                this.setState(() => {
                  return { selectedConsultant: selected[0] };
                });
              }}
            />
          )}
          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Filter
            </button>
            <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
              Reset
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ConsultantTicketTableFilterForm',
})(connect(mapStateToProps, mapDispatchToProps)(ConsultantTicketTableFilterForm));
