import React from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

class TypeAheadField extends React.Component {
  constructor(props) {
    super(props);
    this._typeahead = React.createRef();
  }

  clear() {
    const instance = this._typeahead.current;
    instance.clear();
  }

  render() {
    const {
      input,
      meta,
      label,
      horizontal = false,
      //      notControlled = true,
      ...rest
    } = this.props;
    return (
      <FormGroup className={horizontal ? 'row' : ''}>
        {label !== null && label !== '' && <FormLabel className={horizontal ? 'col-sm-3 col-form-label' : ''}>{label}</FormLabel>}
        {horizontal ? (
          <div className={'col-sm-9'}>
            <AsyncTypeahead
              ref={this._typeahead}
              id="test"
              isLoading
              className={meta.touched && meta.error ? 'alert-danger' : ''}
              {...input}
              {...rest}
              clearButton
              minLength={2}
              //              defaultSelected={notControlled && input.value ? [input.value] : []}
            />
            {meta.touched && meta.error && <small className="form-text text-danger">{meta.error}</small>}
          </div>
        ) : (
          <>
            <AsyncTypeahead
              ref={this._typeahead}
              id="test"
              isLoading
              className={meta.touched && meta.error ? 'alert-danger' : ''}
              {...input}
              {...rest}
              clearButton
              minLength={2}
              //              defaultSelected={notControlled && input.value ? [input.value] : []}
            />
            {meta.touched && meta.error && <small className="form-text text-danger">{meta.error}</small>}
          </>
        )}
      </FormGroup>
    );
  }
}

export default TypeAheadField;
