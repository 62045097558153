import {
  CONSULTANT_BIBLE_LOG_ERROR,
  CONSULTANT_BIBLE_LOG_REQUEST,
  CONSULTANT_BIBLE_LOG_SET_PAGE,
  CONSULTANT_BIBLE_LOG_UNLOAD,
  CONSULTANT_BIBLE_LOG_UPDATED,
} from '../constants';
import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    consultantBibleLog: [],
    currentPage: 1,
    pageCount: null,
    isFetchingConsultantBibleLog: false,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_BIBLE_LOG_REQUEST:
      return {
        ...state,
        isFetchingConsultantBibleLog: true,
      };
    case CONSULTANT_BIBLE_LOG_UPDATED:
      return {
        ...state,
        consultantBibleLog: action.consultantBibleLog['hydra:member'],
        pageCount: hydraPageCount(action.consultantBibleLog),
        isFetchingConsultantBibleLog: false,
      };
    case CONSULTANT_BIBLE_LOG_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case CONSULTANT_BIBLE_LOG_UNLOAD:
      return {
        consultantBibleLog: [],
        currentPage: 1,
        pageCount: null,
        isFetchingConsultantBibleLog: false,
      };
    case CONSULTANT_BIBLE_LOG_ERROR:
      return {
        ...state,
        isFetchingConsultantBibleLog: false,
        consultantBibleLog: null,
      };
    default:
      return state;
  }
};

export default reducer;
