import React, { type ChangeEvent, useState } from 'react';
import FileUploader from '../file/FileUploader';
import type { Invoice } from '../../apiTypes';
import { requests } from '../../lib/helper/agent';
import * as API_ROUTES from '../../lib/routing/api';
import { useDispatch } from 'react-redux';
import { invoiceSingleFetch } from '../../lib/redux/actions/invoices';
import Alert from 'react-bootstrap/Alert';

import Form from 'react-bootstrap/Form';

function InvoicePDFReplaceFileButton({ invoice }: { invoice: Invoice }) {
  const dispatch = useDispatch();
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [file, setFile] = useState<File>();

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.currentTarget.files;
    if (fileList && fileList?.length > 0) {
      setFile(fileList[0]);
      console.log({ file: fileList[0] });
    }
  };

  const doUpload = async (file: File) => {
    await requests.upload(`${API_ROUTES.INVOICE_UPLOAD_INVOICE_PDF.replace(':id', String(invoice.id))}`, file);
    setFile(undefined);
    setShowFileUploader(false);
    dispatch(invoiceSingleFetch(invoice.id));
  };

  return (
    <>
      {showFileUploader && !file ? <FileUploader accept={'.pdf'} onFilesAdded={onFileSelected} requestInProgress={false} /> : null}
      {file && (
        <>
          <Alert variant={'danger text-dark text-center'}>
            <p>Replace current file with: {file.name} </p>
            <strong>Please note there is no automatic checking of the uploaded file.</strong>
            <div className={'my-3'}>
              <button disabled={!confirmed} onClick={() => doUpload(file)} className={'btn btn-primary mb-2'}>
                Upload Replacement
              </button>
              <Form.Check
                checked={confirmed}
                onClick={(e) => setConfirmed(!confirmed)}
                name={'confirm'}
                type={'switch'}
                id={'confirm'}
                label={'I have checked the file'}
              />
            </div>
          </Alert>
        </>
      )}
      <button
        onClick={() => {
          setFile(undefined);
          setShowFileUploader(!showFileUploader);
        }}
        className={'btn btn-outline-danger d-block w-100'}>
        {showFileUploader ? 'Cancel' : invoice.invoicePDFFile ? 'Replace Invoice File' : 'Add Invoice File'}
      </button>
    </>
  );
}

export default InvoicePDFReplaceFileButton;
