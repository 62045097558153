import React, { useCallback, useRef, useState } from 'react';
import * as API_ROUTES from '../../lib/routing/api';
import { debounce } from 'lodash';
import { requests } from '../../lib/helper/agent';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import { userNameFormatter } from '../../lib/helper/formatting';
import Card from 'react-bootstrap/Card';

function Results({ data, clear }) {
  const query = data.q.replace(/%/gi, '');
  return (
    <Card className={'search-results'}>
      <Card.Header>
        <button className={'btn btn-sm float-right btn-info'} onClick={() => clear()}>
          Close
        </button>
        Search Results
      </Card.Header>
      <Card.Body>
        <div className={'row results-row'}>
          {data.results?.invoiceIds?.length > 0 && (
            <div className={'col-4'}>
              <h4>Invoices</h4>
              <ul>
                {data.results.invoiceIds.map((i) => (
                  <li key={i.id}>
                    <Link to={ROUTES.INVOICES.SINGLE.replace(':id', i.id)} onClick={() => clear()} className="text-body text-nowrap">
                      <span className="mr-2">{i.invoiceNo}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {data.results?.invoiceBatchIds?.length > 0 && (
            <div className={'col-4'}>
              <h4>Invoice Batches</h4>
              <ul>
                {data.results.invoiceBatchIds.map((ib) => {
                  const relevantInvoiceNumbers = ib.invoiceNumbers
                    .filter((a) => a.includes(query))
                    .map((a) => ib.invoicePrefix + a)
                    .join(', ');
                  return (
                    <li key={ib.id}>
                      <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', ib.id)} onClick={() => clear()} className="text-body text-nowrap">
                        <span className="mr-2">
                          Batch {ib.id}
                          {relevantInvoiceNumbers && `: (Invoices: ${relevantInvoiceNumbers})`}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {data.results?.consultantIds?.length > 0 && (
            <div className={'col-4'}>
              <h4>Consultants</h4>
              <ul>
                {data.results.consultantIds.map((c) => (
                  <li key={c.id}>
                    <Link to={ROUTES.CONSULTANTS.SINGLE.replace(':id', c.id)} onClick={() => clear()} className="text-body text-nowrap">
                      <span className="mr-2">
                        {c.name} ({c.invoicePrefix})
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {data.results?.userIds?.length > 0 && (
            <div className={'col-4'}>
              <h4>Users</h4>
              <ul>
                {data.results.userIds.map((u) => (
                  <li key={u.id}>
                    <Link to={ROUTES.USERS.SINGLE.replace(':id', u.id)} onClick={() => clear()} className="text-body text-nowrap">
                      <span className="mr-2">{userNameFormatter(u, false, true)}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

const initData = { resultCnt: { total: 0 } };

function Search() {
  const [data, setData] = useState(initData);

  const inputElem = useRef(null);

  const fetchNameResults = (inputVal) => {
    if (inputVal !== '') {
      requests.get(`${API_ROUTES.SEARCH}?q=${inputVal}`).then((response) => {
        setData(response);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((inputVal) => fetchNameResults(inputVal), 500),
    [],
  );

  return (
    <span className={'align-self-center pl-5 d-none d-xl-inline'}>
      <input placeholder={'Search...'} className={'form-control form-control-input'} ref={inputElem} onChange={() => handleSearch(inputElem.current?.value)} />
      {data.resultCnt.total > 0 ? (
        <Results
          data={data}
          clear={() => {
            inputElem.current.value = '';
            setData(initData);
          }}
        />
      ) : null}
    </span>
  );
}

export default Search;
