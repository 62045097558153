import { Field } from 'redux-form';
import React from 'react';
import { relatedToInvoiceBatchUserListFetch } from '../../lib/redux/actions/userList';
import { connect } from 'react-redux';
import { userNameFormatter } from '../../lib/helper/formatting';
import PropTypes from 'prop-types';
import TypeAheadField from '../common/reduxForm/TypeaheadField';

const mapStateToProps = (state) => ({
  ...state.userList,
});
const mapDispatchToProps = {
  relatedToInvoiceBatchUserListFetch,
};

class InvoiceBatchRelatedUserTypeahead extends React.Component {
  onSearch(query) {
    const { defaultFilters = {}, invoiceBatch } = this.props;
    const filters = { nameOrEmail: query, ...defaultFilters };
    this.props.relatedToInvoiceBatchUserListFetch(1, filters, invoiceBatch.id);
  }

  componentDidUpdate() {
    const { shouldClear, hasCleared } = this.props;
    if (shouldClear) {
      this.userRef.getRenderedComponent().clear();
      hasCleared();
    }
  }

  render() {
    const {
      onChange,
      users = [],
      label = 'User',
      name = 'user',
      labelKey = (option) => userNameFormatter(option, false, true),
      multiple = false,
      isFetchingUsers,
      defaultUser = false,
      error,
    } = this.props;

    if (users.length === 0 && defaultUser !== false) {
      users.push(defaultUser);
    }
    return (
      <>
        <Field
          name={name}
          isLoading={isFetchingUsers}
          label={label}
          onSearch={this.onSearch.bind(this)}
          options={users}
          labelKey={labelKey}
          component={TypeAheadField}
          multiple={multiple}
          onChange={onChange}
          ref={(ref) => (this.userRef = ref)}
          forwardRef
        />

        {error && <small className="form-text text-danger">{error}</small>}
      </>
    );
  }
}

InvoiceBatchRelatedUserTypeahead.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  shouldClear: PropTypes.bool.isRequired,
  hasCleared: PropTypes.func.isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  relatedToInvoiceBatchUserListFetch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchRelatedUserTypeahead);
