import React, { Fragment } from 'react';
import Badge from 'react-bootstrap/Badge';
import { isAdmin, isClient, isSeniorStaff, isStaff } from '../../lib/helper/authorisation';
import { niceDateTimeFormatter, userNameFormatter } from '../../lib/helper/formatting';
import * as PropTypes from 'prop-types';
import { NOTE_TYPES } from '../../lib/helper/general';

class InvoiceBatchNote extends React.Component {
  renderNoteBadges(note) {
    if (note?.noteType?.code === NOTE_TYPES.CASE_CLOSED) {
      return (
        <Badge variant={'success'} className="text-uppercase mr-1">
          Embassy - Case Closed
        </Badge>
      );
    }
    if (note?.noteType?.code === NOTE_TYPES.CHASER) {
      return (
        <Badge variant={'warning'} className="text-uppercase mr-1">
          Embassy Case Chased
        </Badge>
      );
    }
  }

  render() {
    const { note, deleteNote } = this.props;
    if (isClient() && note.isPrivate) {
      return null;
    }
    return (
      <div className="invoice-batch-single__note">
        <p className="mb-1">
          {note.isPrivate ? (
            <Badge variant={'light'} className="text-uppercase mr-1">
              Internal Note
            </Badge>
          ) : (
            <Badge variant={'danger'} className="text-uppercase mr-1">
              Public Note
            </Badge>
          )}
          {note.isClient && (
            <Badge variant={'info'} className="text-uppercase mr-1">
              Client Note
            </Badge>
          )}
          {this.renderNoteBadges(note)}
          {((note.isPrivate && isSeniorStaff()) || isAdmin()) && (
            <span
              className="action-icon ml-2"
              onClick={() => {
                deleteNote(note.id);
              }}>
              <span className="sr-only">Delete</span>
              <i className="fas fa-trash" title="Delete note" />
            </span>
          )}
        </p>
        <p className="invoice-batch-single__note-heading">
          {niceDateTimeFormatter(note.createdAt)} - <strong>{userNameFormatter(note.createdBy)}</strong> said:
        </p>
        <p className="invoice-batch-single__note-content">
          {note.note.split('\n').map((item, key) => {
            return (
              <Fragment key={key}>
                {item}
                <br />
              </Fragment>
            );
          })}
        </p>
        {note?.sentToUsersString && isStaff() ? <p className="font-italic">This note was sent to: {note.sentToUsersString}</p> : null}
      </div>
    );
  }
}

InvoiceBatchNote.propTypes = {
  note: PropTypes.object.isRequired,
  deleteNote: PropTypes.func,
};

export default InvoiceBatchNote;
