import { CONSULTANT_TICKET_TYPE_LIST_REQUEST, CONSULTANT_TICKET_TYPE_LIST_RECEIVED, CONSULTANT_TICKET_TYPE_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    consultantTicketTypes: [],
    isFetchingConsultantTicketTypes: false,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_TICKET_TYPE_LIST_REQUEST:
      return {
        ...state,
        isFetchingConsultantTicketTypes: true,
      };

    case CONSULTANT_TICKET_TYPE_LIST_RECEIVED:
      return {
        ...state,
        consultantTicketTypes: action.data['hydra:member'],
        isFetchingConsultantTicketTypes: false,
      };

    case CONSULTANT_TICKET_TYPE_LIST_ERROR:
      return {
        ...state,
        isFetchingConsultantTicketTypes: false,
      };
    default:
      return state;
  }
};

export default reducer;
