import React from 'react';
import { connect } from 'react-redux';
import MASpinner from '../common/MASpinner';
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import * as ROUTES from '../../lib/routing/frontend';
import WeeklyCalendarContainer from '../common/WeeklyCalendarContainer';
import { isChaserOutsourcer } from '../../lib/helper/authorisation';
import qs from 'qs';
import ChaserWorkScheduleTable from './ChaserWorkScheduleTable';
import { userListFetch } from '../../lib/redux/actions/userList';
import { userSingleFetch } from '../../lib/redux/actions/user';
import { chaserWorkScheduleFetchList, chaserWorkScheduleSetDate, chaserWorkScheduleUnload } from '../../lib/redux/actions/chaserWorkSchedule';

const moment = extendMoment(Moment);

const mapStateToProps = (state) => ({
  ...state.userList,
  ...state.userSingle,
  ...state.chaserWorkSchedule,
});

const mapDispatchToProps = {
  chaserWorkScheduleSetDate,
  userListFetch,
  userSingleFetch,
  chaserWorkScheduleFetchList,
  chaserWorkScheduleUnload,
};

class ChaseWorkSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredConsultants: [],
    };
  }

  changePage(date) {
    const { history, chaserWorkScheduleSetDate, useQueryString = false, paginationRoute = ROUTES.CHASER_WORK_SCHEDULE } = this.props;
    chaserWorkScheduleSetDate(date);
    if (useQueryString) {
      history.push(paginationRoute + '?date=' + date);
    } else {
      history.push(paginationRoute.replace(':date', date));
    }
  }

  getQueryParamDate() {
    const { useQueryString = false } = this.props;
    if (useQueryString) {
      const qsDate = qs.parse(this.props.location.search.replace('?', ''));
      return qsDate['date'] || moment().utc().format('DD-MM-YYYY');
    }

    return this.props.match.params.date || moment().utc().format('DD-MM-YYYY');
  }

  componentDidMount() {
    const { chaserWorkScheduleFetchList, userListFetch, userSingleFetch } = this.props;
    if (isChaserOutsourcer(true)) {
      userSingleFetch(window.localStorage.getItem('userId'));
    } else {
      userListFetch(1, { pagination: false, roles: '["ROLE_CHASER_OUTSOURCER"]' });
    }
    chaserWorkScheduleFetchList(this.getQueryParamDate());
  }

  componentDidUpdate(prevProps) {
    const { currentDate, chaserWorkScheduleFetchList, chaserWorkScheduleSetDate } = this.props;
    if (prevProps.match.params.date !== this.getQueryParamDate()) {
      chaserWorkScheduleSetDate(this.getQueryParamDate());
    }

    if (prevProps.currentDate !== currentDate) {
      chaserWorkScheduleFetchList(currentDate);
    }
  }

  componentWillUnmount() {
    const { chaserWorkScheduleUnload } = this.props;

    chaserWorkScheduleUnload();
  }

  render() {
    const { isFetchingWorkSchedule, workSchedules, users, user } = this.props;
    if (isChaserOutsourcer(true)) {
      return (
        <WeeklyCalendarContainer
          title={'Chaser Work Schedule'}
          changePage={this.changePage.bind(this)}
          match={this.props.match}
          isFetching={isFetchingWorkSchedule}>
          {isFetchingWorkSchedule ? (
            <MASpinner wrapperClasses="mt-3 mb-3" />
          ) : (
            <ChaserWorkScheduleTable currentDate={this.getQueryParamDate()} users={[user]} workSchedules={workSchedules} />
          )}
        </WeeklyCalendarContainer>
      );
    }

    return (
      <WeeklyCalendarContainer
        title={'Chaser Work Schedule'}
        changePage={this.changePage.bind(this)}
        match={this.props.match}
        isFetching={isFetchingWorkSchedule}>
        {isFetchingWorkSchedule ? (
          <MASpinner wrapperClasses="mt-3 mb-3" />
        ) : (
          <ChaserWorkScheduleTable currentDate={this.getQueryParamDate()} users={users} workSchedules={workSchedules} />
        )}
      </WeeklyCalendarContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChaseWorkSchedule);
