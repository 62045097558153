import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import UserDetailsForm from '../../components/forms/User/UserDetailsForm';
import ChangePasswordForm from '../../components/forms/Auth/ChangePasswordForm';

class Profile extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Edit your Profile</Card.Header>
              <Card.Body>
                <Row>
                  {/* Left Column - START */}
                  <Col sm={12} md={12} lg={8}>
                    <UserDetailsForm user={user} title={'Your Details'} extended={false} />
                  </Col>
                  {/*  Left Col - END */}
                  <Col sm={12} md={12} lg={4}>
                    {/* View List of links to edit Related Consultant Accounts, shown if User Role of the User record is Staff, Outsourcer or Sub User only - Start */}
                    <div className="crud__section mb-3">
                      <div className="crud__section-icon">
                        <i className="fas fa-lock" />
                      </div>
                      <h2 className="crud__section-header">Your Password</h2>
                      <ChangePasswordForm />
                      {/*  Anything else re: 2FA in here, retitled as Security TBD*/}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Profile;
