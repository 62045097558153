import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { renderField } from '../../lib/helper/form';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { generatePaymentLink } from '../../lib/redux/actions/paymentLink';
import { useHistory, useParams } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    ...state.invoice,
    ...state.paymentLink,
  };
};

const mapDispatchToProps = {
  generatePaymentLink,
};

function BillingDetailsForm({ invoice, handleSubmit, generatePaymentLink, paymentLink }) {
  const { uniqueRef } = useParams();
  const history = useHistory();
  const onSubmit = async (formValues) => {
    if (!invoice) {
      throw new Error('Failed to find invoice');
    }
    try {
      return generatePaymentLink(invoice.id, formValues).then(() => {
        history.push(`/pay-an-invoice/${uniqueRef}`, {
          paymentLink: paymentLink,
          invoice: invoice,
        });
      });
    } catch (error) {
      console.error('Error fetching invoice:', error);
    }
  };

  return (
    <Card className="billing-details mt-4">
      <Card.Header as={'h2'}>Please enter your billing address</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className={'text-left'}>
          <Field horizontal={true} name="address1" label="Address Line 1" type="text" component={renderField} required={true} />
          <Field horizontal={true} name="address2" label="Address Line 2" type="text" component={renderField} />
          <Field horizontal={true} name="address3" label="Address Line 3" type="text" component={renderField} />
          <Field horizontal={true} name="city" label="City" type="text" component={renderField} />
          <Field horizontal={true} name="county" label="County" type="text" component={renderField} />
          <Field horizontal={true} name="postalCode" label="Postcode" type="text" component={renderField} required={true} />
          <Field horizontal={true} name="email" label="Email for Receipt" type="email" component={renderField} required={true} />
          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              Make Payment
            </button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'BillingDetailsForm',
    enableReinitialize: true,
  })(BillingDetailsForm),
);
