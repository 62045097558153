import React from 'react';
import Card from 'react-bootstrap/Card';
import MASpinner from './MASpinner';
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const moment = extendMoment(Moment);

class WeeklyCalendarContainer extends React.Component {
  nextWeek() {
    const { changePage } = this.props;
    const nextDate = moment(this.getQueryParamDate(), 'DD-MM-YYYY').add(7, 'day').format('DD-MM-YYYY');
    changePage(nextDate);
  }

  prevWeek() {
    const { changePage } = this.props;
    const nextDate = moment(this.getQueryParamDate(), 'DD-MM-YYYY').subtract(7, 'day').format('DD-MM-YYYY');
    changePage(nextDate);
  }

  getQueryParamDate() {
    const { useQueryString = false } = this.props;
    if (useQueryString) {
      const query = new URLSearchParams(this?.props?.location?.search);
      return query.get('date') || moment().utc().format('DD-MM-YYYY');
    }
    return this.props.match.params.date || moment().utc().format('DD-MM-YYYY');
  }

  render() {
    const { title, isFetching } = this.props;

    return (
      <Card className="mb-4 mediaccounts-billing-checklist__card">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div>
              <span className="mediaccounts-billing-checklist__page-title mr-4">{title}</span>
              <div className="btn-group btn-group--pagination d-none d-sm-inline-flex" role="group">
                <button
                  disabled={isFetching}
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  title="Previous week"
                  onClick={this.prevWeek.bind(this)}>
                  &larr;
                </button>
                <button disabled={isFetching} type="button" className="btn btn-outline-secondary btn-sm" title="Next week" onClick={this.nextWeek.bind(this)}>
                  &rarr;
                </button>
              </div>
            </div>

            {isFetching ? (
              <MASpinner wrapperClasses="" />
            ) : (
              <span className="mediaccounts-billing-checklist__week-title d-none d-sm-inline-flex">
                Week Start {moment(this.getQueryParamDate(), 'DD-MM-YYYY').startOf('isoWeek').isoWeekday(1).format('DD-MM-YYYY')}
              </span>
            )}
            {/*{!isClient() && !isOutsourcer() &&  <div className="d-none d-sm-inline-flex">*/}
            {/*  <FormControl*/}
            {/*      size={'sm'}*/}
            {/*      placeholder="Filter by account..."*/}
            {/*      aria-label="Type to filter by account"*/}
            {/*      onChange={this.setConsultantFilter.bind(this)}*/}
            {/*  />*/}
            {/*</div>}*/}
          </div>
        </Card.Header>
        {this.props.children}
      </Card>
    );
  }
}

export default withRouter(connect()(WeeklyCalendarContainer));
