import React, { type ChangeEvent } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import FileUploader from '../file/FileUploader';

// Set the worker source for PDF.js library
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

type Props = {
  setExtractedText: (text: string) => void;
  setFile: (file: any) => void;
  setRawFile: (file: any) => void;
};

export default function PDFTextExtractor({ setExtractedText, setFile, setRawFile }: Props) {
  // Asynchronous function to extract text from the PDF
  async function extractText(url: string | ArrayBuffer) {
    const allText: string[] = []; // Array to store all extracted text
    try {
      const pdf = await pdfjsLib.getDocument(url).promise;
      // setFile(pdf);
      const pages = pdf.numPages; // Get the total number of pages in the PDF

      for (let i = 1; i <= pages; i++) {
        const page = await pdf.getPage(i); // Get the page object for each page
        const txt = await page.getTextContent(); // Get the text content of the page
        txt.items.forEach((s) => {
          if ('str' in s) {
            // console.log(s.str);
            if (s.str.trim().length > 0) {
              allText.push(s.str.trim());
            }
          }
        }); // Concatenate the text items into a single string
        // alltext.push(text); // Add the extracted text to the array
      }
      setExtractedText(allText.join('||'));
    } catch (e) {
      if (typeof e === 'string') {
        console.log('Text Extract Error: ', e.toUpperCase());
      } else if (e instanceof Error) {
        console.log('Text Extract Error: ', e.message);
      }
    }
  }

  function readFileAsync(file: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  // Execute when user select a file
  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.currentTarget.files;
    if (fileList && fileList?.length > 0) {
      setRawFile(fileList[0]);
      setFile(window.URL.createObjectURL(fileList[0]));
      // setFile(fileList[0] || null);
      const pdfArrayBuffer = await readFileAsync(fileList[0]);
      if (pdfArrayBuffer) {
        // console.log({pdfArrayBuffer});
        void extractText(pdfArrayBuffer);
      }
    }
  };

  return <FileUploader accept={'.pdf'} onFilesAdded={onFileSelected} requestInProgress={false} />;
}
