import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';
import MASpinner from '../../common/MASpinner';
import { problemStatusesFetch } from '../../../lib/redux/actions/problemStatus';
import { problemTypesFetch } from '../../../lib/redux/actions/problemTypes';
import { problemDataListClearFilters, problemDataListFetch, problemDataListSetFilters } from '../../../lib/redux/actions/problemData';
import { healthInsurerListFetch } from '../../../lib/redux/actions/healthInsurer';

const mapStateToProps = (state) => ({
  ...state.problemDataList,
  ...state.problemStatuses,
  ...state.problemTypes,
  ...state.healthInsurer,
});

const mapDispatchToProps = {
  problemStatusesFetch,
  problemTypesFetch,
  problemDataListSetFilters,
  problemDataListClearFilters,
  problemDataListFetch,
  healthInsurerListFetch,
};

class ProblemDataTableFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConsultants: [],
      clearTypeaheads: false,
    };
  }

  componentDidMount() {
    const { problemStatusesFetch, problemTypesFetch, healthInsurerListFetch } = this.props;
    problemStatusesFetch();
    problemTypesFetch();
    healthInsurerListFetch({}, { pagination: false });
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    if (this.state.selectedConsultants.length) {
      values['invoiceBatch.consultant'] = this.state.selectedConsultants.map((c) => c['@id']);
    }
    if (values['invoiceBatch']) {
      values['invoiceBatch.id'] = '/invoice_batches/' + values['invoiceBatch'];
      delete values['invoiceBatch'];
    }
    if (values['problemType']) {
      values['problemType.code'] = values['problemType'];
      delete values['problemType'];
    }
    if (values['invoiceNumbers'] && values['invoiceNumbers'].length) {
      values['invoiceNumbers'] = `${values['invoiceNumbers']}`;
    }
    this.props.problemDataListSetFilters(values);
  }

  resetFilters() {
    const { reset, problemDataListClearFilters } = this.props;
    problemDataListClearFilters();
    this.clearTypeaheads();
    reset();
  }

  render() {
    const { handleSubmit, error, problemTypes = {}, healthInsurers = {} } = this.props;

    if (!problemTypes && !healthInsurers) {
      return <MASpinner />;
    }

    const problemTypesOptions = [];
    problemTypes.forEach((problemType) => {
      problemTypesOptions.push({
        text: problemType.name,
        value: problemType.code,
      });
    });
    const healthInsurersOptions = [];
    healthInsurers.forEach((healthInsurer) => {
      healthInsurersOptions.push({
        text: healthInsurer.name,
        value: healthInsurer['@id'],
      });
    });

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters col">
          <div className="row">
            <Field name="invoiceBatch" label="Invoice Batch ID" type="number" component={renderField} />
            <ConsultantTypeahead
              label="Consultant"
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              onChange={(selected) => {
                this.setState(() => {
                  return { selectedConsultants: selected };
                });
              }}
            />
            <Field name="invoiceNumbers" label="Related Invoice Number" type="text" component={renderField} />
            <Field name="problemType" label="Type" type="select" options={problemTypesOptions} component={renderSelect} />
            <Field name="insurer" label="Health Insurer" type="select" options={healthInsurersOptions} component={renderSelect} />

            <div className="filter-actions">
              <button type="submit" className="btn btn-primary btn-sm">
                Filter
              </button>
              <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
                Reset
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ProblemDataTableFilterForm',
})(connect(mapStateToProps, mapDispatchToProps)(ProblemDataTableFilterForm));
