import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { userChangePassword } from '../../../lib/redux/actions/user';
import Form from 'react-bootstrap/Form';
import * as qs from 'qs';
import { addToast } from '../../../lib/redux/actions/toast';

const mapDispatchToProps = {
  userChangePassword,
  addToast,
};

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    const queryParams = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : {};
    if (props.user && props.user.email) {
      queryParams.email = props.user.email;
    }
    this.state = {
      showPass: false,
    };

    props.initialize(queryParams);
  }

  toggleShow() {
    this.setState((prevState) => {
      return { showPass: !prevState.showPass };
    });
  }

  onSubmit(values) {
    const { addToast, userChangePassword, reset } = this.props;
    return userChangePassword(values.newPassword, values.retypedNewPassword).then(() => {
      addToast('Your password was changed successfully.', true);
      reset();
    });
  }

  render() {
    const { handleSubmit, error } = this.props;
    return (
      <>
        <p>Please enter and confirm your new password.</p>

        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Field name="newPassword" label="New Password" type={this.state.showPass ? 'text' : 'password'} component={renderField} />
          <Field name="retypedNewPassword" label="Confirm Password" type={this.state.showPass ? 'text' : 'password'} component={renderField} />

          <button type="submit" className="btn btn-outline-primary btn-big btn-block">
            Update Password
          </button>
        </Form>
        <p className="mb-0 mt-3 text-center">
          <button type="button" className="btn btn-sm btn-link" onClick={this.toggleShow.bind(this)}>
            {this.state.showPass ? 'Hide' : 'Reveal'}
          </button>
        </p>
      </>
    );
  }
}

export default reduxForm({
  form: 'ChangePasswordForm',
})(connect(null, mapDispatchToProps)(ChangePasswordForm));
