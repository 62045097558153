import {
  INVOICE_DATE_ADD_REQUEST,
  INVOICE_DATE_ADDED,
  INVOICE_DATE_DELETE_REQUEST,
  INVOICE_DATE_DELETED,
  INVOICE_DATE_UPDATE_REQUEST,
  INVOICE_DATE_UPDATED,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceDateAddRequest = () => ({
  type: INVOICE_DATE_ADD_REQUEST,
});

export const invoiceDateAdded = (data) => ({
  type: INVOICE_DATE_ADDED,
  data,
});

export const invoiceDateAdd = (data) => {
  return (dispatch) => {
    dispatch(invoiceDateAddRequest());
    return requests
      .post(`${API_ROUTES.INVOICE_DATE}`, data)
      .then((response) => dispatch(invoiceDateAdded(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceDateUpdateRequest = () => ({
  type: INVOICE_DATE_UPDATE_REQUEST,
});

export const invoiceDateUpdated = (data) => ({
  type: INVOICE_DATE_UPDATED,
  data,
});

export const invoiceDateUpdate = (invoiceDateId, data) => {
  return (dispatch) => {
    dispatch(invoiceDateUpdateRequest());
    return requests
      .patch(`${API_ROUTES.INVOICE_DATE}/${invoiceDateId}`, data)
      .then((response) => dispatch(invoiceDateUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const invoiceDateDeleteRequest = () => ({
  type: INVOICE_DATE_DELETE_REQUEST,
});

export const invoiceDateDeleted = (id) => ({
  type: INVOICE_DATE_DELETED,
  id,
});

export const invoiceDateDelete = (invoiceDateId) => {
  return (dispatch) => {
    dispatch(invoiceDateDeleteRequest());
    return requests
      .delete(`${API_ROUTES.INVOICE_DATE}/${invoiceDateId}`)
      .then(() => dispatch(invoiceDateDeleted(invoiceDateId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

//export const invoiceBatchNotesRequest = () => ({
//  type: INVOICE_BATCH_NOTES_REQUEST,
//});
//
//export const invoiceBatchNotesReceived = (data, invoiceBatchId) => ({
//  type: INVOICE_BATCH_NOTES_RECEIVED,
//  data,
//  invoiceBatchId,
//});
//
//export const invoiceBatchNotesError = (error) => ({
//  type: INVOICE_BATCH_NOTES_ERROR,
//  error,
//});
//
//export const invoiceBatchNotesFetch = (invoiceBatchId) => {
//  return (dispatch) => {
//    dispatch(invoiceBatchNotesRequest());
//    //console.log('Fetching notes for invoice batch ...');
//
//    return requests.get(`${API_ROUTES.INVOICE_BATCH_NOTES.replace(':id', invoiceBatchId)}`, { pagination: false })
//        .then(response => dispatch(invoiceBatchNotesReceived(response, invoiceBatchId)))
//        .catch(error => {
//          if (error.response && error.response.status === 401) {
//            return dispatch(userLogout());
//          } else if (error.response && error.response.status === 403) {
//            return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
//          }
//          dispatch(invoiceBatchNotesError(parseApiErrors(error)));
//        });
//  };
//};
//
