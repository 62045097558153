import Form from 'react-bootstrap/Form';
import React from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';

class ConfirmDateAndFilesForm extends React.Component {
  onSubmit() {
    const { fileToUpload, reportBatchCreate } = this.props;

    return reportBatchCreate(fileToUpload);
  }

  render() {
    const { isComplete = false, associatedDate, fileToUpload, handleSubmit, error } = this.props;
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <span className="font-weight-bolder">2</span>
        </div>
        {isComplete ? (
          <h2 className="crud__section-header mb-0 d-flex justify-content-between">
            Confirm file to process
            <i className="fa fa-check-circle text-primary" />
          </h2>
        ) : (
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            {error && <div className="alert alert-danger">{error}</div>}
            <h2 className="crud__section-header">Confirm file to process</h2>
            <div className="mb-3 p-3 bg-light">
              <dl className="row">
                <dt className="col-sm-3">Month selected</dt>
                <dd className="col-sm-9">{moment(associatedDate).format('MMMM YYYY')}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-3">File uploaded</dt>
                <dd className="col-sm-9">{fileToUpload.name}</dd>
              </dl>
            </div>
            <button type="submit" className="btn btn-primary">
              Continue
            </button>
          </Form>
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: 'ConfirmDateAndFilesForm',
})(ConfirmDateAndFilesForm);
