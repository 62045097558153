import { CONSULTANT_HEALTH_INSURER_SINGLE_UPDATE_REQUEST, CONSULTANT_HEALTH_INSURER_SINGLE_UPDATED } from '../constants';
import * as API_ROUTES from '../../routing/api';
import { requests } from '../../helper/agent';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantHealthInsurerSingleUpdateRequest = () => ({
  type: CONSULTANT_HEALTH_INSURER_SINGLE_UPDATE_REQUEST,
});

export const consultantHealthInsurerSingleUpdated = (data) => ({
  type: CONSULTANT_HEALTH_INSURER_SINGLE_UPDATED,
  data,
});

export const consultantHealthInsurerSingleUpdate = (data, consultantHealthInsurerId) => {
  return (dispatch) => {
    dispatch(consultantHealthInsurerSingleUpdateRequest());
    let endpointURL = `${API_ROUTES.CONSULTANT_HEALTH_INSURERS}/${consultantHealthInsurerId}`;
    return requests
      .patch(endpointURL, data)
      .then((response) => {
        dispatch(consultantHealthInsurerSingleUpdated(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
