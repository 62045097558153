import PropTypes from 'prop-types';
import React from 'react';
import Table from 'react-bootstrap/Table';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import { userNameFormatter } from '../../lib/helper/formatting';

class ConsultantListTable extends React.Component {
  render() {
    const { consultants = [] } = this.props;

    return (
      <div>
        <Table striped hover responsive className="mb-0">
          <thead>
            <tr>
              <th>Account Code</th>
              <th>Consultant Type</th>
              <th>Account Name</th>
              <th>Primary User</th>
              <th className="text-center">Status</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {consultants.map((consultant) => {
              return (
                <tr key={consultant.id}>
                  <td>
                    <Link to={ROUTES.CONSULTANTS.SINGLE.replace(':id', consultant.id)} className="text-body">
                      {consultant.code}
                    </Link>
                  </td>
                  <td>
                    <strong>{consultant.consultantType.displayName}</strong>
                  </td>
                  <td>
                    <strong>{consultant.name}</strong>
                  </td>
                  <td>
                    <strong>{consultant.primaryUser && userNameFormatter(consultant.primaryUser)}</strong> -{' '}
                    {consultant.primaryUser && consultant.primaryUser.email}
                  </td>
                  <td className="text-center">
                    {consultant.isActive ? (
                      <i className="fa fa-check-circle text-primary" title="Active" />
                    ) : (
                      <i className="fa fa-times-circle text-danger" title="Inactive" />
                    )}
                  </td>
                  <td className="text-sm-left text-md-right">
                    <Link to={ROUTES.CONSULTANTS.SINGLE.replace(':id', consultant.id)} className="btn btn-sm btn-primary ml-2">
                      Edit
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ConsultantListTable;

ConsultantListTable.propTypes = {
  consultants: PropTypes.array.isRequired,
};
