import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField, renderSelect } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { reportsListClearFilters, reportsListSetFilters } from '../../../lib/redux/actions/reports';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { isStaff } from '../../../lib/helper/authorisation';

const moment = extendMoment(Moment);

const mapDispatchToProps = {
  reportsListSetFilters,
  reportsListClearFilters,
};

class ReportsTableFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConsultant: undefined,
      monthOptions: [],
      clearTypeaheads: false,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  componentDidMount() {
    this.setMonthOptions();
  }

  setMonthOptions() {
    const monthOptions = [];
    const startDate = moment().utc().date(1).month(0).year(2018).hour(0).minute(0).second(0);
    const endDate = moment().utc().date(1).hour(0).minute(0).second(0).subtract(1, 'months');
    const range = moment.range(startDate, endDate);

    for (let month of range.by('month')) {
      monthOptions.push({ value: month.utc().format('YYYY-MM-DD'), text: month.utc().format('MMMM YYYY') });
    }
    monthOptions.reverse();
    this.setState(() => {
      return { monthOptions };
    });
  }

  onSubmit(values) {
    if (this.state.selectedConsultant !== undefined) {
      values['consultant'] = this.state.selectedConsultant['@id'];
    }
    this.props.reportsListSetFilters(values);
  }

  resetFilters() {
    const { reset, reportsListClearFilters } = this.props;
    reportsListClearFilters();
    this.clearTypeaheads();
    reset();
  }

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
          <Field name="createdAt(after)" label="From Date" type="date" component={renderField} />
          <Field name="createdAt(before)" label="To Date" type="date" component={renderField} />

          {isStaff() && (
            <ConsultantTypeahead
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              multiple={false}
              label={'Consultant'}
              onChange={(selected) => {
                this.setState(() => {
                  return { selectedConsultant: selected[0] };
                });
              }}
            />
          )}

          <Field id="associatedMonth" name="associatedDate" emptyLabel="" label="Associated Month" options={this.state.monthOptions} component={renderSelect} />

          <div className="filter-actions">
            <button type="submit" className="btn btn-primary btn-sm">
              Filter
            </button>
            <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
              Reset
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ReportsTableFilterForm',
})(connect(null, mapDispatchToProps)(ReportsTableFilterForm));
