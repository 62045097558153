import React from 'react';
import { connect } from 'react-redux';
import MASpinner from '../../components/common/MASpinner';
import { subAccountSingleFetch } from '../../lib/redux/actions/subAccountInvite';
import SubAccountAccept from './SubAccountAccept';
import 'url-search-params-polyfill';
import * as ROUTES from '../../lib/routing/frontend';

const mapStateToProps = (state) => ({
  ...state.subAccountInviteSingle,
});

const mapDispatchToProps = {
  subAccountSingleFetch,
};

class SubAccountAcceptContainer extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      id: query.get('id'),
      code: query.get('code'),
      redirectLogin: false,
    };
  }

  componentDidMount() {
    this.props.subAccountSingleFetch(this.state.id, this.state.code).then((r) => {
      if (r && r.status === 403) {
        this.setState(() => ({ redirectLogin: true }));
      }
    });
  }

  componentDidUpdate() {
    if (this.state.redirectLogin) {
      this.props.history.push(ROUTES.LOGIN);
    }
  }

  render() {
    const { subAccount, isFetchingSubAccount, history } = this.props;
    if (!subAccount || isFetchingSubAccount) {
      return <MASpinner />;
    }
    return <SubAccountAccept history={history} id={this.state.id} code={this.state.code} subAccount={subAccount} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountAcceptContainer);
