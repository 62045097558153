import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import WorkSchedule from '../../components/chaserWorkSchedule/ChaseWorkSchedule';

class ChaserWorkScheduleContainer extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <WorkSchedule match={this.props.match} history={this.props.history} />
        </Col>
      </Row>
    );
  }
}

export default ChaserWorkScheduleContainer;
