import React from 'react';
import PropTypes from 'prop-types';
import { isClient, isStaff } from '../../lib/helper/authorisation';
import { isCancelled, isCompleted } from '../../lib/helper/invoiceBatch';
import CancelButton from '../common/CancelButton';
import AcceptButton from '../common/AcceptButton';
import CompleteButton from '../common/CompleteButton';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  userId: Number(state.auth.userId),
});

class ActionButtons extends React.Component {
  getButtons() {
    const { invoiceBatch } = this.props;
    return (
      <>
        <CompleteButton invoiceBatch={invoiceBatch} />
        <AcceptButton invoiceBatch={invoiceBatch} />
        <CancelButton invoiceBatch={invoiceBatch} />
      </>
    );
  }

  render() {
    const { invoiceBatch, userId } = this.props;
    if (!invoiceBatch || isClient() || isCancelled(invoiceBatch) || isCompleted(invoiceBatch)) {
      return null;
    }

    /* If Staff User, only show section if assigned to me (otherwise there are no actions to take) - so I can Accept and (Complete) */
    if (isStaff(true) && (!invoiceBatch.assignedUser || Number(invoiceBatch.assignedUser.id) !== Number(userId))) {
      return null;
    }

    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-cog" />
        </div>

        <h2 className="invoice-batch-single__section-header">Actions</h2>
        <div className="actions-button-wrap">{this.getButtons()}</div>
      </div>
    );
  }
}

ActionButtons.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(ActionButtons);
