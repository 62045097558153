import {
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_REQUEST,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_RECEIVED,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_ERROR,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_UNLOAD,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_SET_DATE,
} from '../constants';

import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';
import moment from 'moment';

export const billingChecklistWeeklyEventsRequest = () => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_REQUEST,
});

export const billingChecklistWeeklyEventsReceived = (data) => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_RECEIVED,
  data,
});

export const billingChecklistWeeklyEventsError = (error) => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_ERROR,
  error,
});

export const billingChecklistWeeklyEventsUnload = () => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_UNLOAD,
});

export const billingChecklistWeeklyEventsSetDate = (date) => ({
  type: INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_SET_DATE,
  date,
});

export const billingChecklistWeeklyEventsFetch = (date = moment().utc().format('DD-MM-YYYY')) => {
  return (dispatch) => {
    dispatch(billingChecklistWeeklyEventsRequest());
    return requests
      .get(`${API_ROUTES.BILLING_CHECKLIST_WEEKLY_EVENTS}`, { date, pagination: false })
      .then((response) => dispatch(billingChecklistWeeklyEventsReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(billingChecklistWeeklyEventsError(error));
      });
  };
};
