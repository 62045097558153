import { INVOICE_BATCH_STATUS_LIST_REQUEST, INVOICE_BATCH_STATUS_LIST_RECEIVED, INVOICE_BATCH_STATUS_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    invoiceBatchStatuses: null,
    isFetchingInvoiceBatchStatuses: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_STATUS_LIST_REQUEST:
      state = {
        ...state,
        isFetchingInvoiceBatchStatuses: true,
      };
      return state;

    case INVOICE_BATCH_STATUS_LIST_RECEIVED:
      state = {
        ...state,
        invoiceBatchStatuses: action.data['hydra:member'],
        isFetchingInvoiceBatchStatuses: false,
      };
      return state;

    case INVOICE_BATCH_STATUS_LIST_ERROR:
      state = {
        ...state,
        isFetchingInvoiceBatchStatuses: false,
      };
      //      console.log(state);
      return state;

    default:
      return state;
  }
};

export default reducer;
