import {
  HOSPITAL_LIST_REQUEST,
  HOSPITAL_LIST_RECEIVED,
  HOSPITAL_LIST_ERROR,
  HOSPITAL_LIST_SET_PAGE,
  //  HOSPITAL_LIST_SET_FILTERS, HOSPITAL_LIST_CLEAR_FILTERS,
  HOSPITAL_LIST_UNLOAD,
  HOSPITAL_SINGLE_UPDATE_REQUEST,
  HOSPITAL_SINGLE_UPDATED,
  HOSPITAL_SINGLE_CREATE_SUCCESS,
  HOSPITAL_SINGLE_CREATE_REQUEST,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    hospitals: [],
    isFetchingHospitals: false,
    currentPage: 1,
    pageCount: null,
    //  filters: {},

    hospital: null,
    isFetchingHospital: false,
    isUpdatingHospital: false,
    isCreatingHospital: false,
  },
  action,
) => {
  switch (action.type) {
    case HOSPITAL_SINGLE_CREATE_REQUEST:
      return {
        ...state,
        isCreatingHospital: true,
      };

    case HOSPITAL_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingHospital: true,
      };
      return state;

    case HOSPITAL_SINGLE_CREATE_SUCCESS:
    case HOSPITAL_SINGLE_UPDATED: {
      const updatedHospitalsList = [...state.hospitals];
      const removeIndex = updatedHospitalsList.map((item) => item.id).indexOf(action.data.id);

      if (removeIndex >= 0) {
        updatedHospitalsList.splice(removeIndex, 1);
      }

      return {
        ...state,
        hospitals: [...updatedHospitalsList, action.data].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        isUpdatingHospital: false,
        isCreatingHospital: false,
      };
    }
    case HOSPITAL_LIST_UNLOAD:
      return {
        hospitals: [],
        isFetchingHospitals: false,
        currentPage: 1,
        pageCount: null,
        //        filters: {}
      };

    case HOSPITAL_LIST_REQUEST:
      return {
        ...state,
        isFetchingHospitals: true,
      };

    case HOSPITAL_LIST_RECEIVED:
      return {
        ...state,
        hospitals: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingHospitals: false,
      };

    case HOSPITAL_LIST_ERROR:
      return {
        ...state,
        isFetchingHospitals: false,
      };
    case HOSPITAL_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    //    case HOSPITAL_LIST_SET_FILTERS:
    //      return {
    //        ...state,
    //        filters: action.filters,
    //      };
    //
    //    case HOSPITAL_LIST_CLEAR_FILTERS:
    //      return {
    //        ...state,
    ////        filters: {},
    //      };

    default:
      return state;
  }
};

export default reducer;
