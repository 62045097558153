/* N.B. These codes need to reflect app/src/Entity/ProblemType.php in the api project */
const PROBLEM_TYPE = {
  MEMBERSHIP_QUERY: 'MEM',
  DOCTOR: 'DOC',
  UPLIFT: 'UPL',
  NO_INITIAL: 'NOI',
  CANCEL: 'CAN',
  EMBASSY: 'EMB',
};

export default PROBLEM_TYPE;
