import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import MASpinner from '../../common/MASpinner';
import { renderField } from '../../../lib/helper/form';
import {
  billingChecklistEventSingleFetch,
  billingChecklistEventSingleUpdate,
  billingChecklistEventSingleUnload,
} from '../../../lib/redux/actions/billingChecklistEvents';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';

const mapStateToProps = (state, props) => ({
  initialValues: props?.billingChecklistEvent ?? { name: 'Holiday' },
});

const mapDispatchToProps = {
  billingChecklistEventSingleFetch,
  billingChecklistEventSingleUpdate,
  billingChecklistEventSingleUnload,
  addToast,
};

class BillingChecklistEventForm extends React.Component {
  constructor(props) {
    super(props);
    const { billingChecklistEvent } = this.props;

    this.state = {
      selectedConsultant: billingChecklistEvent && billingChecklistEvent.consultant ? [billingChecklistEvent.consultant] : [],
      clearTypeaheads: false,
    };
  }

  onSubmit(values) {
    const { billingChecklistEventSingleUpdate, billingChecklistEvent = undefined, addToast, isCreate = false, onCreate = undefined } = this.props;

    if (this.state.selectedConsultant.length > 0) {
      values.consultant = this.state.selectedConsultant.map((c) => c['@id']);
      values.consultant = values.consultant[0];
    } else {
      values.consultant = null;
    }

    if (isCreate && onCreate) {
      return onCreate(values);
    } else if (billingChecklistEvent) {
      return billingChecklistEventSingleUpdate(values, billingChecklistEvent.id).then(() => {
        addToast('The Billing Checklist Event was successfully updated', true);
      });
    }
  }

  clearTypeaheads() {
    this.setState(() => {
      return {
        selectedConsultant: [],
        clearTypeaheads: true,
      };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      submitting,
      isUpdatingBillingChecklistEvent,
      isFetchingBillingChecklistEvent,
      hasLoadedBillingChecklistEvent,
      billingChecklistEvent,
      title = 'Billing Checklist Event Details',
      isCreate = false,
    } = this.props;
    if (!hasLoadedBillingChecklistEvent && isFetchingBillingChecklistEvent) {
      return <MASpinner />;
    }

    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-book-medical" />
        </div>
        <h2 className="crud__section-header">{title}</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            <div className="col-sm-12 col-md-2">
              <Field name="name" label="Event Name" type="text" component={renderField} />
            </div>

            <div className="col-sm-12 col-md-2">
              <Field
                name="startDate"
                label="Start Date"
                type="date"
                value={billingChecklistEvent ? billingChecklistEvent.startDate : ''}
                component={renderField}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              <Field name="endDate" label="End Date" type="date" value={billingChecklistEvent ? billingChecklistEvent.endDate : ''} component={renderField} />
            </div>
            <div className="col-sm-12 col-md-2">
              <ConsultantTypeahead
                name="consultant"
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                multiple={false}
                selectedConsultants={this.state.selectedConsultant}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedConsultant: selected };
                  });
                }}
              />
            </div>

            {isUpdatingBillingChecklistEvent ? (
              <MASpinner />
            ) : (
              <div className="col-sm-12 col-md-5 align-self-end">
                <button type="submit" disabled={submitting} className="btn btn-primary mb-3">
                  {isCreate ? 'Create Billing Checklist Event' : 'Update'}
                </button>
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'BillingChecklistEventForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(BillingChecklistEventForm),
);
