import React, { Component } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { renderCheckbox, renderField } from '../../../lib/helper/form';
import { upliftDataAdd, upliftDataEdit } from '../../../lib/redux/actions/upliftData';
import { addToast } from '../../../lib/redux/actions/toast';

const mapDispatchToProps = {
  upliftDataAdd,
  upliftDataEdit,
  addToast,
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      charts: props?.problemData?.upliftData?.charts,
      opNotes: props?.problemData?.upliftData?.opNotes,
      upliftForms: props?.problemData?.upliftData?.upliftForms,
    },
  };
};

class UpliftDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charts: props?.problemData?.upliftData?.charts,
      opNotes: props?.problemData?.upliftData?.opNotes,
      upliftForms: props?.problemData?.upliftData?.upliftForms,
    };
  }

  render() {
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <Field name="upliftData.dateSubmitted" label="Date Submitted" id="upliftData.dateSubmitted" type="date" component={renderField} />
              </div>
              <div className="col-md-4">
                <Field
                  name="upliftData.feeRequested"
                  label="Fee Requested"
                  type="number"
                  placeholder="0.00"
                  step="0.01"
                  min="0.00"
                  id="upliftData.feeRequested"
                  component={renderField}
                />
              </div>
              <div className="col-md-4">
                <Field
                  component={renderField}
                  name="upliftData.feeAgreed"
                  label="Fee Agreed"
                  id="upliftData.feeAgreed"
                  placeholder="0.00"
                  step="0.01"
                  min="0.00"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <fieldset className="col-sm-12 col-md-12">
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <p>Charts</p>
                <Form.Group controlId="upliftData.charts" className={'form-check-inline'}>
                  <div className="mb-1">
                    <Field
                      value={true}
                      type="radio"
                      label="Yes"
                      name="upliftData.charts"
                      id="chartsTrue"
                      checked={this.state.charts === true}
                      component={renderCheckbox}
                      onChange={() => this.setState({ charts: true })}
                    />
                  </div>
                  <div className="mb-1">
                    <Field
                      value={false}
                      type="radio"
                      name="upliftData.charts"
                      label="No"
                      checked={this.state.charts === false}
                      id="chartsFalse"
                      component={renderCheckbox}
                      onChange={() => this.setState({ charts: false })}
                    />
                  </div>
                  <div className="mb-1">
                    <Field
                      value={null}
                      type="radio"
                      name="upliftData.charts"
                      label="N/A"
                      checked={this.state.charts === null || this.state.charts === undefined}
                      id="chartsFalse"
                      component={renderCheckbox}
                      onChange={() => this.setState({ charts: null })}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-xl-4">
                <p>Op Notes</p>
                <Form.Group controlId="upliftData.opNotes" className={'form-check-inline'}>
                  <div className="mb-1">
                    <Field
                      value={true}
                      label="Yes"
                      type="radio"
                      name="upliftData.opNotes"
                      id="opNotesTrue"
                      checked={this.state.opNotes === true}
                      component={renderCheckbox}
                      onChange={() => this.setState({ opNotes: true })}
                    />
                  </div>
                  <div className="mb-1">
                    <Field
                      value={false}
                      type="radio"
                      label="No"
                      name="upliftData.opNotes"
                      id="opNotesFalse"
                      checked={this.state.opNotes === false}
                      component={renderCheckbox}
                      onChange={() => this.setState({ opNotes: false })}
                    />
                  </div>
                  <div className="mb-1">
                    <Field
                      value={null}
                      type="radio"
                      label="N/A"
                      name="upliftData.opNotes"
                      id="opNotesFalse"
                      checked={this.state.opNotes === null || this.state.opNotes === undefined}
                      component={renderCheckbox}
                      onChange={() => this.setState({ opNotes: null })}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-xl-4">
                <p>Uplift Forms</p>
                <Form.Group controlId="upliftData.upliftForms" className={'form-check-inline'}>
                  <div className="mb-1">
                    <Field
                      value={true}
                      label="Yes"
                      type="radio"
                      name="upliftData.upliftForms"
                      id="upliftFormsTrue"
                      checked={this.state.upliftForms === true}
                      component={renderCheckbox}
                      onChange={() => this.setState({ upliftForms: true })}
                    />
                  </div>
                  <div className="mb-1">
                    <Field
                      value={false}
                      label="No"
                      type="radio"
                      name="upliftData.upliftForms"
                      id="upliftFormsFalse"
                      checked={this.state.upliftForms === false}
                      component={renderCheckbox}
                      onChange={() => this.setState({ upliftForms: false })}
                    />
                  </div>
                  <div className="mb-1">
                    <Field
                      value={null}
                      label="N/A"
                      type="radio"
                      name="upliftData.upliftForms"
                      id="upliftFormsFalse"
                      checked={this.state.upliftForms === null || this.state.upliftForms === undefined}
                      component={renderCheckbox}
                      onChange={() => this.setState({ upliftForms: null })}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpliftDataForm);
