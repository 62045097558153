import INVOICE_BATCH_STATUS from './statusCodes';

export const isStatus = (invoiceBatch, statusCode) => {
  return invoiceBatch && invoiceBatch.status && Number(invoiceBatch.status.code) === Number(statusCode);
};

export const isCancelled = (invoiceBatch) => {
  return isStatus(invoiceBatch, INVOICE_BATCH_STATUS.CANCELLED);
};
export const isSubmitted = (invoiceBatch) => {
  return isStatus(invoiceBatch, INVOICE_BATCH_STATUS.SUBMITTED);
};

export const isCheckedAndPriced = (invoiceBatch) => {
  return isStatus(invoiceBatch, INVOICE_BATCH_STATUS.CHECKED_AND_PRICED);
};

export const isProcessing = (invoiceBatch) => {
  return isStatus(invoiceBatch, INVOICE_BATCH_STATUS.PROCESSING);
};

export const isCompleted = (invoiceBatch) => {
  return isStatus(invoiceBatch, INVOICE_BATCH_STATUS.COMPLETED);
};

export const hasBeenStatus = (invoiceBatch, statusCode) => {
  return invoiceBatch && invoiceBatch.status && Number(invoiceBatch.status.code) >= Number(statusCode);
};

export const getNumberOfInvoices = (invoiceBatch) => {
  if (invoiceBatch.numberOfInvoicesToProcess && Number(invoiceBatch.numberOfInvoicesToProcess) > 0) {
    return invoiceBatch.numberOfInvoicesToProcess;
  } else if (invoiceBatch.advisedNumberOfInvoices && Number(invoiceBatch.advisedNumberOfInvoices) > 0) {
    return invoiceBatch.advisedNumberOfInvoices;
  }
  return '-';
};

export const hasDiscrepantInvoiceCounts = (invoiceBatch) => {
  const hasNoOfInvoicesToProcess = invoiceBatch.numberOfInvoicesToProcess && Number(invoiceBatch.numberOfInvoicesToProcess) > 0;
  const hasAdvisedNoOfInvoices = invoiceBatch.advisedNumberOfInvoices && Number(invoiceBatch.advisedNumberOfInvoices) > 0;
  return hasNoOfInvoicesToProcess && hasAdvisedNoOfInvoices && Number(invoiceBatch.numberOfInvoicesToProcess) !== Number(invoiceBatch.advisedNumberOfInvoices);
};
