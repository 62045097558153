import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';
import { requests } from '../../helper/agent';
import {
  PROBLEM_DATA_ADDED,
  PROBLEM_DATA_LIST_CLEAR_FILTERS,
  PROBLEM_DATA_LIST_ERROR,
  PROBLEM_DATA_LIST_RECEIVED,
  PROBLEM_DATA_LIST_REQUEST,
  PROBLEM_DATA_LIST_SET_FILTERS,
  PROBLEM_DATA_LIST_SET_PAGE,
  PROBLEM_DATA_LIST_UNLOAD,
  PROBLEM_DATA_RECEIVED,
  PROBLEM_DATA_REQUEST,
  PROBLEM_DATA_SINGLE_ERROR,
  PROBLEM_DATA_SINGLE_RECEIVED,
  PROBLEM_DATA_SINGLE_REQUEST,
  PROBLEM_DATA_SINGLE_UNLOAD,
  PROBLEM_DATA_SINGLE_UPDATED,
} from '../constants';
import { userLogout } from './user';

export const problemDataRequest = () => ({
  type: PROBLEM_DATA_REQUEST,
});

export const problemDataReceived = (data) => ({
  type: PROBLEM_DATA_RECEIVED,
  data,
});

export const problemDataAdded = (data) => ({
  type: PROBLEM_DATA_ADDED,
  data,
});

export const problemDataListRequest = () => ({
  type: PROBLEM_DATA_LIST_REQUEST,
});

export const problemDataSingleUnload = () => ({
  type: PROBLEM_DATA_SINGLE_UNLOAD,
});

export const problemDataListUnload = () => ({
  type: PROBLEM_DATA_LIST_UNLOAD,
});

export const problemDataListReceived = (data, appendUntilUnloaded) => ({
  type: PROBLEM_DATA_LIST_RECEIVED,
  data,
  appendUntilUnloaded,
});

export const problemDataListClearFilters = () => ({
  type: PROBLEM_DATA_LIST_CLEAR_FILTERS,
});

export const problemDataListError = (error) => ({
  type: PROBLEM_DATA_LIST_ERROR,
  error,
});

export const problemDataSingleRequest = () => ({
  type: PROBLEM_DATA_SINGLE_REQUEST,
});

export const problemDataSingleReceived = (data) => ({
  type: PROBLEM_DATA_SINGLE_RECEIVED,
  data,
});

export const problemDataSingleError = (error) => ({
  type: PROBLEM_DATA_SINGLE_ERROR,
  error,
});

export const problemDataUpdated = (data) => ({
  type: PROBLEM_DATA_SINGLE_UPDATED,
  data,
});

export const problemDataListSetPage = (page) => ({
  type: PROBLEM_DATA_LIST_SET_PAGE,
  page,
});

export const problemDataListSetFilters = (filters) => ({
  type: PROBLEM_DATA_LIST_SET_FILTERS,
  filters,
});

export const problemDataSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(problemDataSingleRequest());
    return requests
      .get(`${API_ROUTES.PROBLEM_DATA_SINGLE.replace(':id', id)}`)
      .then((response) => dispatch(problemDataSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(problemDataSingleError(error));
      });
  };
};

export const problemDataListFetch = (page = 1, filters = {}, appendUntilUnloaded = false) => {
  return (dispatch) => {
    dispatch(problemDataListRequest());
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(`${API_ROUTES.PROBLEM_DATA}`, filters)
      .then((response) => dispatch(problemDataListReceived(response, appendUntilUnloaded)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(problemDataListError(error));
      });
  };
};

export const problemDataFetch = () => {
  return (dispatch) => {
    dispatch(problemDataRequest());
    return requests
      .get(`${API_ROUTES.PROBLEM_DATA}`)
      .then((response) => dispatch(problemDataReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const problemDataEdit = (data, id) => {
  return (dispatch) => {
    dispatch(problemDataRequest());
    return requests
      .patch(`${API_ROUTES.PROBLEM_DATA_SINGLE.replace(':id', id)}`, data)
      .then((response) => {
        dispatch(problemDataUpdated(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const problemDataAdd = (data) => {
  return (dispatch) => {
    dispatch(problemDataRequest());
    return requests
      .post(`${API_ROUTES.PROBLEM_DATA}`, data)
      .then((response) => {
        dispatch(problemDataAdded(response));
        return response;
      })
      .catch((error) => {
        dispatch(problemDataSingleError(error));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
