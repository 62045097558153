import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import {
  isChaserOutsourcer,
  isClient,
  isConsultant,
  isOutsourcer,
  isSeniorStaff,
  isStaff,
  isSubUser,
  subUserCanUploadBills,
} from '../../lib/helper/authorisation';
import RegularClinicsForm from '../../components/forms/Consultant/RegularClinicsForm';
import BillingChecklist from '../../components/billingChecklist/BillingChecklist';
import ConsultantBibleLog from '../../components/consultantBibleLog/ConsultantBibleLog';
import DatedCostsChangeLog from '../../components/datedCost/DatedCostsChangeLog';
import InvoiceBatchWeeklyCounts from '../../components/InvoiceBatch/InvoiceBatchWeeklyCounts';
import CreateConsultantTicketForm from '../../components/forms/ConsultantTicket/CreateConsultantTicketForm';
import InvoiceActionStats from '../../components/invoice/InvoiceActionStats';

class Dashboard extends React.Component {
  render() {
    const { stats, consultant = false } = this.props;
    return (
      <div>
        <Card className="mb-4 dashboard-home-intro">
          <Card.Body>
            <h1>Welcome To The MediAccounts Portal</h1>
            <h2 className="h4 mb-4">A Fresh Approach To Medical Billing</h2>
            {/*<p className="mb-0">*/}
            {/*  <Link to={ROUTES.HELP} className="btn btn-outline-white mb-0">*/}
            {/*    How to use the MediAccounts portal*/}
            {/*  </Link>*/}
            {/*</p>*/}
          </Card.Body>
        </Card>
        {isSeniorStaff() && (
          <div>
            <Row>
              <Col xs={12} md={6} lg={4} xl={3}>
                <Card className="mb-4">
                  <Card.Header className={'text-center card-header--small-text'}>Invoices to Assign</Card.Header>
                  <Card.Body className="dashboard-statistics-wrapper">
                    <div className="dashboard-statistics" title={`${stats && stats.invoiceBatchesToAssign ? stats.invoiceBatchesToAssign : 0} invoice batches`}>
                      {stats && stats.invoicesToAssign ? stats.invoicesToAssign : 0}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={4} xl={3}>
                <Card className="mb-4">
                  <Card.Header className={'text-center card-header--small-text'}>Urgent Invoice Batches to Complete</Card.Header>
                  <Card.Body className="dashboard-statistics-wrapper">
                    <div
                      className="dashboard-statistics"
                      title={`${stats && stats.urgentInvoicesToComplete ? stats.urgentInvoicesToComplete : 0} individual invoices`}>
                      {stats && stats.urgentInvoiceBatchesToComplete ? stats.urgentInvoiceBatchesToComplete : 0}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={4} xl={3}>
                <Card className="mb-4">
                  <Card.Header className={'text-center card-header--small-text'}>Problem Invoice Batches</Card.Header>
                  <Card.Body className="dashboard-statistics-wrapper">
                    <div className="dashboard-statistics" title={`${stats && stats.problemInvoices ? stats.problemInvoices : 0} individual invoices`}>
                      {stats && stats.problemInvoiceBatches ? stats.problemInvoiceBatches : 0}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={4} xl={3}>
                <Card className="mb-4">
                  <Card.Header className={'text-center card-header--small-text'}>Invoice Batches in Processing</Card.Header>
                  <Card.Body className="dashboard-statistics-wrapper">
                    <div className="dashboard-statistics" title={`${stats && stats.processingInvoices ? stats.processingInvoices : 0} individual invoices`}>
                      {stats && stats.processingInvoiceBatches ? stats.processingInvoiceBatches : 0}
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} md={12} lg={8} xl={6}>
                <Row>
                  <Col xs={12} md={6}>
                    <Card className="mb-4">
                      <Card.Header className={'text-center card-header--small-text'}>Active Consultant Accounts</Card.Header>
                      <Card.Body className="dashboard-statistics-wrapper">
                        <div className="dashboard-statistics">{stats && stats.activeConsultants ? stats.activeConsultants : 0}</div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={6}>
                    <Card className="mb-4">
                      <Card.Header className={'text-center card-header--small-text'}>Total Invoice Batches Processed</Card.Header>
                      <Card.Body className="dashboard-statistics-wrapper">
                        <div className="dashboard-statistics" title={`${stats && stats.completedInvoices ? stats.completedInvoices : 0} individual invoices`}>
                          {stats && stats.completedInvoiceBatches ? stats.completedInvoiceBatches : 0}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="mb-4">
                      <Card.Header className="text-center card-header--small-text card-header">Invoices Per Week</Card.Header>
                      <Card.Body>
                        <InvoiceBatchWeeklyCounts />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} xl={6}>
                <ConsultantBibleLog path={this.props.path} />
                <DatedCostsChangeLog path={this.props.path} />
              </Col>
            </Row>
          </div>
        )}
        {(isStaff(true) || isOutsourcer(true)) && (
          <div>
            <Row>
              <Col xs={12} md={6}>
                <Card className="mb-4">
                  <Card.Header className={'text-center card-header--small-text'}>Invoices to Accept</Card.Header>
                  <Card.Body className="dashboard-statistics-wrapper">
                    <div className="dashboard-statistics">{stats && stats.invoicesToAccept ? stats.invoicesToAccept : 0}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card className="mb-4">
                  <Card.Header className={'text-center card-header--small-text'}>Invoices to Process</Card.Header>
                  <Card.Body className="dashboard-statistics-wrapper">
                    <div className="dashboard-statistics">{stats && stats.invoicesToProcess ? stats.invoicesToProcess : 0}</div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {isClient() && (
          <div>
            <Row>
              <Col md={12} xl={6}>
                <Row>
                  <Col xs={12}>
                    <Card className="mb-4">
                      <Card.Header className={'text-center card-header--small-text'}>Invoices being Processed</Card.Header>
                      <Card.Body className="dashboard-statistics-wrapper">
                        <div className="dashboard-statistics">{stats && stats.invoicesInProgress ? stats.invoicesInProgress : 0}</div>
                      </Card.Body>
                    </Card>
                  </Col>
                  {consultant && (
                    <Col xs={12} className="d-none d-sm-block">
                      {/* Hiding Billing checklist fully on mobile devices due to need for min width of table header */}
                      <BillingChecklist
                        match={this.props.match}
                        history={this.props.history}
                        paginationRoute={ROUTES.HOME}
                        useQueryString={true}
                        location={this.props.location}
                      />
                    </Col>
                  )}
                </Row>
              </Col>

              {consultant && (
                <Col md={12} xl={6}>
                  <Card className="mb-4">
                    <Card.Header className={'text-center card-header--small-text'}>Invoices Per Week</Card.Header>
                    <Card.Body className="dashboard-statistics-wrapper">
                      <InvoiceBatchWeeklyCounts consultant={consultant} />
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {consultant && (isConsultant(true) || (isSubUser(true) && subUserCanUploadBills())) && (
                <Col xs={12} md={6}>
                  <Card className="mb-4">
                    <Card.Body className="dashboard-statistics-wrapper">
                      <div>
                        <RegularClinicsForm consultant={consultant} clinicSlots={consultant.clinicSlots} />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
              {consultant && (
                <Col xs={12} md={6} className="d-none d-sm-block">
                  <Card className="mb-4">
                    <Card.Body className="dashboard-statistics-wrapper">
                      <CreateConsultantTicketForm consultant={consultant} />
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        )}

        {isChaserOutsourcer(true) && (
          <div>
            <Row>
              <Col md={12}>
                <Row>
                  <Col xs={12}>
                    <Card className="mb-4">
                      <Card.Header className={'text-center card-header--small-text'}>Weekly Invoice Action Stats</Card.Header>
                      <Card.Body className="dashboard-statistics-wrapper">
                        <InvoiceActionStats />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
