import React from 'react';
import { connect } from 'react-redux';
import { getInvoiceBatchWeeklyCounts } from '../../lib/redux/actions/invoiceBatchWeeklyCounts';
import { niceDateFormatter } from '../../lib/helper/formatting';
import { isStaff } from '../../lib/helper/authorisation';
import { renderField } from '../../lib/helper/form';
import { Field, reduxForm } from 'redux-form';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../fields/ConsultantTypeahead';
import { downloadWeeklyInvoiceCountsExport } from '../../lib/helper/downloadHelper';
import { addToast } from '../../lib/redux/actions/toast';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const mapStateToProps = (state) => ({
  ...state.invoiceBatchWeeklyCounts,
  ...state.auth,
  initialValues: {
    fromDate: moment(moment()).add('-12', 'weeks').format('YYYY-MM-DD'),
    toDate: moment(moment()).format('YYYY-MM-DD'),
  },
});

const mapDispatchToProps = {
  getInvoiceBatchWeeklyCounts,
  addToast,
};

class InvoiceBatchWeeklyCounts extends React.Component {
  constructor(props) {
    super(props);

    const { initialize, initialValues } = this.props;

    this.state = {
      selectedConsultant: false,
      clearTypeaheads: false,
      fromDate: moment(moment()).add('-12', 'weeks').format('YYYY-MM-DD'),
      toDate: moment(moment()).format('YYYY-MM-DD'),
      triggerExport: false,
    };

    initialize(initialValues);
  }

  componentDidMount() {
    this.loadInvoiceWeeklyBatchCounts();
  }

  loadInvoiceWeeklyBatchCounts() {
    const { consultant = false } = this.props;

    const filters = {};
    if (consultant !== false) {
      filters.consultant = consultant['@id'];
    }

    this.props.getInvoiceBatchWeeklyCounts(filters);
  }

  onSubmit() {
    const { consultant, getInvoiceBatchWeeklyCounts } = this.props;

    if (consultant) {
      return;
    }

    const filters = {};
    if (this.state.selectedConsultant) {
      filters.consultant = this.state.selectedConsultant.map((c) => c['@id']);
      filters.consultant = filters.consultant[0];
    }
    if (this.state.fromDate) {
      filters.from_date = this.state.fromDate;
    }
    if (this.state.toDate) {
      filters.to_date = this.state.toDate;
    }
    getInvoiceBatchWeeklyCounts(filters);
    if (this.state.triggerExport === true) {
      return downloadWeeklyInvoiceCountsExport(
        filters,
        `INVOICE_WEEKLY_COUNTS_${niceDateFormatter(moment.utc(this.state.fromDate))}-${niceDateFormatter(moment.utc(this.state.toDate))}.csv`,
      ).catch(() => {
        addToast('There was a problem downloading the file. Please seek support if the issue continues.', false);
      });
    }
  }

  resetFilters() {
    const { initialize, initialValues } = this.props;

    this.clearTypeaheads();
    this.setState(() => ({
      selectedConsultant: [],
      fromDate: moment(moment()).add('-12', 'weeks').format('YYYY-MM-DD'),
      toDate: moment(moment()).format('YYYY-MM-DD'),
    }));

    initialize(initialValues);

    this.props.getInvoiceBatchWeeklyCounts();
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  render() {
    const { handleSubmit, showFilters = true, invoiceBatchWeeklyCounts } = this.props;
    return (
      <>
        {isStaff() && showFilters && (
          <div>
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="form-inline form-data-filters">
              <ConsultantTypeahead
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                multiple={false}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedConsultant: selected };
                  });
                }}
              />
              <Field
                name="fromDate"
                label="From Date"
                type="date"
                onChange={(event) => {
                  this.setState(() => {
                    return { fromDate: event.target.value };
                  });
                }}
                value={this.state.fromDate}
                component={renderField}
              />

              <Field
                name="toDate"
                label="To Date"
                type="date"
                onChange={(event) => {
                  this.setState(() => {
                    return { toDate: event.target.value };
                  });
                }}
                value={this.state.toDate}
                component={renderField}
              />

              <div className="filter-actions">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    this.setState(() => {
                      return { triggerExport: false };
                    });
                  }}>
                  Filter
                </button>
                <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-secondary btn-sm ml-2"
                  onClick={() => {
                    this.setState(() => {
                      return { triggerExport: true };
                    });
                  }}
                  name="export">
                  Export
                </button>
              </div>
            </Form>
          </div>
        )}
        <div className="dashboard-chart-wrapper">
          {Object.keys(invoiceBatchWeeklyCounts).length ? (
            <>
              <table className="charts-css column show-heading show-labels show-primary-axis show-10-secondary-axes show-data-axes data-spacing-5">
                <thead>
                  <tr>
                    <th scope="col">Week</th>
                    <th scope="col">Number of Invoices</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(invoiceBatchWeeklyCounts).map(([weekNum, weekData]) => (
                    <tr key={weekNum}>
                      <th scope="row">{niceDateFormatter(weekData.start_of_week, 'DD/MM')}</th>
                      <td style={{ '--size': weekData.number_of_invoices__percentage_of_highest }}>
                        <span className="data">{weekData.number_of_invoices}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>No Invoices found</p>
          )}
        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'InvoiceBatchWeeklyCounts',
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchWeeklyCounts));

//export default compose(
//    withRouter,
//    connect(mapStateToProps, mapDispatchToProps),
//)
//(InvoiceBatchWeeklyCounts);
