import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { renderDatePicker } from '../../../lib/helper/form';
import React from 'react';
import { invoiceDateDelete, invoiceDateUpdate, invoiceDateAdd } from '../../../lib/redux/actions/invoiceDate';
import { invoiceBatchSingleFetch, invoiceBatchAuditLogFetch } from '../../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import moment from 'moment';
import DateWithMorningOrAfternoonSelector from '../../fields/DateWithMorningOrAfternoonSelector';
import { addToast } from '../../../lib/redux/actions/toast';
import 'iterators-polyfill';
import { timeOfDayMap } from '../../../lib/helper/formatting';

const mapDispatchToProps = {
  invoiceDateAdd,
  invoiceDateDelete,
  invoiceDateUpdate,
  invoiceBatchSingleFetch,
  invoiceBatchAuditLogFetch,
  addToast,
};

class EditClinicDatesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDates: [],
      nextDateKey: 0,
      isAmending: false,
    };
  }

  componentDidMount() {
    this.setState(() => {
      return {
        selectedDates: this.props.invoiceBatch.invoiceDates.map((d) => {
          delete d.createdAt;
          delete d.createdBy;
          delete d.updatedAt;
          delete d.updatedBy;
          return d;
        }),
      };
    });
  }

  clearSelectedDates() {
    this.setState(() => {
      return { selectedDates: [], dateAmPmMap: {} };
    });
  }

  addDate(date) {
    this.setState((prevState) => {
      return {
        isAmending: true,
        selectedDates: [
          ...prevState.selectedDates,
          {
            id: prevState.nextDateKey,
            timeOfDay: timeOfDayMap.AM,
            date: moment(date).utc().toISOString(),
            isNew: true,
          },
        ].sort((a, b) => a.date - b.date),
        nextDateKey: prevState.nextDateKey + 1,
      };
    });
  }

  selectDateAmPm(dateId, amOrPm) {
    this.setState((prevState) => {
      return {
        isAmending: true,
        selectedDates: prevState.selectedDates.map((d) => {
          return { ...d, timeOfDay: d.id === dateId ? amOrPm : d.timeOfDay, isUpdated: d.isUpdated || d.id === dateId };
        }),
      };
    });
  }

  removeSelectedDateKeyPair(dateId) {
    // add a remove bool flag to dates to remove
    this.setState((prevState) => {
      return {
        isAmending: true,
        selectedDates: prevState.selectedDates.map((d) => {
          return { ...d, isRemove: d.isRemove || d.id === dateId };
        }),
      };
    });
  }

  onSubmit() {
    const {
      invoiceDateDelete,
      invoiceDateAdd,
      invoiceDateUpdate,
      invoiceBatch,
      toggleAmending,
      invoiceBatchSingleFetch,
      invoiceBatchAuditLogFetch,
      addToast,
      reset,
    } = this.props;

    if (this.state.selectedDates.length < 1) {
      return;
    }
    let removals = 0;
    let updates = 0;
    let additions = 0;
    const clinicSLotRemovalIssue = () => {
      addToast("There was an issue removing selected clinic slot(s). Please check it's not associated with problem medicode(s).", false, true);
      removals--;
    };

    const dateCnt = this.state.selectedDates.length;
    for (let x = 0; x < dateCnt; x++) {
      const invoiceDate = this.state.selectedDates[x];
      if (invoiceDate.isRemove) {
        removals++;
        invoiceDateDelete(invoiceDate.id).catch(clinicSLotRemovalIssue);
      } else if (invoiceDate.isNew) {
        delete invoiceDate.id;
        invoiceDate.invoiceBatch = invoiceBatch['@id'];
        invoiceDateAdd(invoiceDate);
        additions++;
      } else if (invoiceDate.isUpdated) {
        const invoiceDateId = invoiceDate.id;
        //        delete invoiceDate.id;
        //        delete invoiceDate['@id'];
        invoiceDateUpdate(invoiceDateId, invoiceDate);
        updates++;
      }
    }

    let msg = 'Successfully amended clinic dates - ';
    const stats = [];
    if (additions > 0) {
      stats.push(additions + ' added');
    }
    if (updates > 0) {
      stats.push(updates + ' changed');
    }
    if (removals > 0) {
      stats.push(removals + ' removed');
    }

    setTimeout(() => {
      invoiceBatchSingleFetch(invoiceBatch.id).then(() => {
        invoiceBatchAuditLogFetch(invoiceBatch.id);
      });
    }, 800);
    toggleAmending();
    addToast(msg + '(' + stats.join() + ')', true);
    reset();
  }

  doReset() {
    this.props.fileClearReferences();
    this.props.reset();
    this.setState(() => this.getCleanState());
  }

  render() {
    const { handleSubmit, error, submitting } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="mediaccounts-clinic-date-component">
            <p>Please add any missed dates/times that these invoices relate to</p>
            <Field
              component={renderDatePicker}
              name="clinicDates"
              label={<i className="fas fa-calendar mr-2" />}
              id="clinic-dates"
              handleChange={() => {
                /* this is not used in the case but required*/
              }}
              handleSelect={this.addDate.bind(this)}
              clearOnClose={true}
              popperPlacement="top-end"
              className="mediaccounts-clinic-date-input"
            />

            <ul className="list-inline">
              {this.state.selectedDates
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .filter((d) => !d.isRemove)
                .map((invoiceDate) => (
                  <DateWithMorningOrAfternoonSelector
                    isNew={invoiceDate.isNew}
                    key={invoiceDate.id}
                    date={invoiceDate.date}
                    dateKey={invoiceDate.id}
                    amOrPm={invoiceDate.timeOfDay}
                    amPmSelector={this.selectDateAmPm.bind(this)}
                    removeSelectedDate={this.removeSelectedDateKeyPair.bind(this)}
                  />
                ))}
            </ul>
          </div>

          <button type="submit" disabled={!this.state.isAmending || submitting} className="btn btn-primary">
            Save amends
          </button>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'EditClinicDatesForm',
})(connect(null, mapDispatchToProps)(EditClinicDatesForm));
