import React from 'react';
import Card from 'react-bootstrap/Card';
import { userSingleTermsAgree } from '../lib/redux/actions/user';
import { connect } from 'react-redux';
import * as ROUTES from '../lib/routing/frontend';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  userSingleTermsAgree,
};

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAgreed: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.hasAgreed && this.state.hasAgreed) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  agreeToTerms() {
    const { userSingleTermsAgree, userId } = this.props;
    return userSingleTermsAgree(userId).then(() => {
      this.setState(() => ({ hasAgreed: true }));
    });
  }

  render() {
    return (
      <Card className="terms-conditions-card mt-4 mb-4">
        <Card.Header className="text-center">Terms and Conditions</Card.Header>
        <Card.Body className="text-center">
          <h1 className="terms-welcome">Welcome to the MediAccounts Ltd Deliverables Portal</h1>
          <p className="font-weight-bold mb-4 text-uppercase text-danger">Step 1: Before using this service please read our Terms and Conditions.</p>

          <div className="termsSection text-left">
            <h5>Deliverables Portal Terms and Conditions</h5>
            <p>
              These terms and conditions (these &#34;Deliverables Portal T&Cs&#34;) shall apply to the procuring by MediAccounts LTD of the Deliverables Portal
              for use by the Consultant and/or the Consultants Affiliates (Secretary/Accountant) under the relevant MediAccounts Contract
            </p>
            <ol>
              <li>
                <strong>Definitions</strong>
                <ol>
                  <li>
                    The following expressions have the following meanings:
                    <table>
                      <tbody>
                        <tr>
                          <td>&#34;Agreement&#34;</td>
                          <td> the agreement that incorporates these Deliverables Portal T&Cs;</td>
                        </tr>
                        <tr>
                          <td>&#34;Business Day&#34;</td>
                          <td> means any day, other than a Saturday or Sunday, when banks are generally open for business in England;</td>
                        </tr>
                        <tr>
                          <td>&#34;Client End User or ‘User &#34;</td>
                          <td> an individual granted access to the Deliverables Portal pursuant to clause 2.3.1 of these Deliverables Portal T&Cs;</td>
                        </tr>
                        <tr>
                          <td>&#34;Consultant &#34; or &#34;you&#34; or derivatives</td>
                          <td>as defined in the Agreement;</td>
                        </tr>
                        <tr>
                          <td>&#34;Consultant Affiliates&#34;</td>
                          <td>
                            {' '}
                            any entity that directly or indirectly controls, is controlled by, or is under common control with the Consultant,such as a
                            secretary and/or accountant
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Client Personal Data&#34;</td>
                          <td> as defined in clause 9.1 of these Deliverables Portal T&Cs;</td>
                        </tr>
                        <tr>
                          <td>&#34;Data Protection Law&#34;</td>
                          <td>
                            {' '}
                            any applicable legislation or other law in force from time to time in the United Kingdom relating to privacy or the protection of
                            Personal Data or personal information, including the Data Protection Act 1998 and, to the extent it is applicable and in force,
                            Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of Personal Data and on the free
                            movement of such data;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Date of Access&#34;</td>
                          <td>
                            {' '}
                            the date on which the Client Party and/or a Client Party Affiliate, via the Client End User(s), first obtains access to the
                            Deliverables Portal;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Deletion Date&#34;</td>
                          <td> One (1) month after the Contract Period End Date;</td>
                        </tr>
                        <tr>
                          <td>&#34;Deliverables Portal&#34;</td>
                          <td>
                            {' '}
                            the virtual facility designed as a mechanism for MediAccounts to request, and the Consultant and/or Consultant Affiliate (as
                            applicable) to deliver, Information relating to the Services under the MediAccounts Contract
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Destructive Elements&#34;</td>
                          <td>
                            {' '}
                            any software programme or code intended to destroy, interfere with, corrupt or have a disruptive effect on programme files, data or
                            other information, executable code or application software macros;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Documentation&#34;</td>
                          <td>
                            {' '}
                            any user guide documentation in relation to the Deliverables Portal supplied or made available to the Consultants or Consultant
                            Affiliate by MediAccounts, whether in printed or electronic form and as amended, modified or replaced from time to time;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;MediAccounts Contract&#34;</td>
                          <td>
                            {' '}
                            a contract (as may be amended, modified or replaced from time to time) pursuant to which MediAccounts agrees to provide Services to
                            the Consultant or Consultant Affiliate(s) in respect of which it has been agreed that the Deliverables Portal will be used in
                            connection with the provision of those Services;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Engaging Party&#34;</td>
                          <td> MediAccounts providing Services to the Consultants and Consultant Affiliate(s) pursuant to a contract</td>
                        </tr>
                        <tr>
                          <td>&#34;IT Support&#34;</td>
                          <td>
                            {' '}
                            routine and emergency IT support activities, including, site administration, troubleshooting, system maintenance and ad-hoc
                            technical support;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Information&#34;</td>
                          <td>
                            {' '}
                            all documents, information and assistance, including Personal Data, IT systems and infrastructure that MediAccounts may require to
                            undertake the Services;
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Personal Data&#34;</td>
                          <td> as defined in the Data Protection Law;</td>
                        </tr>
                        <tr>
                          <td>&#34;MediAccounts&#34;</td>
                          <td> MediAccounts LTD</td>
                        </tr>
                        <tr>
                          <td>&#34;Services&#34;</td>
                          <td>
                            {' '}
                            the services delivered to the Consultants or the Consultants Affiliate(s) by MediAccounts as detailed in and subject to the Contract
                          </td>
                        </tr>
                        <tr>
                          <td>&#34;Sub-Processors&#34;</td>
                          <td> as defined in clause 9.4;</td>
                        </tr>
                        <tr>
                          <td>&#34;Term&#34;</td>
                          <td> the period commencing on the Date of Access and continuing until the Termination Date; and</td>
                        </tr>
                        <tr>
                          <td>&#34;Termination Date&#34;</td>
                          <td> the date upon which any of the events specified in clauses 8.1 or 8.2 occur.</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    In the case of any conflict between these Deliverables Portal T&Cs and the relevant Contract shall prevail between the applicable parties to
                    that Contract except to the extent expressly stated otherwise in these Deliverables Portal T&Cs.
                  </li>
                  <li>
                    Amendment to these Deliverables Portal T&Cs may be made only by specific reference to the relevant clause in these Deliverables Portal T&Cs
                    and must be in writing signed by each of the parties to the Agreement.
                  </li>
                  <li>
                    Each signatory to the Agreement confirms that they are duly authorised to enter into the Agreement on behalf of the entities indicated in
                    the relevant signature block.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Access</strong>
                <ol>
                  <li>
                    MediAccounts shall use its reasonable endeavours to procure that, in connection with the Services under the relevant Contract and subject to
                    these Deliverables Portal T&Cs, Consultants and Consultants Affiliate(s) (as applicable) have access to the Deliverables Portal. The parties
                    acknowledge that MediAccounts will not charge a service fee for access to the Deliverables Portal by the Consultant OR Consultants
                    Affiliates for those consultants already entered into an agreement on or before January 2021. After this date a fee of £100 a year will be
                    charged for access to the Deliverables Portal by the Consultant OR Consultants Affiliates.
                  </li>
                  <li>
                    The Consultants or the consultants Affiliate (as applicable) may engage a third party to access or upload Information for purposes relating
                    to Services under the relevant contract to the Deliverables Portal on behalf of the consultants or consultants Affiliate(s) (as applicable)
                    provided that
                    <ol>
                      <li>
                        this is done on terms requiring the third party to comply with terms equivalent to these Deliverables Portal T&Cs and the relevant
                        contract in respect of such actions;
                      </li>
                    </ol>
                  </li>
                  <li>
                    the consultant or consultants Affiliate(s) (as applicable) shall notify MediAccounts in accordance with these Deliverables Portal T&Cs in
                    advance. the consultant or consultants Affiliate(s) (as applicable) acknowledge and agree that any such engagement of a third party does not
                    in any way relieve the consultant or consultants Affiliate(s) (as applicable) of their obligations under these Deliverables Portal T&Cs. The
                    consultant or consultants Affiliate(s) (as applicable) shall be liable for the actions or omissions of any such third party in relation to
                    the Deliverables Portal as if they were the actions or omissions of the consultant or consultants Affiliate(s) (as applicable). Other than
                    requests detailed in Schedule 2 of the Agreement at the time the Agreement is signed, all requests from the consultant or consultants
                    Affiliate(s) (as applicable) for:
                    <ol>
                      <li>an individual to be granted access to the Deliverables Portal; or</li>
                      <li>access to the Deliverables Portal to be removed from an individual,</li>
                    </ol>
                    shall be notified by the consultant or consultants Affiliate(s) (as applicable) to that email address or those email addresses MediAccounts
                    as set out in Schedule 1 to the Agreement (as such email address(es) may be amended from time to time in accordance with clause 6 of these
                    Deliverables Portal T&Cs), containing the information set out in Schedule 2 to the Agreement. The parties acknowledge that notifications in
                    respect of this clause 2.3 shall only be submitted by email pursuant to clause 6.1 and not by any other means.
                  </li>
                  <li>Any MediAccounts Entity may deactivate a Consultant’s user access to the Deliverables Portal at any time and without notice.</li>
                  <li>
                    Notwithstanding any other provision of these Deliverables Portal T&Cs and subject to clause 2.7 below, the consultant or consultants
                    Affiliate(s) (as applicable) acknowledge and agree that MediAccounts may, if MediAccounts determines it to be appropriate (acting
                    reasonably):
                    <ol>
                      <li>
                        itself access the Deliverables Portal and download or copy Information for purposes relating to Services under the relevant MediAccounts
                        Contract and/or IT Support without the consent of the consultant or consultants Affiliate(s) (as applicable) provided always that such
                        Information is then held in accordance with these Deliverables Portal T&Cs and, if applicable, the MediAccounts Contract; and
                      </li>
                      <li>
                        engage a non MediAccounts Entity third party to access, download or copy Information from the Deliverables Portal for purposes relating
                        to the Services under the relevant MediAccounts Contract and/or IT Support provided that this is done on terms requiring the third party
                        to comply with terms equivalent to these Deliverables Portal T&Cs and, if applicable, the relevant MediAccounts Contract in respect of
                        such actions. MediAccounts acknowledges and agrees that any such engagement of a third party does not in any way relieve MediAccounts of
                        its obligations under these Deliverables Portal T&Cs or, if applicable, the relevant MediAccounts Contract. MediAccounts shall be liable
                        for the actions or omissions of any such third party under this clause 2.5.2 in relation to the Deliverables Portal as if they were the
                        actions or omissions of MediAccounts as applicable.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Where MediAccounts takes Information out of the Deliverables Portal, it will be held in accordance with the terms of the relevant
                    MediAccounts Contract.
                  </li>
                  <li>
                    The consultant or consultants Affiliate(s) (as applicable) acknowledge and agree that MediAccounts will review only the Information
                    requested by MediAccounts and it shall not be deemed to have reviewed, or be obliged to review, Information that was not requested by it in
                    relation to the Services provided under the relevant MediAccounts Contract.
                    <br />
                    Notwithstanding any other provision of these Deliverables Portal T&Cs, the Consultant or Consultants Affiliate(s) (as applicable)
                    acknowledge and agree that MediAccounts may not review all of the Information provided in response to a particular request by MediAccounts.
                    In determining what Information to review, MediAccounts will exercise its reasonable professional judgement in accordance with and subject
                    to the relevant MediAccounts Contract.
                  </li>
                  <li>
                    MediAccounts reserve the right to remove any Information that they believe has been uploaded to the Deliverables Portal in breach of these
                    Deliverables Portal T&Cs or in breach of applicable laws and regulations. The Consultant or Consultants Affiliate(s) (as applicable)
                    acknowledge and agree that MediAccounts shall be under no duty to review such Information for the purposes of providing the Services.
                  </li>
                  <li>
                    The Consultant or Consultants Affiliate(s) (as applicable) acknowledge and agree that the Deliverables Portal: (i) is a delivery mechanism
                    via which MediAccounts can request, and The Consultant or Consultants Affiliate(s) (as applicable) can provide, Information to assist with
                    the provision of the Services; and (ii) is not a permanent document depository.
                  </li>
                </ol>
              </li>
              <li>
                <strong>The Consultant or Consultants Affiliate(s) Obligations</strong>
                <ol>
                  <li>
                    During the Term, The Consultant or Consultants Affiliate(s) shall:
                    <ol>
                      <li>ensure that all users of the Deliverables Portal have been made aware of, and comply with, these Deliverables Portal T&Cs;</li>
                      <li>
                        use (or procure the use of) information and data protection procedures to prevent unauthorised disclosure or use of confidential
                        information and Personal Data;
                      </li>
                      <li>maintain, and procure that any User maintains, appropriate technology to access the Deliverables Portal;</li>
                      <li>
                        not upload to the Deliverables Portal any Information that is unlawful or could be reasonably viewed as false, offensive, indecent,
                        libellous, defamatory or obscene or that contains any Destructive Elements;
                      </li>
                      <li>
                        follow all reasonable instructions given from time to time by MediAccounts with regard to the use of the Deliverables Portal and respond
                        promptly to any queries or reasonable requests from MediAccounts in connection with the Deliverables Portal;
                      </li>
                      <li>
                        ensure that any provision of Information by The Consultant or Consultants Affiliate(s) (as applicable), via the Client End Users, in
                        respect of, or to, the Deliverables Portal is in accordance with all applicable laws and regulations;
                      </li>
                      <li>
                        not, nor attempt to, alter or modify any part of the Deliverables Portal or any Documentation in any way nor decompile, disassemble,
                        reproduce or reverse engineer the Deliverables Portal or combine the Deliverables Portal with any other software programme;
                      </li>
                      <li>
                        promptly notify in writing to MediAccounts of any issues with the Deliverables Portal in accordance with these Deliverables Portal T&Cs;
                      </li>
                      <li>
                        ensure that the provision of Information onto the Deliverables Portal by Client End Users has been appropriately authorised within the
                        Client Party’s organisation and/or the relevant Client Party Affiliate’s organisation;
                      </li>
                      <li>
                        promptly notify in writing MediAccounts in accordance with these Deliverables Portal T&Cs of the termination of employment or other work
                        relationship of any Affiliate (as applicable) and ensure that the User immediately ceases all use of the Deliverables Portal;
                      </li>
                      <li>
                        ensure that no user engages in any activity that directly or indirectly interferes with the functionality of the Deliverables Portal,
                        including, spamming and the distribution of computer viruses or other malicious code (including Destructive Elements); and
                      </li>
                      <li>
                        notify MediAccounts in accordance with these Deliverables Portal T&Cs immediately upon becoming aware of any breach of the above
                        Consultant or Consultants Affiliate(s) (as applicable) obligations, including any unauthorised access to the Deliverables Portal.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Consultant or Consultants Affiliate(s) (as applicable) shall be responsible for (i) the operation, performance and security of their
                    equipment, networks and other computing resources used to connect to the Deliverables Portal (ii) ensuring the Users exit or log off from
                    the Deliverables Portal at the end of each session, and (iii) managing the confidentiality of the Users’ ids, passwords and/or personal
                    identification numbers used in conjunction with the Deliverables Portal.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Intellectual Property</strong>
                <br />
                There shall be no transfer or assignment of any intellectual property rights concerning the Deliverables Portal by virtue of these Deliverables
                Portal T&Cs other than the right to use the Deliverables Portal in accordance with these Deliverables Portal T&Cs. All intellectual property
                rights relating to the Deliverables Portal T&Cs are owned by ‘MediAccountsLtd’.
              </li>
              <li>
                <strong>Restrictions on MediAccounts liability concerning the Deliverables Portal</strong>
                <br />
                MediAccounts accepts no liabityfor any loss of data or for a data breach.
              </li>
              <li>
                <strong>Notices</strong>
                <ol>
                  <li>
                    Any notice to be given by one party to another party concerning these Deliverables Portal T&Cs shall be in writing and shall, unless a
                    particular method of delivery is required under these Deliverables Portal T&Cs, be sent by email to that email address or those email
                    addresses set out in Schedule 1 to the Agreement (as such email address(es) may be amended from time to time in accordance with clause 6.2).
                  </li>
                  <li>
                    A party may change its details for service of notices as specified in Schedule 1 to the Agreement by giving notice to the other parties. Any
                    change notified pursuant to this Clause shall take effect at 9.00 am on the later of (a) the future date, if any, specified in the notice as
                    the effective date for the change; and (b) five (5) Business Days after deemed receipt of the notice of change.
                  </li>
                  <li>Any notice shall be deemed to have been received at 9.00 am on the next Business Day after transmission.</li>
                  <li>This clause 6 does not apply to the service of any proceedings or other documents in any legal action.</li>
                </ol>
              </li>
              <li>
                <strong>Deletion</strong>
                <ol>
                  <li>
                    Upon the Deletion Date:
                    <ol>
                      <li>
                        Without prejudice to the relevant MediAccounts Contract, Information uploaded onto the Deliverables Portal may be deleted by
                        MediAccounts, along with any back-up of that data; and
                      </li>
                      <li>
                        Client End Users’ access to the Deliverables Portal will be restricted such that they will be unable to access the area of the
                        Deliverables Portal in which MediAccounts requested, and the The Consultant or Consultants Affiliate(s) (as applicable) provided,
                        Information.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <strong>Termination</strong>
                <ol>
                  <li>These Deliverables Portal T&Cs will terminate automatically upon the termination or expiry of the MediAccounts Contract.</li>
                  <li>
                    Any party may terminate these Deliverables Portal T&Cs:
                    <ol>
                      <li>
                        forthwith on giving notice in writing in accordance with these Deliverables Portal T&Cs to the other parties of any material breach of
                        these Deliverables Portal T&Cs by failure, neglect or refusal to comply with any of the material terms and conditions of these
                        Deliverables Portal T&Cs and failure to remedy that breach within thirty (30) days of the date of notice specifying the breach; or
                      </li>
                      <li>on thirty (30) days’ prior notice to the other parties in writing in accordance with these Deliverables Portal T&Cs.</li>
                    </ol>
                  </li>
                  <li>
                    Upon the Termination Date:
                    <ol>
                      <li>Information uploaded onto the Deliverables Portal may be deleted by MediAccounts, along with any back up of that data; and</li>
                      <li>
                        the access of The Consultant or Consultants Affiliate(s) (as applicable) (including access of Client End Users) to the Deliverables
                        Portal will end after one (1) month. Please make sure all reports are downloaded before this date.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Within fourteen (14) days of the Termination Date, The Consultant or Consultants Affiliate(s) (as applicable) shall return any Documentation
                    to MediAccounts and all copies of any part thereof or, alternatively, erase any Documentation from all of the equipment on which it is
                    stored, in either case to the maximum extent technically possible at that time and subject to any applicable law restrictions. The
                    Consultant or Consultants Affiliate(s) (as applicable) shall, if requested, certify in writing that it has so complied with this clause 8.4.
                  </li>
                  <li>
                    Termination of these Deliverables Portal T&Cs shall be without prejudice to any other rights or remedies any party may be entitled to under
                    these Deliverables Portal T&Cs or at law and shall not affect any accrued rights or liabilities of any party.
                  </li>
                  <li>
                    The provisions of clauses 4, 5, 6, 7, 8, 11 and 12 and any other provisions expected to continue or to come into effect after termination
                    shall survive the termination of these Deliverables Portal T&Cs and shall remain in full force and effect.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Data Processing Services</strong>
                <ol>
                  <li>
                    MediAccounts will process Data uploaded onto the Deliverables Portal as a processor on behalf of the The Consultant or Consultants
                    Affiliate(s) (as applicable) (&#34;Client Personal Data&#34;), who will be the controller. MediAccounts shall process Client Personal Data
                    on behalf of the The Consultant or Consultants Affiliate(s) (as applicable) in accordance with these Deliverables Portal T&Cs and other
                    written instructions received from The Consultant or Consultants Affiliate(s) (as applicable) from time to timeMediAccounts shall notify The
                    Consultant or Consultants Affiliate(s) (as applicable) if it believes that the instructions infringe Data Protection Law unless informing
                    The Consultant or Consultants Affiliate(s) (as applicable) is prohibited by law on important grounds of public interest.
                  </li>
                  <li>
                    MediAccounts shall keep Client Personal Data confidential and shall implement appropriate technical and organisational security measures
                    (including confidentiality obligations towards all staff working with Client Personal Data) to ensure a level of security appropriate to the
                    risks that are presented by the processing of Patient Personal Data, in particular accidental loss and unlawful processing. In case of a
                    data breach under these Deliverables Portal T&Cs which may affect Client/Patient Personal Data, MediAccounts shall notify The Consultant or
                    Consultants Affiliate(s) (as applicable) without undue delay after having become aware of it. We will then implement our GDPR Data Breach
                    Policy accordingly.
                  </li>
                  <li></li>
                  <li>
                    The Consultant or Consultants Affiliate(s) (as applicable) acknowledge and agree that MediAccounts may retain members of the the
                    MediAccounts team and other third parties as sub-processors (all together &#34;Sub-Processors&#34;) in connection with the provision of the
                    Deliverables Portal having imposed on such Sub-Processors the same data protection obligations as are imposed on MediAccounts under these
                    Deliverables Portal T&Cs.
                  </li>
                  <li>
                    MediAccounts shall implement measures to assist the The Consultant or Consultants Affiliate(s) (as applicable) in complying with the rights
                    of data subjects.
                  </li>
                </ol>
              </li>
              <li>
                <strong>International Data Transfers</strong>
                <br />
                To the extent that the processing of Client Personal Data under clause 9 involves the transfer of such Client Personal Data to a territory that
                does not provide an adequate level of protection, the parties undertake to provide appropriate safeguards in accordance with Data Protection Law
                and to that effect the relevant standard data protection clauses adopted by the European Commission and valid from time to time are incorporated
                by reference and will deemed to be binding on the parties.
              </li>
              <li>
                <strong>Rights of Third Parties</strong>
                <ol>
                  <li>
                    The Consultant or Consultants Affiliate(s) (as applicable) acknowledge and agree that MediAccounts may enforce any of these Deliverables
                    Portal T&Cs under the Contract (Rights of Third Parties) Act 1999.
                  </li>
                  <li>
                    Subject to clause 11.1 above, no person may enforce these Deliverables Portal T&Cs by virtue of the Contracts (Rights of Third Parties) Act
                    1999.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Jurisdiction</strong>
                <br />
                These Deliverables Portal T&Cs will be governed by and construed in accordance with English law and the parties hereby submit to the exclusive
                jurisdiction of the English courts.
              </li>
            </ol>
          </div>
          <p className="font-weight-bold mb-4 text-uppercase text-danger">Step 2. Agreement to the MediAccounts Ltd Portal Terms and Conditions</p>
          <div className="terms-approval-panel mb-4">
            <p>
              1. This agreement, including Schedules 1 and 2 (the &#34;<strong>Agreement</strong>&#34;) incorporates the Deliverables Portal Terms and
              Conditions and relates to the use of the Deliverables Portal in connection with the Services<sup>1</sup> being provided under the MediAccounts
              Contract<sup>1</sup>.
            </p>
            <p>
              2. This Agreement may be executed in any number of counterparts, each of which when executed shall constitute a duplicate original, but all the
              counterparts shall together constitute one agreement.
            </p>

            <p>
              Each of the parties hereunder acknowledges receipt of the Deliverables Portal Terms and Conditions and, in consideration for the respective rights
              and obligations set out therein, agrees to abide by and be bound by the Deliverables Portal Terms and Conditions, in connection with the Services
              provided under the relevant MediAccounts Contract{' '}
            </p>

            <p>
              <sup>1</sup> As defined in the Deliverables Portal Terms and Conditions
            </p>

            <p className="text-center">
              <button type="button" className={`btn btn-lg btn-primary`} onClick={this.agreeToTerms.bind(this)}>
                I Agree
              </button>
            </p>
            <p className="text-center">
              <a href="/portal-terms-and-conditions-2020.docx" target="_blank">
                Download a copy of the Terms and Conditions
              </a>
            </p>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
