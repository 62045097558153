import { CCSD_CODE_TYPE_LIST_REQUEST, CCSD_CODE_TYPE_LIST_RECEIVED, CCSD_CODE_TYPE_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    ccsdCodeTypes: [],
    isFetchingCcsdCodeTypes: false,
  },
  action,
) => {
  switch (action.type) {
    case CCSD_CODE_TYPE_LIST_REQUEST:
      return {
        ...state,
        isFetchingCcsdCodeTypes: true,
      };

    case CCSD_CODE_TYPE_LIST_RECEIVED:
      return {
        ...state,
        ccsdCodeTypes: action.data['hydra:member'],
        isFetchingCcsdCodeTypes: false,
      };

    case CCSD_CODE_TYPE_LIST_ERROR:
      return {
        ...state,
        isFetchingCcsdCodeTypes: false,
      };
    default:
      return state;
  }
};

export default reducer;
