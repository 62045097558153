import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { userLoginAttempt } from '../../../lib/redux/actions/user';
import Card from 'react-bootstrap/Card';
import { Link, Redirect } from 'react-router-dom';
import * as ROUTES from '../../../lib/routing/frontend';
import Form from 'react-bootstrap/Form';
import WordpressPage from '../../common/WordpressPage';

const mapStateToProps = (state) => ({
  ...state.auth,
});
const mapDispatchToProps = {
  userLoginAttempt,
};

class LoginForm extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.props.history.push(ROUTES.AUTH2FA + this.props.location.search);
    }
  }

  onSubmit(values) {
    return this.props.userLoginAttempt(values.username, values.password);
  }

  loginUser(username) {
    return this.props.userLoginAttempt(username, 'password');
  }

  render() {
    const { handleSubmit, error, isAuthenticated } = this.props;
    if (isAuthenticated) {
      // console.log('Redirect from Login to Home because Authed');
      return <Redirect to={ROUTES.AUTH2FA} />;
    }
    return (
      <>
        <Card className="login-card">
          <Card.Header>Log in</Card.Header>
          <Card.Body>
            <WordpressPage pageId={818} />

            {error && <div className="alert alert-danger">{error}</div>}
            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <Field name="username" label="Email Address" type="text" component={renderField} />
              <Field name="password" label="Password" type="password" component={renderField} />
              {/*<Field name="challenge" label="Authentication Code" type="text" component={renderField}/>*/}
              <button type="submit" className="btn btn-primary btn-block">
                Login
              </button>
            </Form>
            <p className="text-center mt-4">
              <Link to={ROUTES.FORGOT_PASSWORD}>Forgotten Password?</Link>
            </p>
          </Card.Body>
        </Card>
        <p className="login-help text-center text-underline mt-4">
          <Link to={ROUTES.SUPPORT}>Problems logging in?</Link>
        </p>
      </>
    );
  }
}

export default reduxForm({
  form: 'LoginForm',
})(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
