import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import EditableCcsdCodeCost from '../ccsdCodeCost/EditableCcsdCodeCost';
import MASpinner from '../common/MASpinner';

class DefaultCcsdCodeCostTable extends React.Component {
  getRelevantDefaultCcsdCodeCost(healthInsurer) {
    const { ccsdCode } = this.props;
    const relevantCcsdCodeCost = ccsdCode.defaultCcsdCodeCosts.find((ccsdCodeCost) => {
      return ccsdCodeCost.healthInsurer.id === healthInsurer.id;
    });
    return relevantCcsdCodeCost
      ? relevantCcsdCodeCost
      : {
          id: false,
          healthInsurer: {
            feeAssured: false,
          },
          currentCosts: [
            {
              cost: false,
              validFrom: new Date(),
            },
          ],
        };
  }

  render() {
    const { ccsdCode, healthInsurers = [], consultantTypes = [] } = this.props;

    if (!consultantTypes.length) {
      return <MASpinner />;
    }

    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-coins" />
        </div>
        <h2 className="crud__section-header">CCSD Code Fee Assured Costs</h2>

        <Table hover bordered responsive className="consultant-ccsd-table default-ccsd-table mb-0">
          <thead>
            <tr>
              <th>Insurer</th>
              {consultantTypes.map((consultantType) => (
                <th className="text-right" key={consultantType.code}>
                  {consultantType.displayName} Cost
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {healthInsurers
              .filter((healthInsurer) => !healthInsurer.isAnalogue)
              .map((healthInsurer) => (
                <tr key={healthInsurer.id}>
                  <td>{healthInsurer.name}</td>

                  {consultantTypes.map((consultantType) => (
                    <EditableCcsdCodeCost
                      key={`${ccsdCode.id}-${healthInsurer.id}-${consultantType.code}`}
                      ccsdCode={ccsdCode}
                      defaultCcsdCodeCost={this.getRelevantDefaultCcsdCodeCost(healthInsurer)}
                      healthInsurer={healthInsurer}
                      consultantType={consultantType}
                      isDefault={true}
                    />
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DefaultCcsdCodeCostTable;

DefaultCcsdCodeCostTable.propTypes = {
  healthInsurers: PropTypes.array.isRequired,
  ccsdCode: PropTypes.object.isRequired,
};
