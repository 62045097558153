import React from 'react';
import { invoiceBatchSingleUseOriginalFiles } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import { addToast } from '../../lib/redux/actions/toast';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  invoiceBatchSingleUseOriginalFiles,
  addToast,
};

class UseOriginalFilesButton extends React.Component {
  useOriginalFiles() {
    const { invoiceBatchSingleUseOriginalFiles, invoiceBatch, addToast } = this.props;
    return invoiceBatchSingleUseOriginalFiles(invoiceBatch.id).then(() => {
      addToast('The invoice batch was set to use the original files', true);
    });
  }

  render() {
    const { invoiceBatch } = this.props;
    if (!isSeniorStaff() || Number(invoiceBatch.status.code) !== Number(INVOICE_BATCH_STATUS.SUBMITTED)) {
      return null;
    }
    return (
      <button type="button" onClick={this.useOriginalFiles.bind(this)} className="btn btn-info">
        <i className="fa fa-arrow-circle-o-right mr-2" />
        Use Original Files
      </button>
    );
  }
}

UseOriginalFilesButton.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  invoiceBatchSingleUseOriginalFiles: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UseOriginalFilesButton);
