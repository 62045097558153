import React from 'react';
import classNames from 'classnames';
import Card from 'react-bootstrap/Card';

class Paginator extends React.Component {
  constructor(props) {
    super(props);
    const { pageCount } = props;

    this.range = [];

    for (let i = 1; i <= pageCount; i++) {
      this.range.push(i);
    }
  }

  renderPagination() {
    const { currentPage, setPage, nextPage, prevPage, pageCount } = this.props;
    return (
      <div className="btn-group btn-group--pagination" role="group">
        <button type="button" className="btn btn-secondary" disabled={currentPage === 1} onClick={prevPage}>
          &larr;
        </button>
        {currentPage > 4 && (
          <button
            type="button"
            className="btn btn-secondary"
            disabled={currentPage === 1}
            onClick={() => {
              setPage(1);
            }}>
            1
          </button>
        )}
        {currentPage > 5 && (
          <button type="button" className="btn btn-secondary" disabled={true}>
            ...
          </button>
        )}
        {this.range.map((page) => {
          if (page > currentPage + 3 || page < currentPage - 3 || page > pageCount) {
            return null;
          }
          const onClick = () => {
            setPage(page);
          };
          return (
            <button className={classNames('btn', 'btn-secondary', { active: currentPage === page })} key={page} onClick={onClick}>
              {page}
            </button>
          );
        })}
        {currentPage < pageCount - 4 && (
          <button type="button" className="btn btn-secondary" disabled={true}>
            ...
          </button>
        )}
        {currentPage < pageCount - 3 && (
          <button
            type="button"
            className="btn btn-secondary"
            disabled={currentPage === this.range.length}
            onClick={() => {
              setPage(pageCount);
            }}>
            {pageCount}
          </button>
        )}
        <button type="button" className="btn btn-secondary" disabled={currentPage === this.range.length} onClick={nextPage}>
          &rarr;
        </button>
      </div>
    );
  }

  render() {
    const { pageCount, withCard = true } = this.props;

    if (pageCount < 2) {
      return null;
    }
    if (!withCard) {
      return this.renderPagination();
    }
    return (
      <Card style={{ marginTop: '0px' }}>
        <Card.Body className="tabular-data-actions">{this.renderPagination()}</Card.Body>
      </Card>
    );
  }
}

export default Paginator;
