import React from 'react';
import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { consultantSingleFetchByIRI, consultantSingleUnload } from '../../lib/redux/actions/consultant';
import { getSubusersConsultantToManage, isConsultant, isSubUser } from '../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.stats,
  ...state.consultantSingle,
});

const mapDispatchToProps = {
  consultantSingleFetchByIRI,
  consultantSingleUnload,
};

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    if (isSubUser(true) && getSubusersConsultantToManage() && getSubusersConsultantToManage().iri) {
      this.props.consultantSingleFetchByIRI(getSubusersConsultantToManage().iri);
    }
    if (isConsultant(true) && this.props.userData && this.props.userData.consultantAsPrimaryUser && this.props.userData.consultantAsPrimaryUser['@id']) {
      this.props.consultantSingleFetchByIRI(this.props.userData.consultantAsPrimaryUser['@id']);
    }
  }

  componentWillUnmount() {
    this.props.consultantSingleUnload();
  }

  render() {
    const { stats = [], consultant = false, path } = this.props;
    return (
      <div>
        <Dashboard stats={stats} path={path} consultant={consultant} match={this.props.match} history={this.props.history} location={this.props.location} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
