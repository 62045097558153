import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../lib/helper/form';
import { connect } from 'react-redux';
import { invoiceBatchSingleComplete, invoiceBatchSingleUpdate } from '../../lib/redux/actions/invoiceBatch';
import { validateInvoiceNumbers, validateInvoiceNumbersReset } from '../../lib/redux/actions/validateInvoiceNumbers';
import { addToast } from '../../lib/redux/actions/toast';
import MASpinner from '../common/MASpinner';

const mapStateToProps = (state) => {
  const initialValues = {};
  if (state.invoiceBatchSingle.invoiceBatch && state.invoiceBatchSingle.invoiceBatch.invoiceNumbers.length > 0) {
    state.invoiceBatchSingle.invoiceBatch.invoiceNumbers.forEach((invoiceNumber, i) => {
      initialValues[`invoiceNumber_${i}`] = invoiceNumber;
    });
  }
  return {
    isUpdatingInvoiceBatch: state.invoiceBatchSingle.isUpdatingInvoiceBatch,
    checkedInvoiceNumbers: state.validateInvoiceNumbers.checkedInvoiceNumbers,
    isValidating: state.validateInvoiceNumbers.isValidating,
    checkedInvoiceNumberError: state.validateInvoiceNumbers.error,
    initialValues,
  };
};

const mapDispatchToProps = {
  invoiceBatchSingleUpdate,
  invoiceBatchSingleComplete,
  validateInvoiceNumbers,
  validateInvoiceNumbersReset,
  addToast,
};

class InvoiceBatchEditCompleteInvoiceNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      override: false,
    };
  }
  componentWillUnmount() {
    this.props.validateInvoiceNumbersReset();
  }

  renderInvoiceNumberFormFields(invoiceBatch) {
    const fieldArr = [];
    let i = 0;
    const numberOfFieldsRequired =
      invoiceBatch.invoiceNumbers.length > invoiceBatch.numberOfInvoicesToProcess ? invoiceBatch.invoiceNumbers.length : invoiceBatch.numberOfInvoicesToProcess;

    while (i < numberOfFieldsRequired) {
      if (i % 4 === 0) {
        fieldArr.push(<div key={`space${i}`} className="w-100" />);
      }
      fieldArr.push(
        <Col key={i} sm={3}>
          <Field name={`invoiceNumber_${i}`} label={null} type="text" groupClasses="mb-1" component={renderField} />
        </Col>,
      );
      i++;
    }
    return fieldArr;
  }

  validateInvoiceNumbers(invoiceNumbers, invoiceBatchId) {
    const { validateInvoiceNumbers, checkedInvoiceNumberError } = this.props;

    return validateInvoiceNumbers(invoiceNumbers, invoiceBatchId).then((invoiceNumbers) => {
      if (!checkedInvoiceNumberError && typeof invoiceNumbers !== 'undefined') {
        return invoiceNumbers.some(function (invoiceNumber) {
          return invoiceNumber.hasRecords === true;
        });
      }
    });
  }

  findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);

  onSubmit(values) {
    const { invoiceBatch, invoiceBatchSingleUpdate, addToast, validateInvoiceNumbersReset, editInvoiceNumbersHandler } = this.props;
    const { override } = this.state;
    delete values.action;
    this.setState({ rangeContainsDuplicates: false });

    const invoiceNumbers = Object.values(values).filter((item) => item);
    const duplicates = [...new Set(this.findDuplicates(invoiceNumbers))];

    if (duplicates.length > 0) {
      addToast('You cannot submit duplicate invoice numbers: ' + duplicates.join(), false, true);
      return;
    }
    this.validateInvoiceNumbers(
      Object.values(values).filter((item) => item),
      invoiceBatch.id,
    ).then((hasRecords) => {
      if (!hasRecords || override) {
        return invoiceBatchSingleUpdate({ invoiceNumbers }, invoiceBatch.id).then(() => {
          editInvoiceNumbersHandler();
          addToast('Invoice Numbers saved', true);
          validateInvoiceNumbersReset();
          this.setState({ override: false });
        });
      }
    });
  }

  render() {
    const { submitting, isUpdatingInvoiceBatch, pristine, invoiceBatch, handleSubmit, checkedInvoiceNumbers, isValidating } = this.props;

    let duplicateInvoiceNumbers = false;
    let duplicateInvoiceNumbersArr = [];
    if (!isValidating && checkedInvoiceNumbers.length > 0) {
      duplicateInvoiceNumbersArr = checkedInvoiceNumbers
        .filter((invoiceNumber) => invoiceNumber.hasRecords === true)
        .map((invoiceNumber) => {
          return invoiceNumber.invoiceNumber;
        });
      duplicateInvoiceNumbers = duplicateInvoiceNumbersArr.join();
    }

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Form.Row className="mb-2">{this.renderInvoiceNumberFormFields(invoiceBatch)}</Form.Row>
        {isUpdatingInvoiceBatch ? (
          <MASpinner />
        ) : (
          <button type="submit" disabled={pristine || submitting || isUpdatingInvoiceBatch} className="btn btn-primary mr-2">
            Save
          </button>
        )}
        {!isUpdatingInvoiceBatch && duplicateInvoiceNumbers && (
          <>
            <div className="alert alert-danger">
              Invoice Number{duplicateInvoiceNumbers.toString().indexOf(',') > -1 && 's'} <strong>{duplicateInvoiceNumbers}</strong>{' '}
              {duplicateInvoiceNumbers.toString().indexOf(',') > -1 ? 'are' : 'is'} already in use, do you wish to use{' '}
              {duplicateInvoiceNumbers.toString().indexOf(',') > -1 ? 'them' : 'it'} anyway?
            </div>
            <button
              type="submit"
              onClick={() => {
                this.setState({ override: true });
              }}
              value="override"
              disabled={pristine || submitting || isUpdatingInvoiceBatch}
              className="btn btn-danger mr-2">
              Save Anyway
            </button>
          </>
        )}
      </Form>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
InvoiceBatchEditCompleteInvoiceNumbers = reduxForm({
  form: 'InvoiceBatchRelatedInvoiceNumbersForm',
  enableReinitialize: true,
})(InvoiceBatchEditCompleteInvoiceNumbers);

// You have to connect() to any reducers that you wish to connect to yourself
InvoiceBatchEditCompleteInvoiceNumbers = connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchEditCompleteInvoiceNumbers);

export default InvoiceBatchEditCompleteInvoiceNumbers;
