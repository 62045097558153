import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import {
  INVOICE_FILE_DELETE_REQUEST,
  INVOICE_FILE_DELETE_SUCCESS,
  INVOICE_FILE_UPLOAD_CLEAR_REFERENCES,
  INVOICE_FILE_UPLOAD_ERROR,
  INVOICE_FILE_UPLOAD_REQUEST,
  INVOICE_FILE_UPLOAD_SUCCESS,
} from '../constants';
export const fileUploadSuccess = (data) => {
  return {
    type: INVOICE_FILE_UPLOAD_SUCCESS,
    file: data,
  };
};

export const fileUploadRequest = () => {
  return {
    type: INVOICE_FILE_UPLOAD_REQUEST,
  };
};

export const fileUploadError = () => {
  return {
    type: INVOICE_FILE_UPLOAD_ERROR,
  };
};

export const fileUpload = (file) => {
  return (dispatch) => {
    dispatch(fileUploadRequest());
    //console.log('Uploading file...');
    return requests
      .upload(API_ROUTES.TEMPORARY_CC_INVOICE_FILES, file)
      .then((response) => dispatch(fileUploadSuccess(response)))
      .catch(() => dispatch(fileUploadError()));
  };
};

export const fileUploadClearReferences = () => {
  return {
    type: INVOICE_FILE_UPLOAD_CLEAR_REFERENCES,
  };
};

export const fileClearReferences = () => {
  return (dispatch) => {
    //console.log('Clear Temporary file references...');
    dispatch(fileUploadClearReferences());
  };
};

export const fileDeleteSuccess = (id) => {
  return {
    type: INVOICE_FILE_DELETE_SUCCESS,
    id,
  };
};

export const fileDeleteRequest = () => {
  return {
    type: INVOICE_FILE_DELETE_REQUEST,
  };
};

export const fileDelete = (id) => {
  return (dispatch) => {
    dispatch(fileDeleteRequest());
    //console.log('Deleting Image...');
    return requests.delete(`${API_ROUTES.TEMPORARY_INVOICE_FILES}/${id}`).then(() => dispatch(fileDeleteSuccess(id)));
    //    .catch(error => dispatch(imageDeleteError()));
  };
};
