import React from 'react';
import { connect } from 'react-redux';
import {
  consultantBibleLogUnload,
  consultantBibleLogSetPage,
  consultantBibleLogUpdated,
  getConsultantBibleLog,
} from '../../lib/redux/actions/consultantBibleLog';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Paginator from '../common/Paginator';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { niceDateTimeFormatter, userNameFormatter } from '../../lib/helper/formatting';

const mapStateToProps = (state) => ({
  ...state.consultantBibleLog,
  ...state.auth,
});

const mapDispatchToProps = {
  consultantBibleLogUpdated,
  consultantBibleLogSetPage,
  consultantBibleLogUnload,
  getConsultantBibleLog,
};

class ConsultantBibleLog extends React.Component {
  componentWillUnmount() {
    this.props.consultantBibleLogUnload();
  }

  componentDidMount() {
    this.loadConsultantBibleLog();
  }

  loadConsultantBibleLog() {
    const { filterByConsultant = false } = this.props;
    const filters = {};
    if (filterByConsultant !== false) {
      filters.consultant = filterByConsultant['@id'];
    }
    this.props.getConsultantBibleLog(this.getQueryParamPage(), filters);
  }

  componentDidUpdate(prevProps) {
    const { currentPage, getConsultantBibleLog, filterByConsultant = false } = this.props;

    const filters = {};
    if (filterByConsultant !== false) {
      filters.consultant = filterByConsultant['@id'];
    }
    if (prevProps.currentPage !== currentPage) {
      getConsultantBibleLog(currentPage, filters);
    }
  }

  changePage(page) {
    const { history, consultantBibleLogSetPage, path } = this.props;
    consultantBibleLogSetPage(page);
    history.push(path.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);

    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  render() {
    const { consultantBibleLog, isFetchingConsultantBibleLog, pageCount, currentPage, filterByConsultant = false } = this.props;
    if (!consultantBibleLog || consultantBibleLog.length < 1) {
      return null;
    }
    return (
      <Card className="mb-4">
        <Card.Header className={'text-center card-header--small-text'}>Consultant Bible Updates</Card.Header>
        <Card.Body className="dashboard-statistics-wrapper">
          <Table size="sm">
            <thead>
              <tr className="bg-">
                <th className="text-left">Consultant</th>
                <th className="text-left">At</th>
                <th className="text-left">By</th>
                {!filterByConsultant && <th className="text-center">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {consultantBibleLog.map((log) => (
                <tr key={`${String(log.id).toLowerCase()}`}>
                  <td className="font-weight-bold">{log.consultant.name}</td>
                  <td className="font-weight-bold">{niceDateTimeFormatter(log.updatedAt)}</td>
                  <td className="font-weight-bold">{userNameFormatter(log.createdBy)}</td>
                  {!filterByConsultant && (
                    <td className="text-center">
                      <Link to={'/consultant/' + log.consultant.id + '#bible'}>View Consultant</Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {!isFetchingConsultantBibleLog && consultantBibleLog && consultantBibleLog.length > 0 && (
            <Paginator
              currentPage={currentPage}
              pageCount={pageCount}
              setPage={this.changePage.bind(this)}
              nextPage={this.nextPage.bind(this)}
              prevPage={this.prevPage.bind(this)}
              withCard={false}
            />
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConsultantBibleLog);
