// declare namespace MediAccountsAPI {
export enum ContactRecordTypeCodes {
  Patient = '0',
  Parent = '10',
  Other = '99',
}

export type ContactRecordType = {
  id?: number;
  name: string;
  code: ContactRecordTypeCodes;
};

export type ContactRecord = {
  id?: number;
  name: string;
  email?: string;
  notifyByEmail?: boolean;
  mobileNumber?: string;
  notifyByMobile?: boolean;
  additionalNumber?: string;
  contactRecordType?: ContactRecordType;
  invoice?: string | Invoice;
};

export type Consultant = {
  id: string;
  name?: string;
};

type User = {
  id?: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isEnabled: boolean;
  // consultantAsPrimaryUser: Consultant;
  // consultantsAsSubuser: ConsultantSubuser[];
  // assignedInvoiceBatches: InvoiceBatch[];
  assignedInvoices: Invoice[];
  roles: string[];
  retypedPassword: string;
  newPassword: string;
  retypedNewPassword: string;
  oldPassword: string;
  passwordResetCode: string;
  passwordResetRequestedAt: Date;
  dateTermsRead: Date;
  // onboardingItems: OnboardingItem[];
  onboardingNotes: string;
  // workSchedules: WorkSchedule[];
};

type HasIdAndIRI = {
  '@id'?: string;
  id?: number;
};

export type InvoiceBatch = HasIdAndIRI & {};

export type Invoice = HasIdAndIRI & {
  consultant: Consultant;
  payorName: string;
  // payorType: PayorType;
  // patientName: string; // REMOVED TO FORCE USE OF FIRST NAME AND LAST NAME
  insurerRegistration: string;
  dateOfBirth: Date;
  serviceDateImportString: string;
  serviceDate: Date;
  invoiceNo: string;
  invoicePrefix: string;
  invoiceDateImportString: string;
  invoiceDate: Date;
  shortfallInvoiceDate?: Date;
  disableChasingUntilDate?: Date;
  invoiceAmountImportString: string;
  invoiceAmount: number;
  outstandingBalanceImportString: string;
  outstandingBalance: number;
  isEscalated: boolean;
  isVoid: boolean;
  assignedTo: User;
  isPaid: boolean;
  datePaid: Date;
  uniqueRef: string;
  // invoiceActions: InvoiceAction[];
  isMissingContactDetails: boolean;
  dateOfLastContact: Date;
  lastContactingUser: User;
  createdAt: Date;
  createdBy: User;
  updatedBy: User;
  invoiceAuditLogs: InvoiceAuditLog[];
  patientLastName: string;
  patientFirstName: string;
  missingContactDetailsType: InvoiceMissingDetailsType;
  hasBeenObfuscatedForGDPR: boolean;
  invoiceEscalationReason: InvoiceEscalationReason;
  invoiceEscalationStatus: InvoiceEscalationStatus;
  revisitDate: Date;
  consultantContactedForMissingDetails: boolean;
  contactRecords: ContactRecord[];
  invoiceNotifications: InvoiceNotification[];
  previousInvoicePDFFiles: InvoicePDFFile[];
  invoicePDFFile: InvoicePDFFile;
  claimNumber: string;
  invoiceBatch?: InvoiceBatch;
  disableChasing?: boolean;
  isWrittenOff?: boolean;
  paymentRequests?: PaymentRequest[];
  useAutomatedChasing: boolean;
};

type InvoiceAuditLog = {
  id: number;
  invoice: Invoice;
};

export type InvoiceNotification = {
  id: number;
  invoice: Invoice;
  activityName: string;
  events: InvoiceNotificationEvent[];
};

export type InvoiceNotificationEvent = {
  id: number;
  type: InvoiceNotificationType;
  sentAt: Date;
  createdAt: Date;
  sentTo: string;
  serviceIdentifier?: string;
  status?: string;
  contactRecord?: ContactRecord;
};

export type InvoiceNotificationType = {
  id: number;
  code: string;
  name: string;
};

type InvoicePDFFile = {
  id: number;
  invoice: Invoice;
  // file: File;
  // createdBy: User;
  // createdAt: Date;
};

type InvoiceMissingDetailsType = {
  id: number;
  code: string;
  name: string;
};

type InvoiceEscalationReason = {
  id: number;
  code: string;
  reason: string;
};

type InvoiceEscalationStatus = {
  id: number;
  code: string;
  status: string;
};
// }

type PaymentRequest = {
  id: number;
  paymentProvider: string;
  invoice: Invoice;
  email: string | null;
  amount: number;
  status: PaymentRequestStatus;
  transactionReference: string;
  transactionId: number | null;
  paymentMethod: string | null;
  createdAt: Date;
};

type PaymentRequestStatus = {
  id: number;
  code: string;
  name: string;
};
