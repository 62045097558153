import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';
import { invoiceSingleFetch, invoiceSingleUnload } from '../../lib/redux/actions/invoices';
import OldInvoiceSingleRefOnly from '../../components/invoice/OldInvoiceSingleRefOnly';
import InvoiceSingle from './InvoiceSingle';
import Helmet from 'react-helmet';
import { contactRecordRemove, contactRecordSingleCreate, contactRecordSingleUpdate } from '../../lib/redux/actions/contactRecords';

const mapStateToProps = (state) => ({
  ...state.invoices,
  ...state.stats,
});

const mapDispatchToProps = {
  invoiceSingleFetch,
  invoiceSingleUnload,
  contactRecordSingleUpdate,
  contactRecordSingleCreate,
  contactRecordRemove,
  addToast,
};

//const getUISettings = () => {
//  let uiSettings = window.localStorage.getItem('invoiceUISettings');
//  return null === uiSettings ? {} : JSON.parse(uiSettings);
//};

class InvoiceSingleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.initialLoad();
  }

  initialLoad() {
    this.props.invoiceSingleUnload();
    this.props.invoiceSingleFetch(this.props.match.params.id.replace('CC', ''));
  }

  componentWillUnmount() {
    this.props.invoiceSingleUnload();
  }

  componentDidMount() {
    this.initialLoad();
  }

  componentDidUpdate(prevProps) {
    // if just the invoice number changes in the url - force a reload
    if (this.props?.match?.params?.id !== prevProps?.match?.params?.id && prevProps?.match?.params?.id !== false) {
      this.initialLoad();
    }
  }

  render() {
    const { invoice, isFetchingInvoice, isUpdatingInvoice = false, contactRecordSingleCreate, contactRecordSingleUpdate, contactRecordRemove } = this.props;
    if (!isFetchingInvoice && !invoice) {
      return (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">Not Found</div>
          </Card.Header>
          <Card.Body>
            <p>The invoice you are looking for cannot be found.</p>
          </Card.Body>
        </Card>
      );
    }
    if (isFetchingInvoice && !invoice) {
      return <MASpinner />;
    }
    //    console.log({ invoice });
    //    let uiSettings = getUISettings();
    //
    //    const switchUI = () => {
    //      let uiSettings = getUISettings();
    //      uiSettings[invoice.consultant.id] = !uiSettings[invoice.consultant.id];
    //      window.localStorage.setItem('invoiceUISettings', JSON.stringify(uiSettings));
    //      this.forceUpdate();
    //    };

    return (
      <>
        <Helmet>
          <title>{`Invoice ${invoice.invoiceNo} for ${invoice.patientName}`}</title>
        </Helmet>
        {/*<div className={'mb-2 text-right'}>*/}
        {/*  <button className="btn btn-secondary btn-xs" onClick={() => switchUI()}>*/}
        {/*    Switch UI*/}
        {/*  </button>*/}
        {/*</div>*/}
        {invoice?.consultant?.useAutomatedChasing /*|| uiSettings[invoice.consultant.id]*/ ? (
          <InvoiceSingle
            isUpdatingInvoice={isUpdatingInvoice}
            invoice={invoice}
            addContactRecord={(c) => {
              contactRecordSingleCreate(c).then(() => {
                this.props.invoiceSingleFetch(this.props.match.params.id.replace('CC', ''));
              });
            }}
            updateContactRecord={(c) => {
              contactRecordSingleUpdate(c, c.id).then(() => {
                this.props.invoiceSingleFetch(this.props.match.params.id.replace('CC', ''));
              });
            }}
            removeContactRecord={(c) => {
              contactRecordRemove(c).then(() => {
                this.props.invoiceSingleFetch(this.props.match.params.id.replace('CC', ''));
              });
            }}
          />
        ) : (
          <OldInvoiceSingleRefOnly invoice={invoice} fetchingInvoice={isFetchingInvoice} hasLoadedInvoice={!isFetchingInvoice && invoice} />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSingleContainer);
