import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { userForgotPassword } from '../../../lib/redux/actions/user';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { addToast } from '../../../lib/redux/actions/toast';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../lib/routing/frontend';

const mapDispatchToProps = {
  userForgotPassword,
  addToast,
};

class ForgottenPasswordForm extends React.Component {
  onSubmit(values) {
    const { addToast } = this.props;
    return this.props.userForgotPassword(values.email).then(() => {
      addToast('Please check your email for a password reset code.');
    });
  }

  render() {
    const { handleSubmit, error } = this.props;
    return (
      <Card className="login-card">
        <Card.Header>Forgotten Password?</Card.Header>
        <Card.Body>
          <p>Enter your account email address and press the reset password button to receive an email with link which will let you enter a new password.</p>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field name="email" label="Email Address" type="email" component={renderField} />
            <button type="submit" className="btn btn-primary btn-big btn-block">
              Reset Password
            </button>
          </Form>
        </Card.Body>
        <Card.Footer className="text-center">
          <Link className="mt-4" to={ROUTES.LOGIN}>
            Back to Login
          </Link>
        </Card.Footer>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'ForgottenPasswordForm',
})(connect(null, mapDispatchToProps)(ForgottenPasswordForm));
