import moment from 'moment';
import { generalCurrencyFormatter } from '../../lib/helper/formatting';
import React from 'react';

const TimelineCostsIcon = ({ ccsdCodeCost, consultantType }) => {
  //  const timelineRange = 12;
  //  const timelineRangeUnit = 'months';

  //  const todayMinusRange = moment().subtract(timelineRange, timelineRangeUnit);
  //  const todayPlusRange = moment().add(timelineRange, timelineRangeUnit);
  //  const datesWithinRangeForType = ccsdCodeCost.datedCosts ? ccsdCodeCost.datedCosts.filter(datedCost => {
  //    return moment(datedCost.validFrom).isBetween(todayMinusRange, todayPlusRange) && datedCost.consultantType.code === consultantType.code;
  //  }) : [];

  //  if (datesWithinRangeForType.length < 1) {
  //    return null;
  //  }

  const datedCostsOfCorrectType = ccsdCodeCost.datedCosts
    ? ccsdCodeCost.datedCosts.filter((datedCost) => {
        return datedCost.consultantType.code === consultantType.code;
      })
    : [];

  if (datedCostsOfCorrectType.length < 1) {
    return null;
  }

  const currentCostIds = ccsdCodeCost.currentCosts.map((currentCost) => currentCost['@id']);
  // filter out any that only have one cost and that cost is the current cost.
  const nonCurrentDatesWithinRangeForType =
    datedCostsOfCorrectType.length > 1 ? datedCostsOfCorrectType : datedCostsOfCorrectType.filter((datedCost) => !currentCostIds.includes(datedCost['@id']));
  if (nonCurrentDatesWithinRangeForType.length < 1) {
    return null;
  }
  return (
    <i
      className="fa fa-clock text-danger ml-1"
      title={datedCostsOfCorrectType
        .map((datedCost) => {
          return 'From ' + moment(datedCost.validFrom).format('DD-MM-YYYY') + ': ' + generalCurrencyFormatter(datedCost.cost);
        })
        .join('\r\n')}
    />
  );
};

export default TimelineCostsIcon;
