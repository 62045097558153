import React from 'react';
import { connect } from 'react-redux';
import BillingChecklistEventSingleCreate from './BillingChecklistEventSingleCreate';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';
import { billingChecklistEventSingleCreate, billingChecklistEventSingleUnload } from '../../lib/redux/actions/billingChecklistEvents';

const mapDispatchToProps = {
  billingChecklistEventSingleCreate,
  billingChecklistEventSingleUnload,
  addToast,
};

class BillingChecklistEventSingleCreateContainer extends React.Component {
  componentWillUnmount() {
    this.props.billingChecklistEventSingleUnload();
  }

  onCreate(data) {
    const { billingChecklistEventSingleCreate, addToast, history } = this.props;
    return billingChecklistEventSingleCreate(data).then((billingChecklistEvent) => {
      addToast('The Billing Checklist Event was created successfully', true);
      history.push(ROUTES.BILLING_CHECKLIST_EVENTS.SINGLE.replace(':id', billingChecklistEvent.id));
    });
  }

  render() {
    return <BillingChecklistEventSingleCreate onCreate={this.onCreate.bind(this)} />;
  }
}

export default connect(null, mapDispatchToProps)(BillingChecklistEventSingleCreateContainer);
