import { STATS_UPDATED } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const statsUpdated = (stats) => ({
  type: STATS_UPDATED,
  stats,
});

export const getStats = (stopGettingStats = false) => {
  return (dispatch) => {
    //console.log('Fetching stats...');
    return requests
      .get(`${API_ROUTES.STATS}`)
      .then((response) => dispatch(statsUpdated(response)))
      .catch((error) => {
        if (stopGettingStats !== false) {
          stopGettingStats();
        }
        if (error.response && error.response.status === 401) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
      });
  };
};
