import React, { useMemo } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderSelect } from '../../lib/helper/form';
import Card from 'react-bootstrap/Card';
import { generatePaymentLink } from '../../lib/redux/actions/paymentLink';
import { invoiceSingleFetchByUniqueRef } from '../../lib/redux/actions/invoices';
import { useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';

const mapDispatchToProps = {
  invoiceSingleFetchByUniqueRef,
  generatePaymentLink,
};

const mapStateToProps = (state) => ({
  ...state.invoices,
});

function VerifyPatientDetails({ handleSubmit, invoiceSingleFetchByUniqueRef, invoiceError, isFetchingInvoice }) {
  const { uniqueRef } = useParams();

  const getDays = useMemo(() => {
    return Array.from({ length: 31 }, (_, i) => ({
      key: i + 1,
      value: i + 1,
      text: i + 1,
    }));
  }, []);

  const getYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - 1899 }, (_, i) => ({
      key: currentYear - i,
      value: currentYear - i,
      text: currentYear - i,
    }));
  }, []);

  const getMonths = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => {
      const monthNumber = i + 1;
      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
      return {
        key: monthNumber,
        value: monthString,
        text: new Date(0, i).toLocaleString('en', { month: 'long' }),
      };
    });
  }, []);

  const onSubmit = async (formValues) => {
    const { day, month, year } = formValues;
    let dateOfBirth;
    if (day && month && year) {
      dateOfBirth = `${year}-${month}-${day}`;
    }
    try {
      await invoiceSingleFetchByUniqueRef(uniqueRef, { dob: dateOfBirth });
    } catch (error) {
      //      console.error('Error fetching invoice:', error);
    }
  };

  return (
    <Card className="patient-invoice">
      {/*<Card.Header as={'h2'}>Please enter the Patient's date of birth</Card.Header>*/}
      <Card.Body>
        <h5 className={'pb-2'}>Secure invoice payment</h5>
        <p>To unlock and view this invoice please enter the Patient's date of birth</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className={'text-left'}>
            <Col>
              <Field name="day" label="Day" component={renderSelect} options={getDays} />
            </Col>
            <Col>
              <Field name="month" label="Month" component={renderSelect} options={getMonths} />
            </Col>
            <Col>
              <Field name="year" label="Year" component={renderSelect} options={getYears} />
            </Col>
          </Row>
          {invoiceError && (
            <Alert variant={'danger'} className={'text-danger'}>
              {invoiceError}
            </Alert>
          )}
          <div className="text-center">
            <Button disabled={isFetchingInvoice} type="submit" className="btn-primary text-capitalize">
              {isFetchingInvoice ? 'Loading...' : 'Unlock'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'VerifyPatientDetails',
  })(VerifyPatientDetails),
);
