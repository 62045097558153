import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import MASpinner from '../../components/common/MASpinner';
import { consultantTicketSingleFetch, consultantTicketSingleUnload } from '../../lib/redux/actions/consultantTickets';
import ConsultantTicketSingle from './ConsultantTicketSingle';

const mapDispatchToProps = {
  consultantTicketSingleFetch,
  consultantTicketSingleUnload,
};

const mapStateToProps = (state) => {
  return {
    ...state.consultantTickets,
    ...state.consultantTicketTypes,
  };
};

class ConsultantTicketSingleContainer extends Component {
  componentDidMount() {
    this.props.consultantTicketSingleFetch(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.consultantTicketSingleUnload();
  }

  onNoteCreate() {
    this.props.consultantTicketSingleFetch(this.props.match.params.id);
  }

  render() {
    const { consultantTicket, isFetchingConsultantTicket } = this.props;
    if (!isFetchingConsultantTicket && !consultantTicket) {
      return (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">Not Found</div>
          </Card.Header>
          <Card.Body>
            <p>Could not find data for this ticket</p>
          </Card.Body>
        </Card>
      );
    }
    if (isFetchingConsultantTicket && !consultantTicket) {
      return <MASpinner />;
    }
    return <ConsultantTicketSingle consultantTicket={consultantTicket} onNoteCreate={this.onNoteCreate.bind(this)} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantTicketSingleContainer);
