import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import { getSortIcon, niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';

class ProblemDataListTable extends Component {
  render() {
    const { problemData = [], orderByUpdatedAt, orderByUpdatedAtStatus } = this.props;
    if (!problemData || problemData.length === 0) {
      return (
        <Card.Body>
          <Alert variant={'info'} className="mb-0">
            No invoices with problem data found
          </Alert>
        </Card.Body>
      );
    }
    return (
      <div>
        <Table hover responsive className="problem-data-list-table">
          <thead>
            <tr>
              <th style={{ minWidth: '80px' }}>Problem ID</th>
              <th style={{ minWidth: '80px' }}>Invoice Batch ID</th>
              <th>Problem Date</th>
              <th>Consultant - Patient</th>
              <th>Insurer</th>
              <th>Type</th>
              <th>Invoice Numbers</th>
              <th>Status</th>
              <th style={{ cursor: 'pointer' }} onClick={() => orderByUpdatedAt()}>
                Updated At{getSortIcon(orderByUpdatedAtStatus)}
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {problemData &&
              problemData.map((data) => {
                return (
                  <tr key={data.id} title={data?.otherInformation}>
                    <td>{data.id}</td>
                    <td>{data?.invoiceBatch?.id}</td>
                    <td>{niceDateFormatter(data?.problemDate)}</td>
                    <td>
                      {data?.invoiceBatch?.consultant?.name} -
                      <br />
                      {userNameFormatter({ firstName: data.patientFirstName ?? '', lastName: data.patientLastName ?? '' })}
                    </td>
                    <td>{data?.insurer?.name}</td>
                    <td>{data?.problemType?.name}</td>
                    <td>{data?.invoiceNumbers}</td>
                    <td>{data?.problemStatus?.name}</td>
                    <td>{niceDateFormatter(data?.updatedAt)}</td>
                    <td>
                      <Link to={ROUTES.PROBLEM_DATA.SINGLE_EDIT.replace(':id', data.id)} className="btn btn-sm btn-primary ml-2">
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ProblemDataListTable;
