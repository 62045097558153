import {
  CCSD_CODE_TYPEAHEAD_LIST_REQUEST,
  CCSD_CODE_TYPEAHEAD_LIST_RECEIVED,
  CCSD_CODE_TYPEAHEAD_LIST_ERROR,
  CCSD_CODE_TYPEAHEAD_LIST_UNLOAD,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    ccsdCodes: [],
    isFetchingCcsdCodes: false,
    searchCompleted: false,
    currentPage: 1,
    pageCount: null,
  },
  action,
) => {
  switch (action.type) {
    case CCSD_CODE_TYPEAHEAD_LIST_REQUEST:
      return {
        ...state,
        isFetchingCcsdCodes: true,
        searchCompleted: false,
      };

    case CCSD_CODE_TYPEAHEAD_LIST_RECEIVED:
      return {
        ...state,
        ccsdCodes: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingCcsdCodes: false,
        searchCompleted: true,
      };

    case CCSD_CODE_TYPEAHEAD_LIST_ERROR:
      return {
        ...state,
        isFetchingCcsdCodes: false,
        searchCompleted: false,
      };

    case CCSD_CODE_TYPEAHEAD_LIST_UNLOAD:
      return {
        ccsdCodes: [],
        isFetchingCcsdCodes: false,
        searchCompleted: false,
      };

    default:
      return state;
  }
};

export default reducer;
