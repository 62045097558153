const extractFollowingXByKey = (data: string, key: string, followOnItemCnt = 1, concatStr = ', ', extractItemQty = 1) => {
  let extractedValue = '';
  const splitData = data.split('||');
  const itemCnt = splitData.length;
  let extractItemCnt = 1;
  for (let x = 0; x < itemCnt; x++) {
    if (splitData[x] === key && extractItemCnt <= extractItemQty) {
      // if (splitData[x] === key) {
      let extractCnt = 1;
      extractItemCnt++;
      while (extractCnt <= followOnItemCnt) {
        if (extractCnt > 1 && concatStr.length > 0) {
          extractedValue += concatStr;
        }
        extractedValue += splitData[x + extractCnt];

        extractCnt++;
      }
    }
  }
  return extractedValue;
};

const extractWhereKeyContains = (data: string, keyContains: string, followOnItemCnt = 1, concatStr = ', ', extractItemQty = 1) => {
  let extractedValue = '';
  const splitData = data.split('||');
  const itemCnt = splitData.length;
  let extractItemCnt = 1;
  for (let x = 0; x < itemCnt; x++) {
    if (String(splitData[x]).indexOf(keyContains) > -1 && extractItemCnt <= extractItemQty) {
      // if (splitData[x] === key) {
      // let extractCnt = 1;
      // extractItemCnt++;
      // while (extractCnt <= followOnItemCnt) {
      if (extractItemCnt > 1 && concatStr.length > 0) {
        extractedValue += concatStr;
      }
      extractedValue += splitData[x];
      //
      //   extractCnt++;
      // }
    }
  }
  return extractedValue;
};

const extractFollowingUntilByKeys = (data: string, key: string, untilKey: string, concatStr = ', ') => {
  let extractedValue = '';
  const splitData = data.split('||');
  const itemCnt = splitData.length;
  let extractData = false;
  for (let x = 0; x < itemCnt; x++) {
    if (extractData) {
      if (splitData[x] === untilKey) {
        break;
      }
      if (extractedValue.length > 0 && concatStr.length > 0) {
        extractedValue += concatStr;
      }
      extractedValue += splitData[x];
    }
    extractData = extractData || splitData[x] === key;
  }
  return extractedValue;
};

export { extractFollowingUntilByKeys, extractFollowingXByKey, extractWhereKeyContains };
