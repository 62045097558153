import PropTypes from 'prop-types';
import React from 'react';

class MAToggleButton extends React.Component {
  render() {
    const { toggleAction, buttonContent = 'toggle me', classes = 'btn btn-white mr-4 d-md-none' } = this.props;
    return (
      <button className={classes} onClick={toggleAction}>
        {buttonContent}
      </button>
    );
  }
}

export default MAToggleButton;

MAToggleButton.propTypes = {
  toggleAction: PropTypes.func.isRequired,
};
