import {
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_REQUEST,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_RECEIVED,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_ERROR,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_UNLOAD,
  INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_SET_DATE,
} from '../constants';

const reducer = (
  state = {
    checklistWeeklyEvents: [],
    isFetchingChecklistWeeklyEvents: false,
    currentDate: undefined,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingChecklistWeeklyEvents: true,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_RECEIVED:
      return {
        ...state,
        checklistWeeklyEvents: action.data,
        isFetchingChecklistWeeklyEvents: false,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_ERROR:
      return {
        ...state,
        isFetchingChecklistWeeklyEvents: false,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_UNLOAD:
      return {
        checklistWeeklyEvents: [],
        isFetchingChecklistWeeklyEvents: false,
        currentDate: undefined,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_WEEKLY_EVENTS_SET_DATE:
      return {
        ...state,
        currentDate: action.date,
      };

    default:
      return state;
  }
};

export default reducer;
