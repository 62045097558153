import React from 'react';
import { connect } from 'react-redux';
import InvoiceBatchCreate from './InvoiceBatchCreate';
import { fileDelete } from '../../lib/redux/actions/invoiceBatchFiles';

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  ...state.invoiceBatchFiles,
});

const mapDispatchToProps = {
  fileDelete,
};

class InvoiceBatchCreateContainer extends React.Component {
  render() {
    const { /*userData,*/ files, fileDelete, fileRequestInProgress } = this.props;

    //    if ( !canWriteBlogPost(userData)){
    //      return <Redirect to="/login" />;
    //    }

    return <InvoiceBatchCreate files={files} fileDelete={fileDelete} fileRequestInProgress={fileRequestInProgress} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchCreateContainer);
