import React from 'react';
import PropTypes from 'prop-types';
import { Row as TableRow, Cell, StickyTable } from 'react-sticky-table';
import EditableFeeAssuredCell from '../../consultantHealthInsurer/EditableFeeAssuredCell';
import EditableProviderNumberCell from '../../consultantHealthInsurer/EditableProviderNumberCell';
import StickyCcsdCodeCostRow from '../../ccsdCodeCost/StickyCcsdCodeCostRow';
import EditableConsultantHealthInsurerLabelCell from '../../consultantHealthInsurer/EditableConsultantHealthInsurerLabelCell';
import MASpinner from '../../common/MASpinner';
import { sortHealthInsurersByName } from '../../../lib/helper/general';

class ConsultantCcsdCodeCostTable extends React.Component {
  render() {
    const { consultant, ccsdCodes, ccsdCodeCosts, reloadCodes, isFetchingCcsdCodeCosts = false } = this.props;
    if (!consultant.consultantHealthInsurers) {
      return null;
    }
    return (
      <>
        <div className="crud__section mb-3">
          <div className="crud__section-icon">{isFetchingCcsdCodeCosts ? <MASpinner wrapperClasses={''} /> : <i className="fas fa-coins" />}</div>
          <h2 className="crud__section-header">CCSD code costs </h2>
          <div style={{ width: '100%', height: '500px' }}>
            <StickyTable stickyHeaderCount={3} leftStickyColumnCount={1}>
              <TableRow>
                <Cell className="sticky-table-cell batch-invoice-ccsd-code-title-column">Code</Cell>
                {consultant.consultantHealthInsurers.sort(sortHealthInsurersByName).map((consultantHealthInsurer) => (
                  <EditableConsultantHealthInsurerLabelCell key={consultantHealthInsurer.healthInsurer.id} consultantHealthInsurer={consultantHealthInsurer} />
                ))}
              </TableRow>
              <TableRow>
                <Cell>Fee Assured</Cell>
                {consultant.consultantHealthInsurers.sort(sortHealthInsurersByName).map((consultantHealthInsurer) => {
                  return consultantHealthInsurer.healthInsurer.isAnalogue ? (
                    <Cell key={consultantHealthInsurer.healthInsurer.id} className="border-right" />
                  ) : (
                    <EditableFeeAssuredCell
                      consultantCreatedDate={consultant.createdAt}
                      key={consultantHealthInsurer.healthInsurer.id}
                      consultantHealthInsurer={consultantHealthInsurer}
                      reloadCodes={reloadCodes}
                    />
                  );
                })}
              </TableRow>
              <TableRow>
                <Cell>Providers No</Cell>
                {consultant.consultantHealthInsurers.sort(sortHealthInsurersByName).map((consultantHealthInsurer) => {
                  return consultantHealthInsurer.healthInsurer.isAnalogue ? (
                    <Cell key={consultantHealthInsurer.healthInsurer.id} className="border-right" />
                  ) : (
                    <EditableProviderNumberCell key={consultantHealthInsurer.healthInsurer.id} consultantHealthInsurer={consultantHealthInsurer} />
                  );
                })}
              </TableRow>
              {ccsdCodes.map((ccsdCode) => (
                <StickyCcsdCodeCostRow
                  key={ccsdCode.id}
                  ccsdCode={ccsdCode}
                  ccsdCodeCosts={ccsdCodeCosts}
                  consultantHealthInsurers={consultant.consultantHealthInsurers}
                  consultantType={consultant.consultantType}
                />
              ))}
            </StickyTable>
          </div>
        </div>
      </>
    );
  }
}

export default ConsultantCcsdCodeCostTable;

ConsultantCcsdCodeCostTable.propTypes = {
  consultant: PropTypes.object.isRequired,
  ccsdCodes: PropTypes.array.isRequired,
};
