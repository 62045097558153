import React from 'react';
import { connect } from 'react-redux';
import { ccsdCodeCostListFetch, ccsdCodeCostListUnload } from '../../../lib/redux/actions/ccsdCodeCosts';
import MASpinner from '../../common/MASpinner';
import PropTypes from 'prop-types';
import ConsultantCcsdCodeCostTable from './ConsultantCcsdCodeCostTable';
import { CCSD_CODE_TYPE_CONSULTATION_CODE, CCSD_CODE_TYPE_PROCEDURE_CODE } from '../../../lib/helper/general';
import { isSeniorStaff } from '../../../lib/helper/authorisation';
import { Col, Row } from 'react-bootstrap';
import CcsdCodeCostForm from '../../forms/CcsdCode/CcsdCodeCostForm';

const mapStateToProps = (state) => ({
  ...state.ccsdCodeCosts,
});

const mapDispatchToProps = {
  ccsdCodeCostListFetch,
  ccsdCodeCostListUnload,
};

class ConsultantCcsdCodeCostTableContainer extends React.Component {
  componentDidMount() {
    this.loadCcsdCodeCosts(1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.nextPage !== false && prevProps.nextPage !== this.props.nextPage) {
      this.loadCcsdCodeCosts(this.props.nextPage);
    }
  }

  componentWillUnmount() {
    this.props.ccsdCodeCostListUnload();
  }

  loadCcsdCodeCosts(page) {
    if (!this.props.consultant || !this.props.consultant.consultantHealthInsurers || this.props.consultant.consultantHealthInsurers.length < 1) {
      return false;
    }

    this.props.ccsdCodeCostListFetch(
      page,
      {
        consultant: this.props.consultant['@id'],
        pagination: false,
      },
      true,
    );
  }

  render() {
    const { consultant, ccsdCodeCosts, isFetchingCcsdCodeCosts } = this.props;
    if (!consultant || !consultant.consultantHealthInsurers || consultant.consultantHealthInsurers.length < 1 || undefined === ccsdCodeCosts) {
      return null;
    }

    const consultationCcsdCodes = [];
    const procedureCcsdCodes = [];

    const ccsdCodeIds = [];
    ccsdCodeCosts.forEach((ccsdCodeCost) => {
      if (!ccsdCodeIds.includes(ccsdCodeCost.ccsdCode.id)) {
        ccsdCodeIds.push(ccsdCodeCost.ccsdCode.id);
        if (ccsdCodeCost.ccsdCode.ccsdCodeType.code === CCSD_CODE_TYPE_CONSULTATION_CODE) {
          consultationCcsdCodes.push(ccsdCodeCost.ccsdCode);
        } else if (ccsdCodeCost.ccsdCode.ccsdCodeType.code === CCSD_CODE_TYPE_PROCEDURE_CODE) {
          procedureCcsdCodes.push(ccsdCodeCost.ccsdCode);
        }
      }
    });
    //    consultationCcsdCodes.sort((a, b) => a.reference.localeCompare(b.reference));
    //    procedureCcsdCodes.sort((a, b) => a.reference.localeCompare(b.reference));

    const ccsdCodes = [...consultationCcsdCodes, ...procedureCcsdCodes];
    return (
      <div>
        {
          (((!ccsdCodes || ccsdCodes.length < 1) && isFetchingCcsdCodeCosts) || ((!ccsdCodeCosts || ccsdCodeCosts.length < 1) && isFetchingCcsdCodeCosts)) && (
            <MASpinner msg={'Loading consultant codes and fees'} />
          )
          //          <MASpinner msg={'No codes: ' + (!ccsdCodes || ccsdCodes.length < 1) + ' or no costs ' +(!ccsdCodeCosts || ccsdCodeCosts.length < 1)  + 'or still fetching costs '+ isFetchingCcsdCodeCosts} />
        }

        {ccsdCodes && ccsdCodes.length > 0 && (
          <ConsultantCcsdCodeCostTable
            consultant={consultant}
            isFetchingCcsdCodeCosts={isFetchingCcsdCodeCosts}
            ccsdCodes={ccsdCodes}
            ccsdCodeCosts={ccsdCodeCosts}
            reloadCodes={this.loadCcsdCodeCosts.bind(this)}
          />
        )}
        {ccsdCodes && ccsdCodes.length === 0 && !isFetchingCcsdCodeCosts && (
          <>
            <div className="crud__section mb-3">
              <div className="crud__section-icon">{isFetchingCcsdCodeCosts ? <MASpinner wrapperClasses={''} /> : <i className="fas fa-coins" />}</div>
              <h2 className="crud__section-header">CCSD code costs </h2>
              <div style={{ width: '100%' }}>
                <p>Please add a CCSD code below to start adding costs for this Consultant</p>
              </div>
            </div>
          </>
        )}
        {consultant && ccsdCodes && isSeniorStaff() && (
          <div className="crud__section mb-4">
            <div className="crud__section-icon">
              <i className="fas fa-plus-circle" />
            </div>
            <h2 className="crud__section-header">Add a CCSD code</h2>
            <Row>
              <Col lg="6">
                <CcsdCodeCostForm existingCcsdCodes={ccsdCodes} consultant={consultant} reloadCodes={this.loadCcsdCodeCosts.bind(this)} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantCcsdCodeCostTableContainer);

ConsultantCcsdCodeCostTableContainer.propTypes = {
  consultant: PropTypes.object.isRequired,
};
