import { PROBLEM_TYPES_RECEIVED, PROBLEM_TYPES_REQUEST, PROBLEM_TYPES_UNLOAD } from '../constants';

const reducer = (
  state = {
    problemTypes: [],
    isFetchingProblemTypes: false,
  },
  action,
) => {
  switch (action.type) {
    case PROBLEM_TYPES_REQUEST:
      return {
        ...state,
        isFetchingProblemTypes: true,
      };
    case PROBLEM_TYPES_RECEIVED:
      return {
        ...state,
        isFetchingProblemTypes: false,
        problemTypes: action.data['hydra:member'],
      };
    case PROBLEM_TYPES_UNLOAD:
      return {
        ...state,
        isFetchingProblemTypes: false,
        problemTypes: [],
      };
    default:
      return state;
  }
};

export default reducer;
