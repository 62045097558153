import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cmsContentSingleFetch, cmsContentSingleUnload } from '../../lib/redux/actions/cmsContent';
import CmsContentForm from '../../components/forms/CmsContent/CmsContentForm';
import MASpinner from '../../components/common/MASpinner';
import { isOutsourcer, isSeniorStaff, isStaff } from '../../lib/helper/authorisation';
import PropTypes from 'prop-types';
import * as ROUTES from '../../lib/routing/frontend';
import { capitalizeWords } from '../../lib/helper/formatting';
import Card from 'react-bootstrap/Card';

const mapStateToProps = (state) => ({
  ...state.cmsContent,
});

const mapDispatchToProps = {
  cmsContentSingleFetch,
  cmsContentSingleUnload,
};

class CmsContent extends Component {
  componentDidMount() {
    this.props.cmsContentSingleFetch(this.props.apiRoute);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiRoute !== this.props.apiRoute) {
      this.props.cmsContentSingleUnload();
      this.props.cmsContentSingleFetch(this.props.apiRoute);
    }
  }

  componentWillUnmount() {
    this.props.cmsContentSingleUnload();
  }

  render() {
    const { cmsContent, isFetchingContent, apiRoute } = this.props;
    if (isFetchingContent || !cmsContent || !cmsContent.name) {
      return <MASpinner />;
    }
    return (
      <Card className={'cms-content'}>
        <Card.Header>
          <div className="d-flex justify-content-between">{capitalizeWords(cmsContent.name)}</div>
        </Card.Header>

        <Card.Body className={'cms-content__section overflow-auto'}>
          {isSeniorStaff() && <CmsContentForm cmsContent={cmsContent} cmsName={apiRoute.replace('/cms_contents/', '')} />}

          {(isStaff(true) || (this.props.location.pathname === ROUTES.CMS.INSURANCE && isOutsourcer(true))) && (
            <div dangerouslySetInnerHTML={{ __html: cmsContent?.content }}></div>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsContent);

CmsContent.propTypes = {
  apiRoute: PropTypes.string.isRequired,
};
