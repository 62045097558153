import React from 'react';
import { connect } from 'react-redux';
import InvoiceParse from './InvoiceParse';
import { fileDelete } from '../../lib/redux/actions/invoiceBatchFiles';

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  ...state.invoiceBatchFiles,
});

const mapDispatchToProps = {
  fileDelete,
};

class InvoiceParseContainer extends React.Component {
  render() {
    const { files, fileDelete, fileRequestInProgress, isEmbed = false, invoiceBatch = undefined } = this.props;

    return <InvoiceParse files={files} fileDelete={fileDelete} fileRequestInProgress={fileRequestInProgress} invoiceBatch={invoiceBatch} isEmbed={isEmbed} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceParseContainer);
