import React from 'react';
import Form from 'react-bootstrap/Form';
import MASpinner from '../../common/MASpinner';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { hospitalSingleCreate, hospitalSingleUpdate } from '../../../lib/redux/actions/hospitals';
import { renderField } from '../../../lib/helper/form';
import { addToast } from '../../../lib/redux/actions/toast';

const mapStateToProps = (state, props) => {
  const initialValues = {
    name: props.selectedHospital !== null && props.selectedHospital.name ? props.selectedHospital.name : '',
  };
  return {
    ...state.hospitals,
    initialValues,
  };
};
const mapDispatchToProps = {
  hospitalSingleCreate,
  hospitalSingleUpdate,
  addToast,
};

class CreateOrEditHospitalForm extends React.Component {
  onSubmit(values) {
    const { hospitalSingleCreate, hospitalSingleUpdate, reset, selectedHospital = null, clearSelected = () => {} } = this.props;
    if (selectedHospital && typeof selectedHospital === 'object' && 'id' in selectedHospital && !isNaN(selectedHospital.id)) {
      return hospitalSingleUpdate({ name: values.name }, selectedHospital.id).then(() => {
        addToast('The hospital was updated');
        clearSelected();
        reset();
      });
    } else {
      return hospitalSingleCreate({ name: values.name }).then(() => {
        addToast('The hospital was added');
        reset();
      });
    }
  }

  render() {
    const {
      handleSubmit,
      error,
      pristine,
      submitting,
      isCreatingHospital,
      isUpdatingHospital,
      selectedHospital = null,
      reset,
      clearSelected = () => {},
    } = this.props;
    const isCreate = null === selectedHospital;
    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-edit" />
        </div>

        <h2 className="invoice-batch-single__section-header">{isCreate ? 'Add' : 'Edit'} Hospital</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Field className="mb-2" name="name" label="Hospital Name" id="name" type="text" component={renderField} />

          {isCreatingHospital || isUpdatingHospital ? (
            <MASpinner />
          ) : (
            <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block mb-2">
              Save Hospital
            </button>
          )}
        </Form>
        {isCreatingHospital || isUpdatingHospital ? (
          <MASpinner />
        ) : (
          <button
            type="button"
            disabled={pristine || submitting}
            className="btn btn-secondary btn-block"
            onClick={() => {
              if (isCreate) {
                reset();
              } else {
                clearSelected();
              }
            }}>
            Clear
          </button>
        )}
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'CreateOrEditHospitalForm',
    enableReinitialize: true,
  })(CreateOrEditHospitalForm),
);
