import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  CCSD_CODE_TYPEAHEAD_LIST_REQUEST,
  CCSD_CODE_TYPEAHEAD_LIST_RECEIVED,
  CCSD_CODE_TYPEAHEAD_LIST_ERROR,
  CCSD_CODE_TYPEAHEAD_LIST_UNLOAD,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const ccsdCodeTypeaheadListRequest = () => ({
  type: CCSD_CODE_TYPEAHEAD_LIST_REQUEST,
});

export const ccsdCodeTypeaheadListReceived = (data) => ({
  type: CCSD_CODE_TYPEAHEAD_LIST_RECEIVED,
  data,
});

export const ccsdCodeTypeaheadListError = (error) => ({
  type: CCSD_CODE_TYPEAHEAD_LIST_ERROR,
  error,
});

export const ccsdCodeTypeaheadListUnload = () => ({
  type: CCSD_CODE_TYPEAHEAD_LIST_UNLOAD,
});

export const ccsdCodeTypeaheadListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(ccsdCodeTypeaheadListRequest());
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    return requests
      .get(API_ROUTES.CCSD_CODES, filters)
      .then((response) => dispatch(ccsdCodeTypeaheadListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(ccsdCodeTypeaheadListError(error));
      });
  };
};
