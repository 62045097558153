import {
  INVOICE_BATCH_PROBLEM_CATEGORY_LIST_REQUEST,
  INVOICE_BATCH_PROBLEM_CATEGORY_LIST_RECEIVED,
  INVOICE_BATCH_PROBLEM_CATEGORY_LIST_ERROR,
} from '../constants';

const reducer = (
  state = {
    invoiceBatchProblemCategories: [],
    isFetchingInvoiceBatchProblemCategories: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_PROBLEM_CATEGORY_LIST_REQUEST:
      state = {
        ...state,
        isFetchingInvoiceBatchProblemCategories: true,
      };
      return state;

    case INVOICE_BATCH_PROBLEM_CATEGORY_LIST_RECEIVED:
      state = {
        ...state,
        invoiceBatchProblemCategories: action.data['hydra:member'],
        isFetchingInvoiceBatchProblemCategories: false,
      };
      return state;

    case INVOICE_BATCH_PROBLEM_CATEGORY_LIST_ERROR:
      state = {
        ...state,
        isFetchingInvoiceBatchProblemCategories: false,
      };
      //      console.log(state);
      return state;

    default:
      return state;
  }
};

export default reducer;
