import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <div className="text-center p-5">
        <i className="fa fa-question-circle display-1 text-info mb-2" />
        <h1>Error 404</h1>
        <p>Page not found</p>
      </div>
    );
  }
}

export default NotFound;
