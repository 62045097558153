import {
  CONSULTANT_TICKET_LIST_REQUEST,
  CONSULTANT_TICKET_LIST_RECEIVED,
  CONSULTANT_TICKET_LIST_ERROR,
  CONSULTANT_TICKET_LIST_SET_PAGE,
  CONSULTANT_TICKET_LIST_SET_FILTERS,
  CONSULTANT_TICKET_LIST_CLEAR_FILTERS,
  CONSULTANT_TICKET_LIST_UNLOAD,
  CONSULTANT_TICKET_SINGLE_UNLOAD,
  CONSULTANT_TICKET_SINGLE_UPDATE_REQUEST,
  CONSULTANT_TICKET_SINGLE_UPDATED,
  CONSULTANT_TICKET_SINGLE_CREATE_SUCCESS,
  CONSULTANT_TICKET_SINGLE_CREATE_REQUEST,
  CONSULTANT_TICKET_SINGLE_REQUEST,
  CONSULTANT_TICKET_SINGLE_RECEIVED,
  CONSULTANT_TICKET_SINGLE_ERROR,
  CONSULTANT_TICKET_SINGLE_CREATE_ERROR,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    consultantTickets: [],
    isFetchingConsultantTickets: false,
    currentPage: 1,
    pageCount: null,
    filters: {},

    consultantTicket: null,
    isFetchingConsultantTicket: false,
    isUpdatingConsultantTicket: false,
    isCreatingConsultantTicket: false,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_TICKET_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingConsultantTicket: true,
      };

    case CONSULTANT_TICKET_SINGLE_RECEIVED:
      return {
        ...state,
        consultantTicket: action.data,
        isFetchingConsultantTicket: false,
      };

    case CONSULTANT_TICKET_SINGLE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingConsultantTicket: false,
        isUpdatingConsultantTicket: false,
      };
    case CONSULTANT_TICKET_SINGLE_CREATE_REQUEST:
      return {
        ...state,
        isCreatingConsultantTicket: true,
      };

    case CONSULTANT_TICKET_SINGLE_CREATE_ERROR:
      return {
        ...state,
        isCreatingConsultantTicket: false,
      };

    case CONSULTANT_TICKET_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingConsultantTicket: true,
      };
      return state;

    case CONSULTANT_TICKET_SINGLE_CREATE_SUCCESS:
    case CONSULTANT_TICKET_SINGLE_UPDATED:
      return {
        ...state,
        consultantTicket: { ...state.consultantTicket, ...action.data },
        isUpdatingConsultantTicket: false,
        isCreatingConsultantTicket: false,
      };

    case CONSULTANT_TICKET_SINGLE_UNLOAD:
      return {
        ...state,
        filters: {},

        consultantTicket: null,
        isFetchingConsultantTicket: false,
        isUpdatingConsultantTicket: false,
        isCreatingConsultantTicket: false,
      };

    case CONSULTANT_TICKET_LIST_UNLOAD:
      return {
        ...state,
        consultantTickets: [],
        isFetchingConsultantTickets: false,
        currentPage: 1,
        pageCount: null,
        filters: {},
      };

    case CONSULTANT_TICKET_LIST_REQUEST:
      return {
        ...state,
        isFetchingConsultantTickets: true,
      };

    case CONSULTANT_TICKET_LIST_RECEIVED:
      return {
        ...state,
        consultantTickets: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingConsultantTickets: false,
      };

    case CONSULTANT_TICKET_LIST_ERROR:
      return {
        ...state,
        isFetchingConsultantTickets: false,
      };
    case CONSULTANT_TICKET_LIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case CONSULTANT_TICKET_LIST_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case CONSULTANT_TICKET_LIST_CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    default:
      return state;
  }
};

export default reducer;
