import React from 'react';
import Form from 'react-bootstrap/Form';
import { addToast } from '../../../lib/redux/actions/toast';
import { consultantSingleFetch, consultantSingleUpdate } from '../../../lib/redux/actions/consultant';
import { connect } from 'react-redux';
import MASpinner from '../../common/MASpinner';
import { Field, reduxForm } from 'redux-form';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import { userNameFormatter } from '../../../lib/helper/formatting';
import { isAdmin, isClient, isSeniorStaff, isStaff } from '../../../lib/helper/authorisation';
import NewPrimaryUserTypeahead from '../../fields/NewPrimaryUserTypeahead';
import HospitalTypeahead from '../../fields/HospitalTypeahead';
import { consultantTypeListFetch } from '../../../lib/redux/actions/consultantType';

const mapStateToProps = (state) => ({
  ...state.consultantTypes,
});
const mapDispatchToProps = {
  consultantTypeListFetch,
  consultantSingleUpdate,
  consultantSingleFetch,
  addToast,
};

class AccountDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    const { consultant, initialize } = this.props;

    this.state = {
      selectedPrimaryUser: null,
      selectedHospitals: consultant ? consultant.hospitals : [],
      clearTypeaheads: false,
      updatedHospitals: false,
    };
    const initVals = consultant
      ? {
          code: consultant.code,
          name: consultant.name,
          gmcReferenceNumber: consultant.gmcReferenceNumber,
          speciality: consultant.speciality,
          invoicePrefix: consultant.invoicePrefix,
          isActive: consultant.isActive,
          notifyPrimaryUserOnNewInvoiceBatch: consultant.notifyPrimaryUserOnNewInvoiceBatch,
          notifyPrimaryUserOnWeeklyInvoiceSummary: consultant.notifyPrimaryUserOnWeeklyInvoiceSummary,
          consultantType: consultant.consultantType['@id'],
          phoneNumber: consultant.phoneNumber,
          clientAccountNumber: consultant.clientAccountNumber,
          clientSortCode: consultant.clientSortCode,
          personalAccountNumber: consultant.personalAccountNumber,
          personalAccountNumber2: consultant.personalAccountNumber2,
          personalSortCode: consultant.personalSortCode,
          personalSortCode2: consultant.personalSortCode2,
          personalAccountName: consultant.personalAccountName,
          personalAccountName2: consultant.personalAccountName2,
          personalAccountChangeDate: consultant.personalAccountChangeDate,
          secretaryName: consultant.secretaryName,
          secretaryPhoneNumber: consultant.secretaryPhoneNumber,
          secretaryEmail: consultant.secretaryEmail,
          billingStartDate: consultant.billingStartDate,
          useAutomatedChasing: consultant.useAutomatedChasing,
        }
      : {
          isActive: true,
          notifyPrimaryUserOnNewInvoiceBatch: false,
          notifyPrimaryUserOnWeeklyInvoiceSummary: false,
          useAutomatedChasing: false,
        };
    initialize(initVals);
  }

  componentDidMount() {
    this.props.consultantTypeListFetch();
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    const { consultantSingleUpdate, addToast, consultant, isCreate = false, onCreate = undefined } = this.props;
    if (isClient()) {
      delete values.name;
      delete values.name;
      delete values.code;
      delete values.isActive;
      delete values.billingStartDate;
    }

    const refreshConsultantData = !isCreate && consultant.consultantType['@id'] !== values.consultantType;

    if (this.state.selectedPrimaryUser !== null) {
      values['primaryUser'] = this.state.selectedPrimaryUser['@id'];
    }

    delete values['hospitals'];
    values['hospitals'] = this.state.selectedHospitals.map((h) => h['@id']);

    if (isCreate && onCreate) {
      this.setState(() => ({ updatedHospitals: false }));
      return onCreate(values);
    } else {
      return consultantSingleUpdate(values, consultant.id).then(() => {
        this.setState(() => ({ updatedHospitals: false }));
        addToast('Account Details Successfully Updated', true);
        if (refreshConsultantData) {
          this.props.consultantSingleFetch(consultant.id, true);
        }
      });
    }
  }

  render() {
    const {
      consultant,
      handleSubmit,
      error,
      pristine,
      submitting,
      isUpdatingConsultant,
      isFetchingConsultantTypes,
      consultantTypes = [],
      isCreate = false,
    } = this.props;
    const firstError = error ? error[Object.keys(error)[0]] : null;

    if (isFetchingConsultantTypes) {
      return <MASpinner />;
    }
    const typeOptions = [];
    consultantTypes.forEach((consultantType) => {
      typeOptions.push({ value: consultantType['@id'], text: consultantType.displayName });
    });

    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-cog" />
        </div>
        <h2 className="crud__section-header">Account details</h2>
        {error && <div className="alert alert-danger">{firstError}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            <div className="col-sm-4 col-xl-2">
              {isCreate || isAdmin() ? (
                <Field name="code" label="Account Code" id="code" type="text" component={renderField} />
              ) : (
                <Form.Group controlId="formGroupCode">
                  <Form.Label>Account Code</Form.Label>
                  <Form.Control type="text" readOnly value={consultant.code} />
                </Form.Group>
              )}
            </div>
            <div className="col-sm-8 col-xl-3">
              <Field
                name="name"
                readOnly={isClient() || isStaff(true)}
                label="Account Name"
                placeholder="e.g. Doctor Smith"
                type="text"
                component={renderField}
              />
            </div>

            {isCreate && (
              <div className="col-sm-8 col-xl-5">
                <NewPrimaryUserTypeahead
                  selectedUsers={this.state.selectedPrimaryUser ? [this.state.selectedPrimaryUser] : []}
                  required
                  shouldClear={this.state.clearTypeaheads}
                  hasCleared={this.typeaheadCleared.bind(this)}
                  onChange={(selected) => {
                    this.setState(() => {
                      return { selectedPrimaryUser: selected[0] };
                    });
                  }}
                />
              </div>
            )}
            {!isCreate && consultant.primaryUser && (
              <div className="col-sm-8 col-xl-5">
                <Form.Group controlId="formGroupCode">
                  <Form.Label>Primary User</Form.Label>
                  <Form.Control type="text" readOnly value={`${userNameFormatter(consultant.primaryUser)} (${consultant.primaryUser.email})`} />
                </Form.Group>
              </div>
            )}
          </div>
          <hr />
          <div className="row">
            <fieldset className="col-sm-12">
              <div className="row">
                <legend className="col-sm-12">Consultant Specifics</legend>
                <div className="col-sm-6 col-xl-2">
                  <Field
                    name="consultantType"
                    label="Consultant Type"
                    type="select"
                    options={typeOptions}
                    component={renderSelect}
                    readOnly={isClient() || isStaff(true)}
                    showEmpty={isCreate}
                    emptyLabel={''}
                  />
                </div>
                <div className="col-sm-6 col-xl-2">
                  <Field name="gmcReferenceNumber" readOnly={isClient() || isStaff(true)} label="GMC Number" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-2">
                  <Field name="speciality" readOnly={isClient() || isStaff(true)} label="Speciality" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-2">
                  <Field name="invoicePrefix" readOnly={isClient() || isStaff(true)} label="Invoice Prefix" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-2">
                  <Field
                    name="billingStartDate"
                    readOnly={isClient()}
                    label="Billing Start Date"
                    type="date"
                    value={consultant?.billingStartDate}
                    component={renderField}
                  />
                </div>
              </div>
            </fieldset>
          </div>

          <hr />

          <div className="row">
            <fieldset className="col-sm-12">
              <div className="row">
                <legend className="col-sm-12">Personal Account Details</legend>
                <div className="col-sm-6 col-xl-3">
                  <Field name="personalAccountName" readOnly={isClient() || isStaff(true)} label="Current Account Name" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field
                    name="personalAccountNumber"
                    readOnly={isClient() || isStaff(true)}
                    label="Current Account Number"
                    type="text"
                    component={renderField}
                  />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field name="personalSortCode" readOnly={isClient() || isStaff(true)} label="Current Sort Code" type="text" component={renderField} />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xl-3">
                  <Field name="personalAccountName2" readOnly={isClient() || isStaff(true)} label="Old Account Name" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field name="personalAccountNumber2" readOnly={isClient() || isStaff(true)} label="Old Account Number" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field name="personalSortCode2" readOnly={isClient() || isStaff(true)} label="Old Sort Code" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field
                    name="personalAccountChangeDate"
                    readOnly={isClient()}
                    label="Account Change Date"
                    type="date"
                    value={consultant?.personalAccountChangeDate}
                    component={renderField}
                  />
                </div>
              </div>
            </fieldset>
          </div>

          <hr />

          <div className="row">
            <fieldset className="col-sm-12 col-xl-6">
              <div className="row">
                <legend className="col-sm-12">Client Account Details</legend>
                <div className="col-sm-6 col-xl-4">
                  <Field name="phoneNumber" readOnly={isClient() || isStaff(true)} label="Phone Number" type="tel" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-4">
                  <Field name="clientAccountNumber" readOnly={isClient() || isStaff(true)} label="Account Number" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-4">
                  <Field name="clientSortCode" readOnly={isClient() || isStaff(true)} label="Sort Code" type="text" component={renderField} />
                </div>
              </div>
            </fieldset>
          </div>
          <hr />
          <div className="row">
            <fieldset className="col-sm-12">
              <div className="row">
                <legend className="col-sm-12">Secretary Details</legend>
                <div className="col-sm-6 col-xl-3">
                  <Field name="secretaryName" readOnly={isClient() || isStaff(true)} label="Name" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-3">
                  <Field name="secretaryPhoneNumber" readOnly={isClient() || isStaff(true)} label="Phone Number" type="text" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-6">
                  <Field name="secretaryEmail" readOnly={isClient() || isStaff(true)} label="Email" type="email" component={renderField} />
                </div>
              </div>
            </fieldset>
          </div>
          <hr />
          <HospitalTypeahead
            disabled={isClient() || isStaff(true)}
            selectedHospitals={this.state.selectedHospitals}
            shouldClear={this.state.clearTypeaheads}
            hasCleared={this.typeaheadCleared.bind(this)}
            onChange={(selected) => {
              this.setState(() => {
                return { selectedHospitals: selected, updatedHospitals: true };
              });
            }}
          />

          <hr />
          {/*{isSeniorStaff() && <Field name="isActive" label="Active?" id="is-active" component={renderCheckbox}/>}*/}
          {/*<br/>*/}
          <Field
            name="notifyPrimaryUserOnNewInvoiceBatch"
            disabled={isClient() || isStaff(true)}
            label="Receive emails when invoices are uploaded?"
            id="notify-primary-user-on-new-invoice-batch"
            component={renderCheckbox}
          />
          <br />
          <Field
            name="notifyPrimaryUserOnWeeklyInvoiceSummary"
            disabled={isClient() || isStaff(true)}
            label="Receive weekly summary of all invoices uploaded?"
            id="notify-primary-user-on-weekly-invoice-summary"
            component={renderCheckbox}
          />

          <br />
          <Field
            name="useAutomatedChasing"
            disabled={isClient() || isStaff(true)}
            label="Use Automated Invoice Chasing?"
            id="use-automated-chasing"
            component={renderCheckbox}
          />
          <br />
          {(isSeniorStaff() || isClient()) &&
            (isUpdatingConsultant ? (
              <MASpinner />
            ) : (
              <button type="submit" disabled={(!this.state.updatedHospitals && pristine) || submitting} className="btn btn-primary btn-block">
                {isCreate ? 'Create Consultant' : 'Update'}
              </button>
            ))}
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'AccountDetailsForm',
})(connect(mapStateToProps, mapDispatchToProps)(AccountDetailsForm));
