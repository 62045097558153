import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import Helmet from 'react-helmet';
import BillingChecklistEventForm from '../../components/forms/BillingChecklistEvent/BillingChecklistEventForm';
import Alert from 'react-bootstrap/Alert';

class BillingChecklistEventSingle extends React.Component {
  render() {
    const { billingChecklistEvent, deleteBillingChecklistEvent, isDeleting } = this.props;

    return (
      <div>
        <Helmet>
          <title>{billingChecklistEvent.name} - A BillingChecklistEvent</title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="crud__meta">
                    <Link to={ROUTES.BILLING_CHECKLIST_EVENTS.ALL.replace('/:page', '')}>&larr; Back to Billing Checklist Events</Link>
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="crud__body-title">Edit a Billing Checklist Event ({billingChecklistEvent.name})</h1>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <BillingChecklistEventForm billingChecklistEvent={billingChecklistEvent} />

                    {isDeleting && (
                      <Alert variant={'danger'}>
                        Deleting a Billing Checklist Event will permanently remove it and it will no longer show on the Billing Checklist
                      </Alert>
                    )}
                    <button
                      onClick={() => {
                        deleteBillingChecklistEvent();
                      }}
                      className={`btn mr-3 ${isDeleting ? 'btn-primary' : 'btn-danger'}`}
                      type="button">
                      {isDeleting ? 'This is destructive and irreversible. Click again to confirm...' : 'Delete Billing Checklist Event'}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BillingChecklistEventSingle;
