import React from 'react';
import { connect } from 'react-redux';
import { userSingleFetch } from '../../lib/redux/actions/user';
import Profile from './Profile';
import MASpinner from '../../components/common/MASpinner';

const mapStateToProps = (state) => ({
  ...state.userSingle,
  ...state.auth.userId,
});

const mapDispatchToProps = {
  userSingleFetch,
};

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props.userSingleFetch(this.props.userId);
  }

  render() {
    const { user, isFetchingUser } = this.props;
    if (!user || isFetchingUser) {
      return <MASpinner />;
    }
    return <Profile user={user} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
