import React from 'react';
import Table from 'react-bootstrap/Table';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { timeOfDayMap } from '../../lib/helper/formatting';
import { getCurrentWeekRange, isToday } from '../../lib/helper/calendarTableHelper';

const moment = extendMoment(Moment);

class BillingChecklistTable extends React.Component {
  constructor(props) {
    super(props);
    this.today = moment().utc();
  }

  renderHeaderCell(day) {
    return (
      <th key={`th-${day.unix()}`} className={isToday(day, this.today) ? 'highlight-today' : undefined}>
        <span className="day">{day.format('ddd')}</span>
        <span className="date">{day.format('DD/MM')}</span>
      </th>
    );
  }

  renderHeader() {
    const { currentDate, consultants } = this.props;
    const singleConsultant = consultants.length === 1;
    const headerCells = [];
    for (let day of getCurrentWeekRange(currentDate).by('day')) {
      headerCells.push(this.renderHeaderCell(day));
    }

    return (
      <thead>
        <tr>
          {!singleConsultant && <th style={{ width: '180px' }}>Account Name</th>}
          {headerCells}
        </tr>
      </thead>
    );
  }

  renderRow(consultant, currentMonday) {
    const { consultants } = this.props;
    const singleConsultant = consultants.length === 1;

    if (!consultant.isActive) {
      if (moment(consultant.deactivatedDate).utc().isBefore(currentMonday.utc())) {
        return null;
      }
    }
    return (
      <tr key={consultant.code}>
        {!singleConsultant && (
          <th>
            {consultant.name} <small>{consultant.code}</small>
          </th>
        )}
        {this.renderRowCells(consultant)}
      </tr>
    );
  }

  renderRowCells(consultant) {
    const { checklistInvoiceBatches, currentDate, checklistWeeklyEvents } = this.props;

    const consultantInvoices = checklistInvoiceBatches.filter((batch) => {
      return batch.code === consultant.code;
    });

    const range = getCurrentWeekRange(currentDate);
    const cells = [];
    for (let day of range.by('day')) {
      cells.push(this.renderCell(consultant, consultantInvoices, day, checklistWeeklyEvents));
    }
    return cells;
  }

  slotDOWConverted(dow) {
    return Number(dow) === 7 ? 0 : Number(dow);
  }

  renderCell(consultant, consultantInvoices, cellDate, checklistWeeklyEvents) {
    const hasConsultantSentInvoiceForDateTimeSlot = (timeOfDaySlot) => {
      return (
        consultantInvoices.filter((invoiceBatch) => {
          return parseInt(invoiceBatch.time_of_day) === timeOfDaySlot && moment(invoiceBatch.date).format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD');
        }).length > 0
      );
    };

    const doesConsultantHaveClinicOnDateTimeSlot = (timeOfDaySlot) => {
      return (
        consultant.clinicSlots &&
        consultant.clinicSlots.length > 0 &&
        consultant.clinicSlots.filter((slot) => {
          return this.slotDOWConverted(slot.dayOfWeek) === moment(cellDate).utc().day() && slot.timeOfDay === timeOfDaySlot;
        }).length > 0
      );
    };

    let eventNameOnThisDay = '';
    const doesConsultantHaveEventOnThisDay = (cellDate) => {
      return (
        checklistWeeklyEvents.filter((event) => {
          if (
            (event.code === consultant.code || event.code == null) &&
            moment(event.startDate).format('YYYY-MM-DD') <= cellDate.format('YYYY-MM-DD') &&
            moment(event.endDate).format('YYYY-MM-DD') >= cellDate.format('YYYY-MM-DD')
          ) {
            eventNameOnThisDay = event.name;
            return true;
          }
          return false;
        }).length > 0
      );
    };

    const batchExistsForAM = hasConsultantSentInvoiceForDateTimeSlot(timeOfDayMap.AM);
    const batchExistsForPM = hasConsultantSentInvoiceForDateTimeSlot(timeOfDayMap.PM);

    const consultantBatchExpectedAM = doesConsultantHaveClinicOnDateTimeSlot(timeOfDayMap.AM);
    const consultantBatchExpectedPM = doesConsultantHaveClinicOnDateTimeSlot(timeOfDayMap.PM);

    const eventOnThisDay = doesConsultantHaveEventOnThisDay(cellDate);

    return (
      <td key={`${consultant.code}-td-${cellDate.unix()}`} className={`status ${isToday(cellDate, this.today) ? 'highlight-today' : undefined}`}>
        <i
          className={`fa ${batchExistsForAM ? 'fa-check-circle' : eventOnThisDay ? 'fa-h-square' : 'fa-times-circle'} ${!batchExistsForAM && consultantBatchExpectedAM && !eventOnThisDay && 'text-danger'}`}
          title={`${batchExistsForAM ? 'Received (AM)' : eventOnThisDay ? eventNameOnThisDay : 'Nothing Received (AM)'}`}
        />
        <i
          className={`fa ${batchExistsForPM ? 'fa-check-circle' : eventOnThisDay ? 'fa-h-square' : 'fa-times-circle'} ${!batchExistsForPM && consultantBatchExpectedPM && !eventOnThisDay && 'text-danger'}`}
          title={`${batchExistsForPM ? 'Received (PM)' : eventOnThisDay ? eventNameOnThisDay : 'Nothing Received (PM)'}`}
        />

        {/*<i className="fa fa-times-circle text-danger" title="Expected but nothing Received (PM)"/>*/}
      </td>
    );
  }

  render() {
    const { consultants = [], currentDate } = this.props;
    if (consultants.length < 1) {
      return null;
    }
    const currentMonday = getCurrentWeekRange(currentDate).start;
    return (
      <Table hover className="mediaccounts-billing-checklist-table mb-0 d-none d-sm-table">
        {this.renderHeader()}
        <tbody>{consultants.map((consultant) => this.renderRow(consultant, currentMonday))}</tbody>
      </Table>
    );
  }
}

export default BillingChecklistTable;
