import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import InvoiceBatchInvoiceNumbersForm from '../../components/forms/InvoiceBatch/InvoiceBatchInvoiceNumbersForm';
import InvoiceBatchNotes from '../../components/InvoiceBatch/InvoiceBatchNotes';
import AuditLog from '../../components/common/AuditLog';
import EditForm from '../../components/forms/InvoiceBatch/EditForm';
import CreateNoteForm from '../../components/forms/InvoiceBatch/CreateNoteForm';
import InvoiceBatchRelatedInvoiceNumbers from '../../components/InvoiceBatch/InvoiceBatchRelatedInvoiceNumbers';
import { invoiceBatchIdFromIri, niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import CheckedAndPricedFileUploadForm from '../../components/forms/InvoiceBatch/CheckedAndPricedFileUploadForm';
import MAToggleButton from '../../components/common/MAToggleButton';
import { isClient, isOutsourcer, isSeniorStaff, isStaff } from '../../lib/helper/authorisation';
import { OutsourceFlag } from '../../components/common/OutsourceFlag';
import { hasBeenStatus, hasDiscrepantInvoiceCounts, isCancelled, isCheckedAndPriced, isCompleted, isProcessing } from '../../lib/helper/invoiceBatch';
import ActionButtons from '../../components/InvoiceBatch/ActionButtons';
import EditableNumberOfInvoicesBadge from '../../components/InvoiceBatch/EditableNumberOfInvoicesBadge';
import INVOICE_BATCH_FILE_TYPES from '../../lib/helper/fileTypes';
import Helmet from 'react-helmet';
import UseOriginalFilesButton from '../../components/common/UseOriginalFilesButton';
import { Tab, Tabs } from 'react-bootstrap';
import EditClinicDatesForm from '../../components/forms/InvoiceBatch/EditClinicDatesForm';
import PotentialDuplicates from '../../components/InvoiceBatch/PotentialDuplicates';
import InvoiceBatchCcsdCodeCostTableContainer from '../../components/ccsdCode/invoiceBatch/InvoiceBatchCcsdCodeCostTableContainer';
import EditHospitalsForm from '../../components/forms/InvoiceBatch/EditHospitalsForm';
import ProblemDataTable from '../../components/forms/ProblemData/ProblemDataTable';
import ConsultantSingleTableContainer from '../../components/Consultant/ConsultantSingleTableContainer';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import SplitButton from '../../components/common/SplitButton';
//import { consultantSingleFetch, consultantSingleUnload } from '../../lib/redux/actions/consultant';
//import { connect } from 'react-redux';
import InvoiceParseContainer from '../InvoiceParse';

//const mapStateToProps = (state) => ({
//  ...state.consultantSingle,
//});
//
//const mapDispatchToProps = {
//  consultantSingleFetch,
//  consultantSingleUnload,
//};

class InvoiceBatchSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUploader: false,
      appendFiles: false,
      amendingDates: false,
      amendingHospitals: false,
    };
  }
  //  componentDidMount() {
  //    const { invoiceBatch } = this.props;
  //
  //    this.props.consultantSingleFetch(invoiceBatch.consultant.id, false, true);
  //
  //  }

  toggleAmendDates() {
    this.setState((prevState) => {
      return {
        amendingDates: !prevState.amendingDates,
      };
    });
  }

  toggleAmendHospitals() {
    this.setState((prevState) => {
      return {
        amendingHospitals: !prevState.amendingHospitals,
      };
    });
  }

  toggleUploader(appendFiles = false) {
    this.setState((prevState) => {
      return { showUploader: !prevState.showUploader, appendFiles };
    });
  }

  renderHeader() {
    const { invoiceBatch } = this.props;

    let urgentText = 'Urgent';

    if (invoiceBatch.invoiceBatchUrgentCategory && invoiceBatch.invoiceBatchUrgentCategory.displayName) {
      urgentText = urgentText + ' - ' + invoiceBatch.invoiceBatchUrgentCategory.displayName;
    }

    let problemText = 'Problem';

    if (invoiceBatch.invoiceBatchProblemCategory && invoiceBatch.invoiceBatchProblemCategory.displayName) {
      problemText = problemText + ' - ' + invoiceBatch.invoiceBatchProblemCategory.displayName;
    }

    return (
      <Card.Header className={'card-header--invoice-batch-single'}>
        <div className="d-flex align-items-center">
          <span className="invoice-batch-single__meta">
            <span className="d-none d-sm-inline">Invoice Batch </span>ID <strong className="invoice-batch-single__id">{invoiceBatch.id}</strong>
            {invoiceBatch.isUrgent && (
              <Badge variant={'warning'} className="ml-2 text-uppercase">
                {urgentText}
              </Badge>
            )}
            {invoiceBatch.isEmbassy && (
              <Badge variant={'danger'} className="ml-2 text-uppercase">
                Embassy
              </Badge>
            )}
          </span>
          <span className="ml-auto invoice-batch-single__statuses">
            {invoiceBatch?.splitFromInvoiceBatch && (
              <Link
                to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', invoiceBatchIdFromIri(invoiceBatch.splitFromInvoiceBatch))}
                className="btn btn-sm text-white btn-dark">
                Split From Batch {invoiceBatchIdFromIri(invoiceBatch.splitFromInvoiceBatch)}
              </Link>
            )}
            {invoiceBatch?.splitToInvoiceBatch && (
              <Link
                to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', invoiceBatchIdFromIri(invoiceBatch.splitToInvoiceBatch))}
                className="btn btn-sm text-white btn-dark">
                Split To Batch {invoiceBatchIdFromIri(invoiceBatch.splitToInvoiceBatch)}
              </Link>
            )}

            {!isClient() && invoiceBatch.isProblem && (
              <Badge pill variant={'danger'} className="ml-2 text-uppercase">
                <i className="fas fa-exclamation-circle" /> <span className="d-none d-sm-inline">{problemText}</span>
              </Badge>
            )}
            {invoiceBatch && invoiceBatch.status && (
              <Badge pill variant={'light'} className="ml-2">
                <strong className="invoice-batch-single__id text-uppercase">
                  {invoiceBatch.status.name}
                  {isCompleted(invoiceBatch) && ` ${niceDateFormatter(invoiceBatch.closedAt)}`}
                </strong>
              </Badge>
            )}
          </span>
        </div>
      </Card.Header>
    );
  }

  renderDetailRow() {
    const { invoiceBatch } = this.props;
    return (
      <>
        <Row>
          <Col md={'6'}>
            <h1 className="invoice-batch-single__consultant-name">
              {invoiceBatch.consultant.name}
              <span className="invoice-batch-single__consultant-id ml-3">{invoiceBatch.consultant.code}</span>
              {invoiceBatch.consultant.gmcReferenceNumber && (
                <span className="invoice-batch-single__consultant-id ml-3">GMC: {invoiceBatch.consultant.gmcReferenceNumber}</span>
              )}
            </h1>
            <h2 className="invoice-batch-single__date">
              Created {niceDateFormatter(invoiceBatch.createdAt)} {invoiceBatch.createdBy && `by ${userNameFormatter(invoiceBatch.createdBy)}`}
            </h2>
            {!isClient() && invoiceBatch.assignedUser && (
              <div>
                <h2 className="invoice-batch-single__date">
                  Assigned to {userNameFormatter(invoiceBatch.assignedUser)} {invoiceBatch.isProcessingOutsourced && <OutsourceFlag />}
                  {isCheckedAndPriced(invoiceBatch) && (
                    <span className="text-danger">
                      <i className="fa fa-inbox mr-2" />
                      <span className="small">Awaiting acceptance</span>
                    </span>
                  )}
                </h2>
              </div>
            )}
          </Col>
          <Col md={'6'}>
            <Row>
              <Col sm={'12'} lg={'6'}>
                <h4 className="h6 text-uppercase">Clinics selected</h4>
                <ul className="list-unstyled clinic-sessions mb-0">
                  {invoiceBatch.invoiceDates &&
                    invoiceBatch.invoiceDates
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((invoiceDate) => (
                        <li key={invoiceDate.id}>
                          {niceDateFormatter(invoiceDate.date, 'ddd')} <strong>{niceDateFormatter(invoiceDate.date)}</strong>{' '}
                          <span className="clinic-session__day-half">({invoiceDate.timeOfDayText})</span>
                        </li>
                      ))}
                </ul>
                {isSeniorStaff() && !isCancelled(invoiceBatch) && !isCompleted(invoiceBatch) && (
                  <button
                    className={`btn btn-sm btn-secondary mt-2`}
                    onClick={() => {
                      this.toggleAmendDates();
                    }}>
                    {this.state.amendingDates ? 'Cancel Amend' : 'Amend Dates'}
                  </button>
                )}
              </Col>
              <Col sm={'12'} lg={'6'} className="mt-lg-0 mt-sm-4">
                <h4 className="h6 text-uppercase">Hospitals selected</h4>
                <ul className="list-unstyled clinic-sessions mb-0">
                  {invoiceBatch.hospitals && invoiceBatch.hospitals.map((hospital) => <li key={hospital.id}>{hospital.name}</li>)}
                </ul>
                {isSeniorStaff() && !isCancelled(invoiceBatch) && !isCompleted(invoiceBatch) && (
                  <button
                    className={`btn btn-sm btn-secondary mt-2`}
                    onClick={() => {
                      this.toggleAmendHospitals();
                    }}>
                    {this.state.amendingHospitals ? 'Cancel Amend' : 'Amend Hospitals'}
                  </button>
                )}
              </Col>
            </Row>
          </Col>
          {/*<Col md={'2'}>*/}
          {/*  <ActionButtons invoiceBatch={invoiceBatch} />*/}
          {/*</Col>*/}
        </Row>
        {isSeniorStaff() && (this.state.amendingDates || this.state.amendingHospitals) && (
          <Row className={`border-top mt-4 pt-4`}>
            {this.state.amendingDates && (
              <Col>
                <h4 className="h6 text-uppercase">Amend Selected Clinic Dates</h4>
                <EditClinicDatesForm
                  invoiceBatch={invoiceBatch}
                  toggleAmending={() => {
                    this.toggleAmendDates();
                  }}
                />
              </Col>
            )}

            {this.state.amendingHospitals && (
              <Col>
                <h4 className="h6 text-uppercase">Amend Selected Hospitals</h4>
                <EditHospitalsForm
                  invoiceBatch={invoiceBatch}
                  toggleAmending={() => {
                    this.toggleAmendHospitals();
                  }}
                />
              </Col>
            )}
          </Row>
        )}
      </>
    );
  }

  renderAlerts() {
    const { invoiceBatch } = this.props;
    if (!isStaff()) {
      return null;
    }
    return (
      <>
        {isCancelled(invoiceBatch) && (
          <Alert variant={'danger'} className="mb-3">
            The invoice batch has been Cancelled
          </Alert>
        )}
        {!hasBeenStatus(invoiceBatch, INVOICE_BATCH_STATUS.SUBMITTED) && !isCompleted(invoiceBatch) && !isCancelled(invoiceBatch) && (
          <Alert variant={'warning'} className="mb-3">
            The invoice batch has not been Checked and Priced yet
          </Alert>
        )}
        {invoiceBatch &&
          !invoiceBatch.assignedUser &&
          hasBeenStatus(invoiceBatch, INVOICE_BATCH_STATUS.SUBMITTED) &&
          !isCompleted(invoiceBatch) &&
          !isCancelled(invoiceBatch) && (
            <Alert variant={'warning'} className="mb-3">
              The invoice batch has not been Assigned yet
            </Alert>
          )}
        {isCompleted(invoiceBatch) && (
          <Alert variant={'primary'} className="mb-3">
            The invoice batch has been Completed
          </Alert>
        )}
      </>
    );
  }

  doDownload(fileIRI, fileName) {
    const { addToast } = this.props;
    this.props.downloadFile(fileIRI, fileName).catch(() => {
      addToast('There was a problem downloading the file. Please seek support if the issue continues.', false);
    });
  }

  renderFilesSection(originalFiles, checkedAndPricedFiles) {
    const { invoiceBatch } = this.props;

    const originalFile = originalFiles.length > 0 && originalFiles[0];
    let latestCheckedAndPricedFile;
    if (invoiceBatch.useOriginalFiles) {
      latestCheckedAndPricedFile = originalFile;
    } else {
      latestCheckedAndPricedFile = checkedAndPricedFiles.length > 0 && checkedAndPricedFiles[0];
    }
    let uploadButtonText;
    if (latestCheckedAndPricedFile !== false) {
      uploadButtonText = (
        <>
          <i className="fas fa-file-upload mr-2" /> Replace Checked And Priced Files
        </>
      );
    } else if (invoiceBatch.noFilesSupplied && !invoiceBatch.numberOfInvoicesToProcess) {
      uploadButtonText = <>Confirm invoices in client note</>;
    } else if (invoiceBatch.noFilesSupplied && !!invoiceBatch.numberOfInvoicesToProcess) {
      uploadButtonText = false;
    } else {
      uploadButtonText = (
        <>
          <i className="fas fa-file-upload mr-2" /> Upload Checked and Priced Files
        </>
      );
    }

    if (!originalFile && invoiceBatch.noFilesSupplied !== true) {
      return null;
    }
    return (
      <Row>
        <Col>
          <div className="invoice-batch-single__section mb-3">
            <div className="invoice-batch-single__section-icon">
              <i className="fas fa-file-upload" />
            </div>
            <h2 className="invoice-batch-single__section-header d-flex justify-content-between">
              Files for Processing
              <span className="invoice-batch-single__section-header__invoices-supplied-wrap">
                <small className="d-none d-sm-inline mr-2">
                  <span className="d-none d-xl-inline">Inv. </span>Supplied:
                </small>
                <span className={`badge badge-${hasDiscrepantInvoiceCounts(invoiceBatch) ? 'danger' : 'secondary'} badge-pill`}>
                  {invoiceBatch.advisedNumberOfInvoices || 'n/a'}
                </span>
              </span>
              {(invoiceBatch.numberOfInvoicesToProcess || !invoiceBatch.advisedNumberOfInvoices) && (
                <span>
                  <small className="d-none d-sm-inline mr-2">
                    <span className="d-none d-xl-inline">Inv. </span>To Process:
                  </small>
                  <EditableNumberOfInvoicesBadge invoiceBatch={invoiceBatch} />
                </span>
              )}
              {(invoiceBatch.numberOfInvoicesToProcess || !invoiceBatch.advisedNumberOfInvoices) && (
                <span>
                  <SplitButton invoiceBatch={invoiceBatch} />
                </span>
              )}
            </h2>
            <Row>
              {!isOutsourcer(true) && (
                <Col sm={12} md={12} lg={12} xl={!isClient() ? 6 : 12}>
                  <div className={`invoice-batch-single__section ${latestCheckedAndPricedFile !== false ? '' : 'border-primary'}`}>
                    <h2 className="invoice-batch-single__section__title">Files Supplied</h2>
                    {originalFile.isDeletedForDataProtection && (
                      <p className="font-italic">This file is no longer available. It was removed for data security.</p>
                    )}
                    {invoiceBatch.noFilesSupplied ? (
                      <p>No files were supplied please check the notes.</p>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={this.doDownload.bind(this, originalFile['@id'], `BATCH${invoiceBatch.id}_${invoiceBatch.consultant.code}_orig.zip`)}>
                        <i className="fas fa-file-download mr-2" /> Download Original Files
                      </button>
                    )}
                  </div>
                </Col>
              )}
              {!isClient() && (
                <Col sm={12} md={12} lg={12} xl={6}>
                  <div className={`invoice-batch-single__section ${latestCheckedAndPricedFile !== false ? 'border-primary' : ''}`}>
                    <h2 className="invoice-batch-single__section__title">
                      {invoiceBatch.noFilesSupplied && latestCheckedAndPricedFile === false ? 'Confirm Number of Invoices' : 'Checked and Priced Files'}
                    </h2>
                    {/*<Row>*/}
                    {latestCheckedAndPricedFile !== false && (
                      <>
                        {latestCheckedAndPricedFile.isDeletedForDataProtection ? (
                          <p className="font-italic">This file is no longer available. It was removed for data security.</p>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={this.doDownload.bind(
                              this,
                              latestCheckedAndPricedFile['@id'],
                              `BATCH${invoiceBatch.id}_${invoiceBatch.consultant.code}_priced.zip`,
                            )}>
                            <i className="fas fa-file-download mr-2" /> Files to Process
                          </button>
                        )}
                      </>
                    )}
                    {latestCheckedAndPricedFile === false && invoiceBatch.noFilesSupplied && !!invoiceBatch.numberOfInvoicesToProcess && (
                      <p>Number of invoices confirmed as: {invoiceBatch.numberOfInvoicesToProcess}</p>
                    )}
                    {uploadButtonText !== false &&
                      isSeniorStaff() &&
                      !isCancelled(invoiceBatch) &&
                      !isCompleted(invoiceBatch) &&
                      !invoiceBatch?.splitFromInvoiceBatch && (
                        //                          <Col>
                        <MAToggleButton
                          toggleAction={this.toggleUploader.bind(this, false)}
                          buttonContent={uploadButtonText}
                          classes="btn btn-outline-danger"
                        />
                        //                          </Col>
                      )}
                    {latestCheckedAndPricedFile !== false &&
                      isSeniorStaff() &&
                      !isCancelled(invoiceBatch) &&
                      !isCompleted(invoiceBatch) &&
                      !invoiceBatch?.splitFromInvoiceBatch && (
                        //                          <Col>
                        <MAToggleButton
                          toggleAction={this.toggleUploader.bind(this, true)}
                          buttonContent={
                            <>
                              <i className="fas fa-file-medical mr-2" /> Add Extra Checked And Priced Files
                            </>
                          }
                          classes="btn btn-outline-secondary"
                        />
                        //                          </Col>
                      )}
                    {isSeniorStaff() && !invoiceBatch.noFilesSupplied && Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.SUBMITTED) && (
                      //                        <Col>
                      <UseOriginalFilesButton invoiceBatch={invoiceBatch} />
                      //                        </Col>
                    )}
                    {/*</Row>*/}
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const { invoiceBatch, onUploadStart } = this.props;

    const originalFiles =
      invoiceBatch &&
      invoiceBatch.invoiceFiles &&
      invoiceBatch.invoiceFiles.filter((invoiceFile) => invoiceFile.type === INVOICE_BATCH_FILE_TYPES.FILE_TYPE_INVOICE_ORIGINAL);
    const checkedAndPricedFiles =
      invoiceBatch &&
      invoiceBatch.invoiceFiles &&
      invoiceBatch.invoiceFiles.filter((invoiceFile) => invoiceFile.type === INVOICE_BATCH_FILE_TYPES.FILE_TYPE_INVOICE_CURRENT_CHECKED_AND_PRICED);

    const hasCheckedAndPricedFiles = checkedAndPricedFiles && checkedAndPricedFiles.length > 0;
    return (
      <div>
        <Helmet>
          <title>{`Invoice Batch ${invoiceBatch.id} for ${invoiceBatch.consultant.code}`}</title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              {this.renderHeader()}
              <Card.Body>
                {this.renderDetailRow()}
                <hr />
                {this.renderAlerts()}
                {/* Alert section >>> */}
                <Row>
                  {/* Left Column - START */}
                  <Col sm={12} md={12} lg={12} xl={9}>
                    {isClient() && !isCancelled(invoiceBatch) && !isCompleted(invoiceBatch) && (
                      <Alert variant="info" className="mb-3">
                        Invoice processing underway - please check back later for more information
                      </Alert>
                    )}

                    {this.renderFilesSection(originalFiles, checkedAndPricedFiles)}

                    {/* Upload New Files - END */}
                    {isSeniorStaff() && this.state.showUploader && (
                      <Row>
                        <Col>
                          <CheckedAndPricedFileUploadForm
                            invoiceBatch={invoiceBatch}
                            onFilesUploaded={this.toggleUploader.bind(this)}
                            hasCheckedAndPricedFiles={hasCheckedAndPricedFiles}
                            appendFiles={this.state.appendFiles}
                            onUploadStart={onUploadStart}
                          />
                        </Col>
                      </Row>
                    )}
                    {/* Upload New Files - END */}

                    {isCompleted(invoiceBatch) && <InvoiceBatchRelatedInvoiceNumbers invoiceBatch={invoiceBatch} />}
                    <Tabs defaultActiveKey="notes" variant="pills" className="mx-1 mb-2">
                      <Tab eventKey="notes" title="Notes">
                        <InvoiceBatchNotes invoiceBatch={invoiceBatch} />
                        {/*{!isCancelled(invoiceBatch) && <CreateNoteForm invoiceBatch={invoiceBatch} />}*/}
                      </Tab>
                      {invoiceBatch?.consultant?.bible && (
                        <Tab eventKey="bible" title="Bible">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-book-open" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Bible</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <div dangerouslySetInnerHTML={{ __html: invoiceBatch.consultant.bible }}></div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}
                      {isStaff() && invoiceBatch.consultant && (
                        <Tab eventKey="contact" title="Contact">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-book-open" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Contact</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <ConsultantSingleTableContainer consultantId={invoiceBatch.consultant.id} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}

                      {invoiceBatch?.consultant?.id && (
                        <Tab eventKey="fees" title="Fees">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-coins" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Fees ({invoiceBatch.consultant.consultantType.displayName})</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <InvoiceBatchCcsdCodeCostTableContainer consultantId={invoiceBatch.consultant.id} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}

                      {invoiceBatch?.problemData.length > 0 && (
                        <Tab eventKey="problemDatas" title="Problems">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-exclamation-triangle" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Problems</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <ProblemDataTable invoiceBatch={invoiceBatch} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}
                      {isStaff() && invoiceBatch?.consultant?.notes && (
                        <Tab eventKey="consultantNotes" title="Consultant Notes">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-edit" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Consultant Notes</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <div dangerouslySetInnerHTML={{ __html: invoiceBatch.consultant.notes }} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}
                    </Tabs>

                    {isSeniorStaff() && (
                      <PotentialDuplicates
                        currentBatch={invoiceBatch}
                        hospitals={invoiceBatch.hospitals}
                        consultantIRI={invoiceBatch.consultant['@id']}
                        clinicDates={invoiceBatch.invoiceDates}
                      />
                    )}
                  </Col>
                  {/* Left Column - END */}

                  {/* Right Column - START */}
                  {/* Not needed at all for some roles */}
                  <Col sm={12} md={12} lg={12} xl={3}>
                    {!isClient() && invoiceBatch && !isCancelled(invoiceBatch) && (
                      <EditForm invoiceBatch={invoiceBatch} hasCheckedAndPricedFiles={hasCheckedAndPricedFiles} />
                    )}
                    <ActionButtons invoiceBatch={invoiceBatch} />
                    {!isCancelled(invoiceBatch) && <CreateNoteForm invoiceBatch={invoiceBatch} />}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!isClient() &&
                      !isCancelled(invoiceBatch) &&
                      !isCompleted(invoiceBatch) &&
                      (hasCheckedAndPricedFiles || invoiceBatch.noFilesSupplied || invoiceBatch.useOriginalFiles) && (
                        <>
                          {isProcessing(invoiceBatch) && invoiceBatch?.consultant?.useAutomatedChasing && (
                            <InvoiceParseContainer isEmbed={true} invoiceBatch={invoiceBatch} />
                          )}
                          <InvoiceBatchInvoiceNumbersForm invoiceBatch={invoiceBatch} />
                        </>
                      )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {isSeniorStaff() && <AuditLog invoiceBatch={invoiceBatch} />}
      </div>
    );
  }
}
export default InvoiceBatchSingle;
//export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchSingle);
