import Form from 'react-bootstrap/Form';
import FileUploader from '../../file/FileUploader';
import FileBrowser from '../../file/FileBrowser';
import { reduxForm, getFormValues } from 'redux-form';
import React from 'react';
import { connect } from 'react-redux';
import 'iterators-polyfill';
import { fileUpload, fileDelete, fileUploadClearReferences } from '../../../lib/redux/actions/invoiceFiles';
import { invoicePoll, invoiceUploadPaid } from '../../../lib/redux/actions/invoices';
import { addToast } from '../../../lib/redux/actions/toast';
import MASpinner from '../../common/MASpinner';
import moment from 'moment';

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    currentFormValues: getFormValues('ImportPaidCSVForm')(state),

    ...state.invoiceFiles,
  };
}

const mapDispatchToProps = {
  fileUpload,
  fileDelete,
  fileUploadClearReferences,
  invoiceUpdate: invoiceUploadPaid,
  invoicePoll,
  addToast,
};

class ImportPaidCSVForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileLimitReached: false,
      recordsToImport: 0,
      recordsImported: 0,
      errors: [],
      ignorePoll: false,
    };
    this.checkInvoices.bind(this);
    this.poll = null;
  }

  componentDidMount() {
    this.checkInvoices();
    this.poll = setInterval(() => this.checkInvoices(), 2500);
  }

  componentWillUnmount() {
    clearInterval(this.poll);
  }

  onFilesAdded(e) {
    const { fileUploadClearReferences } = this.props;
    fileUploadClearReferences();
    clearInterval(this.poll);
    this.setState({
      fileLimitReached: false,
      recordsToImport: 0,
      recordsImported: 0,
      errors: [],
      isProcessing: false,
      lastImported: null,
    });

    this.props.fileUpload(e.target.files[0]);
  }

  onFilesDelete(e) {
    this.props.fileDelete(e);
  }

  onSubmit() {
    const { invoiceUpdate, temporaryFiles, fileUploadClearReferences } = this.props;

    let file = temporaryFiles.find(() => true);

    return invoiceUpdate({ fileId: file['id'], update: true }).then(() => {
      this.checkInvoices();
      this.poll = setInterval(() => this.checkInvoices(), 10000);
      fileUploadClearReferences();
    });
  }

  checkInvoices() {
    const { invoicePoll, setErrors, setUnassigned, setUpdatedDate } = this.props;
    invoicePoll(true).then((response) => {
      this.setState({
        recordsImported: response['recordsAdded'],
        recordsToImport: response['recordsTotal'],
        isProcessing: response['isProcessing'],
        lastImported: response['lastImported'],
      });
      setErrors(response['errors']);
      setUnassigned(response['invoicesUnassigned']);
      setUpdatedDate(response['lastImported'].date);
    });
  }

  render() {
    const { handleSubmit, error, temporaryFiles, fileRequestInProgress } = this.props;
    const { fileLimitReached, recordsToImport, isProcessing, recordsImported, lastImported } = this.state;

    if (isProcessing) {
      return (
        <div className={'invoice-import'}>
          <MASpinner wrapperClasses={'text-align-left mb-2'} />
          {isProcessing ? <strong>Currently Processing Invoices</strong> : null}
          {lastImported ? <p style={{ margin: 0 }}>Import started at {moment(lastImported.date).format('DD/MM/YYYY HH:mm:ss')}</p> : null}
          {isProcessing ? (
            <p>
              Records Imported {recordsImported ? recordsImported : 0} / {recordsToImport ? recordsToImport : '0'}{' '}
            </p>
          ) : null}
        </div>
      );
    }

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {isProcessing ? null : (
            <>
              <p>Please select the CSV file you wish to upload</p>
              <FileUploader onFilesAdded={this.onFilesAdded.bind(this)} requestInProgress={fileRequestInProgress} multiple={false} />
              {temporaryFiles && temporaryFiles.length > 0 ? (
                <FileBrowser files={temporaryFiles} deleteHandler={this.onFilesDelete.bind(this)} isLocked={fileRequestInProgress} />
              ) : null}
              {fileLimitReached ? <p>You can only upload one file at a time</p> : null}
            </>
          )}
          {lastImported ? <p style={{ margin: 0 }}>Import started at {moment(lastImported.date).format('DD/MM/YYYY HH:mm:ss')}</p> : null}
          {!isProcessing && recordsImported > 0 ? <p style={{ margin: 0 }}>Records Imported {recordsToImport ? recordsToImport : '0'} </p> : null}
          {!isProcessing && recordsImported > 0 ? <p style={{ margin: 0 }}>Payments Processed {recordsImported ? recordsImported : 0} </p> : null}
          <hr />
          {isProcessing || temporaryFiles.length === 0 ? null : (
            <button type="submit" className="btn btn-primary">
              Import invoices
            </button>
          )}
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ImportPaidCSVForm',
})(connect(mapStateToProps, mapDispatchToProps)(ImportPaidCSVForm));
