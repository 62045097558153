import React from 'react';
import { connect } from 'react-redux';
import {
  billingChecklistEventSingleFetch,
  billingChecklistEventSingleUnload,
  billingChecklistEventDelete,
} from '../../lib/redux/actions/billingChecklistEvents';
import BillingChecklistEventSingle from './BillingChecklistEventSingle';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';
import { consultantListFetch } from '../../lib/redux/actions/consultant';

const mapStateToProps = (state) => ({
  ...state.consultant,
  ...state.billingChecklistEvents,
});

const mapDispatchToProps = {
  consultantListFetch,
  billingChecklistEventSingleFetch,
  billingChecklistEventSingleUnload,
  billingChecklistEventDelete,
  addToast,
};

class BillingChecklistEventSingleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
    };
  }

  componentDidMount() {
    this.props.consultantListFetch({}, { pagination: false });
    this.props.billingChecklistEventSingleFetch(this.props.match.params.id);
  }

  delete() {
    const { addToast, history } = this.props;
    if (!this.state.deleting) {
      this.setState(() => {
        return { deleting: true };
      });
    } else {
      this.props.billingChecklistEventDelete(this.props.billingChecklistEvent.id).then(() => {
        addToast('Billing Checklist Event deleted successfully', true);
        this.setState(() => {
          return { deleting: false };
        });
        history.push(ROUTES.BILLING_CHECKLIST_EVENTS.ALL.replace(':page', ''));
      });
    }
  }

  componentWillUnmount() {
    this.props.billingChecklistEventSingleUnload();
  }

  render() {
    const { billingChecklistEvent, isFetchingBillingChecklistEvent, hasLoadedBillingChecklistEvent, consultants } = this.props;
    if (!billingChecklistEvent || (!hasLoadedBillingChecklistEvent && isFetchingBillingChecklistEvent)) {
      return <MASpinner />;
    }
    return (
      <BillingChecklistEventSingle
        billingChecklistEvent={billingChecklistEvent}
        consultants={consultants}
        isDeleting={this.state.deleting}
        deleteBillingChecklistEvent={this.delete.bind(this)}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingChecklistEventSingleContainer);
