import React from 'react';
import { Badge, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import AccountDetailsForm from '../../components/forms/Consultant/AccountDetailsForm';
import RegularClinicsForm from '../../components/forms/Consultant/RegularClinicsForm';
import SubAccountInviteForm from '../../components/forms/Consultant/SubAccountDetailsForm';
import ConsultantBibleForm from '../../components/forms/Consultant/ConsultantBibleForm';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { isAdmin, isClient, isConsultant, isSeniorStaff, isStaff } from '../../lib/helper/authorisation';
import Helmet from 'react-helmet';
import ConsultantBibleLog from '../../components/consultantBibleLog/ConsultantBibleLog';
import ConsultantCcsdCodeCostTableContainer from '../../components/ccsdCode/consultant/ConsultantCcsdCodeCostTableContainer';
import NotesForm from '../../components/forms/Consultant/NotesForm';
import DatedCostsChangeLog from '../../components/datedCost/DatedCostsChangeLog';
import ConsultantRegistrationFormContainer from '../../components/consultantRegistrationForm/ConsultantRegistrationFormContainer';
import ConsultantOnboardingItemsTableContainer from '../../components/consultantOnboarding/ConsultantOnboardingItemsTableContainer';
import InvoiceBatchWeeklyCounts from '../../components/InvoiceBatch/InvoiceBatchWeeklyCounts';
import ConsultantTicketListContainer from '../ConsultantTicketList';

class ConsultantSingle extends React.Component {
  render() {
    const { consultant, subAccountInviteError, deactivateConsultant, activateConsultant, deleteConsultant, isDeleting, isDeactivating } = this.props;
    return (
      <div>
        <Helmet>
          <title>{consultant.name} - A Consultant</title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  {!isClient() && (
                    <span className="crud__meta">
                      <Link to={ROUTES.CONSULTANTS.ALL.replace('/:page', '')}>&larr; Back to Consultant Accounts</Link>
                    </span>
                  )}
                  <span className="ml-auto crud__statuses">
                    {consultant.isActive ? (
                      <Badge pill variant={'primary'} className="ml-2 text-uppercase">
                        Active
                      </Badge>
                    ) : (
                      <Badge pill variant={'danger'} className="ml-2 text-uppercase">
                        <i className="fas fa-exclamation-circle" /> Inactive
                      </Badge>
                    )}
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="crud__body-title">
                      Edit {isClient() ? 'your' : 'a'} Consultant Account ({consultant.name})
                    </h1>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Tabs defaultActiveKey="accountDetails" variant="pills" className="mx-1 mb-2">
                      <Tab eventKey="accountDetails" title="Account">
                        <Row>
                          <Col>
                            <AccountDetailsForm consultant={consultant} />
                          </Col>
                        </Row>

                        {isSeniorStaff() && consultant.isActive && (
                          <Card>
                            <Card.Header>
                              <i className="fas fa-times-circle text-danger mr-3" />
                              Account deactivation
                            </Card.Header>
                            <Card.Body>
                              <p>
                                Clicking the below deactivates the Consultant account, stops access to the portal by the Consultant (Primary User) and Sub
                                Users, and sends a closure email to the Consultant. <strong>No data is deleted.</strong>
                              </p>
                              <button
                                onClick={() => {
                                  deactivateConsultant();
                                }}
                                className={`btn ${isDeactivating ? 'btn-danger' : 'btn-outline-danger'}`}
                                type="button">
                                {isDeactivating ? 'Confirm Deactivation' : 'Disable access for consultant and users?'}
                              </button>
                            </Card.Body>
                          </Card>
                        )}

                        {isSeniorStaff() && !consultant.isActive && (
                          <Card className="mb-4">
                            <Card.Header>
                              <i className="fas fa-check-circle text-primary mr-3" />
                              Account reactivation
                            </Card.Header>
                            <Card.Body>
                              <p>
                                Clicking the below reactivates the Consultant account, allowing access to the portal by the Consultant (Primary User) and Sub
                                Users, and sends a reactivation email to the Consultant.
                              </p>
                              <button
                                onClick={() => {
                                  activateConsultant();
                                }}
                                className="btn btn-outline-primary"
                                type="button">
                                Activate Consultant
                              </button>
                            </Card.Body>
                          </Card>
                        )}

                        {isAdmin(true) && !consultant.isActive && (
                          <Card>
                            <Card.Header>
                              <i className="fas fa-trash text-danger mr-3" />
                              Permanent Data Deletion
                            </Card.Header>
                            <Card.Body>
                              <p>
                                Clicking the below deletes the Consultant Primary User and{' '}
                                <strong className="text-danger">DELETES ALL INVOICE DATA AND FILES PERMANENTLY</strong>. It does not delete any related Sub
                                Users, but they can no longer access this account.
                              </p>

                              <button
                                onClick={() => {
                                  deleteConsultant();
                                }}
                                className={`btn mr-3 ${isDeleting ? 'btn-danger' : 'btn-danger'}`}
                                type="button">
                                {isDeleting ? 'This is destructive and irreversible. Click again to confirm..' : 'Delete Consultant and All Related Data'}
                              </button>
                            </Card.Body>
                          </Card>
                        )}
                      </Tab>
                      {isStaff() && (
                        <Tab eventKey="onboardingChecklist" title="Checklist">
                          <ConsultantOnboardingItemsTableContainer consultant={consultant} />
                        </Tab>
                      )}
                      {isStaff() && (
                        <Tab eventKey="consultantRegistrationForm" title={`Reg. Form`}>
                          <ConsultantRegistrationFormContainer consultant={consultant} />
                        </Tab>
                      )}
                      <Tab eventKey="clinics" title="Regular Clinics">
                        <RegularClinicsForm consultant={consultant} clinicSlots={consultant.clinicSlots} />
                      </Tab>
                      <Tab eventKey="users" title="Authorised Users">
                        {consultant.isActive && !isStaff(true) && (
                          <Row>
                            <Col>
                              <div className="crud__section mb-3">
                                <div className="crud__section-icon">
                                  <i className="fas fa-user" />
                                </div>
                                <h2 className="crud__section-header">Add a user</h2>
                                <p className="hint-text">You can invite users to be connected to your account, such as secretaries and accountants</p>
                                <SubAccountInviteForm key={'AddSubAccountForm_New'} form={`AddSubAccountForm_New`} />
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <div className="crud__section mb-3">
                              <div className="crud__section-icon">
                                <i className="fas fa-user" />
                              </div>
                              <h2 className="crud__section-header">Authorised Users</h2>
                              {subAccountInviteError && Object.prototype.hasOwnProperty.call(subAccountInviteError, 'ConsultantSubAccount') && (
                                <div ref={this.myRef} className="alert alert-danger">
                                  {subAccountInviteError.ConsultantSubAccount}
                                </div>
                              )}
                              {(!consultant.consultantSubusers || (consultant.consultantSubusers && consultant.consultantSubusers.length < 1)) && (
                                <div className="alert alert-info mb-0">Please invite a user to join {isStaff() ? 'the consultants' : 'your'} account</div>
                              )}
                              <TransitionGroup>
                                {consultant.consultantSubusers &&
                                  consultant.consultantSubusers.reverse().map((subUser) => (
                                    <CSSTransition key={subUser.id} timeout={500} classNames="fade">
                                      <SubAccountInviteForm
                                        key={`AddSubAccountForm_${subUser ? subUser.id : ''}`}
                                        form={`AddSubAccountForm_${subUser.id}`}
                                        subUser={subUser}
                                      />
                                    </CSSTransition>
                                  ))}
                              </TransitionGroup>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      {isSeniorStaff() && (
                        <Tab eventKey="bible" title={isStaff() ? 'Consultant Bible' : 'Your Bible'}>
                          <ConsultantBibleForm consultant={consultant} />
                          <ConsultantBibleLog path={this.props.path} filterByConsultant={consultant} />
                        </Tab>
                      )}
                      {isStaff(true) && (
                        <Tab eventKey="bible" title="Consultant Bible">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-book-open" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Bible</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <div dangerouslySetInnerHTML={{ __html: consultant.bible }}></div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}

                      {isSeniorStaff() && (
                        <Tab eventKey="notes" title="Notes">
                          <NotesForm consultant={consultant} />
                        </Tab>
                      )}

                      {isStaff(true) && (
                        <Tab eventKey="notes" title="Notes">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-edit" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Consultant Notes</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <div dangerouslySetInnerHTML={{ __html: consultant.notes }} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}

                      {isConsultant(true) && (
                        <Tab eventKey="bible" title={isStaff() ? 'Consultants Bible' : 'Your Bible'}>
                          <div className="crud__section mb-3">
                            <div className="crud__section-icon">
                              <i className="fas fa-book-open" />
                            </div>
                            <h2 className="crud__section-header">Your Bible</h2>
                            <div dangerouslySetInnerHTML={{ __html: consultant.bible }}></div>
                          </div>
                        </Tab>
                      )}

                      {isSeniorStaff() && (
                        <Tab eventKey="tickets" title={'Tickets'}>
                          <ConsultantTicketListContainer
                            defaultFilters={{ consultant: consultant['@id'] }}
                            match={this.props.match}
                            history={this.props.history}
                            pushToHistory={false}
                          />
                        </Tab>
                      )}
                      <Tab eventKey="ccsdCodes" title={`CCSD code costs (${consultant.consultantType.displayName})`}>
                        <ConsultantCcsdCodeCostTableContainer consultant={consultant} />

                        {isSeniorStaff() && <DatedCostsChangeLog filterByConsultant={consultant} />}
                      </Tab>
                      <Tab eventKey="invoiceBatchWeeklyCounts" title="Invoices By Week">
                        <Row>
                          <Col>
                            <div className="invoice-batch-single__section mb-3">
                              <div className="invoice-batch-single__section-icon">
                                <i className="fas fa-list-alt" />
                              </div>
                              <h2 className="invoice-batch-single__section-header">Invoices By Week</h2>
                              <InvoiceBatchWeeklyCounts consultant={consultant} showFilters={false} />
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantSingle;
