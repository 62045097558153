import React from 'react';
import { invoiceBatchSingleCancel } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import { addToast } from '../../lib/redux/actions/toast';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  invoiceBatchSingleCancel,
  addToast,
};

class CancelButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false,
    };
    this.timeoutHandle = false;
  }

  componentWillUnmount() {
    if (this.timeoutHandle !== false) {
      clearTimeout(this.timeoutHandle);
    }
  }
  confirm() {
    this.setState(() => ({ confirmed: true }));

    this.timeoutHandle = setTimeout(() => {
      this.setState(() => ({ confirmed: false }));
    }, this.props.confirmTimeout);
  }

  cancelBatch() {
    const { invoiceBatchSingleCancel, invoiceBatch, addToast } = this.props;
    return invoiceBatchSingleCancel(invoiceBatch.id).then(() => {
      addToast('The invoice batch was cancelled', true);
    });
  }

  render() {
    const { invoiceBatch } = this.props;
    if (!isSeniorStaff() || Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.COMPLETED)) {
      return null;
    }
    return (
      <button
        type="button"
        onClick={
          this.state.confirmed
            ? this.cancelBatch.bind(this)
            : () => {
                this.confirm();
              }
        }
        className={this.state.confirmed ? 'btn btn-danger btn-sm' : 'btn btn-outline-danger btn-sm'}>
        <i className="fa fa-times mr-2" />
        {this.state.confirmed ? 'Are you sure?' : 'Cancel Batch'}
      </button>
    );
  }
}

CancelButton.propTypes = {
  confirmTimeout: PropTypes.number.isRequired,
  invoiceBatch: PropTypes.object.isRequired,
  invoiceBatchSingleCancel: PropTypes.func.isRequired,
};

CancelButton.defaultProps = {
  confirmTimeout: 10000,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton);
