import * as ROUTES from '../../lib/routing/frontend';
import {
  isAdmin,
  isChaserOutsourcer,
  isConsultant,
  isOutsourcer,
  isSeniorStaff,
  isStaff,
  isSubUser,
  isSuperAdmin,
  isUser,
  subUserCanUploadBills,
  subUserCanViewAndDownloadInvoices,
  subUserCanViewAndDownloadReports,
} from '../helper/authorisation';

export const authByPath = (path) => {
  switch (path) {
    case ROUTES.LOGIN:
    case ROUTES.FORGOT_PASSWORD:
    case ROUTES.RESET_PASSWORD:
    case ROUTES.AUTH2FA:
    case ROUTES.NOT_FOUND:
      return true;

    case ROUTES.KITCHEN_SINK:
      return process.env.NODE_ENV !== 'production';

    case ROUTES.USERS.ALL:
    case ROUTES.USERS.SINGLE:
    case ROUTES.REPORTS.CREATE:
    case ROUTES.BILLING_CHECKLIST:
    case ROUTES.BILLING_CHECKLIST_EVENTS.ALL:
    case ROUTES.BILLING_CHECKLIST_EVENTS.SINGLE:
    case ROUTES.BILLING_CHECKLIST_EVENTS.CREATE:
    case ROUTES.CONSULTANTS.CREATE:
    case ROUTES.USERS.CREATE:
    case ROUTES.EXPORT_INVOICE_NUMBERS:
    case ROUTES.HOSPITALS.ALL:
    case ROUTES.CCSD_CODES.ALL:
    case ROUTES.CCSD_CODES.SINGLE:
    case ROUTES.INVOICE_BATCHES.BULK_ASSIGN:
    case ROUTES.INVOICES.CREATE:
    case ROUTES.INVOICES.PARSE:
    case ROUTES.INVOICES.UPDATE:
      //      console.log('AuthByPath: '+path+' isSeniorStaff()',isSeniorStaff());
      return isSeniorStaff();

    case ROUTES.PROBLEM_DATA.SINGLE:
    case ROUTES.PROBLEM_DATA.ALL:
    case ROUTES.PROBLEM_DATA.COMPLETED:
    case ROUTES.PROBLEM_DATA.SINGLE_EDIT:
      //      console.log('AuthByPath: '+path+'  isStaff() || isOutsourcer()', isStaff() , isOutsourcer());
      return isStaff() || isOutsourcer();

    case ROUTES.INVOICE_BATCHES.URGENT:
    case ROUTES.INVOICE_BATCHES.EMBASSY:
    case ROUTES.CONSULTANTS.ALL:
    case ROUTES.INVOICE_BATCHES.UNASSIGNED:
    case ROUTES.INVOICE_BATCHES.PROBLEM:
    case ROUTES.INVOICE_BATCHES.TO_CHECK_AND_PRICE:
    case ROUTES.CMS.REMITTANCES:
    case ROUTES.CMS.HOSPITAL:
    case ROUTES.CMS.EMBASSY:
      //      console.log('AuthByPath: '+path+' isStaff()',isStaff());
      return isStaff();

    case ROUTES.CONSULTANT_TICKETS.ALL:
    case ROUTES.CONSULTANT_TICKETS.SINGLE:
    case ROUTES.CONSULTANT_TICKETS.CREATE:
      return isSeniorStaff() || isConsultant() || isSubUser(true);

    case ROUTES.CONSULTANTS.SINGLE:
      //      console.log('AuthByPath: '+path+'  isSeniorStaff() || isConsultant()', isSeniorStaff() , isConsultant());
      return isStaff() || isConsultant();

    case ROUTES.CONSULTANTS.SINGLE_FOR_SUBUSER:
      //      console.log('AuthByPath: '+path+'  isSeniorStaff() || isConsultant()', isSeniorStaff() , isConsultant());
      return isStaff() || isConsultant() || isSubUser(true);

    case ROUTES.REPORTS.ALL:
      //      console.log('AuthByPath: '+path+' isSeniorStaff() || isConsultant() || (isSubUser(true) && subUserCanViewAndDownloadReports())',isSeniorStaff() , isConsultant(),  isSubUser(true), subUserCanViewAndDownloadReports() );
      return isSeniorStaff() || isConsultant() || (isSubUser(true) && subUserCanViewAndDownloadReports());

    case ROUTES.INVOICE_BATCHES.CREATE:
      //      console.log('AuthByPath: '+path+'  isStaff() || isConsultant() || (isSubUser(true) && subUserCanUploadBills())', isStaff() , isConsultant(), isSubUser(true), subUserCanUploadBills());
      return isStaff() || isConsultant() || (isSubUser(true) && subUserCanUploadBills());

    case ROUTES.INVOICE_BATCHES.TO_ACCEPT:
    case ROUTES.INVOICE_BATCHES.TO_PROCESS:
    case ROUTES.CMS.INSURANCE:
      //      console.log('AuthByPath: '+path+'  isStaff() || isOutsourcer()', isStaff() , isOutsourcer());
      return isStaff() || isOutsourcer();

    case ROUTES.SELECT_CONSULTANT:
    case ROUTES.SUBACCOUNT.ACCEPT:
    case ROUTES.SUBACCOUNT.ACCEPT_SECURE:
      //      console.log('AuthByPath: '+path+' isSubUser(true)',isSubUser(true));
      return isSubUser(true);

    case ROUTES.INVOICE_BATCHES.SINGLE:
    case ROUTES.INVOICE_BATCHES.ALL:
      //      console.log('AuthByPath: '+path+'  (isSubUser(true) && subUserCanViewAndDownloadInvoices()) || isStaff() || isOutsourcer() || isConsultant()', isSubUser(true), subUserCanViewAndDownloadInvoices() , isStaff(), isOutsourcer() , isConsultant());
      return (isSubUser(true) && subUserCanViewAndDownloadInvoices()) || isStaff() || isOutsourcer() || isConsultant();

    case ROUTES.WORK_SCHEDULE:
    case ROUTES.WORK_SCHEDULE_EVENTS.ALL:
    case ROUTES.WORK_SCHEDULE_EVENTS.SINGLE:
    case ROUTES.WORK_SCHEDULE_EVENTS.CREATE:
      return isOutsourcer() || isSeniorStaff();

    case ROUTES.INVOICES:
    case ROUTES.INVOICES.ALL:
    case ROUTES.INVOICES.UNPAID:
    case ROUTES.INVOICES.SINGLE:
    case ROUTES.INVOICES.BULK_ASSIGN:
    case ROUTES.INVOICES.ESCALATED:
    case ROUTES.INVOICES.MISSING_CONTACT_DETAILS:
      return isChaserOutsourcer() || isStaff();

    case ROUTES.CHASER_WORK_SCHEDULE:
      return isChaserOutsourcer() || isSeniorStaff();

    case ROUTES.HOME:
    case ROUTES.PROFILE:
    case ROUTES.HELP:
    case ROUTES.TERMS:
      //      console.log('AuthByPath: '+path+'  isUser()', isUser() );
      return isUser();

    case ROUTES.SITE_SETTINGS:
    case ROUTES.INVOICES.VOIDS:
      return isSuperAdmin() || isAdmin();
    default:
      console.error('PAGE ACCESS IS DENIED BECAUSE ROUTING AUTHORISATION IS NOT EXPLICITLY SET UP FOR ', path);
      return false;
  }
};
