import { type ContactRecord, type ContactRecordType, ContactRecordTypeCodes } from '../../apiTypes';

export const PAYOR_TYPES = {
  PATIENT: 0,
  HOSPITAL: 10,
  INSURER: 20,
  EMBASSY: 30,
  SPECIALIST: 40,
  SOLICITOR: 50,
  OTHER: 60,
};

export const contactRecordTypeCodeToType = (code: string): ContactRecordType | boolean => {
  // console.log('contactRecordTypeCodeToType', code);
  const pair = Object.entries(ContactRecordTypeCodes).find((codeKVP) => {
    if (String(code) !== String(codeKVP[1])) {
      return false;
    }
    return codeKVP;
  });
  // console.log('contactRecordTypeCodeToType', pair);
  return pair ? { code: pair[1], name: pair[0] } : false;
};

export const contactRecordIsPatient = (contact: ContactRecord) => {
  return contact?.contactRecordType?.code === ContactRecordTypeCodes.Patient;
};
