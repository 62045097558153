import { addToast } from '../../../lib/redux/actions/toast';
import {
  invoiceSingleCreateMissingPhoneContactDetailsAction,
  invoiceSingleCreateMissingEmailContactDetailsAction,
  invoiceSingleCreateMissingPhoneEmailContactDetailsAction,
} from '../../../lib/redux/actions/invoices';
import { isChaserOutsourcer, isStaff } from '../../../lib/helper/authorisation';
import InvoiceAuditLog from '../../common/InvoiceAuditLog';
import { niceDateFormatter } from '../../../lib/helper/formatting';
import { getStats } from '../../../lib/redux/actions/stats';
import React from 'react';
import InvoiceActionForm from './InvoiceActionForm';
import InvoiceEscalationForm from './InvoiceEscalationForm';
import InvoiceMissingDetailsForm from './InvoiceMissingDetailsForm';
import InvoiceAssignUserForm from './InvoiceAssignUserForm';
import ChaserOutsourcerInvoiceEscalationForm from './ChaserOutsourcerInvoiceEscalationForm';
import { connect } from 'react-redux';

const mapDispatchToProps = {
  invoiceSingleCreateMissingPhoneContactDetailsAction,
  invoiceSingleCreateMissingEmailContactDetailsAction,
  invoiceSingleCreateMissingPhoneEmailContactDetailsAction,
  addToast,
  getStats,
};

class InvoiceForm extends React.Component {
  markMissingPhoneContactDetailsAndClose() {
    const { invoiceSingleCreateMissingPhoneContactDetailsAction, invoice, addToast, closeModal, getStats } = this.props;

    const unassignsCurrentUser = isChaserOutsourcer(true) || isStaff(true);
    return invoiceSingleCreateMissingPhoneContactDetailsAction(invoice.id, unassignsCurrentUser).then(() => {
      addToast('The Invoice has been marked as having missing phone number contact details', true);
      getStats();
      closeModal && closeModal();
    });
  }

  markMissingEmailContactDetailsAndClose() {
    const { invoiceSingleCreateMissingEmailContactDetailsAction, invoice, addToast, closeModal, getStats } = this.props;

    const unassignsCurrentUser = isChaserOutsourcer(true) || isStaff(true);
    return invoiceSingleCreateMissingEmailContactDetailsAction(invoice.id, unassignsCurrentUser).then(() => {
      addToast('The Invoice has been marked as having missing email address contact details', true);
      getStats();
      closeModal && closeModal();
    });
  }

  markMissingPhoneEmailContactDetailsAndClose() {
    const { invoiceSingleCreateMissingPhoneContactDetailsAction, invoice, addToast, closeModal, getStats } = this.props;

    const unassignsCurrentUser = isChaserOutsourcer(true) || isStaff(true);
    return invoiceSingleCreateMissingPhoneContactDetailsAction(invoice.id, unassignsCurrentUser).then(() => {
      addToast('The Invoice has been marked as having missing phone number and email address contact details', true);
      getStats();
      closeModal && closeModal();
    });
  }

  render() {
    const {
      closeModal,
      error,
      invoice,
      isUpdatingInvoice,
      title = invoice.patientFirstName +
        ' ' +
        invoice.patientLastName +
        ' (DOB: ' +
        (invoice.dateOfBirth ? niceDateFormatter(invoice.dateOfBirth, 'DD/MM/YYYY') : '') +
        ')',
      subTitle = 'Inv # ' +
        invoice.invoiceNo +
        ' - (' +
        invoice.consultant.name +
        ') - Inv Date: ' +
        (invoice.invoiceDate ? niceDateFormatter(invoice.invoiceDate, 'DD/MM/YYYY') : ''),
    } = this.props;
    if (!invoice) {
      return null;
    }

    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-file-invoice" />
        </div>
        <h2 className="crud__section-header">{title}</h2>
        <h3 className={'crud__section-subheader'}>{subTitle}</h3>
        {error && <div className="alert alert-danger">{error}</div>}

        <div className="row">
          <div className={'col-sm-3'}>
            <InvoiceActionForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice} closeModal={closeModal} />
          </div>

          {isStaff() && (
            <>
              <div className="col-sm-3 invoice-modal-seniorstaff-form-column">
                <h6 className={'mb-3'}>Escalation</h6>
                <InvoiceEscalationForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice} />
              </div>
              <div className="col-sm-3 offset-sm-1 invoice-modal-seniorstaff-form-column">
                <h6 className={'mb-3'}>Missing Details</h6>
                <InvoiceMissingDetailsForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice} />
              </div>

              <div className="col-sm-2">
                <h6 className={'mb-3'}>Assigned User</h6>

                <InvoiceAssignUserForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice} />
              </div>
            </>
          )}
          {!isStaff() && (
            <>
              <div className="col-sm-3">
                <ChaserOutsourcerInvoiceEscalationForm invoice={invoice} isUpdatingInvoice={isUpdatingInvoice} closeModal={closeModal} />
              </div>
              <div className="col-sm-4 offset-sm-1">
                <h6>Missing Contact Details</h6>
                <p>Inform us that some contact details are missing and remove this item from your list.</p>
                <button className="btn btn-secondary mr-4 mb-2 mt-3" onClick={() => this.markMissingPhoneContactDetailsAndClose()}>
                  <i className="mr-2 fas fa-phone text-white" />
                  Missing Phone Number
                </button>
                <button className="btn btn-secondary mr-4 mb-2 mt-3" onClick={() => this.markMissingEmailContactDetailsAndClose()}>
                  <i className="mr-2 fas fa-envelope text-white" />
                  Missing Email Address
                </button>
                <button className="btn btn-secondary mr-4 mb-2 mt-3" onClick={() => this.markMissingPhoneEmailContactDetailsAndClose()}>
                  <i className="fas fa-phone text-white" /> + <i className="mr-2 fas fa-envelope text-white" />
                  Missing Phone and Email
                </button>
              </div>
            </>
          )}
        </div>
        <div className="crud__section mb-3">
          <div className="crud__section-icon">
            <i className="fas fa-book" />
          </div>
          <h2 className="crud__section-header">Audit Log</h2>
          <InvoiceAuditLog invoice={invoice} />
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(InvoiceForm);
