import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderCheckbox, renderSelect } from '../../../lib/helper/form';
import MASpinner from '../../common/MASpinner';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { invoiceSingleUpdate } from '../../../lib/redux/actions/invoices';
import { invoiceMissingDetailsTypeListFetch } from '../../../lib/redux/actions/invoiceMissingDetailsType';

const mapStateToProps = (state, props) => {
  const initialValues = {
    isMissingContactDetails: props?.invoice?.isMissingContactDetails ?? false,
    consultantContactedForMissingDetails: props?.invoice?.consultantContactedForMissingDetails ?? false,
    missingContactDetailsType: props.invoice?.missingContactDetailsType?.['@id'],
  };
  return {
    initialValues,
    ...state.invoiceMissingDetailsTypeList,
    currentFormValues: getFormValues('InvoiceMissingDetailsForm')(state),
  };
};

const mapDispatchToProps = {
  invoiceSingleUpdate,
  invoiceMissingDetailsTypeListFetch,
  addToast,
};

class InvoiceMissingDetailsForm extends React.Component {
  componentDidMount() {
    const { invoiceMissingDetailsTypeListFetch } = this.props;

    invoiceMissingDetailsTypeListFetch();
  }

  onSubmit(values) {
    const { invoiceSingleUpdate, invoice, addToast, reset } = this.props;
    if (!values['missingContactDetailsType']) {
      values['missingContactDetailsType'] = null;
    }
    if (values['isMissingContactDetails'] === false) {
      values['consultantContactedForMissingDetails'] = false;
      values['missingContactDetailsType'] = null;
    }

    return invoiceSingleUpdate(values, invoice.id).then(() => {
      addToast('The Invoice was successfully updated', true);
      reset();
    });
  }

  render() {
    const { handleSubmit, currentFormValues, isUpdatingInvoice, invoiceMissingDetailsTypes, submitting, pristine, showDetailsType = true } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <div>
          <Field name="isMissingContactDetails" label="Missing Contact Details?" id="is-missing-contact-details" component={renderCheckbox} />
        </div>
        {currentFormValues?.isMissingContactDetails ? (
          <>
            {showDetailsType && (
              <div className="my-3">
                <Field
                  name="missingContactDetailsType"
                  label="Which contact details are missing?"
                  id="missing-contact-details-type"
                  type="select"
                  options={invoiceMissingDetailsTypes.map((missingDetailsType) => ({ value: missingDetailsType['@id'], text: missingDetailsType.name }))}
                  showEmpty={true}
                  required={true}
                  emptyLabel="Please choose"
                  component={renderSelect}
                />
              </div>
            )}
            <div className="mb-3">
              <Field
                name="consultantContactedForMissingDetails"
                label="Consultant Contacted"
                id="consultantContactedForMissingDetails"
                component={renderCheckbox}
              />
            </div>
          </>
        ) : null}
        {isUpdatingInvoice ? <MASpinner /> : null}
        {!isUpdatingInvoice && !pristine ? (
          <button type="submit" disabled={submitting} className="btn btn-primary">
            Update
          </button>
        ) : null}
      </Form>
    );
  }
}

InvoiceMissingDetailsForm.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'InvoiceMissingDetailsForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(InvoiceMissingDetailsForm),
);
