import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { invoiceListClearFilters, invoiceListSetFilters } from '../../../lib/redux/actions/invoices';
import { invoicePayorTypeListFetch } from '../../../lib/redux/actions/invoicePayorType';
import Form from 'react-bootstrap/Form';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';
import AssignToUserTypeahead from '../../fields/AssignToUserTypeahead';
import { renderField, renderSelect } from '../../../lib/helper/form';
import MASpinner from '../../common/MASpinner';
import { isSeniorStaff } from '../../../lib/helper/authorisation';
import { userNameFormatter } from '../../../lib/helper/formatting';
import { invoiceEscalationReasonFetch } from '../../../lib/redux/actions/invoiceEscalationReason';
import { invoiceEscalationStatusFetch } from '../../../lib/redux/actions/invoiceEscalationStatus';
import { consultantListFetch } from '../../../lib/redux/actions/consultant';
import { getFiltersFromSession } from '../../../lib/routing/filters';

const validate = (values) => {
  const errors = {};
  if (values['outstandingBalance(gte)'] && values['outstandingBalance(lte)'] && values['outstandingBalance(lte)'] < values['outstandingBalance(gte)']) {
    errors['outstandingBalance(lte)'] = 'Less than must be smaller than greater than';
    errors['outstandingBalance(gte)'] = 'Less than must be smaller than greater than';
  }
  return errors;
};
const mapStateToProps = (state, ownProps) => {
  const { pageType, defaultFilters, location } = ownProps;

  let isEscalated = pageType === 'escalation' || (defaultFilters.hasOwnProperty('isEscalated') && defaultFilters.isEscalated) ? 1 : null;
  if (isEscalated === null && defaultFilters.hasOwnProperty('isEscalated') && !defaultFilters.isEscalated) {
    isEscalated = 0;
  }
  let isMissingContactDetails =
    pageType === 'missing' || (defaultFilters.hasOwnProperty('isMissingContactDetails') && defaultFilters.isMissingContactDetails) ? 1 : null;
  if (isMissingContactDetails === null && defaultFilters.hasOwnProperty('isMissingContactDetails') && !defaultFilters.isMissingContactDetails) {
    isMissingContactDetails = 0;
  }

  let initialValuesFromSession = getFiltersFromSession(location, 'json');
  let initialValues = {
    ...initialValuesFromSession,
    ...state?.invoices?.filters,
    invoiceNo: state?.invoices?.filters?.invoiceNo,
    isPaid: pageType === 'missing' || pageType === 'escalation' ? null : 0,
    'outstandingBalance(gt)': pageType === 'missing' || pageType === 'escalation' ? 0 : undefined,
    isEscalated: isEscalated,
    isMissingContactDetails: isMissingContactDetails,
    isVoid: defaultFilters.isVoid ? 'Yes' : 'No'
  };
  delete initialValues['outstandingBalance(gte)']
  delete initialValues['outstandingBalance(lte)']
  if (initialValuesFromSession && null !== initialValuesFromSession['outstandingBalance(gte)']) {
    initialValues['outstandingBalance(gte)'] = initialValuesFromSession['outstandingBalance(gte)'] / 100;
  } else {
    initialValues['outstandingBalance(gte)'] = state?.invoices?.filters['outstandingBalance(gte)'] ? state.invoices.filters['outstandingBalance(gte)'] / 100 : undefined;
  }
  if (initialValuesFromSession && null !== initialValuesFromSession['outstandingBalance(lte)']) {
    initialValues['outstandingBalance(lte)'] = initialValuesFromSession['outstandingBalance(lte)'] / 100;
  } else {
    initialValues['outstandingBalance(lte)'] = state?.invoices?.filters['outstandingBalance(gte)'] ? state.invoices.filters['outstandingBalance(lte)'] / 100 : undefined;
  }

  return {
    initialValues,
    ...state.invoicePayorTypeList,
    ...state.invoiceEscalationReason,
    ...state.invoiceEscalationStatus,
    ...state.consultantList,
    isPaidToggledOn: 0,
    selectedConsultants: initialValues && null !== initialValues['consultant.id'] ? initialValues['consultant.id'] : [],
  };
};
const mapDispatchToProps = {
  invoiceListSetFilters,
  invoiceListClearFilters,
  invoicePayorTypeListFetch,
  invoiceEscalationReasonFetch,
  invoiceEscalationStatusFetch,
  consultantListFetch,
};

class InvoiceTableFilterForm extends React.Component {
  constructor(props) {
    super(props);

    const { invoicePayorTypeListFetch, invoiceEscalationReasonFetch, invoiceEscalationStatusFetch, consultants = [] } = this.props;

    invoicePayorTypeListFetch();
    invoiceEscalationReasonFetch();
    invoiceEscalationStatusFetch();

    this.state = {
      selectedConsultants: consultants,
      selectedAssignedToUsers: [],
      clearTypeaheads: false,
    };
  }

  componentDidMount() {
    const { selectedConsultants = [] } = this.props;
    if (selectedConsultants.length > 0) {
      this.props.consultantListFetch(null, {
        pagination: false,
        id: selectedConsultants,
      });
    }
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true, isPaidToggledOn: 0, isWrittenOffToggledOn: 0 };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    const { setPage, location } = this.props;

    const submitValues = { ...values };
    if (this.state.selectedConsultants.length) {
      submitValues['consultant.id'] = this.state.selectedConsultants.map((c) => c['@id']);
    }
    if (this.state.selectedAssignedToUsers.length) {
      submitValues['assignedTo'] = this.state.selectedAssignedToUsers.map((u) => u['@id']);
    }

    if (values['isPaid'] === '1') {
      submitValues['outstandingBalance(gt)'] = '-1';
      delete submitValues['outstandingBalance(gte)'];
      delete submitValues['outstandingBalance(lte)'];
      delete values['outstandingBalance(gt)'];
      delete values['outstandingBalance(gte)'];
      delete values['outstandingBalance(lte)'];
    } else if (String(values['outstandingBalance(gte)']) !== '') {
      const val = Number(values['outstandingBalance(gte)']) * 100;
      if (!isNaN(val)) {
        submitValues['outstandingBalance(gte)'] = val; // Number(values['outstandingBalance(gte)']) * 100;
      } else {
        delete values['outstandingBalance(gte)'];
      }
    }
    if (String(values['outstandingBalance(lte)']) !== '') {
      const val = Number(values['outstandingBalance(lte)']) * 100;
      if (!isNaN(val)) {
        submitValues['outstandingBalance(lte)'] = val; //Number(values['outstandingBalance(lte)']) * 100;
      } else {
        delete values['outstandingBalance(lte)'];
      }
    }

    if (values['id']) {
      submitValues['id'] = values['id'].replace(/\D/g, '');
    }

    setPage(1);
    return this.props.invoiceListSetFilters(submitValues, location);
  }

  resetFilters() {
    const { reset, invoiceListClearFilters, setPage, location } = this.props;
    invoiceListClearFilters(location);
    this.clearTypeaheads();
    this.setState(() => ({
      selectedConsultants: [],
      selectedAssignedToUsers: [],
    }));
    setPage(1);
    reset();
  }

  getFiltersToShow() {
    const { filterOverrides = {} } = this.props;

    return {
      ...{
        consultant: true,
        patientName: true,
        invoiceNo: true,
        id: true,
        assignToUser: true,
        isPaid: true,
        isWrittenOff: true,
        isEscalated: true,
        isMissingContactDetails: true,
        escalationFilters: false,
        consultantContactedForMissingDetails: true,
        osBal: true,
      },
      ...filterOverrides,
    };
  }

  render() {
    const { handleSubmit, error, invoicePayorTypes = [], invoiceEscalationReasons = [], invoiceEscalationStatuses = [], pageType } = this.props;

    if (!invoicePayorTypes) {
      return <MASpinner />;
    }

    const boolOptions = [
      { value: 1, text: 'Yes' },
      { value: 0, text: 'No' },
    ];
    const paidOptions = [
      { value: 1, text: 'Paid' },
      { value: 0, text: 'Unpaid' },
    ];

    const payorTypeOptions = [];
    invoicePayorTypes.forEach((payorType) => {
      payorTypeOptions.push({ value: payorType['@id'], text: payorType.name });
    });

    const invoiceEscalationReasonsOptions = [];
    invoiceEscalationReasons.forEach((reason) => {
      invoiceEscalationReasonsOptions.push({
        text: reason.reason,
        value: reason['@id'],
      });
    });

    const invoiceEscalationStatusOptions = [];
    invoiceEscalationStatuses.forEach((status) => {
      invoiceEscalationStatusOptions.push({
        text: status.status,
        value: status['@id'],
      });
    });

    const filtersToShow = this.getFiltersToShow();

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="fake-inline form-data-filters row">
          <div className="col-sm-6 col-xl-2">
            {filtersToShow.consultant && isSeniorStaff() && (
              <ConsultantTypeahead
                label="Consultant"
                shouldClear={this.state.clearTypeaheads}
                hasCleared={this.typeaheadCleared.bind(this)}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedConsultants: selected };
                  });
                }}
                selectedConsultants={this.state.selectedConsultants}
              />
            )}

            {filtersToShow.patientName && <Field name="patientName" label="Patient Name" id="patientName" type="text" component={renderField} />}
            {filtersToShow.assignToUser && isSeniorStaff() && (
              <AssignToUserTypeahead
                rolesToShow='["ROLE_ADMIN","ROLE_SENIOR_STAFF","ROLE_STAFF","ROLE_CHASER_OUTSOURCER"]'
                shouldClear={this.state.clearTypeaheads}
                labelKey={(option) => userNameFormatter(option, false)}
                hasCleared={this.typeaheadCleared.bind(this)}
                onChange={(selected) => {
                  this.setState(() => {
                    return { selectedAssignedToUsers: selected };
                  });
                }}
                label="Assigned To"
              />
            )}
          </div>
          <div className="col-sm-6 col-xl-2">
            {filtersToShow.invoiceNo && <Field name="invoiceNo" label="Invoice #" id="invoiceNo" type="text" component={renderField} />}
            {filtersToShow.id && <Field name="id" label="item Id" id="itemID" type="text" component={renderField} />}
          </div>

          {/*<div className="row">*/}
          <div className="col-sm-6 col-xl-2">
            {filtersToShow.isEscalated && <Field name="isEscalated" label="Escalated?" type="select" options={boolOptions} component={renderSelect} />}
            {filtersToShow.escalationFilters ? (
              <>
                <Field
                  name="invoiceEscalationReason"
                  label="Escalation Reason"
                  type="select"
                  options={invoiceEscalationReasonsOptions}
                  component={renderSelect}
                />
                <Field
                  name="invoiceEscalationStatus"
                  label="Escalation Status"
                  type="select"
                  options={invoiceEscalationStatusOptions}
                  component={renderSelect}
                />
                <Field name="revisitDate" label="Revisit Date" type="date" component={renderField} />
              </>
            ) : null}
          </div>

          <div className="col-sm-6 col-xl-2">
            {filtersToShow.isMissingContactDetails && (
              <Field name="isMissingContactDetails" label="Missing Contact Details?" type="select" options={boolOptions} component={renderSelect} />
            )}

            {filtersToShow.consultantContactedForMissingDetails && pageType === 'missing' ? (
              <>
                <Field name="consultantContactedForMissingDetails" label="Consultant Contacted" options={boolOptions} component={renderSelect} />
              </>
            ) : null}
          </div>

          <div className="col-sm-6 col-xl-2">
            {filtersToShow.isPaid && (
              <Field
                name="isPaid"
                label="Paid?"
                type="select"
                options={paidOptions}
                component={renderSelect}
                onChange={(e) => this.setState({ isPaidToggledOn: e.currentTarget.value })}
              />
            )}
            {filtersToShow.isVoid && (
              <Field
                name="isVoid"
                label="Void?"
                type="select"
                options={boolOptions}
                component={renderSelect}
                onChange={(e) => this.setState({ isVoidToggleOn: e.currentTarget.value })}
              />
            )}
            {filtersToShow.osBal && this.state.isPaidToggledOn !== '1' && (
              <>
                <Field name="outstandingBalance(gte)" label="O/S Bal Greater than" type={'number'} component={renderField} />
                <Field name="outstandingBalance(lte)" label="O/S Bal Less than" type={'number'} component={renderField} formLabelClasses={'pr-2'} />
              </>
            )}
            {filtersToShow.isWrittenOff && (
              <Field
                name="isWrittenOff"
                label="Written Off?"
                type="select"
                options={boolOptions}
                component={renderSelect}
                onChange={(e) => this.setState({ isWrittenOffToggledOn: e.currentTarget.value })}
              />
            )}
          </div>
          {/*{this.state.isPaidToggledOn !== '1' &&*/}
          {/*    <Field name="outstandingBalance(gt)" label="Show Small Values?" showEmpty={false} type="select" options={smallValueOptions} component={renderSelect}/>*/}
          {/*}*/}

          <div className="col-sm-6 col-xl-2">
            <div className="filter-actions">
              <button type="submit" className="btn btn-primary btn-sm">
                Filter
              </button>
              <button type="button" className="btn btn-outline-danger btn-sm ml-2" onClick={this.resetFilters.bind(this)}>
                Reset
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'InvoiceTableFilterForm',
    enableReinitialize: true,
    validate,
  })(InvoiceTableFilterForm),
);
