import React from 'react';
import { connect } from 'react-redux';
import { userSingleFetch, userSingleCreate, userRequestResetLink } from '../../lib/redux/actions/user';
import UserSingleCreate from './UserSingleCreate';
import { addToast } from '../../lib/redux/actions/toast';
import * as ROUTES from '../../lib/routing/frontend';

const mapDispatchToProps = {
  userSingleFetch,
  userSingleCreate,
  userRequestResetLink,
  addToast,
};

class UserSingleCreateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordLink: null,
    };
  }

  onCreate(data) {
    const { userSingleCreate, userRequestResetLink, addToast, history } = this.props;
    return userSingleCreate(data).then((user) => {
      addToast('The user was created successfully', true);
      userRequestResetLink(user.email, true);
      history.push(ROUTES.USERS.SINGLE.replace(':id', user.id));
    });
  }

  render() {
    return <UserSingleCreate onCreate={this.onCreate.bind(this)} />;
  }
}

export default connect(null, mapDispatchToProps)(UserSingleCreateContainer);
