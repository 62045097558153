import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import UserDetailsForm from '../../components/forms/User/UserDetailsForm';
import Helmet from 'react-helmet';
import InvoiceActionStats from '../../components/invoice/InvoiceActionStats';
import { isChaserOutsourcer } from '../../lib/helper/authorisation';

class UserSingle extends React.Component {
  render() {
    const { user, passwordLink = false } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            {user.firstName} {user.lastName} - A User
          </title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="crud__meta">
                    <Link to={ROUTES.USERS.ALL.replace('/:page', '')}>&larr; Back to User List</Link>
                  </span>
                  <span className="ml-auto crud__statuses">
                    {user.isEnabled ? (
                      <Badge pill variant={'primary'} className="ml-2 text-uppercase">
                        Active
                      </Badge>
                    ) : (
                      <Badge pill variant={'danger'} className="ml-2 text-uppercase">
                        <i className="fas fa-exclamation-circle" /> Inactive
                      </Badge>
                    )}
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="crud__body-title">
                      Edit a User ({user.firstName} {user.lastName})
                    </h1>
                  </Col>
                </Row>
                <hr />
                <Row>
                  {/* Left Column - START */}
                  <Col sm={12} md={12} lg={8}>
                    {/* CRUD Main Form - Start */}
                    <Row>
                      <Col>
                        <UserDetailsForm user={user} passwordLink={passwordLink} />
                      </Col>
                    </Row>

                    {/* CRUD Main Form - END */}
                  </Col>
                  {/*  Left Col - END */}
                  <Col sm={12} md={12} lg={4}>
                    {/* View List of links to edit Related Consultant Accounts, shown if User Role of the User record is Staff, Outsourcer or Sub User only - Start */}
                    {user.consultantsAsSubuser.length > 0 && (
                      <div className="crud__section mb-3">
                        <div className="crud__section-icon">
                          <i className="fas fa-link" />
                        </div>
                        <h2 className="crud__section-header">Related Accounts</h2>
                        {/*{JSON.stringify(user)}*/}
                        <div className="list-group">
                          {user.consultantsAsSubuser.map((consultantAsSubuser) => (
                            <Link
                              key={consultantAsSubuser.consultant.code}
                              to={ROUTES.CONSULTANTS.SINGLE.replace(':id', consultantAsSubuser.consultant.id)}
                              className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                              {consultantAsSubuser.consultant.name}
                              <i className="fas fa-edit" />
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                    {isChaserOutsourcer() && (
                      <div className="crud__section mb-3">
                        <div className="crud__section-icon">
                          <i className="fas fa-info" />
                        </div>
                        <h2 className="crud__section-header">Weekly Stats</h2>
                        <InvoiceActionStats user={user} />
                      </div>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UserSingle;
