import { CONSULTANTS_TYPEAHEAD_LIST_REQUEST, CONSULTANTS_TYPEAHEAD_LIST_RECEIVED, CONSULTANTS_TYPEAHEAD_LIST_ERROR } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantsTypeaheadListRequest = () => ({
  type: CONSULTANTS_TYPEAHEAD_LIST_REQUEST,
});

export const consultantsTypeaheadListReceived = (data) => ({
  type: CONSULTANTS_TYPEAHEAD_LIST_RECEIVED,
  data,
});

export const consultantsTypeaheadListError = (error) => ({
  type: CONSULTANTS_TYPEAHEAD_LIST_ERROR,
  error,
});

export const consultantsTypeaheadListFetch = (filters = {}) => {
  return (dispatch) => {
    dispatch(consultantsTypeaheadListRequest());
    //console.log('Fetching consultants ...');
    filters.pagination = false;
    return requests
      .get(`${API_ROUTES.CONSULTANTS}`, filters)
      .then((response) => dispatch(consultantsTypeaheadListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantsTypeaheadListError(error));
      });
  };
};
