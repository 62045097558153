import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

class SelectConsultant extends React.Component {
  render() {
    const { consultants, onSelect } = this.props;
    return (
      <Row>
        <Col>
          <Card className="account-chooser-card">
            <Card.Header>Choose an account</Card.Header>
            <ListGroup variant="flush">
              {consultants.map((consultantSubuserMap) => (
                <ListGroup.Item
                  action
                  key={consultantSubuserMap.consultant.code}
                  onClick={() => {
                    onSelect(consultantSubuserMap.consultant);
                  }}>
                  <strong>{consultantSubuserMap.consultant.name}</strong>
                  <br />
                  <span className="small">{consultantSubuserMap.consultant.code}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default SelectConsultant;
