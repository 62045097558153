import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import JoditEditor from 'jodit-react';
import { cmsContentCreate, cmsContentUpdate } from '../../../lib/redux/actions/cmsContent';
import { JODIT_EDITOR_CONFIG } from '../../../lib/helper/general';

const CmsContentForm = ({ cmsContent, cmsContentUpdate, cmsContentCreate, addToast, cmsName }) => {
  const [text, setText] = useState(cmsContent?.content || '');
  const [submitting, setSubmitting] = useState(false);
  const editor = useRef(null);
  const config = JODIT_EDITOR_CONFIG;

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    if (null === cmsContent) {
      return cmsContentCreate({ name: cmsName, content: text }).then(() => {
        addToast('CMS Content successfully created', true);
        setSubmitting(false);
      });
    } else {
      return cmsContentUpdate(cmsContent.name, { content: text }).then(() => {
        addToast('CMS Content successfully updated', true);
        setSubmitting(false);
      });
    }
  };

  return (
    <Form onSubmit={(event) => onSubmit(event)}>
      <JoditEditor ref={editor} value={text} config={config} tabIndex={1} onBlur={(newContent) => setText(newContent)} />
      <button type="submit" disabled={submitting} className="btn btn-primary btn-block mt-1">
        Update
      </button>
    </Form>
  );
};

const mapDispatchToProps = {
  cmsContentCreate,
  cmsContentUpdate,
  addToast,
};

export default connect(null, mapDispatchToProps)(CmsContentForm);
