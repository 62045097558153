import Form from 'react-bootstrap/Form';
import React from 'react';
import { consultantSubAccountCreate } from '../../../lib/redux/actions/consultant';
import { addToast } from '../../../lib/redux/actions/toast';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderField } from '../../../lib/helper/form';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import * as ROUTES from '../../../lib/routing/frontend';
import { userLoginAttempt } from '../../../lib/redux/actions/user';

const mapStateToProps = (state, props) => ({
  ...state.consultantSingle,
  ...state.auth,
  initialValues: props.consultant,
});

const mapDispatchToProps = {
  consultantSubAccountCreate,
  addToast,
  userLoginAttempt,
};

class SubAccountAcceptInviteForm extends React.Component {
  constructor(props) {
    super(props);
    const { subAccount, initialize, id, code } = this.props;
    this.state = {
      error: null,
    };
    initialize({
      id: id,
      firstName: subAccount.user.firstName,
      lastName: subAccount.user.lastName,
      email: subAccount.user.email,
      code: code,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.props.history.push(ROUTES.AUTH2FA);
    }
  }

  onSubmit(values) {
    const { consultantSubAccountCreate, addToast } = this.props;
    return consultantSubAccountCreate(values).then(
      () => {
        addToast('User Successfully invited to join this account', true);
        if (values.password) {
          return this.props.userLoginAttempt(values.email, values.password);
        } else {
          this.props.history.push(ROUTES.HOME);
        }
      },
      (error) => {
        this.setState({
          error: error ? error.errors[Object.keys(error.errors)[0]] : null,
        });
      },
    );
  }

  renderForm() {
    const { subAccount } = this.props;
    if (!subAccount.user.isEnabled) {
      return (
        <div>
          <Field name="email" label="Email" type="email" value={subAccount.user.email} component={renderField} disabled={true} />
          <Field name="firstName" label="First Name" type="text" value={subAccount.user.firstName} component={renderField} />
          <Field name="lastName" label="Last Name" value={subAccount.user.lastName} type="text" component={renderField} />
          <Field name="password" label="Password" type="password" component={renderField} />
          <Field name="passwordConfirm" label="Retype Password" type="password" component={renderField} />
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, subAccount } = this.props;
    const { error } = this.state;
    return (
      <Card className="account-chooser-card">
        <Card.Header>Join an account</Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item active>
            <strong>{subAccount.consultant.name}</strong>
            <br />
            <span className="small">{subAccount.consultant.code}</span>
          </ListGroup.Item>
        </ListGroup>
        <div className="p-4">
          {error && <div className="alert alert-danger">{error}</div>}
          {subAccount && !subAccount.user.isEnabled && (
            <h2 className="h5 mb-4">
              Welcome to MediAccounts. As you haven&apos;t got an account registered at this email address, please complete your details below.
            </h2>
          )}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Form.Control type="text" name="code" readOnly hidden />
            <Form.Control type="text" name="id" readOnly hidden />
            {this.renderForm()}

            <button className="btn btn-primary btn-block">Accept invitation</button>
          </Form>
        </div>
      </Card>
    );
  }
}

export default reduxForm({
  form: 'SubAccountAcceptInviteForm',
})(connect(mapStateToProps, mapDispatchToProps)(SubAccountAcceptInviteForm));
