import React from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { consultantSingleUpdate } from '../../../lib/redux/actions/consultant';
import { addToast } from '../../../lib/redux/actions/toast';
import JoditEditor from 'jodit-react';
import { JODIT_EDITOR_CONFIG } from '../../../lib/helper/general';

const mapDispatchToProps = {
  consultantSingleUpdate,
  addToast,
};

class ConsultantBibleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      submitting: false,
    };
    this.editorRef = React.createRef();

    this.setText = this.setText.bind(this);
  }
  componentDidMount() {
    if (this.props.consultant?.bible !== false) {
      this.setText(this.props.consultant?.bible);
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const { consultant, consultantSingleUpdate, addToast } = this.props;
    const { text } = this.state;

    this.setState({
      submitting: true,
    });

    return consultantSingleUpdate({ bible: text }, consultant.id).then(() => {
      addToast('Consultant Bible Successfully Updated', true);
      this.setState({
        submitting: false,
      });
    });
  }

  setText(value) {
    this.setState({
      text: value,
    });
  }

  render() {
    const { text, submitting } = this.state;
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-book-open" />
        </div>
        <h2 className="crud__section-header">Consultant Bible</h2>
        <p className="hint-text"></p>
        <Form onSubmit={this.onSubmit.bind(this)}>
          <JoditEditor
            ref={this.editorRef}
            value={this.state.text}
            config={JODIT_EDITOR_CONFIG}
            tabIndex={1}
            onBlur={(newContent) => this.setText(newContent)}
          />
          <button type="submit" disabled={!text || submitting} className="btn btn-primary btn-block mt-1">
            Update
          </button>
        </Form>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ConsultantBibleForm);
