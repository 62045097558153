import Form from 'react-bootstrap/Form';
import FileUploader from '../../file/FileUploader';
import FileBrowser from '../../file/FileBrowser';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { renderCheckbox, renderDatePicker, renderField, renderReactSelect } from '../../../lib/helper/form';
import React from 'react';
import { fileClearReferences, fileDelete, fileUpload } from '../../../lib/redux/actions/invoiceBatchFiles';
import { invoiceBatchAdd, invoiceBatchSingleUnload } from '../../../lib/redux/actions/invoiceBatch';
import { consultantSingleFetchByIRI, consultantSingleUnload } from '../../../lib/redux/actions/consultant';
import { connect } from 'react-redux';
import ConsultantTypeahead from '../../fields/ConsultantTypeahead';
import moment from 'moment';
import DateWithMorningOrAfternoonSelector from '../../fields/DateWithMorningOrAfternoonSelector';
import { timeOfDayMap } from '../../../lib/helper/formatting';
import { addToast } from '../../../lib/redux/actions/toast';
import {
  getSubusersConsultantToManage,
  isClient,
  isConsultant,
  isStaff,
  isSubUser,
  subUserCanViewAndDownloadInvoices,
} from '../../../lib/helper/authorisation';
import { invoiceBatchNoteAdd } from '../../../lib/redux/actions/invoiceBatchNote';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../lib/routing/frontend';
import 'iterators-polyfill';
import { Alert } from 'react-bootstrap';
import PotentialDuplicates from '../../InvoiceBatch/PotentialDuplicates';

const formFieldValueSelector = formValueSelector('CreateForm');

function mapStateToProps(state) {
  const isEmbassy = formFieldValueSelector(state, 'isEmbassy');
  const confirmEmbassy = formFieldValueSelector(state, 'confirmEmbassy');
  return {
    userData: state.auth.userData,
    currentFormValues: getFormValues('CreateForm')(state),
    selectedConsultant: state.consultantSingle.consultant,
    ...state.invoiceBatchFiles,
    dirtyIsEmbassy: isEmbassy ?? false,
    dirtyConfirmEmbassy: confirmEmbassy ?? false,
  };
}

const mapDispatchToProps = {
  fileDelete,
  fileClearReferences,
  invoiceBatchAdd,
  fileUpload,
  addToast,
  invoiceBatchNoteAdd,
  invoiceBatchSingleUnload,
  consultantSingleFetchByIRI,
  consultantSingleUnload,
};

class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCleanState();
    if (!isStaff()) {
      this.props.consultantSingleFetchByIRI(this.getRelevantConsultantIRI());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedConsultant !== this.props.selectedConsultant) {
      this.setState(() => {
        return {
          selectedConsultant: this.props.selectedConsultant,
          selectedHospitals: [],
          availableHospitals:
            this.props.selectedConsultant && this.props.selectedConsultant.hospitals
              ? this.props.selectedConsultant.hospitals.map((h) => {
                  return { value: h['@id'], label: h.name };
                })
              : [],
        };
      });
    }
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  getCleanState() {
    return {
      clearTypeaheads: false,
      selectedConsultant: this.props.selectedConsultant ?? null,
      selectedDates: [],
      selectedHospitals: [],
      dateAmPmMap: {},
      nextDateKey: 0,
      availableHospitals: [],
    };
  }

  componentWillUnmount() {
    this.props.invoiceBatchSingleUnload();
    this.props.consultantSingleUnload();
  }

  clearSelectedDates() {
    this.setState(() => {
      return { selectedDates: [], dateAmPmMap: {} };
    });
  }

  selectDate(date) {
    this.setState((prevState) => {
      const dateAmPmMap = { ...prevState.dateAmPmMap };
      dateAmPmMap[prevState.nextDateKey] = timeOfDayMap.AM;
      return {
        selectedDates: [...prevState.selectedDates, { key: prevState.nextDateKey, date: moment(date).valueOf() }].sort((a, b) => a.date - b.date),
        nextDateKey: prevState.nextDateKey + 1,
        dateAmPmMap,
      };
    });
  }

  selectDateAmPm(dateKey, amOrPm) {
    this.setState((prevState) => {
      const dateAmPmMap = { ...prevState.dateAmPmMap };
      dateAmPmMap[dateKey] = amOrPm;
      return {
        dateAmPmMap,
      };
    });
  }

  removeSelectedDateKeyPair(dateKey) {
    this.setState((prevState) => {
      return {
        selectedDates: [...prevState.selectedDates.filter((e) => e.key !== dateKey)],
      };
    });
  }

  onFilesAdded(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      this.props.fileUpload(e.target.files[i]);
    }
  }

  getRelevantConsultantIRI() {
    const { userData } = this.props;
    if (this.state.selectedConsultant !== undefined && this.state.selectedConsultant !== null) {
      return this.state.selectedConsultant['@id'];
    } else if (isSubUser(true) && getSubusersConsultantToManage()) {
      return getSubusersConsultantToManage().iri;
    } else if (isConsultant(true) && userData.consultantAsPrimaryUser && userData.consultantAsPrimaryUser.id) {
      return userData.consultantAsPrimaryUser['@id'];
    }
    return null;
  }

  onSubmit(values) {
    const { invoiceBatchAdd, invoiceBatchNoteAdd, fileClearReferences, temporaryFiles, reset, addToast, currentFormValues } = this.props;
    const baseValues = {
      isProblem: false,
      isEmbassy: false,
      isUrgent: false,
      invoiceDates: [],
    };

    if (currentFormValues.noFilesSupplied !== true) {
      baseValues.temporaryInvoiceFilesToAttach = temporaryFiles.map((tempFile) => tempFile['id']);
    }
    const noteContent = values.note;
    delete (values.clinicDates, values.confirmEmbassy, values.note, values.selectedHospitals, values.hospitals);
    const invoiceBatchValues = { ...baseValues, ...values };

    invoiceBatchValues['advisedNumberOfInvoices'] = Number(invoiceBatchValues['advisedNumberOfInvoices']);
    invoiceBatchValues['consultant'] = this.getRelevantConsultantIRI();

    if (this.state.selectedHospitals && this.state.selectedHospitals.length > 0) {
      invoiceBatchValues['hospitals'] = this.state.selectedHospitals.map((h) => {
        return h.value;
      });
    }

    if (this.state.selectedDates.length > 0) {
      this.state.selectedDates.forEach((dateKeyPair) => {
        let amPmValue = this.state.dateAmPmMap[dateKeyPair.key];
        invoiceBatchValues['invoiceDates'].push({
          date: moment(dateKeyPair.date).utc().toISOString(),
          timeOfDay: amPmValue,
        });
      });
    }

    return invoiceBatchAdd(invoiceBatchValues).then((response) => {
      if (noteContent) {
        invoiceBatchNoteAdd({ note: noteContent, isPrivate: false, invoiceBatch: response['@id'] });
      }
      fileClearReferences();
      reset();
      this.clearTypeaheads();
      const link = (
        <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', response.id)} className="btn btn-sm btn-light ml-2">
          View
        </Link>
      );

      addToast('Invoice successfully created', true, false, !isSubUser(true) || subUserCanViewAndDownloadInvoices() ? link : undefined);
      this.setState(() => this.getCleanState());
    });
  }

  doReset() {
    this.props.fileClearReferences();
    this.props.reset();
    this.clearTypeaheads();
    this.setState(() => this.getCleanState());
  }

  render() {
    const {
      handleSubmit,
      currentFormValues,
      error,
      temporaryFiles,
      fileDelete,
      fileRequestInProgress,
      pristine,
      submitting,
      dirtyIsEmbassy,
      dirtyConfirmEmbassy,
    } = this.props;
    const consultantIRI = this.getRelevantConsultantIRI();
    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {isStaff() && (
            <ConsultantTypeahead
              name="consultant"
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              multiple={false}
              label={'Please choose a Consultant'}
              onChange={(selected) => {
                this.setState(() => {
                  return {
                    selectedConsultant: selected[0],
                    selectedHospitals: [],
                    availableHospitals:
                      selected[0] && selected[0].hospitals
                        ? selected[0].hospitals.map((h) => {
                            return { value: h['@id'], label: h.name };
                          })
                        : [],
                  };
                });
              }}
            />
          )}

          <Field
            disabled={!isClient() && this.state.selectedConsultant == null}
            name="selectedHospitals"
            isMulti
            closeMenuOnSelect={this.state.availableHospitals.length - this.state.selectedHospitals.length === 1}
            handleSelect={(selected) => {
              this.setState(() => {
                return Symbol.iterator in Object(selected) ? { selectedHospitals: [...selected] } : { selectedHospitals: [] };
              });
            }}
            selectedOptions={this.state.selectedHospitals}
            label="Please select the Hospitals that these invoices relate to"
            options={this.state.availableHospitals}
            component={renderReactSelect}
          />

          <div className="mediaccounts-clinic-date-component">
            <p>Please select the dates/times that these invoices relate to</p>
            <Field
              component={renderDatePicker}
              name="clinicDates"
              label={<i className="fas fa-calendar mr-2" />}
              id="clinic-dates"
              handleChange={() => {
                /* this is not used in the case but required*/
              }}
              handleSelect={this.selectDate.bind(this)}
              clearOnClose={true}
              popperPlacement="top-end"
              className="mediaccounts-clinic-date-input"
            />

            <ul className="list-inline">
              {this.state.selectedDates.map((dateKeyPair) => (
                <DateWithMorningOrAfternoonSelector
                  key={dateKeyPair.key}
                  date={dateKeyPair.date}
                  dateKey={dateKeyPair.key}
                  amOrPm={this.state.dateAmPmMap[dateKeyPair.key]}
                  amPmSelector={this.selectDateAmPm.bind(this)}
                  removeSelectedDate={this.removeSelectedDateKeyPair.bind(this)}
                />
              ))}
              {this.state.selectedDates.length > 0 && (
                <li className="list-inline-item">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger date-with-morning-or-afternoon-delete-all-button"
                    onClick={this.clearSelectedDates.bind(this)}>
                    <i className="fa fa-times mr-2" /> Remove all
                  </button>
                </li>
              )}
            </ul>
          </div>
          {consultantIRI && (
            <PotentialDuplicates
              hospitals={this.state.selectedHospitals}
              consultantIRI={consultantIRI}
              clinicDates={this.state.selectedDates}
              dateAmPmMap={this.state.dateAmPmMap}
            />
          )}

          {currentFormValues.noFilesSupplied !== true && (
            <>
              <p>Please select the invoice files you wish to upload</p>
              <FileUploader onFilesAdded={this.onFilesAdded.bind(this)} requestInProgress={fileRequestInProgress} />
              <FileBrowser files={temporaryFiles} deleteHandler={fileDelete} isLocked={fileRequestInProgress} />
            </>
          )}
          {temporaryFiles.length < 1 && (
            <Field
              name="noFilesSupplied"
              label="I'm not supplying any files (Details in note below)"
              id="no-files"
              component={renderCheckbox}
              className="mb-4"
            />
          )}

          <Field
            name="advisedNumberOfInvoices"
            label="Number of individual invoices"
            id="advised-number-of-invoices"
            type="number"
            step={1}
            component={renderField}
          />
          <br />

          <Field
            groupClasses={!isStaff() && 'mb-4'}
            name="note"
            label={`Add a${currentFormValues.noFilesSupplied !== true ? `n optional ` : ` `}note`}
            id="note"
            rows="3"
            type="textarea"
            component={renderField}
          />
          {isStaff() && <p className="badge badge-danger mb-4">Notes added show to Consultant and related Sub-users</p>}
          <Field name="isUrgent" label="Mark as Urgent" id="is-urgent" component={renderCheckbox} />
          <br />

          <Field name="isEmbassy" label="Embassy Claim" id="is-embassy" component={renderCheckbox} />

          {dirtyIsEmbassy && (
            <div>
              <br /> <Alert variant="danger">N.B No Kuwait Health Invoices will be processed, so therefore please do not upload these.</Alert>
            </div>
          )}

          {dirtyIsEmbassy && (
            <div>
              <br />
              <Field name="confirmEmbassy" label="I confirm I have included the log files required" id="confirm-embassy" component={renderCheckbox} />
            </div>
          )}
          <hr />
          <button
            type="submit"
            disabled={
              fileRequestInProgress ||
              this.state.selectedHospitals.length < 1 ||
              this.state.selectedDates.length < 1 ||
              (temporaryFiles.length < 1 && (currentFormValues.noFilesSupplied !== true || !currentFormValues.note || currentFormValues.note.length < 1)) ||
              (dirtyIsEmbassy && !dirtyConfirmEmbassy) ||
              pristine ||
              submitting
            }
            className="btn btn-primary">
            Submit invoices
          </button>

          {/* Dan - this doesn't clear Consultant or Dates. Is it even needed? */}
          <button
            type="button"
            onClick={() => {
              this.doReset();
            }}
            className="ml-2 btn btn-outline-danger">
            Clear form
          </button>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'CreateForm',
  initialValues: {
    selectedHospitals: [],
  },
})(connect(mapStateToProps, mapDispatchToProps)(CreateForm));
