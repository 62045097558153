import {
  WORK_SCHEDULE_ERROR,
  WORK_SCHEDULE_RECEIVED,
  WORK_SCHEDULE_REQUEST,
  WORK_SCHEDULE_SET_DATE,
  WORK_SCHEDULE_SINGLE_RECEIVED,
  WORK_SCHEDULE_UNLOAD,
} from '../constants';
import moment from 'moment';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const workScheduleRequest = () => ({
  type: WORK_SCHEDULE_REQUEST,
});

export const workScheduleReceived = (data, date) => ({
  type: WORK_SCHEDULE_RECEIVED,
  data,
  date,
});

export const workScheduleSingleReceived = (data) => ({
  type: WORK_SCHEDULE_SINGLE_RECEIVED,
  data,
});

export const workScheduleError = (error) => ({
  type: WORK_SCHEDULE_ERROR,
  error,
});

export const workScheduleUnload = () => ({
  type: WORK_SCHEDULE_UNLOAD,
});

export const workScheduleSetDate = (date) => ({
  type: WORK_SCHEDULE_SET_DATE,
  date,
});

export const workScheduleFetchList = (date = moment().utc().format('DD-MM-YYYY')) => {
  return (dispatch) => {
    dispatch(workScheduleRequest());
    return requests
      .get(`${API_ROUTES.WORK_SCHEDULE_LIST}`, { date, pagination: false })
      .then((response) => dispatch(workScheduleReceived(response, date)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(workScheduleError(error));
      });
  };
};

export const workScheduleUpdate = (data) => {
  return (dispatch) => {
    return requests
      .post(`${API_ROUTES.WORK_SCHEDULE_UPDATE}`, data)
      .then((response) => dispatch(workScheduleSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(workScheduleError(error));
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
