import React from 'react';
import { connect } from 'react-redux';
import MASpinner from '../../components/common/MASpinner';
import SelectConsultant from './SelectConsultant';
import { subUserFetch, subuserSetConsultantToManage } from '../../lib/redux/actions/subUser';
import * as ROUTES from '../../lib/routing/frontend';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  subuserSetConsultantToManage,
  subUserFetch,
};

class SelectConsultantContainer extends React.Component {
  componentDidMount() {
    // if only one related consultant just set it here and move on
    if (this.props.userData && this.props.userData.consultantsAsSubuser && this.props.userData.consultantsAsSubuser.length === 1) {
      this.selectConsultantAndRedirect(this.props.userData.consultantsAsSubuser[0].consultant);
    }
  }

  selectConsultantAndRedirect(consultant) {
    const { subuserSetConsultantToManage, subUserFetch, history, userId } = this.props;

    const consultantToManage = (({ code, name }) => ({ code, name }))(consultant);
    consultantToManage.id = consultant.id;
    consultantToManage.iri = consultant['@id'];
    subuserSetConsultantToManage(consultantToManage);
    subUserFetch(userId, consultant.id);

    history.push(ROUTES.HOME);
  }

  render() {
    const { userData } = this.props;
    if (!userData) {
      return <MASpinner />;
    }
    if (!userData.consultantsAsSubuser || userData.consultantsAsSubuser.length < 1) {
      return <MASpinner msg="You don't seem to be able to manage any consultants?" />;
    }
    return <SelectConsultant consultants={userData.consultantsAsSubuser} onSelect={this.selectConsultantAndRedirect.bind(this)} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectConsultantContainer);
