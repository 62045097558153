import React, { useCallback, useEffect, useState } from 'react';
import VerifyPatientDetails from './VerifyPatientDetails';
import { connect } from 'react-redux';
import YourInvoice from './YourInvoice';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';

const ViewAnInvoiceContainer = ({ invoice, error }) => {
  useEffect(() => {
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, []);
  const [invoiceError, setInvoiceError] = useState(null);

  const handleInvoiceError = useCallback(() => {
    if (error && error.error) {
      setInvoiceError(error.error);
      const timeoutId = setTimeout(() => {
        setInvoiceError(null);
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [error]);

  useEffect(() => {
    return handleInvoiceError();
  }, [handleInvoiceError]);

  return (
    <Container>
      <Row>
        <Col
          className="pt-4"
          xs={12}
          md={12}
          lg={{
            span: 8,
            offset: 2,
          }}>
          <div>{invoice ? <YourInvoice invoice={invoice} /> : <VerifyPatientDetails invoiceError={invoiceError} />}</div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.invoices,
  };
};

export default connect(mapStateToProps, null)(ViewAnInvoiceContainer);
