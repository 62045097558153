import React, { Fragment, Component } from 'react';
import { onboardingItemsListFetch, onboardingItemsListUnload } from '../../lib/redux/actions/onboardingItems';
import { connect } from 'react-redux';
import ConsultantOnboardingItemsTable from './ConsultantOnboardingItemsTable';
import OnboardingNotesForm from './OnboardingNotesForm';

const mapStateToProps = (state) => ({
  ...state.onboardingItems,
});

const mapDispatchToProps = {
  onboardingItemsListFetch,
  onboardingItemsListUnload,
};

class ConsultantOnboardingItemsTableContainer extends Component {
  componentDidMount() {
    this.loadOnboardingItems();
  }

  componentWillUnmount() {
    this.props.onboardingItemsListUnload();
  }

  loadOnboardingItems = () => {
    return this.props.onboardingItemsListFetch(this.props.consultant.primaryUser.id);
  };

  render() {
    const { onboardingItems, isFetchingOnboardingItems, consultant } = this.props;
    return (
      <Fragment>
        <ConsultantOnboardingItemsTable isFetchingOnboardingItems={isFetchingOnboardingItems} onboardingItems={onboardingItems} consultant={consultant} />
        <OnboardingNotesForm consultant={consultant} />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantOnboardingItemsTableContainer);
