import MASpinner from '../common/MASpinner';
import InvoiceCreditControlForm from '../forms/Invoice/InvoiceCreditControlForm';
import React from 'react';
import * as PropTypes from 'prop-types';
import InvoicePDFFileDownloadButton from './InvoicePDFFileDownloadButton';

function OldInvoiceSingleRefOnly(props) {
  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        {!props.fetchingInvoice && props.hasLoadedInvoice && props.invoice && <p className={'mb-0'}>{props.invoice.formattedIdentifier}</p>}

        {!props.fetchingInvoice && props.hasLoadedInvoice && props.invoice && (props.invoice.isEscalated || props.invoice.isMissingContactDetails) && (
          <p className={'mb-0'}>
            {props.invoice.isEscalated && (
              <span className="ml-2 text-uppercase badge badge-pill badge-danger">
                <i className="fas fa-exclamation-circle" title="Escalated" />
                <span className="ml-1 d-none d-sm-inline">Escalated</span>
              </span>
            )}

            {props.invoice.isMissingContactDetails && props.invoice?.missingContactDetailsType && (
              <span className="ml-2 text-uppercase badge badge-pill badge-danger">
                {props.invoice.missingContactDetailsType.code === '0' && <i className="fas fa-phone" title={props.invoice.missingContactDetailsType.name} />}
                {props.invoice.missingContactDetailsType.code === '10' && (
                  <i className="fas fa-envelope" title={props.invoice.missingContactDetailsType.name} />
                )}
                {props.invoice.missingContactDetailsType.code === '20' && (
                  <>
                    <i className="fas fa-phone" title={props.invoice.missingContactDetailsType.name} />
                    <i className="ml-2 fas fa-envelope" title={props.invoice.missingContactDetailsType.name} />
                  </>
                )}
                <span className="ml-1 d-none d-sm-inline">{props.invoice.missingContactDetailsType.name}</span>
              </span>
            )}
          </p>
        )}

        {props?.closeModal && (
          <button className="btn btn-outline-dark" onClick={() => props.closeModal()}>
            Close
          </button>
        )}
      </div>

      <div className="card-body modalised-overflowing-card-body">
        {props.fetchingInvoice && !props.hasLoadedInvoice && !props.invoice && <MASpinner />}
        {!props.fetchingInvoice && props.hasLoadedInvoice && props.invoice && (
          <InvoiceCreditControlForm invoice={props.invoice} closeModal={props.closeModal} />
        )}
        <InvoicePDFFileDownloadButton invoice={props.invoice} />
      </div>
    </div>
  );
}

OldInvoiceSingleRefOnly.propTypes = {
  fetchingInvoice: PropTypes.any,
  hasLoadedInvoice: PropTypes.any,
  invoice: PropTypes.any,
  closeModal: PropTypes.func,
};

export default OldInvoiceSingleRefOnly;
