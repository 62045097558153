import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

const PdfViewer = ({ pdfUrl, scale = 1 }) => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument } = usePdf({
    file: pdfUrl,
    page,
    canvasRef,
    scale: scale,
  });

  return (
    <div style={{ overflowY: 'auto', height: '600px' }}>
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} className={'border border-dark'} width={'100%'} />
      {Boolean(pdfDocument && pdfDocument.numPages && pdfDocument.numPages > 1) && (
        <nav>
          <ul className="pager d-inline p-0">
            <li className="previous d-inline mr-4">
              <button className={'btn btn-outline-secondary'} disabled={page === 1} onClick={() => setPage(page - 1)}>
                <i className="fas fa-chevron-left" />
              </button>
            </li>
            <li className="next d-inline">
              <button className={'btn btn-outline-secondary'} disabled={page === pdfDocument.numPages} onClick={() => setPage(page + 1)}>
                <i className="fas fa-chevron-right" />
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
export default PdfViewer;
