import React from 'react';
import * as PropTypes from 'prop-types';

class ConfirmButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false,
    };
    this.timeoutHandle = false;
  }

  componentWillUnmount() {
    if (this.timeoutHandle !== false) {
      clearTimeout(this.timeoutHandle);
    }
  }

  confirm() {
    this.setState(() => ({ confirmed: true }));

    this.timeoutHandle = setTimeout(() => {
      this.setState(() => ({ confirmed: false }));
    }, this.props.confirmTimeout);
  }

  render() {
    return (
      <button
        type="button"
        onClick={
          this.state.confirmed
            ? () => {
                this.props.confirmedAction();
              }
            : () => {
                this.confirm();
              }
        }
        className={this.state.confirmed ? this.props.confirmedClasses : this.props.confirmClasses}>
        {this.state.confirmed ? this.props.confirmedText : this.props.confirmText}
      </button>
    );
  }
}

ConfirmButton.defaultProps = {
  confirmTimeout: 10000,
};

ConfirmButton.propTypes = {
  confirmTimeout: PropTypes.number.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmedText: PropTypes.string.isRequired,
  confirmClasses: PropTypes.string.isRequired,
  confirmedClasses: PropTypes.string.isRequired,
  confirmedAction: PropTypes.func.isRequired,
};

export default ConfirmButton;
