import React from 'react';
import Form from 'react-bootstrap/Form';
import MASpinner from '../../common/MASpinner';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderCheckbox } from '../../../lib/helper/form';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { invoiceSingleAction, invoiceSingleCreateCompleteCallAction } from '../../../lib/redux/actions/invoices';
import { addToast } from '../../../lib/redux/actions/toast';
import { isChaserOutsourcer, isLoggedInUser, isStaff } from '../../../lib/helper/authorisation';
import moment from 'moment/moment';
import { getStats } from '../../../lib/redux/actions/stats';

const actions = [
  { value: 'chaser_1', label: 'Chaser 1', id: 'chaser-1', requiresConfirm: true },
  { value: 'chaser_2', label: 'Chaser 2', id: 'chaser-2', requiresConfirm: true },
  { value: 'chaser_3', label: 'Chaser 3', id: 'chaser-3', requiresConfirm: true, staffOnly: true },
  { value: 'chaser_4', label: 'Chaser 4', id: 'chaser-4', requiresConfirm: true },
  { value: 'chaser_5', label: 'Chaser 5', id: 'chaser-5', requiresConfirm: true, staffOnly: true },
  { value: 'did_not_chase', label: 'Did Not Chase', id: 'did-not-chase', requiresConfirm: true },
  { value: 'part_paid', label: 'Part Payment Taken', id: 'part-paid', requiresConfirm: true },
  { value: 'paid', label: 'Full Payment Taken', id: 'fully-paid', requiresConfirm: true },
  { value: 'not_due_from_patient', label: 'Not due from patient', id: 'not-due-from-patient', requiresConfirm: true },
];

const requiresConfirm = (type) => actions.some((action) => action.value === type && action.requiresConfirm);

const mapStateToProps = (state) => {
  return {
    currentFormValues: getFormValues('InvoiceActionForm')(state),
  };
};

const mapDispatchToProps = {
  invoiceSingleAction,
  addToast,
  invoiceSingleCreateCompleteCallAction,
  getStats,
};

class InvoiceActionForm extends React.Component {
  onSubmit(values) {
    const { invoiceSingleAction, invoice, addToast, reset } = this.props;
    return invoiceSingleAction(invoice.id, values['actionType']).then(() => {
      addToast('The Invoice was successfully updated', true);
      reset();
    });
  }

  completeCall() {
    const { invoiceSingleCreateCompleteCallAction, invoice, addToast, closeModal, getStats } = this.props;

    const unassignsCurrentUser = isChaserOutsourcer(true) || isStaff(true);
    return invoiceSingleCreateCompleteCallAction(invoice.id, unassignsCurrentUser).then(() => {
      addToast('The Invoice/Call has been completed', true);
      getStats();
      closeModal && closeModal();
    });
  }
  componentDidUpdate(prevProps) {
    const { change, currentFormValues } = this.props;
    if (prevProps?.currentFormValues?.actionType !== currentFormValues?.actionType) {
      change('isActionConfirmed', false);
    }
  }

  render() {
    const { handleSubmit, currentFormValues, invoice, disabled, isUpdatingInvoice } = this.props;

    let canComplete = false;

    const lastActionedBy = invoice.lastActionedBy ? invoice.lastActionedBy.id : false;
    if (
      lastActionedBy &&
      invoice.lastActionedDate &&
      (isStaff() || isLoggedInUser(lastActionedBy)) &&
      moment(invoice.lastActionedDate).isSame(new Date(), 'week')
    ) {
      canComplete = true;
    }

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Form.Group controlId="actionType" title={'Invoice Actions'}>
          <h6 className={'mb-3'}>Invoice Actions</h6>
          {actions.map((type) => {
            return !type?.staffOnly || isStaff() ? (
              <div key={type.value} className="mb-3">
                <Field
                  type="radio"
                  name="actionType"
                  label={type.label}
                  id={type.id}
                  value={type.value}
                  component={renderCheckbox}
                  checked={currentFormValues?.actionType === type.value}
                />
              </div>
            ) : null;
          })}
        </Form.Group>

        {isUpdatingInvoice ? <MASpinner /> : null}
        {!isUpdatingInvoice && requiresConfirm(currentFormValues?.actionType) ? (
          <>
            <div className={'modal-confirmation-input'}>
              <Field
                name="isActionConfirmed"
                label="Please confirm that you have added a note in Healthcodes"
                id="is-action-confirmed"
                component={renderCheckbox}
              />
            </div>
            <button type="submit" disabled={disabled || !currentFormValues?.isActionConfirmed} className="btn btn-primary mb-3 mt-3">
              Log Action
            </button>
          </>
        ) : (
          <button type="submit" disabled={disabled} className="btn btn-primary mb-3 mt-3">
            Log Action
          </button>
        )}
        <button
          title={
            !canComplete
              ? 'Please log an action to be able to complete the call'
              : 'Clicking Complete Call will finish your work on this item and remove it from your list.'
          }
          className={`btn btn-danger float-right mt-3`}
          type={'button'}
          disabled={!canComplete}
          onClick={() => this.completeCall()}>
          Complete Call
        </button>
      </Form>
    );
  }
}

InvoiceActionForm.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'InvoiceActionForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(InvoiceActionForm),
);
