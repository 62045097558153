import {
  CONTACT_RECORD_LIST_REQUEST,
  CONTACT_RECORD_LIST_RECEIVED,
  CONTACT_RECORD_LIST_ERROR,
  CONTACT_RECORD_LIST_UNLOAD,
  CONTACT_RECORD_SINGLE_UPDATE_REQUEST,
  CONTACT_RECORD_SINGLE_UPDATED,
  CONTACT_RECORD_SINGLE_CREATE_SUCCESS,
  CONTACT_RECORD_SINGLE_CREATE_REQUEST,
  CONTACT_RECORD_SINGLE_REMOVE_ERROR,
  CONTACT_RECORD_SINGLE_REMOVED,
  CONTACT_RECORD_SINGLE_REMOVE_REQUEST,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    contactRecords: [],
    isFetchingContactRecords: false,
    currentPage: 1,
    pageCount: null,
    //  filters: {},

    contactRecord: null,
    isFetchingContactRecord: false,
    isUpdatingContactRecord: false,
    isCreatingContactRecord: false,
    isRemovingContactRecord: false,
  },
  action,
) => {
  switch (action.type) {
    case CONTACT_RECORD_SINGLE_CREATE_REQUEST:
      return {
        ...state,
        isCreatingContactRecord: true,
      };

    case CONTACT_RECORD_SINGLE_UPDATE_REQUEST:
      state = {
        ...state,
        isUpdatingContactRecord: true,
      };
      return state;

    case CONTACT_RECORD_SINGLE_CREATE_SUCCESS:
    case CONTACT_RECORD_SINGLE_UPDATED: {
      const updatedContactRecordsList = [...state.contactRecords];
      const removeIndex = updatedContactRecordsList.map((item) => item.id).indexOf(action.data.id);

      if (removeIndex >= 0) {
        updatedContactRecordsList.splice(removeIndex, 1);
      }

      return {
        ...state,
        contactRecords: [...updatedContactRecordsList, action.data].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        isUpdatingContactRecord: false,
        isCreatingContactRecord: false,
      };
    }
    case CONTACT_RECORD_LIST_UNLOAD:
      return {
        contactRecords: [],
        isFetchingContactRecords: false,
        currentPage: 1,
        pageCount: null,
        //        filters: {}
      };

    case CONTACT_RECORD_LIST_REQUEST:
      return {
        ...state,
        isFetchingContactRecords: true,
      };

    case CONTACT_RECORD_LIST_RECEIVED:
      return {
        ...state,
        contactRecords: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingContactRecords: false,
      };
    case CONTACT_RECORD_SINGLE_REMOVE_REQUEST:
      return {
        ...state,
        isRemovingContactRecord: true,
      };
    case CONTACT_RECORD_SINGLE_REMOVED:
      const updatedContactRecordsList = [...state.contactRecords];
      const removeIndex = updatedContactRecordsList.map((item) => item.id).indexOf(action.data.id);

      if (removeIndex >= 0) {
        updatedContactRecordsList.splice(removeIndex, 1);
      }
      return {
        ...state,
        contactRecords: [...updatedContactRecordsList, action.data].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        isRemovingContactRecord: false,
      };
    case CONTACT_RECORD_SINGLE_REMOVE_ERROR:
    case CONTACT_RECORD_LIST_ERROR:
      return {
        ...state,
        isFetchingContactRecords: false,
      };

    default:
      return state;
  }
};

export default reducer;
