import PropTypes from 'prop-types';
import React from 'react';
import MASpinner from '../common/MASpinner';

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileTooBig: false };
  }

  validateFileSize(e) {
    const { sizeLimitMB, onFilesAdded } = this.props;
    let tooBig = false;

    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        if (e.target.files.item(i).size >= sizeLimitMB * 1024 * 1024) {
          tooBig = true;
        }
      }
    }

    if (tooBig) {
      this.setState(() => ({ fileTooBig: true }));
      return;
    }

    this.setState(() => ({ fileTooBig: false }));
    onFilesAdded(e);
  }

  render() {
    const { title, requestInProgress, multiple, accept, sizeLimitMB } = this.props;
    return (
      <>
        <p className="show-only-ie-and-edge-non-chromium text-danger">
          <i className="fas fa-exclamation text-danger mr-2"></i> You&apos;re running an outdated version of the edge browser. Please update to be able to use
          drag and drop uploading or click the plus below to upload.
        </p>
        <div className="nice-image-upload form-group">
          {!requestInProgress ? (
            <>
              <input
                type="file"
                multiple={multiple}
                onChange={(e) => {
                  this.validateFileSize(e);
                }}
                className="form-control-file text-primary font-weight-bold"
                data-title={title}
                accept={accept !== false ? accept : undefined}
              />
              {this.state.fileTooBig && <p className="alert-danger">File too big, please select a file less than {sizeLimitMB} MB</p>}
            </>
          ) : (
            <MASpinner />
          )}
        </div>
      </>
    );
  }
}

FileUploader.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  multiple: PropTypes.bool,
  onFilesAdded: PropTypes.func.isRequired,
  requestInProgress: PropTypes.bool,
  sizeLimitMB: PropTypes.number,
  title: PropTypes.string,
};

FileUploader.defaultProps = {
  accept: false,
  multiple: true,
  requestInProgress: false,
  sizeLimitMB: 100,
  title: 'Click here or drag and drop a file to upload',
};

export default FileUploader;
