import {
  SITE_SETTINGS_LIST_REQUEST,
  SITE_SETTINGS_LIST_RECEIVED,
  SITE_SETTINGS_LIST_ERROR,
  SITE_SETTINGS_LIST_UNLOAD,
  SITE_SETTING_SINGLE_UPDATED,
} from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    siteSettings: [],
    pageCount: null,
    currentPage: 1,
    isFetchingSiteSettings: false,
  },
  action,
) => {
  switch (action.type) {
    case SITE_SETTINGS_LIST_REQUEST:
      return {
        ...state,
        isFetchingSiteSettings: true,
      };

    case SITE_SETTINGS_LIST_RECEIVED:
      return {
        ...state,
        siteSettings: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingSiteSettings: false,
      };

    case SITE_SETTINGS_LIST_ERROR:
      return {
        ...state,
        isFetchingSiteSettings: false,
      };

    case SITE_SETTINGS_LIST_UNLOAD:
      return {
        siteSettings: [],
        pageCount: null,
        currentPage: 1,
        isFetchingSiteSettings: false,
      };

    case SITE_SETTING_SINGLE_UPDATED:
      return {
        ...state,
        siteSettings: [
          ...state.siteSettings.map((x) => {
            return action.data.id === x.id ? action.data : x;
          }),
        ],
      };
    default:
      return state;
  }
};

export default reducer;
