import React from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import Helmet from 'react-helmet';
import ConsultantCcsdCodeCostTableContainer from '../../components/ccsdCode/consultant/ConsultantCcsdCodeCostTableContainer';

class ConsultantSingleForSubUser extends React.Component {
  render() {
    const { consultant } = this.props;
    return (
      <div>
        <Helmet>
          <title>{consultant.name} - A Consultant</title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Account Details: {consultant.name}</Card.Header>
              <Card.Body>
                {/*<Row>*/}
                {/*  <Col>*/}
                {/*    <h1 className="crud__body-title">{consultant.name}</h1>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                {/*<hr/>*/}
                <Row>
                  <Col>
                    <Tabs defaultActiveKey="ccsdCodes" variant="pills" className="mx-1 mb-2">
                      <Tab eventKey="ccsdCodes" title={`CCSD code costs`}>
                        <ConsultantCcsdCodeCostTableContainer consultant={consultant} />
                      </Tab>
                      {consultant?.bible && consultant?.bible?.length && (
                        <Tab eventKey="bible" title="Consultant Bible">
                          <Row>
                            <Col>
                              <div className="invoice-batch-single__section mb-3">
                                <div className="invoice-batch-single__section-icon">
                                  <i className="fas fa-book-open" />
                                </div>
                                <h2 className="invoice-batch-single__section-header">Bible</h2>
                                <div className="invoice-batch-single__notes-wrapper">
                                  <div dangerouslySetInnerHTML={{ __html: consultant.bible }}></div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                      )}
                    </Tabs>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantSingleForSubUser;
