import { Field } from 'redux-form';
import React from 'react';
import { ccsdCodeTypeaheadListFetch, ccsdCodeTypeaheadListUnload } from '../../lib/redux/actions/ccsdCodesTypeahead';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TypeAheadField from '../common/reduxForm/TypeaheadField';
import { ccsdCodeTypeListFetch } from '../../lib/redux/actions/ccsdCodeType';
import { ccsdCodeSingleCreate } from '../../lib/redux/actions/ccsdCodes';
import { addToast } from '../../lib/redux/actions/toast';
import Alert from 'react-bootstrap/Alert';
import { renderField } from '../../lib/helper/form';

const mapStateToProps = (state) => ({
  ...state.ccsdCodesTypeahead,
  ...state.ccsdCodeTypes,
});
const mapDispatchToProps = {
  ccsdCodeTypeaheadListFetch,
  ccsdCodeTypeaheadListUnload,
  ccsdCodeTypeListFetch,
  ccsdCodeSingleCreate,
  addToast,
};

class CcsdCodeTypeaheadWithCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameVal: undefined,
    };
  }

  componentDidMount() {
    this.props.ccsdCodeTypeListFetch();
  }

  componentDidUpdate() {
    const { shouldClear, hasCleared } = this.props;
    if (shouldClear) {
      this.ccsdCodeRef.getRenderedComponent().clear();
      hasCleared();
    }
  }

  _onSearch(query) {
    this.props.ccsdCodeTypeaheadListFetch(1, { reference: query });
  }

  createCcsdCode(ccsdCodeType) {
    const { ccsdCodeSingleCreate, addToast, hasCleared, ccsdCodeTypeaheadListUnload, onCcsdCodeCreated } = this.props;

    const newCcsdCode = {
      reference: this.ccsdCodeRef.value,
      ccsdCodeType: ccsdCodeType['@id'],
      required: ccsdCodeType.isRequiredDefault,
    };

    if (this.state.nameVal !== undefined) {
      newCcsdCode.name = this.state.nameVal;
    }

    ccsdCodeSingleCreate(newCcsdCode)
      .then((data) => {
        onCcsdCodeCreated(data);

        addToast('New Code Added', true);
        this.ccsdCodeRef.getRenderedComponent().clear();
        hasCleared();
        ccsdCodeTypeaheadListUnload();
      })
      .catch(() => {
        addToast('Problem with code creation', false, true);
        this.ccsdCodeRef.getRenderedComponent().clear();
        hasCleared();
        ccsdCodeTypeaheadListUnload();
      });
  }

  render() {
    const {
      onChange,
      ccsdCodes = [],
      searchCompleted = false,
      isFetchingCcsdCodes,
      ccsdCodeTypes = [],
      multiple = true,
      label = 'CCSD code',
      name = 'ccsdCode',
    } = this.props;
    return (
      <>
        <Field
          name={name}
          isLoading={isFetchingCcsdCodes}
          label={label}
          emptyLabel={'CCSD Code Not found'}
          onSearch={this._onSearch.bind(this)}
          options={ccsdCodes || []}
          labelKey={(option) => `${option.reference} - (${option.name})`}
          component={TypeAheadField}
          multiple={multiple}
          onChange={onChange}
          ref={(ref) => (this.ccsdCodeRef = ref)}
          forwardRef
        />
        {searchCompleted && ccsdCodes.length === 0 && (
          <Alert variant="info" className="pb-1">
            <div className="mb-2">
              <br />
              <p className="font-weight-bold">This looks like a new code...</p>

              <Field
                name="name"
                label={`Add optional Name/Description`}
                id="ccsdCodeName"
                type="text"
                onChange={(e) => {
                  this.setState(() => ({ nameVal: e.target.value }));
                }}
                component={renderField}
              />

              {ccsdCodeTypes.map((ccsdCodeType) => (
                <button
                  className="btn btn-info mr-2 mb-1"
                  key={ccsdCodeType.id}
                  onClick={() => {
                    this.createCcsdCode(ccsdCodeType);
                  }}>
                  Add {ccsdCodeType.name} code
                </button>
              ))}
            </div>
          </Alert>
        )}
      </>
    );
  }
}

CcsdCodeTypeaheadWithCreate.propTypes = {
  shouldClear: PropTypes.bool.isRequired,
  hasCleared: PropTypes.func.isRequired,
  isFetchingCcsdCodes: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  ccsdCodeTypeaheadListFetch: PropTypes.func.isRequired,
  ccsdCodeSingleCreate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CcsdCodeTypeaheadWithCreate);
