import {
  INVOICE_BATCH_BILLING_CHECKLIST_REQUEST,
  INVOICE_BATCH_BILLING_CHECKLIST_RECEIVED,
  INVOICE_BATCH_BILLING_CHECKLIST_ERROR,
  INVOICE_BATCH_BILLING_CHECKLIST_UNLOAD,
  INVOICE_BATCH_BILLING_CHECKLIST_SET_DATE,
} from '../constants';

const reducer = (
  state = {
    checklistInvoiceBatches: [],
    isFetchingChecklistInvoicesBatches: false,
    currentDate: undefined,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_BILLING_CHECKLIST_REQUEST:
      return {
        ...state,
        isFetchingChecklistInvoicesBatches: true,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_RECEIVED:
      return {
        ...state,
        checklistInvoiceBatches: action.data,
        isFetchingChecklistInvoicesBatches: false,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_ERROR:
      return {
        ...state,
        isFetchingChecklistInvoicesBatches: false,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_UNLOAD:
      return {
        checklistInvoiceBatches: [],
        isFetchingChecklistInvoicesBatches: false,
        currentDate: undefined,
      };

    case INVOICE_BATCH_BILLING_CHECKLIST_SET_DATE:
      return {
        ...state,
        currentDate: action.date,
      };

    default:
      return state;
  }
};

export default reducer;
