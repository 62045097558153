import { PAYMENT_LINK_ERROR, PAYMENT_LINK_RECEIVED, PAYMENT_LINK_REQUEST } from '../constants';

const reducer = (
  state = {
    paymentLink: null,
    isFetchingPaymentLink: false,
  },
  action,
) => {
  switch (action.type) {
    case PAYMENT_LINK_REQUEST:
      return {
        ...state,
        isFetchingPaymentLink: true,
      };
    case PAYMENT_LINK_RECEIVED:
      return {
        ...state,
        isFetchingPaymentLink: false,
        paymentLink: action.data?.paymentLink,
      };
    case PAYMENT_LINK_ERROR:
      return {
        ...state,
        isFetchingPaymentLink: false,
        paymentLink: null,
      };
    default:
      return state;
  }
};

export default reducer;
