import React from 'react';
import { invoiceBatchSingleSplit, invoiceBatchSingleFetch } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import { addToast } from '../../lib/redux/actions/toast';
import MASpinner from './MASpinner';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../lib/helper/form';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  invoiceBatchSingleSplit,
  invoiceBatchSingleFetch,
  addToast,
};

class SplitButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      splitting: false,
      showSplitQty: false,
      hasSplit: false,
    };
  }

  onSubmit(values) {
    const { invoiceBatch, invoiceBatchSingleSplit, addToast, invoiceBatchSingleFetch } = this.props;
    this.setState({ splitting: true });

    return invoiceBatchSingleSplit(invoiceBatch.id, Number(values.numberOfInvoices))
      .then(() => {
        invoiceBatchSingleFetch(invoiceBatch.id);
        this.setState({
          splitting: false,
          hasSplit: true,
        });
        addToast('The invoice batch was split', true, false); // ,link);
      })
      .catch(() => {
        addToast('There was a problem splitting the batch', false, true);
        this.setState({ splitting: false });
      });
  }

  render() {
    const { invoiceBatch, handleSubmit, pristine, submitting, error } = this.props;

    if (invoiceBatch?.splitFromInvoiceBatch || invoiceBatch?.splitToInvoiceBatch || this.state.hasSplit || invoiceBatch.numberOfInvoicesToProcess < 2) {
      return null;
    }
    if (
      !isSeniorStaff() ||
      (Number(invoiceBatch.status.code) !== Number(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED) &&
        Number(invoiceBatch.status.code) !== Number(INVOICE_BATCH_STATUS.PROCESSING))
    ) {
      return null;
    }
    if (this.state.splitting) {
      return <MASpinner />;
    }

    if (!this.state.showSplitQty) {
      return (
        <button
          title={`Split off some invoices into a new Batch. (Max: ${invoiceBatch.numberOfInvoicesToProcess - 1})`}
          onClick={() => {
            this.setState({ showSplitQty: true });
          }}
          type="button"
          className="ml-2 btn btn-dark btn-sm split-button">
          <i className="fa fa-object-ungroup mr-2" />
          Split
        </button>
      );
    }
    return (
      <>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="number-invoices-edit-form">
          <div className="input-group input-group-sm ml-2">
            <Field
              name="numberOfInvoices"
              min={'1'}
              max={invoiceBatch.numberOfInvoicesToProcess - 1}
              placeholder={'#'}
              groupClasses={'input-group-sm'}
              style={{ minWidth: '50px' }}
              id="numberOfInvoices"
              type="number"
              component={renderField}
            />
            <div className="input-group-append">
              <button
                type="submit"
                title={`The number of invoices to split into a new Batch (Max: ${invoiceBatch.numberOfInvoicesToProcess - 1})`}
                disabled={pristine || submitting}
                className="btn btn-primary">
                Split Off
              </button>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

SplitButton.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  invoiceBatchSingleSplit: PropTypes.func.isRequired,
  invoiceBatchSingleFetch: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'SplitButton',
    enableReinitialize: true,
  })(SplitButton),
);
