import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderCheckbox, renderField } from '../../../lib/helper/form';
import { consultantSingleFetchByIRI, consultantSingleUnload } from '../../../lib/redux/actions/consultant';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

const mapStateToProps = (state, props) => {
  return {
    userData: state.auth.userData,
    selectedConsultant: state.consultantSingle.consultant,
    currentFormValues: getFormValues('ReviewInvoiceDataForm')(state),
  };
};
const mapDispatchToProps = {
  addToast,
  consultantSingleFetchByIRI,
  consultantSingleUnload,
};

class ReviewInvoiceDataForm extends React.Component {
  constructor(props) {
    super(props);

    const { initialize } = this.props;

    this.state = {
      clearTypeaheads: false,
      selectedConsultant: props.invoiceData?.consultant ?? null,
    };

    const initVals = {
      ...props.invoiceData,
      patientDOB: props.invoiceData?.patientDOB ? moment(props.invoiceData.patientDOB, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      invoiceDate: props.invoiceData?.invoiceDate ? moment(props.invoiceData.invoiceDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      shortfallInvoiceDate: props.invoiceData?.shortfallInvoiceDate ? moment(props.invoiceData.shortfallInvoiceDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      serviceDate: props.invoiceData?.serviceDate ? moment(props.invoiceData.serviceDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      invoiceAmount: props.invoiceData?.invoiceAmount.replace(/[£,]/g, ''),
      amountDue: props.invoiceData?.amountDue.replace(/[£,]/g, ''),
    };

    initialize(initVals);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedConsultant !== this.props.selectedConsultant) {
      //      console.log({ selectedConsultant: this.props.selectedConsultant, prevSelectedConsultant: prevProps.selectedConsultant });
      this.setState(() => {
        return {
          selectedConsultant: this.props.selectedConsultant,
        };
      });
    }
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  getCleanState() {
    return {
      clearTypeaheads: false,
      selectedConsultant: this.props.selectedConsultant ?? null,
    };
  }

  componentWillUnmount() {
    //    this.props.invoiceBatchSingleUnload();
    this.props.consultantSingleUnload();
  }

  onSubmit(values) {
    const invoiceData = { ...this.props.invoiceData, ...values, consultant: this.state.selectedConsultant };
    if (!this.state?.selectedConsultant?.id) {
      return;
    }
    if (
      invoiceData?.invoiceBatchConsultantId &&
      this.state.selectedConsultant?.id &&
      invoiceData.invoiceBatchConsultantId !== this.state.selectedConsultant.id
    ) {
      // consultantMismatch between invoiceBatch and invoice.
      return;
    }
    // create a new object where values overwrites this.props.invoiceData
    delete invoiceData.specialist;
    delete invoiceData.userChecked;
    delete invoiceData.invoiceBatchConsultantId;
    this.props.onSubmit(invoiceData);
  }

  render() {
    const { handleSubmit, error, pristine, submitting, currentFormValues, invoiceData } = this.props;
    // console.log({ currentFormValues });
    const consultantMismatch =
      invoiceData?.invoiceBatchConsultantId && this.state.selectedConsultant?.id && invoiceData.invoiceBatchConsultantId !== this.state.selectedConsultant.id;
    return (
      <div className="row">
        <div className="col">
          <h4 className={'mb-3'}>Confirm Details</h4>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            {
              // only senior staff can change the consultant
              // invoiceData?.invoiceBatchConsultantId && !isSeniorStaff() ? (
              <p>
                Consultant: {invoiceData?.consultant?.name ?? <strong>NOT FOUND</strong>}{' '}
                {invoiceData?.consultant?.name && !invoiceData?.consultant?.useAutomatedChasing && (
                  <>
                    <br />
                    <strong style={{ color: 'red' }}>AUTO CHASING DISABLED</strong>
                  </>
                )}
              </p>
              // ) : (
              //   <ConsultantTypeahead
              //     required
              //     name="consultant"
              //     shouldClear={this.state.clearTypeaheads}
              //     hasCleared={this.typeaheadCleared.bind(this)}
              //     multiple={false}
              //     label={'Consultant'}
              //     selectedConsultants={this.state.selectedConsultant ? [this.state.selectedConsultant] : []}
              //     onChange={(selected) => {
              //       this.setState(() => {
              //         return {
              //           selectedConsultant: selected[0],
              //         };
              //       });
              //     }}
              //   />
              // )
            }
            {consultantMismatch && <div className="alert alert-danger text-center">The invoice consultant does not match this invoice batch</div>}
            <Row>
              <Col className={'col-lg-8'}>
                <Field required name="invoiceNo" label="Invoice Number" type="text" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field
                  required
                  name="invoiceDate"
                  label={currentFormValues?.isShortfall ? 'Shortfall Date' : 'Invoice Date'}
                  type="date"
                  component={renderField}
                />
              </Col>
            </Row>
            {currentFormValues?.isShortfall ? (
              <Row>
                <Col className={'col-lg-4'}>
                  <Field required name="shortfallInvoiceDate" label={'Original Invoice Date'} type="date" component={renderField} />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col className={'col-lg-8'}>
                <Field required name="patientName" label="Patient Name" type="text" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field required name="patientDOB" label="Patient DOB" type="date" component={renderField} />
              </Col>
            </Row>

            <Row>
              <Col className={'col-lg-4'}>
                <Field name="serviceDate" label="Service Date" type="date" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field required name="invoiceAmount" label="Invoice Amount (£)" type="number" step=".01" component={renderField} />
              </Col>
              <Col className={'col-lg-4'}>
                <Field
                  required
                  name="amountDue"
                  label={currentFormValues?.isShortfall ? 'Shortfall Amount (£)' : 'O/S Amount (£)'}
                  type="number"
                  step=".01"
                  component={renderField}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="subscriberNumber" label="Subscriber Number (Insurer Reg.)" type={'text'} component={renderField} />
              </Col>
              <Col>
                <Field name="claimNumber" label="Claim Number" type={'text'} component={renderField} />
              </Col>
            </Row>

            <Row>
              <Col className={'col-lg-4'}>
                <Field name="isShortfall" label="Shortfall?" id="is-shortfall" component={renderCheckbox} />{' '}
              </Col>
              <Col>
                <Field
                  required
                  name="userChecked"
                  label="I confirm I've checked these details"
                  id="user-checked"
                  component={renderCheckbox}
                  className={'text-right'}
                />
              </Col>
            </Row>

            <hr />
            {!invoiceData.existingInvoiceId && !consultantMismatch && !!this.state?.selectedConsultant?.id ? (
              <button type="submit" disabled={pristine || submitting || !currentFormValues.userChecked} className="btn btn-primary float-right">
                Lookup Contact Details
              </button>
            ) : (
              <Alert variant={'danger'} className={'text-center'}>
                You cannot upload this invoice{!!invoiceData?.invoiceBatchId ? ' against this batch' : ''}.
              </Alert>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ReviewInvoiceDataForm',
  })(ReviewInvoiceDataForm),
);
