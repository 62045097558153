import { INVOICE_ESCALATION_REASON_LIST_ERROR, INVOICE_ESCALATION_REASON_LIST_RECEIVED, INVOICE_ESCALATION_REASON_LIST_REQUEST } from '../constants';

const reducer = (
  state = {
    invoiceEscalationReasons: [],
    isFetchingInvoiceEscalationReasons: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_ESCALATION_REASON_LIST_REQUEST:
      state = {
        ...state,
        isFetchingInvoiceEscalationReasons: true,
      };
      return state;

    case INVOICE_ESCALATION_REASON_LIST_RECEIVED:
      state = {
        ...state,
        invoiceEscalationReasons: action.data['hydra:member'],
        isFetchingInvoiceEscalationReasons: false,
      };
      return state;

    case INVOICE_ESCALATION_REASON_LIST_ERROR:
      state = {
        ...state,
        isFetchingInvoiceEscalationReasons: false,
      };
      return state;

    default:
      return state;
  }
};

export default reducer;
