import React, { useEffect, useState } from 'react';
import { contactRecordIsPatient } from '../../../lib/helper/invoice';
import ContactRecordDrawer from './ContactRecordDrawer';
import type { ContactRecord } from '../../../apiTypes';
import { Row, Col } from 'react-bootstrap';

interface Props {
  setContactsUnderEdit: (contactsUnderEdit: boolean) => void;
  contacts: ContactRecord[];
  addNewContact: (newContact: ContactRecord) => boolean;
  removeContact: (contactToRemove: ContactRecord) => boolean;
  updateContact: (updatedContact: ContactRecord, origContact: ContactRecord) => boolean;
  existingInvoice?: boolean;
}

export const ManageInvoiceContacts = ({ contacts = [], updateContact, addNewContact, removeContact, setContactsUnderEdit, existingInvoice = false }: Props) => {
  const [addingContact, setAddingContact] = useState(false);
  const [formsUnderEdit, setFormsUnderEdit] = useState<string[]>([]);

  const toggleFormUnderEdit = (formId: string) => {
    setFormsUnderEdit(formsUnderEdit.includes(formId) ? [...formsUnderEdit.filter((f) => f !== formId)] : [...formsUnderEdit, formId]);
  };

  useEffect(() => {
    setContactsUnderEdit(!Boolean(formsUnderEdit.length));
  }, [formsUnderEdit, setContactsUnderEdit]);

  const hasPatientContact = contacts.some((c) => contactRecordIsPatient(c));
  return (
    <Row>
      <Col>
        {contacts.map((contact) => (
          <ContactRecordDrawer
            isNew={contact.id === -1} // because id is initially spoofed as name
            existingInvoice={existingInvoice}
            canRemove={contacts.length > 1}
            toggleFormUnderEdit={toggleFormUnderEdit}
            showPatientOption={!hasPatientContact || contactRecordIsPatient(contact)}
            key={contact.name + (contact.id ?? '-1')}
            contact={contact}
            onSubmit={(c: ContactRecord) => updateContact(c, contact)}
            startOpen={contact.id === -1 || contactRecordIsPatient(contact)}
            onRemove={(c: ContactRecord) => removeContact(c)}
          />
        ))}
        {!addingContact ? (
          <button className={'btn btn-outline-secondary'} type={'button'} onClick={() => setAddingContact(true)}>
            Add another contact
          </button>
        ) : (
          <>
            <ContactRecordDrawer
              existingInvoice={existingInvoice}
              canRemove={false}
              toggleFormUnderEdit={toggleFormUnderEdit}
              isNew={true}
              showPatientOption={!hasPatientContact}
              contact={
                {
                  notifyByMobile: true,
                  notifyByEmail: true,
                } as ContactRecord
              }
              startOpen={true}
              onSubmit={(c: ContactRecord) => {
                const added = addNewContact(c);
                setAddingContact(false);
                return added;
              }}
            />

            <button className={'btn btn-danger float-right'} type={'button'} onClick={() => setAddingContact(false)}>
              Cancel adding contact
            </button>
          </>
        )}
      </Col>
    </Row>
  );
};
