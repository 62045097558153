import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  CCSD_CODE_COST_LIST_REQUEST,
  CCSD_CODE_COST_LIST_RECEIVED,
  CCSD_CODE_COST_LIST_ERROR,
  CCSD_CODE_COST_LIST_SET_PAGE,
  CCSD_CODE_COST_LIST_UNLOAD,
  CCSD_CODE_COST_SINGLE_CREATE_REQUEST,
  CCSD_CODE_COST_SINGLE_CREATE_SUCCESS,
  CCSD_CODE_COST_SINGLE_UPDATED,
  CCSD_CODE_COST_SINGLE_UPDATE_REQUEST,
  CCSD_CODE_COST_SINGLE_REQUEST,
  CCSD_CODE_COST_SINGLE_RECEIVED,
  CCSD_CODE_COST_SINGLE_ERROR,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const ccsdCodeCostListRequest = () => ({
  type: CCSD_CODE_COST_LIST_REQUEST,
});

export const ccsdCodeCostListReceived = (data, append = false) => ({
  type: CCSD_CODE_COST_LIST_RECEIVED,
  data,
  append,
});

export const ccsdCodeCostListError = (error) => ({
  type: CCSD_CODE_COST_LIST_ERROR,
  error,
});

export const ccsdCodeCostListSetPage = (page) => ({
  type: CCSD_CODE_COST_LIST_SET_PAGE,
  page,
});

export const ccsdCodeCostListUnload = () => ({
  type: CCSD_CODE_COST_LIST_UNLOAD,
});

export const ccsdCodeCostListFetch = (page = 1, filters = {}, append = false, itemsPerPage = 100) => {
  return (dispatch) => {
    dispatch(ccsdCodeCostListRequest());
    filters.page = page;
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    if (filters.pagination) {
      filters.itemsPerPage = itemsPerPage;
    }
    return requests
      .get(API_ROUTES.CCSD_CODE_COSTS, filters)
      .then((response) => dispatch(ccsdCodeCostListReceived(response, append)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(ccsdCodeCostListError(error));
      });
  };
};

export const ccsdCodeCostSingleRequest = () => ({
  type: CCSD_CODE_COST_SINGLE_REQUEST,
});

export const ccsdCodeCostSingleReceived = (data) => ({
  type: CCSD_CODE_COST_SINGLE_RECEIVED,
  data,
});

export const ccsdCodeCostSingleError = (error) => ({
  type: CCSD_CODE_COST_SINGLE_ERROR,
  error,
});

export const ccsdCodeCostSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(ccsdCodeCostSingleRequest());
    //console.log('Fetching single consultant ...');
    return requests
      .get(`${API_ROUTES.CCSD_CODE_COSTS}/${id}`)
      .then((response) => dispatch(ccsdCodeCostSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new ccsdCodeCostSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(ccsdCodeCostSingleError(error));
      });
  };
};

export const ccsdCodeCostSingleCreateRequest = () => ({
  type: CCSD_CODE_COST_SINGLE_CREATE_REQUEST,
});

export const ccsdCodeCostSingleCreateSuccess = (data) => ({
  type: CCSD_CODE_COST_SINGLE_CREATE_SUCCESS,
  data,
});

//export const ccsdCodeCostSingleCreateError = (error) => ({
//  type: CCSD_CODE_COST_SINGLE_CREATE_ERROR,
//  error,
//});

export const ccsdCodeCostSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(ccsdCodeCostSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.CCSD_CODE_COSTS}`, data)
      .then((response) => {
        dispatch(ccsdCodeCostSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
          parsedError._error = 'It looks like you may be creating a CCSD code Cost which already exists - please check and try again if not.';
        } else {
          parsedError._error =
            'There was a problem creating the CCSD code Cost - please check the details and try again. If the problem persists please contact support.';
        }
        throw new SubmissionError(parsedError);
      });
  };
};

export const ccsdCodeCostSingleUpdateRequest = () => ({
  type: CCSD_CODE_COST_SINGLE_UPDATE_REQUEST,
});

export const ccsdCodeCostSingleUpdated = (data) => ({
  type: CCSD_CODE_COST_SINGLE_UPDATED,
  data,
});

export const ccsdCodeCostSingleUpdate = (data, ccsdCodeCostId) => {
  return (dispatch) => {
    dispatch(ccsdCodeCostSingleUpdateRequest());
    let endpointURL = `${API_ROUTES.CCSD_CODE_COSTS}/${ccsdCodeCostId}`;
    return requests
      .patch(endpointURL, data)
      .then((response) => {
        dispatch(ccsdCodeCostSingleUpdated(response));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
