import React from 'react';
import Table from 'react-bootstrap/Table';
import * as ROUTES from '../../lib/routing/frontend';
import { Link } from 'react-router-dom';
import { niceRolesFormatter } from '../../lib/helper/formatting';
import clsx from 'clsx';

class UserListTable extends React.Component {
  render() {
    const { users = [] } = this.props;
    return (
      <div>
        <Table striped hover responsive className="user-list-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>User Role</th>
              <th className="text-center"># Inv</th>
              <th className="text-center">Status</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const invoiceCounterClasses = clsx({ 'h5 text-center': true, 'has-invoices': user.numberOfInvoicesCurrentlyProcessing > 1 });
              return (
                <tr key={user.id}>
                  <td>
                    <Link to={ROUTES.USERS.SINGLE.replace(':id', user.id)} className="text-body">
                      {user.firstName} {user.lastName}
                    </Link>
                  </td>
                  <td>{user.email}</td>
                  <td>{niceRolesFormatter(user.roles)}</td>
                  <td className={invoiceCounterClasses}>
                    <span className="invoice-count-wrapper">
                      <span className="badge badge-pill badge-secondary" title="Invoices currently in process">
                        {user.numberOfInvoicesCurrentlyProcessing}
                      </span>
                    </span>
                  </td>
                  <td className="text-center">
                    {user.isEnabled ? (
                      <i className="fa fa-check-circle text-primary" title="Active" />
                    ) : (
                      <i className="fa fa-times-circle text-danger" title="Inactive" />
                    )}
                  </td>
                  <td className="text-sm-left text-md-right">
                    <Link to={ROUTES.USERS.SINGLE.replace(':id', user.id)} className="btn btn-sm btn-primary ml-2">
                      Edit
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default UserListTable;
