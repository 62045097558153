import React, { Component, Fragment } from 'react';
import { Cell, Row as TableRow, StickyTable } from 'react-sticky-table';
import MASpinner from '../common/MASpinner';
import { niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import { isSeniorStaff, isStaff } from '../../lib/helper/authorisation';
import { FormCheck } from 'react-bootstrap';
import { renderField } from '../../lib/helper/form';
import { Field, Form, reduxForm, reset } from 'redux-form';
import { onboardingItemsListPatch } from '../../lib/redux/actions/onboardingItems';
import { connect } from 'react-redux';
import { addToast } from '../../lib/redux/actions/toast';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

const mapDispatchToProps = {
  onboardingItemsListPatch,
  addToast,
};

class ConsultantOnboardingItemsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
    };
  }

  buildFormData = (formValues) => {
    let keys = Object.keys(formValues);
    let data = [];
    let isCompleted = this.state.formData;
    keys.forEach((v, id) => {
      let name = v.split('-')[0];
      let value = formValues[v];
      data.push({
        id: v.split('-')[1],
        [name]: value,
      });
    });
    isCompleted.forEach((v, name) => {
      Object.keys(v).forEach((v) => {
        if (typeof data.find((d) => d.id === v.split('-')[1]) !== 'undefined') {
          data.find((d) => d.id === v.split('-')[1]).isComplete = Boolean(v.split('-')[1]);
        } else {
          data.push({
            id: v.split('-')[1],
            isComplete: Boolean(v.split('-')[1]),
          });
        }
      });
    });
    return data;
  };

  onSubmit = (formValues) => {
    let formData = this.buildFormData(formValues);
    return this.props.onboardingItemsListPatch(formData).then(() => {
      this.props.addToast('Onboarding Items successfully saved.', true);
    });
  };

  handleCompleteCheck = (event) => {
    let obj = { [`${event.target.id}`]: event.target.value };
    if (Object.keys(this.state.formData).length > 0) {
      this.setState(
        {
          formData: [...this.state.formData, obj],
        },
        () => {},
      );
    } else {
      this.setState({ formData: [{ [`${event.target.id}`]: event.target.value }] }, () => {});
    }
  };

  renderCompleteCheck = (onboardingItem) => {
    if (onboardingItem.isComplete || (isStaff(true) && onboardingItem.isComplete)) {
      return <i className="fa fa-check-circle text-primary" />;
    } else if (isStaff(true) && !onboardingItem.isComplete) {
      return <i className="fa fa-times-circle text-danger" />;
    } else {
      return (
        <FormCheck
          type="switch"
          id={`isComplete-${onboardingItem.id}`}
          name={`isComplete-${onboardingItem.id}`}
          onChange={(e) => this.handleCompleteCheck(e)}
        />
      );
    }
  };

  render() {
    const { isFetchingOnboardingItems, onboardingItems } = this.props;
    return (
      <Fragment>
        {this.props.consultant.id <= window.CONSULTANT_PRE_ONBOARDING_ID && (
          <Card.Body>
            <Alert variant={'info'} className="mb-0">
              This consultant was created prior to the onboarding functionality being added
            </Alert>
          </Card.Body>
        )}
        <div className="crud__section mb-3">
          <div className="crud__section-icon">
            <i className="fas fa-list-alt" />
          </div>
          {isFetchingOnboardingItems && <MASpinner wrapperClasses={''} />}
          <h2 className="crud__section-header">
            Onboarding Checklist
            <span className="ml-4 badge badge-info">
              ({onboardingItems.filter((i) => i.isComplete).length}/{onboardingItems.length} Complete)
            </span>
          </h2>
          <Form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
            <div style={{ minWidth: '100%' }}>
              <StickyTable>
                <TableRow>
                  <Cell className="sticky-table-cell border-right">Description</Cell>
                  <Cell className="sticky-table-cell border-right">Assignee Initials</Cell>
                  <Cell className="sticky-table-cell border-right">Notes</Cell>
                  <Cell className="sticky-table-cell border-right">Is Complete</Cell>
                  <Cell className="sticky-table-cell border-right">Date Completed</Cell>
                  <Cell className="sticky-table-cell border-right">Completed By</Cell>
                </TableRow>
                {onboardingItems
                  .sort((a, b) => a.sortOrder < b.sortOrder)
                  .map((onboardingItem) => {
                    return (
                      <TableRow key={onboardingItem.id}>
                        <Cell className="border-right">{onboardingItem.description ?? '-'}</Cell>
                        <Cell className="border-right">{onboardingItem.assigneeInitials ?? '-'}</Cell>
                        <Cell className="border-right">
                          {onboardingItem.isComplete || !isSeniorStaff() ? (
                            <div>{onboardingItem.notes}</div>
                          ) : (
                            <Field
                              name={`notes-${onboardingItem.id}`}
                              id={`notes-${onboardingItem.id}`}
                              rows="3"
                              type="textarea"
                              formLabelClasses={'d-none'}
                              groupClasses={'mb-0'}
                              className={'mt-n2 mb-n2'}
                              component={renderField}
                              placeholder={onboardingItem.notes}
                            />
                          )}
                        </Cell>
                        <Cell className="border-right text-center">{this.renderCompleteCheck(onboardingItem)}</Cell>
                        <Cell className="border-right">{onboardingItem.dateCompleted ? niceDateFormatter(onboardingItem.dateCompleted) : '-'}</Cell>
                        <Cell className="border-right">{onboardingItem.completingUser ? userNameFormatter(onboardingItem.completingUser) : '-'}</Cell>
                      </TableRow>
                    );
                  })}
              </StickyTable>
            </div>
            {isStaff(true) ? (
              <></>
            ) : (
              <button type="submit" className="btn btn-primary btn-block mt-4">
                Update
              </button>
            )}
          </Form>
        </div>
      </Fragment>
    );
  }
}

const afterSubmit = (result, dispatch) => dispatch(reset('consultantOnboardingForm'));

export default reduxForm({
  form: 'consultantOnboardingForm',
  onSubmitSuccess: afterSubmit,
})(connect(null, mapDispatchToProps)(ConsultantOnboardingItemsTable));
