import React from 'react';
import PropTypes from 'prop-types';
import { isSeniorStaff } from '../../lib/helper/authorisation';
import EditNumberOfInvoicesForm from '../forms/InvoiceBatch/EditNumberOfInvoicesForm';
import { hasDiscrepantInvoiceCounts } from '../../lib/helper/invoiceBatch';

class EditableNumberOfInvoicesBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  toggleEditing() {
    if (!isSeniorStaff()) {
      return false;
    }
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing,
    }));
  }

  render() {
    const { invoiceBatch } = this.props;
    if (!invoiceBatch) {
      return null;
    }

    if (!isSeniorStaff()) {
      return (
        <span className={`badge badge-${hasDiscrepantInvoiceCounts(invoiceBatch) ? 'danger' : 'secondary'} badge-pill`}>
          {invoiceBatch.numberOfInvoicesToProcess}
        </span>
      );
    }
    if (this.state.isEditing) {
      return <EditNumberOfInvoicesForm onUpdateSuccess={this.toggleEditing.bind(this)} invoiceBatch={invoiceBatch} />;
    } else {
      return (
        <button
          onClick={this.toggleEditing.bind(this)}
          title="Edit invoice count"
          className={`badge badge-${hasDiscrepantInvoiceCounts(invoiceBatch) ? 'danger' : 'secondary'} badge-pill  invoice-number-editor-badge`}>
          {invoiceBatch.numberOfInvoicesToProcess || 'n/a'}
        </button>
      );
    }
  }
}

EditableNumberOfInvoicesBadge.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
};

export default EditableNumberOfInvoicesBadge;
