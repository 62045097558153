import 'react-app-polyfill/ie11';
import 'airbnb-js-shims';
import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './assets/style/app.scss';

import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './lib/bootstrap/configureStore';
import { createBrowserHistory } from 'history';

if (window) {
  if (!window.API_URL) {
    window.API_URL = 'https://stage-api.mediaccounts.co.uk';
  }
  if (!window.APP_ENV) {
    window.APP_ENV = 'pr';
  }
  if (!window.CONSULTANT_PRE_ONBOARDING_ID) {
    window.CONSULTANT_PRE_ONBOARDING_ID = 120;
  }
}

const store = configureStore();
const history = createBrowserHistory();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (['stage', 'production'].includes(window.APP_ENV)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    async beforeSend(event, hint) {
      if (hint.originalException.name === 'SubmissionError') {
        // attempt ignore Submission errors
        return null;
      }
      return event;
    },

    environment: window.APP_ENV,
    release: process.env.REACT_APP_PORTAL_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.05,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/portal\.mediaccounts\.co\/uk/],

    // Capture Replay for 1% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.005,
    replaysOnErrorSampleRate: 1.0,
  });
}
//const render = (Component) => {
//  ReactDOM.render(
//    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
//      <Provider store={store}>
//        {/*<AppContainer>*/}
//        <Component />
//        {/*</AppContainer>*/}
//      </Provider>
//    </Sentry.ErrorBoundary>,
//    document.getElementById('root'),
//  );
//};
//
//render(App);
//
const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      {/*<AppContainer>*/}
      <App />
      {/*</AppContainer>*/}
    </Provider>
  </Sentry.ErrorBoundary>,
);

//// Webpack Hot Module Replacement API
//if (module.hot) {
//  module.hot.accept('./App', () => {
//    const NextApp = require('./App').default;
//    render(NextApp);
//  });
//}
