import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ExportInvoiceNumbersForm from '../../components/forms/ExportInvoiceNumbers/ExportInvoiceNumbersForm';

class ExportInvoiceNumbers extends React.Component {
  render() {
    const { doExport } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header>Export Invoice Numbers CSV</Card.Header>
              <Card.Body>
                <Row>
                  {/* Left Column - START */}
                  <Col sm={12} md={12} lg={8}>
                    <ExportInvoiceNumbersForm doExport={doExport} />
                    <p className="hint-text mt-3 mb-0">
                      Export of all Completed Status batch invoices with a Completed At date within the range. To export January: 01/01/2020 - 01/02/2020.
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ExportInvoiceNumbers;
