import React from 'react';
import { connect } from 'react-redux';
import { ccsdCodeListFetch, ccsdCodeListUnload } from '../../../lib/redux/actions/ccsdCodes';
import { ccsdCodeCostListFetch, ccsdCodeCostListUnload } from '../../../lib/redux/actions/ccsdCodeCosts';
import MASpinner from '../../common/MASpinner';
//import PropTypes from 'prop-types';
import InvoiceBatchCcsdCodeCostTable from './InvoiceBatchCcsdCodeCostTable';
import { consultantSingleFetch } from '../../../lib/redux/actions/consultant';

const mapStateToProps = (state) => ({
  ...state.consultantSingle,
  ...state.ccsdCodes,
  ...state.ccsdCodeCosts,
});

const mapDispatchToProps = {
  consultantSingleFetch,
  ccsdCodeListFetch,
  ccsdCodeListUnload,
  ccsdCodeCostListFetch,
  ccsdCodeCostListUnload,
};

class InvoiceBatchCcsdCodeCostTableContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      loadedConsultantHealthInsurers: [],
      selectedConsultantHealthInsurerIRI: false,
    };
  }

  setSelectedConsultantHealthInsurer(consultantHealthInsurerId) {
    const iri = '/consultant_health_insurers/' + consultantHealthInsurerId;
    if (!this.state.loadedConsultantHealthInsurers.includes(iri)) {
      this.setState(
        () => ({
          selectedConsultantHealthInsurerIRI: iri,
        }),
        this.loadCcsdCodeCosts,
      );
    }
  }

  componentDidMount() {
    this.props.ccsdCodeListUnload();
    this.props.ccsdCodeCostListUnload();
    this.props.consultantSingleFetch(this.props.consultantId);
  }

  componentWillUnmount() {
    this.props.ccsdCodeListUnload();
    this.props.ccsdCodeCostListUnload();
  }

  loadCcsdCodes() {
    if (!this.props.consultant || !this.props.consultant.consultantHealthInsurers || this.props.consultant.consultantHealthInsurers.length < 1) {
      return false;
    }

    this.props
      .ccsdCodeListFetch(1, {
        pagination: false,
        'ccsdCodeCosts.consultantHealthInsurer': [
          ...this.props.consultant.consultantHealthInsurers.map((chi) => {
            return chi['@id'];
          }),
        ],
      })
      .then(() => {
        this.loadCcsdCodeCosts();
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.nextPage !== false && prevProps.nextPage !== this.props.nextPage) {
      this.loadCcsdCodeCosts(this.props.nextPage);
    }
    if (this.props.consultant !== prevProps.consultant) {
      this.loadCcsdCodes();
    }
  }

  loadCcsdCodeCosts(page = 1) {
    let consultantHealthInsurersToLoad = this.props.consultant.consultantHealthInsurers
      .filter((chi) => chi.healthInsurer.isAnalogue && !chi.healthInsurer.canHaveOverrideLabel)
      .map((chi) => {
        return chi['@id'];
      });
    if (this.state.selectedConsultantHealthInsurerIRI) {
      consultantHealthInsurersToLoad = [this.state.selectedConsultantHealthInsurerIRI];
      this.setState((prevState) => ({
        loadedConsultantHealthInsurers: [...prevState.loadedConsultantHealthInsurers, this.state.selectedConsultantHealthInsurerIRI],
      }));
    }
    this.props
      .ccsdCodeCostListFetch(
        page,
        {
          consultant: this.props.consultant['@id'],
          pagination: false,
          consultantHealthInsurer: consultantHealthInsurersToLoad,
          //      'ccsdCode': this.props.ccsdCodes.map(ccsdCode => {
          //        return ccsdCode['@id'];
          //      }),
        },
        true,
      )
      .then(() => {
        this.setState(() => ({
          selectedConsultantHealthInsurerIRI: false,
        }));
      });
  }

  render() {
    const { consultant, ccsdCodes, ccsdCodeCosts, isFetchingCcsdCodes } = this.props;
    //    if (!this.props.consultant || !this.props.consultant.consultantHealthInsurers || this.props.consultant.consultantHealthInsurers.length < 1) {
    //      return (<p>missing data {JSON.stringify({props:this.props})}</p>);
    //    }
    return (
      <div>
        {isFetchingCcsdCodes && <MASpinner />}

        {!isFetchingCcsdCodes && ccsdCodes && ccsdCodes.length > 0 && consultant && (
          <InvoiceBatchCcsdCodeCostTable
            setSelectedConsultantHealthInsurer={(id) => this.setSelectedConsultantHealthInsurer(id)}
            consultant={consultant}
            ccsdCodes={ccsdCodes}
            ccsdCodeCosts={ccsdCodeCosts}
            reloadCodes={this.loadCcsdCodes.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBatchCcsdCodeCostTableContainer);

//InvoiceBatchCcsdCodeCostTableContainer.propTypes = {
//  //  consultant: PropTypes.object.isRequired,
//  //  consultantId: PropTypes.object.isRequired,
//};
