import React from 'react';
import { connect } from 'react-redux';
import { datedCostListUnload, datedCostListSetPage, datedCostListUpdated, getDatedCostList } from '../../lib/redux/actions/datedCosts';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Paginator from '../common/Paginator';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import DatedCostChangeRow from './DatedCostChangeRow';

const mapStateToProps = (state) => ({
  ...state.datedCostList,
  ...state.auth,
});

const mapDispatchToProps = {
  datedCostListUpdated,
  datedCostListSetPage,
  datedCostListUnload,
  getDatedCostList,
};

class datedCostList extends React.Component {
  componentWillUnmount() {
    this.props.datedCostListUnload();
  }

  componentDidMount() {
    this.loadDatedCostList();
  }

  loadDatedCostList() {
    const { filterByConsultant = false } = this.props;
    const filters = {
      'exists[createdBy]': true,
    };
    if (filterByConsultant !== false) {
      filters['ccsdCodeCost.consultant'] = filterByConsultant['@id'];
    }
    this.props.getDatedCostList(this.getQueryParamPage(), filters);
  }

  componentDidUpdate(prevProps) {
    const { currentPage, getDatedCostList, filterByConsultant = false } = this.props;

    const filters = {
      'exists[createdBy]': true,
    };
    if (filterByConsultant !== false) {
      filters['ccsdCodeCost.consultant'] = filterByConsultant['@id'];
    }
    if (prevProps.currentPage !== currentPage) {
      getDatedCostList(currentPage, filters);
    }
  }

  changePage(page) {
    const { history, datedCostListSetPage, path } = this.props;
    datedCostListSetPage(page);
    if (path) {
      history.push(path.replace(':page', page));
    }
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);

    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  render() {
    const { datedCostList, isFetchingDatedCostList, pageCount, currentPage, filterByConsultant = false } = this.props;
    if (!datedCostList || datedCostList.length < 1) {
      return null;
    }
    return (
      <Card className="mb-4">
        <Card.Header className={'text-center card-header--small-text'}>Consultant CCSD Cost Changes</Card.Header>
        <Card.Body className="dashboard-statistics-wrapper">
          <Table size="sm">
            <thead>
              <tr className="bg-">
                <th className="text-left">Consultant</th>
                <th className="text-left">At</th>
                <th className="text-left">By</th>
                <th className="text-left">What</th>
                {!filterByConsultant && <th className="text-center">Action</th>}
              </tr>
            </thead>
            <tbody>
              {datedCostList.map((datedCost) => (
                <DatedCostChangeRow key={`${String(datedCost.id).toLowerCase()}`} datedCost={datedCost} filterByConsultant={filterByConsultant} />
              ))}
            </tbody>
          </Table>
          {!isFetchingDatedCostList && datedCostList && datedCostList.length > 0 && (
            <Paginator
              currentPage={currentPage}
              pageCount={pageCount}
              setPage={this.changePage.bind(this)}
              nextPage={this.nextPage.bind(this)}
              prevPage={this.prevPage.bind(this)}
              withCard={false}
            />
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(datedCostList);
