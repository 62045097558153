import React from 'react';
import WordpressPage from '../components/common/WordpressPage';
import Card from 'react-bootstrap/Card';

class SupportPage extends React.Component {
  render() {
    return (
      <Card className="support-card mt-4 mb-4">
        {/*<Card.Header className="text-center">*/}
        {/*  /!*Support*!/*/}
        {/*</Card.Header>*/}
        <Card.Body>
          <WordpressPage pageId={1003} showAlert={false} showHr={false} />
        </Card.Body>
      </Card>
      //            <h1>Common issue resolution</h1>
      //            <div className="text-left">
      //              <div className="step">
      //                <p>Are you having problems using the MediAccounts portal? Please follow the instructions below to assist our technical support team.</p>
      //              </div>
      //              <div className="step">
      //                <h4>Step 1: Hard Refreshing</h4>
      //                <h5>Chromium based browsers:</h5>
      //                <p>Includes Google Chrome, Microsoft Edge, Brave Browser, Opera, etc...</p>
      //                <p>
      //                  <strong>Windows/Linux</strong>: Press Ctrl + F5 or Shift + Ctrl + R.
      //                  <strong>Mac</strong>: Press Cmd + Shift + R.
      //                </p>
      //                <h5>Mozilla Firefox:</h5>
      //                <p>
      //                  <strong>Windows/Linux:</strong> Press Ctrl + F5 or Ctrl + Shift + R.
      //                </p>
      //                <p>
      //                  <strong>Mac:</strong> Press Cmd + Shift + R.
      //                </p>
      //
      //                <h5>Safari:</h5>
      //                <p>
      //                  <strong>Mac:</strong> Press Cmd + Option + R.
      //                </p>
      //              </div>
      //              <hr/>
      //              <div className="step">
      //                <h4>Step 2: Log out and Log back in</h4>
      //                <p>Click the Log out button to the top right of the screen. Log back in to the portal and see if your problem has been resolved.</p>
      //              </div>
      //              <hr/>
      //              <div className="step">
      //                <h4>Step 3: Common issues</h4>
      //                <p className="font-weight-bold">
      //                  Is your 2FA not working?
      //                </p>
      //                <p>
      //                  If you are using the QR code, please ensure that you are logged in to your Authentication app on the same device that you registered for two-factor authentication. If you cannot access, you will need to reset your
      //                  two-factor codes. Email <a href="mailto:info@mediaccounts.co.uk">info@mediaccounts.co.uk</a> requesting two-factor reset. You will need to confirm this via a telephone call - one of our staff will
      //                  contact you in due course.
      //                </p>
      //                <p>
      //                  If you are using the email code, but no email has been received - please wait up to 5 minutes and check your Spam folder. If no email is still received please contact us.
      //                </p>
      //                <p>
      //                  If you have triggered multiple emails - only the latest email code will be valid.
      //                </p>
      //                <p className="font-weight-bold">
      //                  What to do if you have a blank screen?
      //                </p>
      //                <p>
      //                  If you can see a log out button in the top bar, click this. Then please try Hard Refreshing as above, or proceed to Step 4 and identify your browser and contact Mediaccounts.
      //                </p>
      //                <strong>
      //                  What if it says your password is incorrect or your account is not active?
      //                </strong>
      //                <p>
      //                  Please check your password carefully - it is case-sensitive, and always type it rather than copy and pasting to ensure that no extra characters or spaces are included. If you’ve confirmed that you are using the expected
      //                  password, you can reset your password via the link below.
      //                </p>
      //                <Link target="_blank" to={'/forgot-password'}>
      //                  <img className="img-fluid" src="/images/reset-password.webp" alt="Reset Password"/>
      //                </Link>
      //                <p className={'font-weight-bold'}>How to reset password?</p>
      //                <p>Click here to <Link to={'/forgot-password'}>reset your password</Link>. Enter your email address and click "RESET PASSWORD".</p>
      //                <p>
      //                  You will receive an email with a link to click to update your password. This link is valid for 1 day. Once you click the link, enter your new password, and then proceed to log in again.
      //                </p>
      //                <img className="img-fluid" src="/images/resetpassword-email.webp" alt="Reset Password"/>
      //              </div>
      //              <hr/>
      //              <div className="step">
      //                <h4>Step 4: Identify your browser and operating system</h4>
      //                <p>
      //                  Visit <a target="_blank" rel="noopener noreferrer"  href="https://www.whatsmybrowser.org/">https://www.whatsmybrowser.org/</a> and copy the share link provided
      //                </p>
      //                <div>
      //                  <a target="_blank" rel="noopener noreferrer"  href="https://www.whatsmybrowser.org/">
      //                    <img className="img-fluid" src="/images/whatsmybrowser.webp" alt="What's my browser?"/>
      //                  </a>
      //                </div>
      //                <p>
      //                  Send an email to <a href="mailto:info@mediaccounts.co.uk">info@mediaccounts.co.uk</a> with the link, a description of your problem and confirmation that you&apos;ve attempted Step 1 and Step 2. Attach screenshots or screen
      //                  recordings that may help our technical team to diagnose your issue.
      //                </p>
      //              </div>
      //            </div>
      //          </Card.Body>
      //        </Card>
    );
  }
}

export default SupportPage;
