import React from 'react';
import Table from 'react-bootstrap/Table';
import { niceDateFormatter } from '../../lib/helper/formatting';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';

class BillingChecklistEventListTable extends React.Component {
  render() {
    const { billingChecklistEvents } = this.props;

    return (
      <div>
        <Table striped hover responsive className="mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Consultant</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {billingChecklistEvents.map((billingChecklistEvent) => {
              return (
                <tr key={billingChecklistEvent.id}>
                  <td>
                    <strong>{billingChecklistEvent.name}</strong>
                  </td>
                  <td>{niceDateFormatter(billingChecklistEvent.startDate, 'DD/MM/YYYY')}</td>
                  <td>
                    {billingChecklistEvent.endDate ? (
                      <>{niceDateFormatter(billingChecklistEvent.endDate, 'DD/MM/YYYY')}</>
                    ) : (
                      <>{niceDateFormatter(billingChecklistEvent.startDate, 'DD/MM/YYYY')}</>
                    )}
                  </td>
                  <td>{billingChecklistEvent.consultant ? <strong>{billingChecklistEvent.consultant.name}</strong> : 'Global'}</td>
                  <td className="text-sm-left text-md-right">
                    <Link to={ROUTES.BILLING_CHECKLIST_EVENTS.SINGLE.replace(':id', billingChecklistEvent.id)} className="btn btn-sm btn-primary ml-2">
                      Edit
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default BillingChecklistEventListTable;
