import { CONTACT_RECORD_TYPE_LIST_REQUEST, CONTACT_RECORD_TYPE_LIST_RECEIVED, CONTACT_RECORD_TYPE_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    contactRecordTypes: [],
    isFetchingContactRecordTypes: false,
  },
  action,
) => {
  switch (action.type) {
    case CONTACT_RECORD_TYPE_LIST_REQUEST:
      return {
        ...state,
        isFetchingContactRecordTypes: true,
      };

    case CONTACT_RECORD_TYPE_LIST_RECEIVED:
      return {
        ...state,
        contactRecordTypes: action.data['hydra:member'],
        isFetchingContactRecordTypes: false,
      };

    case CONTACT_RECORD_TYPE_LIST_ERROR:
      return {
        ...state,
        isFetchingContactRecordTypes: false,
      };
    default:
      return state;
  }
};

export default reducer;
