import React from 'react';
import HospitalListTable from './HospitalListTable';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { hospitalListFetch, hospitalListSetPage } from '../../lib/redux/actions/hospitals';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import * as ROUTES from '../../lib/routing/frontend';
import MASpinner from '../../components/common/MASpinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreateOrEditHospitalForm from '../../components/forms/Hospital/CreateOrEditHospitalForm';

const mapStateToProps = (state) => ({
  ...state.hospitals,
});

const mapDispatchToProps = {
  hospitalListFetch,
  hospitalListSetPage,
};

class HospitalListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      selectedHospital: null,
    };
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    return { ...filters, ...defaultFilters };
  }

  componentDidMount() {
    this.props.hospitalListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  componentDidUpdate(prevProps) {
    const { currentPage, hospitalListFetch, hospitalListSetPage, filters } = this.props;

    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      hospitalListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      hospitalListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, hospitalListSetPage } = this.props;
    hospitalListSetPage(page);
    history.push(ROUTES.HOSPITALS.ALL.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  render() {
    const { hospitals, isFetchingHospitals, currentPage, pageCount, pageTitle } = this.props;
    const setSelectedHospital = (selectedHospital) => {
      this.setState(() => {
        return { selectedHospital: selectedHospital };
      });
    };
    return (
      <div>
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              {' '}
              {pageTitle}
              {!isFetchingHospitals && hospitals && hospitals.length > 0 && (
                <div>
                  <span className="tabular-data-result-count">{pageCount} pages</span>
                </div>
              )}
            </div>
          </Card.Header>

          {isFetchingHospitals && (
            <Card.Body>
              <MASpinner />
            </Card.Body>
          )}

          {!isFetchingHospitals && (hospitals === null || hospitals.length === 0) && (
            <Card.Body>
              <Alert variant={'info'} className="mb-0">
                No hospitals found
              </Alert>
            </Card.Body>
          )}
          {!isFetchingHospitals && hospitals && hospitals.length > 0 && (
            <Row>
              <Col sm={12} md={12} lg={8}>
                <Row>
                  <Col>
                    <HospitalListTable hospitals={hospitals} selectedHospital={this.state.selectedHospital} selectHospital={setSelectedHospital} />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <Row>
                  <Col>
                    <div className="p-4">
                      <CreateOrEditHospitalForm
                        selectedHospital={this.state.selectedHospital}
                        clearSelected={() => {
                          setSelectedHospital(null);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {!isFetchingHospitals && hospitals && hospitals.length > 0 && (
            <Paginator
              currentPage={currentPage}
              pageCount={pageCount}
              setPage={this.changePage.bind(this)}
              nextPage={this.nextPage.bind(this)}
              prevPage={this.prevPage.bind(this)}
            />
          )}
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalListContainer);
