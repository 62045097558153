import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CreateConsultantTicketForm from '../../components/forms/ConsultantTicket/CreateConsultantTicketForm';

class ConsultantTicketCreate extends Component {
  render() {
    const { consultant } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col>
                    <CreateConsultantTicketForm consultant={consultant} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantTicketCreate;
