import React from 'react';
import { Row, Cell } from 'react-sticky-table';
import EditableCcsdCodeCost from './EditableCcsdCodeCost';
import CcsdCodeCostAndTimelineIcon from './CcsdCodeCostAndTimelineIcon';
import { CCSD_CODE_TYPE_PROCEDURE_CODE } from '../../lib/helper/general';
import { capitalizeWords } from '../../lib/helper/formatting';

class StickyCcsdCodeCostRow extends React.Component {
  getRelevantCcsdCodeCost(healthInsurer) {
    const { ccsdCodeCosts, ccsdCode } = this.props;

    const relevantCcsdCodeCost = ccsdCodeCosts.find((ccsdCodeCost) => {
      return ccsdCodeCost.ccsdCode.id === ccsdCode.id && ccsdCodeCost.consultantHealthInsurer.healthInsurer.id === healthInsurer.id;
    });

    return relevantCcsdCodeCost
      ? relevantCcsdCodeCost
      : {
          id: false,
          consultantHealthInsurer: {
            feeAssured: false,
          },
          currentCosts: [
            {
              cost: false,
              validFrom: new Date(2021, 7, 1),
            },
          ],
        };
  }

  //  getRelevantDefaultCcsdCodeCost(healthInsurer) {
  //    const { ccsdCode } = this.props;
  //    const relevantCcsdCodeCost = ccsdCode?.defaultCcsdCodeCostsWithCosts.find(ccsdCodeCost => {
  //      return ccsdCodeCost.healthInsurer.id === healthInsurer.id;
  //    });
  //    return relevantCcsdCodeCost ? relevantCcsdCodeCost : {
  //      id: false,
  //      healthInsurer: {
  //        feeAssured: false,
  //      },
  //      currentCosts: [
  //        {
  //          cost: false,
  //          validFrom: new Date(2021, 7, 1),
  //        },
  //      ],
  //    };
  //  }

  render() {
    const { ccsdCode, consultantType, consultantHealthInsurers = [] } = this.props;
    return (
      <Row>
        <Cell
          className="sticky-table-cell batch-invoice-ccsd-code-title-column font-weight-bold"
          title={ccsdCode.name && ccsdCode.name.length > 0 ? ccsdCode.name : ''}>
          {ccsdCode.name}
          <br />
          <span className="font-weight-normal">{ccsdCode.reference}</span> <small>({capitalizeWords(String(ccsdCode.ccsdCodeType.code))} Code)</small>
        </Cell>
        {consultantHealthInsurers.map((consultantHealthInsurer) => {
          /* Only Procedure codes for fee assured relations are shared costs. i.e. you can edit other types than procedure */
          if (consultantHealthInsurer.feeAssured && String(ccsdCode.ccsdCodeType.code) === String(CCSD_CODE_TYPE_PROCEDURE_CODE)) {
            const ccsdCodeCostToUse = this.getRelevantCcsdCodeCost(consultantHealthInsurer.healthInsurer); //this.getRelevantDefaultCcsdCodeCost(consultantHealthInsurer.healthInsurer);
            return (
              <Cell
                key={`${ccsdCode.id}-${consultantHealthInsurer.healthInsurer.id}`}
                className="text-right border-right"
                title={`Edit ${ccsdCode.reference} for ${consultantHealthInsurer.healthInsurer.name}`}>
                <CcsdCodeCostAndTimelineIcon consultantType={consultantType} ccsdCodeCostToUse={ccsdCodeCostToUse} />
              </Cell>
            );
          } else {
            return (
              <EditableCcsdCodeCost
                consultantType={consultantType}
                consultantHealthInsurer={consultantHealthInsurer}
                isCell={true}
                key={`${ccsdCode.id}-${consultantHealthInsurer.healthInsurer.id}`}
                ccsdCode={ccsdCode}
                ccsdCodeCost={this.getRelevantCcsdCodeCost(consultantHealthInsurer.healthInsurer)}
                healthInsurer={consultantHealthInsurer.healthInsurer}
              />
            );
          }
        })}
      </Row>
    );
  }
}

export default StickyCcsdCodeCostRow;
