import React, { useEffect, useState } from 'react';
import { listFormatter, niceDateTimeFormatter } from '../../lib/helper/formatting';
import { requests } from '../../lib/helper/agent';
import * as API_ROUTES from '../../lib/routing/api';

const EmailAuditLogList = ({ id, entityType }) => {
  const [recipients, setRecipients] = useState([]);
  const [emailAuditLog, setEmailAuditLog] = useState([]);

  useEffect(() => {
    requests.get(`${API_ROUTES.EMAIL_AUDIT_LOG}`, { entity_id: id, entity_type: `App\\Entity\\${entityType}` }).then((response) => {
      setEmailAuditLog(response['hydra:member']);
    });
  }, [id, entityType]);

  useEffect(() => {
    let emailLogRecipients = [];
    if (emailAuditLog.length > 0) {
      emailAuditLog.forEach((emailLog) => {
        if (emailLog.entity_id === id) {
          emailLogRecipients.push(emailLog.recipient + ' - ' + niceDateTimeFormatter(emailLog.sent_at));
        }
      });
      setRecipients(emailLogRecipients);
    }
  }, [emailAuditLog, id]);

  if (recipients.length > 0) {
    return (
      <div className="my-2">
        &ndash; Sent to: <span>{listFormatter.format(recipients)}</span>
      </div>
    );
  }
  return null;
};

export default EmailAuditLogList;
