import React from 'react';
import UserTypeahead from './UserTypeahead';

class PrimaryUserTypeahead extends React.Component {
  render() {
    const { label = 'Primary User', name = 'primaryUser', ...rest } = this.props;
    return <UserTypeahead label={label} name={name} defaultFilters={{ roles: 'ROLE_PRIMARY_USER' }} {...rest} />;
  }
}

export default PrimaryUserTypeahead;
