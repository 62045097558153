import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import {
  billingChecklistEventListFetch,
  billingChecklistEventListSetPage,
  billingChecklistEventListUnload,
} from '../../lib/redux/actions/billingChecklistEvents';
import MASpinner from '../../components/common/MASpinner';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import * as ROUTES from '../../lib/routing/frontend';
import BillingChecklistEventTableFilterForm from '../../components/forms/BillingChecklistEvent/BillingChecklistEventTableFilterForm';
import BillingChecklistEventListTable from './BillingChecklistEventListTable';

const mapStateToProps = (state) => ({
  ...state.billingChecklistEvents,
});

const mapDispatchToProps = {
  billingChecklistEventListFetch,
  billingChecklistEventListSetPage,
  billingChecklistEventListUnload,
};

class BillingChecklistEventListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
    };
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    return { ...filters, ...defaultFilters };
  }

  componentWillUnmount() {
    this.props.billingChecklistEventListUnload();
  }

  componentDidMount() {
    this.props.billingChecklistEventListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  componentDidUpdate(prevProps) {
    const { currentPage, billingChecklistEventListFetch, billingChecklistEventListSetPage, filters } = this.props;

    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      billingChecklistEventListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      billingChecklistEventListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, billingChecklistEventListSetPage } = this.props;
    billingChecklistEventListSetPage(page);
    history.push(ROUTES.BILLING_CHECKLIST_EVENTS.ALL.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  render() {
    const { billingChecklistEvents, isFetchingBillingChecklistEvents, pageTitle, pageCount, currentPage } = this.props;

    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            {' '}
            {pageTitle}
            {!isFetchingBillingChecklistEvents && billingChecklistEvents && billingChecklistEvents.length > 0 && (
              <div>
                <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                  Filters
                </button>
                <span className="tabular-data-result-count">{pageCount} pages</span>
              </div>
            )}
          </div>
        </Card.Header>

        {this.state.showFilters && (
          <Card.Body className={'mediaccount-filter-wrapper'}>
            <BillingChecklistEventTableFilterForm />
          </Card.Body>
        )}

        {isFetchingBillingChecklistEvents && (
          <Card.Body>
            <MASpinner />
          </Card.Body>
        )}

        {!isFetchingBillingChecklistEvents && (billingChecklistEvents === null || billingChecklistEvents.length === 0) && (
          <Card.Body>
            <Alert variant={'info'} className="mb-0">
              No Events found
            </Alert>
          </Card.Body>
        )}

        {!isFetchingBillingChecklistEvents && billingChecklistEvents && billingChecklistEvents.length > 0 && (
          <BillingChecklistEventListTable billingChecklistEvents={billingChecklistEvents} />
        )}
        {!isFetchingBillingChecklistEvents && billingChecklistEvents && billingChecklistEvents.length > 0 && (
          <Paginator
            currentPage={currentPage}
            pageCount={pageCount}
            setPage={this.changePage.bind(this)}
            nextPage={this.nextPage.bind(this)}
            prevPage={this.prevPage.bind(this)}
          />
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingChecklistEventListContainer);
