import React, { Component, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import CreateConsultantTicketNoteForm from '../../components/forms/ConsultantTicket/CreateConsultantTicketNoteForm';
import MarkAsActionedButton from '../../components/consultantTicket/MarkAsActionedButton';
import Badge from 'react-bootstrap/Badge';
import EmailAuditLogList from '../../components/emailAuditLog/EmailAuditLogList';

function ConsultantTicketNote({ consultantTicketNote }) {
  return (
    <div>
      <p>
        <strong>{userNameFormatter(consultantTicketNote.createdBy)} replied:</strong>
      </p>
      <p className="consultant-ticket-single__note-content font-italic">
        {consultantTicketNote?.note.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </p>
      <EmailAuditLogList id={consultantTicketNote.id} entityType="ConsultantTicketNote" />
    </div>
  );
}

class ConsultantTicketSingle extends Component {
  render() {
    const { consultantTicket, onNoteCreate } = this.props;

    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="consultant-ticket-single__meta">
                    <span className="d-none d-sm-inline">Consultant Ticket ID</span>:{' '}
                    <strong className="consultant-ticket-single__id">{consultantTicket.id}</strong>
                  </span>

                  <span className="ml-auto consultant-ticket-single__statuses">
                    {consultantTicket?.actionedDate ? (
                      <Badge
                        pill
                        variant={'primary'}
                        className="ml-2"
                        title={`${niceDateFormatter(consultantTicket.actionedBy)} by ${userNameFormatter(consultantTicket.actionedBy)}`}>
                        <strong className="consultant-ticket-single__id text-uppercase">Actioned</strong>
                      </Badge>
                    ) : (
                      <Badge pill variant={'light'} className="ml-2">
                        <strong className="consultant-ticket-single__id text-uppercase">Unactioned</strong>
                      </Badge>
                    )}
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="consultant-ticket-single__consultant-name">
                      {consultantTicket.consultant.name}
                      <span className="consultant-ticket-single__consultant-id ml-3">{consultantTicket.consultant.code}</span>
                      {consultantTicket.consultant.gmcReferenceNumber && (
                        <span className="consultant-ticket-single__consultant-id ml-3">GMC: {consultantTicket.consultant.gmcReferenceNumber}</span>
                      )}
                    </h1>
                    <h2 className="consultant-ticket-single__date">
                      Created {niceDateFormatter(consultantTicket.createdAt, 'DD/MM/YYYY HH:mm')}{' '}
                      {consultantTicket.createdBy && `by ${userNameFormatter(consultantTicket.createdBy)}`}
                    </h2>
                    <h2 className="consultant-ticket-single__date">
                      Ticket Type: <strong>{consultantTicket.consultantTicketType.displayName}</strong>
                    </h2>
                    {consultantTicket.actionDate ? (
                      <h2 className="consultant-ticket-single__date">Action Date: {niceDateFormatter(consultantTicket.createdAt, 'DD/MM/YYYY')}</h2>
                    ) : null}

                    <hr />

                    <div className="crud__section mb-3">
                      <div className="invoice-batch-single__section-icon">
                        <i className="fas fa-ticket-alt" />
                      </div>
                      <h4 className="pl-3 pb-2">Ticket Details:</h4>
                      <p className="consultant-ticket-single__note-content font-italic">
                        {consultantTicket &&
                          consultantTicket?.message &&
                          consultantTicket?.message.split('\n').map((item, key) => {
                            return (
                              <Fragment key={key}>
                                {item}
                                <br />
                              </Fragment>
                            );
                          })}
                      </p>
                      <EmailAuditLogList id={consultantTicket.id} entityType="ConsultantTicket" />
                    </div>
                    {consultantTicket?.consultantTicketNotes?.length >= 1 &&
                      consultantTicket?.consultantTicketNotes.map((note) => (
                        <Fragment key={note.id}>
                          <hr />
                          <ConsultantTicketNote consultantTicketNote={note} />
                        </Fragment>
                      ))}
                    <hr />
                    <CreateConsultantTicketNoteForm consultantTicket={consultantTicket} onCreate={onNoteCreate} />
                    <p className={'text-right'}>
                      <MarkAsActionedButton consultantTicket={consultantTicket} />
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ConsultantTicketSingle;
