import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import * as ROUTES from '../../lib/routing/frontend';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const PaymentResponseContainer = ({ invoice }) => {
  const params = useParams();
  useEffect(() => {
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, []);

  const { response } = params;

  const getContent = () => {
    switch (response) {
      case 'success':
        return (
          <>
            <p>Your payment was successful you will receive a receipt via email.</p>
            <p>
              If you require a medical receipt{' '}
              <a
                rel="noreferrer"
                target={'_blank'}
                href={`mailto:payments@mediaccounts.co.uk?subject=${`Request Medical Receipt for ${invoice?.invoiceNo} ${invoice?.consultant?.name ? '- ' + invoice.consultant.name : ''}`}`}>
                please email us here
              </a>
            </p>
          </>
        );
      case 'failure':
        return (
          <>
            Unfortunately your payment was unsuccessful.
            <br />
            Please contact us or <Link to={ROUTES.PUBLIC_PATIENT_INVOICES.VIEW.replace(':uniqueRef', invoice?.uniqueRef)}>try again</Link>.
          </>
        );
      default:
        return (
          <>
            We are currently waiting for a response about your payment from our payment provider. If it was successful you will receive a receipt via email.
            <br />
            Otherwise please contact us or <Link to={ROUTES.PUBLIC_PATIENT_INVOICES.VIEW.replace(':uniqueRef', invoice?.uniqueRef)}>try again</Link>.
          </>
        );
    }
  };
  return (
    <Container>
      <Row>
        <Col
          className="pt-4"
          xs={12}
          md={12}
          lg={{
            span: 8,
            offset: 2,
          }}>
          <Card className="patient-invoice-details">
            <Card.Header>Payment {response === 'success' ? 'Successful' : response === 'failure' ? 'Failed' : 'Processing'}!</Card.Header>
            <Card.Body>{getContent()}</Card.Body>
          </Card>
          {response !== 'success' ? (
            <Card className="patient-invoice-details">
              <Card.Header>Other ways to pay</Card.Header>
              <Card.Body>
                <p>Payment Instructions: Pay by BACS</p>
                {invoice && invoice.consultant && (
                  <>
                    {invoice?.consultant?.name ? <p>Payee: MediAccounts Ltd {invoice.consultant.name} Client Deposit Manager</p> : null}
                    {invoice?.consultant?.clientSortCode ? <p>Sort Code: {invoice.consultant.clientSortCode}</p> : null}
                    {invoice?.consultant?.clientAccountNumber ? <p>Account Number: {invoice?.consultant?.clientAccountNumber}</p> : null}
                  </>
                )}
                <p>PLEASE NOTE THIS IS A BUSINESS ACCOUNT</p>
                <p>
                  Reference: Please use the Invoice No. <strong>{invoice?.invoiceNo}</strong>
                </p>
                <p>
                  Please call 020 3637 0038 or go to the website{' '}
                  <a target={'_blank'} href="https://www.mediaccounts.co.uk/" rel="noreferrer">
                    https://www.mediaccounts.co.uk/
                  </a>
                </p>
                <p>Cheques are no longer accepted.</p>
                <p>Payment terms: Please note payment is now due.</p>
              </Card.Body>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.invoices,
  };
};

export default connect(mapStateToProps, null)(PaymentResponseContainer);
