import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import auth from './reducers/auth';
import invoiceBatchList from './reducers/invoiceBatchList';
import invoiceDate from './reducers/invoiceDate';
import invoiceBatchStatusList from './reducers/invoiceBatchStatusList';
import consultantList from './reducers/consultantList';
import consultantTypes from './reducers/consultantTypes';
import consultantsTypeaheadList from './reducers/consultantsTypeaheadList';
import invoiceBatchSingle from './reducers/invoiceBatchSingle';
import invoiceBatchFiles from './reducers/invoiceBatchFiles';
import invoiceBatchUrgentCategoryList from './reducers/invoiceBatchUrgentCategoryList';
import invoiceBatchProblemCategoryList from './reducers/invoiceBatchProblemCategoryList';
import reportBatchSingle from './reducers/reportBatchSingle';
import reportsList from './reducers/reportsList';
import userList from './reducers/userList';
import toast from './reducers/toast';
import stats from './reducers/stats';
import consultantSingle from './reducers/consultantSingle';
import userSingle from './reducers/userSingle';
import subAccountInviteSingle from './reducers/subAccountInviteSingle';
import billingChecklist from './reducers/billingChecklist';
import billingChecklistWeeklyEvents from './reducers/billingChecklistWeeklyEvents';
import subUserSingle from './reducers/subUserSingle';
import hospitals from './reducers/hospitals';
import healthInsurer from './reducers/healthInsurer';
import consultantBibleLog from './reducers/consultantBibleLog';
import consultantTickets from './reducers/consultantTickets';
import consultantTicketTypes from './reducers/consultantTicketTypes';
import ccsdCodes from './reducers/ccsdCodes';
import ccsdCodesTypeahead from './reducers/ccsdCodesTypeahead';
import ccsdCodeCosts from './reducers/ccsdCodeCosts';
import ccsdCodeTypes from './reducers/ccsdCodeTypes';
import datedCostList from './reducers/datedCostList';
import cmsContent from './reducers/cmsContent';
import onboardingItems from './reducers/onboardingItems';
import validateInvoiceNumbers from './reducers/validateInvoiceNumbers';
import workSchedule from './reducers/workSchedule';
import problemTypes from './reducers/problemTypes';
import problemStatuses from './reducers/problemStatuses';
import problemDataList from './reducers/problemDataList';
import problemDataSingle from './reducers/problemDataSingle';
import problemDataInsurers from './reducers/problemDataInsurers';
import upliftData from './reducers/upliftData';
import invoiceBatchWeeklyCounts from './reducers/invoiceBatchWeeklyCounts';
import billingChecklistEvents from './reducers/billingChecklistEvents';
import workScheduleEvents from './reducers/workScheduleEvents';
import workScheduleWeeklyEvents from './reducers/workScheduleWeeklyEvents';
import invoices from './reducers/invoices';
import invoiceFiles from './reducers/invoiceFiles';
import invoicePayorTypeList from './reducers/invoicePayorTypeList';
import invoiceMissingDetailsTypeList from './reducers/invoiceMissingDetailsTypeList';
import chaserWorkSchedule from './reducers/chaserWorkSchedule';
import invoiceActionStats from './reducers/invoiceActionStats';
import invoiceEscalationReason from './reducers/invoiceEscalationReason';
import invoiceEscalationStatus from './reducers/invoiceEscalationStatus';
import consultantTicketUsersTypeaheadList from './reducers/consultantTicketUsersTypeaheadList';
import emailAuditLogList from './reducers/emailAuditLogList';
import paymentLink from './reducers/paymentLink';
import siteSettings from './reducers/siteSettings';
import contactRecords from './reducers/contactRecords';
import contactRecordTypes from './reducers/contactRecordTypes';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth,
    billingChecklist,
    billingChecklistWeeklyEvents,
    consultantSingle,
    consultantsTypeaheadList,
    consultantList,
    consultantTypes,
    consultantTickets,
    consultantTicketTypes,
    ccsdCodes,
    ccsdCodeTypes,
    ccsdCodeCosts,
    ccsdCodesTypeahead,
    datedCostList,
    hospitals,
    healthInsurer,
    invoiceDate,
    invoiceFiles,
    invoiceBatchFiles,
    invoiceBatchSingle,
    invoiceBatchList,
    invoiceBatchStatusList,
    invoiceBatchUrgentCategoryList,
    invoiceBatchProblemCategoryList,
    reportBatchSingle,
    reportsList,
    subAccountInviteSingle,
    subUserSingle,
    userList,
    userSingle,
    consultantBibleLog,
    toast,
    stats,
    cmsContent,
    onboardingItems,
    validateInvoiceNumbers,
    workSchedule,
    problemTypes,
    problemStatuses,
    problemDataList,
    problemDataSingle,
    upliftData,
    problemDataInsurers,
    invoiceBatchWeeklyCounts,
    billingChecklistEvents,
    workScheduleEvents,
    workScheduleWeeklyEvents,
    invoices,
    invoicePayorTypeList,
    invoiceEscalationReason,
    invoiceEscalationStatus,
    invoiceMissingDetailsTypeList,
    chaserWorkSchedule,
    invoiceActionStats,
    consultantTicketUsersTypeaheadList,
    emailAuditLogList,
    paymentLink,
    siteSettings,
    contactRecords,
    contactRecordTypes,
  });

export default createRootReducer;
