import React from 'react';
import { connect } from 'react-redux';
import { getInvoiceActionStats } from '../../lib/redux/actions/invoiceActionStats';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import MASpinner from '../common/MASpinner';
import { compose } from 'redux';
import { withRouter } from 'react-router';

const moment = extendMoment(Moment);

const mapStateToProps = (state) => ({
  ...state.invoiceActionStats,
  ...state.auth,
  ...state.isFetchingInvoiceActionStats,
});

const mapDispatchToProps = {
  getInvoiceActionStats,
};

class InvoiceActionStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(moment()).format('DD-MM-YYYY'),
    };
  }

  nextWeek() {
    const nextDate = moment(this.state.date, 'DD-MM-YYYY').add(7, 'day').format('DD-MM-YYYY');
    this.setState({ date: nextDate }, this.loadInvoiceActionStats);
  }

  prevWeek() {
    const prevDate = moment(this.state.date, 'DD-MM-YYYY').subtract(7, 'day').format('DD-MM-YYYY');
    this.setState({ date: prevDate }, this.loadInvoiceActionStats);
  }

  componentDidMount() {
    this.loadInvoiceActionStats();
  }

  loadInvoiceActionStats() {
    const { getInvoiceActionStats, user } = this.props;
    const filters = {};

    if (user) {
      filters.user = user.id;
    }

    if (this.state.date) {
      filters.date = this.state.date;
    }

    getInvoiceActionStats(filters);
  }

  render() {
    const { invoiceActionStats, isFetchingInvoiceActionStats } = this.props;
    return (
      <>
        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <div>
              <div className="btn-group btn-group--pagination d-none d-sm-inline-flex" role="group">
                <button type="button" className="btn btn-outline-secondary btn-sm" title="Previous week" onClick={() => this.prevWeek()}>
                  &larr;
                </button>
                <button type="button" className="btn btn-outline-secondary btn-sm" title="Next week" onClick={() => this.nextWeek()}>
                  &rarr;
                </button>
              </div>
            </div>
            <span className="mediaccounts-billing-checklist__week-title d-none d-sm-inline-flex">
              Week Start {moment(this.state.date, 'DD-MM-YYYY').startOf('isoWeek').isoWeekday(1).format('DD-MM-YYYY')}
            </span>
          </div>
        </div>
        {isFetchingInvoiceActionStats ? (
          <MASpinner />
        ) : (
          <div>
            {invoiceActionStats && invoiceActionStats.actions_performed && Object.keys(invoiceActionStats).length ? (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Action</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(invoiceActionStats.actions_performed).map(([key, count]) => (
                    <tr key={key + count}>
                      <th>{key}</th>
                      <td width="30%">{count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Error retrieving stats</p>
            )}
            {invoiceActionStats && invoiceActionStats.actions_requested && Object.keys(invoiceActionStats).length ? (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Requested</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(invoiceActionStats.actions_requested).map(([key, count]) => (
                    <tr key={key + count}>
                      <th>{key}</th>
                      <td width="30%">{count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Error retrieving stats</p>
            )}
          </div>
        )}
      </>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(InvoiceActionStats);
