import { generalCurrencyFormatter, niceDateTimeFormatter, userNameFormatter } from '../../lib/helper/formatting';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import React from 'react';
import * as ROUTES from '../../lib/routing/frontend';

function DatedCostChangeRow(props) {
  const consultantName = props?.datedCost?.ccsdCodeCost?.consultantHealthInsurer?.consultant?.name ?? 'All (fee assured)';
  const consultantId = props?.datedCost?.ccsdCodeCost?.consultantHealthInsurer?.consultant?.id ?? false;
  const ccsdCode = props?.datedCost?.ccsdCodeCost?.ccsdCode ?? props?.datedCost?.defaultCcsdCodeCost?.ccsdCode;
  const healthInsurer = props?.datedCost?.ccsdCodeCost?.consultantHealthInsurer?.healthInsurer ?? props?.datedCost?.defaultCcsdCodeCost?.healthInsurer;

  if (!ccsdCode || !healthInsurer) {
    return null;
  }
  return (
    <tr>
      <td className="font-weight-bold">{consultantName}</td>
      <td className="font-weight-bold">{niceDateTimeFormatter(props.datedCost.createdAt)}</td>
      <td className="font-weight-bold">{props.datedCost.createdBy ? userNameFormatter(props.datedCost.createdBy) : 'Initial setup'}</td>
      <td className="font-weight-bold">
        <span title={ccsdCode.name}>{ccsdCode.reference}</span> for{' '}
        {`${healthInsurer.name} will be ${generalCurrencyFormatter(props.datedCost.cost)} from ${niceDateTimeFormatter(props.datedCost.validFrom, 'DD-MM-YYYY')}`}
      </td>
      {consultantId ? (
        <td className="text-center">
          <Link to={ROUTES.CONSULTANTS.SINGLE.replace(':id', consultantId) + '#ccsd-codes'}>View Consultant</Link>
        </td>
      ) : (
        <td className="text-center">
          <Link to={ROUTES.CCSD_CODES.SINGLE.replace(':id', ccsdCode.id)}>View Code</Link>
        </td>
      )}
    </tr>
  );
}

DatedCostChangeRow.propTypes = {
  datedCost: PropTypes.any,
};

export default DatedCostChangeRow;
