import { INVOICE_BATCH_WEEKLY_COUNTS_UPDATED } from '../constants';

const reducer = (
  state = {
    invoiceBatchWeeklyCounts: [],
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_BATCH_WEEKLY_COUNTS_UPDATED:
      return {
        ...state,
        invoiceBatchWeeklyCounts: action.invoiceBatchWeeklyCounts,
      };

    default:
      return state;
  }
};

export default reducer;
