import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderCheckbox, renderSelect } from '../../../lib/helper/form';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { invoiceSingleCreateEscalatedAction, invoiceSingleUpdate } from '../../../lib/redux/actions/invoices';
import { invoiceEscalationReasonFetch } from '../../../lib/redux/actions/invoiceEscalationReason';
import { isChaserOutsourcer, isStaff } from '../../../lib/helper/authorisation';
import { getStats } from '../../../lib/redux/actions/stats';

const mapStateToProps = (state, props) => {
  const initialValues = {
    invoiceEscalationReason: props.invoice?.invoiceEscalationReason?.['@id'],
  };
  return {
    initialValues,
    ...state.invoiceEscalationReason,
    currentFormValues: getFormValues('ChaserOutsourcerInvoiceEscalationForm')(state),
  };
};

const mapDispatchToProps = {
  invoiceSingleUpdate,
  invoiceEscalationReasonFetch,
  invoiceSingleCreateEscalatedAction,
  addToast,
  getStats,
};

class ChaserOutsourcerInvoiceEscalationForm extends React.Component {
  componentDidMount() {
    const { invoiceEscalationReasonFetch } = this.props;

    invoiceEscalationReasonFetch();
  }

  onSubmit(values) {
    const { invoiceSingleCreateEscalatedAction, invoice = undefined, addToast, closeModal, getStats } = this.props;

    const unassignsCurrentUser = isChaserOutsourcer(true) || isStaff(true);
    return invoiceSingleCreateEscalatedAction(invoice.id, unassignsCurrentUser, values['invoiceEscalationReason']).then(() => {
      addToast('The Invoice has been escalated', true);
      getStats();
      closeModal();
    });
  }

  render() {
    const { handleSubmit, currentFormValues, invoiceEscalationReasons } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <h6>Escalation</h6>
        <p>Escalate this item</p>
        <div className={'modal-confirmation-input'}>
          <Field
            className="mt-3"
            name="isEscalateConfirmedChecked"
            label="Please confirm that you have added a note in Healthcodes"
            id="is-escalate-confirmed-checked"
            component={renderCheckbox}
          />
        </div>
        {currentFormValues?.isEscalateConfirmedChecked ? (
          <div className="my-3">
            <Field
              name="invoiceEscalationReason"
              label="Escalation Reason"
              id="reason"
              component={renderSelect}
              options={invoiceEscalationReasons.map((reason) => ({ text: reason.reason, value: reason['@id'] }))}
              emptyLabel={'Select Reason'}
              required={true}
            />
          </div>
        ) : null}
        {currentFormValues?.isEscalateConfirmedChecked ? (
          <button className="btn btn-danger mr-4 mb-2 mt-3">Escalate and Close</button>
        ) : (
          <span className="btn btn-outline-danger mr-4 mb-2 mt-3">Confirm before escalating</span>
        )}
      </Form>
    );
  }
}

ChaserOutsourcerInvoiceEscalationForm.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ChaserOutsourcerInvoiceEscalationForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(ChaserOutsourcerInvoiceEscalationForm),
);
