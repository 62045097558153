import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form';
import { parseApiErrors } from '../../helper/apiUtils';
import {
  PROBLEM_DATA_INSURER_LIST_ERROR,
  PROBLEM_DATA_INSURER_LIST_RECEIVED,
  PROBLEM_DATA_INSURER_LIST_REQUEST,
  PROBLEM_DATA_INSURER_LIST_UNLOAD,
} from '../constants';

export const problemDataInsurerListRequest = () => ({
  type: PROBLEM_DATA_INSURER_LIST_REQUEST,
});

export const problemDataInsurerListReceived = (data) => ({
  type: PROBLEM_DATA_INSURER_LIST_RECEIVED,
  data,
});

export const problemDataInsurerListError = (error) => ({
  type: PROBLEM_DATA_INSURER_LIST_ERROR,
  error,
});

export const problemDataInsurerListUnload = () => ({
  type: PROBLEM_DATA_INSURER_LIST_UNLOAD,
});

export const problemDataInsurerListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(problemDataInsurerListRequest());
    if (filters.pagination === undefined) {
      filters.pagination = false;
    }
    if (filters.pagination) {
      filters.page = page;
    }
    return requests
      .get(API_ROUTES.PROBLEM_DATA_INSURERS, filters)
      .then((response) => dispatch(problemDataInsurerListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(problemDataInsurerListError(error));
      });
  };
};
