/* N.B. These codes need to reflect app/src/Entity/InvoiceBatchStatus.php in the api project */

const INVOICE_BATCH_STATUS = {
  CANCELLED: -10,
  SUBMITTED: 0,
  CHECKED_AND_PRICED: 10,
  //  ASSIGNED: 20,
  PROCESSING: 30,
  COMPLETED: 40,
};

export default INVOICE_BATCH_STATUS;
