import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import React from 'react';
import FileUploader from '../../file/FileUploader';
import { Field, reduxForm } from 'redux-form';
import { renderSelect } from '../../../lib/helper/form';

import FileBrowser from '../../file/FileBrowser';
import { reportBatchAddFileAndDate } from '../../../lib/redux/actions/reportBatches';
import { connect } from 'react-redux';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const mapDispatchToProps = {
  reportBatchAddFileAndDate,
};

class ChooseDateAndFilesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthOptions: [],
      file: undefined,
    };
  }

  componentDidMount() {
    this.setMonthOptions();
  }

  onFileAdded(e) {
    if (!e.target || !e.target.files || !e.target.files.length > 0) {
      return;
    }
    const file = e.target.files[0];
    file.id = file.name;
    file.url = file.name;
    this.setState(() => {
      return { file };
    });
  }

  setMonthOptions() {
    const monthOptions = [];
    const startDate = moment().utc().date(1).hour(0).minute(0).second(0).subtract(2, 'years');
    const endDate = moment().utc().date(1).hour(0).minute(0).second(0).subtract(1, 'months');
    const range = moment.range(startDate, endDate);

    for (let month of range.by('month')) {
      monthOptions.push({ value: month.utc().toISOString(), text: month.utc().format('MMMM YYYY') });
    }
    monthOptions.reverse();
    this.setState(() => {
      return { monthOptions };
    });
  }

  onSubmit(values) {
    const { reportBatchAddFileAndDate } = this.props;

    return reportBatchAddFileAndDate(values.associated_date, this.state.file);
  }

  render() {
    const { isComplete = false, handleSubmit, error, pristine, submitting } = this.props;
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <span className="font-weight-bolder">1</span>
        </div>
        {isComplete ? (
          <h2 className="crud__section-header mb-0 d-flex justify-content-between">
            Upload files for processing
            <i className="fa fa-check-circle text-primary" />
          </h2>
        ) : (
          <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            {error && <div className="alert alert-danger">{error}</div>}
            <h2 className="crud__section-header">Upload files for processing</h2>
            <Form.Row>
              <Field
                id="formGroupMonth"
                as={Col}
                name="associated_date"
                emptyLabel=""
                label="Associated Month"
                options={this.state.monthOptions}
                component={renderSelect}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formGroupFileUpload" as={Col}>
                <Form.Label>Upload a zip file containing all files to send</Form.Label>
                <FileUploader accept={'.zip'} multiple={false} onFilesAdded={this.onFileAdded.bind(this)} />
                <FileBrowser files={this.state.file ? [this.state.file] : []} />
              </Form.Group>
            </Form.Row>
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              Continue
            </button>
          </Form>
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: 'ChooseDateAndFilesForm',
})(connect(null, mapDispatchToProps)(ChooseDateAndFilesForm));
