import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { renderCheckbox } from '../../../lib/helper/form';
import { connect } from 'react-redux';
import { daysOfTheWeekShortened, dayOfWeekShortenedFormatter } from '../../../lib/helper/formatting';
import { consultantSingleUpdate } from '../../../lib/redux/actions/consultant';
import { addToast } from '../../../lib/redux/actions/toast';
import { getSubusersConsultantToManage, isConsultant, isStaff, isSubUser } from '../../../lib/helper/authorisation';
import { Alert } from 'react-bootstrap';

const mapDispatchToProps = {
  consultantSingleUpdate,
  addToast,
};

class RegularClinicsForm extends React.Component {
  constructor(props) {
    super(props);
    const { clinicSlots, initialize } = this.props;
    initialize(this.mapClinicSlotsForForm(clinicSlots));
  }

  mapClinicSlotsForForm(clinicSlots) {
    const mappedSlots = {};
    clinicSlots.forEach((slot) => {
      mappedSlots[`${String(dayOfWeekShortenedFormatter(Number(slot.dayOfWeek - 1))).toLowerCase()}_${String(slot.timeOfDayText).toLowerCase()}`] = 1;
    });
    return mappedSlots;
  }

  mapFormValuesToClinicSlots(values) {
    const clinicSlots = [];

    for (let [key, value] of Object.entries(values)) {
      if (value) {
        const [day, time] = key.split('_');
        const dayOfWeekNo = Number(daysOfTheWeekShortened.findIndex((el) => String(el).toLowerCase() === day));
        clinicSlots.push({
          dayOfWeek: Number(dayOfWeekNo + 1),
          timeOfDay: time === 'am' ? 1 : 10,
        });
      }
    }
    return clinicSlots;
  }

  onSubmit(values) {
    const { consultant, consultantSingleUpdate, addToast } = this.props;
    if (isStaff(true)) {
      return;
    }

    return consultantSingleUpdate({ clinicSlots: this.mapFormValuesToClinicSlots(values) }, consultant.id).then(() => {
      addToast('Clinic Slots Successfully Updated', true);
    });
  }

  render() {
    const { pristine, submitting, handleSubmit, clinicSlots } = this.props;
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-calendar-alt" />
        </div>
        <h2 className="crud__section-header">
          {isConsultant(true) ? 'Your ' : isSubUser(true) ? getSubusersConsultantToManage().name + "'s " : ''}Regular Clinics
        </h2>
        <p className="hint-text">Completing this section helps us to alert you when invoices seem to be missing for regular clinics</p>
        {(!clinicSlots || clinicSlots.length < 1) && (
          <Alert variant="danger">Please tell us any regular clinic days to help us monitor for missing invoices</Alert>
        )}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Table size="sm">
            <thead>
              <tr>
                <th />
                <th className="text-center">Morning</th>
                <th className="text-center">Afternoon</th>
              </tr>
            </thead>
            <tbody>
              {daysOfTheWeekShortened.map((dotw) => (
                <tr key={`${String(dotw).toLowerCase()}`}>
                  <td className="font-weight-bold">{dotw}</td>
                  <td className="text-center">
                    <Field
                      disabled={isStaff(true)}
                      name={`${String(dotw).toLowerCase()}_am`}
                      id={`${String(dotw).toLowerCase()}_am`}
                      aria-label={`${dotw} AM`}
                      label=""
                      component={renderCheckbox}
                    />
                  </td>
                  <td className="text-center">
                    <Field
                      disabled={isStaff(true)}
                      name={`${String(dotw).toLowerCase()}_pm`}
                      id={`${String(dotw).toLowerCase()}_pm`}
                      aria-label={`${dotw} PM`}
                      label=""
                      component={renderCheckbox}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!isStaff(true) && (
            <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block">
              Update
            </button>
          )}
        </Form>
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'RegularClinicsForm',
  })(RegularClinicsForm),
);
