import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import Helmet from 'react-helmet';
import WorkScheduleEventForm from '../../components/forms/WorkScheduleEvent/WorkScheduleEventForm';
import Alert from 'react-bootstrap/Alert';

class WorkScheduleEventSingle extends React.Component {
  render() {
    const { workScheduleEvent, deleteWorkScheduleEvent, isDeleting } = this.props;

    return (
      <div>
        <Helmet>
          <title>{workScheduleEvent.name} - A WorkScheduleEvent</title>
        </Helmet>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  <span className="crud__meta">
                    <Link to={ROUTES.WORK_SCHEDULE_EVENTS.ALL.replace('/:page', '')}>&larr; Back to Outsourcer Holidays</Link>
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h1 className="crud__body-title">Edit an Outsourcer Holiday ({workScheduleEvent.name})</h1>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <WorkScheduleEventForm workScheduleEvent={workScheduleEvent} />

                    {isDeleting && (
                      <Alert variant={'danger'}>Deleting a Outsourcer Holiday will permanently remove it and it will no longer show on the Work Schedule</Alert>
                    )}
                    <button
                      onClick={() => {
                        deleteWorkScheduleEvent();
                      }}
                      className={`btn mr-3 ${isDeleting ? 'btn-primary' : 'btn-danger'}`}
                      type="button">
                      {isDeleting ? 'This is destructive and irreversible. Click again to confirm...' : 'Delete Work Schedule Event'}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WorkScheduleEventSingle;
