import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderField } from '../../../lib/helper/form';
import { addToast } from '../../../lib/redux/actions/toast';
import { consultantTicketNoteSingleCreate } from '../../../lib/redux/actions/consultantTicketNotes';
import ConsultantTicketUsersTypeAhead from '../../fields/ConsultantTicketUsersTypeAhead';
import { isStaff } from '../../../lib/helper/authorisation';

const mapStateToProps = (state) => {
  return {
    ...state.consultantTickets,
  };
};
const mapDispatchToProps = {
  consultantTicketNoteSingleCreate,
  addToast,
};

class CreateConsultantTicketNoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearTypeaheads: false,
      selectedAuthorisedUsers: undefined,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return {
        clearTypeaheads: true,
        selectedAuthorisedUsers: undefined,
      };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onSubmit(values) {
    const { consultantTicketNoteSingleCreate, reset, consultantTicket, addToast, onCreate = () => {} } = this.props;

    let authorisedUsersToCopy = [];
    if (this.state.selectedAuthorisedUsers) {
      authorisedUsersToCopy.push(...this.state.selectedAuthorisedUsers);
    }

    return consultantTicketNoteSingleCreate({
      consultantTicket: consultantTicket['@id'],
      note: values.note,
      authorisedUsersToCopy: authorisedUsersToCopy,
    }).then(() => {
      this.clearTypeaheads();
      onCreate();
      addToast('Your reply was sent', true);
      reset();
    });
  }

  render() {
    const { handleSubmit, error, pristine, submitting, reset, consultantTicket } = this.props;

    return (
      <div className="invoice-batch-single__section mb-3">
        <div className="invoice-batch-single__section-icon">
          <i className="fas fa-edit" />
        </div>

        <h2 className="invoice-batch-single__section-header">Reply to the Ticket</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          {isStaff() && consultantTicket && consultantTicket.consultant ? (
            <ConsultantTicketUsersTypeAhead
              consultant={consultantTicket.consultant}
              shouldClear={this.state.clearTypeaheads}
              hasCleared={this.typeaheadCleared.bind(this)}
              multiple={true}
              label={'Email these Consultant/Consultant Authorised/Staff users'}
              onChange={(selected) => {
                this.setState(() => {
                  return {
                    selectedAuthorisedUsers: selected,
                  };
                });
              }}
            />
          ) : null}
          <Field className="mb-2" name="note" label="Enter your response:" id="note" type="textarea" component={renderField} />

          <button type="submit" disabled={pristine || submitting} className="btn btn-primary btn-block mb-2">
            Send
          </button>
        </Form>
        {!pristine && (
          <button
            type="button"
            disabled={pristine || submitting}
            className="btn btn-secondary btn-block"
            onClick={() => {
              reset();
            }}>
            Clear
          </button>
        )}
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'CreateConsultantTicketNoteForm',
    enableReinitialize: true,
  })(CreateConsultantTicketNoteForm),
);
