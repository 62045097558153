import * as API_ROUTES from '../../routing/api';
import { PROBLEM_STATUSES_RECEIVED, PROBLEM_STATUSES_REQUEST, PROBLEM_STATUSES_UNLOAD } from '../constants';
import { requests } from '../../helper/agent';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const problemStatusesRequest = () => ({
  type: PROBLEM_STATUSES_REQUEST,
});

export const problemStatusesReceived = (data) => ({
  type: PROBLEM_STATUSES_RECEIVED,
  data,
});

export const problemStatusesUnload = () => ({
  type: PROBLEM_STATUSES_UNLOAD,
});

export const problemStatusesFetch = () => {
  return (dispatch) => {
    dispatch(problemStatusesRequest());
    return requests
      .get(`${API_ROUTES.PROBLEM_STATUSES}`)
      .then((response) => dispatch(problemStatusesReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
