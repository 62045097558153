import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';

class HospitalListTable extends React.Component {
  render() {
    const { hospitals = [], selectHospital, selectedHospital } = this.props;
    return (
      <div>
        <Table striped hover responsive className="hospital-list-table">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {hospitals.map((hospital) => {
              return (
                <tr key={hospital.id} className={selectedHospital && selectedHospital.id === hospital.id ? 'table-active' : ''}>
                  <td>{hospital.name}</td>
                  <td className="text-sm-left text-md-right">
                    <button
                      className="btn btn-sm btn-primary ml-2"
                      onClick={() => {
                        selectHospital(hospital);
                      }}>
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

HospitalListTable.propTypes = {
  hospitals: PropTypes.array.isRequired,
  selectHospital: PropTypes.func.isRequired,
};

export default HospitalListTable;
