import React, { Component } from 'react';
import ProblemDataCreate from './ProblemDataCreate';
import { connect } from 'react-redux';
import { problemTypesFetch, problemTypesUnload } from '../../lib/redux/actions/problemTypes';
import { invoiceBatchSingleFetch, invoiceBatchSingleUnload } from '../../lib/redux/actions/invoiceBatch';
import Card from 'react-bootstrap/Card';
import MASpinner from '../../components/common/MASpinner';
import { problemStatusesFetch, problemStatusesUnload } from '../../lib/redux/actions/problemStatus';
import { problemDataInsurerListFetch, problemDataInsurerListUnload } from '../../lib/redux/actions/problemDataInsurers';

const mapDispatchToProps = {
  problemDataInsurerListFetch,
  problemDataInsurerListUnload,
  problemTypesFetch,
  problemTypesUnload,
  invoiceBatchSingleUnload,
  invoiceBatchSingleFetch,
  problemStatusesFetch,
  problemStatusesUnload,
};

const mapStateToProps = (state) => {
  return {
    ...state.problemDataInsurers,
    ...state.problemTypes,
    ...state.problemStatuses,
    ...state.invoiceBatchSingle,
    ...state.problemData,
  };
};

class ProblemDataCreateContainer extends Component {
  componentDidMount() {
    this.props.invoiceBatchSingleFetch(this.props.match.params.id);
    this.props.problemDataInsurerListFetch({}, { pagination: false });
    this.props.problemTypesFetch();
    this.props.problemStatusesFetch();
  }

  componentWillUnmount() {
    this.props.problemTypesUnload();
    this.props.problemStatusesUnload();
    this.props.invoiceBatchSingleUnload();
    this.props.problemDataInsurerListUnload();
  }

  render() {
    const { invoiceBatch, problemDataInsurers, problemTypes, isFetchingInvoiceBatch, problemStatuses, history } = this.props;

    if (!isFetchingInvoiceBatch && !invoiceBatch) {
      return (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">Not Found</div>
          </Card.Header>
          <Card.Body>
            <p>The invoice you are looking for cannot be found.</p>
          </Card.Body>
        </Card>
      );
    }
    if (isFetchingInvoiceBatch && !invoiceBatch) {
      return <MASpinner />;
    }
    return (
      <ProblemDataCreate
        history={history}
        invoiceBatch={invoiceBatch}
        healthInsurers={problemDataInsurers}
        problemTypes={problemTypes}
        problemStatuses={problemStatuses}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemDataCreateContainer);
