import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { consultantSingleUpdate } from '../../lib/redux/actions/consultant';
import { isStaff } from '../../lib/helper/authorisation';
import { addToast } from '../../lib/redux/actions/toast';
import { userSingleUpdate } from '../../lib/redux/actions/user';
import { reduxForm } from 'redux-form';
import { JODIT_EDITOR_CONFIG } from '../../lib/helper/general';
import JoditEditor from 'jodit-react';

const mapDispatchToProps = {
  consultantSingleUpdate,
  addToast,
  userSingleUpdate,
};

class OnboardingNotesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    this.editorRef = React.createRef();
    this.setText = this.setText.bind(this);
  }

  setText(value) {
    this.setState({
      text: value,
    });
  }

  onSubmit() {
    let data = {
      onboardingNotes: this.state.text,
    };
    return this.props.userSingleUpdate(data, this.props.consultant.primaryUser.id).then(() => {
      this.props.addToast('Additional notes have been saved successfully.', true);
    });
  }
  componentDidMount() {
    if (this.props.consultant?.primaryUser?.onboardingNotes !== false) {
      this.setText(this.props.consultant?.primaryUser?.onboardingNotes);
    }
  }

  render() {
    return (
      <div className="crud__section mb-3">
        <div className="crud__section-icon">
          <i className="fas fa-book-open" />
        </div>
        <h2 className="crud__section-header">Additional Notes</h2>
        <Form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
          {isStaff(true) ? (
            <div dangerouslySetInnerHTML={{ __html: this.props.consultant?.primaryUser?.onboardingNotes }} />
          ) : (
            <>
              <JoditEditor
                ref={this.editorRef}
                value={this.state.text}
                config={JODIT_EDITOR_CONFIG}
                tabIndex={1}
                onBlur={(newContent) => this.setText(newContent)}
              />
              <button type="submit" className="btn btn-primary btn-block mt-5">
                Update
              </button>
            </>
          )}
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'onboardingNotesForm',
})(connect(null, mapDispatchToProps)(OnboardingNotesForm));
