import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';

class CcsdCodeListTable extends React.Component {
  render() {
    const { ccsdCodes } = this.props;

    return (
      <div>
        <Table striped hover responsive className="mb-0">
          <thead>
            <tr>
              <th>CCSD Code</th>
              <th>Code Type</th>
              <th>Code Description</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {ccsdCodes.map((ccsdCode) => {
              return (
                <tr key={ccsdCode.id}>
                  <td>
                    <Link to={ROUTES.CCSD_CODES.SINGLE.replace(':id', ccsdCode.id)} className="text-body">
                      {ccsdCode.reference}
                    </Link>
                  </td>
                  <td className="text-capitalize">{ccsdCode.ccsdCodeType.code}</td>
                  <td>{ccsdCode.name}</td>
                  <td className="text-sm-left text-md-right">
                    <Link to={ROUTES.CCSD_CODES.SINGLE.replace(':id', ccsdCode.id)} className="btn btn-sm btn-primary ml-2">
                      Edit
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default CcsdCodeListTable;
