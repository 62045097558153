import { CONSULTANTS_TYPEAHEAD_LIST_ERROR, CONSULTANTS_TYPEAHEAD_LIST_RECEIVED, CONSULTANTS_TYPEAHEAD_LIST_REQUEST } from '../constants';

import { hydraPageCount } from '../../helper/apiUtils';

const reducer = (
  state = {
    consultants: [],
    isFetchingConsultants: false,
    pageCount: null,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANTS_TYPEAHEAD_LIST_REQUEST:
      return {
        ...state,
        isFetchingConsultants: true,
      };

    case CONSULTANTS_TYPEAHEAD_LIST_RECEIVED:
      return {
        ...state,
        consultants: action.data['hydra:member'],
        pageCount: hydraPageCount(action.data),
        isFetchingConsultants: false,
      };

    case CONSULTANTS_TYPEAHEAD_LIST_ERROR:
      return {
        ...state,
        isFetchingConsultants: false,
      };

    default:
      return state;
  }
};

export default reducer;
