import React from 'react';
import { invoiceBatchSingleAccept } from '../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isOutsourcer, isStaff } from '../../lib/helper/authorisation';
import INVOICE_BATCH_STATUS from '../../lib/helper/statusCodes';
import { addToast } from '../../lib/redux/actions/toast';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  invoiceBatchSingleAccept,
  addToast,
};

class AcceptButton extends React.Component {
  accept() {
    const { invoiceBatch, invoiceBatchSingleAccept, addToast } = this.props;
    return invoiceBatchSingleAccept(invoiceBatch.id).then(() => {
      addToast('The invoice batch was accepted for processing', true);
    });
  }

  render() {
    const { invoiceBatch, userData } = this.props;

    if (
      (isOutsourcer(true) || isStaff()) &&
      Number(invoiceBatch.status.code) === Number(INVOICE_BATCH_STATUS.CHECKED_AND_PRICED) &&
      invoiceBatch.assignedUser &&
      invoiceBatch.assignedUser['@id'] === userData['@id']
    ) {
      return (
        <button onClick={this.accept.bind(this)} type="button" className="btn btn-primary accept-button">
          <i className="fa fa-check-circle mr-2" />
          Accept <span>Invoice to Process</span>
        </button>
      );
    }

    return null;
  }
}

AcceptButton.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  invoiceBatchSingleAccept: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptButton);
