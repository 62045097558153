import React from 'react';
import ReportsListTable from './ReportsListTable';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { reportsListFetch, reportsListSetPage, reportSingleDelete } from '../../lib/redux/actions/reports';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import * as ROUTES from '../../lib/routing/frontend';
import ReportsTableFilterForm from '../../components/forms/Reports/ReportsTableFilterForm';
import { downloadReportFile } from '../../lib/helper/downloadHelper';
import MASpinner from '../../components/common/MASpinner';
import { addToast } from '../../lib/redux/actions/toast';

const mapStateToProps = (state) => ({
  ...state.reportsList,
});

const mapDispatchToProps = {
  reportsListFetch,
  reportsListSetPage,
  reportSingleDelete,
  addToast,
};

class ReportsListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
    };
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {} } = this.props;
    return { ...filters, ...defaultFilters };
  }

  componentDidMount() {
    this.props.reportsListFetch(this.getQueryParamPage(), this.getCombinedFilters());
  }

  componentDidUpdate(prevProps) {
    const { currentPage, reportsListFetch, reportsListSetPage, filters } = this.props;
    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      reportsListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      reportsListFetch(currentPage, this.getCombinedFilters());
    }
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, reportsListSetPage } = this.props;
    reportsListSetPage(page);
    history.push(ROUTES.REPORTS.ALL.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  render() {
    const { reports, isFetchingReports, currentPage, pageCount, pageTitle, addToast, reportSingleDelete } = this.props;

    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            {' '}
            {pageTitle}
            {!isFetchingReports && reports && reports.length > 0 && (
              <div>
                <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                  Filters
                </button>
                <span className="tabular-data-result-count">{pageCount} pages</span>
              </div>
            )}
          </div>
        </Card.Header>

        {this.state.showFilters && (
          <Card.Body className={'mediaccount-filter-wrapper'}>
            <ReportsTableFilterForm />
          </Card.Body>
        )}

        {isFetchingReports && (
          <Card.Body>
            <div className="spinner-wrapper mt-4 mb-4">
              <MASpinner />
            </div>
          </Card.Body>
        )}

        {!isFetchingReports && (reports === null || reports.length === 0) && (
          <Card.Body>
            <Alert variant={'info'} className="mb-0">
              No reports found
            </Alert>
          </Card.Body>
        )}

        {!isFetchingReports && reports && reports.length > 0 && (
          <ReportsListTable addToast={addToast} reports={reports} downloadFile={downloadReportFile} deleteReport={reportSingleDelete} />
        )}
        {!isFetchingReports && reports && reports.length > 0 && (
          <Paginator
            currentPage={currentPage}
            pageCount={pageCount}
            setPage={this.changePage.bind(this)}
            nextPage={this.nextPage.bind(this)}
            prevPage={this.prevPage.bind(this)}
          />
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListContainer);
