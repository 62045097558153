import React from 'react';
import { niceClinicDateFormatter, timeOfDayMap } from '../../lib/helper/formatting';

class DateWithMorningOrAfternoonSelector extends React.Component {
  render() {
    const { date, dateKey, amOrPm = timeOfDayMap.AM, amPmSelector, removeSelectedDate = () => {}, isNew = false } = this.props;

    return (
      <li className={`list-inline-item date-with-morning-or-afternoon-selector ${isNew ? 'bg-primary-light' : ''}`}>
        <span className="date-with-morning-or-afternoon-title">
          {niceClinicDateFormatter(date)}
          <button
            type="button"
            onClick={() => {
              removeSelectedDate(dateKey, amOrPm);
            }}
            className={`btn btn-xs btn-outline-danger`}>
            <i className="fa fa-times" />
          </button>
        </span>
        <div className="btn-group date-with-morning-or-afternoon-button-group" role="group" aria-label="AM / PM selector">
          <button
            type="button"
            onClick={() => {
              amPmSelector(dateKey, timeOfDayMap.AM);
            }}
            className={`btn btn-sm btn-primary${amOrPm === timeOfDayMap.AM ? '' : '-outline'}`}>
            AM
          </button>
          <button
            type="button"
            onClick={() => {
              amPmSelector(dateKey, timeOfDayMap.PM);
            }}
            className={`btn btn-sm btn-primary${amOrPm === timeOfDayMap.PM ? '' : '-outline'}`}>
            PM
          </button>
        </div>
        <br />
      </li>
    );
  }
}

export default DateWithMorningOrAfternoonSelector;
