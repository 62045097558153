import Form from 'react-bootstrap/Form';
import { Field, reduxForm } from 'redux-form';
import { renderReactSelect } from '../../../lib/helper/form';
import React from 'react';
import { invoiceBatchSingleUpdate, invoiceBatchSingleFetch, invoiceBatchAuditLogFetch } from '../../../lib/redux/actions/invoiceBatch';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import 'iterators-polyfill';
import { isClient } from '../../../lib/helper/authorisation';

const mapDispatchToProps = {
  addToast,
  invoiceBatchSingleUpdate,
  invoiceBatchSingleFetch,
  invoiceBatchAuditLogFetch,
};

class EditHospitalsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHospitals: [],
      availableHospitals: [],
      hasChanged: false,
    };
  }

  componentDidMount() {
    if (this.props.invoiceBatch.consultant) {
      this.setState(() => {
        return {
          selectedHospitals:
            this.props.invoiceBatch && this.props.invoiceBatch.hospitals
              ? this.props.invoiceBatch.hospitals.map((h) => {
                  return { value: h['@id'], label: h.name };
                })
              : [],
          availableHospitals:
            this.props.invoiceBatch.consultant && this.props.invoiceBatch.consultant.hospitals
              ? this.props.invoiceBatch.consultant.hospitals.map((h) => {
                  return { value: h['@id'], label: h.name };
                })
              : [],
        };
      });
    }
  }

  onSubmit() {
    const { invoiceBatchSingleUpdate, invoiceBatchSingleFetch, invoiceBatchAuditLogFetch, invoiceBatch, addToast, reset, toggleAmending } = this.props;

    if (this.state.selectedHospitals.length < 1) {
      return;
    }

    const invoiceBatchValues = {
      hospitals: [],
    };

    if (this.state.selectedHospitals && this.state.selectedHospitals.length > 0) {
      invoiceBatchValues['hospitals'] = this.state.selectedHospitals.map((h) => {
        return h.value;
      });
    }

    invoiceBatchSingleUpdate(invoiceBatchValues, invoiceBatch.id).then(() => {
      invoiceBatchSingleFetch(invoiceBatch.id).then(() => {
        invoiceBatchAuditLogFetch(invoiceBatch.id);
      });
    });

    toggleAmending();
    addToast('Successfully updated Hospitals', true);
    reset();
  }

  doReset() {
    this.props.fileClearReferences();
    this.props.reset();
    this.setState(() => this.getCleanState());
  }

  render() {
    const { handleSubmit, error, submitting } = this.props;

    return (
      <div>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="mediaccounts-clinic-date-component">
            <p>Please add any missed dates/times that these invoices relate to</p>

            <Field
              disabled={!isClient() && this.state.selectedConsultant == null}
              name="selectedHospitals"
              isMulti
              closeMenuOnSelect={this.state.availableHospitals.length - this.state.selectedHospitals.length === 1}
              handleSelect={(selected) => {
                this.setState(() => {
                  return Symbol.iterator in Object(selected)
                    ? { hasChanged: true, selectedHospitals: [...selected] }
                    : { hasChanged: true, selectedHospitals: [] };
                });
              }}
              selectedOptions={this.state.selectedHospitals}
              label="Please select the Hospitals that these invoices relate to"
              options={this.state.availableHospitals}
              component={renderReactSelect}
            />
          </div>

          <button type="submit" disabled={!this.state.hasChanged || submitting} className="btn btn-primary">
            Save amends
          </button>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'EditHospitalsForm',
})(connect(null, mapDispatchToProps)(EditHospitalsForm));
