import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from 'react';
import PdfViewer from '../PdfViewer';
import ReviewInvoiceDataForm from '../../../components/forms/Invoice/ReviewInvoiceDataForm';

type Step2CardProps = {
  extractedText: string;
  invoiceData: { consultant: {} };
  setReviewedData: () => {};
  reset: () => void;
  debug?: boolean;
  file: string;
  children?: React.ReactNode;
};

export function Step2Card({ extractedText, invoiceData, setReviewedData, reset, file, children, debug = false }: Step2CardProps) {
  const startScale = Number(localStorage.getItem('pdfScale')) || 0.8;
  const [scale, setScale] = useState(startScale);
  const scaleBy = 0.1;
  useEffect(() => {
    localStorage.setItem('pdfScale', scale.toString());
  }, [scale]);
  return (
    <Card className="mb-4">
      <Card.Header as={'h2'}>
        Review Invoice Details{' '}
        <button type={'button'} className="btn btn-secondary float-right" onClick={reset}>
          Start Over
        </button>
      </Card.Header>
      <Card.Body>
        {debug && (
          <Row>
            <Col>
              <p>{extractedText}</p>
              <pre>{JSON.stringify(invoiceData, null, 2)}</pre>
            </Col>
          </Row>
        )}
        <Row>
          <Col className={'col-sm-12 col-xl-4'}>
            <div className="crud__section mb-3">
              <ReviewInvoiceDataForm invoiceData={invoiceData} onSubmit={setReviewedData} />
              {children}
            </div>
          </Col>
          <Col className={'col-sm-12 col-xl-8 text-center'}>
            <Row className={'mb-2'}>
              <Col>
                <button type={'button'} className="btn btn-sm btn-outline-dark mr-2 col-md-1" onClick={() => setScale(scale + scaleBy)}>
                  +
                </button>
                <button type={'button'} className="btn btn-sm btn-outline-dark col-md-1" onClick={() => setScale(scale - scaleBy)}>
                  -
                </button>
              </Col>
            </Row>
            <Row>
              <Col>{file && <PdfViewer pdfUrl={file} scale={scale} />}</Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
