import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { connect } from 'react-redux';
import { userLogout } from '../../lib/redux/actions/user';
import Sidebar from './Sidebar';
import Toasts from '../common/Toasts';
import MASpinner from '../common/MASpinner';
import { getSubusersConsultantToManage, isSubUser } from '../../lib/helper/authorisation';

const mapStateToProps = (state) => ({
  ...state.stats,
  ...state.auth,
});

const mapDispatchToProps = {
  userLogout,
};

class StandardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSidebar: false };
  }

  toggleSidebar() {
    this.setState((prevState) => ({ showSidebar: !prevState.showSidebar }));
  }

  render() {
    const { children, isAuthenticated, is2faAuthenticated, userData, userLogout, stats } = this.props;
    if (!userData || !userData.roles) {
      return (
        <div className="app-spinner-wrapper">
          <MASpinner />
        </div>
      );
    }
    const newVersionAvailable =
      process.env.REACT_APP_PORTAL_VERSION !== 'local' &&
      stats?.portalVersion &&
      process.env.REACT_APP_PORTAL_VERSION &&
      stats?.portalVersion !== process.env.REACT_APP_PORTAL_VERSION;
    const consultantToManage = isSubUser(true) ? getSubusersConsultantToManage() : false;
    return (
      <div className={`app app-standard${newVersionAvailable ? ' app-new-version' : ''}`}>
        <Header
          isAuthenticated={isAuthenticated}
          is2faAuthenticated={is2faAuthenticated}
          userData={userData}
          userLogout={userLogout}
          sideBarToggleAction={this.toggleSidebar.bind(this)}
          consultantToManage={consultantToManage}
        />

        {newVersionAvailable ? (
          <div className={'bg-danger text-center'} style={{ top: '20%', position: 'fixed', width: '60%', left: '20%', zIndex: '999', padding: '40px' }}>
            <a className={'text-white h3 font-weight-bold'} href={'https://' + window.location.host + '?v=' + stats.portalVersion}>
              New Version Available - Click here to update
            </a>
          </div>
        ) : null}

        {isAuthenticated && (
          <Sidebar userData={userData} userLogout={userLogout} shouldBeVisible={this.state.showSidebar} toggleSidebar={this.toggleSidebar.bind(this)} />
        )}

        <main className="main-content">
          <div className="p-lg-4 p-sm-3 p-2">{children}</div>
          <Toasts />
        </main>
      </div>
    );
  }
}

StandardLayout.propTypes = {
  children: PropTypes.node,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardLayout);
