import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { problemDataAdd } from '../../../lib/redux/actions/problemData';
import { Field, reduxForm } from 'redux-form';
import Form from 'react-bootstrap/Form';
import { renderCheckbox, renderField, renderSelect } from '../../../lib/helper/form';
import PropTypes from 'prop-types';
import { niceDateFormatter } from '../../../lib/helper/formatting';
import UpliftDataForm from '../UpliftData/UpliftDataForm';
import moment from 'moment';
import * as ROUTES from '../../../lib/routing/frontend';
import { upliftDataAdd } from '../../../lib/redux/actions/upliftData';

const mapDispatchToProps = {
  addToast,
  problemDataAdd,
  upliftDataAdd,
};

const mapStateToProps = (state) => {
  return {
    ...state.problemData,
    initialValues: {
      problemDate: moment().utc().format('YYYY-MM-DD'),
      upliftData: {
        dateSubmitted: moment().utc().format('YYYY-MM-DD'),
      },
    },
  };
};

class CreateProblemDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateAmPmMap: [],
      nextDateKey: 0,
      selectedDates: [],
      currentHealthInsurerId: false,
      problemType: false,
      hasBeenAddedToHealthCodes: false,
    };
  }

  onSubmit = (formValues) => {
    const { invoiceBatch, problemDataAdd, upliftDataAdd, addToast, history } = this.props;
    formValues.invoiceBatch = `/invoice_batches/${invoiceBatch.id}`;
    formValues.problemStatus = '/problem_statuses/1';
    if (this.state.selectedInvoiceNumbers && this.state.selectedInvoiceNumbers.length > 0) {
      formValues['invoiceNumbers'] = this.state.selectedInvoiceNumbers.map((h) => {
        return h.value;
      });
    }
    let upliftFormData;
    if (formValues.upliftData) {
      upliftFormData = formValues.upliftData;
    }
    return problemDataAdd(formValues).then((response) => {
      this.setState({ problemDataId: response['@id'] });
      addToast('Success', true);
      if (response.problemType !== '/problem_types/3') {
        history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', invoiceBatch.id));
      } else {
        upliftFormData.problemData = {
          id: response.id || this.state.problemDataId,
        };
        if (upliftFormData.charts) {
          upliftFormData.charts = upliftFormData.charts === 'false' ? Boolean(false) : Boolean(true);
        } else {
          delete upliftFormData.charts;
        }
        if (upliftFormData.opNotes) {
          upliftFormData.opNotes = upliftFormData.opNotes === 'false' ? Boolean(false) : Boolean(true);
        } else {
          delete upliftFormData.opNotes;
        }
        if (upliftFormData.upliftForms) {
          upliftFormData.upliftForms = upliftFormData.upliftForms === 'false' ? Boolean(false) : Boolean(true);
        } else {
          delete upliftFormData.upliftForms;
        }
        return upliftDataAdd(upliftFormData).then(() => {
          addToast('Successfully added uplift Data', true);
          history.push(ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', invoiceBatch?.id || response?.invoiceBatch?.id));
        });
      }
    });
  };

  render() {
    const { handleSubmit, healthInsurers, problemTypes, invoiceBatch } = this.props;
    const healthInsurerOptions = [];
    healthInsurers.forEach((healthInsurer) => {
      healthInsurerOptions.push({
        text: healthInsurer.name,
        value: healthInsurer['@id'],
      });
    });
    const problemTypesOptions = [];
    problemTypes.forEach((problemType) => {
      problemTypesOptions.push({
        text: problemType.name,
        value: problemType['@id'],
      });
    });
    const consultationDatesOptions = [];
    invoiceBatch.invoiceDates.forEach((invoiceDate) => {
      consultationDatesOptions.push({
        text: niceDateFormatter(invoiceDate.date) + ' ' + invoiceDate.timeOfDayText,
        value: invoiceDate['@id'],
      });
    });
    return (
      <div className="p-3">
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="row">
            <fieldset className="col-md-12">
              <div className="row">
                <div className="col-sm-6 col-xl-4">
                  <Field name="problemDate" label="Problem Date" id="problemDate" type="date" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-4">
                  <Field name="insurer" options={healthInsurerOptions} emptyLabel={'Please select'} label="Health Insurer" component={renderSelect} />
                </div>
                <div className="col-sm-6 col-xl-4">
                  <Field
                    name="consultationDate"
                    options={consultationDatesOptions}
                    emptyLabel={'Please select'}
                    label="Treatment Date"
                    component={renderSelect}
                  />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <Field name="patientFirstName" label="Patient First Name" id="patientFirstName" type="text" component={renderField} />
                </div>
                <div className="col-md-6">
                  <Field name="patientLastName" label="Patient Last Name" id="patientLastName" type="text" component={renderField} />
                </div>
              </div>
            </fieldset>
          </div>

          <div className="row">
            <fieldset className="col-sm-12 col-md-12">
              <div className="row">
                <div className="col-sm-6 col-xl-6">
                  <Field
                    name="problemType"
                    options={problemTypesOptions}
                    label="Type"
                    emptyLabel={'Please select'}
                    onChange={(e) => {
                      if (e.target.value === '/problem_types/3') {
                        this.setState({ showUpliftDataForm: true });
                      } else {
                        this.setState({ showUpliftDataForm: false });
                      }
                      this.setState({ problemType: e.target.value });
                    }}
                    component={renderSelect}
                  />
                </div>
                <div className="col-md-6">
                  <Field name="invoiceNumbers" id="invoiceNumbers" label="Invoice Numbers" type="textarea" component={renderField} />
                </div>
              </div>
            </fieldset>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-6 col-xl-6">
                  <Field name="treatment" label="Treatment" id="treatment" rows="3" type="textarea" component={renderField} />
                </div>
                <div className="col-sm-6 col-xl-6">
                  <Field name="otherInformation" label="Any Other Information" id="otherInformation" rows="3" type="textarea" component={renderField} />
                </div>
              </div>
            </div>
          </div>
          <hr />
          {this.state.showUpliftDataForm && (
            <>
              Uplift Data
              <UpliftDataForm invoiceBatch={invoiceBatch} problemDataId={this.state.problemDataId} type="create" />
            </>
          )}
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Form.Check
                  className="mt-3 mb-3"
                  name="isEscalateConfirmedChecked"
                  label="Please confirm that you have added a note in Healthcodes"
                  id="is-escalate-confirmed-checked"
                  component={renderCheckbox}
                  type={'switch'}
                  checked={this.state.hasBeenAddedToHealthCodes}
                  onChange={() => this.setState({ hasBeenAddedToHealthCodes: !this.state.hasBeenAddedToHealthCodes })}
                />
              </div>
              <button
                type="submit"
                disabled={this.props.pristine || this.props.submitting || !this.state.hasBeenAddedToHealthCodes}
                className="btn btn-primary float-right">
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

CreateProblemDataForm = reduxForm({
  form: 'CreateProblemDataForm',
})(CreateProblemDataForm);

CreateProblemDataForm = connect(mapStateToProps, mapDispatchToProps)(CreateProblemDataForm);

export default CreateProblemDataForm;

CreateProblemDataForm.propTypes = {
  invoiceBatch: PropTypes.object.isRequired,
  problemTypes: PropTypes.array.isRequired,
  healthInsurers: PropTypes.array.isRequired,
};
