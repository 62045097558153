import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderField } from '../../../lib/helper/form';
import MASpinner from '../../common/MASpinner';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import { invoiceSingleFetch } from '../../../lib/redux/actions/invoices';
import { requests } from '../../../lib/helper/agent';
import * as API_ROUTES from '../../../lib/routing/api';

const mapStateToProps = (state, props) => {
  return {
    currentFormValues: getFormValues('InvoiceManualPaymentForm')(state),
  };
};

const mapDispatchToProps = {
  invoiceSingleFetch,
  addToast,
};

class InvoiceManualPaymentForm extends React.Component {
  onSubmit(values) {
    const { invoice, addToast, reset, invoiceSingleFetch } = this.props;
    requests.post(API_ROUTES.INVOICE_MANUAL_PAYMENT.replace(':id', invoice.id), values).then((response) => {
      addToast('Manual payment recorded successfully', true);
      reset();
      invoiceSingleFetch(Number(invoice.id));
    });
  }

  render() {
    const { handleSubmit, isUpdatingInvoice, submitting, pristine, reset } = this.props;

    return (
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="my-3">
            <Field
                name="amount"
                horizontal={true}
                horizontalLabelWidth={8}
                label="Take Manual Payment (£)"
                id="manual-payment-amount"
                type="number"
                placeholder="0.00"
                step="0.01"
                min="0.00"
                required={true}
                component={renderField}
            />
          </div>
          {isUpdatingInvoice ? <MASpinner/> : null}
          {!isUpdatingInvoice && !pristine ? (
              <>
                <button type="submit" disabled={submitting} className="btn btn-primary mr-2">
                  Record Payment
                </button>
                <button type="reset" disabled={submitting} className="btn btn-secondary" onClick={() => reset()}>
                  Reset
                </button>
              </>
          ) : null}
        </Form>
    );
  }
}

InvoiceManualPaymentForm.propTypes = {
  invoice: PropTypes.object.isRequired,
  isUpdatingInvoice: PropTypes.bool.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
      form: 'InvoiceManualPaymentForm',
      enableReinitialize: true,
      destroyOnUnmount: false,
    })(InvoiceManualPaymentForm),
);
