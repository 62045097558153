import React, { useEffect } from 'react';
import WorldpayPaymentContainer from './WorldpayPaymentContainer';
import { connect } from 'react-redux';
import YourInvoice from './YourInvoice';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';

const PayAnInvoiceContainer = ({ invoice, match }) => {
  const history = useHistory();
  useEffect(() => {
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, []);

  if (!invoice) {
    history.push(`/view-an-invoice/${match.params.uniqueRef}`);
  }
  return (
    <Container>
      <Row>
        <Col
          className="pt-4"
          xs={12}
          md={12}
          lg={{
            span: 8,
            offset: 2,
          }}>
          <div>
            {invoice ? (
              <>
                <YourInvoice invoice={invoice} showWorldPayIframe={true} />
                <WorldpayPaymentContainer />
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.invoices,
  };
};

export default connect(mapStateToProps, null)(PayAnInvoiceContainer);
