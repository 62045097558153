import {
  CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_ERROR,
  CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_RECEIVED,
  CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_REQUEST,
} from '../constants';

const reducer = (
  state = {
    authorisedUsers: [],
    isFetchingConsultantTicketUsers: false,
    pageCount: null,
  },
  action,
) => {
  switch (action.type) {
    case CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_REQUEST:
      return {
        ...state,
        isFetchingConsultantTicketUsers: true,
      };
    case CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_RECEIVED:
      let response = action.data;
      let consultantTicketUsersResponse = [];
      if (response) {
        response.forEach((authedUser) => {
          consultantTicketUsersResponse.push(authedUser.user);
        });
      }
      return {
        ...state,
        isFetchingConsultantTicketUsers: false,
        authorisedUsers: consultantTicketUsersResponse,
      };
    case CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_ERROR:
      return {
        ...state,
        isFetchingConsultantTicketUsers: false,
      };
    default:
      return state;
  }
};

export default reducer;
