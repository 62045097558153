import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';
import {
  CMS_CONTENT_CREATE_REQUEST,
  CMS_CONTENT_SINGLE_UPDATE_REQUEST,
  CMS_CONTENT_SINGLE_UPDATE_SUCCESS,
  CMS_CONTENT_CREATE_SUCCESS,
  CMS_CONTENT_SINGLE_ERROR,
  CMS_CONTENT_SINGLE_REQUEST,
  CMS_CONTENT_SINGLE_RECEIVED,
  CMS_CONTENT_SINGLE_UNLOAD,
} from '../constants';

export const cmsContentCreateRequest = (data) => ({
  type: CMS_CONTENT_CREATE_REQUEST,
  data,
});

export const cmsContentCreateSuccess = (data) => ({
  type: CMS_CONTENT_CREATE_SUCCESS,
  data,
});

export const cmsContentSingleRequest = () => ({
  type: CMS_CONTENT_SINGLE_REQUEST,
});

export const cmsContentSingleUpdateRequest = () => ({
  type: CMS_CONTENT_SINGLE_UPDATE_REQUEST,
});
export const cmsContentSingleUpdated = (data) => ({
  type: CMS_CONTENT_SINGLE_UPDATE_SUCCESS,
  data,
});

export const cmsContentSingleReceived = (data) => ({
  type: CMS_CONTENT_SINGLE_RECEIVED,
  data,
});

export const cmsContentSingleError = (error) => ({
  type: CMS_CONTENT_SINGLE_ERROR,
  error,
});

export const cmsContentSingleUnload = () => ({
  type: CMS_CONTENT_SINGLE_UNLOAD,
});

export const cmsContentCreate = (data) => {
  return (dispatch) => {
    dispatch(cmsContentCreateRequest());
    return requests
      .post(API_ROUTES.CMS_CONTENT_CREATE, data)
      .then((response) => dispatch(cmsContentCreateSuccess(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(cmsContentSingleError(error));
      });
  };
};

export const cmsContentUpdate = (page, content) => {
  return (dispatch) => {
    dispatch(cmsContentSingleUpdateRequest());
    return requests
      .patch(API_ROUTES.CMS_CONTENT_UPDATE.replace(':page', page), content)
      .then((response) => dispatch(cmsContentSingleUpdated(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(cmsContentSingleError(error));
      });
  };
};

export const cmsContentSingleFetch = (apiRoute) => {
  if (![API_ROUTES.CMS_FETCH_REMITTANCES, API_ROUTES.CMS_FETCH_HOSPITAL, API_ROUTES.CMS_FETCH_INSURANCE, API_ROUTES.CMS_FETCH_EMBASSY].includes(apiRoute)) {
    return false;
  }
  return (dispatch) => {
    dispatch(cmsContentSingleRequest());
    return requests
      .get(apiRoute)
      .then((response) => dispatch(cmsContentSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(cmsContentSingleError(error));
      });
  };
};
