import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { removeToast } from '../../lib/redux/actions/toast';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  ...state.toast,
});

const mapDispatchToProps = {
  removeToast,
};

class Toasts extends React.Component {
  closeToast(id) {
    return this.props.removeToast(id);
  }

  render() {
    const { toasts } = this.props;
    if (toasts.length < 1) {
      return null;
    }

    const hideDelay = 7000;

    return (
      <div className="mediaccount-toastwrapper" aria-live="polite" aria-atomic="true">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            style={{
              marginTop: '5px',
            }}
            onClose={this.closeToast.bind(this, toast.id)}
            delay={hideDelay}
            autohide
            animation>
            <Toast.Body className={`medi-toast${toast.positive ? ' positive' : ''}${toast.negative ? ' negative' : ''}`}>
              {toast.positive && <i className="fa fa-check-circle mr-2" />}
              {toast.negative && <i className="fa fa-times-circle mr-2" />}
              {toast.text}
              {toast.embed !== undefined && toast.embed}
            </Toast.Body>
          </Toast>
        ))}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
