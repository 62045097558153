import {
  CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_ERROR,
  CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_RECEIVED,
  CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_REQUEST,
} from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const consultantTicketUsersTypeaheadListRequest = () => ({
  type: CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_REQUEST,
});

export const consultantTicketUsersTypeaheadListReceived = (data) => ({
  type: CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_RECEIVED,
  data,
});

export const consultantTicketUsersTypeaheadListError = (error) => ({
  type: CONSULTANT_TICKET_USERS_TYPEAHEAD_LIST_ERROR,
  error,
});

export const consultantTicketUsersTypeaheadListFetch = (filters = {}) => {
  const { consultant = false } = filters;
  delete filters.consultant;
  const route = API_ROUTES.SUB_USERS.replace(':consultantId', consultant.id);
  return (dispatch) => {
    dispatch(consultantTicketUsersTypeaheadListRequest());
    filters.pagination = false;
    return requests
      .get(route, filters)
      .then((response) => {
        dispatch(consultantTicketUsersTypeaheadListReceived(response));
      })

      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(consultantTicketUsersTypeaheadListError(error));
      });
  };
};
