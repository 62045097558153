import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

class MASpinner extends React.Component {
  render() {
    const { msg = false, wrapperClasses = 'mb-3', size = '' } = this.props;
    return (
      <div className={`spinner-wrapper ${wrapperClasses}`}>
        <Spinner animation="border" variant="primary" size={size} />
        {msg !== false && <p>{msg}</p>}
      </div>
    );
  }
}

export default MASpinner;
