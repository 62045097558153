import { UPLIFT_DATA_RECEIVED, UPLIFT_DATA_RECEIVED_ERROR, UPLIFT_DATA_REQUEST } from '../constants';

const reducer = (
  state = {
    upliftData: null,
    error: false,
    isFetchingUpliftData: false,
  },
  action,
) => {
  switch (action.type) {
    case UPLIFT_DATA_REQUEST:
      return {
        ...state,
        isFetchingUpliftData: true,
        error: false,
      };
    case UPLIFT_DATA_RECEIVED_ERROR: {
      return {
        ...state,
        isFetchingUpliftData: false,
        error: true,
      };
    }
    case UPLIFT_DATA_RECEIVED:
      return {
        ...state,
        isFetchingUpliftData: false,
        error: false,
        upliftData: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
