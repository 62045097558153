import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import EditProblemDataForm from '../../components/forms/ProblemData/EditProblemDataForm';
import { isClient } from '../../lib/helper/authorisation';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import Badge from 'react-bootstrap/Badge';
import PROBLEM_STATUS from '../../lib/helper/problemStatusCodes';
import { niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import { OutsourceFlag } from '../../components/common/OutsourceFlag';
import { isCheckedAndPriced } from '../../lib/helper/invoiceBatch';

class ProblemDataEdit extends Component {
  renderDetailRow() {
    const { problemData } = this.props;
    let invoiceBatch = problemData?.invoiceBatch;
    return (
      <>
        <Row>
          <Col sm={'8'} md={'7'}>
            <h1 className="invoice-batch-single__consultant-name">
              {invoiceBatch.consultant.name}
              <span className="invoice-batch-single__consultant-id ml-3">{invoiceBatch.consultant.code}</span>
              {invoiceBatch.consultant.gmcReferenceNumber && (
                <span className="invoice-batch-single__consultant-id ml-3">GMC: {invoiceBatch.consultant.gmcReferenceNumber}</span>
              )}
            </h1>
            <h2 className="invoice-batch-single__date">
              Created {niceDateFormatter(invoiceBatch.createdAt)} {invoiceBatch.createdBy && `by ${userNameFormatter(invoiceBatch.createdBy)}`}
            </h2>
            <h2 className="invoice-batch-single__date">
              Problem Created {niceDateFormatter(problemData.createdAt)} {problemData.createdBy && `by ${userNameFormatter(problemData.createdBy)}`}
            </h2>
            {!isClient() && invoiceBatch.assignedUser && (
              <div>
                <h2 className="invoice-batch-single__date">
                  Assigned to {userNameFormatter(invoiceBatch.assignedUser)} {invoiceBatch.isProcessingOutsourced && <OutsourceFlag />}
                  {isCheckedAndPriced(invoiceBatch) && (
                    <span className="text-danger">
                      <i className="fa fa-inbox mr-2" />
                      <span className="small">Awaiting acceptance</span>
                    </span>
                  )}
                </h2>
              </div>
            )}
          </Col>
          <Col sm={'4'} md={'5'}>
            <Row>
              <Col sm={'12'} lg={'6'}>
                <h4 className="h6 text-uppercase">Clinics selected</h4>
                <ul className="list-unstyled clinic-sessions mb-0">
                  {invoiceBatch.invoiceDates &&
                    invoiceBatch.invoiceDates
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((invoiceDate) => (
                        <li key={invoiceDate.id}>
                          {niceDateFormatter(invoiceDate.date, 'ddd')} <strong>{niceDateFormatter(invoiceDate.date)}</strong>{' '}
                          <span className="clinic-session__day-half">({invoiceDate.timeOfDayText})</span>
                        </li>
                      ))}
                </ul>
              </Col>
              <Col sm={'12'} lg={'6'} className="mt-lg-0 mt-sm-4">
                <h4 className="h6 text-uppercase">Hospitals selected</h4>
                <ul className="list-unstyled clinic-sessions mb-0">
                  {invoiceBatch.hospitals && invoiceBatch.hospitals.map((hospital) => <li key={hospital.id}>{hospital.name}</li>)}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { healthInsurers, problemTypes, problemStatuses, problemData, history } = this.props;
    let invoiceBatch = problemData?.invoiceBatch;
    return (
      <div>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Header className={'card-header--crud'}>
                <div className="d-flex align-items-center">
                  {!isClient() && (
                    <span className="crud__meta">
                      <Link to={ROUTES.INVOICE_BATCHES.SINGLE.replace(':id', problemData?.invoiceBatch?.id)}>
                        &larr; Back to Invoice Batch ID {problemData?.invoiceBatch?.id}
                      </Link>
                    </span>
                  )}
                  <span className="ml-auto crud__statuses">
                    {Number(problemData?.problemStatus?.code) !== PROBLEM_STATUS.COMPLETE ? (
                      <Badge pill variant={'warning'} className="ml-2 text-uppercase">
                        {problemData?.problemStatus?.name}
                      </Badge>
                    ) : (
                      <Badge pill variant={'primary'} className="ml-2 text-uppercase">
                        {problemData?.problemStatus?.name}
                      </Badge>
                    )}
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                {this.renderDetailRow()}
                <hr />
                <Row>
                  <Col>
                    <div className="crud__section mb-3">
                      <div className="crud__section-icon mb-0 d-flex">
                        <i className="fas fa-exclamation-triangle" />
                      </div>
                      <h2 className="crud__section-header">Problem Data</h2>
                      <EditProblemDataForm
                        history={history}
                        problemData={problemData}
                        healthInsurers={healthInsurers}
                        problemTypes={problemTypes}
                        problemStatuses={problemStatuses}
                        invoiceBatch={invoiceBatch}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProblemDataEdit;
