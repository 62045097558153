import { INVOICE_MISSING_DETAILS_TYPE_LIST_REQUEST, INVOICE_MISSING_DETAILS_TYPE_LIST_RECEIVED, INVOICE_MISSING_DETAILS_TYPE_LIST_ERROR } from '../constants';

const reducer = (
  state = {
    invoiceMissingDetailsTypes: [],
    isFetchingInvoiceMissingDetailsTypes: false,
  },
  action,
) => {
  switch (action.type) {
    case INVOICE_MISSING_DETAILS_TYPE_LIST_REQUEST:
      state = {
        ...state,
        isFetchingInvoiceMissingDetailsTypes: true,
      };
      return state;

    case INVOICE_MISSING_DETAILS_TYPE_LIST_RECEIVED:
      state = {
        ...state,
        invoiceMissingDetailsTypes: action.data['hydra:member'],
        isFetchingInvoiceMissingDetailsTypes: false,
      };
      return state;

    case INVOICE_MISSING_DETAILS_TYPE_LIST_ERROR:
      state = {
        ...state,
        isFetchingInvoiceMissingDetailsTypes: false,
      };
      return state;

    default:
      return state;
  }
};

export default reducer;
