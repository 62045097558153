import { INVOICE_BATCH_STATUS_LIST_REQUEST, INVOICE_BATCH_STATUS_LIST_RECEIVED, INVOICE_BATCH_STATUS_LIST_ERROR } from '../constants';
import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SubmissionError } from 'redux-form/lib/SubmissionError';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceBatchStatusListRequest = () => ({
  type: INVOICE_BATCH_STATUS_LIST_REQUEST,
});

export const invoiceBatchStatusListReceived = (data) => ({
  type: INVOICE_BATCH_STATUS_LIST_RECEIVED,
  data,
});

export const invoiceBatchStatusListError = (error) => ({
  type: INVOICE_BATCH_STATUS_LIST_ERROR,
  error,
});

export const invoiceBatchStatusListFetch = (page = 1) => {
  return (dispatch) => {
    dispatch(invoiceBatchStatusListRequest());
    //    console.log('Fetching invoice batches statuses...');
    return requests
      .get(`${API_ROUTES.INVOICE_BATCH_STATUS}`, { page })
      .then((response) => dispatch(invoiceBatchStatusListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceBatchStatusListError(error));
      });
  };
};
