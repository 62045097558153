import React from 'react';
import Table from 'react-bootstrap/Table';
import { niceDateFormatter, userNameFormatter } from '../../lib/helper/formatting';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';
import { isStaff } from '../../lib/helper/authorisation';

class WorkScheduleEventListTable extends React.Component {
  render() {
    const { workScheduleEvents } = this.props;

    return (
      <div>
        <Table striped hover responsive className="mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              {isStaff() && <th>User</th>}
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {workScheduleEvents.map((workScheduleEvent) => {
              return (
                <tr key={workScheduleEvent.id}>
                  <td>
                    <strong>{workScheduleEvent.name}</strong>
                  </td>
                  <td>{niceDateFormatter(workScheduleEvent.startDate, 'DD/MM/YYYY')}</td>
                  <td>
                    {workScheduleEvent.endDate ? (
                      <>{niceDateFormatter(workScheduleEvent.endDate, 'DD/MM/YYYY')}</>
                    ) : (
                      <>{niceDateFormatter(workScheduleEvent.startDate, 'DD/MM/YYYY')}</>
                    )}
                  </td>
                  {isStaff() && <td>{workScheduleEvent.user ? <strong>{userNameFormatter(workScheduleEvent.user)}</strong> : 'Global'}</td>}
                  <td className="text-sm-left text-md-right">
                    <Link to={ROUTES.WORK_SCHEDULE_EVENTS.SINGLE.replace(':id', workScheduleEvent.id)} className="btn btn-sm btn-primary ml-2">
                      Edit
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default WorkScheduleEventListTable;
