import { downloadInvoicePDFFile } from '../../lib/helper/downloadHelper';
import type { Invoice } from '../../apiTypes';
import { useState } from 'react';

function InvoicePDFFileDownloadButton({
  invoice,
  overrideClassStr = '',
  btnText = 'Download Invoice',
}: {
  invoice: Invoice;
  overrideClassStr?: string;
  btnText?: string;
}) {
  const [err, setErr] = useState<string | null>(null);
  if (!invoice?.invoicePDFFile?.id) {
    return null;
  }
  const downloadFile = () => {
    downloadInvoicePDFFile(invoice.invoicePDFFile.id, invoice.invoiceNo + '.pdf', invoice.uniqueRef).catch(() => {
      setErr('Failed to download file. Please try again or contact us if the problem persists.');
    });
  };
  return (
    <>
      {err ? <div className={'alert alert-danger'}>{err}</div> : null}
      <button type={'button'} className={overrideClassStr !== '' ? overrideClassStr : 'btn btn-primary d-block w-100'} onClick={downloadFile}>
        {btnText}
      </button>
    </>
  );
}

export default InvoicePDFFileDownloadButton;
