import React from 'react';
import Form from 'react-bootstrap/Form';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { addToast } from '../../../lib/redux/actions/toast';
import CcsdCodeTypeaheadWithCreate from '../../fields/CcsdCodeTypeaheadWithCreate';
import PropTypes from 'prop-types';
import { ccsdCodeCostSingleCreate } from '../../../lib/redux/actions/ccsdCodeCosts';

const mapDispatchToProps = {
  ccsdCodeCostSingleCreate,
  addToast,
};

const mapStateToProps = (state) => ({
  ...state.ccsdCodeCosts,
});

class CcsdCodeCostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearTypeaheads: false,
      selectedCcsdCode: this.props.selectedCcsdCode ?? null,
    };
  }

  clearTypeaheads() {
    this.setState(() => {
      return { clearTypeaheads: true };
    });
  }

  typeaheadCleared() {
    if (this.state.clearTypeaheads) {
      this.setState(() => {
        return { clearTypeaheads: false };
      });
    }
  }

  onNewCcsdCodeCreated(newCcsdCode) {
    const { reloadCodes } = this.props;

    // only call this when the ccsdCode is not required (required ccsdCodes have this action fulfilled serverside as it is part of a wider scope)
    if (!newCcsdCode.required) {
      this.createCcsdCodeCostsForCcsdCode(newCcsdCode);
    }

    // TODO NB this timeout should be replaced with a 'run after all the above requests have completed'
    setTimeout(() => {
      //      console.log('reload codes');
      reloadCodes();
    }, 1500);
  }

  createCcsdCodeCostsForCcsdCode(newCcsdCode) {
    const { consultant, ccsdCodeCostSingleCreate } = this.props;

    if (consultant && consultant.consultantHealthInsurers) {
      for (let x = 0; x < consultant.consultantHealthInsurers.length; x++) {
        ccsdCodeCostSingleCreate({
          ccsdCode: newCcsdCode['@id'],
          consultantHealthInsurer: consultant.consultantHealthInsurers[x]['@id'],
          cost: 0,
          validFrom: new Date(),
        });
      }
    }
  }

  onSubmit() {
    const { existingCcsdCodes, addToast, reloadCodes } = this.props;
    const selectedCcsdCode = this.state.selectedCcsdCode || false;

    if (selectedCcsdCode !== false && existingCcsdCodes.some((existingCcsdCode) => existingCcsdCode.id === this.state.selectedCcsdCode.id)) {
      addToast('That Ccsd code already exists.', false, true);
      return false;
    }

    if (selectedCcsdCode) {
      const returned = this.createCcsdCodeCostsForCcsdCode(selectedCcsdCode);
      // TODO NB this timeout should be replaced with a 'run after all the above requests have completed'
      setTimeout(() => {
        reloadCodes();
        addToast('The Ccsd code was successfully added. (See last table row)', true, false);
      }, 1500);
      return returned;
    }
  }

  render() {
    const { handleSubmit, error, pristine, submitting, isUpdatingCcsdCodeCost } = this.props;
    return (
      <>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <CcsdCodeTypeaheadWithCreate
            name="ccsdCode"
            shouldClear={this.state.clearTypeaheads}
            hasCleared={this.typeaheadCleared.bind(this)}
            multiple={false}
            label={''}
            onCcsdCodeCreated={this.onNewCcsdCodeCreated.bind(this)}
            onChange={(selected) => {
              this.setState(() => {
                return {
                  selectedCcsdCode: selected[0],
                };
              });
            }}
          />
          <button type="submit" disabled={pristine || submitting || isUpdatingCcsdCodeCost} className="btn btn-primary">
            Add CCSD code
          </button>
        </Form>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'CcsdCodeCostForm',
    enableReinitialize: true,
  })(CcsdCodeCostForm),
);

CcsdCodeCostForm.propTypes = {
  consultant: PropTypes.object.isRequired,
  existingCcsdCodes: PropTypes.array.isRequired,
};
