import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import {
  WORK_SCHEDULE_EVENT_LIST_REQUEST,
  WORK_SCHEDULE_EVENT_LIST_RECEIVED,
  WORK_SCHEDULE_EVENT_LIST_ERROR,
  WORK_SCHEDULE_EVENT_LIST_SET_PAGE,
  WORK_SCHEDULE_EVENT_LIST_UNLOAD,
  WORK_SCHEDULE_EVENT_SINGLE_CREATE_REQUEST,
  WORK_SCHEDULE_EVENT_SINGLE_CREATE_SUCCESS,
  WORK_SCHEDULE_EVENT_SINGLE_REQUEST,
  WORK_SCHEDULE_EVENT_SINGLE_RECEIVED,
  WORK_SCHEDULE_EVENT_SINGLE_ERROR,
  WORK_SCHEDULE_EVENT_LIST_SET_FILTERS,
  WORK_SCHEDULE_EVENT_LIST_CLEAR_FILTERS,
  WORK_SCHEDULE_EVENT_SINGLE_UPDATE_REQUEST,
  WORK_SCHEDULE_EVENT_SINGLE_UPDATED,
  WORK_SCHEDULE_EVENT_SINGLE_UNLOAD,
  WORK_SCHEDULE_EVENT_DELETE_REQUEST,
  WORK_SCHEDULE_EVENT_DELETED,
} from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const workScheduleEventListRequest = () => ({
  type: WORK_SCHEDULE_EVENT_LIST_REQUEST,
});

export const workScheduleEventListReceived = (data) => ({
  type: WORK_SCHEDULE_EVENT_LIST_RECEIVED,
  data,
});

export const workScheduleEventListError = (error) => ({
  type: WORK_SCHEDULE_EVENT_LIST_ERROR,
  error,
});

export const workScheduleEventListSetPage = (page) => ({
  type: WORK_SCHEDULE_EVENT_LIST_SET_PAGE,
  page,
});

export const workScheduleEventListSetFilters = (filters) => ({
  type: WORK_SCHEDULE_EVENT_LIST_SET_FILTERS,
  filters,
});

export const workScheduleEventListClearFilters = () => ({
  type: WORK_SCHEDULE_EVENT_LIST_CLEAR_FILTERS,
});

export const workScheduleEventListUnload = () => ({
  type: WORK_SCHEDULE_EVENT_LIST_UNLOAD,
});

export const workScheduleEventListFetch = (page = 1, filters = {}) => {
  return (dispatch) => {
    dispatch(workScheduleEventListRequest());
    if (filters.pagination === undefined) {
      filters.pagination = true;
    }
    if (filters.pagination) {
      filters.page = page;
    }
    return requests
      .get(API_ROUTES.WORK_SCHEDULE_EVENTS, filters)
      .then((response) => dispatch(workScheduleEventListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(workScheduleEventListError(error));
      });
  };
};

// CONSULTANT_SINGLE

export const workScheduleEventSingleRequest = () => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_REQUEST,
});

export const workScheduleEventSingleReceived = (data) => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_RECEIVED,
  data,
});

export const workScheduleEventSingleError = (error) => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_ERROR,
  error,
});

export const workScheduleEventSingleFetch = (id) => {
  return (dispatch) => {
    dispatch(workScheduleEventSingleRequest());
    return requests
      .get(`${API_ROUTES.WORK_SCHEDULE_EVENTS}/${id}`)
      .then((response) => dispatch(workScheduleEventSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new workScheduleEventSingleError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(workScheduleEventSingleError(error));
      });
  };
};

export const workScheduleEventSingleCreateRequest = () => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_CREATE_REQUEST,
});

export const workScheduleEventSingleCreateSuccess = (data) => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_CREATE_SUCCESS,
  data,
});

//export const workScheduleEventSingleCreateError = (error) => ({
//  type: WORK_SCHEDULE_EVENT_SINGLE_CREATE_ERROR,
//  error,
//});

export const workScheduleEventSingleCreate = (data) => {
  return (dispatch) => {
    dispatch(workScheduleEventSingleCreateRequest());
    return requests
      .post(`${API_ROUTES.WORK_SCHEDULE_EVENTS}`, data)
      .then((response) => {
        dispatch(workScheduleEventSingleCreateSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        const parsedError = parseApiErrors(error);
        if (parsedError && parsedError._error && parsedError._error.indexOf('uplicate') > 0) {
          parsedError._error = 'It looks like you may be creating a Billing Checklist Event which already exists - please check and try again if not.';
        } else {
          parsedError._error =
            'There was a problem creating the Billing Checklist Event - please check the details and try again. If the problem persists please contact support.';
        }
        throw new SubmissionError(parsedError);
      });
  };
};

export const workScheduleEventSingleUpdateRequest = () => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_UPDATE_REQUEST,
});

export const workScheduleEventSingleUpdated = (data) => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_UPDATED,
  data,
});

export const workScheduleEventSingleUpdate = (data, workScheduleEventId) => {
  return (dispatch) => {
    dispatch(workScheduleEventSingleUpdateRequest());
    return requests
      .patch(`${API_ROUTES.WORK_SCHEDULE_EVENTS}/${workScheduleEventId}`, data)
      .then((response) => dispatch(workScheduleEventSingleUpdated(response)))
      .catch((error) => {
        dispatch(workScheduleEventSingleError(''));
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};

export const workScheduleEventSingleUnload = () => ({
  type: WORK_SCHEDULE_EVENT_SINGLE_UNLOAD,
});

export const workScheduleEventDeleteRequest = () => ({
  type: WORK_SCHEDULE_EVENT_DELETE_REQUEST,
});

export const workScheduleEventDeleted = (id) => ({
  type: WORK_SCHEDULE_EVENT_DELETED,
  id,
});

export const workScheduleEventDelete = (workScheduleEventId) => {
  return (dispatch) => {
    dispatch(workScheduleEventDeleteRequest());
    return requests
      .delete(`${API_ROUTES.WORK_SCHEDULE_EVENTS}/${workScheduleEventId}`)
      .then(() => dispatch(workScheduleEventDeleted(workScheduleEventId)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        throw new SubmissionError(parseApiErrors(error));
      });
  };
};
