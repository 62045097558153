import React from 'react';
import { connect } from 'react-redux';
import { userPasswordLinkRequestUnload, userSingleFetch } from '../../lib/redux/actions/user';
import UserSingle from './UserSingle';
import MASpinner from '../../components/common/MASpinner';

const mapStateToProps = (state) => ({
  ...state.userSingle,
});

const mapDispatchToProps = {
  userSingleFetch,
  userPasswordLinkRequestUnload,
};

class UserSingleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.props.userSingleFetch(this.props.match.params.id);
  }

  componentWillUnmount() {
    const { userPasswordLinkRequestUnload } = this.props;
    userPasswordLinkRequestUnload();
  }

  render() {
    const { user, isFetchingUser } = this.props;
    if (!user || isFetchingUser) {
      return <MASpinner />;
    }
    return <UserSingle user={user} passwordLink={this.props.resetPasswordLink} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSingleContainer);
