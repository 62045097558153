import { requests } from '../../helper/agent';
import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { SUB_ACCOUNT_SINGLE_REQUEST, SUB_ACCOUNT_SINGLE_RECEIVED, SUB_ACCOUNT_SINGLE_ERROR } from '../constants';

export const subAccountSingleRequest = () => ({
  type: SUB_ACCOUNT_SINGLE_REQUEST,
});

export const subAccountSingleReceived = (data) => ({
  type: SUB_ACCOUNT_SINGLE_RECEIVED,
  data,
});

export const subAccountSingleError = (error) => ({
  type: SUB_ACCOUNT_SINGLE_ERROR,
  error,
});

export const subAccountSingleFetch = (id, code) => {
  return (dispatch) => {
    dispatch(subAccountSingleRequest());
    //console.log('Fetching single sub account ...');
    return requests
      .post(`${API_ROUTES.SUB_ACCOUNT_INVITE_VERIFY}`, { id: id, code: code })
      .then((response) => dispatch(subAccountSingleReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          dispatch(subAccountSingleError(error.response));
          return { status: 403 };
        }
        dispatch(subAccountSingleError(error.response));
      });
  };
};
