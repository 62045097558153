import { requests } from '../../helper/agent';
import { SubmissionError } from 'redux-form';
import { INVOICE_MISSING_DETAILS_TYPE_LIST_REQUEST, INVOICE_MISSING_DETAILS_TYPE_LIST_RECEIVED, INVOICE_MISSING_DETAILS_TYPE_LIST_ERROR } from '../constants';

import * as API_ROUTES from '../../routing/api';
import { userLogout } from './user';
import { parseApiErrors } from '../../helper/apiUtils';

export const invoiceMissingDetailsTypeListRequest = () => ({
  type: INVOICE_MISSING_DETAILS_TYPE_LIST_REQUEST,
});

export const invoiceMissingDetailsTypeListReceived = (data) => ({
  type: INVOICE_MISSING_DETAILS_TYPE_LIST_RECEIVED,
  data,
});

export const invoiceMissingDetailsTypeListError = (error) => ({
  type: INVOICE_MISSING_DETAILS_TYPE_LIST_ERROR,
  error,
});

export const invoiceMissingDetailsTypeListFetch = () => {
  return (dispatch) => {
    dispatch(invoiceMissingDetailsTypeListRequest());
    const filters = { pagination: false };
    return requests
      .get(API_ROUTES.INVOICE_MISSING_DETAILS_TYPE, filters)
      .then((response) => dispatch(invoiceMissingDetailsTypeListReceived(response)))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          return dispatch(userLogout());
        } else if (error.response && error.response.status === 403) {
          return new SubmissionError(parseApiErrors({ _error: 'You are not allowed to do this.' }));
        }
        dispatch(invoiceMissingDetailsTypeListError(error));
      });
  };
};
